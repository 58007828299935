import { ArrowSquareOut, CheckCircle } from '@phosphor-icons/react'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import certnOnboardingIllustration from '../../../assets/images/certn-onboarding-illustration.svg'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'

export function OnBoardingModal({ open, onClose }) {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader
        className='tw-w-full tw-border-none tw-bg-primary-20 !tw-p-0'
        tag='div'
        close={
          <ModalCloseButton
            className='tw-absolute tw-right-6 tw-top-6'
            toggle={onClose}
          />
        }
      >
        <img
          src={certnOnboardingIllustration}
          alt='An Illustration showing a big blue check icon in the center, small icons around the big check icon, and a Certn logo at the bottom of the big icon.'
          className='tw-h-full tw-w-full'
        />
      </ModalHeader>

      <ModalBody className='tw-border-t-0 !tw-p-6'>
        <div className='tw-mb-4 tw-text-[32px] tw-font-bold tw-leading-tight'>
          Request background checks on RemotePass!
        </div>

        <ul className='tw-mb-0 tw-flex tw-flex-col tw-gap-4'>
          {[
            'Streamline onboarding with seamless integration',
            'Gain insights with comprehensive workers reports',
            'Ensure due diligence is complete with confidence',
          ].map((item) => (
            <li
              className='tw-flex tw-items-center tw-gap-2 tw-text-base tw-text-text-80'
              key={item}
            >
              <CheckCircle
                size={20}
                color='var(--system-green-100)'
                className='tw-shrink-0'
              />
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </ModalBody>

      <ModalFooter className='!tw-p-6'>
        <Button
          className='!tw-py-4'
          outline
          iconRight={<ArrowSquareOut size={16} />}
          color='light'
        >
          Learn More
        </Button>
        <Button className='!tw-py-4'>Get Started</Button>
      </ModalFooter>
    </Modal>
  )
}
