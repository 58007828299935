import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap'

import { CustomCurrencyInput } from '../../components/ControlledCurrencyInput'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Head from '../../components/head'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { useFetch } from '../../helpers/hooks'
import {
  getAdminCalculatorResult,
  getCalculatorResult,
} from '../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../utils/map-to-option'
import QuoteView from '../Contract/components/Quote/QuoteView'
import EmptyContent from '../remotepass-cards/components/empty-content'

export default function EorSimulation() {
  const isAdmin = location.pathname.startsWith('/admin')

  const [controls, setControls] = useState({})

  function handleControlChange(name, value) {
    setControls((prev) => ({ ...prev, [name]: value }))
  }

  const allDataAvailable =
    !!controls?.country && !!controls?.amount && !!controls?.currency

  const { data: currencyCalculations, isLoading } = useFetch(
    {
      action: isAdmin ? getAdminCalculatorResult : getCalculatorResult,
      withAdminAccess: isAdmin,
      body: {
        country_code: controls?.country?.value,
        annual_salary: Number(controls?.amount),
        currency: controls?.currency?.value,
      },
      autoFetch: controls?.country && controls?.amount && controls?.currency,
    },
    [controls?.country, controls?.amount, controls?.currency],
  )

  const customerCurrencyCode = controls?.currency?.code

  const error = currencyCalculations?.original?.data?.error
    ? `We do not have data for ${controls?.country?.label}.`
    : currencyCalculations?.error || null

  return (
    <div className='page-content'>
      <Head title='EOR Simulation' />

      <PageHeading>
        <PageHeading.Title>EOR Simulation</PageHeading.Title>
      </PageHeading>

      <Card>
        <CardHeader className='bg-white rounded d-flex gap-12'>
          <QuoteControls controls={controls} onChange={handleControlChange} />
        </CardHeader>
        <CardBody
          className='d-flex align-items-center flex-column'
          style={{ minHeight: 576 }}
        >
          {!allDataAvailable ? (
            <EmptyContent
              isCard={false}
              shadow={false}
              minHeight={500}
              className='font-size-16'
            >
              <p className='mb-0'>
                Please select <strong>country</strong>,{' '}
                <strong>currency</strong> and <strong>yearly salary</strong> to
                see the <u>EOR simulation</u>.
              </p>
            </EmptyContent>
          ) : isLoading ? (
            <Loader minHeight={500} />
          ) : error ? (
            <EmptyContent
              isCard={false}
              shadow={false}
              minHeight={500}
              className='font-size-16'
            >
              {error}
            </EmptyContent>
          ) : (
            <QuoteView
              quote={currencyCalculations}
              customerCurrencyCode={customerCurrencyCode}
            />
          )}
        </CardBody>
      </Card>
    </div>
  )
}

function QuoteControls({ controls = {}, onChange = () => {} }) {
  const { eor_countries: eorCountries, currencies } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )
  const countryOptions = (eorCountries ?? []).map((c) =>
    mapCountryToOption(c, 'iso2'),
  )
  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'code'))

  return (
    <>
      <CustomSelect
        label='Country'
        name='country'
        options={countryOptions}
        value={controls?.country}
        wrapperClassName='flex-1'
        onChange={(newValue) => {
          onChange('country', newValue)
        }}
      />

      <FormGroup className='mb-0 flex-1'>
        <Label htmlFor='amount'>Yearly salary</Label>

        <InputGroup>
          <CustomCurrencyInput
            name='amount'
            className='rounded-right-0'
            hideError
            value={controls?.amount}
            onChange={(newValue) => onChange('amount', newValue)}
          />
          <InputGroupAddon addonType='append' style={{ width: 150, zIndex: 1 }}>
            <CustomSelect
              name='currency'
              options={currencyOptions}
              value={controls?.currency}
              classNamePrefix='RS-Addon'
              wrapperClassName='w-100'
              onChange={(newValue) => {
                onChange('currency', newValue)
              }}
            />
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
    </>
  )
}
