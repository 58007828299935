import { CaretDown } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React from 'react'
import { Badge, cn } from 'ui'

function RequestStep({
  children,
  isOpen,
  isDone,
  order,
  title,
  userName,
  updatedAt,
  description,
  toggle,
  noArrow,
}) {
  return (
    <div className='tw-rounded tw-border tw-border-surface-30'>
      <button
        onClick={toggle}
        className={cn(
          'tw-flex tw-w-full tw-items-center tw-justify-between tw-px-4 tw-py-6 tw-transition-colors tw-duration-200 tw-ease-in-out hover:tw-bg-surface-10',
          isOpen ? 'tw-bg-surface-10' : 'tw-bg-transparent',
        )}
      >
        <div className='tw-flex tw-items-center md:tw-w-[55%]'>
          <div
            className={cn(
              'tw-mr-4 tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-text-base tw-font-medium',
              isDone
                ? 'tw-bg-systemGreen-10 tw-text-systemGreen-100'
                : 'tw-bg-systemGold-10 tw-text-systemGold-100',
            )}
          >
            {order}
          </div>
          <div className='tw-flex tw-flex-col tw-items-start'>
            <div className='tw-text-start tw-text-base tw-font-semibold'>
              {title}
            </div>
            <div className='tw-text-start tw-text-sm tw-text-text-80'>
              {description}
            </div>
          </div>
        </div>
        <div className='tw-flex tw-items-center tw-justify-end tw-gap-8 md:tw-w-[45%]'>
          <div className='tw-flex tw-flex-col tw-items-end md:tw-items-start'>
            <div className='tw-pl-2 tw-text-start tw-text-sm tw-text-text-80'>
              {userName && 'Submitted by ' + userName}
            </div>
            <div className='tw-pl-2 tw-text-start tw-text-sm tw-text-text-80'>
              {/* format example: Sep. 12, 2023  */}
              {updatedAt && (
                <>
                  {format(new Date(updatedAt), 'MMM. dd, yyyy')}
                  <span className='tw-text-text-60'>
                    {' '}
                    {format(new Date(updatedAt), 'hh:mm aa')}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className='tw-flex tw-items-center tw-justify-center tw-gap-4'>
            {isDone ? (
              <Badge color='green'>Done</Badge>
            ) : (
              <Badge color='yellow'>Pending</Badge>
            )}
            {noArrow ? (
              <div style={{ width: 24, height: 24 }}></div>
            ) : (
              <CaretDown
                className={cn('tw-transition-transform tw-duration-200', {
                  'tw-rotate-180': isOpen,
                })}
                size={24}
              />
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className='tw-p-6'>{children}</div>}
    </div>
  )
}

export default RequestStep
