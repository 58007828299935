import React from 'react'
import WorkingDays from '../pages/new-time-off-policy/working-days'
import { cn } from 'ui'

export default function WorkingDaysView({ days, className }) {
  return (
    <span className={cn('tw-flex tw-flex-col', className)}>
      <div className='tw-text-base tw-font-bold tw-text-black'>
        Working days
      </div>
      <span className='tw-mb-6 tw-text-sm tw-text-text-60'>
        Only the working days will be included in time off requests
      </span>
      <span className='tw-flex tw-gap-3'>
        <WorkingDays
          days={days}
          activeClassName='tw-border-secondary-50 tw-bg-secondary-20 tw-text-secondary'
          inActiveClassName='tw-bg-white tw-border-surface-30 tw-font-medium'
          className='tw-h-12 tw-w-12 tw-text-xs'
        />
      </span>
    </span>
  )
}
