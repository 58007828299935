import cx from 'classnames'
import React from 'react'
import Card from 'reactstrap/lib/Card'

export default function EmptyContent({
  children,
  minHeight = '20rem',
  styles = {},
  shadow = true,
  className,
  isCard = true,
  ...props
}) {
  const Comp = isCard ? Card : 'div'
  return (
    <Comp
      className={cx(
        'd-flex align-items-center justify-content-center text-muted rounded-lg',
        { 'rp-shadow-2': shadow, 'shadow-none': !shadow },
        className,
      )}
      style={{ gap: '2rem', minHeight, ...styles }}
      {...props}
    >
      {children}
    </Comp>
  )
}
