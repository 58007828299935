import { AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import { PasswordChecklistComp } from '../../components/CommonForBoth/ChangePassword'
import TextInput from '../../components/Forms/TextInput'
import Button from '../../components/ui/button'
import { HeaderAnonym } from '../../components/ui/header-anonym'
import { useFetch } from '../../helpers/hooks'
import { updatePassword } from '../../services/api'

const UpdatePassword = (props) => {
  const token = new URLSearchParams(props.location.search).get('token')

  const [isValid, setIsValid] = useState('')
  const [password, setPassword] = useState('')
  const updatePass = useFetch({
    action: updatePassword,
    onComplete: () => {
      props.history.push('/login')
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <React.Fragment>
      <div className='account-pages m-0 p-0'>
        <HeaderAnonym />

        <Container className='p-0 mt-5'>
          <Row className='justify-content-center p-0 m-0'>
            <Col md={8} lg={6} xl={5} className='p-3 p-md-0 m-0'>
              <Card className='overflow-hidden p-0 m-0'>
                <CardBody className='p-3 p-md-4 m-0'>
                  <div>
                    {props.forgetError && props.forgetError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color='success' style={{ marginTop: '13px' }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={(e, v) => {
                        if (isValid) {
                          updatePass.startFetch({ ...v, token })
                        } else {
                          toastr.error(
                            'Weak password easy to guess, password should respect checklist condition',
                          )
                        }
                      }}
                    >
                      <div className='form-group'>
                        <Row className='verificationCode m-0 justify-content-center pt-3 pt-md-4'>
                          {/* <ReactInputVerificationCode */}
                          {/*  type='number' */}
                          {/*  length={4} */}
                          {/*  inputStyle={{ */}
                          {/*    padding: '12px', */}
                          {/*    margin: '12px' */}
                          {/*  }} */}
                          {/*  onChange={(e) => { */}
                          {/*    setCode(e) */}
                          {/*  }} */}
                          {/* /> */}
                        </Row>
                      </div>
                      <div className='form-group'>
                        <TextInput
                          name='password'
                          label='New Password'
                          placeholder='Enter new password'
                          type='password'
                          required
                          autocomplete='autocomplete_off_hack_xfr4!k'
                          id='passWord'
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <PasswordChecklistComp
                          password={password}
                          TooltipId='passWord'
                          onChange={(v) => setIsValid(v)}
                        />
                      </div>
                      <Row className='form-group mb-0'>
                        <Col className='text-right'>
                          <Button
                            loading={updatePass.isLoading}
                            text='Reset'
                            type='submit'
                            style={{ height: 48, width: '100%' }}
                          />
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className='my-3 my-md-4 text-center'>
                <div className='my-3 my-md-4 d-flex justify-content-center align-items-center'>
                  <Link
                    to='/login'
                    className='text-primary mb-0 font-size-14 font-weight-medium'
                  >
                    Go back to the login page
                  </Link>
                </div>
                <p>© {new Date().getFullYear()} RemotePass. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdatePassword
