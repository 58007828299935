import cx from 'classnames'
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller } from 'react-hook-form'

import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

const ControlledCurrencyInput = ({
  control,
  name,
  defaultValue,
  error: externalError,
  hideError = false,
  isDisabled,
  id,
  label,
  transform,
  className,
  containerClassName,
  onBlur: _onBlur,
  ...prop
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value, onBlur, onChange, name },
        fieldState: { error },
      }) => {
        const theError =
          getInputErrorMessage(externalError) || getInputErrorMessage(error)

        return (
          <CustomCurrencyInput
            value={transform?.input ? transform.input(value) : value}
            onChange={(newValue) =>
              transform?.output
                ? onChange(transform.output(newValue))
                : onChange(newValue ?? '')
            }
            name={name}
            error={theError}
            hideError={hideError}
            isDisabled={isDisabled}
            id={id}
            label={label}
            className={className}
            containerClassName={containerClassName}
            {...prop}
            onBlur={(event) => {
              onBlur?.(event)
              _onBlur?.(event)
            }}
          />
        )
      }}
    />
  )
}

export function CustomCurrencyInput({
  value,
  onChange,
  onBlur,
  name,
  error,
  hideError = false,
  isDisabled,
  id,
  label,
  className,
  containerClassName,
  ...prop
}) {
  const showingError = error && !hideError

  return (
    <div className={cx('flex-grow-1', containerClassName)}>
      {!label ? null : <label htmlFor={id}>{label}</label>}

      <CurrencyInput
        className={cx('form-control', { 'border-danger': !!error }, className)}
        name={name}
        value={value}
        onValueChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
        id={id}
        // currency input props
        allowDecimals
        decimalsLimit={2}
        decimalSeparator='.'
        groupSeparator=','
        {...prop}
      />

      {!error || !showingError ? null : (
        <InputFeedback className='tw-mt-1'>{error}</InputFeedback>
      )}
    </div>
  )
}

export default ControlledCurrencyInput
