import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from 'availity-reactstrap-validation'
import {
  Button,
  Card,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'

import { useFetch } from '../../../../helpers/hooks'
import {
  approveKYCSubmission,
  getUserKYCSubmission,
  rejectKYCSubmission,
} from '../../../../services/api'

function getStatusColor(status) {
  switch (status) {
    case 'pending': {
      return 'bg-soft-warning text-warning'
    }
    case 'approved': {
      return 'bg-soft-success text-success'
    }
    case 'rejected': {
      return 'bg-soft-danger text-danger'
    }
    default: {
      return ''
    }
  }
}

const rejectionReasons = [
  { label: 'Photos provided not clear/visible', value: '1' },
  { label: 'ID Expired', value: '2' },
  { label: 'Name mismatch', value: '3' },
]

const kycChecks = [
  { label: 'Name provided matches name on ID', value: 'nameMatches' },
  { label: 'ID provided is valid', value: 'idValid' },
]

export default function ManualKycVerification() {
  const [rejecting, setRejecting] = useState()
  const [rejectionReason, setRejectionReason] = useState(
    rejectionReasons[0].value,
  )
  const [loadingSubmission, setLoadingSubmission] = useState(false)

  const { userId } = useParams()
  const history = useHistory()

  const { data, isLoading, completed } = useFetch(
    {
      action: getUserKYCSubmission,
      withAdminAccess: true,
      body: { request_id: userId },
      autoFetch: true,
    },
    [],
  )
  const { startFetch: approveSubmission } = useFetch(
    {
      action: approveKYCSubmission,
      withAdminAccess: true,
      onComplete: onSubmissionComplete,
    },
    [],
  )
  const { startFetch: rejectSubmission } = useFetch(
    {
      action: rejectKYCSubmission,
      withAdminAccess: true,
      onComplete: onSubmissionComplete,
    },
    [],
  )

  function iniciateRejection() {
    setRejecting(true)
  }

  function toggleRejectionModal() {
    setRejecting((r) => !r)
  }

  function onSubmissionComplete() {
    setLoadingSubmission(false)
    history.push('/admin/users#kyc-verification')
  }

  function handleSubmit(_, errors) {
    if (errors.length > 0) {
      return
    }
    setLoadingSubmission(true)

    approveSubmission({ request_id: userId })
  }

  function handleRejectSubmission() {
    setLoadingSubmission(true)

    const reason = rejectionReasons.find((r) => r.value === rejectionReason)
    const body = { request_id: userId, reason: reason?.label ?? '' }

    rejectSubmission(body)
  }

  function validateChecks(value, ctx, _, callb) {
    callb(ctx.judjment !== 'reject' ? value.length > 0 : true)
  }

  if (isLoading || !completed) {
    return (
      <div
        className='page-content align-items-center d-flex justify-content-center'
        style={{ minHeight: 350 }}
      >
        <Spinner type='grow' color='primary' />
      </div>
    )
  }

  return (
    <div className='page-content'>
      <Card className='p-4'>
        <AvForm onSubmit={handleSubmit}>
          <div className='mb-3'>
            <h5 className='' style={{ fontSize: '0.8rem' }}>
              <span>Status: </span>{' '}
              <span
                style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}
                className={`badge badge-pill ${getStatusColor(
                  data.status,
                )} font-size-14 rounded text-capitalize`}
              >
                {data.status}
              </span>
            </h5>
          </div>

          <AvField
            name='name'
            label='Name on RemotePass:'
            value={data.contractor_name}
            disabled
          />
          <AvField name='name' label='Name on ID:' value={data.name} disabled />

          {data.files.map(({ image, name }, index) => (
            <ReadOnlyImage src={image} alt='' key={index} label={`${name}:`} />
          ))}

          <AvCheckboxGroup
            name='submissionChecks'
            validate={{ async: validateChecks }}
          >
            {kycChecks.map(({ label, value }) => (
              <AvCheckbox
                label={label}
                value={value}
                key={value}
                style={{
                  '--size': '14px',
                  width: 'var(--size)',
                  height: 'var(--size)',
                  marginTop: '0.2rem',
                }}
              />
            ))}
          </AvCheckboxGroup>

          <div className='d-flex' style={{ gap: '1rem' }}>
            <Button
              color={loadingSubmission ? 'light' : 'success'}
              disabled={loadingSubmission}
            >
              {loadingSubmission ? 'Submitting review...' : 'Validate'}
            </Button>

            <Button
              type='button'
              color={loadingSubmission ? 'light' : 'danger'}
              onClick={iniciateRejection}
            >
              {loadingSubmission ? 'Submitting review...' : 'Reject'}
            </Button>
          </div>
        </AvForm>
      </Card>

      <Modal
        isOpen={rejecting}
        role='dialog'
        autoFocus={true}
        centered={true}
        tabindex='-1'
        toggle={toggleRejectionModal}
      >
        <ModalHeader toggle={toggleRejectionModal}>
          Reject submission
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for='reason'>Rejection Reason:</Label>
            <Input
              type='select'
              name='reason'
              className='w-75'
              onChange={(e) => setRejectionReason(e.target.value)}
              value={rejectionReason}
            >
              {rejectionReasons.map(({ label, value }, index) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            type='button'
            color='outline-light'
            onClick={toggleRejectionModal}
            disabled={loadingSubmission}
          >
            Cancel
          </Button>
          <Button
            type='button'
            color={loadingSubmission ? 'light' : 'danger'}
            onClick={handleRejectSubmission}
            disabled={loadingSubmission}
          >
            Reject submission
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

function ReadOnlyImage({ src, alt, label }) {
  return (
    <div className='w-75 form-group'>
      {!label ? null : (
        <h5 className='mb-2' style={{ fontSize: '0.8rem' }}>
          {label}
        </h5>
      )}
      <div style={{ width: '100%', height: 290 }} className='bg-light p-2'>
        <img
          src={src}
          alt={alt}
          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        />
      </div>
    </div>
  )
}
