import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

const capitalizeFirstLetter = (string) => {
  return string.charAt(1).toUpperCase() + string.slice(2)
}

class NonAuthLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const currentage = capitalizeFirstLetter(this.props.location.pathname)

    document.title =
      currentage + ' | RemotePass - Compliance & Payment for remote contractors'
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

export default withRouter(NonAuthLayout)
