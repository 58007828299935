import { Camera } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import Dropzone from 'react-dropzone'

import { LoadingElement } from '../../components/ui/button'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'

export default function ManageAvatar({
  src,
  name,
  onUploadPhoto,
  isUploading,
  avatarTitleClassName = 'avatar-title',
}) {
  const { hasAccess } = usePermissions()
  return (
    <div className='tw-relative tw-inline-block tw-size-[100px] lg:tw-size-[124px]'>
      {src ? (
        <img
          src={src}
          alt=''
          className='tw-size-[100px] tw-rounded-full tw-bg-systemBlue-10 tw-object-contain tw-p-2 lg:tw-size-[124px]'
        />
      ) : (
        <span
          className={cx('tw-rounded-full tw-font-bold', avatarTitleClassName)}
          style={{ fontSize: 40 }}
        >
          {name?.charAt(0)}
        </span>
      )}
      {hasAccess(permissions.manageCompanySettings) && (
        <div
          className='upload-btn tw-absolute tw-cursor-pointer'
          style={{ width: 32, top: 0, right: 0 }}
        >
          <Dropzone
            onDrop={onUploadPhoto}
            accept={{
              'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ zIndex: 99999999999 }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className='avatar-xs tw-mr-4'>
                    <span className='avatar-title tw-rounded-full tw-border tw-bg-primary tw-text-white'>
                      {isUploading ? (
                        <LoadingElement size={16} />
                      ) : (
                        <Camera size={16} className='tw-fill-white' />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}
    </div>
  )
}
