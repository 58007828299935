import cx from 'classnames'
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { FormGroup, Label } from 'reactstrap'

export default function FieldAmount({
  label,
  name,
  value,
  onValueChange,
  prefix,
  inputRef,
  helperText,
  error,
}) {
  return (
    <FormGroup className='position-relative'>
      <Label
        htmlFor={name}
        className='position-absolute px-3'
        style={{ top: '0.5rem', color: '#424652' }}
      >
        {label}
      </Label>
      <CurrencyInput
        className={cx('form-control font-size-18 px-3', {
          'bg-soft-danger border-soft-danger text-danger': !!error,
        })}
        name={name}
        id={name}
        prefix={prefix ? `${prefix} ` : ''}
        value={value}
        onValueChange={onValueChange}
        ref={inputRef}
        style={{ paddingTop: '2rem', height: 'auto' }}
        // currency input props
        allowDecimals={true}
        decimalsLimit={2}
        decimalSeparator='.'
        groupSeparator=','
      />
      {!helperText && !error ? null : (
        <div
          className={cx('font-size-12 mt-1', {
            'text-danger': !!error,
            'text-muted': !error,
          })}
        >
          {error ?? helperText}
        </div>
      )}
    </FormGroup>
  )
}
