import { yupResolver } from '@hookform/resolvers/yup'
import { PencilSimpleLine } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Button from '../../../../components/ui/button'
import IconButton from '../../../../components/ui/icon-button'
import { useFetch } from '../../../../helpers/hooks'
import { updateCompanyAdminJson } from '../../../../services/api'

export function ServiceFeeManagement({ company, updateCompaniesList }) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => !open)
  }

  const { startFetch: updateData, isLoading: isUpdating } = useFetch({
    action: updateCompanyAdminJson,
    withAdminAccess: true,
    onComplete: () => {
      updateCompaniesList?.()
    },
  })

  function onSubmit(values) {
    const body = {
      company_id: values.company_id,
      service_fee: Number(values.service_fee),
      service_fee_percentage: values.service_fee_percentage ? 1 : 0,
    }

    if (values?.service_fee_percentage) {
      body.service_fee_min = Number(values.service_fee_min)
      body.service_fee_max = Number(values.service_fee_max)
    }

    updateData(body)
  }

  return (
    <>
      <FeeValueDisplay
        isPercentage={company?.service_fee_percentage}
        value={company?.service_fee}
        onEdit={toggle}
        max={company?.service_fee_max}
        min={company?.service_fee_min}
      />

      {!isOpen ? null : (
        <ServiceFeeModal
          company={company}
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={onSubmit}
          loading={isUpdating}
        />
      )}
    </>
  )
}

export function FeeValueDisplay({ value, min, max, isPercentage, onEdit }) {
  return (
    <div
      className='align-items-center border d-flex gap-12 position-relative pl-2 pr-1.5 py-1 rounded text-text-70'
      style={{ width: 'fit-content' }}
    >
      <div style={{ lineHeight: 1 }}>
        {isPercentage ? '%' : '$'} {value}{' '}
        {min && max ? `($${min} - $${max})` : null}
      </div>

      <IconButton
        color='light'
        size={22}
        icon={<PencilSimpleLine size={18} color='currentColor' />}
        onClick={onEdit}
        className='text-current'
      />
    </div>
  )
}

const serviceFeeFormId = 'service-fee-form'
function ServiceFeeModal({ company, onSubmit, isOpen, toggle, loading }) {
  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      service_fee: company.service_fee,
      service_fee_percentage: company.service_fee_percentage === 1,
      service_fee_min: company.service_fee_min,
      service_fee_max: company.service_fee_max,
    },
    resolver: yupResolver(
      yup.object().shape({
        service_fee: yup.number()?.typeError('Invalid value').required(),
        service_fee_percentage: yup.boolean().required(),
        service_fee_min: yup.number().when('service_fee_percentage', {
          is: true,
          then: (schema) => schema.required('The minimum value is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        service_fee_max: yup.number().when('service_fee_percentage', {
          is: true,
          then: (schema) => schema.required('The maximum value is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    ),
  })

  const isPercentage = watch('service_fee_percentage')

  const companyInfo = getCompanyInfo(company)

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Service Fee</ModalHeader>
      <ModalBody>
        <div className='bg-surface-10 border mb-3 px-2.5 py-2 rounded'>
          <InfoList items={companyInfo} />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='d-flex flex-column gap-14'
          id={serviceFeeFormId}
        >
          <input
            type='hidden'
            {...register('company_id', { value: company?.id })}
          />

          <ControlledCurrencyInput
            control={control}
            id='service_fee'
            name='service_fee'
            label='Service Fee'
            prefix={isPercentage ? '% ' : '$ '}
          />

          <ControlledCheckbox
            control={control}
            id='service_fee_percentage'
            name='service_fee_percentage'
            label='Service Fee Percentage'
          />

          {!isPercentage ? null : (
            <>
              <ControlledCurrencyInput
                control={control}
                id='service_fee_min'
                name='service_fee_min'
                label='Service Fee Min'
                prefix='$ '
              />

              <ControlledCurrencyInput
                control={control}
                id='service_fee_max'
                name='service_fee_max'
                label='Service Fee Max'
                prefix='$ '
              />
            </>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={serviceFeeFormId}
          disabled={loading}
          loading={loading}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function getCompanyInfo(company) {
  return [
    { label: 'Company name', value: company.name },
    { label: 'dba', value: company.dba },
    { label: 'Company country', value: company.country?.name },
    { label: 'Currency', value: company.currency?.name },
    { label: 'Is Cor Enabled', value: company.is_cor_enabled ? 'Yes' : 'No' },
  ]
}

export function InfoList({ items }) {
  return items.map(({ label, value }) => {
    return (
      <div key={label} className='d-flex'>
        <div className='text-text-70 text-capitalize' style={{ minWidth: 120 }}>
          {label}:
        </div>
        <div>{value ?? 'N/A'}</div>
      </div>
    )
  })
}
