import { List, PencilSimple, TrashSimple } from '@phosphor-icons/react'
import React, { useState } from 'react'
import toastr from 'toastr'
import { cn } from 'ui'

import { useFetch } from '../../../../../helpers/hooks'
import { deleteFormField, reOrderFields } from '../../../../../services/api'
import { getFieldTypeText } from '../helpers/getOrder'
import IconButton from '../../../../../components/ui/icon-button'

function FormField({ field, refetch, onUpdateField, notEditable }) {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const { startFetch: deleteField, isLoading: isDeleting } = useFetch({
    action: deleteFormField,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Field deleted successfully')
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const { startFetch: reOrder, isLoading: isReOrdering } = useFetch({
    action: reOrderFields,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Field Moved successfully')
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const handleDragStart = (e) => {
    e.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        file_id: field.id,
        order: field.order,
        step_id: field.form_step_id,
      }),
    )
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDraggedOver(true)
  }
  const handleOnDragLeave = () => {
    setIsDraggedOver(false)
  }
  const handleDrop = (ev) => {
    ev.preventDefault()
    const draggedField = JSON.parse(ev.dataTransfer.getData('text/plain'))
    setIsDraggedOver(false)
    if (draggedField.file_id === field.id || isReOrdering) {
      return
    }
    if (draggedField.step_id !== field.form_step_id) {
      toastr.info("can't move field to another step")
      return
    }
    reOrder({
      form_step_id: field.form_step_id ?? undefined,
      form_id: field.form_id,
      form_fields: [
        { id: draggedField.file_id, order: field.order },
        { id: field.id, order: draggedField.order },
      ],
    })
  }
  return (
    <div
      draggable={!notEditable}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleOnDragLeave}
      className='tw-relative tw-mt-4'
    >
      <div
        className={cn(
          'tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded tw-bg-systemGreen-30 tw-text-2xl tw-text-systemGreen tw-transition-opacity tw-duration-300',
          isDraggedOver ? 'tw-opacity-50' : 'tw-opacity-0',
          { 'tw-opacity-30': isReOrdering },
        )}
      >
        {isReOrdering ? 'Swapping' : 'Swap'}
      </div>
      <div
        className={cn(
          'tw-group tw-relative tw-box-border tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-border tw-border-secondary-30 tw-p-4',
          { 'tw-opacity-50': isDeleting },
        )}
      >
        <div className=''>
          <span className='tw-text-base'>{field.title}</span>
          <span className='tw-ml-1 tw-text-secondary-80'>
            ({getFieldTypeText(field.type)})
          </span>
        </div>
        {!notEditable && (
          <div className='overflow-hidden tw-relative tw-flex tw-items-center tw-gap-1'>
            <IconButton
              color='transparent'
              icon={<PencilSimple size={24} />}
              onClick={() => onUpdateField?.(field)}
            />
            <IconButton
              color='transparent'
              icon={<TrashSimple color='red' size={24} />}
              onClick={() =>
                deleteField({
                  form_id: field.form_id,
                  form_field_id: field.id,
                })
              }
            />
            <List
              className='tw-mr-[-40px] tw-cursor-grab tw-opacity-0 tw-transition-all tw-duration-300 group-hover:tw-mr-0 group-hover:tw-opacity-100'
              size={24}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FormField
