export const INSURANCE_BANNER_COOKIE = 'insurance_banner'
export const PHYSICAL_CARD_BANNER_COOKIE = 'physical_card_banner'
export const DE_ONBOARDING_BANNER_COOKIE = 'de_onboarding_banner'
export const PHYSICAL_CARD_RED_DOT_COOKIE = 'physical_card_red_dot'
export const INSURANCE_RED_DOT_COOKIE = 'insurance_red_dot'
export const INSURANCE_ALLOWANCE_RED_DOT_COOKIE = 'insurance_allowance_red_dot'
export const BG_CHECK_RED_DOT_COOKIE = 'bg_check_red_dot'

export const TFA_REMINDER = 'tfa_reminder'

export const SB_INSURANCE = 'sb_insurance'
export const SB_CARDS = 'sb_cards'
export const SB_PERKS = 'sb_perks'
export const SB_REFERS = 'sb_refers'

export const ORG_CHART = 'org_chart'
