import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { InView } from 'react-intersection-observer'
import { UncontrolledTooltip } from 'reactstrap'
import { cn } from 'ui'

import { ControlledCheckbox2 } from '../../components/controlled-checkbox'

export function ReviewItemsView({
  items = [],
  activeItem,
  onClickItem = () => {},
  fetchNextPage = () => {},
  isFetchingNextPage,
  isSelecting,
}) {
  return (
    <>
      <AnimatePresence mode='popLayout' initial={false}>
        {items.map((item, index) => {
          const isActive = item.id === activeItem?.id

          return (
            <ReviewListItem
              key={item.id}
              onClick={() => onClickItem(item)}
              item={item}
              itemIndex={index}
              isActive={isActive}
              isSelecting={isSelecting}
            />
          )
        })}
      </AnimatePresence>

      <InView
        onChange={(isView) => {
          if (isView) {
            fetchNextPage()
          }
        }}
        as='div'
        rootMargin='400px 0px 0px 0px'
      >
        {!isFetchingNextPage ? null : (
          <div className='tw-flex tw-items-center tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-p-6'>
            <div className='tw-h-12 tw-w-12 tw-animate-pulse tw-rounded-full tw-bg-surface-20' />
            <div>
              <div className='tw-mt-2 tw-h-4 tw-w-32 tw-animate-pulse tw-rounded tw-bg-surface-20' />
              <div className='tw-mt-1 tw-h-4 tw-w-16 tw-animate-pulse tw-rounded tw-bg-surface-20' />
            </div>
            <div className='tw-ml-auto tw-flex tw-flex-col tw-items-end'>
              <div className='tw-mt-2 tw-h-4 tw-w-32 tw-animate-pulse tw-rounded tw-bg-surface-20' />
              <div className='tw-mt-1 tw-h-4 tw-w-16 tw-animate-pulse tw-rounded tw-bg-surface-20' />
            </div>
          </div>
        )}
      </InView>
    </>
  )
}

function ReviewListItem({ onClick, item, itemIndex, isActive, isSelecting }) {
  const { control, register } = useFormContext()

  return (
    <motion.div
      className={cn(
        'tw-relative tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-1 tw-rounded tw-p-6',
        isActive
          ? 'tw-bg-primary-10 tw-ring-2 tw-ring-primary-100'
          : 'tw-ring-1 tw-ring-surface-30 hover:tw-bg-surface-10',
      )}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      layout
    >
      <div className='tw-flex tw-items-center tw-gap-4 tw-text-sm'>
        <div className='tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-20 tw-p-2.5 tw-text-primary-100'>
          {isSelecting ? (
            <>
              <ControlledCheckbox2
                name={`selectedItems.${item.id}.selected`}
                id={`selectedItems.${item.id}`}
                control={control}
                containerClassName='tw-relative tw-z-10'
                className='tw-rounded'
                disabled={item.disabled}
                containerId={`item-${item.id}`}
              />
              {!item.disabled ? null : (
                <UncontrolledTooltip target={`item-${item.id}`}>
                  The country is not supported
                </UncontrolledTooltip>
              )}
            </>
          ) : (
            item?.icon
          )}
        </div>

        <input
          type='hidden'
          {...register(`selectedItems.${item.id}.id`, { value: item.id })}
        />
        <input
          type='hidden'
          {...register(`selectedItems.${item.id}.index`, { value: itemIndex })}
        />

        <div>
          <div className='tw-font-medium'>{item.left.top}</div>
          <div className='tw-text-text-80'>{item.left.bottom}</div>
        </div>
      </div>
      <div className='tw-text-right'>
        <div className='tw-text-base tw-font-medium'>{item.right.top}</div>
        <div className='tw-text-text-80'>{item.right.bottom}</div>
      </div>

      <button
        className={cn(
          'tw-absolute tw-inset-0 tw-rounded',
          'tw-ring-inset focus-visible:tw-ring-2 focus-visible:tw-ring-primary-50',
          isActive ? '!tw-cursor-default' : 'tw-cursor-pointer',
        )}
        onClick={onClick}
      />
    </motion.div>
  )
}
