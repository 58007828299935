import Calendar from '@toast-ui/react-calendar'
import 'tui-calendar/dist/tui-calendar.css'
// If you use the default popups, use this.
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Spinner } from 'reactstrap'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'

import arrowLeft from '../../assets/images/arrowLeft.svg'
import arrowRight from '../../assets/images/arrowRight.svg'
import close from '../../assets/images/close.svg'
import dollar from '../../assets/images/dollar.svg'
import gift from '../../assets/images/gift.svg'
import happy from '../../assets/images/happy.png'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getCalendarData } from '../../services/api'
import { updateShowCalender } from '../../store/actions'
import '../Calendar/calendarCustom.css'

const Index = (props) => {
  const { show, hide } = props
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const { hasAccess } = usePermissions()
  const calendarRef = useRef()
  const [thisMonth, setThisMonth] = useState(new Date())
  const showPaydays = hasAccess(permissions.viewCalendarPaydays)
  const [checkPaydays, setCheckPaydays] = useState(showPaydays)
  const [checkHolidays, setCheckHolidays] = useState(false)
  const [checkTimeOff, setCheckTimeOff] = useState(true)
  const [checkTimeOffRequest, setCheckTimeOffRequest] = useState(true)
  const [checkBirthday, setCheckBirthday] = useState(true)
  const [clientHolidays, setClientHolidays] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [schedules, setSchedules] = useState([])

  const getData = useFetch({
    action: getCalendarData,
  })

  useEffect(() => {
    const calendarInstance = calendarRef.current.getInstance()
    const start = calendarInstance.getDateRangeStart()
    const end = calendarInstance.getDateRangeEnd()

    setStartDate(start)
    setEndDate(end)
  }, [])

  useEffect(() => {
    let theFilter = []
    theFilter = checkTimeOffRequest
      ? [...theFilter, 'pending_time_offs']
      : theFilter
    theFilter = checkPaydays ? [...theFilter, 'paydays'] : theFilter
    theFilter = checkHolidays
      ? [...theFilter, 'contractor_holidays']
      : theFilter
    theFilter = checkTimeOff ? [...theFilter, 'approved_time_offs'] : theFilter
    theFilter = checkBirthday
      ? [...theFilter, 'contractor_birthdays']
      : theFilter
    theFilter = clientHolidays ? [...theFilter, 'company_holidays'] : theFilter

    if (show) {
      getData.startFetch({
        start_date: moment(new Date(startDate)).format('YYYY-MM-DD'),
        end_date: moment(new Date(endDate)).format('YYYY-MM-DD'),
        filter: theFilter,
      })
    }
  }, [
    startDate,
    endDate,
    checkHolidays,
    clientHolidays,
    checkBirthday,
    checkTimeOff,
    checkTimeOffRequest,
    checkPaydays,
    show,
  ])

  useEffect(() => {
    // *******Client Connected*************
    // - Approved time off => approved_time_offs
    //     - Pending time off => pending_time_offs
    //     - Paydays => paydays
    // - Client holidays => company_holidays
    //     - Team holidays => contractor_holidays
    //     - Birthdays => contractor_birthdays
    const payrollDays = getData?.data?.paydays || []
    const clientHolidays = getData?.data?.company_holidays || []
    const timeOffs = getData?.data?.approved_time_offs || []
    const contractorBirthdays = getData?.data?.contractor_birthdays || []
    const contractorHolidays = getData?.data?.contractor_holidays || []
    const timeOffsRequests = getData?.data?.pending_time_offs || []

    const concatArrays = payrollDays?.concat(
      contractorHolidays,
      timeOffsRequests,
      clientHolidays,
      timeOffs,
      contractorBirthdays,
    )

    const getColor = (t) => {
      let colors = { bgColor: '#f0f3fe', textColor: '#114EF7' }
      if (t === 'Approved') {
        colors = { bgColor: '#f0f3fe', textColor: '#114EF7' }
      } else if (t === 'Pending') {
        colors = { bgColor: '#e8e9eb', textColor: '#0F1035' }
      }

      return colors
    }

    const newdata = concatArrays
      ?.filter((el, i) => {
        return el !== undefined
      })
      .map((item, i) => ({
        id: i,
        calendarId: i,
        category: 'time',
        title: item?.title,
        type: item?.type,
        dueDateClass: item,
        raw: item,
        location: item?.location,
        body: item?.type,
        customStyle: item?.type,
        start: moment(item?.start_date).format(),
        end: moment(item?.end_date)
          .add(['Pending', 'Approved'].includes(item?.type) ? 24 : 12, 'hours')
          .format(),
        bgColor: getColor(item?.type).bgColor,
        borderColor: getColor(item?.type).textColor,
        color: getColor(item?.type).textColor,
      }))

    setSchedules(newdata)
  }, [checkPaydays, checkTimeOff, checkBirthday, checkHolidays, getData.data])

  const myTheme = {
    'common.border': '1px solid #e8e9eb',
    'common.backgroundColor': 'white',
    'common.holiday.color': 'rgba(51, 51, 51, 1)',
    'common.saturday.color': 'rgba(51, 51, 51, 1)',
    'common.dayname.color': 'rgba(51, 51, 51, 1)',
    'common.today.color': 'rgba(51, 51, 51, 1)',

    // creation guide style
    'common.creationGuide.backgroundColor': '#c3cbf7',
    'common.creationGuide.border': '1px solid #114EF7',

    // month header 'dayname'
    'month.dayname.height': '31px',
    'month.dayname.borderLeft': '0',
    'month.dayname.paddingLeft': '10px',
    'month.dayname.paddingRight': '10px',
    'month.dayname.backgroundColor': '#e8e9eb',
    'month.dayname.fontSize': '12px',
    'month.dayname.fontWeight': 'normal',
    'month.dayname.textAlign': 'center',

    // month day grid cell 'day'
    'month.holidayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
    'month.dayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
    'month.weekend.backgroundColor': 'inherit',
    'month.day.fontSize': '14px',

    // month schedule style
    'month.schedule.borderRadius': '2px',
    'month.schedule.height': '25px',
    'month.schedule.marginTop': '2px',
    'month.schedule.marginLeft': '12px',
    'month.schedule.marginRight': '8px',

    // month more view
    'month.moreView.border': '1px solid #d5d5d5',
    'month.moreView.boxShadow': '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
    'month.moreView.backgroundColor': 'white',
    'month.moreView.paddingBottom': '17px',
    'month.moreViewTitle.height': '44px',
    'month.moreViewTitle.marginBottom': '12px',
    'month.moreViewTitle.backgroundColor': 'inherit',
    'month.moreViewTitle.borderBottom': 'none',
    'month.moreViewTitle.padding': '12px 17px 0 17px',
    'month.moreViewList.padding': '0 17px',

    // week header 'dayname'
    'week.dayname.height': '42px',
    'week.dayname.borderTop': '1px solid #e5e5e5',
    'week.dayname.borderBottom': '1px solid #e5e5e5',
    'week.dayname.borderLeft': 'inherit',
    'week.dayname.paddingLeft': '0',
    'week.dayname.backgroundColor': 'inherit',
    'week.dayname.textAlign': 'left',
    'week.today.color': '#333',
    'week.pastDay.color': '#bbb',

    // week vertical panel 'vpanel'
    'week.vpanelSplitter.border': '1px solid #e5e5e5',
    'week.vpanelSplitter.height': '3px',

    // week daygrid 'daygrid'
    'week.daygrid.borderRight': '1px solid #e5e5e5',
    'week.daygrid.backgroundColor': 'inherit',

    'week.daygridLeft.width': '72px',
    'week.daygridLeft.backgroundColor': 'inherit',
    'week.daygridLeft.paddingRight': '8px',
    'week.daygridLeft.borderRight': '1px solid #e5e5e5',

    'week.today.backgroundColor': '#f4f5f7',
    'week.weekend.backgroundColor': 'inherit',
    'common.today.backgroundColor': '#000',

    // week timegrid 'timegrid'
    'week.timegridLeft.width': '72px',
    'week.timegridLeft.backgroundColor': 'inherit',
    'week.timegridLeft.borderRight': '1px solid #e5e5e5',
    'week.timegridLeft.fontSize': '11px',
    'week.timegridLeftTimezoneLabel.height': '40px',
    'week.timegridLeftAdditionalTimezone.backgroundColor': 'white',

    'week.timegridOneHour.height': '52px',
    'week.timegridHalfHour.height': '26px',
    'week.timegridHalfHour.borderBottom': 'none',
    'week.timegridHorizontalLine.borderBottom': '1px solid #e5e5e5',

    'week.timegrid.paddingRight': '8px',
    'week.timegrid.borderRight': '1px solid #e5e5e5',
    'week.timegridSchedule.borderRadius': '2px',
    'week.timegridSchedule.paddingLeft': '2px',

    'week.currentTime.color': '#114EF7',
    'week.currentTime.fontSize': '11px',
    'week.currentTime.fontWeight': 'normal',

    'week.pastTime.color': '#bbb',
    'week.pastTime.fontWeight': 'normal',

    'week.futureTime.color': '#333',
    'week.futureTime.fontWeight': 'normal',

    'week.currentTimeLinePast.border': '1px dashed #114EF7',
    'week.currentTimeLineBullet.backgroundColor': '#114EF7',
    'week.currentTimeLineToday.border': '1px solid #e5e5e5',
    'week.currentTimeLineFuture.border': 'none',

    // week creation guide style
    'week.creationGuide.color': '#114EF7',
    'week.creationGuide.fontSize': '11px',
    'week.creationGuide.fontWeight': 'bold',

    // week daygrid schedule style
    'week.dayGridSchedule.borderRadius': '2px',
    'week.dayGridSchedule.height': '24px',
    'week.dayGridSchedule.marginTop': '2px',
    'week.dayGridSchedule.marginLeft': '8px',
    'week.dayGridSchedule.marginRight': '8px',
  }
  const getTimeTemplate = (schedule, isAllDay) => {
    let title = schedule.title
    let icon = ''
    const flag = schedule?.raw?.flag
    if (schedule.raw?.type === 'paydays') {
      title = 'Payday'
      icon = `<img style="width: 17px;margin-right: 6px;" src=${dollar} />`
    } else if (schedule.raw?.type === 'Holiday') {
      title = schedule?.raw?.title
      icon = `<img style="width: 1rem; height: 1rem; border-radius: 0.5rem; margin-right: 10px; object-fit: cover;" alt="Afghanistan" src=/${flag}>`
    } else if (schedule.raw?.type === 'Birthday') {
      icon = `<img style="width: 17px;margin-right: 6px;" src=${gift} />`
    } else if (schedule.raw?.type === 'Approved') {
      title = schedule?.raw?.title
    }
    return `<span class="d-flex align-items-center pt-2" >${icon} <h6 style="white-space: nowrap;font-size: 12px;color:#1c1c1c" class="mb-0 ">${title}</h6></span>`
  }

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance()
    calendarInstance.next()
    handleDateChange(calendarInstance)
  }

  const handleClickPervButton = () => {
    const calendarInstance = calendarRef.current.getInstance()
    calendarInstance.prev()

    handleDateChange(calendarInstance)
  }

  const handleDateChange = (calendarInstance) => {
    const thisDate = calendarInstance.getDate()
    const startDate = calendarInstance.getDateRangeStart()
    const endDate = calendarInstance.getDateRangeEnd()

    setStartDate(startDate)
    setEndDate(endDate)
    setThisMonth(new Date(thisDate))
  }

  const handleClickToDayButton = () => {
    const calendarInstance = calendarRef.current.getInstance()
    calendarInstance.today()
    handleDateChange(calendarInstance)
  }

  function onBefore(event) {
    const schedule = event.schedule
    document
      .querySelectorAll('.tui-full-calendar-popup-arrow-border')
      .forEach((el) => el.remove())

    if (
      schedule?.raw?.type === 'Approved' ||
      schedule?.raw?.type === 'Pending'
    ) {
      const status = schedule?.raw?.type
      document.querySelector('.tui-full-calendar-schedule-title').innerHTML =
        `<div class="tui-full-calendar-schedule-title">
<a target="_blank" href="/contract/detail?id=${schedule?.raw?.contract_ref}&tab"> ${schedule?.raw?.name}</a></div>`
      document.querySelector('.tui-full-calendar-popup-detail-date').innerHTML =
        `<p class="rp-font-bold">${schedule?.raw?.description}</p>`
      document.querySelector('.tui-full-calendar-popup-detail-item').innerHTML =
        ''
      document.querySelector(
        '.tui-full-calendar-popup-detail-item-separate',
      ).innerHTML = `<span class="font-size-12 rounded bg-soft-${
        status === 'Pending' ? 'warning' : 'success'
      } badge badge-white badge-pill">
           <p class="   mb-0   text-${
             status === 'Pending' ? 'warning' : 'success'
           } " style="padding: 3px 2px;">${status}</p>
           </span>`
    }

    if (schedule?.raw?.type === 'Holiday') {
      document
        .querySelectorAll('.tui-full-calendar-popup-detail-date')
        .forEach((el) => el.remove())
    }

    const happyIcon = `<img style="width: 26px;  margin-left: 11px;" src=${happy} />`

    if (schedule?.raw?.type === 'Birthday') {
      document.querySelector('.tui-full-calendar-schedule-title').innerHTML =
        `<div class="tui-full-calendar-schedule-title d-flex  align-items-end">${
          schedule?.raw?.title
        }
           ${user?.type !== 'client' ? happyIcon : ''}
           </div>`
      document
        .querySelectorAll('.tui-full-calendar-popup-detail-date')
        .forEach((el) => el.remove())
      document
        .querySelectorAll('.tui-full-calendar-popup-detail-item')
        .forEach((el) => el.remove())
    }

    if (schedule?.raw?.type === 'paydays') {
      let innerHTMLContent = `<div style="font-size: 0.75rem;font-weight: 400;margin-bottom: 0.325rem;">Payday: ${schedule.raw.start_date}</div>`

      for (let pas = 0; pas < schedule?.raw?.items.length; pas++) {
        innerHTMLContent += `<div>
        <a target="_blank" href="/contract/detail?id=${schedule?.raw?.items?.[pas]?.contract_ref}">${schedule?.raw?.items?.[pas]?.contractor_name}</a>
             </div>`
      }

      document.querySelector('.tui-full-calendar-schedule-title').innerHTML =
        innerHTMLContent
      document
        .querySelectorAll('.tui-full-calendar-popup-detail-item')
        .forEach((el) => el.remove())
      document
        .querySelectorAll('.tui-full-calendar-popup-detail-date')
        .forEach((el) => el.remove())
    }
  }
  const isMobile = useResize()

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <Menu
        onClose={() => {
          dispatch(updateShowCalender(false))
          hide()
        }}
        className='bg-white'
        isOpen={show}
        width={isMobile ? '100%' : '83%'}
        right
      >
        <div
          className='p-2 p-md-4'
          style={{
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: 10,
            minWidth: '100%',
            maxWidth: '100%',
          }}
        >
          {!!getData.isLoading && (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{
                background: 'rgb(255,255,255,.5)',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 9,
              }}
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </div>
          )}
          <Row className='mb-4 mt-2'>
            <Col className='col-12 d-flex align-items-center justify-content-between'>
              <div style={{ flex: 1 }}></div>
              <div
                style={{ flex: 4 }}
                className='d-flex align-items-center justify-content-between'
              >
                <div className='d-flex align-items-center justify-content-start'>
                  <a
                    href='#'
                    onClick={() => handleClickToDayButton()}
                    style={{
                      border: '1px solid #114EF7',
                      padding: '4px 10px',
                      borderRadius: 3,
                      color: '#fff',
                      background: '#114EF7',
                    }}
                  >
                    Today
                  </a>
                  <h4 className='ml-3 mb-0 mr-3'>
                    {moment(thisMonth).format('MMMM YYYY')}
                  </h4>
                  <a
                    href='#'
                    style={{ width: 25, height: 25 }}
                    onClick={() => handleClickPervButton()}
                  >
                    <img src={arrowLeft} alt='' />
                  </a>
                  <a
                    href='#'
                    style={{ width: 25, height: 25 }}
                    onClick={() => handleClickNextButton()}
                  >
                    <img src={arrowRight} alt='' />
                  </a>
                </div>
                <div>
                  <a
                    onClick={hide}
                    style={{ width: 20, height: 20, display: 'block' }}
                  >
                    <img src={close} alt='' />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <div
            className='mobileContainer d-flex justify-content-between'
            style={{
              overflow: isMobile ? 'auto' : 'initial',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ flex: 1 }}>
              <h4 className='mb-4 mt-1'>Calendars</h4>
              <div
                className='d-flex align-items-center mb-3'
                style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
              >
                <input
                  checked={checkTimeOff}
                  onChange={(event) => setCheckTimeOff(event.target.checked)}
                  style={{ transform: 'scale(1.3)' }}
                  type='checkbox'
                />
                <label className='mb-0'>Approved time off</label>
              </div>
              <div
                className='d-flex align-items-center mb-3'
                style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
              >
                <input
                  checked={checkTimeOffRequest}
                  onChange={(event) =>
                    setCheckTimeOffRequest(event.target.checked)
                  }
                  style={{ transform: 'scale(1.3)' }}
                  type='checkbox'
                />
                <label className='mb-0'>Pending time off</label>
              </div>
              {showPaydays && (
                <div
                  className='d-flex align-items-center mb-3'
                  style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
                >
                  <input
                    checked={checkPaydays}
                    onChange={(event) => setCheckPaydays(event.target.checked)}
                    style={{ transform: 'scale(1.3)' }}
                    type='checkbox'
                  />
                  <label className='mb-0'>Paydays</label>
                </div>
              )}
              {user?.type === 'client' && (
                <div
                  className='d-flex align-items-center mb-3'
                  style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
                >
                  <input
                    checked={checkBirthday}
                    onChange={(event) => setCheckBirthday(event.target.checked)}
                    style={{ transform: 'scale(1.3)' }}
                    type='checkbox'
                  />
                  <label className='mb-0'>Birthdays</label>
                </div>
              )}
              <div
                className='d-flex align-items-center mb-3'
                style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
              >
                <input
                  checked={clientHolidays}
                  onChange={(event) => setClientHolidays(event.target.checked)}
                  style={{ transform: 'scale(1.3)' }}
                  type='checkbox'
                />
                <label className='mb-0'>Company holidays</label>
              </div>
              <div
                className='d-flex align-items-center mb-3'
                style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
              >
                <input
                  checked={checkHolidays}
                  onChange={(event) => setCheckHolidays(event.target.checked)}
                  style={{ transform: 'scale(1.3)' }}
                  type='checkbox'
                />
                <label className='mb-0'>
                  {' '}
                  {user?.type === 'client' ? 'Team holidays' : 'My holidays'}
                </label>
              </div>
              <h4 className='mb-4 mt-5'>Legends</h4>
              <div
                className='mb-2 font-size-12 bg-soft-primary badge badge-white badge-pill'
                style={{
                  borderLeft: '3px solid #114EF7',
                  borderRadius: '2px',
                  display: 'table',
                }}
              >
                <p
                  className='mb-0 text-primary'
                  style={{
                    padding: '4px 2px',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  Approved Time Off
                </p>
              </div>
              <div
                className='mb-2 font-size-12 badge badge-white badge-pill'
                style={{
                  borderLeft: '3px solid #0F1035',
                  borderRadius: '2px',
                  display: 'table',
                  backgroundColor: '#e8e9eb',
                }}
              >
                <p
                  className='mb-0'
                  style={{
                    padding: '4px 2px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#0F1035',
                  }}
                >
                  Pending Time off Request
                </p>
              </div>
              <div className='mt-4'>
                <p
                  className='mb-2 d-flex align-items-center'
                  style={{ gap: 8 }}
                >
                  <img
                    width={18}
                    style={{ marginTop: '-4px' }}
                    src={gift}
                    alt=''
                  />{' '}
                  Birthday
                </p>
                <p
                  className='mb-2 d-flex align-items-center'
                  style={{ gap: 8 }}
                >
                  <img width={18} src={dollar} alt='' /> Payday
                </p>
              </div>
            </div>
            <div style={{ flex: 4 }}>
              <Calendar
                theme={myTheme}
                ref={calendarRef}
                height='100%'
                disableDblClick={true}
                disableClick={false}
                isReadOnly={true}
                month={{ visibleScheduleCount: 4 }}
                schedules={schedules}
                scheduleView
                taskView
                onClickSchedule={onBefore}
                useDetailPopup={true}
                useCreationPopup
                view='month'
                week={{
                  showTimezoneCollapseButton: true,
                  timezonesCollapsed: true,
                }}
                template={{
                  monthGridHeaderExceed: function (hiddenSchedules) {
                    return `<span style="color:#114EF7" class="weekday-grid-more-schedules">+ ${hiddenSchedules} more</span>`
                  },
                  time: function (schedule) {
                    return getTimeTemplate(schedule, false)
                  },
                }}
              />
            </div>
          </div>
          <Row>
            <Col className='col-12 d-flex align-items-center justify-content-between'>
              <div style={{ flex: 1 }}></div>
              <div style={{ flex: 4 }}>
                <p
                  style={{
                    color: '#777F9E',
                    marginTop: '1rem',
                    fontSize: 11,
                    fontStyle: 'italic',
                  }}
                >
                  Disclaimer: Holidays calendar is designed for informational
                  purposes and may not include all major holidays. Some dates of
                  holidays may vary from these displayed.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Menu>
    </div>
  )
}

export default Index
