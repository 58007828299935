import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverBody } from 'reactstrap'

import styles from './contract-ids-cell.module.scss'

export function ContractIDsCell({ cellData, rowData }) {
  if (!cellData) return <span className='px-2'>N/A</span>

  if (!cellData?.length) return <span className='px-2'>N/A</span>

  const numberOfSlicedIds = 1

  return (
    <div
      className='overflow-auto d-flex gap-8'
      style={{ maxWidth: 300, padding: '0.75rem' }}
    >
      <IdsList ids={cellData.slice(0, numberOfSlicedIds).map((c) => c.ref)} />
      {cellData.length <= numberOfSlicedIds ? null : (
        <MoreIdsList
          slicedIds={numberOfSlicedIds}
          ids={cellData}
          unique={rowData?.transaction_id}
        />
      )}
    </div>
  )
}

function IdsList({ ids }) {
  return ids.map((id) => (
    <Link key={id} target='_blank' to={`/admin/contract-detail?id=${id}`}>
      {id}
    </Link>
  ))
}

function MoreIdsList({ ids, slicedIds = 2, unique }) {
  const [isListOpen, setIsListOpen] = useState(false)

  function toggleList() {
    setIsListOpen((v) => !v)
  }

  const uniqueKey = `more-contracts-${unique}`

  return (
    <>
      <button
        type='button'
        className='rp-btn-nostyle text-primary py-0 px-1 bg-surface-20 rounded'
        style={{ width: 70 }}
        onClick={toggleList}
        id={uniqueKey}
      >
        {isListOpen ? 'hide list' : `+${ids.length - slicedIds} more`}
      </button>

      <Popover
        flip
        isOpen={isListOpen}
        target={uniqueKey}
        popperClassName={styles.moreIdsPopover}
        hideArrow
      >
        <PopoverBody
          className='position-relative pb-3 pl-4.5 pr-3 overflow-auto'
          style={{ maxHeight: 140 }}
        >
          <button
            type='button'
            className='rp-btn-nostyle p-1 rounded-circle bg-soft-primary text-muted font-size-16 position-sticky d-flex'
            style={{ top: 0, transform: 'translate(-28px, 0px)' }}
            onClick={toggleList}
          >
            <i className='bx bx-x font-size-16' />
          </button>
          <div className='d-flex flex-column gap-6' style={{ marginTop: -18 }}>
            <IdsList ids={ids.slice(slicedIds).map((c) => c.ref)} />
          </div>
        </PopoverBody>
      </Popover>
    </>
  )
}
