import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from '../lib/utils/cn';
const colors = {
    default: {
        default: 'tw-bg-secondary-20 tw-text-secondary-110',
        inverted: 'tw-bg-secondary-110 tw-text-secondary-20',
    },
    green: {
        default: 'tw-bg-systemGreen-20 tw-text-systemGreen-110',
        inverted: 'tw-bg-systemGreen-110 tw-text-systemGreen-20',
    },
    red: {
        default: 'tw-bg-systemRed-20 tw-text-systemRed-110',
        inverted: 'tw-bg-systemRed-110 tw-text-systemRed-20',
    },
    yellow: {
        default: 'tw-bg-systemGold-20 tw-text-systemGold-110',
        inverted: 'tw-bg-systemGold-110 tw-text-systemGold-20',
    },
    primary: {
        default: 'tw-bg-primary-20 tw-text-primary-110',
        inverted: 'tw-bg-primary-110 tw-text-primary-20',
    },
};
export function Badge({ children, color = 'green', inverted = false, className, }) {
    return (_jsx("span", { className: cn('tw-rounded-full tw-px-2 tw-py-1 tw-text-xs tw-font-bold tw-uppercase', colors[color][inverted ? 'inverted' : 'default'], className), children: children }));
}
