import React from 'react'

import { cn } from 'ui'

export default function StyledTd(props) {
  const { children, colSpan, style, className = '' } = props
  return (
    <td
      style={style}
      colSpan={colSpan}
      className={cn(
        '!tw-p-4 tw-text-sm first:!tw-pl-6 last:!tw-pr-6',
        className,
      )}
    >
      {children}
    </td>
  )
}
