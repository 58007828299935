import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'

export function useTimeOffPermissionCheck() {
  const { hasAccess } = usePermissions()
  const canPerformAction = (action) => {
    switch (action) {
      case TIME_OFF_ACTIONS.CHANGE_POLICY:
      case TIME_OFF_ACTIONS.ADJUST_BALANCE:
      case TIME_OFF_ACTIONS.UNASSIGN_POLICY:
        return hasAccess(permissions.manageContractSettings)
      case TIME_OFF_ACTIONS.SUBMIT_TIME_OFF:
        return hasAccess(permissions.addTimeOff)
      case TIME_OFF_ACTIONS.VIEW:
        return hasAccess(permissions.viewTimeOffs)
      default:
        return false
    }
  }
  return { canPerformAction }
}

export const TIME_OFF_ACTIONS = {
  CHANGE_POLICY: 'change',
  ADJUST_BALANCE: 'adjust',
  UNASSIGN_POLICY: 'unassign',
  SUBMIT_TIME_OFF: 'submit',
  VIEW: 'view',
}
