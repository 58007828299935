import { PencilSimpleLine } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import IconButton from '../../../../components/ui/icon-button'
import { useFetch } from '../../../../helpers/hooks'
import { updateCompanyAdminJson } from '../../../../services/api'
import ControlledInput from '../../../../components/ControlledInputNew'
import Button from '../../../../components/ui/button'

export function ManageEorMarkupPercentage({ company, updateCompaniesList }) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => !open)
  }

  const { startFetch: updateData, isLoading } = useFetch({
    action: updateCompanyAdminJson,
    withAdminAccess: true,
    onComplete: () => {
      updateCompaniesList?.()
    },
  })

  function onSubmit(values) {
    updateData({
      company_id: values.company_id,
      eor_markup_percentage: values.eor_markup_percentage,
    })
  }

  return (
    <>
      <MarkupValueDisplay
        value={company?.eor_markup_percentage}
        onEdit={toggle}
      />

      {!isOpen ? null : (
        <MarkupPercentageModal
          company={company}
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={onSubmit}
          loading={isLoading}
        />
      )}
    </>
  )
}

export function MarkupValueDisplay({ value, onEdit }) {
  return (
    <div
      className='align-items-center border d-flex gap-12 position-relative pl-2 pr-1.5 py-1 rounded text-text-70'
      style={{ width: 'fit-content' }}
    >
      <div style={{ lineHeight: 1 }}>{value || 'N/A'}</div>

      <IconButton
        color='light'
        size={22}
        icon={<PencilSimpleLine size={18} color='currentColor' />}
        onClick={onEdit}
        className='text-current'
      />
    </div>
  )
}

export function getCompanyInfo(company) {
  return [
    { label: 'Company name', value: company.name },
    { label: 'dba', value: company.dba },
    { label: 'Company country', value: company.country?.name },
    { label: 'Currency', value: company.currency?.name },
  ]
}

export function InfoList({ items }) {
  return items.map(({ label, value }) => {
    return (
      <div key={label} className='d-flex'>
        <div className='text-text-70 text-capitalize' style={{ minWidth: 120 }}>
          {label}:
        </div>
        <div>{value ?? 'N/A'}</div>
      </div>
    )
  })
}

const markupPercentageFormId = 'eor-markup-percentage-form'
function MarkupPercentageModal({ company, onSubmit, isOpen, toggle, loading }) {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      eor_markup_percentage: company.eor_markup_percentage,
    },
    resolver: yupResolver(
      yup.object().shape({
        eor_markup_percentage: yup
          .number()
          .required('Markup is required')
          .positive('Must be a positive number')
          .typeError('Invalid numeric value'),
      }),
    ),
  })

  const companyInfo = getCompanyInfo(company)

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Markup Percentage</ModalHeader>
      <ModalBody>
        <div className='bg-surface-10 border mb-3 px-2.5 py-2 rounded'>
          <InfoList items={companyInfo} />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='d-flex flex-column gap-14'
          id={markupPercentageFormId}
        >
          <input
            type='hidden'
            {...register('company_id', { value: company?.id })}
          />

          <ControlledInput
            control={control}
            label='Markup Percentage'
            name='eor_markup_percentage'
            id='eor_markup_percentage'
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={markupPercentageFormId}
          disabled={loading}
          loading={loading}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}
