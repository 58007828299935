import React from 'react'
import PageHeading from '../../../components/ui/page-heading'

export default function Heading({ children, className }) {
  return (
    <PageHeading className={className}>
      <PageHeading.Title>{children}</PageHeading.Title>
    </PageHeading>
  )
}
