import React, { useState } from 'react'
import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps'

const geoUrl =
  'https://public-assets-rp.s3.eu-central-1.amazonaws.com/map/world-countries.json'

const defaultOptions = { fill: '#D8D8D8', stroke: '#FFF' }

export default function MapChart({
  markers = [],
  geoOptions = {},
  options = {},
  zoomable = false,
  renderMarkerTooltip,
}) {
  const mapOptions = { ...defaultOptions, ...geoOptions }

  return (
    <ComposableMap {...options} className={!zoomable ? null : 'cursor-grab'}>
      <WrapWithZoom zoomable={zoomable}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} {...mapOptions} />
            ))
          }
        </Geographies>

        <Markers markers={markers} renderMarkerTooltip={renderMarkerTooltip} />
      </WrapWithZoom>
    </ComposableMap>
  )
}

function WrapWithZoom({ children, zoomable }) {
  if (!zoomable) return children

  return <ZoomableGroup>{children}</ZoomableGroup>
}

function Markers({ markers, renderMarkerTooltip }) {
  const [annotationOpen, setAnnotationOpen] = useState(null)

  function showAnnotation(marker) {
    setAnnotationOpen(marker)
  }

  const x = annotationOpen?.coordinates[0]
  const y = annotationOpen?.coordinates[1]
  const dx = x < 10 ? -40 : x > 50 ? -180 : -80 // offset from marker
  const dy = y < -10 ? -130 : y < 0 ? 50 : x > 50 ? 0 : -10 // offset from marker

  return (
    <>
      {markers.map((m) => {
        const [x, y] = m?.coordinates ?? []

        if (!x || !y) return null
        if (typeof x !== 'number' || typeof y !== 'number') return null

        return (
          <MapMarker
            key={`${x}-${y}`}
            coordinates={[x, y]}
            onMouseOver={() => showAnnotation(m)}
            onMouseOut={() => showAnnotation(null)}
          />
        )
      })}

      {!annotationOpen || !renderMarkerTooltip ? null : (
        <Annotation
          subject={annotationOpen?.coordinates}
          dx={dx}
          dy={dy}
          connectorProps={{ stroke: 'transparent', strokeWidth: 0 }}
        >
          <foreignObject
            x={x}
            y={y}
            width={204}
            height={89}
            style={{ transform: 'scale(1.3)' }}
          >
            {renderMarkerTooltip?.(annotationOpen?.markerData)}
          </foreignObject>
        </Annotation>
      )}
    </>
  )
}

function MapMarker({ coordinates, onMouseOver, onMouseOut }) {
  return (
    <Marker
      coordinates={coordinates}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <circle r='14' fill='rgba(50, 90, 231, 0.26)' />
      <circle r='6' fill='var(--primary)' />
    </Marker>
  )
}
