import cx from 'classnames'
import React from 'react'
import { Card } from 'reactstrap'

export function CardsSection({ children, style, className }) {
  return (
    <Card
      className={cx('rp-shadow-2 !tw-mb-0 tw-items-center', className)}
      style={style}
    >
      {children}
    </Card>
  )
}

export function CardsSectionHeader({ children }) {
  return (
    <div
      className='d-flex align-items-center justify-content-between flex-wrap gap-4 pb-1 pt-3 px-4 w-100'
      style={{ minHeight: 60 }}
    >
      {children}
    </div>
  )
}

export function SectionHeading({ children, className }) {
  return <h3 className={cx('text-gray-h', className)}>{children}</h3>
}
