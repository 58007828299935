'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useToggleState } from '@react-stately/toggle';
import { useRef } from 'react';
import { VisuallyHidden, useFocusRing, useSwitch } from 'react-aria';
import { cn } from '../lib/utils/cn';
const getToggleColors = (variant) => {
    switch (variant) {
        case 'secondary':
            return {
                bgColorClass: 'tw-bg-secondary',
                focusColorClass: 'tw-outline-secondary-40 tw-ring-secondary-40',
            };
        case 'cyan':
            return {
                bgColorClass: 'tw-bg-cyan',
                focusColorClass: 'tw-outline-cyan-40 tw-ring-cyan-40',
            };
        case 'green':
            return {
                bgColorClass: 'tw-bg-green',
                focusColorClass: 'tw-outline-green-40 tw-ring-green-40',
            };
        case 'red':
            return {
                bgColorClass: 'tw-bg-red',
                focusColorClass: 'tw-outline-red-40 tw-ring-red-40',
            };
        case 'yellow':
            return {
                bgColorClass: 'tw-bg-yellow',
                focusColorClass: 'tw-outline-yellow-40 tw-ring-yellow-40',
            };
        case 'primary':
        default:
            return {
                bgColorClass: 'tw-bg-primary',
                focusColorClass: 'tw-outline-primary-40 tw-ring-primary-40',
            };
    }
};
function Toggle(_a) {
    var { className, labelLeft = false, isDisabled, color, isSelected, name } = _a, props = __rest(_a, ["className", "labelLeft", "isDisabled", "color", "isSelected", "name"]);
    const toggleProps = Object.assign({ isDisabled, isSelected }, props);
    const state = useToggleState(toggleProps);
    let ref = useRef(null);
    let { inputProps } = useSwitch(toggleProps, state, ref);
    let { isFocusVisible, focusProps } = useFocusRing();
    const { bgColorClass, focusColorClass } = getToggleColors(color);
    return (_jsxs("label", { className: cn('tw-inline-flex tw-items-center tw-gap-2', className), children: [labelLeft && props.children, _jsx(VisuallyHidden, { children: _jsx("input", Object.assign({ name: name }, inputProps, focusProps, { ref: ref })) }), _jsx("div", { className: cn('tw-rounded-full', isFocusVisible ? `${focusColorClass} tw-outline-2 tw-ring-2` : null), children: _jsx("div", { "aria-hidden": 'true', className: cn('tw-relative tw-h-6 tw-w-10 tw-rounded-full tw-border tw-transition tw-duration-200 tw-ease-linear', state.isSelected
                        ? `tw-border-transparent ${bgColorClass}`
                        : 'tw-border-secondary-40 tw-bg-transparent', isDisabled
                        ? state.isSelected
                            ? 'tw-opacity-40'
                            : ''
                        : 'tw-cursor-pointer'), children: _jsx("div", { className: cn('tw-absolute tw-top-[3px] tw-h-4 tw-w-4 tw-rounded-full tw-transition-transform tw-duration-200', state.isSelected
                            ? 'tw-translate-x-5 tw-bg-white'
                            : 'tw-translate-x-1 tw-bg-secondary-40') }) }) }), !labelLeft && props.children] }));
}
export { Toggle };
