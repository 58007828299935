import { useHistory } from 'react-router-dom'

import isNill from '../../utils/is-nill'

export function useUrlState({ name, defaultValue }) {
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)
  const value = searchParams.get(name) || defaultValue

  function stateChange(newValue, { deleteIfNil = true } = {}) {
    const searchParams = new URLSearchParams(history.location.search)

    if (deleteIfNil && isNill(newValue)) {
      searchParams.delete(name)
    } else {
      searchParams.set(name, newValue)
    }

    history.push({ ...history.location, search: searchParams.toString() })
  }

  return [value, stateChange]
}

export function useUrlStateV2(defaultValues, { replaceRoute = false } = {}) {
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)

  const value = Object.keys(defaultValues).reduce((acc, key) => {
    return {
      ...acc,
      [key]: searchParams.get(key) || defaultValues[key],
    }
  }, {})

  function stateChange(newValue, { deleteIfNil = true } = {}) {
    const searchParams = new URLSearchParams(history.location.search)

    Object.keys(newValue).forEach((key) => {
      if (deleteIfNil && isNill(newValue[key])) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, newValue[key])
      }
    })

    history[replaceRoute ? 'replace' : 'push']({
      ...history.location,
      search: searchParams.toString(),
    })
  }

  return { value, updater: stateChange }
}
