import React from 'react'
import { Link } from 'react-router-dom'

import logoDark from 'ui/assets/images/logo_main.png'
import globe from '../../assets/images/identity/symbol_main.png'

export function HeaderLogo() {
  return (
    <>
      <img
        src={globe}
        className='tw-h-6 tw-w-auto tw-object-contain md:tw-hidden'
        alt='RemotePass logo'
        aria-hidden
      />
      <img
        src={logoDark}
        className='tw-hidden tw-h-6 tw-w-auto tw-object-contain md:tw-block'
        alt='RemotePass logo'
        aria-hidden
      />
      <span className='tw-sr-only'>RemotePass logo</span>
    </>
  )
}

export default function HeaderLogoLink() {
  return (
    <Link to='/' className='tw-ml-6'>
      <HeaderLogo />
    </Link>
  )
}
