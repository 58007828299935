import {
  NETSUITE_UPDATE_PRE_DATA,
  NETSUITE_SUBMIT_ACCOUNT_MAPPING,
  NETSUITE_UPDATE_CONTRACTOR,
  NETSUITE_UPDATE_REQUEST,
  NETSUITE_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  NETSUITE_UPDATE_ORGANIZATIONS,
  NETSUITE_UPDATE_ORGANIZATION,
  NETSUITE_INIT,
} from './actionTypes'

export const updateNetSuitePreData = (payload) => {
  return {
    type: NETSUITE_UPDATE_PRE_DATA,
    payload,
  }
}

export const updateNetSuiteOrganizations = (payload) => {
  return {
    type: NETSUITE_UPDATE_ORGANIZATIONS,
    payload,
  }
}

export const updateNetSuiteOrganization = (payload) => {
  return {
    type: NETSUITE_UPDATE_ORGANIZATION,
    payload,
  }
}
export const initNetSuite = () => {
  return {
    type: NETSUITE_INIT,
  }
}

export const submitNetSuiteAccountMapping = (payload) => {
  return {
    type: NETSUITE_SUBMIT_ACCOUNT_MAPPING,
    payload,
  }
}

export const updateNetSuiteContractor = (payload) => {
  return {
    type: NETSUITE_UPDATE_CONTRACTOR,
    payload,
  }
}

export const updateNetSuiteRequest = (payload) => {
  return {
    type: NETSUITE_UPDATE_REQUEST,
    payload,
  }
}
export const submitNetSuiteAccountMappingAndUpdateRequest = (payload) => {
  return {
    type: NETSUITE_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
    payload,
  }
}
