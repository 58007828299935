import { X } from '@phosphor-icons/react'
import React from 'react'
import { Modal } from 'reactstrap'

import deOnboardIllustration from '../../../../assets/images/de-onboard-illustration.svg'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'

export default function OnboardToDeModal({ isOpen, toggle }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
      contentClassName='flex-row'
    >
      <div style={{ flex: 1 }}>
        <img
          alt=''
          aria-hidden='true'
          src={deOnboardIllustration}
          className='w-100 h-100'
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className='flex-grow-1 p-4' style={{ flex: 1 }}>
        <div className='d-flex justify-content-between align-items-start mb-4'>
          <BadgeX className='uppercase' pill color='primary' size='sm'>
            New
          </BadgeX>

          <X size={24} onClick={toggle} className='cursor-pointer' />
        </div>
        <div className='mb-5 text-gray-h' style={{ lineHeight: '22px' }}>
          <h2 style={{ fontSize: 32 }}>Direct Employee</h2>

          <p className='font-size-16'>
            Now on RemotePass, you can directly onboard and manage full time
            employees who are registered under your entity
          </p>

          <ul
            className='font-size-14 text-text-80'
            style={{ lineHeight: '1.375rem' }}
          >
            <li style={{ marginBottom: '0.75rem' }}>
              Onboard employees hired under your entity
            </li>
            <li style={{ marginBottom: '0.75rem' }}>
              Streamline HR management, from onboarding to off-boarding
            </li>
            <li style={{ marginBottom: '0.75rem' }}>
              Instantly generate and sign compliant employment contracts
            </li>
            <li style={{ marginBottom: '0.75rem' }}>
              Issue salary certificates, NDAs, and NOCs directly via the app
            </li>
            <li style={{ marginBottom: '0.75rem' }}>
              Automate payroll, pay slips and end of service calculation
            </li>
            <li style={{ marginBottom: '0.75rem' }}>Setup approval flows</li>
            <li style={{ marginBottom: '0.75rem' }}>
              Manage expenses, equipments and time off
            </li>
            <li style={{ marginBottom: '0.75rem' }}>
              Advanced analytics and reporting to streamline operations
            </li>
          </ul>
        </div>
        <Button onClick={toggle}>Continue</Button>
      </div>
    </Modal>
  )
}
