import React from 'react'
import { useSelector } from 'react-redux'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import ContractorDoc from './ContractorDocs'
import { ClientDocuments } from './client-documents'
import DocumentsDeEmployee from './documents-de-employee'

export function Documents() {
  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const isClient = user?.type === userTypes.COMPANY

  if (isClient) {
    return <ClientDocuments />
  }

  if (isDeEmployee) {
    return <DocumentsDeEmployee />
  }

  return <ContractorDoc />
}
