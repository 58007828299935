import {
  CheckSquareOffset,
  DownloadSimple,
  IdentificationCard,
  Paperclip,
  SealCheck,
  ShieldCheck,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import Shimmer from '../../../components/ui/shimmer'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { getFlagUrl } from '../../../helpers/config'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { downloadFile, getDocumentRequestTypes } from '../../../services/api'
import {
  getBackgroundCheck,
  getBackgroundCheckReportUrl,
  getDocumentInfo,
} from '../../../services/api-compliance'
import openFileV2 from '../../../utils/file/open-v2'
import { DATE_FORMAT } from '../../../utils/formatters/date-picker-date-format'
import { getFullName } from '../../../utils/get-full-name'
import { ContractBadge } from '../../Contract/ContractDetail/contract-timeline'
import { GenerateDocumentModal } from '../../Contract/ContractPage/de-documents-tab'
import { RequestFormModal } from '../../Contract/ContractPage/documents-request-form-modal'
import {
  ContractorHeader,
  DetailsInfoList,
} from '../../review-center/review-layout-details-components'
import { BackgroundCheckSection } from './request-check-section'
import { useReviewContext } from './review-documents-columns'

export function ReviewDocumentDetails() {
  const { activeItem } = useReviewContext()

  return (
    <>
      {activeItem ? (
        <RenderDocumentItem />
      ) : (
        <div className='tw-flex tw-h-full tw-items-center tw-justify-center tw-text-text-80'>
          Select an item to review
        </div>
      )}
    </>
  )
}

function RenderDocumentItem() {
  const { activeItem, isSelecting } = useReviewContext()
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()

  const isActiveDeContract = activeItem?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const {
    data: documentInfo,
    isLoading: documentInfoLoading,
    startFetch: getDocumentInfoAction,
  } = useFetch(
    {
      action: getDocumentInfo,
      autoFetch: !!activeItem?.id,
      body: { contract_id: activeItem?.id },
    },
    [activeItem?.id],
  )

  const {
    data: backgroundCheck,
    isLoading: backgroundCheckLoading,
    startFetch: getBackgroundCheckAction,
    setData,
  } = useFetch(
    {
      action: getBackgroundCheck,
      autoFetch: !!activeItem?.id && user?.type === userTypes.COMPANY,
      body: { userId: activeItem?.contractor?.id },
      onError: () => {
        setData(null)
      },
    },
    [user?.id, activeItem?.contractor?.id],
  )

  const { data: docTypes } = useFetch({
    action: getDocumentRequestTypes,
    autoFetch: true,
    body: { contract_id: activeItem?.id },
  })

  const docTypeOptions = useMemo(() => {
    return (
      docTypes?.map((e) => {
        return { label: e.name, value: e.id }
      }) ?? []
    )
  }, [docTypes])

  const documentItems =
    documentInfo?.documents?.map((document) => {
      return {
        label: document.name,
        value: <DocumentStatusBadge document={document} />,
      }
    }) ?? []

  return (
    <>
      <ContractorHeader
        contractId={activeItem?.ref}
        name={getFullName(activeItem?.contractor)}
        photo={activeItem?.contractor?.photo}
        flag={getFlagUrl(activeItem?.contractor?.flag)}
        flagIsAbsolute
      />

      <h4 className='tw-z-[1] tw-mb-1 tw-mt-6 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-medium'>
        <IdentificationCard
          className='tw-text-text-60'
          size={20}
          weight='fill'
        />
        <span>Contract</span>
      </h4>

      <DetailsInfoList
        className='-tw-my-6'
        items={[
          {
            label: 'Status',
            value: (
              <ContractBadge
                amended={activeItem?.amended}
                status={activeItem?.status}
                lastTimeline={
                  activeItem?.timeline?.[activeItem?.timeline?.length - 1]
                }
              />
            ),
          },
          {
            label: 'Start date',
            value: format(
              new Date(activeItem.start_date),
              DATE_FORMAT.FORMAT_1,
            ),
          },
        ]}
      />

      {isSelecting || documentInfoLoading || backgroundCheckLoading ? (
        backgroundCheckLoading ? (
          <div className='tw-p-6'>
            <Shimmer height={24} width='40%' className='tw-mb-4' />
            <Shimmer width='100%' className='tw-mb-3' />
            <Shimmer width='100%' />
          </div>
        ) : null
      ) : (
        ![
          CONTRACT_STATUS.PENDING_INVITE.value,
          CONTRACT_STATUS.CANCELLED.value,
          CONTRACT_STATUS.ENDED.value,
          CONTRACT_STATUS.TERMINATED.value,
        ].includes(activeItem.status.id) &&
        activeItem?.contractor.first_name &&
        hasAccess(permissions.ViewContractsDocuments) && (
          <>
            <div className='tw-z-[1] tw-mb-1 tw-mt-6 tw-flex tw-flex-wrap tw-justify-between tw-gap-2 tw-px-6'>
              <h4 className='tw-mb-0 tw-flex tw-items-center tw-gap-1 tw-text-base tw-font-medium'>
                <SealCheck
                  className='tw-shrink-0 tw-text-text-60'
                  size={20}
                  weight='fill'
                />
                <span>Background check</span>
              </h4>

              {backgroundCheck?.userActivityLog?.report?.status !==
              'DONE' ? null : (
                <DownloadReport
                  applicantId={backgroundCheck?.applicant?.id}
                  userId={backgroundCheck?.applicant?.userId}
                />
              )}
            </div>

            <BackgroundCheckSection
              activeItem={activeItem}
              onCheckRequestSuccess={() => {
                getBackgroundCheckAction({ userId: activeItem?.contractor?.id })
              }}
              backgroundCheck={backgroundCheck}
            />
          </>
        )
      )}

      {documentInfoLoading ? (
        <div className='tw-p-6'>
          <Shimmer height={24} width='40%' className='tw-mb-4' />
          <Shimmer width='100%' className='tw-mb-3' />
          <Shimmer width='100%' />
        </div>
      ) : (
        <>
          <h4 className='tw-z-[1] tw-mb-1 tw-mt-6 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-medium'>
            <Paperclip className='tw-text-text-60' size={20} weight='fill' />
            <span>Documents</span>
          </h4>

          <DetailsInfoList className='-tw-my-6' items={documentItems} />

          <h4 className='tw-z-[1] tw-mb-1 tw-mt-6 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-medium'>
            <CheckSquareOffset
              className='tw-text-text-60'
              size={20}
              weight='fill'
            />
            <span>Compliance</span>
          </h4>

          <DetailsInfoList
            className='-tw-mt-6'
            items={[
              {
                label: 'KYC Status',
                value: (
                  <ComplianceBadge
                    status={documentInfo?.compliance?.kyc_status}
                  />
                ),
              },
              {
                label: 'Date of verification',
                value: !documentInfo?.compliance?.kyc_verified_at
                  ? null
                  : format(
                      new Date(
                        documentInfo?.compliance?.kyc_verified_at * 1000,
                      ),
                      DATE_FORMAT.FORMAT_1,
                    ),
              },
            ]}
          />
        </>
      )}

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-10 tw-mt-auto tw-grid tw-gap-2 tw-border-t tw-border-surface-30 tw-bg-white tw-p-6 sm:tw-grid-cols-2'>
          {!isActiveDeContract || !docTypeOptions?.length ? (
            <div></div>
          ) : (
            <GenerateDocumentModal
              contract={{
                id: activeItem?.id,
                type: activeItem?.type,
              }}
              refreshDocs={() =>
                getDocumentInfoAction({ contract_id: activeItem?.id })
              }
              docTypeOptions={docTypeOptions}
              buttonProps={{ outline: true }}
            />
          )}

          <RequestDocumentAction
            contractId={activeItem?.id}
            onSuccess={() => {
              getDocumentInfoAction({ contract_id: activeItem?.id })
            }}
          />
        </div>
      )}
    </>
  )
}

function ComplianceBadge({ status }) {
  switch (status) {
    case 'verified': {
      return (
        <BadgeV2
          className='tw-uppercase'
          rightIcon={<ShieldCheck size={20} weight='fill' />}
          status='primary'
        >
          {status}
        </BadgeV2>
      )
    }
    case 'submitted': {
      return (
        <BadgeV2
          className='tw-uppercase'
          rightIcon={<ShieldCheck size={20} weight='fill' />}
          status='warning'
        >
          {status}
        </BadgeV2>
      )
    }
    default: {
      return (
        <BadgeV2
          className='tw-uppercase'
          rightIcon={<ShieldCheck size={20} weight='fill' />}
          status='secondary'
        >
          {status}
        </BadgeV2>
      )
    }
  }
}

function DocumentStatusBadge({ document }) {
  const status = document?.status
  switch (status) {
    case 'Requested': {
      return (
        <BadgeV2 className='tw-uppercase' status='warning'>
          {status}
        </BadgeV2>
      )
    }
    case 'Completed': {
      return document?.link ? (
        <DownloadDocument document={document} />
      ) : (
        <BadgeV2 className='tw-uppercase' status='success'>
          {status}
        </BadgeV2>
      )
    }
    default: {
      return (
        <BadgeV2 className='tw-uppercase' status='secondary'>
          {status}
        </BadgeV2>
      )
    }
  }
}

function DownloadDocument({ document }) {
  const { startFetch: downloadDoc, isLoading: downloadingDoc } = useFetch({
    action: downloadFile,
    onComplete: (data) => {
      const name = `${document?.name}.${data?.type?.split('/')[1]}`

      openFileV2(data, { name, download: true })
    },
  })

  return (
    <Button
      color='link'
      className='!tw-p-0'
      iconRight={<DownloadSimple size={20} />}
      onClick={() => downloadDoc(document)}
      loading={downloadingDoc}
      disabled={downloadingDoc}
    >
      Download
    </Button>
  )
}

function RequestDocumentAction({ contractId, onSuccess }) {
  const [open, setOpen] = useState(false)
  const { hasAccess } = usePermissions()

  return (
    <>
      <Button
        type='button'
        onClick={() => setOpen(true)}
        disabled={!hasAccess(permissions.RequestContractsDocuments)}
        id='check'
      >
        Request document
      </Button>
      {!hasAccess(permissions.RequestContractsDocuments) && (
        <UncontrolledTooltip target='check'>
          You do not have permission to manage documents. Please contact your
          administrator.
        </UncontrolledTooltip>
      )}

      {!open ? null : (
        <RequestFormModal
          show={open}
          hide={() => setOpen(false)}
          contractId={contractId}
          onUpdate={onSuccess}
        />
      )}
    </>
  )
}

export function DownloadReport({
  applicantId,
  userId,
  color = 'link',
  label = 'Download Report',
  iconRight = <DownloadSimple size={20} />,
  className,
}) {
  const { startFetch: getReportUrl, isLoading: downloadingReport } = useFetch({
    action: getBackgroundCheckReportUrl,
    onComplete: (data) => {
      openInNewTab(data.url)
    },
  })

  return (
    <Button
      color={color}
      className={cn('!tw-p-0', className)}
      iconRight={iconRight}
      onClick={() => getReportUrl({ applicantId, userId })}
      disabled={downloadingReport}
      loading={downloadingReport}
    >
      {label}
    </Button>
  )
}

function openInNewTab(url) {
  window.open(url, '_blank').focus()
}
