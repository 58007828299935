export const defaultNumberLocal = 'en-US'

export function getNumberFormatter({
  locale = defaultNumberLocal,
  options = {},
} = {}) {
  return {
    format: (value) => {
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      }).format(Number(value || 0))
    },
  }
}
