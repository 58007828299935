import { HourglassLow, ShieldCheck, ShieldWarning } from '@phosphor-icons/react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  Col,
  NavItem,
  Row,
  Spinner,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import backgroundBlue from '../../assets/images/bkblue.svg'
import ChangePassword from '../../components/CommonForBoth/ChangePassword'
import { PageNav } from '../../components/page-nav'
import BadgeX from '../../components/Table/BadgeX'
import { StyledH3 } from '../../components/Typo'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import TabContent from '../../components/ui/tabs'
import { BE_CONTRACT_CATEGORY, contractorTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  getCompanyInfo,
  getContractorInfo,
  savePayoneerAccount,
  updateContractorProfile,
} from '../../services/api'
import { updateUserProfileInfo } from '../../store/profile/actions'
import CompanyInfo from '../CompanySetting/CompanyInfo'
import ManageAvatar from '../CompanySetting/manage-avatar'
import WithdrawalMethod from '../Withdrawal/WithdrawalMethod'
import Kyc from './KYC'
import ProfileInfoNew from './ProfileInfoNew'

function ContractorSettings(props) {
  const [activeTab, setActiveTab] = useState('1')
  const formRef = useRef(null)
  const companyFormRef = useRef(null)

  const user = useSelector((state) => state?.Account?.user)

  const userProfile = useSelector(
    (state) => state?.userProfile?.userProfile ?? {},
  )
  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()
  const payeeId = new URLSearchParams(location.search).get('payee_id')

  const { startFetch: savePayoneer } = useFetch({ action: savePayoneerAccount })

  useEffect(() => {
    if (payeeId) {
      setActiveTab('2')
      history.replace('/settings')
      savePayoneer({ payee_id: payeeId })
      toastr.success('You Payoneer account was added successfully', 'Success')
    }

    // Disabling exhaustive-deps because `startFetch` is not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payeeId, history])

  useEffect(() => {
    switch (props.location.hash) {
      case '#kyc':
        setActiveTab('3')
        break
      case '#profile':
        setActiveTab('1')
        break
      case '#accounts':
        setActiveTab('2')
        break
      case '#security':
        setActiveTab('4')
        break
    }
    if (
      props.location?.pathname?.includes('settings/paypal') ||
      props.location?.pathname?.includes('settings/coinbase')
    ) {
      setActiveTab('2')
    }
  }, [props.location])

  const info = useFetch(
    {
      action: getCompanyInfo,
      autoFetch: contractorType === contractorTypes.ENTITY,
      onError: (err) => toastr.error(err),
    },
    [contractorType],
  )

  const {
    isLoading: loadingContractorInfo,
    data: contractorInfo,
    setData: setContractorInfo,
    startFetch: refetchContractorInfo,
  } = useFetch({
    action: getContractorInfo,
    autoFetch: true,
    onComplete: () => {
      window.analytics.track('Viewed profile', { 'email-id': user?.email })
    },
  })

  const { startFetch: updateContractor, isLoading: updateContractorIsLoading } =
    useFetch({
      autoFetch: false,
      checkSuccess: true,
      action: updateContractorProfile,
      onComplete: (data) => {
        setContractorInfo(data)
        dispatch(updateUserProfileInfo(data || {}))
      },
      onError: (err) => {
        toastr.error(err)
      },
    })

  const contractorProfileTabs = [
    { label: 'General Info', value: '1' },
    userProfile?.contractor_type === contractorTypes.ENTITY && {
      label: 'Company Info',
      value: '5',
    },
    {
      label: isDeEmployee ? 'Bank Accounts' : 'Withdrawal Methods',
      value: '2',
    },
    !isEmployee && { label: 'Verification', value: '3' },
    { label: 'Security', value: '4' },
  ].filter(Boolean)

  return (
    <div className='page-content position-relative'>
      <img
        className='position-absolute left-0 right-0 bg-primary w-100'
        style={{
          backgroundRepeat: 'repeat',
          backgroundImage: backgroundBlue ? `url(${backgroundBlue})` : null,
          top: 'var(--header-height)',
          height: 210,
        }}
      />

      <Row className='pt-4.5 mx-0'>
        <Col
          md={3}
          className='d-flex flex-column align-items-center px-0 pt-4 bg-white rp-shadow-2 rounded'
          style={{ height: 'fit-content', zIndex: 2 }}
        >
          <ManageAvatar
            src={userProfile?.photo}
            name={user?.first_name}
            onUploadPhoto={(acceptedFiles) => {
              const file = acceptedFiles[0]
              if (file.size > 2000000) {
                toastr.error('The file may not be greater than 2MB')
              } else {
                const dataInfo = { photo: acceptedFiles[0] }
                updateContractor(dataInfo)
              }
            }}
            isUploading={updateContractorIsLoading}
            avatarTitleClassName={
              user?.type === 'client' ? 'avatar-title-green' : 'avatar-title'
            }
          />

          <StyledH3
            min='22px'
            max='1.75em'
            mid='1.25em'
            style={{ fontWeight: 600 }}
            className='px-2 text-center text-dark'
            translate='no'
          >
            {user?.first_name} {user?.last_name}
          </StyledH3>

          <Row className='justify-content-center'>
            <Badge
              className='font-size-12 rounded bg-soft-self-badge mb-3'
              pill
            >
              <p style={{ padding: 2 }} className='mb-0 font-size-14'>
                {isEmployee || isDeEmployee ? 'Employee' : 'Contractor'}
              </p>
            </Badge>
            <div className='p-1' />
            {!isEmployee && (
              <Badge
                className='font-size-12 rounded bg-soft-self-badge mb-3'
                pill
              >
                <p style={{ padding: 2 }} className='mb-0 font-size-14'>
                  {userProfile?.contractor_type === contractorTypes.ENTITY
                    ? 'Entity'
                    : 'Individual'}
                </p>
              </Badge>
            )}
          </Row>

          {isEmployee ? null : !contractorInfo || loadingContractorInfo ? (
            <BadgeX
              status='light'
              textStatus='secondary'
              className='d-flex gap-6'
              leftIcon={<Spinner size='sm' />}
            >
              Checking Verification
            </BadgeX>
          ) : contractorInfo?.kyc_verified === 1 ? (
            <BadgeX
              leftIcon={<ShieldCheck weight='fill' className='tw-size-4' />}
              status='success'
            >
              Identity verified
            </BadgeX>
          ) : contractorInfo?.kyc_status === 'submitted' ? (
            <BadgeX
              leftIcon={<HourglassLow weight='fill' className='tw-size-4' />}
              status='warning'
            >
              Verification in progress
            </BadgeX>
          ) : (
            <BadgeX
              status='danger'
              leftIcon={<ShieldWarning weight='bold' className='tw-size-4' />}
            >
              Identity not verified
            </BadgeX>
          )}
          <div className='border-top my-md-4 mb-3 mt-4 w-100' />

          <div className='px-4 pb-3 pb-md-4 w-100'>
            <Button block outline color='light' tag={Link} to='/logout'>
              Log out
            </Button>
          </div>
        </Col>

        <Col md={9} className='pl-md-4 pr-md-0'>
          <Card className='overflow-visible rp-shadow-2'>
            <PageNav>
              {contractorProfileTabs.map(({ label, value }, index) => {
                return (
                  <NavItem key={index}>
                    <PageNav.Link
                      isActive={activeTab === value}
                      onClick={() => setActiveTab(value)}
                      tag='button'
                      type='button'
                    >
                      {label}
                    </PageNav.Link>
                  </NavItem>
                )
              })}
            </PageNav>

            {loadingContractorInfo ? (
              <Loader minHeight='50vh' />
            ) : (
              <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                  {!!contractorInfo && !!countries?.length ? (
                    <ProfileInfoNew
                      ref={formRef}
                      data={contractorInfo}
                      countries={countries}
                      key={loadingContractorInfo ? 'loading' : '1'}
                      refetchContractorInfo={() => {
                        refetchContractorInfo(null, false)
                      }}
                    />
                  ) : (
                    <CardBody
                      style={{ minHeight: '50vh' }}
                      className='p-0 m-0'
                    />
                  )}
                </TabPane>
                {userProfile?.contractor_type === contractorTypes.ENTITY && (
                  <TabPane tabId='5'>
                    {!!info?.data && !!countries?.length && (
                      <CompanyInfo
                        onComplete={() => {}}
                        ref={companyFormRef}
                        countries={countries}
                        data={info?.data}
                      />
                    )}
                  </TabPane>
                )}
                <TabPane tabId='2'>
                  <CardBody className='p-0 m-0'>
                    <WithdrawalMethod
                      profile={contractorInfo}
                      onUpdate={() => refetchContractorInfo(false)}
                      loading={loadingContractorInfo}
                    />
                  </CardBody>
                </TabPane>
                <TabPane tabId='3'>
                  {activeTab === '3' && <Kyc userInfo={contractorInfo} />}
                </TabPane>
                <TabPane tabId='4'>
                  <ChangePassword />
                </TabPane>
              </TabContent>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(ContractorSettings)
