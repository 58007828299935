import cx from 'classnames'
import React from 'react'
import { Badge } from 'reactstrap'

export default function BadgeX({
  status,
  textStatus = status,
  name,
  children,
  className,
  style,
  size = 'lg',
  leftIcon,
  rightIcon,
  textClassName,
  noPadding = false,
  textStyle,
  ...props
}) {
  return (
    <Badge
      className={cx(
        'align-items-center',
        {
          [`badge-soft-${status}`]: status,
          [`text-${textStatus}`]: textStatus,
          'py-1': !noPadding,
          'font-size-10': size === 'sm',
          'font-size-12': size === 'md',
          'font-size-14': size === 'lg',
          'd-inline-flex gap-6': leftIcon || rightIcon,
        },
        className,
      )}
      style={style}
      {...props}
    >
      {!leftIcon ? null : leftIcon}

      <span style={textStyle} className={textClassName}>
        {name ?? children}
      </span>

      {!rightIcon ? null : rightIcon}
    </Badge>
  )
}
