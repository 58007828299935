import React from 'react'
import { StyledH5 } from '../Typo'

const TableHeader = ({ title, leftSide, noPadding, className = '', style }) => {
  return (
    <div
      className={`${
        noPadding ? '' : 'p-4'
      } d-flex justify-content-between align-items-center ${className}`}
      style={style}
    >
      {!title ? null : (
        <StyledH5 min='16px' max='24px' className='mb-0'>
          {title}
        </StyledH5>
      )}
      {!leftSide ? null : <div>{leftSide}</div>}
    </div>
  )
}

export default TableHeader
