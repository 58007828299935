import { searchParamsFromObject } from '../utils/search-params-from-object'
import { apiV2, getConfigV2, api, getConfig } from './api'

export const getInternalAccounts = (token) => {
  return apiV2.get('/payments/api/v1/balances/accounts', getConfigV2(token))
}

export const getInternalTransactions = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.get(
    '/payments/api/v1/balances/transactions' + sp,
    getConfigV2(token),
  )
}

export const internalAccountTransfer = (token, data) => {
  return apiV2.post(
    '/payments/api/v1/balances/transactions',
    data,
    getConfigV2(token),
  )
}

export const getTransferQuote = (token, data) => {
  return apiV2.post(
    '/payments/api/v1/balances/quotes',
    data,
    getConfigV2(token),
  )
}

export const sendOtp = (token) => {
  return api.get('/admin/otp/send', getConfig(token))
}

export const verifyOtp = (token, data) => {
  return api.post('/admin/otp/verify', data, getConfig(token))
}
