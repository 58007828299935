'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input, Label, TextArea, TextField, } from 'react-aria-components';
import { cn } from '../lib/utils/cn';
export const TextInput = React.forwardRef((_a, ref) => {
    var { wrapperClassName, label, errorMessage, required, type = 'text', id, textArea, className, rows } = _a, inputProps = __rest(_a, ["wrapperClassName", "label", "errorMessage", "required", "type", "id", "textArea", "className", "rows"]);
    return (_jsxs(TextField, { className: cn('tw-flex tw-flex-col', wrapperClassName), children: [label && (_jsxs("div", { className: 'tw-flex', children: [_jsx(Label, { className: cn('tw-mb-2'), htmlFor: id, children: label }), required && _jsx("span", { className: 'tw-text-red', children: "*" })] })), !textArea ? (_jsx(Input, Object.assign({}, inputProps, { className: cn('tw-rounded tw-border tw-px-4 tw-py-2 tw-text-disabled tw-outline-none', errorMessage ? 'tw-border-red' : 'tw-border-surface-30', className), type: type, ref: ref, id: id }))) : (_jsx(TextArea, { className: cn('tw-border tw-border-surface-30 tw-p-3 tw-text-disabled tw-outline-none', { '!tw-border-red': errorMessage }), id: id, name: inputProps.name, value: inputProps.value, rows: rows })), errorMessage && (_jsx("span", { className: 'tw-font-sm tw-text-xs tw-text-red', children: errorMessage }))] }));
});
