import { ArrowElbowDownRight, X } from '@phosphor-icons/react'
import React from 'react'
import Select, { components } from 'react-select'

import { cn } from 'ui'
import { selectFieldPadding } from '../../Common/react-select-custom-styles'
import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'
import { DropdownIndicator } from './select-components'

const CustomSelect = ({
  isDisabled,
  leftRounded,
  value,
  options,
  onChange,
  label,
  defaultValue,
  classNamePrefix = 'RS-Control',
  searchable = true,
  hasError,
  wrapperClassName,
  labelClassName,
  wrapperStyles,
  selectStyles,
  inputProps,
  error,
  showError = true,
  required,
  selectRef,
  clearIcon,
  ...props
}) => {
  const { Option, SingleValue } = components
  const IconOption = (props) => (
    <Option {...props}>
      <div className='d-flex flex-row align-items-center text-left'>
        {!!props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              marginRight: 10,
              objectFit: 'cover',
            }}
            alt={props.data.label}
          />
        )}
        <div
          className={cn(
            'tw-flex',
            props.isDisabled ? 'tw-opacity-40' : undefined,
          )}
        >
          {props.data.icon}
        </div>

        <div className='d-flex flex-column align-items-start tw-w-full'>
          <div className='tw-w-full'>
            {props.data?.isChild && (
              <ArrowElbowDownRight
                className='tw-ml-4'
                size={12}
                weight='bold'
              />
            )}{' '}
            {props.data.label}
          </div>
          <div className='font-size-10 text-left'>{props.data.description}</div>
        </div>
      </div>
    </Option>
  )

  const CustomSingleValue = ({ data, ...props }) => {
    return (
      <SingleValue style={{ maxWidth: '94%' }} {...props}>
        <div className='d-flex flex-row align-items-center'>
          {data.flag && (
            <img
              src={data.flag}
              style={{
                width: '1rem',
                height: '1rem',
                borderRadius: '0.5rem',
                marginRight: 10,
                objectFit: 'cover',
              }}
              alt={data.label}
            />
          )}
          {data.icon}
          <span className='tw-w-full tw-truncate'>{data.label}</span>
        </div>
      </SingleValue>
    )
  }

  const Input = ({ ...rest }) => {
    return <components.Input {...rest} {...inputProps} />
  }

  function ClearIndicator(props) {
    return (
      <button
        className='tw-rounded tw-p-1 hover:tw-bg-surface-20 hover:tw-text-text-80 focus-visible:tw-ring-2 focus-visible:tw-ring-surface-30'
        {...props.innerProps}
      >
        {clearIcon ?? <X size={18} />}
      </button>
    )
  }

  const formatGroupLabel = (data) => (
    <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
      {data.icon?.({ size: 20 })}
      <span className='!tw-leading-[0px]'>{data.label}</span>
    </div>
  )

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: 'var(--text-40)',
      padding: selectFieldPadding,
    }),

    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: '#777F9E',
      minWidth: 200,
      textAlign: 'right',
      zIndex: 999,
      width: props.transparent ? 300 : provided.width,
      right: props.transparent ? '0%' : provided?.right,
      marginLeft: props.leftDir ? -100 : 0,
    }),

    multiValue: () => ({
      margin: 2,
      paddingLeft: 6,
      backgroundColor: 'rgb(59, 124, 255,0.1)',
      color: '#3b7cff',
      display: 'flex',
      alignItems: 'stretch',
    }),

    multiValueLabel: () => ({ fontSize: '14px' }),

    singleValue: (provided, state) => {
      const color = state.isDisabled ? 'var(--text-70)' : 'var(--text-100)'

      return { ...provided, color, padding: selectFieldPadding }
    },
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: hasError
        ? 'var(--red) !important'
        : state.isFocused && props.transparent
          ? '#325AE7 !important'
          : undefined,
      borderTopRightRadius: leftRounded
        ? '0px'
        : provided?.borderTopRightRadius,
      borderBottomRightRadius: leftRounded
        ? '0px'
        : provided?.borderBottomRightRadius,
      height: props.transparent
        ? 'calc(1.5em + 1.3rem + 2px)'
        : provided.height,
      ...selectStyles?.control(provided, state),
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: props.transparent
        ? 'transparent'
        : provided.backgroundColor,
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        ...selectStyles?.valueContainer?.(provided, state),
      }
    },
  }

  return (
    <div className={wrapperClassName} style={wrapperStyles}>
      {!label ? null : (
        <label className={labelClassName} htmlFor={props?.inputId}>
          {label}
          {required && (
            <span
              className='text-danger font-size-16 ml-1'
              style={{ lineHeight: 1 }}
            >
              *
            </span>
          )}
        </label>
      )}
      <Select
        isDisabled={isDisabled}
        components={{
          DropdownIndicator,
          Option: IconOption,
          SingleValue: CustomSingleValue,
          value: CustomSingleValue,
          IndicatorSeparator: null,
          ClearIndicator,
          Input,
          ...props.customComponents,
        }}
        value={value}
        classNamePrefix={classNamePrefix}
        onChange={onChange}
        options={options}
        isSearchable={searchable}
        defaultValue={defaultValue}
        styles={customStyles}
        ref={selectRef}
        formatGroupLabel={formatGroupLabel}
        {...props}
      />

      {!error || !showError ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

export default CustomSelect
