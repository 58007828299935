import { format } from 'date-fns'
import toastr from 'toastr'

import { otherSelectOption } from '../../../components/ControlledSelect'
import {
  areNamesLatin,
  isAddressLatin,
} from '../../../helpers/info-latin-regex'
import { eddFields } from '../organisms/card-edd'
import { otherSuffix } from '../components/form-content-renderer'

export const birthDateFormat = 'yyyy-MM-dd'

export function formatRequestData(data, { isEdd, isUpdate }) {
  const eddData = !isEdd
    ? null
    : eddFields.map(({ name, label: question }) => {
        const otherValue = data?.[`${name}${otherSuffix}`]
        let value = data?.[name]

        if (value?.includes(otherSelectOption.value) && otherValue) {
          value = otherValue
        }

        const answers = typeof value === 'string' ? [value] : value

        return { answers, question }
      })

  const formattedData = {
    user_residential_address: {
      address_type: 'residential',
      address_1: data.address_1.trim(),
      address_2: data.address_2.trim(),
      city: data.city,
      state: data.state,
      country: data.country.label,
      country_code: data.country.value,
      zipcode: data.zipcode,
    },
  }

  const misterCode = 'Mr'
  const missCode = 'Miss'

  if (!isUpdate) {
    formattedData.user_info = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      mobile_country_code: data.mobile_country_code,
      mobile: data.mobile,
      gender: data.gender.value,
      preferred_name: data?.preferred_name,

      nationality: data.nationality.value,

      // Prefilled fields
      title: data.gender.value === 'male' ? misterCode : missCode,
      birthday: format(new Date(data.birthday), birthDateFormat),
    }

    if (data?.middle_name) {
      formattedData.user_info.middle_name = data.middle_name
    }

    formattedData.card = {
      type: data.card_type,
    }
  } else {
    formattedData.user_info = {
      first_name: data.first_name,
      last_name: data.last_name,
    }

    if (data?.middle_name) {
      formattedData.user_info.middle_name = data.middle_name
    }
  }

  if (isEdd && eddData) {
    formattedData.edd_form = eddData
  }

  return formattedData
}

export function isDataValid(data) {
  const names = [
    data?.first_name,
    data?.last_name,
    data?.middle_name,
    data?.preferred_name,
  ]
  const namesAreLatin = areNamesLatin(names)

  if (!namesAreLatin) {
    toastr.error(
      'Only latin characters and commas are accepted for the name fields and preferred name.',
    )
    return false
  }

  const addresses = [data?.address_1, data?.address_2]
  const addressesAreLatin = isAddressLatin(addresses)
  if (!addressesAreLatin) {
    toastr.error(
      'Only latin characters and commas are accepted for the address',
    )
    return false
  }

  return true
}
