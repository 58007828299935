import { yupResolver } from '@hookform/resolvers/yup'
import { Eye, Spinner, X } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useForm } from 'react-hook-form'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useSelector } from 'react-redux'
import { Badge, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import { cn } from 'ui'
import { number, object, string } from 'yup'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import { FlagIcon } from '../../../../components/ui/flag-icon'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import { useFetch, useResize } from '../../../../helpers/hooks'
import {
  getInternalAccounts,
  getInternalTransactions,
  getTransferQuote,
  internalAccountTransfer,
  sendOtp,
  verifyOtp,
} from '../../../../services/api-internal-accounts'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { getFullName } from '../balances/balances-list'
import Citi from './icons/Citi.svg'
import ENBD from './icons/ENBD.svg'
import HSBC from './icons/HSBC.svg'
import MatchMove from './icons/Matchmove.svg'
import Mercury from './icons/Mercury.svg'
import Payoneer from './icons/Payoneer.svg'
import Paysend from './icons/Paysend.svg'
import SVB from './icons/Svb.svg'
import TransferMate from './icons/TM.svg'
import Wise from './icons/Wise.svg'
import { parseJwt } from '../../../../utils/parseJwt'

const getLogo = (provider) => {
  if (provider.includes('wise')) {
    return Wise
  }
  if (provider.includes('hsbc')) {
    return HSBC
  }
  if (provider.includes('payoneer')) {
    return Payoneer
  }
  if (provider.includes('paysend')) {
    return Paysend
  }
  if (provider.includes('enbd')) {
    return ENBD
  }
  if (provider.includes('matchmove')) {
    return MatchMove
  }
  if (provider.includes('svb')) {
    return SVB
  }
  if (provider.includes('mercury')) {
    return Mercury
  }
  if (provider.includes('transfermate') || provider.includes('jpm')) {
    return TransferMate
  }
  if (provider.includes('citi')) {
    return Citi
  }
}

const Item = ({ label, value, className }) => (
  <div
    className={cn(
      'tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-px-6',
      className,
    )}
  >
    <span className='tw-py-4 tw-text-sm tw-font-normal tw-text-text'>
      {label}
    </span>
    <span className='tw-text-sm tw-font-semibold tw-text-black'>{value}</span>
  </div>
)

const DetailsButton = ({ data, bankName }) => {
  const [showDetails, setShowDetails] = useState(false)
  const isMobile = useResize()

  return (
    <>
      <Button
        icon={<Eye size={16} className='tw-text-primary' />}
        size='sm'
        outline
        className='!tw-border-none !tw-text-primary hover:!tw-bg-transparent'
        onClick={() => setShowDetails(true)}
      >
        Details
      </Button>

      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 1100,
        }}
      >
        <Menu
          onClose={() => setShowDetails(false)}
          className='tw-bg-white'
          isOpen={showDetails}
          width={isMobile ? '100%' : window.innerWidth < 1400 ? '40%' : '30%'}
          right
          menuClassName='scrollbar-hidden'
        >
          <div className='tw-border-b tw-border-b-surface-30 tw-p-6'>
            <span className='tw-flex tw-items-center tw-justify-between tw-text-xl tw-font-semibold tw-text-black'>
              {data.ref}
              <button onClick={() => setShowDetails(false)} type='button'>
                <X />
              </button>
            </span>
            <span className='tw-text-sm tw-font-semibold tw-text-text-60'>
              {data.created_at
                ? format(new Date(data.created_at), 'MMM d, yyyy HH:mm:ss')
                : ''}
            </span>
          </div>

          <Item
            label='Status'
            value={
              <Badge
                className={cn(
                  'font-size-12 rounded py-1',
                  `${data.is_confirmed ? 'bg-soft-success' : 'tw-bg-red-30'}`,
                )}
                color='white'
                pill
              >
                {data.is_confirmed ? 'CONFIRMED' : 'UNCONFIRMED'}
              </Badge>
            }
          />

          <Item label='Initiated by' value={data.user_name} />
          <Item
            label='From'
            value={
              <span className='tw-flex'>
                <img src={getLogo(data.source_account)} className='tw-mr-2' />
                {bankName.from}
              </span>
            }
          />
          <Item
            label='To'
            value={
              <span className='tw-flex'>
                <img src={getLogo(data.target_account)} className='tw-mr-2' />
                {bankName.to}
              </span>
            }
          />
          <Item
            label='Source amount'
            value={getCurrencyFormatter(
              data.source_account.split('_').pop(),
            ).format(data.source_amount)}
          />
          <Item
            label='Fees'
            value={
              data.source_fee
                ? getCurrencyFormatter(
                    data.source_account.split('_').pop(),
                  ).format(data.source_fee)
                : 'N/A'
            }
          />
          <Item
            label='Target amount'
            value={
              data.target_amount
                ? getCurrencyFormatter(
                    data.target_account.split('_').pop(),
                  ).format(data.target_amount)
                : 'N/A'
            }
          />
        </Menu>
      </div>
    </>
  )
}

const STAGE = { INITIAL: 0, QUOTE: 1, OTP: 2, VERIFY_OTP: 3, TRANSFER: 4 }

const MoveMoneyButton = ({ accounts, onSuccess }) => {
  const [showModal, setShowModal] = useState(false)
  const [stage, setStage] = useState(STAGE.INITIAL)
  const [quote, setQuote] = useState()
  const [code, setCode] = useState()

  const loginToken = useSelector((state) => state.Login?.loginToken)
  const jwtData = parseJwt(loginToken)

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(
      object().shape({
        sourceAccountCode: string().required('From is required'),
        targetAccountCode: string().required('To is required'),
        amount: number().required('Amount is required'),
      }),
    ),
  })

  const onModalClose = () => {
    setShowModal(false)
    setStage(STAGE.INITIAL)
    setQuote(undefined)
    reset()
  }

  const { isLoading } = useFetch(
    {
      action: internalAccountTransfer,
      withAdminAccess: true,
      onComplete: () => {
        toastr.success('Money moved successfully')
        onModalClose()
        onSuccess()
      },
      onError: (error) => toastr.error(error),
      autoFetch: stage === STAGE.TRANSFER,
      body: {
        amount: Number(getValues('amount')),
        sourceAccountCode: getValues('sourceAccountCode'),
        targetAccountCode: getValues('targetAccountCode'),
        user_id: Number(jwtData.sub),
        user_name: getFullName(jwtData),
      },
    },
    [stage],
  )

  const { startFetch: getQuote, isLoading: gettingQuote } = useFetch({
    action: getTransferQuote,
    withAdminAccess: true,
    onComplete: (data) => {
      setQuote(data)
      setStage(STAGE.QUOTE)
    },
    onError: (error) => toastr.error(error),
  })

  const { isLoading: sendingOtp, startFetch: _sendOtp } = useFetch(
    {
      action: sendOtp,
      withAdminAccess: true,
      onError: (error) => toastr.error(error),
      onComplete: () => {
        toastr.success('OTP has been sent to your email')
        setStage(STAGE.OTP)
      },
    },
    [stage],
  )

  const { isLoading: verifyingOtp } = useFetch(
    {
      action: verifyOtp,
      withAdminAccess: true,
      onError: (error) => toastr.error(error),
      autoFetch: stage === STAGE.VERIFY_OTP,
      body: { code },
      onComplete: () => setStage(STAGE.TRANSFER),
    },
    [stage],
  )

  const onSubmit = (data) => {
    switch (stage) {
      case STAGE.INITIAL:
        getQuote(data)
        return
      case STAGE.QUOTE:
        _sendOtp()
        return
      case STAGE.OTP:
        setStage(STAGE.VERIFY_OTP)
    }
  }

  return (
    <>
      <Button
        color='primary'
        onClick={() => setShowModal(true)}
        disabled={!accounts}
      >
        Move Money
      </Button>

      <Modal
        isOpen={showModal}
        toggle={onModalClose}
        contentClassName={stage > STAGE.QUOTE && '!tw-w-fit'}
      >
        <ModalHeader
          close={
            <ModalCloseButton
              toggle={onModalClose}
              className='tw-text-[32px] tw-text-black'
            />
          }
          className='tw-items-center'
        >
          Move money
        </ModalHeader>

        {stage === STAGE.INITIAL || stage === STAGE.QUOTE ? (
          <ModalBody className='tw-flex tw-flex-col tw-gap-4'>
            <ControlledSelect
              options={accounts?.map((acc) => ({
                label: (
                  <span className='tw-flex tw-w-full tw-justify-between'>
                    <span className='tw-flex'>
                      <img src={getLogo(acc.provider)} className='tw-mr-2' />
                      {acc.name}
                    </span>
                    {acc.amount &&
                      getCurrencyFormatter(acc.currency).format(acc.amount)}
                  </span>
                ),
                value: acc.code,
              }))}
              label='From'
              name='sourceAccountCode'
              control={control}
              error={errors.sourceAccountCode?.message}
              isDisabled={stage}
            />

            <ControlledSelect
              options={accounts
                ?.find((acc) => acc.code === watch('sourceAccountCode'))
                ?.beneficiaries?.map((beneficiary) => ({
                  label: (
                    <span className='tw-flex tw-w-full tw-justify-between'>
                      <span className='tw-flex'>
                        <img
                          src={getLogo(beneficiary.code)}
                          className='tw-mr-2'
                        />
                        {`${
                          accounts?.find(
                            (account) => account.code === beneficiary.code,
                          )?.name ?? beneficiary.code
                        }`}
                      </span>
                    </span>
                  ),
                  value: beneficiary.code,
                }))}
              label='To'
              name='targetAccountCode'
              control={control}
              isDisabled={!watch('sourceAccountCode') || stage > STAGE.INITIAL}
              error={errors.targetAccountCode?.message}
            />

            <ControlledCurrencyInput
              name='amount'
              label='Amount'
              control={control}
              error={errors.amount?.message}
              disabled={stage}
              groupSeparator=','
              prefix={
                getValues('sourceAccountCode')
                  ?.split('_')
                  ?.pop()
                  ?.toUpperCase() + ' '
              }
            />

            {stage === STAGE.QUOTE && (
              <div>
                <Item
                  label='Should arrive in'
                  value={quote.estimatedDelivery}
                  className='!tw-px-0'
                />

                <Item
                  label='Source fees'
                  value={
                    quote.sourceFee
                      ? getCurrencyFormatter(quote.sourceCurrency).format(
                          quote.sourceFee,
                        )
                      : 'N/A'
                  }
                  className='!tw-px-0'
                />

                <Item
                  label='Target amount'
                  value={
                    quote.targetAmount === 'N/A'
                      ? 'N/A'
                      : getCurrencyFormatter(quote.targetCurrency).format(
                          quote.targetAmount,
                        )
                  }
                  className='!tw-px-0'
                />

                <Item
                  label='Target fees'
                  value={
                    quote.targetFee
                      ? getCurrencyFormatter(quote.targetCurrency).format(
                          quote.targetFee,
                        )
                      : 'N/A'
                  }
                  className='!tw-px-0'
                />
              </div>
            )}
          </ModalBody>
        ) : (
          <ModalBody className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
            <span className='tw-text-xl tw-font-semibold tw-text-black'>
              Please enter the verification code
            </span>
            <span className='tw-mb-6 tw-text-sm tw-text-text-80'>
              Enter the code we sent you via email
            </span>
            <ReactInputVerificationCode
              length={6}
              autoFocus
              onCompleted={(code) => setCode(code)}
            />
            {sendingOtp ? (
              <Spinner
                size={20}
                className='tw-mt-6 tw-animate-spin tw-transition-transform tw-duration-300'
              />
            ) : (
              <button className='tw-mt-6' onClick={() => _sendOtp()}>
                You didn’t a receive a code? <strong>Resend</strong>
              </button>
            )}
          </ModalBody>
        )}
        <ModalFooter className='tw-mt-14'>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading || gettingQuote || verifyingOtp || sendingOtp}
            loading={isLoading || gettingQuote || verifyingOtp || sendingOtp}
          >
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default function InternalAccounts() {
  const [page, setPage] = useState(1)

  const { data: accounts } = useFetch({
    action: getInternalAccounts,
    autoFetch: true,
    withAdminAccess: true,
  })

  const { data: transactions, startFetch: getTransactions } = useFetch(
    {
      action: getInternalTransactions,
      autoFetch: true,
      withAdminAccess: true,
      body: { page },
    },
    [page],
  )

  return (
    <div className='page-content'>
      <div className='tw-mb-8 tw-flex tw-items-center tw-justify-between'>
        <h1>Internal accounts</h1>
        <MoveMoneyButton accounts={accounts} onSuccess={getTransactions} />
      </div>

      <h5 className='tw-mb-4'>Payout accounts</h5>
      {!accounts ? (
        <Loader />
      ) : (
        <div className='tw-mb-8 tw-flex tw-flex-wrap tw-gap-4'>
          {accounts.map((acc) => (
            <div
              key={acc._id}
              className='tw-w-full tw-rounded tw-bg-white md:tw-w-[246px]'
            >
              <div className='tw-flex tw-gap-2 tw-border-b tw-border-b-surface-30 tw-p-4'>
                <img src={getLogo(acc.provider)} />
                <span className='tw-flex-1 tw-text-sm tw-font-normal tw-text-black'>
                  {acc.name}
                </span>
              </div>

              <div className='tw-flex tw-items-center tw-justify-between tw-p-4'>
                <span className='tw-flex tw-items-center'>
                  <FlagIcon code={acc.currency} className='tw-mr-2' />
                  {acc.currency}
                </span>
                {acc.amount && (
                  <span className='tw-text-base tw-font-semibold tw-text-black'>
                    {getCurrencyFormatter(acc.currency).format(acc.amount)}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <h5 className='tw-mb-4'>Transactions</h5>
      <div className='tw-flex tw-flex-col tw-bg-white tw-p-6'>
        {!transactions ? (
          <Loader />
        ) : (
          <>
            <DataTable
              columns={[
                { Header: 'Initiated by', accessor: 'user_name' },
                {
                  Header: 'Transaction date',
                  accessor: 'created_at',
                  Cell: ({ cellData }) =>
                    cellData
                      ? format(new Date(cellData), 'MMM d, yyyy HH:mm:ss')
                      : '',
                },
                {
                  Header: 'From',
                  accessor: 'source_account',
                  Cell: ({ cellData }) => (
                    <span className='tw-flex tw-min-w-max'>
                      <img src={getLogo(cellData)} className='tw-mr-2' />
                      {
                        accounts?.find((account) => account.code === cellData)
                          ?.name
                      }
                    </span>
                  ),
                },
                {
                  Header: 'To',
                  accessor: 'target_account',
                  Cell: ({ cellData }) => (
                    <span className='tw-flex tw-min-w-max'>
                      <img src={getLogo(cellData)} className='tw-mr-2' />
                      {accounts?.find((account) => account.code === cellData)
                        ?.name ?? cellData}
                    </span>
                  ),
                },
                {
                  Header: 'Source amount',
                  accessor: 'source_amount',
                  Cell: ({ rowData }) => (
                    <span>
                      {getCurrencyFormatter(
                        rowData.source_account.split('_').pop(),
                      ).format(rowData.source_amount)}
                    </span>
                  ),
                },
                {
                  Header: 'Target amount',
                  accessor: 'target_amount',
                  Cell: ({ rowData }) => (
                    <span>
                      {rowData.target_amount
                        ? getCurrencyFormatter(
                            rowData.target_account.split('_').pop(),
                          ).format(rowData.target_amount)
                        : 'N/A'}
                    </span>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'is_confirmed',
                  Cell: ({ cellData }) => (
                    <Badge
                      className={cn(
                        'font-size-12 rounded py-1',
                        `${cellData ? 'bg-soft-success' : 'tw-bg-red-30'}`,
                      )}
                      color='white'
                      pill
                    >
                      {cellData ? 'CONFIRMED' : 'UNCONFIRMED'}
                    </Badge>
                  ),
                },
                {
                  Cell: ({ rowData }) => (
                    <DetailsButton
                      data={rowData}
                      bankName={{
                        from: accounts?.find(
                          (account) => account.code === rowData.source_account,
                        )?.name,
                        to: accounts?.find(
                          (account) => account.code === rowData.target_account,
                        )?.name,
                      }}
                    />
                  ),
                },
              ]}
              data={transactions?.items ?? []}
              responsive
            />

            <div className='tw-mt-4 tw-self-end'>
              <Pagination
                activePage={page}
                totalItemsCount={transactions?.totalItemsCount ?? 0}
                itemsCountPerPage={transactions?.limit ?? 10}
                onChange={(page) => setPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
