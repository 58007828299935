export const isMobile = () => {
  // width taken from tailwind md utility
  return window.innerWidth < 768
}

export const getFilenameFromUrl = (url) => {
  return url?.match(/filename%3D%22(.*)%22/)[1]
}

export const convertDataURIToBinary = (dataURI) => {
  const BASE64_MARKER = ';base64,'
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  const base64 = dataURI.substring(base64Index)
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  return array
}

/**
 * Leaves only unique elements in the array.
 * Used like [].filter(onlyUnique)
 * Advantage over lodashes uniq is that we dont have to wrap everything like uniq(...), we can just use more functional approach
 */
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

/**
 * Function for sorting an array of objects or arrays by field name
 *
 * Similar to sortBy from lodash, but this can be used to chain multiple
 * array manipulations in a more functional programming style
 */
export function sortBy(fieldName) {
  return (item1, item2) => {
    return item1[fieldName] > item2[fieldName]
      ? 1
      : item1[fieldName] < item2[fieldName]
        ? -1
        : 0
  }
}
export function groupBy(array, key) {
  const groups = {}

  array.forEach((item) => {
    const groupKey = typeof key === 'function' ? key(item) : item[key]
    if (!groups[groupKey]) {
      groups[groupKey] = []
    }
    groups[groupKey].push(item)
  })

  // Convert the groups object into an array of arrays
  return Object.values(groups)
}
export function sumProperty(arr, property) {
  return arr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue[property]
  }, 0)
}
export const downloadFile = (url, fileName, target = '_blank') => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.setAttribute('target', target)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getStatusColor = (status) => {
  const statusCode = status?.toLowerCase()
  switch (statusCode) {
    case 'paid':
    case 'approved':
    case 'signed': {
      return 'success'
    }

    case 'unpaid':
    case 'declined': {
      return 'danger'
    }

    case 'processing':
    case 'pending approval':
    case 'pending': {
      return 'warning'
    }

    default:
      return 'secondary'
  }
}
