import {
  SUBMIT_ACCOUNT_MAPPING,
  SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  UPDATE_CONTRACTOR,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATIONS,
  UPDATE_PRE_DATA,
  UPDATE_REQUEST,
  XERO_INIT,
} from './actionTypes'

const initialState = {
  preData: null,
  accountMapping: {},
  contractors: [],
  request: null,
}

const xero = (state = initialState, action) => {
  let categories = []
  switch (action.type) {
    case UPDATE_PRE_DATA:
      return {
        ...state,
        preData: action.payload,
        contractors: action.payload.contractors,
      }
    case UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      }
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      }
    case XERO_INIT:
      return initialState
    case SUBMIT_ACCOUNT_MAPPING:
      return {
        ...state,
        accountMapping: action.payload,
      }
    case SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST:
      categories = Object.keys(action.payload).map((e) => ({
        rp: e,
        qb: action.payload[e],
        xero: action.payload[e],
      }))
      return {
        ...state,
        accountMapping: action.payload,
        request: { categories },
      }
    case UPDATE_CONTRACTOR:
      return {
        ...state,
        contractors: state.contractors.map((e) =>
          e.contractor_id === action.payload.contractor_id ? action.payload : e,
        ),
      }
    case UPDATE_REQUEST:
      if (!action.payload) {
        return {
          ...state,
          request: null,
        }
      }

      if (!action.payload?.isUpdate) {
        categories = Object.keys(state?.accountMapping).map((e) => ({
          rp: e,
          xero: state.accountMapping[e],
          qb: state.accountMapping[e],
        }))
      }
      // eslint-disable-next-line no-case-declarations
      const contractors = state?.contractors.map((e) => {
        const object = { rp: e?.contractor_id }
        if (e.categories && typeof e.categories === 'object') {
          object.categories = Object.keys(e.categories).map((k) => ({
            rp: k,
            xero: e.categories[k],
            qb: e.categories[k],
          }))
        }
        if (e.categories && Array.isArray(e.categories)) {
          object.categories = e.categories.map((k) => ({
            rp: k?.category_id,
            xero: k?.xero_id,
            qb: k?.xero_id,
          }))
        }
        if (e?.xero?.value || e?.xero_id || e?.qb_id) {
          object.xero = e?.xero?.value || e?.xero_id
          object.qb = e?.xero?.value || e?.qb_id
        }
        return object
      })
      if (action.payload?.isUpdate) {
        return {
          ...state,
          request: {
            ...action.payload,
            contractors,
          },
        }
      } else {
        return {
          ...state,
          request: {
            ...action.payload,
            categories,
            contractors,
          },
        }
      }

    default:
      return state
  }
}

export default xero
