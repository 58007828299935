import {
  Elements,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useMemo, useState } from 'react'
import { Col, Input, Row } from 'reactstrap'

import { useFetch, useResize } from '../../helpers/hooks'
import { setupSepa } from '../../services/api'

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          // color: "#424770",
          // letterSpacing: "0.025em",
          // // fontFamily: "Source Code Pro, monospace",
          // "::placeholder": {
          //     color: "#aab7c4"
          // }
        },
        invalid: { color: '#9e2146' },
      },
    }),
    [],
  )

  return options
}

const SepaForm = ({
  onSubmitted,
  loading,
  onFocus,
  onPay,
  isNewCard,
  onCancel = () => {},
}) => {
  const SplitForm = () => {
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const isMobile = useResize()

    const setup = useFetch({
      action: setupSepa,
      autoFetch: true,
    })
    const handleSubmit = async (event) => {
      event.preventDefault()

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      const payload = await stripe.confirmSepaDebitSetup(setup.data.secret, {
        payment_method: {
          sepa_debit: elements.getElement(IbanElement),
          billing_details: {
            name,
            email,
          },
        },
      })
      onSubmitted(payload)
    }

    return (
      <form onSubmit={handleSubmit}>
        <div
          style={{
            minHeight: isMobile ? '70vh' : undefined,
            marginBottom: isMobile ? '5rem' : undefined,
          }}
          className='p-0 pt-3'
        >
          <Col>
            <Row>
              <Col>
                <label>Name</label>
                <Input
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  className='form-control'
                  placeholder='Account Holder'
                />
              </Col>
              <Col>
                <label>Email</label>
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  className='form-control'
                  placeholder='Email'
                />
              </Col>
            </Row>
            <Row className='my-3'>
              <Col>
                <label>IBAN</label>
                <IbanElement
                  className='form-control'
                  options={{ ...options, supportedCountries: ['SEPA'] }}
                  onFocus={onFocus}
                />

                <p className='mt-2'>
                  By providing your IBAN and confirming this payment, you are
                  authorizing RemotePass Inc. and Stripe, our payment service
                  provider, to send instructions to your bank to debit your
                  account and your bank to debit your account in accordance with
                  those instructions. You are entitled to a refund from your
                  bank under the terms and conditions of your agreement with
                  your bank. A refund must be claimed within 8 weeks starting
                  from the date on which your account was debited.
                </p>
              </Col>
            </Row>
          </Col>

          <div
            className={`modal-footer ${
              isMobile ? 'fixed-bottom bg-white' : ''
            }`}
          >
            <Col>
              <Row
                className={
                  isMobile ? 'justify-content-between' : 'justify-content-end'
                }
              >
                <button
                  className='btn btn-outline-light mr-2'
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  onClick={!isNewCard ? onPay : null}
                  data-secret={process.env.REACT_APP_STRIPE_KEY}
                  className={`btn btn-${
                    stripe ? 'primary' : 'secondary'
                  } pl-3 pr-3 rp-font-bold`}
                  type='submit'
                  disabled={!stripe}
                >
                  {loading && (
                    <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
                  )}{' '}
                  Add Card
                </button>
              </Row>
            </Col>
          </div>
        </div>
      </form>
    )
  }
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

  return (
    <Elements stripe={stripePromise}>
      <SplitForm />
    </Elements>
  )
}

export default SepaForm
