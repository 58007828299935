import { yupResolver } from '@hookform/resolvers/yup'
import { Info } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import RelatedFieldContent from '../../../components/related-field-content'
import InputFeedback from '../../../components/ui/input-feedback'
import Loader from '../../../components/ui/loader'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { TERMINATE_CONTRACT_TYPE } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import { cancelScheduledTerminate } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import LabelContent from '../CreateContract/components/label-content'
import ControlledTypeSelect from '../CreateContract/fields/type-select'

const TerminationModal = ({
  isOpen,
  toggle,
  onTerminationConfirm,
  loading,
  contract,
  onCancelTerminationConfirm,
}) => {
  const contractTerminationTypes = [
    {
      label: 'Terminate now',
      value: TERMINATE_CONTRACT_TYPE.NOW,
    },
    {
      label: 'Schedule termination',
      value: TERMINATE_CONTRACT_TYPE.LATER,
    },
  ]

  const isFixedContract = contract?.type === CONTRACT_TYPES.FIXED

  const showCancelTermination = contract?.is_scheduled_terminated === 1

  const terminateContractFormId = 'terminate-contract-form'

  const { startFetch: cancelTermination, isLoading: cancelingTermination } =
    useFetch({
      action: cancelScheduledTerminate,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Error cancelling termination')
        } else {
          toastr.success('Termination cancelled')
          onCancelTerminationConfirm?.()
          toggle()
        }
      },
      onError: (error) => {
        toastr.error(error?.message || 'Error cancelling termination')
      },
    })

  const schema = yup.object().shape({
    terminate_contract_way: yup
      .string()
      .oneOf(contractTerminationTypes.map((v) => v.value))
      .required('Please select one'),
    last_payment_amount: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .notRequired(),
    termination_date: yup.date().when('terminate_contract_way', {
      is: TERMINATE_CONTRACT_TYPE.LATER,
      then: (schema) =>
        schema
          .min(new Date(), 'Minimum scheduled date is tomorrow')
          .required('Termination date is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      terminate_contract_way: TERMINATE_CONTRACT_TYPE.NOW,
      last_payment_amount: null,
      termination_date: null,
    },
    resolver: yupResolver(schema),
  })

  function onSubmit(values) {
    onTerminationConfirm(
      values.last_payment_amount,
      values.termination_date,
      values.terminate_contract_way,
    )
  }

  useEffect(() => {
    clearErrors()
  }, [watch('terminate_contract_way')])

  const today = new Date()
  const tomorrowDate = today.setDate(today.getDate() + 1)

  return (
    <Modal isOpen={isOpen} role='dialog' toggle={toggle}>
      <div className='modal-content'>
        <ModalHeader close={<ModalCloseButton toggle={toggle} size={24} />}>
          Contract termination
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader minHeight='30rem' />
          ) : showCancelTermination ? (
            <>
              <div className='d-flex border-bottom py-3 px-2 flex-row align-items-center'>
                <span className='text-text-100 font-size-14 flex-1'>
                  Contract will be terminated on
                </span>

                <span className='text-right text-text-black font-size-14 rp-font-semibold flex-1'>
                  {contract?.termination_date
                    ? format(new Date(contract?.termination_date), 'dd/MM/yyyy')
                    : null}
                </span>
              </div>
            </>
          ) : (
            <Col className='p-0'>
              <form
                id={terminateContractFormId}
                onSubmit={handleSubmit(onSubmit)}
              >
                <ControlledTypeSelect
                  types={contractTerminationTypes}
                  name='terminate_contract_way'
                  error={errors?.terminate_contract_way}
                  control={control}
                  required
                  cols={2}
                  buttonClassName='text-center'
                  spaced={false}
                />

                {isFixedContract &&
                  watch('terminate_contract_way') ===
                    TERMINATE_CONTRACT_TYPE.NOW && (
                    <>
                      <hr />
                      <label htmlFor='last_payment_amount'>
                        <LabelContent>Last payment amount</LabelContent>
                      </label>
                      <InputGroup style={{ flex: 1 }}>
                        <ControlledCurrencyInput
                          control={control}
                          name='last_payment_amount'
                          error={errors?.last_payment_amount}
                          id='last_payment_amount'
                          placeholder='Amount'
                          className='rounded-right-0 rounded-bottom-0'
                          hideError={true}
                        />

                        <InputGroupAddon addonType='append'>
                          <InputGroupText>
                            <div style={{ textAlign: 'center' }}>
                              {contract?.currency?.code}
                            </div>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <RelatedFieldContent>
                        The current payment cycle will be cancelled.
                      </RelatedFieldContent>

                      {!errors?.last_payment_amount ? null : (
                        <InputFeedback className='tw-mt-1'>
                          {getErrorMessage(errors.last_payment_amount)}
                        </InputFeedback>
                      )}
                      <div className='custom-alert bg-red-20 border rounded p-4 font-size-14 font-weight-light d-flex flex-nowrap mt-3'>
                        <span className='text-red-110 pr-3 mt-0.5'>
                          <Info size={20} />
                        </span>
                        {`This contract has a ${contract?.notice_period} day notice period counting from today.`}
                      </div>
                    </>
                  )}

                {watch('terminate_contract_way') ===
                  TERMINATE_CONTRACT_TYPE.LATER && (
                  <>
                    <hr />

                    <div className='my-3'>
                      <ControlledDatePicker
                        label={
                          <LabelContent required>Termination date</LabelContent>
                        }
                        control={control}
                        name='termination_date'
                        error={errors?.termination_date}
                        placeholder='Termination date'
                        showPreviousMonths={false}
                        minDate={tomorrowDate}
                        className='rounded-bottom-0'
                        showError={true}
                      />

                      <div className='custom-alert bg-blue-20 border rounded p-4 font-size-14 font-weight-light d-flex flex-nowrap mt-3'>
                        <span className='text-blue-110 pr-3 mt-0.5'>
                          <Info size={20} />
                        </span>
                        <span className='alert-text'>
                          You will receive an email reminder 7 days and 24 hours
                          before the termination date, to ensure that your
                          verify and edit the payments if needed.
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </Col>
          )}
        </ModalBody>
        {loading ? null : (
          <ModalFooter>
            {showCancelTermination ? (
              <Button
                outline
                block
                color='light'
                className='rp-font-bold'
                onClick={() => {
                  cancelTermination({ contract_id: contract.id })
                }}
                loading={cancelingTermination}
                disabled={cancelingTermination}
              >
                Cancel Termination
              </Button>
            ) : (
              <>
                <Button type='button' color='light' outline onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  type='submit'
                  color='danger'
                  form={terminateContractFormId}
                >
                  {watch('terminate_contract_way') ===
                  TERMINATE_CONTRACT_TYPE.LATER
                    ? 'Schedule Termination'
                    : 'Terminate Now'}
                </Button>
              </>
            )}
          </ModalFooter>
        )}
      </div>
    </Modal>
  )
}

export default TerminationModal
