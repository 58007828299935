export function formatPhysicalRequestData(data) {
  return {
    shipping_details: {
      shipping_address: {
        address_1: data.address_1.trim(),
        address_2: data.address_2.trim(),
        city: data.city,
        state: data.state,
        country: data.country.label,
        country_code: data.country.value,
        zipcode: data.zipcode,
      },
      shipping_contact: {
        mobile: {
          country_code: data.mobile_country_code,
          number: data.mobile,
        },
      },
    },
    card: { type: 'physical' },
  }
}
