import { yupResolver } from '@hookform/resolvers/yup'
import { format, isValid, parse } from 'date-fns'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { getCustomStyles } from '../../../../components/Common/react-select-custom-styles'
import ControlledRadioList from '../../../../components/controlled-radio-list'
import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import ControlledSelect from '../../../../components/ControlledSelect'

import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import {
  adminExportCardMarkups,
  adminExportClientPaymentBalances,
  adminExportClientPaymentBreakdowns,
  adminExportContractorBalance,
  adminExportContractorBalances,
  adminExportEOROutstandingInvoices,
  adminExportEOROutstandingPayments,
  adminExportEORPendingAR,
  adminExportProviderFees,
  adminExportTransactions,
  adminExportWalletProviders,
  adminExportWithdrawMarkups,
  checkAdminReportStatus,
  getAdminCompanies,
  getUsersListAdmin,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import { getFullName } from '../balances/balances-list'
import { isProduction } from '../../../../utils/isProduction'

function formatMonth(month) {
  return format(new Date(month), 'yyyy-MM') // "2022-09"
}
function formatDate(date) {
  return format(new Date(date), 'yyyy-MM-dd') // "2022-09-10"
}

// Report types
const transactionType = 'transactions'
const contractorBalancesType = 'contractor_balances'
const contractorBalanceType = 'contract_balance'
const clientPaymentBalances = 'client_payment_balances'
const clientPaymentBreakdowns = 'client_payment_breakdowns'
const eorPendingARType = 'eorPending_ar'
const eorOutstandingInvoicesType = 'eorOutstanding_invoices'
const eorOutstandingPaymentsType = 'eorOutstanding_payments'
const providerFees = 'provider_fees'
const withdrawMarkups = 'withdraw_markups'
const walletProviders = 'wallet_providers'
const cardMarkups = 'card_markups'

const reportTypeOptions = [
  { value: transactionType, label: 'Transactions' },
  { value: contractorBalancesType, label: 'Contractor balances' },
  { value: contractorBalanceType, label: 'Balance per contractor' },
  { value: clientPaymentBalances, label: 'Client payments balances' },
  { value: clientPaymentBreakdowns, label: 'Client payments breakdown' },
  { value: eorPendingARType, label: 'EOR Pending AR' },
  { value: eorOutstandingInvoicesType, label: 'EOR Outstanding Invoices' },
  { value: eorOutstandingPaymentsType, label: 'EOR Outstanding Payments' },
  { value: providerFees, label: 'Provider fees' },
  { value: withdrawMarkups, label: 'Withdraw markups' },
  { value: walletProviders, label: 'Wallet providers' },
  { value: cardMarkups, label: 'Card markups' },
]

// Schemas for validation
// export Transactions
const transactionSchema = yup.object().shape({
  date_column: yup.string().required('Date by is required'),
  month: yup.string().required('Month is required'),
})
// export Contractors balances
const contractorBalancesSchema = yup.object().shape({
  date: yup.string().required('Month is required'),
})
// export Balances per contractor
const contractorBalanceSchema = yup.object().shape({
  start_date: yup.string().required('Start date is required'),
  end_date: yup.string().required('End date is required'),
  contractor_id: yup
    .object()
    .shape({
      value: yup.string().required('Contractor is required'),
      label: yup.string().required('Contractor is required'),
    })
    .required(),
})
// export Client payments balances
const clientPaymentBalancesSchema = yup.object().shape({
  start_date: yup.string().required('Start date is required'),
  end_date: yup.string().required('End date is required'),
})
// export Client payments breakdown
const clientPaymentBreakdownsSchema = yup.object().shape({
  start_date: yup.string().required('Start date is required'),
  end_date: yup.string().required('End date is required'),
})
// export EOR Pending AR
const eorPendingARSchema = yup.object().shape({})
// export EOR Outstanding Invoices
const eorOutstandingInvoicesSchema = yup.object().shape({})
// export EOR Outstanding Payments
const eorOutstandingPaymentsSchema = yup.object().shape({})
const providerFeesSchema = yup.object({
  month: yup.string().required('Month is required'),
})
const withdrawMarkupsSchema = yup.object({
  month: yup.string().required('Month is required'),
})
const walletProvidersSchema = yup.object({
  month: yup.string().required('Month is required'),
})
const cardMarkupsSchema = yup.object({
  month: yup.string().required('Month is required'),
})

const reportTypeSchema = {
  [transactionType]: transactionSchema,
  [contractorBalancesType]: contractorBalancesSchema,
  [contractorBalanceType]: contractorBalanceSchema,
  [clientPaymentBalances]: clientPaymentBalancesSchema,
  [clientPaymentBreakdowns]: clientPaymentBreakdownsSchema,
  [eorPendingARType]: eorPendingARSchema,
  [eorOutstandingInvoicesType]: eorOutstandingInvoicesSchema,
  [eorOutstandingPaymentsType]: eorOutstandingPaymentsSchema,
  [providerFees]: providerFeesSchema,
  [withdrawMarkups]: withdrawMarkupsSchema,
  [walletProviders]: walletProvidersSchema,
  [cardMarkups]: cardMarkupsSchema,
}

const fields = {
  [transactionType]: [
    {
      name: 'date_column',
      label: 'Date by',
      type: 'radio',
      options: [
        { value: 'created_at', label: 'Creation' },
        { value: 'confirmed_at', label: 'Confirmation' },
      ],
    },
    {
      name: 'month',
      label: 'Month',
      type: 'month',

      placeholder: 'Pick a month',
      dateFormat: 'MMM - yyyy',
      showMonthYearPicker: true,
    },
  ],
  [contractorBalancesType]: [
    { name: 'date', label: 'Date', type: 'date', placeholder: 'Pick a date' },
  ],
  [contractorBalanceType]: [
    { name: 'contractor_id', label: 'Contractor', type: 'contractor-select' },
    {
      name: 'start_date',
      label: 'Start date',
      type: 'date',
      placeholder: 'Pick a date',
    },
    {
      name: 'end_date',
      label: 'End date',
      type: 'date',
      placeholder: 'Pick a date',
    },
  ],
  [clientPaymentBalances]: [
    {
      name: 'start_date',
      label: 'Start date',
      type: 'date',
      placeholder: 'Pick a date',
    },
    {
      name: 'end_date',
      label: 'End date',
      type: 'date',
      placeholder: 'Pick a date',
    },
    {
      name: 'company_id',
      label: 'Company',
      type: 'company',
      placeholder: 'select a company',
    },
  ],
  [clientPaymentBreakdowns]: [
    {
      name: 'start_date',
      label: 'Start date',
      type: 'date',
      placeholder: 'Pick a date',
    },
    {
      name: 'end_date',
      label: 'End date',
      type: 'date',
      placeholder: 'Pick a date',
    },
  ],
  [eorPendingARType]: [],
  [eorOutstandingInvoicesType]: [],
  [eorOutstandingPaymentsType]: [],
  [providerFees]: [
    {
      name: 'month',
      label: 'Month',
      type: 'month',

      placeholder: 'Pick a month',
      dateFormat: 'yyyy-MM',
      showMonthYearPicker: true,
    },
  ],
  [withdrawMarkups]: [
    {
      name: 'month',
      label: 'Month',
      type: 'month',

      placeholder: 'Pick a month',
      dateFormat: 'MMM - yyyy',
      showMonthYearPicker: true,
    },
  ],
  [walletProviders]: [
    {
      name: 'month',
      label: 'Month',
      type: 'month',

      placeholder: 'Pick a month',
      dateFormat: 'MMM - yyyy',
      showMonthYearPicker: true,
    },
  ],
  [cardMarkups]: [
    {
      name: 'month',
      label: 'Month',
      type: 'month',

      placeholder: 'Pick a month',
      dateFormat: 'MMM - yyyy',
      showMonthYearPicker: true,
    },
  ],
}

// Report api calls
const reportTypeApi = {
  [transactionType]: adminExportTransactions,
  [contractorBalancesType]: adminExportContractorBalances,
  [contractorBalanceType]: adminExportContractorBalance,
  [clientPaymentBalances]: adminExportClientPaymentBalances,
  [clientPaymentBreakdowns]: adminExportClientPaymentBreakdowns,
  [eorPendingARType]: adminExportEORPendingAR,
  [eorOutstandingInvoicesType]: adminExportEOROutstandingInvoices,
  [eorOutstandingPaymentsType]: adminExportEOROutstandingPayments,
  [providerFees]: adminExportProviderFees,
  [withdrawMarkups]: adminExportWithdrawMarkups,
  [walletProviders]: adminExportWalletProviders,
  [cardMarkups]: adminExportCardMarkups,
}

const reportFormId = 'report-form'
let interval

function getDateFileName(data) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const defAcc = acc ? `${acc}-` : acc
    if (key.includes('date')) {
      const parsedDate = parse(value, 'yyyy-MM-dd', new Date())
      if (isValid(parsedDate)) {
        return `${defAcc}${formatDate(value)}`
      }
    } else if (key.includes('month')) {
      return `${defAcc}${formatMonth(value)}`
    }
    return acc
  }, '')
}

export default function ExportReportModal({ isOpen, toggle }) {
  const [reportType, setReportType] = useState(null)
  const [downloading, setDownloading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(reportTypeSchema[reportType?.value]),
  })

  const { startFetch: checkStatus } = useFetch({
    action: checkAdminReportStatus,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data.url) {
        openFileV2(data.url, { isDataUrl: true, download: true })
        clearInterval(interval)
        setDownloading(false)
        toggle()
      }
    },
    onError: (err) => {
      clearInterval(interval)
      setDownloading(false)
      toastr.error(err)
    },
  })

  const { startFetch: exportReport } = useFetch({
    action: reportTypeApi[reportType?.value],
    withAdminAccess: true,
    onComplete: (data, body) => {
      if (data.id) {
        interval = setInterval(() => {
          checkStatus({ id: data?.id })
        }, 1000 * 5)
      } else {
        const name = `report_${getDateFileName(body)}_${
          reportType?.label ?? ''
        }.xlsx`

        openFileV2(data, { download: true, name })
        setDownloading(false)
        toggle()
      }
    },
    onError: () => {
      toastr.error('Error while exporting transactions')
    },
  })

  const onSubmit = (values) => {
    const body = Object.entries(values).reduce((acc, [key, value]) => {
      if (key === 'month') {
        acc[key] = formatMonth(value)
      } else if (key === 'contractor_id') {
        acc[key] = value.value
      } else {
        acc[key] = value
      }

      return acc
    }, {})

    exportReport(body)
    setDownloading(true)
  }

  function handleErrors(errors) {
    const firstError = Object.entries(errors)[0]
    toastr.error(firstError[1].message)
  }

  function handleReportTypeChange(value) {
    setReportType(value)
    resetForm()
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      unmountOnClose
      modalClassName='overflow-auto'
    >
      <ModalHeader toggle={toggle}>Export report</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className='font-size-14'>Report type</Label>
          <CustomSelect
            name='report_type'
            options={reportTypeOptions}
            value={reportType}
            onChange={handleReportTypeChange}
          />
        </FormGroup>
        <Form onSubmit={handleSubmit(onSubmit, handleErrors)} id={reportFormId}>
          {fields[reportType?.value]?.map((field) => {
            const { label, ...restField } = field
            return (
              <FormGroup key={field?.name}>
                <Label className='font-size-14'>{label}</Label>
                <RenderFieldType
                  field={restField}
                  control={control}
                  errors={errors}
                />
              </FormGroup>
            )
          })}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={downloading}>
          Cancel
        </Button>
        <Button
          disabled={downloading}
          loading={downloading}
          type='submit'
          formId={reportFormId}
        >
          Export
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function RenderFieldType({ control, errors, field: { type, ...field } }) {
  switch (type) {
    case 'date': {
      return (
        <ControlledDatePicker
          {...field}
          control={control}
          error={errors[field?.name]?.message}
        />
      )
    }
    case 'company': {
      return (
        <CompaniesPicker
          {...field}
          control={control}
          error={errors[field?.name]?.message}
        />
      )
    }
    case 'month': {
      return (
        <ControlledDatePicker
          {...field}
          control={control}
          error={errors[field?.name]?.message}
        />
      )
    }
    case 'radio': {
      return (
        <ControlledRadioList
          {...field}
          control={control}
          error={errors[field?.name]?.message}
        />
      )
    }
    case 'contractor-select': {
      return (
        <ControlledContractorSelector
          {...field}
          control={control}
          error={errors[field?.name]?.message}
        />
      )
    }
    default: {
      return !isProduction()
        ? `[REPORT MODAL] Field type ${field.type} is not supported yet`
        : null
    }
  }
}

export function CompaniesPicker({ control, name, ...rest }) {
  const { data: companiesList, isLoading } = useFetch({
    autoFetch: true,
    action: getAdminCompanies,
    withAdminAccess: true,
  })
  return (
    <ControlledSelect
      control={control}
      name={name}
      disabled={isLoading}
      loading={isLoading}
      options={
        companiesList?.map((e) => ({
          label: e.name,
          value: e.id,
        })) ?? []
      }
    />
  )
}
export function ControlledContractorSelector({
  control,
  name,
  error,
  isDisabled,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, onChange } }) => {
        return (
          <ContractorSelector
            name={name}
            onChange={onChange}
            value={value}
            onMenuClose={onBlur}
            hasError={!!error}
            aria-invalid={!!error}
            aria-errormessage={name + '-error-msg'}
            styles={getCustomStyles({ hasError: !!error })}
            isDisabled={isDisabled}
            {...rest}
          />
        )
      }}
    />
  )
}

function ContractorSelector(props) {
  const loginToken = useSelector((state) => state?.Login?.loginToken)
  const { mutate } = useContractors(loginToken)

  const loadOptions = (inputValue, callback) => {
    mutate(getUsersBody(inputValue), {
      onSettled: (data) => {
        const options = data?.data?.data?.users?.map((user) => {
          return {
            value: user?.id,
            label: `${getFullName(user)} (${user?.email})`,
            balances: user?.balances,
          }
        })

        callback(options)
      },
    })
  }

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      placeholder='Search contractor by name or email'
      components={{ IndicatorSeparator: null }}
      {...props}
    />
  )
}

function getUsersBody(inputValue) {
  const body = { page: 1, tab: 'contractors', archived: 0 }

  if (inputValue) {
    body.search = inputValue
  }

  return body
}

function useContractors(token) {
  return useMutation({
    mutationFn: (data) => {
      return getUsersListAdmin(token, data)
    },
  })
}
