import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import toastr from 'toastr'

import { ReactComponent as RefersHeaderIcon } from '../../assets/images/refersHeaderIcon.svg'
import { useFetch, useResize } from '../../helpers/hooks'
import { getReferrals, sendReferralInvites } from '../../services/api'
import InstructionSection from './components/instructionSection'
import PreviewModal from './components/previewModal'
import Refers from './components/refers'
import SharingSection from './components/sharing-section'
import TermsModal from './components/TermsModal'

const USER_FULL_NAME_PLACEHOLDER = '__USER_FULL_NAME__'
const defaultEmail = `Hi there,

I've been using an HR Platform called RemotePass for a while now and I think you'll find it really useful for your remote onboarding and payroll needs. Here is what else you can do with RemotePass.

- Smart Document Collection
- Get a head start with locally compliant templates.
- Global Health Insurance Administration
- Pay your remote team in 90+ currencies

Sign Up today and when you run payroll you'll get your first month off!

Sincerely,
${USER_FULL_NAME_PLACEHOLDER}`

export default function RemotepassReferrals() {
  const isMobile = useResize()
  const user = useSelector((state) => state.Account?.user)
  const [showPreviewEmail, setShowPreviewEmail] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)

  const [emails, setEmails] = useState([])
  const [emailText, setEmailText] = useState(
    defaultEmail.replace(
      USER_FULL_NAME_PLACEHOLDER,
      `${user.first_name} ${user.last_name}`,
    ),
  )
  const { startFetch: inviteUsers, isLoading: sendingInvitation } = useFetch({
    action: sendReferralInvites,
    onComplete: () => {
      setShowPreviewEmail(false)
      toastr.success('Invitations sent successfully')
      setEmails([])
      referrals.startFetch()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  function sendInvitations() {
    inviteUsers({
      emailAddresses: emails,
      subject: 'subject',
      content: emailText,
      id: user?.id,
    })
  }
  const referrals = useFetch({
    action: getReferrals,
    autoFetch: true,
    body: { id: user?.id },
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <div className='page-content'>
      <Container
        className='p-0 d-flex flex-column'
        style={{ gap: '1.5rem' }}
        fluid
      >
        <RefersHeader isMobile={isMobile} />
        <div
          className={`d-flex ${
            isMobile ? 'flex-column' : ''
          } justify-content-between flex-wrap mb-0`}
        >
          <SharingSection
            showPreview={setShowPreviewEmail}
            emails={emails}
            setEmails={setEmails}
            loading={sendingInvitation}
            sendInvitations={sendInvitations}
          />
          <InstructionSection />
        </div>
        <Refers referrals={referrals} />
        <PreviewModal
          isOpen={showPreviewEmail}
          toggle={() => setShowPreviewEmail(false)}
          emailText={emailText}
          setEmailText={setEmailText}
          onConfirm={sendInvitations}
          loading={sendingInvitation}
        />
        <TermsModal
          isOpen={showTermsModal}
          toggle={() => setShowTermsModal(false)}
        />
      </Container>
    </div>
  )
}

function RefersHeader({ isMobile }) {
  return (
    <div
      className={`d-flex ${isMobile ? 'flex-column-reverse' : ''} ${
        isMobile ? 'align-items-center' : 'align-items-start'
      } justify-content-between w-100 p-3`}
      style={{ backgroundColor: 'rgba(227, 235, 255, 1)', borderRadius: 4 }}
    >
      <div
        className={`${isMobile ? 'd-flex flex-column align-items-center' : ''}`}
      >
        <p className='m-0 font-size-24 font-weight-600'>Refer & Earn $400</p>
        <div className='font-size-16 text-muted py-3'>
          Refer a company to RemotePass and get up to $400 when they process
          payroll!
        </div>

        <a
          className='text-primary pt-3'
          target='_blank'
          rel='noreferrer'
          href='https://www.remotepass.com/blog/refer-and-earn-with-remotepass'
        >
          Learn more
        </a>
      </div>
      <div className='pr-4'>
        <RefersHeaderIcon />
      </div>
    </div>
  )
}
