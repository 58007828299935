import {
  UPDATE_PRE_DATA,
  SUBMIT_ACCOUNT_MAPPING,
  UPDATE_CONTRACTOR,
  UPDATE_REQUEST,
  SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  UPDATE_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
  XERO_INIT,
} from './actionTypes'

export const updatePreData = (payload) => {
  return {
    type: UPDATE_PRE_DATA,
    payload,
  }
}

export const updateOrganizations = (payload) => {
  return {
    type: UPDATE_ORGANIZATIONS,
    payload,
  }
}

export const updateOrganization = (payload) => {
  return {
    type: UPDATE_ORGANIZATION,
    payload,
  }
}
export const initXero = () => {
  return {
    type: XERO_INIT,
  }
}

export const submitAccountMapping = (payload) => {
  return {
    type: SUBMIT_ACCOUNT_MAPPING,
    payload,
  }
}

export const updateContractor = (payload) => {
  return {
    type: UPDATE_CONTRACTOR,
    payload,
  }
}

export const updateRequest = (payload) => {
  return {
    type: UPDATE_REQUEST,
    payload,
  }
}
export const submitAccountMappingAndUpdateRequest = (payload) => {
  return {
    type: SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
    payload,
  }
}
