import React from 'react'
import { Modal } from 'reactstrap'

const DiscardModal = ({ isOpen, toggle, onDiscard }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Save Contract</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>Are you sure you want to discard your changes.</p>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline-light'
            data-dismiss='modal'
            onClick={toggle}
          >
            Cancel
          </button>
          <button type='button' className='btn btn-danger' onClick={onDiscard}>
            Discard
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DiscardModal
