import React from 'react'
import { Container } from 'reactstrap'
import Expenses from '../../Contract/ContractPage/Expenses'
import { useFetch } from '../../../helpers/hooks'
import { getFullTimeContractDetails } from '../../../services/api'
import { updateContract } from '../../../store/contract/actions'
import { useDispatch } from 'react-redux'

const FteExpensesPage = () => {
  const dispatch = useDispatch()
  useFetch({
    action: getFullTimeContractDetails,
    autoFetch: true,
    onComplete: (data) => {
      dispatch(updateContract(data))
    },
  })
  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <Expenses />
      </Container>
    </div>
  )
}

export default FteExpensesPage
