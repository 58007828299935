import React from 'react'
import { useController } from 'react-hook-form'
import InputFeedback from '../../components/ui/input-feedback'
import WorkingDays from './working-days'

export default function ControlledWorkingDays({
  inActiveClassName,
  control,
  name,
}) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  const toggle = (day) => {
    if (value?.includes(day)) {
      const index = value.indexOf(day)
      value.splice(index, 1)
    } else {
      value.push(day)
    }
    onChange(value)
  }

  return (
    <>
      <WorkingDays
        days={value}
        onClick={(day) => toggle(day.index)}
        inActiveClassName={inActiveClassName}
      />
      {error && (
        <InputFeedback className='tw-mt-1'>{error.message}</InputFeedback>
      )}
    </>
  )
}
