import React from 'react'
import { Redirect } from 'react-router-dom'

// Authentication related pages
import ForgetPassword from '../pages/Authentication/ForgetPassword'
import Invite from '../pages/Authentication/Invite'
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import Switch from '../pages/Authentication/Switch'

// Dashboard
import permissions from '../helpers/permissions'
import AccessDenied from '../pages/AccessDenied'
import AccountType from '../pages/AccountType'
import CompleteCompany from '../pages/AccountType/completeCompanyInfo'
import Activity from '../pages/Activity'
import AllPayments from '../pages/AdminPanel/pages/AllPayments'
import AutoWithdraw from '../pages/AdminPanel/pages/AutoWithdraw'
import Companies from '../pages/AdminPanel/pages/Companies'
import ConfirmedTransfers from '../pages/AdminPanel/pages/ConfirmedTransfers'
import ExpenseDetails from '../pages/AdminPanel/pages/Contracts/ExpenseDetails'
import ContractDetailAdmin from '../pages/AdminPanel/pages/Contracts/contractDetails'
import ContractsListAdmin from '../pages/AdminPanel/pages/Contracts/contractsList'
import LoginAdmin from '../pages/AdminPanel/pages/LoginAdmin'
import PendingTransfers from '../pages/AdminPanel/pages/PendingTransfers'
import Templates from '../pages/AdminPanel/pages/Templates'
import Transactions from '../pages/AdminPanel/pages/Transactions'
import UsersAdmin from '../pages/AdminPanel/pages/Users/users'
import TwoFactorAuthenticator from '../pages/Authentication/TwoFactorAuthenticator'
import UpdatePassword from '../pages/Authentication/UpdatePassword'
import CompanySetting from '../pages/CompanySetting'
import QuickbookLoading from '../pages/CompanySetting/components/QuickbooksLoadingPage'
import CompleteProfile from '../pages/CompleteProfile'
import ContractEditor from '../pages/Contract/ContractEditor'
import ContractList from '../pages/Contract/ContractList'
import ContractPage from '../pages/Contract/ContractPage'
import TemplateEditor from '../pages/Contract/TemplateEditor'
import { Documents } from '../pages/Documents'
import Entity from '../pages/Entity'
import Insurance from '../pages/Insurance'
import ProfileSettings from '../pages/ProfileSettings'
import TestPaymentCycle from '../pages/TestPaymentCycle/TestPaymentSycle'
import History from '../pages/Transactions'
import UpcomingPayment from '../pages/UpcomingPayment'
import Withdrawal from '../pages/Withdrawal'
import StripeFormReactNative from '../pages/due-payments-page/CheckoutFormReactNative'
import { AllDuePayments } from '../pages/due-payments-page/all-due-payments'
import PayInvoices from '../pages/payInvoices'
import { UserInvoices } from '../pages/user-invoices'
import InvoiceDetail from '../pages/user-invoices/InvoiceDetail'
import WithdrawProcess from '../pages/withdrawProcess'

import ADMIN_PERMISSIONS from '../config/admin-permissions'
import featureFlags from '../config/feature-flags'
import PaymentsByCompany from '../pages/AdminPanel/pages/AllPayments/PaymentsByCompany'
import PaymentsDetails from '../pages/AdminPanel/pages/AllPayments/PaymentsDetails'
import CurrencyMarkup from '../pages/AdminPanel/pages/CurrencyMarkup'
import EORCalculator from '../pages/AdminPanel/pages/EORCalculator'
import EorInvoices from '../pages/AdminPanel/pages/EORInvoices'
import GenerateInvoice from '../pages/AdminPanel/pages/EORInvoices/GenerateInvoice'
import GeneratedInvoices from '../pages/AdminPanel/pages/EORInvoices/GenertatedInvoices'
import InvoiceGeneration from '../pages/AdminPanel/pages/EORInvoices/InvoiceGeneration'
import FullTimeRequests from '../pages/AdminPanel/pages/FullTimeRequests'
import QuotationGenerator from '../pages/AdminPanel/pages/FullTimeRequests/QuotationGeneration'
import RequestDetailsNew from '../pages/AdminPanel/pages/FullTimeRequests/requestDetails'
import InsuranceAdmin from '../pages/AdminPanel/pages/Insurance'
import InsurancePaymentAdmin from '../pages/AdminPanel/pages/Insurance/PaymentsList'
import UpcomingOverduePayments from '../pages/AdminPanel/pages/Insurance/upcoming-overdue-payments'
import Partners from '../pages/AdminPanel/pages/Partners'
import ManualKycVerification from '../pages/AdminPanel/pages/Users/manual-kyc'
import { AdminHome } from '../pages/AdminPanel/pages/admin-home'
import AdminList from '../pages/AdminPanel/pages/admin-management/admin-list'
import AdminRoles from '../pages/AdminPanel/pages/admin-management/admin-roles'
import BalancesClients from '../pages/AdminPanel/pages/balances/balances-clients'
import BalancesContractors from '../pages/AdminPanel/pages/balances/balances-contractors'
import Beneficiaries from '../pages/AdminPanel/pages/beneficiaries'
import AdminCardsAll from '../pages/AdminPanel/pages/cards/cards-all'
import AdminCardsKyc from '../pages/AdminPanel/pages/cards/cards-kyc'
import AdminCardsPhysical from '../pages/AdminPanel/pages/cards/cards-physical'
import AdminCardsTransactions from '../pages/AdminPanel/pages/cards/cards-transactions'
import AdminCardsUnpaidFees from '../pages/AdminPanel/pages/cards/cards-unpaid-fees'
import AdminCardsSupportedCountries from '../pages/AdminPanel/pages/cards/supported-countries'
import CorContracts from '../pages/AdminPanel/pages/cor/contracts'
import CorCountriesSupported from '../pages/AdminPanel/pages/cor/countries-supported'
import ManageWithdrawalMethods from '../pages/AdminPanel/pages/currencies-payment-methods/manage-withdrawal-methods'
import WalletConversionMarkups from '../pages/AdminPanel/pages/currencies-payment-methods/wallet-conversion-markups'
import { AdminDepositsRefunds } from '../pages/AdminPanel/pages/deposits-refunds'
import JurisdictionAdd from '../pages/AdminPanel/pages/direct-employee/jurisdiction-add'
import JurisdictionEdit from '../pages/AdminPanel/pages/direct-employee/jurisdiction-edit'
import Jurisdictions from '../pages/AdminPanel/pages/direct-employee/jurisdictions'
import Adjustments from '../pages/AdminPanel/pages/eor/adjustments'
import Amendments from '../pages/AdminPanel/pages/eor/amendments'
import Countries from '../pages/AdminPanel/pages/eor/countries'
import DocumentRequests from '../pages/AdminPanel/pages/eor/document-requests'
import PartnerInvoiceBreakdown from '../pages/AdminPanel/pages/eor/partner-invoice-breakdown'
import PartnerInvoices from '../pages/AdminPanel/pages/eor/partner-invoices'
import Payrolls from '../pages/AdminPanel/pages/eor/payrolls'
import Renewal from '../pages/AdminPanel/pages/eor/renewal'
import ForbiddenDomains from '../pages/AdminPanel/pages/forbidden-domains'
import FormsList from '../pages/AdminPanel/pages/forms'
import FormPage from '../pages/AdminPanel/pages/forms/form'
import InternalAccounts from '../pages/AdminPanel/pages/internal-accounts'
import ProviderRules from '../pages/AdminPanel/pages/provider-rules'
import { SendDataContract } from '../pages/AdminPanel/pages/send-data/contracts/id'
import FeeSimulator from '../pages/AdminPanel/tools/FeeSimulator'
import UserTypeChooser from '../pages/Authentication/UserTypeChooser'
import BambooIntegration from '../pages/CompanySetting/components/BambooIntegration'
import FreshbookLoading from '../pages/CompanySetting/components/FreshbooksLoadingPage'
import HibobIntegration from '../pages/CompanySetting/components/HibobIntegration'
import NetSuiteLoading from '../pages/CompanySetting/components/NetSuiteLoadingPage'
import SaplingIntegration from '../pages/CompanySetting/components/SaplingIntegration'
import UpdateBambooIntegration from '../pages/CompanySetting/components/UpdateBambooIntegration'
import UpdateFreshbooksIntegration from '../pages/CompanySetting/components/UpdateFreshbooksIntegration'
import UpdateHibobIntegration from '../pages/CompanySetting/components/UpdateHibobIntegration'
import UpdateNetSuiteIntegration from '../pages/CompanySetting/components/UpdateNetSuiteIntegration'
import UpdateQBIntegration from '../pages/CompanySetting/components/UpdateQBIntegration'
import UpdateSaplingIntegration from '../pages/CompanySetting/components/UpdateSaplingIntegration'
import UpdateXeroIntegration from '../pages/CompanySetting/components/UpdateXeroIntegration'
import UpdateZohoIntegration from '../pages/CompanySetting/components/UpdateZohoIntegration'
import XeroLoadingPage from '../pages/CompanySetting/components/XeroLoadingPage'
import ZohoBooksLoading from '../pages/CompanySetting/components/ZohobooksLoadingPage'
import { NewAutomationModal } from '../pages/CompanySetting/components/automations-tab'
import RegistrationDocument from '../pages/CompanySetting/registration-document'
import CorSowCreation from '../pages/Contract/ContractDetail/cor-sow-creation'
import DeDocumentGenerate from '../pages/Contract/ContractPage/de-document-generate'
import CreateContractV3 from '../pages/Contract/CreateContract/create-contract-v3'
import InvitationLoader from '../pages/Contract/InvitationLoader'
import RequestContract from '../pages/Contract/RequestContract'
import { ContractBulkCreation } from '../pages/Contract/bulk-creation'
import { ContractBulkOperations } from '../pages/Contract/bulk-operations'
import EmployeeJoiningFormPage from '../pages/Contract/eor-data-collection'
import IdwiseVerification from '../pages/ContractorSettings/idwise-verification'
import CreateDoc from '../pages/Documents/CreateDoc'
import EmployeeDocuments from '../pages/Fulltime/employee-documents'
import ScanInvoice from '../pages/ScanInvoice'
import { CreateAndEditBill } from '../pages/bills/create-and-edit'
import { GoToVendorScreen } from '../pages/bills/create-and-edit/go-to-vendor'
import Bills from '../pages/bills/list'
import CreateVendor from '../pages/bills/vendors/create'
import Vendors from '../pages/bills/vendors/list'
import { DuePaymentsPage } from '../pages/due-payments-page/due-payments-page'
import ReviewPayments from '../pages/due-payments-page/review-payments'
import ReviewPaymentsConfirmed from '../pages/due-payments-page/review-payments-confirmed'
import ExpensesPage from '../pages/expenses'
import { NewExternalInvoice } from '../pages/new-external-invoice/index'
import { NewTimeOffPolicy } from '../pages/new-time-off-policy'
import { OrganizationChartPage } from '../pages/org-chart'
import PayInsurance from '../pages/payInsurance'
import PayslipsPage from '../pages/payslips'
import RemotepassCards from '../pages/remotepass-cards'
import ActivatePhysicalCard from '../pages/remotepass-cards/screens/activate-physical-card'
import CardConvert from '../pages/remotepass-cards/screens/card-convert'
import CardOffload from '../pages/remotepass-cards/screens/card-offload'
import CardTopup from '../pages/remotepass-cards/screens/card-topup'
import CardTransactions from '../pages/remotepass-cards/screens/card-transactions'
import CardsKyc from '../pages/remotepass-cards/screens/cards-kyc'
import CardsRequestPhysical from '../pages/remotepass-cards/screens/request-physical'
import ResetPin from '../pages/remotepass-cards/screens/reset-pin'
import RemotepassPerks from '../pages/remotepass-perks'
import RemotepassReferrals from '../pages/remotepass-refers'
import Reports from '../pages/reports'
import CustomReports from '../pages/reports/custom'
import { ReviewCenter } from '../pages/review-center'
import SlackAuthPage from '../pages/slack'
import TimeOffPage from '../pages/time-off'
import EorSimulation from '../pages/tools/eor-simulation'
import TwoFaReminderPage from '../pages/two-fa-reminder'

const userRoutes = [
  { path: '/activity', component: Activity },
  { path: '/review-center', component: ReviewCenter },
  {
    path: '/invoices',
    component: UserInvoices,
    permissions: [permissions.ViewInvoices],
  },
  { path: '/reports', component: Reports, exact: true },
  { path: '/reports/custom', component: CustomReports },
  {
    path: '/documents',
    component: Documents,
    // should handle permissions in the component
    // permissions: [permissions.ViewCompaniesDocuments],
  },
  {
    path: '/contracts',
    component: ContractList,
    permissions: [permissions.ViewContracts],
  },
  {
    path: '/transactions',
    component: History,
    permissions: [permissions.ViewTransactions],
  },
  { path: '/insurance', component: Insurance },
  { path: '/cards', component: RemotepassCards, exact: true },
  { path: '/cards/transactions', component: CardTransactions, exact: true },

  { path: '/perks', component: RemotepassPerks },
  { path: '/referrals', component: RemotepassReferrals },
  { path: '/time-off', component: TimeOffPage },
  { path: '/my-documents', component: EmployeeDocuments },
  { path: '/payslips', component: PayslipsPage },
  { path: '/expenses', component: ExpensesPage },
  {
    path: '/payment/:id',
    component: () => {
      return (
        <div className='page-content'>
          <AllDuePayments />
        </div>
      )
    },
    permissions: [permissions.PrepareTransactions],
  },
  {
    path: '/payment-v1',
    component: () => {
      return (
        <div className='page-content'>
          <AllDuePayments />
        </div>
      )
    },
    permissions: [permissions.ViewUnpaidPayments],
  },
  {
    path: '/payment',
    component: DuePaymentsPage,
    permissions: [permissions.ViewUnpaidPayments],
  },
  { path: '/review-payments', component: ReviewPayments },
  {
    path: '/upcoming-payment',
    component: UpcomingPayment,
    permissions: [permissions.ViewUpcomingPayments],
  },
  { path: '/contract/detail', component: ContractPage },
  { path: '/contract/Expenses/detail', exact: true, component: ExpenseDetails },
  { path: '/invoice/detail', component: InvoiceDetail },
  {
    path: '/settings/:type?',
    component: CompanySetting,
    exact: true,
    permissions: [
      permissions.ViewCompaniesInfo,
      permissions.UpdateDefaultPaymentMethods,
      permissions.InviteUsers,
      permissions.ViewPaymentMethods,
      permissions.ViewPaymentMethodsInfo,
      permissions.ViewCompaniesUsers,
      permissions.ViewDefaultPaymentMethods,
      permissions.ViewAttributes,
      permissions.manageCompanySettings,
      permissions.DeleteAttribute,
      permissions.UpdateAttribute,
      permissions.SetupPaymentMethods,
      permissions.DeletePaymentMethods,
    ],
  },
  { path: '/withdraw', component: Withdrawal },
  { path: '/profile-settings', component: ProfileSettings },
  { path: '/no-access', component: AccessDenied },
  { path: '/integrations/update-xero', component: UpdateXeroIntegration },
  { path: '/integrations/update-quickbooks', component: UpdateQBIntegration },
  {
    path: '/integrations/update-freshbooks',
    component: UpdateFreshbooksIntegration,
  },
  { path: '/integrations/update-zoho', component: UpdateZohoIntegration },
  {
    path: '/integrations/update-netsuite',
    component: UpdateNetSuiteIntegration,
  },
  { path: '/integrations/update-hibob', component: UpdateHibobIntegration },
  { path: '/integrations/update-sapling', component: UpdateSaplingIntegration },
  { path: '/integrations/update-bamboo', component: UpdateBambooIntegration },

  { path: '/tools/eor-simulation', component: EorSimulation },
  {
    path: '/bills',
    component: Bills,
    exact: true,
    permissions: [
      permissions.ViewOwnBills,
      permissions.ViewAllBills,
      permissions.ManageVendors,
    ],
  },
  {
    path: '/bills/vendors',
    component: Vendors,
    exact: true,
    permissions: [permissions.ViewVendors, permissions.ManageVendors],
  },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to='/activity' /> },
]

const authRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPassword },
  { path: '/update-password', component: UpdatePassword },
  { path: '/register', component: Register },
  { path: '/signup', component: Register },
  { path: '/referral', component: Register },
  { path: '/partner', component: Register },
  { path: '/invite', component: Invite },
  { path: '/test-payment-cycle', component: TestPaymentCycle },
  { path: '/two-factor-authentication', component: TwoFactorAuthenticator },
  { path: '/select-profile', component: UserTypeChooser },
]
const authAdminRoutes = [{ path: '/admin/login', component: LoginAdmin }]

const adminPanelRoutes = [
  {
    path: '/private-zone',
    exact: true,
    component: () => <Redirect to='/admin/home' />,
  },
  {
    path: '/admin/home',
    component: AdminHome,
  },
  {
    path: '/admin/all-payments',
    component: AllPayments,
    permissions: ADMIN_PERMISSIONS.VIEW_PAYMENTS,
  },
  {
    path: '/admin/transactions',
    component: Transactions,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_TRANSACTIONS,
      ADMIN_PERMISSIONS.SEARCH_TRANSACTIONS,
    ],
  },
  {
    path: '/admin/deposits-refunds',
    component: AdminDepositsRefunds,
    permissions: [ADMIN_PERMISSIONS.MANAGE_ADMIN_DEPOSITS],
  },
  {
    path: '/admin/pending-transfers',
    exact: true,
    component: PendingTransfers,
    permissions: ADMIN_PERMISSIONS.VIEW_TRANSFERS,
  },
  {
    path: '/admin/confirmed-transfers',
    exact: true,
    component: ConfirmedTransfers,
    permissions: ADMIN_PERMISSIONS.VIEW_TRANSFERS,
  },
  {
    path: '/admin/internal-accounts',
    component: InternalAccounts,
    permissions: ADMIN_PERMISSIONS.VIEW_TRANSFERS,
  },
  {
    path: '/admin/balance-contractors',
    exact: true,
    component: BalancesContractors,
  },
  {
    path: '/admin/balance-clients',
    exact: true,
    component: BalancesClients,
  },
  {
    path: '/admin/contracts',
    exact: true,
    component: ContractsListAdmin,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_CONTRACTS,
      ADMIN_PERMISSIONS.SEARCH_CONTRACTS,
    ],
  },
  {
    path: '/admin/companies',
    exact: true,
    component: Companies,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_COMPANIES,
      ADMIN_PERMISSIONS.SEARCH_COMPANIES,
    ],
  },
  {
    path: '/admin/users',
    exact: true,
    component: UsersAdmin,
    permissions: [ADMIN_PERMISSIONS.VIEW_USERS, ADMIN_PERMISSIONS.SEARCH_USERS],
  },
  {
    path: '/admin/cards-kyc',
    exact: true,
    component: AdminCardsKyc,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
    ],
  },
  {
    path: '/admin/cards-unpaid-fees',
    exact: true,
    component: AdminCardsUnpaidFees,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
    ],
  },
  {
    path: '/admin/cards-physical',
    exact: true,
    component: AdminCardsPhysical,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
    ],
  },
  {
    path: '/admin/cards-all',
    exact: true,
    component: AdminCardsAll,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
    ],
  },
  {
    path: '/admin/cards-transactions',
    exact: true,
    component: AdminCardsTransactions,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
    ],
  },
  {
    path: '/admin/cards-support',
    exact: true,
    component: AdminCardsSupportedCountries,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
    ],
  },
  {
    path: '/admin/kyc-verification/:userId',
    component: ManualKycVerification,
    permissions: [ADMIN_PERMISSIONS.VIEW_USERS, ADMIN_PERMISSIONS.SEARCH_USERS],
  },
  {
    path: '/admin/withdraw',
    exact: true,
    component: AutoWithdraw,
    permissions: ADMIN_PERMISSIONS.VIEW_AUTO_WITHDRAWS,
  },
  {
    path: '/admin/templates',
    exact: true,
    component: Templates,
    permissions: ADMIN_PERMISSIONS.VIEW_TEMPLATES,
  },
  {
    path: '/admin/forms',
    component: FormsList,
    exact: true,
    // TODO permission to be added
  },
  {
    path: '/admin/forms/:id',
    component: FormPage,
    // TODO permission to be added
  },
  {
    path: '/admin/contract-detail',
    component: ContractDetailAdmin,
    permissions: [
      ADMIN_PERMISSIONS.VIEW_CONTRACT_DETAILS,
      ADMIN_PERMISSIONS.VIEW_EOR_CONTRACTS,
    ],
  },
  // EOR
  {
    path: '/admin/requests',
    exact: true,
    component: FullTimeRequests,
    permissions: ADMIN_PERMISSIONS.VIEW_EOR_CONTRACTS,
  },
  {
    path: '/admin/amendments',
    exact: true,
    component: Amendments,
    permissions: ADMIN_PERMISSIONS.MANAGE_EOR_CONTRACTS,
  },
  {
    path: '/admin/payrolls',
    exact: true,
    component: Payrolls,
    permissions: ADMIN_PERMISSIONS.MANAGE_PARTNERS,
  },
  {
    path: '/admin/adjustments',
    exact: true,
    component: Adjustments,
    permissions: ADMIN_PERMISSIONS.MANAGE_EOR_CONTRACTS,
  },
  {
    path: '/admin/eor-documents',
    exact: true,
    component: DocumentRequests,
    permissions: ADMIN_PERMISSIONS.VIEW_EOR_CONTRACTS,
  },
  {
    path: '/admin/partners',
    exact: true,
    component: Partners,
    permissions: ADMIN_PERMISSIONS.VIEW_PARTNERS,
  },
  {
    path: '/admin/countries',
    exact: true,
    component: Countries,
    permissions: ADMIN_PERMISSIONS.MANAGE_EOR_CONTRACTS,
  },
  {
    path: '/admin/EORCalculator',
    exact: true,
    component: EORCalculator,
    permissions: ADMIN_PERMISSIONS.VIEW_CALCULATOR,
  },
  {
    path: '/admin/quote-generator',
    exact: true,
    component: QuotationGenerator,
    permissions: ADMIN_PERMISSIONS.VIEW_QUOTE_GENERATOR,
  },
  {
    path: '/admin/fee-simulator',
    exact: true,
    component: FeeSimulator,
    // permissions: ADMIN_PERMISSIONS.VIEW_QUOTE_GENERATOR,
  },
  {
    path: '/admin/invoice-generator',
    exact: true,
    component: InvoiceGeneration,
    permissions: ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR,
  },
  {
    path: '/admin/invoice-generation',
    exact: true,
    component: GenerateInvoice,
    permissions: ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR,
  },
  {
    path: '/admin/generated-invoices',
    exact: true,
    component: GeneratedInvoices,
    permissions: ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR,
  },
  {
    path: '/admin-fulltime-details/:contractId',
    exact: true,
    component: RequestDetailsNew,
  },
  // END EOR

  // RemotePass+
  {
    path: '/admin/cor/contracts',
    exact: true,
    component: CorContracts,
    permissions: ADMIN_PERMISSIONS.VIEW_CONTRACTS,
  },
  {
    path: '/admin/cor/countries',
    exact: true,
    component: CorCountriesSupported,
    permissions: ADMIN_PERMISSIONS.VIEW_CONTRACTS,
  },
  // END RemotePass+

  // Direct Employees
  {
    path: '/admin/jurisdictions',
    exact: true,
    component: Jurisdictions,
    permissions: ADMIN_PERMISSIONS.VIEW_CONTRACTS,
  },
  {
    path: '/admin/jurisdictions/add',
    exact: true,
    component: JurisdictionAdd,
    permissions: ADMIN_PERMISSIONS.VIEW_CONTRACTS,
  },
  {
    path: '/admin/jurisdictions/:id',
    exact: true,
    component: JurisdictionEdit,
    permissions: ADMIN_PERMISSIONS.VIEW_CONTRACTS,
  },
  // END Direct Employees

  {
    path: '/admin/insurance',
    exact: true,
    component: InsuranceAdmin,
    permissions: ADMIN_PERMISSIONS.VIEW_INSURANCE_REQUESTS,
  },
  {
    path: '/admin/insurance-payments',
    exact: true,
    component: InsurancePaymentAdmin,
    permissions: ADMIN_PERMISSIONS.VIEW_INSURANCE_PAYMENTS,
  },
  {
    path: '/admin/upcoming-overdue-payments',
    exact: true,
    component: UpcomingOverduePayments,
    permissions: ADMIN_PERMISSIONS.VIEW_INSURANCE_PAYMENTS,
  },
  {
    path: '/admin/contracts-invoices',
    exact: true,
    component: EorInvoices,
    permissions: ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR,
  },
  {
    path: '/admin/partner-invoices',
    exact: true,
    component: PartnerInvoices,
    permissions: ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR,
  },
  {
    path: '/admin/renewal',
    exact: true,
    component: Renewal,
  },
  {
    path: '/admin/partner-invoice-breakdown',
    exact: true,
    component: PartnerInvoiceBreakdown,
  },

  {
    path: '/admin/currency-markup',
    exact: true,
    component: CurrencyMarkup,
    permissions: ADMIN_PERMISSIONS.VIEW_CURRENCY_MARKUP,
  },

  {
    path: '/admin/wallet-conversion-markups',
    exact: true,
    component: WalletConversionMarkups,
    permissions: ADMIN_PERMISSIONS.MANAGE_ADMIN_MARKUPS,
  },
  {
    path: '/admin/withdrawal-methods',
    exact: true,
    component: ManageWithdrawalMethods,
    permissions: ADMIN_PERMISSIONS.MANAGE_ADMIN_MARKUPS,
  },

  {
    path: '/admin/provider-rules',
    exact: true,
    component: ProviderRules,
    permissions: ADMIN_PERMISSIONS.VIEW_PROVIDER_RULES,
  },
  {
    path: '/admin/beneficiaries',
    exact: true,
    component: Beneficiaries,
    permissions: ADMIN_PERMISSIONS.MANAGE_BANK_ACCOUNTS,
  },

  {
    path: '/admin/admin-list',
    exact: true,
    component: AdminList,
    permissions: ADMIN_PERMISSIONS.VIEW_ADMINS,
  },
  {
    path: '/admin/admin-roles',
    exact: true,
    component: AdminRoles,
    permissions: ADMIN_PERMISSIONS.VIEW_ADMIN_PERMISSIONS,
  },

  { path: '/admin/due-payments', exact: true, component: PaymentsDetails },
  {
    path: '/admin-due-payments/:companyId',
    exact: true,
    component: PaymentsByCompany,
  },
  { path: '/admin/upcoming-payments', exact: true, component: PaymentsDetails },
  {
    path: '/admin-upcoming-payments/:companyId',
    exact: true,
    component: PaymentsByCompany,
  },
  {
    path: '/admin/forbidden-domains',
    exact: true,
    component: ForbiddenDomains,
  },
]

const adminPanelModalRoutes = [
  {
    path: '/admin/contract-editor',
    component: ContractEditor,
    forceRefresh: true,
  },
  {
    path: '/admin/template-editor',
    component: TemplateEditor,
    forceRefresh: true,
  },
  {
    path: '/admin/cor/sow-create',
    component: CorSowCreation,
  },

  // WEBHOOKS
  {
    path: '/admin/send-data/contracts/:contractId',
    exact: true,
    component: SendDataContract,
  },
]
const anonymousRoutes = [
  { path: '/logout', component: Logout },
  { path: '/contract/invitation', component: InvitationLoader },
]
const ReactNativeRoutes = [
  { path: '/StripeFormReactNative', component: StripeFormReactNative },
]

const modalRoutes = [
  { path: '/slack/auth', component: SlackAuthPage },
  { path: '/2fa-reminder', component: TwoFaReminderPage },
  {
    path: '/pay-invoices/:id',
    component: PayInvoices,
    permissions: [permissions.PrepareTransactions],
  },
  { path: '/pay-insurance/:id', component: PayInsurance },
  { path: '/pay-insurance', component: PayInsurance },
  {
    path: '/pay-invoices',
    component: PayInvoices,
    permissions: [permissions.PrepareTransactions],
  },
  { path: '/review-payments-confirmed', component: ReviewPaymentsConfirmed },
  { path: '/withdrawal-process', component: WithdrawProcess, exact: true },
  {
    path: '/withdrawal-process/:type',
    component: WithdrawProcess,
    exact: true,
  },
  { path: '/registration-document', component: RegistrationDocument },
  { path: '/complete-profile', component: CompleteProfile },
  { path: '/complete-company', component: CompleteCompany },
  { path: '/idwise-verification', component: IdwiseVerification },
  { path: '/switch', component: Switch },
  { path: '/account-type', component: AccountType },
  { path: '/contract-editor', component: ContractEditor, forceRefresh: true },
  { path: '/template-editor', component: TemplateEditor, forceRefresh: true },
  { path: '/employee/join', component: EmployeeJoiningFormPage },
  {
    path: '/external-invoice/create/:stepKey?',
    component: NewExternalInvoice,
  },
  {
    path: '/time-off-policies/add/:stepKey?',
    component: NewTimeOffPolicy,
  },
  ...(featureFlags.AUTOMATION_COMPANIES_TAB
    ? [
        {
          path: '/automations/add',
          component: NewAutomationModal,
        },
      ]
    : []),
  {
    path: '/contract/create',
    component: CreateContractV3,
    permissions: [permissions.CreateContracts],
  },
  {
    path: '/contract/bulk-creation/:stepKey?',
    component: ContractBulkCreation,
    permissions: [permissions.CreateContracts],
  },
  {
    path: '/contract/bulk-operations/:stepKey?',
    component: ContractBulkOperations,
    // @todo: do we have bulk addition permissions?
    permissions: [permissions.CreateContracts],
  },
  {
    path: '/contract/create-v2',
    component: () => <Redirect to='/contract/create' />,
    permissions: [permissions.CreateContracts],
  },
  {
    path: '/org-chart',
    component: OrganizationChartPage,
  },
  {
    path: '/document/create',
    component: CreateDoc,
    permissions: [permissions.createDocument],
  },
  {
    path: '/contract/request',
    component: RequestContract,
    permissions: [permissions.CreateContracts],
  },
  { path: '/entity/add', component: Entity },
  { path: '/scan-invoice', component: ScanInvoice },
  {
    path: '/bills/vendors/create/:stepKey?',
    component: CreateVendor,
    permissions: [permissions.ManageVendors],
  },

  {
    path: '/bills/create',
    component: CreateAndEditBill,
    exact: true,
    permissions: [
      permissions.ManageBill,
      permissions.ManageOwnBills,
      permissions.ManageAllBills,
    ],
  },
  {
    path: '/bills/create/go-to-vendor/:vendorId',
    component: GoToVendorScreen,
    exact: true,
  },
  {
    path: '/bills/edit/:billId',
    component: CreateAndEditBill,
    permissions: [
      permissions.ManageBill,
      permissions.ManageAllBills,
      permissions.ViewOwnBills,
    ],
  },

  { path: '/cards/topup', component: CardTopup },
  { path: '/cards/offload', component: CardOffload },
  { path: '/cards/convert', component: CardConvert },
  { path: '/cards/start-kyc', component: CardsKyc },
  { path: '/cards/request-physical', component: CardsRequestPhysical },
  { path: '/cards/activate-physical-card', component: ActivatePhysicalCard },
  { path: '/cards/reset-pin/:cardId', component: ResetPin },

  // ----
  { path: '/document/generate/:id', component: DeDocumentGenerate },

  // Integrations
  { path: '/integrations/xero', component: XeroLoadingPage },
  { path: '/integrations/quickbooks', component: QuickbookLoading },
  { path: '/integrations/freshbooks', component: FreshbookLoading },
  { path: '/integrations/zoho', component: ZohoBooksLoading },
  { path: '/integrations/netsuite', component: NetSuiteLoading },
  { path: '/integrations/hibob', component: HibobIntegration },
  { path: '/integrations/sapling', component: SaplingIntegration },
  { path: '/integrations/bamboohr', component: BambooIntegration },
].filter(Boolean)

export {
  adminPanelModalRoutes,
  adminPanelRoutes,
  anonymousRoutes,
  authAdminRoutes,
  authRoutes,
  modalRoutes,
  ReactNativeRoutes,
  userRoutes,
}
