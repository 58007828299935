import { CheckCircle, DownloadSimple, X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../../../components/Common/modal-close-button'
import { StyledH2, StyledH5, StyledH6 } from '../../../../../components/Typo'
import Button from '../../../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../../../core/config/contract-types'
import { userTypes } from '../../../../../helpers/enum'
import { useFetch, usePermissions } from '../../../../../helpers/hooks'
import permissions from '../../../../../helpers/permissions'
import {
  approveExpense,
  deleteExpense,
  rejectExpense,
} from '../../../../../services/api'
import openFileV2 from '../../../../../utils/file/open-v2'
import ExpenseModal from '../../../../Contract/components/ExpensesModal'
import { BaseAmount } from '../../../../expenses/components/base-amount'

const ExpenseDetails = () => {
  const user = useSelector((state) => state?.Account?.user)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const location = useLocation()
  const history = useHistory()
  const { expense, contract } = location?.state ?? {}
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [showNotified, setShowNotified] = useState(true)
  const isLoading = false
  const { hasAccess } = usePermissions()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.currency?.code || 'USD',
  })

  const remove = useFetch({
    action: deleteExpense,
    onComplete: () => {
      history.goBack()
    },
  })
  const approve = useFetch({
    action: approveExpense,
    onComplete: () => {
      history.goBack()
    },
  })
  const reject = useFetch({
    action: rejectExpense,
    onComplete: () => {
      history.goBack()
    },
  })

  return (
    <div className='page-content position-relative'>
      {user?.type === userTypes.CONTRACTOR && !!showNotified && (
        <div
          className='position-absolute left-0 right-0 bg-white p-3 px-md-5 px -3 d-flex justify-content-between align-items-center'
          style={{
            top: 'var(--header-height)',
            minHeight: 66,
          }}
        >
          <div className='d-flex align-items-start'>
            <CheckCircle
              size='1.4rem'
              weight='fill'
              color='var(--success)'
              style={{ marginRight: 16 }}
            />

            <h6 className='font-size-16 font-weight-light mb-0'>
              This expense will be paid out in your next regular payroll.
            </h6>
          </div>
          <button
            className='rp-btn-nostyle p-1 d-flex'
            onClick={() => setShowNotified(false)}
            type='button'
          >
            <X weight='bold' size={20} />
          </button>
        </div>
      )}

      {isLoading ? (
        <Col style={{ minHeight: '30rem' }}>
          <Row
            style={{ minHeight: '30rem' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <Container fluid className='p-0 m-0'>
          {showNotified && user?.type === userTypes.CONTRACTOR && (
            <div style={{ minHeight: '70px' }} />
          )}
          <Col>
            <Row className='align-items-center'>
              <a onClick={() => history.goBack()}>
                <i className='bx bx-arrow-back pr-2 font-size-20' />
              </a>
              <StyledH2
                max='32px'
                min='22px'
                className='text-dark rp-font-bold'
              >
                Expense details
              </StyledH2>
            </Row>
          </Col>
          <Row className='p-0 m-0 mt-3'>
            <Col
              xs={12}
              md={6}
              className='p-0 mb-3 mb-md-0 bg-surface-10 p-3 rounded border-dashed border-surface-30 d-flex justify-content-center align-items-center flex-column'
              style={{ width: '100%' }}
            >
              {expense?.photo ? (
                <>
                  <img
                    style={{ maxHeight: '100%', height: 280 }}
                    src={expense?.photo}
                  />
                  <Button
                    className='mt-3 text-primary'
                    onClick={() => {
                      openFileV2(expense?.photo, { isDataUrl: true })
                    }}
                    color='transparent'
                    icon={<DownloadSimple size={20} />}
                  >
                    Download
                  </Button>
                </>
              ) : (
                <div
                  className='h-100 w-75 border rounded border-secondary-30 d-flex align-items-center justify-content-center text-secondary'
                  style={{ minHeight: '300px' }}
                >
                  No receipt provided
                </div>
              )}
            </Col>

            <Col xs={12} md={6} className='p-0 bg-white rounded border'>
              <div className='px-3 px-md-4 pt-3 pt-md-4'>
                <div className='mb-4'>
                  <StyledH5 style={{ marginBottom: 12 }} max='14px'>
                    Expense
                  </StyledH5>
                  <StyledH6 className='text-secondary font-weight-light font-size-14'>
                    {expense?.name}
                  </StyledH6>
                </div>
                <div className='mb-4'>
                  <StyledH5 style={{ marginBottom: 12 }} max='14px'>
                    Date
                  </StyledH5>
                  <StyledH6 className='text-secondary font-weight-light font-size-14'>
                    {expense?.date}
                  </StyledH6>
                </div>
                <div className='mb-4'>
                  <StyledH5 style={{ marginBottom: 12 }} max='14px'>
                    Amount
                  </StyledH5>
                  <StyledH6 className='text-secondary font-weight-light font-size-14'>
                    {!isEmployee && formatter.format(expense?.amount)}
                    <BaseAmount
                      baseAmount={expense?.base_amount}
                      baseCurrency={expense?.base_currency}
                    />
                  </StyledH6>
                </div>
              </div>
              <div className='px-3 px-md-4 py-3 pt-md-4 border-top d-flex justify-content-between'>
                {user?.type === userTypes.COMPANY ? (
                  <div className='d-flex align-items-center justify-content-between w-100 gap-6 flex-wrap'>
                    {expense.status.id === 2 && (
                      <>
                        <Button
                          color='danger'
                          loading={reject.isLoading}
                          onClick={() => setShow2(true)}
                          disabled={
                            !(
                              hasAccess(permissions.rejectExpense) ||
                              contract?.can_approve
                            )
                          }
                        >
                          Decline
                        </Button>
                        <Button
                          color='success'
                          loading={approve.isLoading}
                          onClick={() => {
                            approve.startFetch({ expense_id: expense?.id })
                          }}
                          disabled={
                            !(
                              hasAccess(permissions.approveExpense) ||
                              contract?.can_approve
                            )
                          }
                        >
                          Approve
                        </Button>
                      </>
                    )}
                  </div>
                ) : expense?.status?.id === 2 &&
                  ((contract?.type === CONTRACT_TYPES.FULL_TIME &&
                    isEmployee) ||
                    contract?.type !== CONTRACT_TYPES.FULL_TIME) ? (
                  <Button
                    color='danger'
                    loading={remove.isLoading}
                    disabled={remove.isLoading}
                    onClick={() => {
                      remove.startFetch({ expense_id: expense?.id })
                    }}
                    block
                  >
                    Delete Expense
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
          <ExpenseModal show={show} hide={() => setShow(false)} />
          <ConfirmDeleteExpenseModal
            show={show2}
            hide={() => setShow2(false)}
            reject={(reason) => {
              reject.startFetch({ expense_id: expense?.id, reason })
            }}
          />
        </Container>
      )}
    </div>
  )
}

export function ConfirmDeleteExpenseModal({ show, hide, reject }) {
  const [reason, setReason] = useState('')

  return (
    <Modal isOpen={show} toggle={hide} centered={true} className='confirmModal'>
      <ModalHeader
        className='!tw-border-none !tw-pb-0'
        close={<ModalCloseButton toggle={hide} />}
      >
        Decline Reason
      </ModalHeader>
      <ModalBody>
        <textarea
          name='raison'
          className='form-control'
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between justify-content-md-end'>
        <Button color='light' outline onClick={hide}>
          Cancel
        </Button>
        <Button
          color='danger'
          onClick={() => {
            if (!reason) {
              toastr.error('Please set a reason!')
              return
            }
            reject(reason)
            hide()
          }}
        >
          Decline
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ExpenseDetails
