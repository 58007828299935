import { Paperclip } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import toastr from 'toastr'
import { ActionsDropdown } from 'ui'

import TableH from '../../../components/Table/TableH'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { downloadFile, removeRequestDoc } from '../../../services/api'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import TabEmpty from '../components/tab/tab-empty'
import { RequestFormModal } from './documents-request-form-modal'
import DataTable from '../../../components/ui/data-table'
import openFileV2 from '../../../utils/file/open-v2'

const Documents = ({ docs, contractId, isAdmin, loading, onUpdate }) => {
  const [open, setOpen] = useState(false)
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const downloadDoc = useFetch({
    action: downloadFile,
    onComplete: (data, body) => {
      openFileV2(data, {
        name: `${body?.name}.${data?.type?.split('/')[1]}`,
        download: true,
      })
    },
  })

  const deleteDoc = useFetch({
    action: removeRequestDoc,
    onComplete: () => {
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleDownload = (doc) => downloadDoc.startFetch(doc)

  const handleDelete = (doc) => {
    deleteDoc.startFetch({
      request_id: doc?.id,
    })
  }

  const columns = [
    { Header: 'Name', accessor: 'name' },
    {
      Cell: ({ rowData }) => {
        const actions = []
        if (
          rowData?.can_delete &&
          hasAccess(permissions.DeleteContractsDocuments)
        ) {
          actions.push({
            label: 'Remove',
            onClick: () => handleDelete(rowData),
          })
        }
        if (
          rowData?.link &&
          hasAccess(permissions.DownloadContractsDocuments)
        ) {
          actions.push({
            label: 'Download',
            onClick: () => handleDownload(rowData),
          })
        }
        if (!isAdmin && actions.length > 0) {
          return (
            <span className='tw-flex tw-justify-end'>
              <ActionsDropdown data={actions} />
            </span>
          )
        }
      },
    },
  ]

  const actionButton =
    user?.type === userTypes.COMPANY ? (
      <PermissionTooltip
        showing={
          !hasAccess([
            permissions.RequestContractsDocuments,
            permissions.createDocument,
          ])
        }
        id='add-work-btn-tooltip'
        area={PERMISSION_GROUP.DOCUMENTS.name}
      >
        <Button
          type='button'
          disabled={
            !hasAccess(
              permissions.RequestContractsDocuments,
              permissions.createDocument,
            )
          }
          onClick={() => setOpen(true)}
          className='font-size-14'
        >
          Request Document
        </Button>
      </PermissionTooltip>
    ) : null

  return (
    <>
      {loading ? (
        <Row
          style={{ minHeight: '80vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      ) : docs?.length > 0 ? (
        <Row className='p-0 m-0'>
          <Col xs='12' className='p-0 m-0'>
            <Card className='p-0 m-0'>
              <CardBody className='p-0 m-0'>
                <TableH title='Documents' leftSide={actionButton} />

                <DataTable data={docs} columns={columns} responsive striped />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <TabEmpty
          icon={
            <Paperclip size={250} color='var(--primary)' weight='duotone' />
          }
          title='No documents added'
          subtitle='Submitted compliance documents will be shown here'
        >
          {actionButton}
        </TabEmpty>
      )}

      {!open ? null : (
        <RequestFormModal
          show={open}
          hide={() => setOpen(false)}
          contractId={contractId}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}

export default Documents
