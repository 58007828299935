import React from 'react'
import { Card, CardBody, Container, Row } from 'reactstrap'
import toastr from 'toastr'
import { Search } from 'ui'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import NavTabs from '../../../../components/ui/nav-tabs'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import { useUrlState } from '../../../../helpers/hooks/use-url-state'
import {
  exportDepositListAdmin,
  getAdminCompanies,
  getDepositListAdmin,
  getDepositSummaryAdmin,
  getTransactionList,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import { searchParamsFromObject } from '../../../../utils/search-params-from-object'
import { FilterField } from '../cards/filter-field'
import { useFilters } from '../cards/use-filters'
import { DepositTotals, DepositsList } from './deposits-list'
import { RefundsList } from './refunds-list'
import { getErrorMessage } from '../../../../utils/get-errors'

export function AdminDepositsRefunds() {
  const [activeTab, setActiveTab] = useUrlState({
    name: 'tab',
    defaultValue: 'deposits',
  })

  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0'>Deposit and refunds</h2>
      </div>

      <Card className='pt-2'>
        <CardBody>
          <NavTabs
            options={[
              { label: 'Deposits', value: 'deposits' },
              { label: 'Refunds', value: 'refunds' },
            ]}
            onClickOption={(option) => setActiveTab(option.value)}
            activeOption={activeTab}
          />
        </CardBody>
        <CardBody>
          {
            {
              deposits: <Deposits />,
              refunds: <Refunds />,
            }[activeTab]
          }
        </CardBody>
      </Card>
    </div>
  )
}

function Deposits() {
  const [filters, setFilters] = useFilters({ page: 1 })

  const {
    data: deposits,
    isLoading: loadingDeposits,
    startFetch: updateDeposits,
    paginator: paginatorDeposits,
  } = useFetch(
    {
      action: getDepositListAdmin,
      body: filters,
      autoFetch: true,
      withAdminAccess: true,
    },
    [filters],
  )

  const {
    data: totals,
    isLoading: loadingTotals,
    startFetch: updateTotals,
  } = useFetch({
    action: getDepositSummaryAdmin,
    autoFetch: true,
    withAdminAccess: true,
  })

  const { data: companiesList, isLoading: loadingCompaniesList } = useFetch({
    action: getAdminCompanies,
    autoFetch: true,
    withAdminAccess: true,
  })

  const { startFetch: exportDeposits, isLoading: loadingExportDeposits } =
    useFetch({
      action: exportDepositListAdmin,
      withAdminAccess: true,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Error exporting deposits')
        } else {
          const dateWithTimestamp = new Date().toISOString().replace(/:/g, '-')

          // eslint-disable-next-line @typescript-eslint/no-unused-vars -- we don't need the page in the filename
          const { page: _, ...rest } = filters
          const sp = searchParamsFromObject(rest, false, true)

          openFileV2(data, {
            name: `deposits-export-${dateWithTimestamp}-${sp}.xlsx`,
            download: true,
          })
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const companyOptions = Array.isArray(companiesList)
    ? companiesList.map((e) => ({ label: e.name, value: e.id }))
    : []

  const companyItem = companyOptions?.find(
    (option) => option.value === filters.company_id,
  )

  return (
    <>
      <DepositTotals totals={totals} loading={loadingTotals} />
      <div className='tw-my-4 tw-grid tw-gap-3 md:tw-grid-cols-12'>
        <Search
          placeholder='Search by: contractor name, contract ID, transaction ID'
          wrapperClassName='tw-col-span-4'
          onQueryChanged={(text) =>
            setFilters('search_key', text, { action: 'clear' })
          }
        />

        <CustomSelect
          options={companyOptions}
          value={companyItem}
          onChange={(value) => setFilters('company_id', value?.value)}
          isLoading={loadingCompaniesList}
          isDisabled={loadingCompaniesList}
          placeholder='Select Company'
          wrapperClassName='tw-col-span-6'
          isClearable
        />

        <Button
          type='button'
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars -- we don't need the page in the request
            const { page: _, ...rest } = filters
            exportDeposits(rest)
          }}
          loading={loadingExportDeposits}
          disabled={loadingExportDeposits}
          className='tw-col-span-2'
        >
          Export
        </Button>
      </div>

      {loadingDeposits || !deposits ? (
        <Loader minHeight='max(40vh, 440px)' />
      ) : (
        <>
          <DepositsList
            deposits={deposits}
            updateDeposits={() => updateDeposits(filters)}
            updateTotals={updateTotals}
          />

          <div className='d-flex justify-content-end px-3 mt-3'>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={paginatorDeposits?.per_page ?? 20}
              totalItemsCount={paginatorDeposits?.total ?? 0}
              onChange={(page) => setFilters('page', page)}
            />
          </div>
        </>
      )}
    </>
  )
}

const refundStatusOptions = [
  { label: 'Processing', value: 'processing' },
  { label: 'Confirmed', value: 'confirmed' },
]
function Refunds() {
  const [filters, setFilters] = useFilters({
    page: 1,
    archived: 0,
    status: refundStatusOptions[0],
    tab: 'refunds',
  })

  const {
    data: refunds,
    isLoading,
    paginator,
  } = useFetch(
    {
      autoFetch: true,
      withAdminAccess: true,
      action: getTransactionList,
      body: {
        ...filters,
        status: filters?.status?.value ?? filters?.status,
        search: filters?.search || undefined,
      },
    },
    [filters],
  )

  if (isLoading || !refunds) {
    return <Loader minHeight='max(40vh, 440px)' />
  }

  return (
    <>
      <Filters filters={filters} onChange={setFilters} />

      <RefundsList refunds={refunds} />

      <div className='d-flex justify-content-end px-3'>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={paginator?.per_page ?? 20}
          totalItemsCount={paginator?.total ?? 0}
          onChange={(page) => setFilters('page', page)}
        />
      </div>
    </>
  )
}

function Filters({ filters, onChange }) {
  const filtersFields = [
    {
      name: 'status',
      options: refundStatusOptions,
      type: 'select',
    },
    { name: 'search', type: 'text', placeholder: 'Search ...' },
  ]

  return (
    <Container fluid>
      <Row>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}
