import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function handleState(func, message, callback) {
  if (message && typeof func === 'function') {
    func(message)
  }
  if (typeof callback === 'function') {
    callback()
  }
}

export function handleErrorOnComplete({
  data,
  onError,
  errorMessage,
  onSuccess,
  successMessage,
}) {
  if (
    Object.prototype.hasOwnProperty.call(data, 'success') &&
    data.success === false
  ) {
    handleState(toastr.error, errorMessage, onError)
  } else {
    handleState(toastr.success, successMessage, onSuccess)
  }
}
