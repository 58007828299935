import React from 'react'

import Payslips from '../Fulltime/Payslips'

export default function PayslipsPage() {
  return (
    <div className='page-content'>
      <Payslips />
    </div>
  )
}
