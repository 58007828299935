import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import ControlledInput from '../../../../components/ControlledInput'
import InputFeedback from '../../../../components/ui/input-feedback'
import LabelContent from './label-content'

export default function ProbationPeriodField({
  control,
  errors,
  disabled = false,
}) {
  return (
    <>
      <label>
        <LabelContent required>Probation period:</LabelContent>
      </label>

      <InputGroup className='flex-nowrap'>
        <ControlledInput
          className='rounded-right-0'
          wrapperClassName='w-100'
          control={control}
          name='probation_period'
          type='number'
          min={0}
          showError={false}
          disabled={disabled}
        />

        <InputGroupAddon addonType='append'>
          <InputGroupText>Calendar Days</InputGroupText>
        </InputGroupAddon>
      </InputGroup>

      {!errors?.probation_period?.message ? null : (
        <InputFeedback className='tw-mt-1'>
          {errors.probation_period.message}
        </InputFeedback>
      )}
    </>
  )
}
