import React from 'react'
import { Card } from 'reactstrap'
import { cn } from 'ui'

export function VendorCard({ className, children }) {
  return (
    <Card className={cn('tw-rounded tw-bg-white tw-shadow', className)}>
      {children}
    </Card>
  )
}
