import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import {
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import ControlledSelect from '../../../../../components/ControlledSelectNew'
import ControlledInput from '../../../../../components/ControlledInputNew'
import { useFetch } from '../../../../../helpers/hooks'
import { getSpecialists } from '../../../../../services/api'
import toastr from 'toastr'
import { useSelector } from 'react-redux'
import { mapCountryToOption } from '../../../../../utils/map-to-option'
import Button from '../../../../../components/ui/button'
import { Check } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { CONTRACT_STATUS } from '../../../../../helpers/enum'
import { getFullName } from '../../../../../utils/get-full-name'
import { ControlledToggle } from '../../../../../components/Forms/Toggle/controlled-toggle'
import { SubmitMondayDotComTemplate } from './submit-monday-dot-com-template'

const EMPLOYEE_INVITED = 'Employee invite'
const CLIENT_NOTIFIED = 'Client notified'
const schema = yup.object().shape({
  onboarding_specialist_id: yup.string().required('Specialist is required'),
  notify_client: yup.boolean(),
  notify_employee: yup.boolean(),
})

function InviteEmployeeForm({
  contract,
  onSubmit,
  specialist,
  isLoading,
  sendInviteDate,
  refetchContract,
  setSpecialist,
}) {
  const { eor_countries: eorCountries } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )
  const [showInviteModal, setShowInviteModal] = useState(false)
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      employee_first_name: contract?.employee?.first_name,
      employee_last_name: contract?.employee?.last_name,
      employee_email: contract?.employee?.email,
      working_from_country_id: contract?.employee?.working_from_country?.id,
      onboarding_specialist_id: specialist?.value ?? null,
      notify_client: true,
      notify_employee: true,
    },
  })
  const { data: requestsSpecialists } = useFetch({
    action: getSpecialists,
    withAdminAccess: true,
    autoFetch: true,
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    const selectedSpecialist = requestsSpecialists
      ?.filter((c) => c.status === 'active')
      .map((c) => ({
        value: c.id,
        label: getFullName(c),
      }))
      .find((p) => p?.value === watch('onboarding_specialist_id'))
    setSpecialist(selectedSpecialist)
  }, [watch('onboarding_specialist_id')])
  useEffect(() => {
    setValue('onboarding_specialist_id', specialist?.value)
  }, [specialist, setValue])
  return (
    <>
      <Form
        autoComplete='off'
        className='form-horizontal m-0 pt-3 pt-md-4'
        onSubmit={handleSubmit(onSubmit)}
      >
        {showInviteModal ? (
          <ResendInviteModal
            isOpen={showInviteModal}
            toggle={() => setShowInviteModal(false)}
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            sending={isLoading}
            history={contract?.employee_invitation_history}
            errors={errors}
          />
        ) : null}
        <Col className='p-0 overflow-md-hidden'>
          <Label className='text-gray-h font-size-16 mb-4' tag='div'>
            Employee Info
          </Label>
          <Row>
            <Col md={6} sm={12} className='mb-3'>
              <ControlledInput
                control={control}
                label='First Name'
                name='employee_first_name'
                error={errors.employee_first_name}
                disabled={true}
              />
            </Col>
            <Col md={6} sm={12} className='mb-3'>
              <ControlledInput
                control={control}
                label='Last Name'
                name='employee_last_name'
                error={errors.employee_last_name}
                disabled={true}
              />
            </Col>
            <Col md={6} sm={12} className='mb-3'>
              <ControlledInput
                control={control}
                label='Email address'
                name='employee_email'
                error={errors.employee_email}
                disabled
              />
            </Col>
            <Col md={6} sm={12} className='mb-3'>
              <ControlledSelect
                options={eorCountries?.map((c) => mapCountryToOption(c))}
                label='Country Of Employment'
                control={control}
                name='working_from_country_id'
                error={errors.working_from_country_id}
                isDisabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className='mb-3'>
              <ControlledSelect
                options={
                  requestsSpecialists?.length > 0
                    ? requestsSpecialists
                        ?.filter((c) => c.status === 'active')
                        .map((c) => ({
                          value: c.id,
                          label: getFullName(c),
                        }))
                    : []
                }
                label='Assign an Employee Experience Specialist'
                control={control}
                name='onboarding_specialist_id'
                error={errors.onboarding_specialist_id}
                disabled={
                  contract?.status?.id !== CONTRACT_STATUS.ONGOING.value
                }
              />
            </Col>
            {contract?.employee_invited ? (
              <Col md={6} sm={12} className='mb-3'>
                <div className='py-3 tw-flex tw-items-center tw-justify-center tw-gap-1 tw-rounded tw-bg-primary-20'>
                  <Check
                    size={20}
                    className='tw-rounded-full tw-bg-primary-20 tw-text-primary-100'
                  />
                  <span className='rp-font-medium font-size-14 tw-text-primary'>
                    {`Invitation sent on ${format(
                      new Date(sendInviteDate),
                      'dd/MM/yyyy',
                    )}`}
                  </span>
                </div>
                <Button
                  text='Resend Invite'
                  color='transparent'
                  textClassName='tw-text-primary'
                  className='p-0 mt-2'
                  onClick={() => setShowInviteModal(true)}
                />
              </Col>
            ) : (
              <Col md={6} sm={12} className='mb-3'>
                <Button
                  id='invite-employee-button'
                  size='lg'
                  className='tw-h-fit tw-w-full'
                  textClassName='font-size-14 py-1'
                  onClick={handleSubmit(onSubmit)}
                  text='Invite Employee'
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    contract?.status?.id !== CONTRACT_STATUS.ONGOING.value ||
                    !specialist
                  }
                />
                {contract?.status?.id !== CONTRACT_STATUS.ONGOING.value && (
                  <UncontrolledTooltip
                    placement='bottom'
                    target='invite-employee-button'
                  >
                    The contract should be ongoing to invite employee
                  </UncontrolledTooltip>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Form>
      <SubmitMondayDotComTemplate
        contract={contract}
        refetchContract={refetchContract}
      />
    </>
  )
}
function ResendInviteModal({
  isOpen,
  toggle,
  control,
  onSubmit,
  sending,
  history = [],
  errors,
}) {
  const { notify_employee: notifyEmployee, notify_client: notifyClient } =
    useWatch({ control })
  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose>
      <ModalHeader toggle={toggle}>Resend invite</ModalHeader>

      <ModalBody className='p-0'>
        <div className='px-3 py-4'>
          <Label
            className='rp-font-bold font-size-16 my-3 tw-text-black'
            tag='div'
          >
            History
          </Label>
          {!history || history.length === 0
            ? 'No history found.'
            : history.map((p, index) => {
                return (
                  <div
                    key={index}
                    className='border-bottom tw-flex tw-items-center tw-justify-between tw-py-2'
                  >
                    <p className='font-size-14 tw-mb-0 tw-text-text-100'>
                      {p?.type === 'employee'
                        ? EMPLOYEE_INVITED
                        : CLIENT_NOTIFIED}
                    </p>
                    <p className='rp-font-semibold font-size-14 tw-mb-0 tw-text-text-100'>
                      {format(new Date(p?.date), 'MM/dd/yyyy hh:mm:ss')}
                    </p>
                  </div>
                )
              })}
        </div>
        <div className='border-bottom'></div>
        <div className='px-3 py-4'>
          <FormGroup>
            <div className='tw-flex tw-items-center tw-justify-between tw-rounded-tl tw-rounded-tr tw-border tw-border-surface-30 tw-p-6'>
              <div className='tw-flex tw-gap-4'>
                <div>
                  <div className='tw-text-base tw-font-semibold'>
                    Employee invite
                  </div>
                  <div className='tw-text-sm tw-text-text-30'>
                    Are you sure you want to resend the Employee invite?
                  </div>
                </div>
              </div>
              <div>
                <ControlledToggle
                  control={control}
                  name='notify_employee'
                  //   defaultValue={true}
                  error={errors.notify_employee}
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <div className='tw-flex tw-items-center tw-justify-between tw-rounded-tl tw-rounded-tr tw-border tw-border-surface-30 tw-p-6'>
              <div className='tw-flex tw-gap-4'>
                <div>
                  <div className='tw-text-base tw-font-semibold'>
                    Notify client about delay
                  </div>
                  <div className='tw-text-sm tw-text-text-30'>
                    Do you want to send an email to the client regarding the
                    delay of this invitation?
                  </div>
                </div>
              </div>
              <div>
                <ControlledToggle
                  control={control}
                  name='notify_client'
                  // defaultValue={true}
                  error={errors.notify_client}
                />
              </div>
            </div>
          </FormGroup>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={sending}>
          Cancel
        </Button>

        <Button
          onClick={onSubmit}
          disabled={sending || (!notifyEmployee && !notifyClient)}
          loading={sending}
        >
          Send
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default InviteEmployeeForm
