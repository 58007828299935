import Dagre from '@dagrejs/dagre'

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

export const DAGRE_DIRECTION = {
  TB: 'TB',
  LR: 'LR',
}

export function getLayoutedElements(nodes, edges, options) {
  g.setGraph({ rankdir: options.direction })

  edges.forEach((edge) => g.setEdge(edge.source, edge.target))
  nodes.forEach((node) => g.setNode(node.id, node))

  Dagre.layout(g)

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id)
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - node.width / 2
      const y = position.y - node.height / 2

      return { ...node, position: { x, y } }
    }),
    edges,
    labels: g.graph(),
  }
}
