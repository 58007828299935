import {
  ArrowLeft,
  Eye,
  Flag,
  Info,
  MapPin,
  MapTrifold,
  Notebook,
  Plus,
  Storefront,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import toastr from 'toastr'
import { useSelector } from 'react-redux'

import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import Head from '../../../../components/head'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import CountryFlag, { getFlagUrlFromIso2 } from '../../../../components/ui/flag'
import PageHeading from '../../../../components/ui/page-heading'
import Pagination from '../../../../components/ui/pagination'
import Shimmer from '../../../../components/ui/shimmer'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  sideMenuGridClass,
  SideMenuHeader,
  SideMenuHeading,
  useSideMenuState,
} from '../../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import { useUrlStateV2 } from '../../../../helpers/hooks/use-url-state'
import permissions from '../../../../helpers/permissions'
import {
  deleteVendor,
  getVendors,
} from '../../../../services/api-bill-payments'
import { mapListToOption } from '../../../../utils/map-to-option'
import { FormSectionHr } from '../../../Contract/CreateContract/components/form-section-title'
import { EmptyState } from '../../../review-center/empty-state'
import { DetailsInfoList } from '../../../review-center/review-layout-details-components'
import BillCategoryIcon from '../../bill-category-icon'
import {
  AddressBadge,
  BankBadge,
  DetailSectionTitle,
  VENDOR_BANK_ACCOUNT_STATUS,
  VendorBankDetails,
} from '../../detail-section-title'
import { BillInfoText } from '../../list'
import { AddBankSideMenu } from './add-bank-side-menu'
import { EditVendorDetailsSideMenu } from './edit-vendor-details'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { EditBankSideMenu } from './edit-bank-side-menu'
import { useBillModuleActive } from '../../bill-permission-hook'

const PageHeader = () => {
  const { hasAccess } = usePermissions()
  return (
    <PageHeading className='tw-mb-6'>
      <PageHeading.Title className='tw-text-2xl'>
        <PageHeading.ActionGroup className='tw-flex-col tw-gap-0'>
          <Button
            icon={<ArrowLeft size={24} />}
            color='transparent'
            className='!tw-block !tw-p-0'
            tag={Link}
            to='/bills'
          />
          Vendors
        </PageHeading.ActionGroup>
      </PageHeading.Title>
      {hasAccess(permissions.ManageBill) && (
        <Button
          icon={<Plus size={20} />}
          className='!tw-h-14'
          tag={Link}
          to='/bills/vendors/create'
        >
          Add Vendor
        </Button>
      )}
    </PageHeading>
  )
}

const vendorFiltersDefaultValues = {
  selectedItem: undefined,
  category: undefined,
  name: undefined,
  page: 1,
}

const BodyHeader = ({ isLoading, categories, selectedCategory }) => {
  const wrapperClassName = 'tw-grid tw-grid-rows-2 tw-gap-2 md:tw-grid-cols-2'
  const {
    updater: setUrlState,
    value: { name: query },
  } = useUrlStateV2(vendorFiltersDefaultValues, { replaceRoute: true })
  if (isLoading) {
    return (
      <span className={wrapperClassName}>
        <Shimmer width='1fr' />
        <Shimmer width='1fr' />
      </span>
    )
  }

  return (
    <span className={wrapperClassName}>
      <SearchBar
        placeholder='Search by name'
        onQueryChanged={(name) => setUrlState({ name: name || undefined })}
        query={query}
        inputClassName='tw-w-full !tw-h-11'
      />

      <CustomSelect
        placeholder='Category'
        isClearable
        options={categories?.map(mapListToOption)}
        onChange={(val) => setUrlState({ category: val?.label })}
        value={selectedCategory}
        selectStyles={{ control: () => ({ height: '44px' }) }}
      />
    </span>
  )
}
const extractAddressDetails = (item) => {
  const addressDetails = {
    city: item?.city,
    address: item?.address,
    country_id: item?.country?.id,
  }
  return addressDetails
}

export const Title = ({ details }) => {
  const addressDetails = extractAddressDetails(details)
  return (
    <span className='flex tw-gab-2 tw-items-center'>
      Details <AddressBadge details={addressDetails} />
    </span>
  )
}

const DetailsButton = ({
  item,
  fetchVendors,
  activeItem,
  showItem,
  closeItem,
}) => {
  const [isAddBankOpen, toggleAddBank] = useSideMenuState()
  const [isEditOpen, toggleEdit] = useSideMenuState()
  const { hasAccess } = usePermissions()
  const canManageBills = hasAccess(permissions.ManageBill)

  const {
    bank_account: bankAccount,
    name,
    country,
    category,
    city,
    address,
  } = item
  const iconStyle = 'tw-fill-text-60 tw-size-6'

  const isActive = activeItem === item?.id
  return (
    <>
      <Button
        color='link'
        onClick={() => showItem?.()}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {isActive && (
        <SideMenu
          isOpen
          onClose={() => closeItem?.()}
          itemListClassName={sideMenuGridClass()}
        >
          <SideMenuHeader toggle={() => closeItem?.()}>
            <SideMenuHeading title={name} subTitle='Vendor' />
          </SideMenuHeader>

          <SideMenuBody className='tw-flex-1'>
            <DetailsInfoList
              items={[
                {
                  label: 'Vendor',
                  value: <BillInfoText title={name} />,
                  icon: <Storefront className={iconStyle} />,
                },
                {
                  label: 'Category',
                  value: category ? (
                    <BillInfoText title={category.name} />
                  ) : (
                    'N/A'
                  ),
                  icon: <Notebook className={iconStyle} />,
                },
                {
                  label: 'Country',
                  value: country ? (
                    <span className='tw-flex tw-gap-2'>
                      <BillInfoText title={country.name} />
                      <CountryFlag url={getFlagUrlFromIso2(country.iso2)} />
                    </span>
                  ) : (
                    'N/A'
                  ),
                  icon: <Flag className={iconStyle} />,
                },
                {
                  label: 'City',
                  value: city ? <BillInfoText title={city} /> : 'N/A',
                  icon: <MapTrifold className={iconStyle} />,
                },
                {
                  label: 'Recipient address',
                  value: address ? <BillInfoText title={address} /> : 'N/A',
                  icon: <MapPin className={iconStyle} />,
                },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title={<Title details={{ ...item }} />}
                  icon={<Info size={20} className='tw-mr-2 tw-fill-primary' />}
                  containerClassName='tw-mb-2'
                  rightItem={
                    canManageBills && (
                      <Button
                        color='link'
                        className='!tw-px-0 !tw-py-1'
                        onClick={toggleEdit}
                      >
                        Edit
                      </Button>
                    )
                  }
                />
              }
            />
            <EditVendorDetailsSideMenu
              isOpen={isEditOpen}
              toggle={toggleEdit}
              updateVendors={() => fetchVendors(undefined, false)}
              vendor={item}
            />

            <FormSectionHr className='tw-my-6' />

            <VendorBankDetails
              bankInfo={bankAccount}
              handleAddBankAccount={toggleAddBank}
              canManageBills={canManageBills}
              vendor={item}
              updateVendors={() => fetchVendors(undefined, false)}
            />

            <AddBankSideMenu
              isOpen={isAddBankOpen}
              toggle={toggleAddBank}
              updateVendors={() => fetchVendors(undefined, false)}
              vendor={item}
            />
          </SideMenuBody>

          {canManageBills && (
            <SideMenuFooter className='tw-justify-between'>
              <DeleteButton
                onSuccess={() => {
                  closeItem?.()
                  fetchVendors?.()
                }}
                vendor={item}
              />
            </SideMenuFooter>
          )}
        </SideMenu>
      )}
    </>
  )
}

const DeleteButton = ({ onSuccess, vendor }) => {
  const [showConfirm, setShowConfirm] = useState()
  const { isLoading: deletingVendor, startFetch: _deleteVendor } = useFetch({
    action: deleteVendor,
    onComplete: () => {
      toastr.success(`Vendor "${vendor.name}" deleted successfully.`)
      onSuccess()
      setShowConfirm(false)
    },
    onError: (error) => toastr.error(error),
  })

  return (
    <>
      <Button
        color='transparent'
        className='!tw-px-0 !tw-text-systemRed'
        onClick={() => setShowConfirm(true)}
      >
        Delete
      </Button>

      <ConfirmationModal
        toggle={() => setShowConfirm(false)}
        isOpen={showConfirm}
        title='Delete Vendor'
        message='Are you sure you want to delete this vendor'
        onConfirm={() => _deleteVendor({ id: vendor.id })}
        confirmLoading={deletingVendor}
      />
    </>
  )
}

const EditBankDetailsButton = ({ fetchVendors, item }) => {
  const [isAddBankOpen, toggleAddBank] = useSideMenuState()
  const [isEditBankOpen, toggleEditBank] = useSideMenuState()
  const [showConfirm, setShowConfirm] = useState()

  function confirmEditBankDetails() {
    setShowConfirm(false)
    toggleEditBank()
  }
  const hasBankAccount =
    item.bank_account &&
    item?.bank_account?.screening_status !==
      VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS

  return (
    <>
      {hasBankAccount ? (
        <>
          <Button
            color='link'
            onClick={() => setShowConfirm(true)}
            className='!tw-px-4 tw-text-sm !tw-text-primary-100'
          >
            Edit
          </Button>
          <EditBankSideMenu
            isOpen={isEditBankOpen}
            toggle={toggleEditBank}
            vendor={item}
            updateVendors={() => fetchVendors(undefined, false)}
          />
        </>
      ) : (
        <>
          <Button
            color='link'
            onClick={() => toggleAddBank()}
            className='!tw-px-4 tw-text-sm !tw-text-primary-100'
          >
            Add
          </Button>
          <AddBankSideMenu
            isOpen={isAddBankOpen}
            toggle={toggleAddBank}
            updateVendors={() => fetchVendors(undefined, false)}
            vendor={item}
          />
        </>
      )}
      <ConfirmationModal
        toggle={() => setShowConfirm(false)}
        isOpen={showConfirm}
        title='Edit Bank Details'
        message='New bank details you enter will replace the previous ones. Only new bills you create and the bills that are in draft status will have the new bank details. Bills details that are already sent for approval will not be changed.'
        onConfirm={confirmEditBankDetails}
      />
    </>
  )
}
export const EditVendorLocationDetailsButton = ({ item, fetchVendors }) => {
  const [isEditOpen, toggleEdit] = useSideMenuState()
  const addressDetails = extractAddressDetails(item)
  return (
    <>
      <AddressBadge details={addressDetails} />
      <Button
        color='link'
        className='!tw-px-4 tw-text-sm !tw-text-primary-100'
        onClick={toggleEdit}
      >
        Edit
      </Button>
      <EditVendorDetailsSideMenu
        isOpen={isEditOpen}
        toggle={toggleEdit}
        updateVendors={() => fetchVendors(undefined, false)}
        vendor={item}
      />
    </>
  )
}

const Body = () => {
  const {
    value: {
      selectedItem: _selectedItem,
      category,
      name: query,
      page: currentPage,
    },
    updater: setUrlState,
  } = useUrlStateV2(vendorFiltersDefaultValues)

  function setSelectedItem(item) {
    setUrlState({ selectedItem: item })
  }

  const selectedItem = !_selectedItem ? null : Number(_selectedItem)
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const categories = useSelector(
    (state) => state?.Layout?.staticData?.bill_categories,
  )

  const { id: selectedCategoryId, name: selectedCategoryName } =
    categories?.find((cat) => cat.name === category) ?? {}
  const isFiltering = query || selectedCategoryId

  const {
    data: vendors,
    isLoading,
    startFetch: fetchVendors,
    paginator,
  } = useFetch(
    {
      action: getVendors,
      autoFetch: true,
      body: {
        search_key: query,
        category_id: selectedCategoryId,
        page: currentPage,
      },
    },
    [query, selectedCategoryId, currentPage],
  )

  const columns = [
    { Header: 'Vendor Name', accessor: 'name' },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cellData }) => {
        if (!cellData.id) return 'N/A'
        return (
          <span className='tw-flex tw-gap-2'>
            <BillCategoryIcon category={cellData?.name} /> {cellData?.name}
          </span>
        )
      },
    },
    {
      Header: 'Bank Account Details',
      accessor: 'bank_account',
      Cell: ({ cellData, rowData }) => {
        return (
          <>
            <BankBadge status={cellData.screening_status} details={cellData} />
            <EditBankDetailsButton fetchVendors={fetchVendors} item={rowData} />
          </>
        )
      },
    },
    {
      Header: 'Address Details',
      accessor: 'bank_account',
      Cell: ({ rowData }) => {
        return (
          <>
            <EditVendorLocationDetailsButton
              fetchVendors={fetchVendors}
              item={rowData}
            />
          </>
        )
      },
    },
    {
      Cell: ({ rowData }) => (
        <DetailsButton
          item={rowData}
          fetchVendors={fetchVendors}
          activeItem={selectedItem}
          showItem={() => setSelectedItem(rowData?.id)}
          closeItem={() => setSelectedItem(undefined)}
        />
      ),
    },
  ]
  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader
        isLoading={isLoading && !isFiltering}
        categories={categories}
        selectedCategory={
          selectedCategoryId
            ? { label: selectedCategoryName, value: selectedCategoryId }
            : undefined
        }
      />
      {isLoading ? (
        <span className='tw-flex tw-flex-col tw-gap-2'>
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
        </span>
      ) : vendors?.length === 0 ? (
        <EmptyState
          title='You have no vendor to show'
          textElement={
            !isFiltering
              ? 'You can click Add Vendor button and create one.'
              : ''
          }
          pictureElement={
            <Storefront
              weight='duotone'
              className='tw-mb-4 tw-fill-primary'
              size={250}
            />
          }
          className='tw-shadow-none'
        />
      ) : (
        <>
          <DataTable columns={columns} data={vendors} striped responsive />
          <span className='tw-flex tw-justify-end'>
            <Pagination
              activePage={Number(currentPage)}
              onChange={(page) => {
                searchParams.set('page', page)
                history.replace(
                  `${history.location.pathname}?${searchParams.toString()}`,
                )
              }}
              itemsCountPerPage={paginator?.per_page}
              totalItemsCount={paginator?.total ?? 0}
            />
          </span>
        </>
      )}
    </div>
  )
}

export default function Vendors() {
  useBillModuleActive()
  return (
    <div className='page-content'>
      <Head title='Vendors' />
      <PageHeader />
      <Body />
    </div>
  )
}
