import { X } from '@phosphor-icons/react'
import React from 'react'
import { cn } from 'ui'

export function ModalCloseButton({ toggle, size = 20, className }) {
  return (
    <button
      type='button'
      onClick={toggle}
      className={cn(
        'close tw-flex-shrink-0 tw-rounded !tw-p-1.5 tw-text-secondary-100 tw-transition-colors hover:tw-bg-surface-20',
        className,
      )}
      aria-label='Close'
      style={{ width: 'auto', height: 'auto' }}
    >
      <X size={size} />
    </button>
  )
}
