import React from 'react'
import AddNewCard from './add-new-card'
import Button from '../../../components/ui/button'
import { CARD_TYPE_ENUM, getCardType } from '../utils/card-types-status'

const PhysicalCardBanner = ({
  hide,
  handleRequestCard,
  isPhysicalCardSupported,
}) => {
  if (hide) return null

  return (
    <AddNewCard
      cardLabel={getCardType(CARD_TYPE_ENUM.PHYSICAL)?.label}
      requestedCard={CARD_TYPE_ENUM.PHYSICAL}
      action={
        <Button
          type='button'
          onClick={isPhysicalCardSupported ? handleRequestCard : () => {}}
          disabled={!isPhysicalCardSupported}
          color={isPhysicalCardSupported ? 'primary' : 'secondary'}
          style={{ cursor: isPhysicalCardSupported ? null : 'not-allowed' }}
        >
          {isPhysicalCardSupported ? 'Request physical card' : 'Coming soon'}
        </Button>
      }
    />
  )
}
export default PhysicalCardBanner
