import { defaultNumberLocal, getNumberFormatter } from './number'

export function getCurrencyFormatter(
  currency = 'USD',
  locale = defaultNumberLocal,
  options = {},
) {
  const formatter = getNumberFormatter({
    locale,
    options: { style: 'currency', currency, ...options },
  })

  return { format: formatter.format }
}

export const usdFormatter = getCurrencyFormatter()
