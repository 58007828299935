import moment from 'moment'
import React, { useState } from 'react'
import Paginations from 'react-js-pagination'
import { Badge, Card, Col, Container, Row, Spinner, Table } from 'reactstrap'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { StyledH5 } from '../../../../components/Typo'
import UserFlagging from '../../../../components/userFlag'
import { useFetch } from '../../../../helpers/hooks'
import { getTransferList } from '../../../../services/api'
import ContractRef from '../../components/ContractRef'
import { TransferCardAdmin } from '../PendingTransfers'

const ConfirmedTransfers = () => {
  const [activePage, setActivePage] = useState(1)
  const defaultCompany = { label: 'Select Company', value: null }

  const [company, setCompany] = useState(defaultCompany)
  const transfers = useFetch(
    {
      action: getTransferList,
      autoFetch: true,
      withAdminAccess: true,
      body: {
        transferred: 1,
        page: activePage,
        company_id: company?.value ?? undefined,
      },
    },
    [activePage, company?.value],
  )

  const handlePageChange = (page) => {
    setActivePage(page)
  }

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <Row className='justify-content-end mb-3 mb-md-4 p-0 m-0'>
          <Col className='p-0 m-0'>
            <StyledH5 min='22px' max='32px'>
              Confirmed Transfers
            </StyledH5>
          </Col>
        </Row>
        <Row className='p-0 m-0'>
          <Col className='p-0 m-0'>
            <Card className='p-0 m-0'>
              <Container fluid>
                <Row className='p-3' style={{ gap: '0.75rem' }}>
                  <Col md={4} className='px-0'>
                    <CustomSelect
                      options={
                        Array.isArray(transfers?.data?.companies)
                          ? [
                              defaultCompany,
                              ...(transfers?.data?.companies?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultCompany]
                      }
                      value={company}
                      onChange={setCompany}
                    />
                  </Col>
                  <Col md={4} xs={12} className='px-0'>
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        setActivePage(1)
                        setCompany(defaultCompany)
                      }}
                    >
                      Clear Filter
                    </button>
                  </Col>
                </Row>
              </Container>
              {transfers.isLoading ? (
                <Container style={{ minHeight: '30rem' }}>
                  <Col style={{ minHeight: '30rem' }}>
                    <Row
                      style={{ minHeight: '30rem' }}
                      className='justify-content-center align-items-center'
                    >
                      <Spinner type='grow' className='mr-2' color='primary' />
                    </Row>
                  </Col>
                </Container>
              ) : (
                <>
                  <div className='d-block d-md-none p-3'>
                    {transfers?.data?.transfers?.map((order, key) => (
                      <TransferCardAdmin order={order} index={key} key={key} />
                    ))}
                  </div>
                  <div className='table-responsive d-none d-md-block'>
                    <Table className='table table-centered table-nowrap text-muted'>
                      <thead className='thead-light'>
                        <tr>
                          <th
                            className='border-top-0 sticky-col first-col'
                            style={{ '--col-width': '70px' }}
                          >
                            TRX ID
                          </th>
                          <th
                            className='border-top-0 sticky-col second-col'
                            style={{
                              '--col-left': '70px',
                              '--col-width': '120px',
                            }}
                          >
                            Item ID
                          </th>
                          <th
                            className='border-top-0 sticky-col third-col border-col'
                            style={{
                              '--col-left': '220px',
                              '--col-width': '100px',
                            }}
                          >
                            Contract ID
                          </th>
                          <th className='border-top-0'>Company</th>
                          <th className='border-top-0'>Client name</th>
                          <th className='border-top-0'>Contractor name</th>
                          <th className='border-top-0'>Amount</th>
                          <th className='border-top-0'>TRX Amount</th>
                          <th className='border-top-0'>Payment Method</th>
                          <th className='border-top-0'>Due date</th>
                          <th className='border-top-0'>TRX date</th>
                          <th className='border-top-0'>TRX Confirmed date</th>
                          <th className='border-top-0'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transfers?.data?.transfers?.map((order, key) => {
                          const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: order?.currency?.code || 'USD',
                          })
                          const sourceFormatter = new Intl.NumberFormat(
                            'en-US',
                            {
                              style: 'currency',
                              currency: order?.source_currency?.code || 'USD',
                            },
                          )
                          return (
                            <tr key={'_order_' + key}>
                              <td
                                className='sticky-col first-col'
                                style={{ '--col-width': '70px' }}
                              >
                                {order.transaction_ref}
                              </td>
                              <td
                                className='sticky-col second-col text-truncate'
                                style={{
                                  '--col-left': '70px',
                                  '--col-width': '150px',
                                }}
                              >
                                {order.transaction_item_id}
                              </td>
                              <td
                                className='sticky-col third-col border-col'
                                style={{
                                  '--col-left': '220px',
                                  '--col-width': '100px',
                                }}
                              >
                                <ContractRef
                                  isAdmin
                                  contractId={order.contract_ref}
                                />
                              </td>
                              <td>{order.company_name}</td>
                              <td>
                                <div className='d-flex'>
                                  {order.client.is_flagged ? (
                                    <UserFlagging
                                      user={order?.client}
                                      onCompleteAction={() => {
                                        transfers.startFetch({ transferred: 1 })
                                      }}
                                    />
                                  ) : null}
                                  {order.client?.first_name}{' '}
                                  {order.client?.last_name}
                                </div>
                              </td>
                              <td>
                                <div className='d-flex'>
                                  {order.contractor.is_flagged ? (
                                    <UserFlagging
                                      user={order?.contractor}
                                      onCompleteAction={() => {
                                        transfers.startFetch({ transferred: 1 })
                                      }}
                                    />
                                  ) : null}
                                  {order.contractor?.first_name}{' '}
                                  {order.contractor?.last_name}
                                </div>
                              </td>
                              <td className='text-right'>
                                {formatter.format(order.amount)}
                              </td>
                              <td className='text-right'>
                                {sourceFormatter.format(order.source_amount)}
                              </td>
                              <td>{order.method?.name}</td>
                              <td>
                                {moment(order.due_date).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                {moment(order.created_at).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                {moment(order.confirmed_at).format(
                                  'MM/DD/YYYY',
                                )}
                              </td>
                              <td>
                                <Badge
                                  className='font-size-12 rounded bg-soft-success py-1'
                                  color='white'
                                  pill
                                >
                                  <p className='mb-0 text-success'>
                                    {order.transferred === 0
                                      ? 'Not transferred'
                                      : 'Transferred'}
                                  </p>
                                </Badge>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Col>
                    <Row className='px-3 justify-content-end'>
                      <Paginations
                        itemClass='page-item'
                        linkClass='page-link'
                        activePage={activePage}
                        itemsCountPerPage={transfers.paginator?.per_page}
                        totalItemsCount={transfers.paginator?.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </Row>
                  </Col>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ConfirmedTransfers
