export const CARD_TYPE_ENUM = { PHYSICAL: 'physical', VIRTUAL: 'virtual' }
export const CARD_TYPES = {
  VIRTUAL: 'rmpgprmccard',
  PHYSICAL: 'rmpgprmcpcard',
}
const CARD_TYPES_OPTIONS = [
  {
    value: CARD_TYPE_ENUM.PHYSICAL,
    label: 'Physical Card',
    labelShort: 'Physical',
  },
  {
    value: CARD_TYPE_ENUM.VIRTUAL,
    label: 'Virtual Card',
    labelShort: 'Virtual',
  },
]

export function getCardType(value) {
  return CARD_TYPES_OPTIONS.find((option) => option.value === value)
}

export const CARD_STATUSES = {
  ACTIVE: 'ACTIVE',
  TEMP_BLOCK: 'TEMP_BLOCK',
  TERMINATED: 'TERMINATED',
  PENDING_ACTIVATION: 'pending activation',
  PENDING: 'PENDING',
}

export function isCardActive(status) {
  if (typeof status !== 'string') return false

  return status === CARD_STATUSES.ACTIVE
}

export default CARD_TYPES_OPTIONS
