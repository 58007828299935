import {
  Asterisk,
  Briefcase,
  Check,
  ClipboardText,
  DotsThreeOutline,
  Headset,
  IdentificationCard,
  ShieldCheck,
  UserCirclePlus,
} from '@phosphor-icons/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'ui'

import BadgeV2 from '../../components/ui/badge-v2'

export function CenterOnboardingItem({
  type,
  contract_id: contractId,
  entity_id: entityId,
  completed,
  in_progress: inProgress,
  link,
  disabled,
  title,
}) {
  const actionProps =
    completed || inProgress
      ? {}
      : getActionProps({ type, link, disabled, contractId, entityId })
  const Comp = actionProps?.to ? Link : actionProps?.onClick ? 'button' : 'div'

  return (
    <Comp
      className={cn(
        'tw-flex tw-w-full tw-items-center tw-gap-4 tw-border-t tw-border-surface-30 tw-p-6 tw-transition-colors',
        {
          'hover:tw-bg-surface-10 hover:tw-text-black':
            !completed && !inProgress,
        },
        completed || inProgress ? 'tw-text-text-80' : 'tw-text-black',
        completed ? 'tw-bg-primary-10' : '',
      )}
      {...actionProps}
    >
      {completed ? (
        <Check
          size={40}
          className='tw-rounded-full tw-bg-primary-20 tw-p-2 tw-text-primary-100'
        />
      ) : inProgress ? (
        <DotsThreeOutline
          size={40}
          className='tw-rounded-full tw-bg-systemGold-20 tw-p-2 tw-text-systemGold-110'
          weight='fill'
        />
      ) : (
        getOnboardingIcon({
          type,
          size: 40,
          className: 'tw-rounded-full tw-bg-primary-100 tw-p-2 tw-text-white',
          weight: 'fill',
        })
      )}

      <div className='tw-text-base tw-font-semibold'>
        {title}

        {!inProgress ? null : (
          <BadgeV2
            size='md'
            className='tw-ml-1 !tw-bg-systemGold-20 !tw-text-systemGold-110'
          >
            In progress
          </BadgeV2>
        )}
      </div>
    </Comp>
  )
}

export const ONBOARDING_TYPES = {
  NEED_ASSISTANCE: { key: 'need_assistance' },
  COMPLETE_COMPANY_PROFILE: { key: 'complete_profile' },
  KYC: { key: 'kyc' },
  COMPANY_DETAILS: { key: 'company_details' },
  FIRST_CONTRACT: { key: 'first_contract' },
  ADD_USERS: { key: 'add_users' },
  VACATION_POLICY: { key: 'vacation_policy' },
  CONTRACT_DETAILS: { key: 'missing_contract_details' },
  ENTITY_DETAILS: { key: 'missing_entity_details' },
}

function getOnboardingIcon({ type, size = 24, className = '', weight }) {
  switch (type) {
    case ONBOARDING_TYPES.NEED_ASSISTANCE.key: {
      return <Headset size={size} className={className} weight={weight} />
    }
    case ONBOARDING_TYPES.COMPLETE_COMPANY_PROFILE.key: {
      return <ClipboardText size={size} className={className} weight={weight} />
    }
    case ONBOARDING_TYPES.KYC.key: {
      return <ShieldCheck size={size} className={className} weight={weight} />
    }
    case ONBOARDING_TYPES.COMPANY_DETAILS.key: {
      return <Briefcase size={size} className={className} weight={weight} />
    }
    case ONBOARDING_TYPES.FIRST_CONTRACT.key: {
      return (
        <IdentificationCard size={size} className={className} weight={weight} />
      )
    }
    case ONBOARDING_TYPES.ADD_USERS.key: {
      return (
        <UserCirclePlus size={size} className={className} weight={weight} />
      )
    }
    default: {
      return <Asterisk size={size} className={className} weight={weight} />
    }
  }
}

function getActionProps({ type, link, disabled, contractId, entityId }) {
  switch (type) {
    case ONBOARDING_TYPES.NEED_ASSISTANCE.key: {
      return {
        to: null,
        onClick: () => {
          window.open(link, '_blank', 'noopener')
        },
      }
    }
    case ONBOARDING_TYPES.COMPLETE_COMPANY_PROFILE.key: {
      return { to: '/settings#profile' }
    }
    case ONBOARDING_TYPES.KYC.key: {
      return { to: '/profile-settings?tab=verification' }
    }
    case ONBOARDING_TYPES.FIRST_CONTRACT.key: {
      return { to: '/contract/create' }
    }
    case ONBOARDING_TYPES.ADD_USERS.key: {
      return { to: '/settings/users' }
    }
    case ONBOARDING_TYPES.COMPANY_DETAILS.key: {
      return disabled ? {} : { to: '/registration-document' }
    }
    case ONBOARDING_TYPES.CONTRACT_DETAILS.key: {
      return {
        to: `contract/detail?id=${contractId}`,
      }
    }
    case ONBOARDING_TYPES.ENTITY_DETAILS.key: {
      return {
        to: `settings?entityId=${entityId}`,
      }
    }

    default: {
      return { to: null }
    }
  }
}
