import { Sparkle } from '@phosphor-icons/react'
import React from 'react'
import { useAutomations } from './use-automations'
import Shimmer from '../../../../components/ui/shimmer'
import { AutomationsCard } from './automations-card'
import Button from '../../../../components/ui/button'

export function AutomationsTemplates(props) {
  const { onShowAll } = props
  const { automations: templates, isLoading } = useAutomations({
    onlyTemplates: true,
  })

  if (isLoading)
    return (
      <div className='tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-4'>
        {Array.from({ length: 4 }, (_, i) => i + 1).map((i) => (
          <Shimmer key={i} width='100%' height='250px' />
        ))}
      </div>
    )

  if (!templates?.length) return null

  return (
    <div className='tw-flex tw-flex-col tw-gap-4 tw-rounded tw-bg-primary-10 tw-p-4'>
      <div className='tw-flex tw-flex-row'>
        <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-y-1'>
          <Sparkle className='tw-text-primary' size={25} />
          <span className='tw-text-lg tw-font-medium'>Templates</span>
          <span>
            Make your alerts more specified based on country, entity and more.
          </span>
        </div>
        <Button
          color='link'
          className='tw-align-self-center !tw-px-0'
          onClick={() => onShowAll()}
          text='See all'
        />
      </div>

      <div className='tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-4'>
        {templates?.slice(0, 4).map((t) => (
          <AutomationsCard
            id={t._id}
            key={t._id}
            title={t.name}
            description={t?.description}
            channels={t?.action_channels}
          />
        ))}
      </div>
    </div>
  )
}
