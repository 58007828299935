import { useState, React } from 'react'
import {
  checkAdminReportStatus,
  exportInsurancePayments,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import toastr from 'toastr'
import { format } from 'date-fns'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'

let interval

const ExportInsurance = ({ date, status }) => {
  const [downloading, setDownloading] = useState(false)

  const { startFetch: checkStatus } = useFetch({
    action: checkAdminReportStatus,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data.url) {
        openFileV2(data.url, { isDataUrl: true, download: true })
        clearInterval(interval)
        setDownloading(false)
      }
    },
    onError: (err) => {
      clearInterval(interval)
      setDownloading(false)
      toastr.error(err)
    },
  })

  const { startFetch: exportInsurance } = useFetch({
    action: exportInsurancePayments,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data.id) {
        interval = setInterval(() => {
          checkStatus({ id: data?.id })
        }, 1000 * 5)
      } else {
        const name = `insurance-payments.xlsx`
        openFileV2(data, { download: true, name })
        setDownloading(false)
      }
    },
    body: {
      month: date && format(date, 'MM'),
      year: date && format(date, 'yyyy'),
      status,
    },
  })

  return (
    <>
      <Button
        color='primary'
        onClick={() => {
          exportInsurance()
          setDownloading(true)
        }}
        loading={downloading}
        disabled={downloading}
      >
        Export Insurance
      </Button>
    </>
  )
}

export default ExportInsurance
