import { Nav, NavItem, NavLink } from 'reactstrap'
import React from 'react'
import { cn } from 'ui'
import Shimmer from './ui/shimmer'

export default function AdminTabNav({
  items = [],
  loading,
  tag,
  props,
  className,
}) {
  return (
    <Nav className={cn('tw-h-10 tw-space-x-4', className)}>
      {items.map((item) => {
        const key = item.label?.toLowerCase()?.replaceAll(' ', '_')
        if (loading) {
          return <Shimmer width='152px' height='38px' key={key + '-shimmer'} />
        }

        return (
          <NavItem key={key} className='tw-bg-white'>
            <NavLink
              className={cn(
                'tw-cursor-pointer tw-border-b-2 tw-border-transparent tw-bg-white !tw-px-1 tw-text-sm tw-font-medium tw-text-text-60 tw-transition-colors tw-duration-75',
                item.isActive
                  ? 'tw-border-primary-100 tw-text-primary-100'
                  : 'hover:tw-border-surface-60',
              )}
              onClick={item.onClick}
              tag={tag ?? 'button'}
              {...props}
            >
              {item.label}
            </NavLink>
          </NavItem>
        )
      })}
    </Nav>
  )
}
