import {
  ArrowsLeftRight,
  Buildings,
  CaretDown,
  Gear,
  SignOut,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap'

import { storage } from '../../../helpers/config'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../../helpers/enum'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { sideIconStyles } from '../../BurgerMenu'
import ModalHeader from '../../ModalHeader'

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false)
  const [show, setShow] = useState(false)
  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const { hasAccess } = usePermissions()

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='h-100'>
        <DropdownToggle
          className='btn header-item px-3 py-0 h-100 align-items-center d-flex gap-16'
          id='page-header-user-dropdown'
          tag='button'
        >
          {userProfile?.photo ? (
            <img
              className='rounded-circle header-profile-user object-contain'
              src={userProfile?.photo}
              alt=''
            />
          ) : (
            <div className='rounded-circle header-profile-user'>
              <span
                className={`${
                  isClient ? 'avatar-title-green' : 'avatar-title'
                } rounded-circle`}
              >
                {user?.first_name?.charAt(0)}
              </span>
            </div>
          )}
          <div
            className='d-flex flex-column align-items-start mr-3'
            style={{ minWidth: 120 }}
          >
            <span
              style={{ fontWeight: 600 }}
              className='text-dark font-size-16'
              translate='no'
            >
              {user?.first_name} {user?.last_name}
            </span>
            {!props.isMobile && (
              <span
                style={{ fontWeight: 500 }}
                className='text-secondary font-size-14'
              >
                {isClient
                  ? 'Client'
                  : isEmployee || isDeEmployee
                    ? 'Employee'
                    : 'Contractor'}
              </span>
            )}
          </div>
          <CaretDown size={24} />
        </DropdownToggle>
        <DropdownMenu right className='w-100 mt-2'>
          {user?.type === userTypes.COMPANY && (
            <DropdownItem
              tag={Link}
              to='/profile-settings'
              className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
            >
              <Gear {...sideIconStyles} />
              Profile Settings
            </DropdownItem>
          )}
          {(hasAccess(permissions.ViewCompaniesInfo) || isContractor) &&
            !isEmployee && (
              <DropdownItem
                tag={Link}
                to='/settings'
                className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
              >
                <Buildings {...sideIconStyles} />

                {isClient ? 'Company Settings' : 'Profile Settings'}
              </DropdownItem>
            )}
          {isEmployee && (
            <DropdownItem
              tag={Link}
              to='/settings'
              className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
            >
              <Buildings {...sideIconStyles} />

              {props.t('Profile Settings')}
            </DropdownItem>
          )}
          <div className='dropdown-divider' />
          {!isEmployee &&
            hasAccess(permissions.switchAccount) &&
            user[0] &&
            user[1] && (
              <DropdownItem
                tag='button'
                type='button'
                onClick={() => props.history.push('/switch')}
                className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
              >
                <ArrowsLeftRight {...sideIconStyles} />

                <div>
                  <div className='tw-leading-none'>Switch account</div>
                  <div className='tw-text-xs tw-text-text-70'>{`To ${
                    isContractor ? 'client' : 'contractor'
                  } account`}</div>
                </div>
              </DropdownItem>
            )}
          <DropdownItem
            tag={Link}
            to='/logout'
            className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base !tw-text-systemRed-100 tw-transition-colors hover:!tw-bg-systemRed-80/10'
          >
            <SignOut {...sideIconStyles} className='tw-text-current' />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <SwitchModal
        user={user}
        history={props.history}
        show={show}
        hide={() => setShow(false)}
      />
    </>
  )
}

const SwitchModal = (props) => {
  const { show, hide, history, user } = props
  return (
    <Modal
      style={{
        maxWidth: '100%',
        margin: 0,
        backgroundColor: 'white',
      }}
      isOpen={show}
      toggle={hide}
      className='p-0 switch-modal h-100'
    >
      <Container fluid>
        <ModalHeader action={hide} />
        <ModalBody>
          <div
            style={{
              borderRadius: 10,
              minWidth: '33%',
              maxWidth: '100%',
            }}
          >
            <h3 className='mb-4 mt-4 text-center'>
              Which account would you like to use?
            </h3>
            <div
              onClick={hide}
              style={{ borderRadius: 10 }}
              className='border d-flex align-items-center justify-content-between p-3 mb-3'
            >
              <Row className='p-0 m-0'>
                {!user?.photo ? (
                  <div className='avatar-sm mx-auto'>
                    <span
                      className={`${
                        user?.type === 'client'
                          ? 'avatar-title-green'
                          : 'avatar-title'
                      } rounded-circle text-white font-size-16`}
                    >
                      {user?.first_name
                        ? user?.first_name?.charAt(0)
                        : user?.email?.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      className='rounded-circle avatar-sm'
                      src={`${storage}${user?.photo}`}
                      alt='user-avatar'
                    />
                  </div>
                )}
                <Col>
                  <h5 className='font-size-15 text-dark'>
                    {user?.first_name && user?.last_name}
                  </h5>
                  <Row className='ml-0 flex-nowrap'>
                    <p className='text-muted mb-0'>{`your ${user?.type} account`}</p>
                  </Row>
                </Col>
              </Row>
              <i className='bx bx-chevron-right font-size-24 align-middle mr-1 text-primary' />
            </div>

            <div
              onClick={() => history.push('/switch')}
              style={{ borderRadius: 10 }}
              className='border d-flex align-items-center justify-content-between p-3 mb-3'
            >
              <Row className='p-0 m-0'>
                {!user?.photo ? (
                  <div className='avatar-sm mx-auto'>
                    <span
                      className={`rounded-circle text-white font-size-16 ${
                        user?.type === 'client'
                          ? 'avatar-title-green'
                          : 'avatar-title'
                      }`}
                    >
                      {user?.first_name
                        ? user?.first_name?.charAt(0)
                        : user?.email?.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      className='rounded-circle avatar-sm'
                      src={`${storage}${user?.photo}`}
                      alt='user-avatar'
                    />
                  </div>
                )}
                <Col>
                  <h5 className='font-size-15 text-dark'>
                    {user?.first_name && user?.last_name}
                  </h5>
                  <Row className='ml-0 flex-nowrap'>
                    <p className='text-muted mb-0'>{`your ${
                      user?.type === userTypes.CONTRACTOR
                        ? 'client'
                        : 'contractor'
                    } account`}</p>
                  </Row>
                </Col>
              </Row>
              <i className='bx bx-chevron-right font-size-24 align-middle mr-1 text-primary' />
            </div>
          </div>
        </ModalBody>
      </Container>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { error, success } = state.Profile
  return { error, success }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu)),
)
