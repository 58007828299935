import { getCurrencyFormatter } from '../../../utils/formatters/currency'

export function getBalanceOption(
  balance,
  { disabledEmptyBalance = false } = {},
) {
  if (!balance) return null

  const formatter = getCurrencyFormatter(balance?.currency?.code)
  const label = formatter.format(balance.balance)
  return {
    ...balance,
    value: balance?.id ?? balance?.currency_id,
    label,
    isDisabled: disabledEmptyBalance ? balance?.balance === 0 : false,
  }
}

export function mapBalancesToOptions(
  balances,
  { disabledEmptyBalance = false } = {},
) {
  return balances.map((b) => getBalanceOption(b, { disabledEmptyBalance }))
}
