import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Col, Container, Row } from 'reactstrap'

import { ActionsDropdown, cn } from 'ui'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import SearchBar from '../../../components/SearchBar'
import DataTable from '../../../components/ui/data-table'
import Pagination from '../../../components/ui/pagination'
import Shimmer from '../../../components/ui/shimmer'
import {
  CONTRACT_ACTIVITIES,
  CONTRACT_STATUS,
  userTypes,
} from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  archiveAdminContract,
  archiveContract,
  getContractDetail,
} from '../../../services/api'
import { setContractToDuplicate } from '../../../store/contract/actions'
import ContractRef from '../../AdminPanel/components/ContractRef'
import welcomeImage from './../../../assets/images/welcome.svg'
import ClientCell from './client-cell'
import ContractStatus from './contract-status'
import UserCell from './user-cell'

export function getContractStatusColor(status, lastContractActivity = null) {
  switch (status?.id) {
    case CONTRACT_STATUS.ONGOING.value:
      return [
        CONTRACT_ACTIVITIES.TERMINATION_SCHEDULED,
        CONTRACT_ACTIVITIES.TERMINATION_REQUESTED,
        CONTRACT_ACTIVITIES.TERMINATION_REQUEST_APPROVED,
      ].includes(lastContractActivity)
        ? 'danger'
        : 'success'
    case CONTRACT_STATUS.ENDED.value:
    case CONTRACT_STATUS.CANCELLED.value:
    case CONTRACT_STATUS.TERMINATED.value:
      return 'danger'
    default:
      return 'warning'
  }
}

const ContractList = ({
  Orders,
  onQuery,
  isAdmin,
  withArchive,
  loading,
  archiveValue,
  onUpdate,
  withCustomFilter,
  history,
  paginator,
  page,
  setPage,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const [data, setData] = useState(Orders || [])
  const [query, setQuery] = useState(null)
  const defaultState = { label: 'All', value: -1 }
  const [state, setState] = useState(defaultState)
  const status = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setData(Orders)
  }, [Orders])

  const handleSearch = (query) => {
    setQuery(query)
    onQuery(query)
  }

  const handleFilter = (elm) => {
    setState(elm)
    if (elm?.value === -1) {
      clearFilter()
    } else {
      setData(Orders.filter((s) => s?.status?.id === elm.value))
    }
  }

  const clearFilter = () => {
    setData(Orders)
    setState(defaultState)
  }

  const toDuplicate = useFetch({
    action: getContractDetail,
    onComplete: (data) => {
      dispatch(setContractToDuplicate(data))
      history.push('/contract/create')
    },
  })

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
        contract_type: body?.contract?.type,
      })
    },
  })

  const columns = [
    {
      Header: 'Contract ID',
      accessor: 'ref',
      Cell: ({ cellData }) => (
        <ContractRef
          contractId={cellData}
          isAdmin={isAdmin}
          target={isAdmin ? '_blank' : null}
        />
      ),
    },
    isAdmin && {
      Header: 'Created on',
      accessor: 'created_at',
      Cell: ({ cellData }) =>
        moment(cellData * 1000).format('MM/DD/YYYY HH:mm'),
    },
    {
      Header: 'Role',
      accessor: 'name',
      Cell: ({ cellData }) => <span title={cellData}>{cellData}</span>,
    },
    { Header: 'Type', accessor: 'type' },
    {
      Header: 'Compensation',
      accessor: 'total',
      Cell: ({ cellData }) => {
        const total = cellData?.split('/') ?? []
        return (
          <span className='tw-text-black'>
            {total[0]}
            <span className='tw-text-disabled'> /{total[1]}</span>
          </span>
        )
      },
    },
    isAdmin && { Header: 'Company', accessor: 'company' },
    {
      Header: isAdmin || user?.type === 'client' ? 'Name' : 'Client',
      Cell: ({ rowData }) => (
        <UserCell isAdmin={isAdmin} item={rowData} onUpdate={onUpdate} />
      ),
    },
    isAdmin && {
      Header: 'Client',
      Cell: ({ rowData }) => (
        <ClientCell isAdmin item={rowData} onUpdate={onUpdate} />
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cellData, rowData }) => (
        <ContractStatus
          contract={rowData}
          badgeStatus={getContractStatusColor(cellData)}
        />
      ),
    },
    withArchive &&
      hasAccess(permissions.archiveContracts) && {
        accessor: 'status',
        Cell: ({ cellData, rowData }) => {
          const actionsOptions = []
          const canArchive =
            (cellData?.id !== 4 || archiveValue === 0 || isAdmin) &&
            withArchive &&
            hasAccess(permissions.archiveContracts)

          const canDuplicate =
            user?.type !== userTypes.CONTRACTOR &&
            rowData?.type !== 'Full Time' &&
            !isAdmin &&
            hasAccess(permissions.archiveContracts)

          if (canArchive) {
            actionsOptions[0] = {
              label: archiveValue === 0 ? 'Unarchive' : 'Archive',
              onClick: () => {
                archive.startFetch({
                  contract_id: rowData.id,
                  status: archiveValue,
                  contract: rowData,
                })
              },
            }
          }

          if (canDuplicate) {
            actionsOptions[1] = {
              label: 'Duplicate',
              onClick: () => {
                toDuplicate.startFetch({ id: rowData?.ref })
              },
            }
          }

          return (
            actionsOptions?.length > 0 && (
              <ActionsDropdown
                data={actionsOptions}
                loading={archive.isLoading || toDuplicate.isLoading}
              />
            )
          )
        },
      },
  ].filter(Boolean)

  return Orders || isAdmin ? (
    loading ? (
      <>
        {withArchive && (user?.type !== userTypes.CONTRACTOR || isAdmin) && (
          <Container fluid>
            <Row className='pb-3 px-3' style={{ gap: '0.75rem' }}>
              <Col className='px-0'>
                <Shimmer width='100%' height='42px' />
              </Col>
              {withCustomFilter && user?.type === userTypes.COMPANY && (
                <Col md={4} xs={12} className='px-0'>
                  <Shimmer width='100%' height='42px' />
                </Col>
              )}
            </Row>
          </Container>
        )}
        <Card className='tw-p-4'>
          <div className='tw-w-full' style={{ '--s-height': '57px' }}>
            <Shimmer
              width='100%'
              height='var(--s-height)'
              className='tw-mb-1'
            />
            <Shimmer
              width='100%'
              height='var(--s-height)'
              className='tw-mb-1'
            />
            <Shimmer width='100%' height='var(--s-height)' />
          </div>
        </Card>
      </>
    ) : (
      <div className='tw-rounded tw-bg-white tw-shadow'>
        {withArchive && (user?.type !== userTypes.CONTRACTOR || isAdmin) && (
          <Container fluid>
            <Row className='tw-px-4 tw-pb-4' style={{ gap: '0.75rem' }}>
              <Col className='tw-px-0'>
                <SearchBar
                  query={query}
                  placeholder='Search by name, email, or contract ID'
                  onQueryChanged={handleSearch}
                  className='md:tw-mr-2'
                  isClearable
                />
              </Col>
              {withCustomFilter && user?.type === userTypes.COMPANY && (
                <Col md={4} xs={12} className='tw-px-0'>
                  <CustomSelect
                    options={
                      Array.isArray(status)
                        ? [
                            defaultState,
                            ...(status?.map((e) => ({
                              label: e.name,
                              value: e.id,
                            })) ?? []),
                          ]
                        : [defaultState]
                    }
                    value={state}
                    onChange={handleFilter}
                  />
                </Col>
              )}
            </Row>
          </Container>
        )}
        <DataTable data={data} columns={columns} responsive striped />

        {!paginator ? null : (
          <div className='tw-mt-2 tw-flex tw-p-8 md:tw-mt-0 md:tw-justify-end'>
            <Pagination
              innerClass='pagination mb-0'
              activePage={page}
              onChange={setPage}
              itemsCountPerPage={paginator?.per_page ?? 50}
              totalItemsCount={paginator?.total ?? 0}
            />
          </div>
        )}
      </div>
    )
  ) : !loading ? (
    <div>
      <NoContent
        image={welcomeImage}
        subtitle={
          user?.type === userTypes.COMPANY
            ? undefined
            : 'Manage contracts, invoices and get paid in +126 currencies'
        }
        title=''
        withAction={hasAccess(permissions.CreateContracts)}
        actionTitle='New Contract'
        onAction={() => {
          history.push('/contract/create')
        }}
      />
    </div>
  ) : null
}

export function ImageIcon({
  children,
  id,
  icon,
  hoverIcon,
  alt,
  style,
  noMargin,
}) {
  return (
    <span
      id={id}
      style={{
        '--size': '16px',
        '--icon': `url(${icon})`,
        '--hoverIcon': `url(${hoverIcon})`,
        ...style,
      }}
      className={cn(
        'tw-flex tw-size-[--size] tw-items-center tw-bg-[image:--icon] tw-bg-no-repeat hover:tw-bg-[image:--hoverIcon]',
        !noMargin && 'tw-mr-3',
        '[&:hover_p]:tw-text-primary-100 [&_p]:tw-mb-0 [&_p]:tw-pl-6',
      )}
      title={alt}
    >
      {children}
    </span>
  )
}

export default withRouter(ContractList)
