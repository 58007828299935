import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Spinner, Table } from 'reactstrap'
import { getPaymentListByCompany } from '../../../../services/api'
import { useFetch } from '../../../../helpers/hooks'
import Paginations from 'react-js-pagination'
import { useLocation } from 'react-router'
import axios from 'axios'
import StyledTh from '../../../../components/Table/StyledTh'
import StyledTd from '../../../../components/Table/StyledTd'
import { Link } from 'react-router-dom'
import { StyledH5 } from '../../../../components/Typo'

const PaymentsDetails = () => {
  const location = useLocation()
  const [activePage, setActivePage] = useState(1)
  const payments = useFetch({
    action: getPaymentListByCompany,
    withAdminAccess: true,
  })

  const getType = (path) => {
    switch (path) {
      case '/admin/upcoming-payments':
        return {
          key: 'upcoming',
          label: 'Upcoming Payments',
          link: '/admin-upcoming-payments',
        }
      case '/admin/due-payments':
        return {
          key: 'due',
          label: 'Due Payments',
          link: '/admin-due-payments',
        }
      case '/admin/payments':
        return { key: 'paid', label: 'Paid this month' }
      default:
        return {
          key: 'upcoming',
          label: 'Upcoming Payments',
          link: '/admin-upcoming-payments',
        }
    }
  }

  useEffect(() => {
    let cancel
    const body = { page: activePage, type: getType(location.pathname).key }
    // eslint-disable-next-line no-return-assign
    payments.startFetch(
      body,
      activePage === 1,
      new axios.CancelToken((c) => (cancel = c)),
    )
    return () => {
      if (cancel) cancel()
    }
  }, [location.pathname, activePage])

  const handlePageChange = (page) => {
    setActivePage(page)
  }
  return (
    <div className='page-content'>
      {payments.isLoading ? (
        <Container style={{ minHeight: '80vh' }}>
          <Col style={{ minHeight: '80vh' }}>
            <Row
              style={{ minHeight: '80vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className='p-0 m-0'>
          <StyledH5 min='22px' max='32px' className='mb-3 mb-md-4'>
            {getType(location.pathname).label}
          </StyledH5>
          <Card>
            <div className='table-responsive p-0 m-0 d-none d-md-block'>
              <Table className='table table-centered table-nowrap text-muted'>
                <thead className='thead-light'>
                  <tr>
                    <StyledTh>Company</StyledTh>
                    <StyledTh>Last payment by</StyledTh>
                    <StyledTh>Contracts</StyledTh>
                    <StyledTh>Amount</StyledTh>
                    {getType(location.pathname).key === 'due' && (
                      <StyledTh>Overdue</StyledTh>
                    )}
                    <StyledTh>Actions</StyledTh>
                  </tr>
                </thead>
                <tbody>
                  {payments.data?.map((payment, key) => (
                    <Line
                      item={payment}
                      key={key}
                      link={getType(location.pathname).link}
                      isDue={getType(location.pathname).key === 'due'}
                      isAdmin
                    />
                  ))}
                </tbody>
              </Table>
            </div>
            <Col>
              <Row className='px-3 justify-content-end'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={payments.paginator?.total}
                  onChange={handlePageChange}
                />
              </Row>
            </Col>
          </Card>
        </Container>
      )}
    </div>
  )
}

const Line = ({ item, link, isDue }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
      <StyledTd>
        {item.company_name}
        {item.company_dba && item.company_dba !== item.company_name && (
          <span className='ml-1'>
            (<b>{item.company_dba}</b>)
          </span>
        )}
      </StyledTd>

      <StyledTd>
        {item?.last_payment_user?.first_name}{' '}
        {item?.last_payment_user?.last_name}
      </StyledTd>
      <StyledTd>{item.contracts}</StyledTd>
      <StyledTd>{formatter.format(item.amount)}</StyledTd>
      {isDue && (
        <StyledTd>
          <div className='text-danger'>
            {formatter.format(item.amount_over_due)}
          </div>
        </StyledTd>
      )}
      <StyledTd>
        {!!link && (
          <Link target='_blank' to={`${link}/${item.company_id}`}>
            View Payments
          </Link>
        )}
      </StyledTd>
    </tr>
  )
}

export default PaymentsDetails
