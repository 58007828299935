import { ArrowDown, ArrowUp, CaretDoubleRight } from '@phosphor-icons/react'
import { format, isValid, parseISO } from 'date-fns'
import React from 'react'
import { Table } from 'reactstrap'

import { ReactComponent as TransactionsIllustration } from '../../../assets/images/transactions-illustration.svg'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

const cashbackFormatter = (trx) =>
  getCurrencyFormatter(trx?.cashback?.currency_code)

function formatTrxDate(date) {
  const parsedDate = parseISO(date)
  const isValidDate = isValid(parsedDate)

  if (isValidDate) {
    return format(new Date(date), 'MMM dd, yyyy')
  } else {
    return date
  }
}

export default function LatestTransactions({
  data,
  withArrow,
  setSelectedTrx,
  setShow,
  isLoading,
}) {
  if (isLoading) {
    return <Loader minHeight='max(40vh, 440px)' />
  }

  if (!data || !Array.isArray(data) || data?.length <= 0) {
    return (
      <div
        style={{ minHeight: 'max(40vh, 440px)' }}
        className='align-items-center d-flex flex-column justify-content-center p-4 text-center'
      >
        <TransactionsIllustration />
        <h4>No transactions yet</h4>
        <p className='font-size-16 text-muted mb-0 pb-3'>
          Your transactions will show here
        </p>
      </div>
    )
  }

  function handleShowDetails(trx) {
    setSelectedTrx(getDetails(trx))
    setShow(true)
  }

  return (
    <>
      <div className='tw-flex tw-flex-col tw-gap-4 md:tw-hidden'>
        {data.map((trx) => {
          return (
            <TransactionCard
              key={trx?.transaction_id}
              trx={trx}
              withArrow={withArrow}
              onShowDetails={() => {
                handleShowDetails(trx)
              }}
            />
          )
        })}
      </div>

      <Table
        responsive
        className='table-centered table-nowrap !tw-mb-0 tw-hidden tw-text-text-60 md:tw-table'
      >
        <thead className='thead-light'>
          <tr>
            <th className='border-top-0 rounded' />
            <th className='border-top-0'>Merchant name</th>
            <th className='border-top-0'>Category</th>
            <th className='border-top-0'>Date</th>
            <th className='border-top-0'>Transaction Type</th>
            <th className='border-top-0'>Card Type</th>
            <th className='border-top-0 text-right'>Amount</th>
            <th className='border-top-0 text-right'>Cashback</th>
            <th className='border-top-0 rounded' />
          </tr>
        </thead>

        <tbody className='rounded'>
          {data.map((trx) => {
            return (
              <TransactionLine
                key={trx?.transaction_id}
                trx={trx}
                withArrow={withArrow}
                onShowDetails={() => {
                  handleShowDetails(trx)
                }}
              />
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

function getDetails(trx) {
  const formatter = getCurrencyFormatter(trx?.authorization?.total_currency)
  const amount = formatter.format(trx?.authorization?.total_amount)
  return {
    transaction_ref: trx?.transaction_ref,
    items: [
      {
        title: 'Details',
        data: [
          // { title: 'Balance', value: amount },
          {
            title: 'Amount',
            value: amount,
          },
          {
            title: 'Merchant name',
            value: trx?.merchant_details?.name,
          },
          {
            title: 'Category',
            value: getMerchantCategory(
              trx?.merchant_details?.merchant_category_code,
            ),
          },
          {
            title: 'Date',
            value: formatTrxDate(trx?.updated_at ?? trx.updatedAt),
          },
          {
            title: 'Transaction type',
            value: <TrxType trx={trx} />,
          },
          {
            title: 'Card type',
            value: trx?.card_form_factor,
          },
          {
            title: 'Cashback',
            value: cashbackFormatter(trx).format(trx?.cashback?.amount),
          },
        ],
      },
      // {
      //   title: 'Status',
      //   data: [
      //     { title: 'Indicator', value: trx?.indicator },
      //     { title: 'Type', value: trx?.type },
      //   ],
      // },
    ],
  }
}

function Merchant({ logo, name }) {
  return (
    <div className='d-flex align-items-center flex-nowrap' style={{ gap: 14 }}>
      {!logo ? null : (
        <img className='rounded-circle avatar-xs' src={logo} alt='' />
      )}
      {!name ? null : <span style={{ color: '#4A4E5B' }}>{name}</span>}
    </div>
  )
}

function getMerchantCategory(mcc) {
  const mccNumber = Number(mcc)
  if (mccNumber <= 1499) {
    return 'Agricultural Services'
  } else if (mccNumber <= 2999) {
    return 'Contracted Services'
  } else if (mccNumber <= 4799) {
    return 'Transportation Services'
  } else if (mccNumber <= 4999) {
    return 'Utility Services'
  } else if (mccNumber <= 5599) {
    return 'Retail Outlet Services'
  } else if (mccNumber <= 5699) {
    return 'Clothing Stores'
  } else if (mccNumber <= 7299) {
    return 'Miscellaneous Stores'
  } else if (mccNumber <= 7999) {
    return 'Business Services'
  } else if (mccNumber <= 8999) {
    return 'Professional Services and Membership'
  } else if (mccNumber <= 9999) {
    return 'Government Services'
  }
  return 'Other'
}

const arrowUp = <ArrowUp color='var(--success)' size={18} />
const arrowDown = <ArrowDown color='var(--danger)' size={18} />

export const trxIcon = {
  Purchase: arrowDown,
  Offload: arrowDown,
  Withdraw: arrowDown,
  'Money Transfer Debit': arrowDown,

  'Top up': arrowUp,
  Topup: arrowUp,
}

function getIconFromTrxItem(trx) {
  const isReversed = trx?.is_reversed

  if (isReversed) {
    return trxIcon.Topup
  }

  return trxIcon[trx?.authorization?.transaction_type]
}

function getTrxTypeText(trx) {
  const isReversed = trx?.is_reversed
  const reversedText = isReversed ? ' (Reversed)' : ''
  return `${trx?.authorization?.transaction_type}${reversedText}`
}

function TrxType({ trx }) {
  const icon = getIconFromTrxItem(trx)

  return (
    <span className='align-items-center d-flex gap-4'>
      <span
        className='bg-surface-30 rounded-circle d-flex align-items-center justify-content-center'
        style={{ height: 24, width: 24 }}
      >
        {!icon ? null : icon}
      </span>
      {getTrxTypeText(trx)}
    </span>
  )
}

export function TransactionLine({ trx, onShowDetails, withArrow }) {
  const formatter = getCurrencyFormatter(trx?.authorization?.total_currency)
  const amount = formatter.format(trx?.authorization?.total_amount)
  const icon = getIconFromTrxItem(trx)

  return (
    <tr>
      <td>
        <div
          style={{ width: 40, height: 40 }}
          className='align-items-center bg-slate-100 d-flex justify-content-center rounded-circle'
        >
          {!icon ? null : icon}
        </div>
      </td>
      <td>
        <Merchant name={trx?.merchant_details?.name} />
      </td>
      <td>
        {getMerchantCategory(trx?.merchant_details?.merchant_category_code)}
      </td>
      <td>{formatTrxDate(trx?.updated_at ?? trx?.updatedAt)}</td>
      <td>{getTrxTypeText(trx)}</td>
      <td className='rp-capitalize'>{trx?.card_form_factor}</td>
      <td className='text-right'>{amount}</td>
      <td className='text-center'>
        {cashbackFormatter(trx).format(trx?.cashback?.amount)}
      </td>
      <td className='text-center'>
        {!withArrow ? null : (
          <button
            className='rp-btn-nostyle p-1 d-inline-flex align-items-center justify-content-center'
            onClick={onShowDetails}
          >
            <CaretDoubleRight weight='bold' />
          </button>
        )}
      </td>
    </tr>
  )
}

export function TransactionCard({ trx, onShowDetails, withArrow }) {
  const formatter = getCurrencyFormatter(trx?.authorization?.total_currency)
  const amount = formatter.format(trx?.authorization?.total_amount)
  const cashbackAmount = cashbackFormatter(trx).format(trx?.cashback?.amount)

  const infoItems = [
    { label: 'Date', value: formatTrxDate(trx?.updated_at ?? trx.updatedAt) },
    {
      label: 'Merchant',
      value: <Merchant name={trx?.merchant_details?.name} />,
    },
    {
      label: 'Transaction Type',
      value: <TrxType trx={trx} />,
    },
    {
      label: 'Card Type',
      value: <span className='rp-capitalize'>{trx?.card_form_factor}</span>,
    },
    { label: 'Amount', value: amount },
    { label: 'Cashback amount', value: cashbackAmount },
  ]

  return (
    <div
      className='tw-flex tw-flex-col tw-border tw-border-surface-30 tw-py-4'
      style={{ gap: '1rem', borderRadius: 6 }}
    >
      <div className='tw-flex tw-flex-col tw-gap-2 tw-px-4'>
        {infoItems.map(({ label, value }, index) => {
          return (
            <div key={index} className='tw-flex tw-justify-between'>
              <div className='tw-text-text-60'>{label}</div>
              <div className='tw-text-text-100'>{value}</div>
            </div>
          )
        })}
      </div>

      {!withArrow ? null : (
        <div className='tw-px-4'>
          <Button
            color='light'
            textClassName='tw-text-sm'
            className='tw-h-11'
            outline
            onClick={onShowDetails}
            size='sm'
            block
          >
            Details
          </Button>
        </div>
      )}
    </div>
  )
}
