import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from 'ui'

import UserFlagging from '../../../components/userFlag'
import { userTypes } from '../../../helpers/enum'

export default function ClientCell({ isAdmin, item, onUpdate }) {
  const user = useSelector((state) => state?.Account?.user)

  const secondPart = item?.client

  if (!secondPart) return null

  const contractUser =
    user?.type === userTypes.CONTRACTOR && !isAdmin
      ? item?.company_name || secondPart?.email
      : (secondPart?.full_name ?? secondPart?.first_name)
        ? secondPart?.full_name
        : secondPart?.email

  return (
    <div className='position-relative d-flex align-items-center gap-8 rp-font-bold text-primary'>
      <Avatar
        photo={item?.client?.photo}
        name={item?.client?.first_name || item?.client?.email}
        flag={item?.client?.flag}
      />

      <div
        className='text-truncate'
        style={{ fontWeight: 'inherit', maxWidth: '17ch' }}
        translate='no'
      >
        {contractUser}
      </div>

      {(isAdmin && item?.client?.is_flagged === 1) ||
      item?.client?.is_flagged === true ? (
        <UserFlagging user={item?.client} onCompleteAction={onUpdate} />
      ) : null}
    </div>
  )
}
