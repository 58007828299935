import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import logger from '../services/logger'
import { getErrorMessage } from '../utils/get-errors'
import { userTypes } from './enum'

function isHttpSuccessCode(code) {
  if (code >= 200 && code < 300) {
    return true
  }
  return false
}

const useFetch = (
  {
    autoFetch,
    initResult,
    action,
    body,
    onComplete = () => {},
    onError = () => {},
    checkSuccess,
    successAction,
    withAdminAccess,
    isOpenApi = false,
  },
  deps = [],
) => {
  const [isLoading, setIsLoading] = useState(!!autoFetch)
  const [error, setError] = useState(null)
  const [data, setData] = useState(initResult)
  const [paginator, setPaginator] = useState(null)
  const [completed, setCompleted] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const loginToken = useSelector((state) => state.Login?.loginToken)
  const dispatch = useDispatch()
  const history = useHistory()

  const startFetch = (b = body, loading = true, cancelToken, token) => {
    try {
      setIsLoading(loading)
      setError(null)
      action(withAdminAccess ? loginToken : token || user?.token, b, {
        cancelToken,
      })
        .then((res) => {
          if (isOpenApi) {
            if (res.status >= 400) {
              const errorMessage = getErrorMessage(res.data?.error)
              setError(errorMessage)
              onError(errorMessage, res.data, b)

              logger.error(
                [
                  'useFetch-startFetch-errorMessage',
                  action?.name,
                  errorMessage,
                ].join(' :: '),
              )
            } else {
              const result = res.data?.result
              if (result) {
                if (result.items) {
                  setData(result.items)
                  setPaginator({
                    totalItemsCount: result.total_items_count,
                    totalPagesCount: result.total_pages_count,
                    currentPage: result.page,
                  })
                } else {
                  setData(result)
                }

                if (successAction) {
                  dispatch(successAction(result.items || result))
                }
              }
              setCompleted(true)
              onComplete(result, b)
            }
          } else if (res.data.success) {
            setData(res.data?.data)
            setPaginator(res.data?.paginator)
            setCompleted(true)
            onComplete(res.data?.data || res.data, b)
            if (successAction) {
              dispatch(successAction(res.data?.data))
            }
          } else if (res.data?.code === 403) {
            logger.error(
              ['useFetch-startFetch-403', action?.name, res.data?.code].join(
                ' :: ',
              ),
            )

            history.push('/activity')
          } else if (
            !checkSuccess &&
            isHttpSuccessCode(res.status) &&
            res.data?.status !== 'fail' &&
            !(res.data.data?.error || res.data?.error)
          ) {
            setData(res.data)
            setCompleted(true)
            onComplete(res.data, b)
          } else if (withAdminAccess && res.data?.code === 401) {
            logger.log(
              ['useFetch-startFetch-401', action?.name, res.data?.code].join(
                ' :: ',
              ),
            )

            history.push('/admin/login')
          } else {
            const errorMessage = getErrorMessage(
              res.data.data?.error ||
                res.data?.error ||
                res?.error?.details ||
                res.data?.message,
            )
            setError(errorMessage)
            onError(errorMessage, res.data, b)

            logger.error(
              [
                'useFetch-startFetch-errorMessage',
                action?.name,
                errorMessage,
              ].join(' :: '),
            )
          }
          setIsLoading(false)
        })
        .catch((e) => {
          if (withAdminAccess) {
            if (e?.response?.status === 401) {
              logger.error(
                [
                  'useFetch-startFetch-catch-401',
                  action?.name,
                  e?.response?.status,
                ].join(' :: '),
              )

              history.push('/admin/login')
            }
          }
          if (axios.isCancel(e)) {
            return
          } else {
            setError(e.toLocaleString())
          }

          logger.error(
            [
              'useFetch-startFetch-catcherror',
              action?.name,
              e.toLocaleString(),
              e?.response?.status,
            ].join(' :: '),
          )

          const errorMessage = getErrorMessage(
            e?.response?.data?.data?.error ||
              e?.response?.data?.error ||
              e?.response?.error?.details ||
              e?.response?.data?.message,
          )
          setError(errorMessage)
          onError(errorMessage, e?.response?.data, b)
          setIsLoading(false)
        })
    } catch (e) {
      if (axios.isCancel(e)) {
        return
      } else {
        setError(e.toLocaleString())
      }
      logger.error(
        [
          'useFetch-startFetch-catcherror2',
          action?.name,
          e.toLocaleString(),
          e.response?.status,
        ].join(' :: '),
      )
      onError(e?.response?.data ?? e.toLocaleString(), e?.response?.data, b)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let cancel
    if (autoFetch) {
      // eslint-disable-next-line no-return-assign
      startFetch(body, true, new axios.CancelToken((c) => (cancel = c)))
    }
    return () => {
      if (cancel) cancel()
    }
  }, deps)

  return {
    error,
    isLoading,
    data,
    startFetch,
    completed,
    setData,
    paginator,
    setError,
  }
}

const useCustomResize = ({ minWidth = 769 } = {}) => {
  const [width, setWidth] = useState(window.innerWidth)
  const handleResize = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return width <= minWidth
}

export const MOBILE_BREAKDOWN = 619
export const SIDEBAR_BREAKDOWN = 920
const useResize = () => useCustomResize({ minWidth: MOBILE_BREAKDOWN })

const usePermissions = () => {
  const myPermissions = useSelector(
    (state) => state?.userProfile?.userProfile?.permissions,
  )

  const user = useSelector((state) => state?.Account?.user)

  const hasAccess = (permissions) => {
    const checkPermission = (permission) => {
      return (
        myPermissions?.includes(permission) ||
        user?.type === userTypes.CONTRACTOR
      )
    }

    if (Array.isArray(permissions)) {
      return permissions.some(checkPermission)
    } else {
      return checkPermission(permissions)
    }
  }

  return { hasAccess }
}

function useAdminPermission() {
  const myAdminPermissions = useSelector(
    (state) => state?.userProfile?.userProfile?.adminPermissions,
  )

  const hasAccess = (permission) => {
    if (Array.isArray(permission)) {
      return permission.some((p) => myAdminPermissions?.includes(p))
    }
    return myAdminPermissions?.includes(permission)
  }

  return { hasAccess }
}

export {
  useAdminPermission,
  useCustomResize,
  useFetch,
  usePermissions,
  useResize,
}
