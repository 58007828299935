import React, { useCallback, useRef } from 'react'
import ReactQuill from 'react-quill'
import { Controller } from 'react-hook-form'
import 'react-quill/dist/quill.snow.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { usePlaceholders } from './placeholders-context'
import Loader from '../../../../components/ui/loader'
import isNil from 'lodash/isNil'
import { Input } from 'reactstrap'
import { TagsInput } from '../../../remotepass-refers/components/sharing-section'

export function ActionEmailForm(props) {
  const { index } = props
  const quillRef = useRef(null)
  const { isLoading, items } = usePlaceholders()

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      quillRef.current.focus()
    }
  }

  const insertPlaceholder = useCallback((placeholder) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()
      const cursorPosition = quill.getSelection()?.index
      if (!isNil(cursorPosition)) {
        quill.insertText(cursorPosition, `{{${placeholder}}}`, 'user')
        quill.setSelection(cursorPosition + `{{${placeholder}}}`.length)
      }
    }
  }, [])
  return (
    <>
      <Controller
        render={({ field: { value = [], onChange } }) => (
          <TagsInput setTags={onChange} tags={value} placeholder='To' />
        )}
        name={`steps.${index}.to`}
      />

      <Controller
        render={({ field: { value = '', onChange, onBlur } }) => (
          <Input
            id='input'
            onKeyDown={handleKeyDown}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder='Email Subject'
          />
        )}
        name={`steps.${index}.subject`}
      />

      <Controller
        name={`steps.${index}.body`}
        render={({ field: { value = '', onChange } }) => (
          <div>
            <ReactQuill
              ref={quillRef}
              value={value}
              className='tw-flex tw-h-[250px] tw-flex-col-reverse tw-rounded tw-border tw-border-surface-130 [&>*:nth-child(2)]:!tw-p-[15px]'
              onChange={onChange}
              placeholder='Email body'
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                ],
              }}
            />

            {isLoading ? (
              <Loader minHeight='20px' className='tw-my-2 tw-flex-1' />
            ) : items?.length ? (
              <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-bg-surface-30 tw-px-2 tw-py-4'>
                {items?.map((p) => (
                  <button
                    key={p.key}
                    onClick={() => {
                      insertPlaceholder(p.key)
                    }}
                    className='tw-rounded-full tw-bg-white tw-px-5 tw-py-2'
                  >
                    {p.title}
                  </button>
                ))}
              </div>
            ) : null}
          </div>
        )}
      ></Controller>
    </>
  )
}
