import { HIBOB_REQUEST, HIBOB_UPDATE_CONTRACTORS } from './actionTypes'

export const HiBobRequest = (data) => {
  return {
    type: HIBOB_REQUEST,
    data,
  }
}

export const HiBobUpdateContractors = (payload) => {
  return {
    type: HIBOB_UPDATE_CONTRACTORS,
    payload,
  }
}
