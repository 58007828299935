import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import Input from 'reactstrap/lib/Input'
import toastr from 'toastr'
import { Trash } from '@phosphor-icons/react'

import { cn } from 'ui'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import { getOCR, uploadContractorDoc } from '../../../services/api'
import uploadFiles from './../../../assets/images/uploadFiles.svg'

const UploadModal = ({ isOpen, toggle, doc, update }) => {
  const [title, setTitle] = useState(null)
  const [file, setFile] = useState(null)
  const user = useSelector((state) => state?.Account?.user)
  const upload = useFetch({
    action: uploadContractorDoc,
    checkSuccess: true,
    onComplete: (_, body) => {
      toggle()
      update()
      window.analytics.track('Uploaded document', {
        contractor_id: user?.id,
        document_name: body?.title,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const ocr = useFetch({
    action: getOCR,
  })
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'image/*,application/pdf',
  })
  const files = acceptedFiles.map((file) => (
    <p className='mb-0' key={file.path}>
      {file.path}
    </p>
  ))

  useEffect(() => {
    setTitle({ value: doc?.type_id ? doc?.title : '' })
    removeAll()
  }, [isOpen])
  const removeAll = (e) => {
    e?.stopPropagation()
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  useEffect(() => {
    if (!!acceptedFiles && acceptedFiles?.length > 0) {
      if (acceptedFiles[0].size > 2000000) {
        toastr.error('The file may not be greater than 2MB')
        removeAll()
      } else {
        setFile(acceptedFiles[0])
      }
    }
  }, [acceptedFiles])
  const handleUploadClick = () => {
    if (!title?.value) {
      toastr.error('Please enter the document title')
      return
    }
    if (!file) {
      toastr.error('Please pick a file first')
      return
    }
    if (acceptedFiles[0].size > 2000000) {
      toastr.error('The file may not be greater than 2MB')
      return
    }
    upload.startFetch({
      file,
      title: title?.value,
      type_id: doc?.type_id,
    })
  }

  const isMobile = useResize()
  return (
    <Modal
      className='uploadModal'
      unmountOnClose
      backdrop={!upload.isLoading}
      centered
      isOpen={isOpen}
      role='dialog'
      autoFocus
      toggle={toggle}
    >
      <div className='modal-content'>
        <ModalHeader close={<ModalCloseButton toggle={toggle} size={24} />}>
          Upload Document
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Input
                placeholder='Title'
                value={title?.value}
                disabled={!!doc?.type_id}
                onChange={(e) => {
                  setTitle({ ...title, value: e.target.value, error: false })
                }}
              />
              <section className='tw-mt-4 tw-p-0'>
                <div className='dropzone !tw-border-primary'>
                  <div
                    className='dz-message needsclick tw-bg-primary/10'
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps()}
                      accept='image/*,application/pdf'
                    />

                    {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                      <div
                        className='tw-flex tw-items-center tw-justify-between tw-text-sm'
                        style={{ minHeight: 149 }}
                      >
                        {files}
                        <button
                          className='rp-btn-nostyle tw-p-1'
                          onClick={removeAll}
                        >
                          <Trash className='tw-fill-red' />
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className='tw-flex tw-items-start tw-py-6'>
                          <img src={uploadFiles} alt='' />
                          <div className='tw-flex-grow'>
                            <h5 className='tw-text-sm md:tw-text-base'>
                              Drop files here or click to upload.
                            </h5>
                            <p className='tw-text-xs tw-text-disabled'>
                              Max file size 2MB
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter
          className={cn({
            'tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50': isMobile,
          })}
        >
          <div
            className={cn({ 'tw-flex tw-w-full tw-justify-between': isMobile })}
          >
            <Button
              disabled={upload.isLoading || ocr.isLoading}
              type='button'
              color='white'
              className='!tw-border-surface-30 md:tw-mr-4'
              onClick={toggle}
            >
              Cancel
            </Button>

            <Button
              disabled={upload.isLoading || ocr.isLoading}
              type='button'
              color='primary'
              onClick={handleUploadClick}
              loading={upload.isLoading || ocr.isLoading}
            >
              Upload
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default UploadModal
