import { Eye } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Modal } from 'reactstrap'

import { cn } from 'ui'
import Button from '../../components/ui/button'

export function PreviewInvoice({ imagePreview }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggleModal() {
    setIsModalOpen((isOpen) => !isOpen)
  }

  return (
    <>
      <Button
        icon={<Eye size={20} />}
        color='link'
        type='button'
        size='sm'
        className='tw-mt-4 !tw-px-3'
        onClick={toggleModal}
      >
        Preview
      </Button>

      <PreviewModal isModalOpen={isModalOpen} toggleModal={toggleModal}>
        <InvoiceDocumentPreview imagePreview={imagePreview} />
      </PreviewModal>
    </>
  )
}

export function PreviewModal({ isModalOpen, toggleModal, children }) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className='native-dialog-sm !tw-max-w-[595px]'
      cssModule={{
        'modal-content': 'modal-content !tw-rounded-none !tw-border-none',
      }}
    >
      {children}
    </Modal>
  )
}

function DataPreview({ className }) {
  return (
    <div
      className={cn(
        'tw-h-[12px] tw-w-20 tw-rounded-full tw-bg-surface-20',
        className,
      )}
    ></div>
  )
}

export function InvoiceDocumentPreview({
  reference,
  issueOn,
  dueDate,
  clientDetails,
  invoiceItems,
  taxRate,
  className,
  style,
  imagePreview,
  currencySymbol = '',
}) {
  const subtotal =
    invoiceItems?.length <= 0 || !invoiceItems
      ? null
      : invoiceItems.reduce((acc, item) => acc + Number(item.amount), 0)

  const taxAmount = taxRate ? (subtotal * taxRate) / 100 : null
  const taxText = !taxRate
    ? null
    : `(${taxRate}%) ${currencySymbol} ${taxAmount}`

  const total = !subtotal ? null : subtotal + taxAmount

  return (
    <div
      className={cn(
        'tw-aspect-[595/842] tw-max-h-[842px] tw-max-w-[595px] tw-overflow-auto tw-overscroll-contain tw-bg-white',
        className,
      )}
      style={style}
    >
      <div className='tw-flex tw-h-full tw-flex-col'>
        <div className='tw-px-8 tw-py-9'>
          <div className='tw-flex tw-justify-between tw-gap-4'>
            <div className='tw-flex tw-items-center tw-gap-4'>
              <div className='tw-text-[40px]/[40px] tw-font-extrabold'>
                INVOICE
              </div>
              <div className='tw-flex tw-min-h-[32px] tw-flex-col tw-justify-between'>
                {typeof reference === 'string' ? (
                  <div className='tw-text-[10px]/[18px] tw-font-bold tw-text-text-70'>
                    {reference}
                  </div>
                ) : (
                  <DataPreview />
                )}
                {typeof issueOn === 'string' ? (
                  <div className='tw-text-[10px]/[18px] tw-font-bold'>
                    Issued on {format(new Date(issueOn), 'yyyy-MM-dd')}
                  </div>
                ) : (
                  <DataPreview className='tw-w-9' />
                )}
              </div>
            </div>
            <img src={imagePreview} className='tw-size-12' alt='invoice' />
          </div>
          <div className='tw-mt-11'>
            <div className='tw-text-base tw-font-bold'>Client</div>
            {clientDetails?.length <= 0 || !clientDetails ? (
              <div className='tw-mt-2 tw-space-y-2'>
                {Array.from({ length: 4 }).map((_, index) => (
                  <DataPreview
                    key={index}
                    className={
                      ['tw-w-20', 'tw-w-28', 'tw-w-32', 'tw-w-28'][index]
                    }
                  />
                ))}
              </div>
            ) : (
              clientDetails.map((details, index) => (
                <div
                  key={index}
                  className='tw-text-[10px]/[18px] tw-font-semibold tw-text-text-100'
                >
                  {details}
                </div>
              ))
            )}
          </div>
          <table className='tw-mt-6 tw-w-full'>
            <thead>
              <tr>
                <th className='tw-border-b !tw-px-0 !tw-py-2 tw-text-[10px]/[18px] tw-font-semibold tw-text-text-100'>
                  Description
                </th>
                <th className='tw-border-b !tw-px-0 !tw-py-2 tw-text-[10px]/[18px] tw-font-semibold tw-text-text-100'>
                  Rate
                </th>
                <th className='tw-border-b !tw-px-0 !tw-py-2 tw-text-[10px]/[18px] tw-font-semibold tw-text-text-100'>
                  Quantity
                </th>
                <th className='tw-border-b !tw-px-0 !tw-py-2 tw-text-[10px]/[18px] tw-font-semibold tw-text-text-100'>
                  Unit Price
                </th>
                <th className='tw-border-b !tw-px-0 !tw-py-2 tw-text-end tw-text-[10px]/[18px] tw-font-semibold tw-text-text-100'>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems?.length <= 0 || !invoiceItems ? (
                <tr>
                  <td className='!tw-px-0 md:tw-py-4'>
                    <DataPreview className='tw-w-32' />
                  </td>
                  <td className='!tw-px-0 md:tw-py-4'>
                    <DataPreview className='tw-w-32' />
                  </td>
                  <td className='!tw-px-0 md:tw-py-4'>
                    <DataPreview className='tw-w-16' />
                  </td>
                  <td className='!tw-px-0 md:tw-py-4'>
                    <DataPreview className='tw-w-16' />
                  </td>
                  <td className='!tw-px-04tw-text-end md:tw-py-2'>
                    <DataPreview className='tw-w-16' />
                  </td>
                </tr>
              ) : (
                invoiceItems.map((item, index) => (
                  <tr
                    key={index}
                    className={cn(
                      '[&:first-child>*]:!tw-pt-2 [&:not(:first-child)>*]:!tw-pt-0 [&:not(:last-child)>*]:!tw-pb-2 [&>*:last-child]:!tw-pr-0 [&>*:not(:last-child)]:!tw-pr-2 [&>*]:!tw-pl-0',
                      'md:[&:first-child>*]:!tw-pt-4 md:[&:not(:last-child)>*]:!tw-pb-4 md:[&>*:not(:last-child)]:!tw-pr-4',
                    )}
                  >
                    <td className='tw-text-[10px]/[18px] tw-font-bold'>
                      {item.description}
                    </td>
                    <td className='tw-text-[10px]/[18px] tw-text-text-100'>
                      {item.unit}
                    </td>
                    <td className='tw-text-[10px]/[18px] tw-text-text-100'>
                      {item.quantity}
                    </td>
                    <td className='tw-text-[10px]/[18px] tw-text-text-100'>
                      {currencySymbol} {item.rate}
                    </td>
                    <td className='tw-text-end tw-text-[10px]/[18px] tw-font-semibold'>
                      {currencySymbol} {item.amount}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className='tw-mt-auto'>
          <div className='tw-bg-surface-10 tw-p-8 tw-text-[10px]/[18px] tw-font-bold'>
            <div className='tw-flex tw-justify-between tw-py-1.5'>
              <div className='tw-text-text-100'>Subtotal</div>
              {!subtotal ? (
                <DataPreview className='tw-w-8' />
              ) : (
                <div>
                  {currencySymbol} {subtotal}
                </div>
              )}
            </div>
            <div className='tw-flex tw-justify-between tw-py-1.5'>
              <div className='tw-text-text-100'>Tax</div>
              {!taxRate ? (
                <DataPreview className='tw-w-8' />
              ) : (
                <div>{taxText}</div>
              )}
            </div>

            <hr className='tw-border-t-text-100' />

            <div className='tw-flex tw-items-center tw-justify-between tw-py-1.5'>
              <div className='tw-text-text-100'>Total</div>
              <div className='tw-text-end'>
                {!total ? (
                  <DataPreview className='tw-h-5 tw-w-28' />
                ) : (
                  <div className='tw-text-3xl/10 tw-font-extrabold'>
                    {currencySymbol} {total}
                  </div>
                )}
                {!dueDate ? (
                  <DataPreview className='tw-mt-2 tw-inline-block tw-w-8' />
                ) : (
                  <div className='tw-mt-2'>{dueDate}</div>
                )}
              </div>
            </div>
          </div>
          <div className='tw-flex tw-justify-between tw-bg-primary-130 tw-px-8 tw-py-6 tw-text-[10px]/[18px] tw-text-white'>
            <div>
              <div className='tw-font-bold tw-text-surface-120'>
                Generated with
              </div>
              <img
                src='/remotepass-logo-light.svg'
                className='tw-h-[14px] tw-w-auto'
              />
            </div>
            <div className='tw-text-end tw-font-bold'>
              {!invoiceItems || invoiceItems.length <= 0 ? (
                <DataPreview className='tw-inline-block tw-w-8 tw-bg-white/20' />
              ) : (
                <div className='tw-text-surface-120'>Page 1 of 1</div>
              )}
              {!reference ? (
                <DataPreview className='tw-w-20 tw-bg-white/20' />
              ) : (
                <div>{reference}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
