import React from 'react'
import { Card, Col, Container, Row } from 'reactstrap'
import { useFetch } from '../../../../helpers/hooks'
import {
  getEORBenefitDocuments,
  getEOREmploymentContracts,
  getEORImmigrationDocuments,
} from '../../../../services/api'
import DataTable from '../../../../components/ui/data-table'
import ContractRef from '../../components/ContractRef'
import Pagination from '../../../../components/ui/pagination'
import Loader from '../../../../components/ui/loader'
import NavTabs from '../../../../components/ui/nav-tabs'
import { useFilters } from '../cards/use-filters'
import { format } from 'date-fns'
import { downloadFile } from '../../../Contract/utils/helpers'
import { DownloadSimple } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { useLocation } from 'react-router-dom'

const DAYS_BEFORE_ACTION = [
  { value: '1', label: '1' },
  { value: '30', label: '30' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
]
const DATE_TYPE = [
  { value: 'expiry_date', label: 'Expiry date' },
  { value: 'probation_end_date', label: 'Probation end date' },
]
function Renewal() {
  const location = useLocation()
  const tab = new URLSearchParams(location.search).get('tab')
  const [filters, handleFiltersChange] = useFilters({
    tab: tab ?? 'benefit_documents',
  })

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Renewal</h1>

        <Card>
          <NavTabs
            className='p-3'
            options={[
              {
                label: 'Benefit documents',
                value: 'benefit_documents',
              },
              {
                label: 'Immigration documents',
                value: 'immigration_documents',
              },
              {
                label: 'Employment Contracts',
                value: 'employment_contracts',
              },
            ]}
            activeOption={filters.tab}
            onClickOption={(option) => handleFiltersChange('tab', option.value)}
          />
          {filters.tab === 'benefit_documents' ? (
            <BenefitsList />
          ) : filters.tab === 'immigration_documents' ? (
            <ImmigrationDocsList />
          ) : (
            <EmploymentContractsList />
          )}
        </Card>
      </Container>
    </div>
  )
}
function BenefitsList() {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    days_before_action_needed: null,
  })
  const {
    data: benefitDocuments,
    paginator,
    isLoading,
  } = useFetch(
    {
      action: getEORBenefitDocuments,
      body: {
        ...filters,
        days_before_action_needed: filters?.days_before_action_needed?.value,
      },
      withAdminAccess: true,
      autoFetch: true,
    },
    [filters],
  )

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const benefitColumns = [
    {
      Header: 'Contract ID',
      accessor: 'contract_ref',
      Cell: ({ cellData }) => {
        return (
          <ContractRef contractId={cellData} isAdmin={true} target={null} />
        )
      },
    },
    {
      Header: 'Identifier',
      accessor: 'identifier',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'File',
      accessor: 'file',
      Cell: ({ cellData }) => {
        return (
          <button
            onClick={() => {
              downloadFile(cellData, cellData)
            }}
          >
            <DownloadSimple size={20} weight='fill' className='mr-1' />
          </button>
        )
      },
    },
    {
      Header: 'Issue date',
      accessor: 'issue_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Expiry date',
      accessor: 'expiry_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
  ]
  function handleResetFilters() {
    handleFiltersChange('page', 1)
    handleFiltersChange('days_before_action_needed', null)
  }
  return (
    <>
      <Container fluid>
        <Row className='p-3' style={{ gap: '0.75rem' }}>
          <Col md={5} className='p-0'>
            <CustomSelect
              options={DAYS_BEFORE_ACTION}
              value={filters.days_before_action_needed}
              onChange={(val) =>
                handleFiltersChange('days_before_action_needed', val)
              }
              placeholder='Select days before action needed'
            />
          </Col>
          <Col md={3} className='p-0'>
            <Button block onClick={handleResetFilters}>
              Clear Filter
            </Button>
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <>
          <Loader style={{ minHeight: 300 }} className='w-100' />
        </>
      ) : benefitDocuments?.length === 0 ? (
        <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
          No benefits documents
        </div>
      ) : (
        <DataTable
          data={benefitDocuments}
          responsive
          columns={benefitColumns}
        />
      )}
      {showPaginator && !isLoading && (
        <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={paginator?.per_page ?? 10}
            totalItemsCount={paginator?.total ?? 0}
            onChange={(newPage) => handleFiltersChange('page', newPage)}
          />
        </div>
      )}
    </>
  )
}
function ImmigrationDocsList() {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    days_before_action_needed: null,
  })
  const {
    data: immigrationDocuments,
    paginator,
    isLoading,
  } = useFetch(
    {
      action: getEORImmigrationDocuments,
      body: {
        ...filters,
        days_before_action_needed: filters?.days_before_action_needed?.value,
      },
      withAdminAccess: true,
      autoFetch: true,
    },
    [filters],
  )

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const immigrationColumns = [
    {
      Header: 'Contract ID',
      accessor: 'contract_ref',
      Cell: ({ cellData }) => {
        return (
          <ContractRef contractId={cellData} isAdmin={true} target={null} />
        )
      },
    },
    {
      Header: 'Identifier',
      accessor: 'identifier',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'File',
      accessor: 'file',
      Cell: ({ cellData }) => {
        return (
          <button
            onClick={() => {
              downloadFile(cellData, cellData)
            }}
          >
            <DownloadSimple size={20} weight='fill' className='mr-1' />
          </button>
        )
      },
    },
    {
      Header: 'Issue date',
      accessor: 'issue_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Expiry date',
      accessor: 'expiry_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
  ]
  function handleResetFilters() {
    handleFiltersChange('page', 1)
    handleFiltersChange('days_before_action_needed', null)
  }
  return (
    <>
      <Container fluid>
        <Row className='p-3' style={{ gap: '0.75rem' }}>
          <Col md={5} className='p-0'>
            <CustomSelect
              options={DAYS_BEFORE_ACTION}
              value={filters.days_before_action_needed}
              onChange={(val) =>
                handleFiltersChange('days_before_action_needed', val)
              }
              placeholder='Select days before action needed'
            />
          </Col>
          <Col md={3} className='p-0'>
            <Button block onClick={handleResetFilters}>
              Clear Filter
            </Button>
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <>
          <Loader style={{ minHeight: 300 }} className='w-100' />
        </>
      ) : immigrationDocuments?.length === 0 ? (
        <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
          No immigration documents
        </div>
      ) : (
        <DataTable
          data={immigrationDocuments}
          responsive
          columns={immigrationColumns}
        />
      )}
      {showPaginator && !isLoading && (
        <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={paginator?.per_page ?? 10}
            totalItemsCount={paginator?.total ?? 0}
            onChange={(newPage) => handleFiltersChange('page', newPage)}
          />
        </div>
      )}
    </>
  )
}
function EmploymentContractsList() {
  const location = useLocation()
  const type = new URLSearchParams(location.search).get('type')
  const days = new URLSearchParams(location.search).get('days')
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    days_before_action_needed: DAYS_BEFORE_ACTION.find((d) => d.value === days),
    date_type: DATE_TYPE.find((d) => d.value === type),
  })
  const {
    data: benefitDocuments,
    paginator,
    isLoading,
  } = useFetch(
    {
      action: getEOREmploymentContracts,
      body: {
        ...filters,
        date_type: filters?.date_type?.value,
        days_before_action_needed: filters?.days_before_action_needed?.value,
      },
      withAdminAccess: true,
      autoFetch: true,
    },
    [filters],
  )

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const employmentContractsColumns = [
    {
      Header: 'Contract ID',
      accessor: 'contract_ref',
      Cell: ({ cellData }) => {
        return (
          <ContractRef contractId={cellData} isAdmin={true} target={null} />
        )
      },
    },
    {
      Header: 'File',
      accessor: 'file',
      Cell: ({ cellData }) => {
        return (
          <button
            onClick={() => {
              downloadFile(cellData, cellData)
            }}
          >
            <DownloadSimple size={20} weight='fill' className='mr-1' />
          </button>
        )
      },
    },
    {
      Header: 'Probation end date',
      accessor: 'probation_end_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Last renewal date',
      accessor: 'last_renewal_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Expiry date',
      accessor: 'expiry_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
  ]
  function handleResetFilters() {
    handleFiltersChange('page', 1)
    handleFiltersChange('days_before_action_needed', null)
    handleFiltersChange('date_type', null)
  }
  return (
    <>
      <Container fluid>
        <Row className='p-3' style={{ gap: '0.75rem' }}>
          <Col md={4} className='p-0'>
            <CustomSelect
              options={DATE_TYPE}
              value={filters.date_type}
              onChange={(val) => handleFiltersChange('date_type', val)}
              placeholder='Select date type'
            />
          </Col>
          <Col md={4} className='p-0'>
            <CustomSelect
              options={DAYS_BEFORE_ACTION}
              value={filters.days_before_action_needed}
              onChange={(val) =>
                handleFiltersChange('days_before_action_needed', val)
              }
              placeholder='Select days before action needed'
            />
          </Col>

          <Col md={3} className='p-0'>
            <Button block onClick={handleResetFilters}>
              Clear Filters
            </Button>
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <>
          <Loader style={{ minHeight: 300 }} className='w-100' />
        </>
      ) : benefitDocuments?.length === 0 ? (
        <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
          No emplyment contracts
        </div>
      ) : (
        <DataTable
          data={benefitDocuments}
          responsive
          columns={employmentContractsColumns}
        />
      )}
      {showPaginator && !isLoading && (
        <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={paginator?.per_page ?? 10}
            totalItemsCount={paginator?.total ?? 0}
            onChange={(newPage) => handleFiltersChange('page', newPage)}
          />
        </div>
      )}
    </>
  )
}
export default Renewal
