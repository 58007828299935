import { yupResolver } from '@hookform/resolvers/yup'
import { PencilSimple, Trash } from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import { FlagIcon } from '../../../../components/ui/flag-icon'
import Loader from '../../../../components/ui/loader'
import { useFetch } from '../../../../helpers/hooks'
import {
  createWalletConversionMarkup,
  deleteWalletConversionMarkup,
  getWalletConversionMarkup,
} from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import { USD_CODE } from '../../../Contract/CreateContract/utils/usd-id'
import Pagination from '../../../../components/ui/pagination'

export default function WalletConversionMarkups() {
  const [addModalOpen, setAddModalOpen] = useState()
  const [page, setPage] = useState(1)

  const {
    data: markupList,
    isLoading: gettingMarkupList,
    startFetch: getMarkupList,
    paginator,
  } = useFetch(
    {
      action: getWalletConversionMarkup,
      autoFetch: true,
      body: { page, perPage: 40 },
      withAdminAccess: true,
    },
    [page],
  )
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  const columns = useMemo(() => {
    return [
      { Header: 'Source', accessor: 'source', Cell: CurrencyCell },
      { Header: 'Target', accessor: 'target', Cell: CurrencyCell },
      { Header: 'Value', accessor: 'value' },
      {
        Header: 'Actions',
        Cell: (args) => <ActionCell {...args} refreshData={getMarkupList} />,
      },
    ]
  }, [getMarkupList])

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>Wallet Conversion Markups</h1>

        {gettingMarkupList ? null : (
          <Button onClick={() => setAddModalOpen(true)} color='primary'>
            Add Markup
          </Button>
        )}
      </div>

      <Card style={{ minHeight: '30rem' }}>
        {gettingMarkupList ? (
          <Loader minHeight='30rem' />
        ) : (
          <>
            <DataTable columns={columns} data={markupList?.data} />

            <AddMarkupModal
              isOpen={addModalOpen}
              toggle={() => setAddModalOpen((open) => !open)}
              refreshData={getMarkupList}
            />
          </>
        )}
        {!showPaginator || gettingMarkupList ? null : (
          <div className='ml-auto mx-3 mt-2'>
            <Pagination
              activePage={page}
              itemsCountPerPage={paginator?.per_page ?? 10}
              totalItemsCount={paginator?.total ?? 0}
              onChange={(newPage) => setPage(newPage)}
            />
          </div>
        )}
      </Card>
    </div>
  )
}

function CurrencyCell({ cellData }) {
  return (
    <div className='d-flex gap-8 align-items-center'>
      <FlagIcon code={cellData} /> {cellData}
    </div>
  )
}

function ActionCell({ rowData, refreshData }) {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  return (
    <div className='d-flex gap-8'>
      <Button
        size='sm'
        outline
        icon={<PencilSimple size={12} />}
        onClick={() => setEditModalOpen(true)}
      >
        Edit
      </Button>
      <Button
        size='sm'
        color='danger'
        outline
        icon={<Trash size={12} />}
        onClick={() => setDeleteModalOpen(true)}
      >
        Delete
      </Button>

      {!deleteModalOpen ? null : (
        <DeleteModal
          isOpen={deleteModalOpen}
          toggle={() => setDeleteModalOpen((open) => !open)}
          data={rowData}
          refreshData={refreshData}
        />
      )}

      {!editModalOpen ? null : (
        <EditMarkupModal
          isOpen={editModalOpen}
          toggle={() => setEditModalOpen((open) => !open)}
          data={rowData}
          refreshData={refreshData}
        />
      )}
    </div>
  )
}

const addMarkupFormId = 'addMarkupForm'
function AddMarkupModal({ isOpen, toggle, refreshData }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { target: USD_CODE },
    resolver: yupResolver(
      yup.object().shape({
        source: yup.string().required(),
        target: yup.string().required(),
        value: yup.number().required(),
      }),
    ),
  })

  const { startFetch: createMarkup, isLoading: creatingMarkup } = useFetch({
    action: createWalletConversionMarkup,
    withAdminAccess: true,
    onComplete: () => {
      toggle?.()
      refreshData?.()
    },
  })

  function onSubmit(data) {
    createMarkup(data)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Markup</ModalHeader>

      <ModalBody>
        <form
          id={addMarkupFormId}
          onSubmit={handleSubmit(onSubmit)}
          className='d-flex flex-column gap-8'
        >
          <MarkupForm control={control} errors={errors} />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={creatingMarkup}
        >
          Cancel
        </Button>
        <Button
          formId={addMarkupFormId}
          type='submit'
          disabled={creatingMarkup}
          loading={creatingMarkup}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const editMarkupFormId = 'editMarkupForm'
function EditMarkupModal({ isOpen, toggle, data, refreshData }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: data })

  const { startFetch: editMarkup, isLoading: editingMarkup } = useFetch({
    action: createWalletConversionMarkup,
    withAdminAccess: true,
    onComplete: () => {
      toggle?.()
      refreshData?.()
    },
  })

  function onSubmit(data) {
    editMarkup({ ...data, id: data.id })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Markup</ModalHeader>

      <ModalBody>
        <form
          id={editMarkupFormId}
          onSubmit={handleSubmit(onSubmit)}
          className='d-flex flex-column gap-8'
        >
          <MarkupForm control={control} errors={errors} />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={editingMarkup}>
          Cancel
        </Button>
        <Button
          formId={editMarkupFormId}
          type='submit'
          disabled={editingMarkup}
          loading={editingMarkup}
        >
          Edit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function MarkupForm({ control, errors }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'code'))

  return (
    <>
      <ControlledSelect
        control={control}
        name='source'
        label='Source'
        error={errors?.source?.message}
        options={currencyOptions}
      />

      <ControlledSelect
        control={control}
        name='target'
        label='Target'
        error={errors?.target?.message}
        options={currencyOptions}
      />

      <ControlledInput
        control={control}
        name='value'
        label='Value'
        error={errors?.value?.message}
        type='number'
      />
    </>
  )
}

function DeleteModal({ isOpen, toggle, data, refreshData }) {
  const { startFetch: deleteMarkup, isLoading: deletingMarkup } = useFetch({
    action: deleteWalletConversionMarkup,
    withAdminAccess: true,
    onComplete: () => {
      toggle?.()
      refreshData?.()
    },
  })

  function handleDelete() {
    deleteMarkup({ id: data.id })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete Markup</ModalHeader>

      <ModalBody>
        <p>Are you sure you want to delete this markup?</p>
      </ModalBody>

      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={deletingMarkup}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          color='danger'
          disabled={deletingMarkup}
          loading={deletingMarkup}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}
