import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft } from '@phosphor-icons/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../../components/ui/button'
import PageHeading from '../../../../components/ui/page-heading'
import { useFetch } from '../../../../helpers/hooks'
import { deCreateJurisdiction } from '../../../../services/api'
import {
  JurisdictionForm,
  getBoolean,
  jurisdictionSchema,
} from './jurisdiction-edit'

export default function JurisdictionAdd() {
  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title
          action={
            <Link to='/admin/jurisdictions' className='text-text-80'>
              <ArrowLeft size={24} />
            </Link>
          }
        >
          Jurisdiction Add
        </PageHeading.Title>
      </PageHeading>

      <Card style={{ minHeight: '30rem' }}>
        <CardBody className='py-4 px-0'>
          <AddJurisdiction />
        </CardBody>
      </Card>
    </div>
  )
}

function getMessage(err) {
  return typeof err === 'string' ? err : 'Failed to add jurisdiction'
}

function AddJurisdiction() {
  const history = useHistory()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(jurisdictionSchema) })

  const { startFetch: updateJurisdiction, isLoading } = useFetch({
    action: deCreateJurisdiction,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(getMessage(data?.message))
      } else {
        history.push('/admin/jurisdictions')
      }
    },
    onError: (err) => {
      toastr.error(getMessage(err))
    },
  })

  function onSubmit(data) {
    const body = {
      ...data,
      is_wps_enabled: getBoolean(data.is_wps_enabled),
      is_external_payroll_enabled: getBoolean(data.is_external_payroll_enabled),
      is_internal_payroll_enabled: getBoolean(data.is_internal_payroll_enabled),
      is_employee_identifier_required: getBoolean(
        data.is_employee_identifier_required,
      ),
    }

    updateJurisdiction(body)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <JurisdictionForm
          setValue={setValue}
          control={control}
          errors={errors}
        />

        <Container fluid className='mt-4'>
          <Row>
            <Col>
              <Button type='submit' loading={isLoading} disabled={isLoading}>
                Add jurisdiction
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  )
}
