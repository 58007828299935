import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Button from '../../../components/ui/button'
import { X } from '@phosphor-icons/react'
import { CashBackIcon } from '../../../components/ui/cashback-icon'
import { REWARD_UNLOCKED } from '..'
import DotsBG from '../../../assets/images/dots-bg.svg'
const RewardUnlockedModal = ({ isOpen, toggle, userName, date }) => {
  useEffect(() => {
    localStorage.setItem(REWARD_UNLOCKED, true)
  }, [])
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} id='HelloFr'>
        <ModalBody className='p-0'>
          <div
            className='bg-cyan-20'
            style={{ height: 440, overflow: 'hidden' }}
          >
            <img src={DotsBG} />
            <Button
              color='transparent'
              className='position-absolute p-1'
              onClick={toggle}
              style={{ top: 16, right: 16 }}
            >
              <X size={24} />
            </Button>
            <div
              className='position-absolute w-100 d-flex justify-content-center'
              style={{ top: 90, paddingRight: 57 }}
            >
              <CashBackIcon size={320} color='var(--cyan-100)' />
            </div>
          </div>
          <div className='font-size-32 font-weight-bold text-center px-4 pt-4 pb-2'>
            Reward unlocked!
          </div>
          <div className='font-size-16 text-center px-4 pb-4 pt-2'>
            Way to go {userName}! your earnings now will be sent automatically
            to your card by {date}. You can still grow your cashback until the
            end of the current month
          </div>
          <div className='w-100 d-flex align-items-center justify-content-center pb-5'>
            <Button
              onClick={toggle}
              textClassName='font-size-14'
              style={{ minHeight: 48 }}
            >
              View my earnings
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default RewardUnlockedModal
