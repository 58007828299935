import { yupResolver } from '@hookform/resolvers/yup'
import { Trash } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Button from '../../../../components/ui/button'
import InputFeedback from '../../../../components/ui/input-feedback'
import { mapCountryToOption } from '../../../../utils/map-to-option'
import { InfoList, getCompanyInfo } from './management-fee-management'

const emptyConfig = {
  country_id: null,
  eor_annual_management_fees: null,
  eor_setup_fee: null,
}
const managementFeeFormId = 'management-fee-form'
export const beConfigsKey = 'regional_configs'
export function ManageEorFeeModal({
  company,
  onSubmit,
  isOpen,
  toggle,
  loading,
}) {
  const [nulledItems, setNulledItems] = useState([])

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [beConfigsKey]: company[beConfigsKey].filter((config) => {
        return !!config.eor_annual_management_fees || !!config.eor_setup_fee
      }),
      eor_setup_fee: company.eor_setup_fee,
      setup_enabled: !!company.eor_setup_fee,
    },
    resolver: yupResolver(
      yup.object().shape({
        regional_configs: yup
          .array()
          .of(
            yup.object().shape({
              country_id: yup
                .number()
                .required('Country is required')
                .moreThan(0, 'Country is required'),
              eor_annual_management_fees: yup
                .number()
                .required('Amount is required')
                .moreThan(0, 'Minimum amount is 1'),
            }),
          )
          .test(
            'unique-country-id',
            'You can only select a country once',
            function (value) {
              const countryIds = value.map((config) => config.country_id)
              const uniqueCountryIds = new Set(countryIds)
              return uniqueCountryIds.size === countryIds.length
            },
          )
          .optional(),
        eor_setup_fee: yup
          .number()
          .when('setup_enabled', {
            is: true,
            then: (schema) => schema.required('Setup fee is required'),
          })
          .nullable(),
      }),
    ),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: beConfigsKey,
  })

  const companyInfo = getCompanyInfo(company)
  const { eor_countries: eorCountries } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )

  const configCountries = watch(beConfigsKey).map((config) => config.country_id)
  const filteredCountries = eorCountries?.filter(
    (country) => !configCountries.includes(country.id),
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg' className='!tw-max-w-2xl'>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Edit EOR Fee
      </ModalHeader>

      <ModalBody>
        <div className='bg-surface-10 border mb-3 px-2.5 py-2 rounded'>
          <InfoList items={companyInfo} />
        </div>

        <hr className='-tw-mx-4' />

        <form
          onSubmit={handleSubmit((values) =>
            onSubmit({ ...values, nulledItems }),
          )}
          className='d-flex flex-column gap-14'
          id={managementFeeFormId}
        >
          <input
            type='hidden'
            {...register('company_id', { value: company?.id })}
          />

          <ControlledCheckbox
            control={control}
            id='setup_enabled'
            name='setup_enabled'
            label='Setup fee'
            containerClassName='!tw-inline-flex'
            className='-tw-ml-2 tw-cursor-pointer tw-rounded-full tw-py-1 !tw-pl-11 tw-pr-2 hover:tw-bg-surface-20'
          />

          {!watch('setup_enabled') ? null : (
            <ControlledCurrencyInput
              control={control}
              id='eor_setup_fee'
              name='eor_setup_fee'
              label='Setup fee'
              prefix='$ '
            />
          )}

          <hr className='-tw-mx-4 tw-my-0' />

          <h4 className='tw-mb-0 tw-text-sm'>EOR fees, country exceptions</h4>

          {fields.map((field, index) => {
            return (
              <div className='tw-flex tw-items-start tw-gap-4' key={index}>
                <ControlledSelect
                  control={control}
                  wrapperClassName='tw-flex-1'
                  label='Country'
                  inputId={`${beConfigsKey}.${index}.country_id`}
                  name={`${beConfigsKey}.${index}.country_id`}
                  controlValue={(valueId) => {
                    return mapCountryToOption(
                      eorCountries?.find((country) => country.id === valueId),
                    )
                  }}
                  options={
                    filteredCountries
                      ? filteredCountries?.map((country) =>
                          mapCountryToOption(country),
                        )
                      : []
                  }
                />

                <ControlledCurrencyInput
                  control={control}
                  id={`${beConfigsKey}.${index}.eor_annual_management_fees`}
                  name={`${beConfigsKey}.${index}.eor_annual_management_fees`}
                  label='Annual management fees'
                  prefix='$ '
                  containerClassName='tw-flex-1'
                />

                <ControlledCurrencyInput
                  control={control}
                  id={`${beConfigsKey}.${index}.eor_setup_fee`}
                  name={`${beConfigsKey}.${index}.eor_setup_fee`}
                  label='Setup fee'
                  prefix='$ '
                  containerClassName='tw-flex-1'
                />

                <Button
                  size='sm'
                  className='tw-h-[42px] tw-self-end'
                  color='danger'
                  outline
                  onClick={() => {
                    remove(field.id)
                    if (field.country_id) {
                      setNulledItems((prev) => [
                        ...prev,
                        { ...emptyConfig, country_id: field.country_id },
                      ])
                    }
                  }}
                  icon={<Trash size={18} />}
                />
              </div>
            )
          })}

          {errors[beConfigsKey]?.message && (
            <InputFeedback className='mt-2'>
              {errors[beConfigsKey]?.message}
            </InputFeedback>
          )}

          <Button
            className='tw-self-end'
            onClick={() => append({ ...emptyConfig })}
            type='button'
            size='sm'
            outline
          >
            Add exception
          </Button>
        </form>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={managementFeeFormId}
          disabled={loading}
          loading={loading}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}
