import React, { useState } from 'react'
import { Collapse } from 'reactstrap'

import { StyledH3 } from '../../../components/Typo'
import { CaretDown, CaretUp } from '@phosphor-icons/react'

export function QuestionItem({ title, component, defaultStatus = false }) {
  const [isOpen, setIsOpen] = useState(defaultStatus)
  const onHeaderClick = () => {
    setIsOpen((t) => !t)
  }
  return (
    <div className='rp-shadow-2 mb-3 bg-white rounded'>
      <button
        className='d-flex align-items-center justify-content-between text-left bg-transparent w-100 border-0'
        style={{ padding: '1.325rem' }}
        onClick={onHeaderClick}
      >
        <StyledH3
          style={{ maxWidth: '76%' }}
          className='rp-font-bold m-0'
          min='16px'
          max='24px'
        >
          {title}
        </StyledH3>
        {isOpen ? (
          <CaretUp weight='bold' size={24} />
        ) : (
          <CaretDown weight='bold' size={24} />
        )}
      </button>
      <Collapse isOpen={isOpen}>{component}</Collapse>
    </div>
  )
}
