import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'

import customStyles from '../../../../components/Common/react-select-custom-styles'
import { searchNetSuiteVendors } from '../../../../services/api'

const createNewOption = { label: 'Create New', value: null }

export function VendorContractorSelect({
  placeholder,
  menuPosition,
  defaultOptions,
  name,
  onChange,
  value,
}) {
  const userToken = useSelector((state) => state.Account?.user?.token)
  const { mutate } = useMutation({
    mutationFn: (data) => {
      return searchNetSuiteVendors(userToken, data)
    },
  })

  function loadOptions(inputValue, callback) {
    mutate(
      { search_key: inputValue },
      {
        onSettled: (data) => {
          const options = data?.data?.data?.map((vendor) => {
            return {
              value: vendor?.account_id,
              label: vendor?.name,
            }
          })
          const allOptions = [createNewOption, ...options]
          callback(allOptions)
        },
        onError: (error) => {
          const emptyData = [createNewOption]
          callback(emptyData)
          console.log('Vendor Error', error)
        },
      },
    )
  }

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      components={{ IndicatorSeparator: null }}
      placeholder={placeholder}
      menuPosition={menuPosition}
      defaultOptions={defaultOptions}
      name={name}
      onChange={onChange}
      value={value}
      styles={customStyles}
    />
  )
}
