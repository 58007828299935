import React from 'react'
import { cn } from 'ui'
import BadgeX from '../../../components/Table/BadgeX'
import Flag, { getFlagUrlFromIso2 } from '../../../components/ui/flag'
import { Eye, IdentificationCard } from '@phosphor-icons/react'
import Button from '../../../components/ui/button'

export default function Entity({ data, isMain, onViewDetailsClick }) {
  return (
    <div className='tw-flex tw-flex-col tw-rounded tw-border tw-border-surface-30 tw-p-4'>
      <div className='tw-flex tw-items-center tw-justify-between'>
        <span
          className={cn(
            'tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full',
            data.logo ? 'tw-bg-surface-20' : 'tw-bg-primary',
          )}
        >
          {data.logo ? (
            <img src={data.logo} alt='Company logo' className='tw-h-6 tw-w-6' />
          ) : (
            <span className='tw-font-bold tw-uppercase tw-text-white'>
              {data.name.charAt(0)}
            </span>
          )}
        </span>
        <BadgeX
          className={cn(
            '!tw-flex !tw-items-center !tw-justify-center !tw-rounded-[32px] !tw-px-2 !tw-py-1',
            isMain ? '!tw-bg-primary' : '!tw-bg-primary-20',
          )}
          textClassName={cn('tw-font-bold tw-leading-[18px]', {
            'tw-text-primary': !isMain,
          })}
          size='sm'
        >
          {isMain ? 'MAIN COMPANY' : 'ENTITY'}
        </BadgeX>
      </div>

      <span className='tw-mb-2 tw-mt-4 tw-text-sm tw-font-bold tw-text-black'>
        {data.name}
      </span>

      <div className='tw-mb-2 tw-flex'>
        <Flag url={getFlagUrlFromIso2(data.country.iso2)} />
        <span className='tw-ml-2 tw-text-xs tw-font-medium tw-text-text-80'>
          {data.country.name}
        </span>
      </div>

      <div className='tw-mb-4 tw-flex'>
        <IdentificationCard size={16} />
        <span className='tw-ml-2 tw-text-xs tw-font-medium tw-text-text-80'>
          {`${data.contracts} contract${data.contracts > 1 ? 's' : ''}`}
        </span>
      </div>

      <Button outline icon={<Eye size={20} />} onClick={onViewDetailsClick}>
        View Details
      </Button>
    </div>
  )
}
