import toastr from 'toastr'

import { useFetch } from '../../../helpers/hooks'
import {
  getAdminRecurringExpensesList,
  getRecurringExpensesList,
} from '../../../services/api'

export default function useRecurringExpenses({ isAdmin, contract }) {
  const list = useFetch(
    {
      action: isAdmin
        ? getAdminRecurringExpensesList
        : getRecurringExpensesList,
      withAdminAccess: isAdmin,
      autoFetch: !!contract?.id,
      onError: toastr.error,
      body: { contract_id: contract?.id },
    },
    [contract?.id],
  )

  return list
}
