export const nameRegExp = /^[a-zA-Z ]*$/
export const addressRegExp = /^[0-9a-zA-Z, ]*$/

export function areNamesLatin(names) {
  const ns = names.filter(Boolean)
  const namesAreLatin = ns.every((name) => {
    return !(name?.length > 0 && !nameRegExp.test(name))
  })

  return namesAreLatin
}

export function isAddressLatin(adds) {
  const filteredAddresses = adds.filter((a) => a.length > 0).filter(Boolean)
  const addressesAreLatin = filteredAddresses.every((address) => {
    return !!addressRegExp.test(address)
  })

  return addressesAreLatin
}
