import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import cx from 'classnames'
import React, { useMemo, useState } from 'react'
import { Col, Input, ModalFooter, Row } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../components/ui/button'
import { useResize } from '../../helpers/hooks'

const useOptions = () => {
  const options = useMemo(
    () => ({ style: { invalid: { color: '#9e2146' } } }),
    [],
  )

  return options
}

const StripeForm = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

  return (
    <Elements stripe={stripePromise}>
      <SplitForm {...props} />
    </Elements>
  )
}

const SplitForm = ({
  onSubmitted,
  loading,
  setLoading,
  onFocus,
  onPay,
  isNewCard,
  onCancel = () => {},
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()
  const [name, setName] = useState(null)
  const isMobile = useResize()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setLoading(true)

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: { name },
    })

    if (payload?.error) {
      toastr.error(payload.error.message)
      setLoading(false)
    } else {
      onSubmitted(payload)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          minHeight: isMobile ? '70vh' : undefined,
          marginBottom: isMobile ? '5rem' : undefined,
        }}
        className='p-0 pt-3'
      >
        <Col>
          <Row>
            <Col>
              <label>Card Holder</label>
              <Input
                onChange={(e) => {
                  setName(e.target.value)
                }}
                className='form-control'
                placeholder='Card holder'
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <label>Card Number</label>
              <CardNumberElement
                className='form-control'
                options={options}
                onFocus={onFocus}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col sm={12} md={6}>
              <label>Expiration Date</label>
              <CardExpiryElement
                className='form-control'
                options={options}
                onFocus={onFocus}
              />
            </Col>
            <Col sm={12} md={6} className='mt-3 mt-md-0'>
              <label>CVV</label>
              <CardCvcElement
                className='form-control'
                options={options}
                onFocus={onFocus}
              />
            </Col>
          </Row>
        </Col>

        <ModalFooter className={cx({ 'fixed-bottom bg-white': isMobile })}>
          <Button color='light' outline disabled={loading} onClick={onCancel}>
            Cancel
          </Button>

          <Button
            loading={loading}
            color={stripe ? 'primary' : 'secondary'}
            disabled={!stripe || loading}
            type='submit'
            onClick={!isNewCard ? onPay : null}
            data-secret={process.env.REACT_APP_STRIPE_KEY}
          >
            Add Card
          </Button>
        </ModalFooter>
      </div>
    </form>
  )
}

export default StripeForm
