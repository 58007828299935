import React, { useEffect, useState } from 'react'
import toastr from 'toastr'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { checkExportStatus, downloadAllInvoices } from '../../../services/api'

const DownloadInvoicesButton = ({ invoices, month, icon, className }) => {
  const [loading, setLoading] = useState(false)

  const ExportStatuses = {
    FAILED: 'failed',
    READY: 'ready',
    PENDING: 'pending',
  }

  const allRef = invoices?.map((invoice) => invoice.invoice_ref)
  const checkDownloadStatus = useFetch(
    {
      action: checkExportStatus,
      autoFetch: false,
      onComplete: (data) => {
        if (data?.status === ExportStatuses.FAILED) {
          toastr.error('Failed to export')
          setLoading(false)
        } else if (data?.status === ExportStatuses.READY) {
          const link = document.createElement('a')
          link.href = data?.url
          link.setAttribute(
            'download',
            sendExportRequest.data?.path || 'AllInvoices.zip',
          )
          document.body.appendChild(link)
          link.click()

          setLoading(false)
        }
      },
      onError: (err) => {
        setLoading(false)
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  const sendExportRequest = useFetch(
    {
      action: downloadAllInvoices,
      autoFetch: false,
      body: { refs: allRef, month: month?.value },
      onError: (err) => {
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
      if (sendExportRequest.data?.id) {
        checkDownloadStatus.startFetch({
          exportId: sendExportRequest.data?.id,
        })
      }
    }, 5000)

    if (checkDownloadStatus.data?.url) {
      clearInterval(timeIntervalId)
    }

    return () => clearInterval(timeIntervalId)
  }, [sendExportRequest.data, checkDownloadStatus.data])

  const startDownloadProcess = () => {
    setLoading(true)
    sendExportRequest.startFetch()
  }

  return invoices?.length === 0 ? null : (
    <Button
      onClick={() => startDownloadProcess()}
      disabled={loading}
      loading={loading}
      icon={icon ?? null}
      className={className}
    >
      Download invoices
    </Button>
  )
}

export default DownloadInvoicesButton
