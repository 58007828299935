import React from 'react'
import { Controller } from 'react-hook-form'

import { RadioButtonsV2 } from './radio-buttons-v2'

export function ControlledRadioButtonV2({
  control,
  name,
  transform,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const {
          onChange: onChangeField,
          value: valueField,
          onBlur,
          name,
          ref,
        } = field

        function handleChange(event) {
          if (typeof transform?.output === 'function') {
            onChangeField(transform.output(event))
          } else {
            onChangeField(event)
          }
        }

        const value =
          typeof transform?.input === 'function'
            ? transform.input(valueField)
            : valueField

        return (
          <RadioButtonsV2
            {...props}
            name={name}
            onChange={handleChange}
            value={value}
            onBlur={onBlur}
            fieldRef={ref}
            error={error}
          />
        )
      }}
    />
  )
}
