import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Row, Spinner } from 'reactstrap'
import toastr from 'toastr'

import { useFetch } from '../../../helpers/hooks'
import {
  getAdminCalculatorResult,
  getCalculatorResult,
} from '../../../services/api'
import QuoteView from './Quote/QuoteView'

export function Quote({
  updateQuote,
  quoteResults,
  localCountryCode,
  customerCurrencyCode,
  salaryAmountInCustomerCurrency,
  noOfHires,
}) {
  const [quote, setQuote] = useState(null)
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')

  const getCurrencyCalculations = useFetch({
    action: isAdmin ? getAdminCalculatorResult : getCalculatorResult,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      setQuote(data)

      quoteResults(data)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    if (
      Number(salaryAmountInCustomerCurrency) &&
      Number(salaryAmountInCustomerCurrency) > 0 &&
      getCurrencyCalculations.isLoading === false
    ) {
      getCurrencyCalculations.startFetch({
        country_code: localCountryCode,
        annual_salary: Number(salaryAmountInCustomerCurrency),
        currency: customerCurrencyCode,
      })
    }
  }, [
    localCountryCode,
    customerCurrencyCode,
    salaryAmountInCustomerCurrency,
    noOfHires,
    updateQuote,
  ])

  return (
    <>
      {getCurrencyCalculations.isLoading ? (
        <Row className='w-100 justify-content-center p-5'>
          <Spinner type='border' color='primary' />
        </Row>
      ) : getCurrencyCalculations?.error ? (
        <div className='bg-soft-danger rounded text-danger py-3 border border-danger p-3'>
          <p className='mb-0 d-flex'>
            <i className='bx bx-info-circle text-danger mr-2 font-weight-light font-size-20 ml-1' />
            {getCurrencyCalculations?.error}
          </p>
        </div>
      ) : quote?.original?.data?.error ? (
        <div className='bg-soft-primary rounded text-primary py-3 border border-primary p-3'>
          <p className='mb-0 d-flex'>
            <i className='bx bx-info-circle text-primary mr-2 font-weight-light font-size-20 ml-1' />
            {quote?.original?.data?.error}
          </p>
        </div>
      ) : (
        <QuoteView quote={quote} customerCurrencyCode={customerCurrencyCode} />
      )}
    </>
  )
}
