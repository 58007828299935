import React from 'react'

import PageHeading from '../../components/ui/page-heading'
import { AddTimeOff } from './add-time-off'

export default function TimeOffPageHeader({ updateTimeOff, timeOffs }) {
  return (
    <PageHeading>
      <PageHeading.Title>Time off</PageHeading.Title>

      {timeOffs?.list?.length <= 0 ? null : (
        <AddTimeOff onSubmit={updateTimeOff} timeOffs={timeOffs?.list} />
      )}
    </PageHeading>
  )
}
