import React from 'react'
import { Link } from 'react-router-dom'

const ContractRef = ({
  contractId,
  isAdmin,
  target = '_blank',
  withHash = false,
}) => {
  return (
    <Link
      to={
        isAdmin
          ? `/admin/contract-detail?id=${contractId}`
          : `/contract/detail?id=${contractId}`
      }
      target={target}
    >
      {withHash && '#'}
      {contractId}
    </Link>
  )
}

export default ContractRef
