'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { DotsThreeOutlineVertical, Spinner } from '@phosphor-icons/react';
import { cn } from '../lib/utils/cn';
export function getMenuItemsOrigin(position) {
    const [x, y] = position.split(' ');
    switch (y) {
        case 'start': {
            switch (x) {
                case 'top': {
                    return 'tw-origin-bottom-left';
                }
                case 'bottom': {
                    return 'tw-origin-top-left';
                }
                default: {
                    return '';
                }
            }
        }
        case 'end': {
            switch (x) {
                case 'top': {
                    return 'tw-origin-bottom-right';
                }
                case 'bottom': {
                    return 'tw-origin-top-right';
                }
                default: {
                    return '';
                }
            }
        }
        default: {
            return '';
        }
    }
}
export function ActionsDropdown({ className, loading, data, position = 'bottom end', }) {
    var _a;
    const purifiedData = (_a = data === null || data === void 0 ? void 0 : data.filter(Boolean)) !== null && _a !== void 0 ? _a : [];
    return (_jsxs(Menu, { children: [_jsx(MenuButton, { className: cn(`tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded tw-bg-secondary-10 tw-p-1.5 tw-text-secondary-100 tw-transition-colors tw-duration-75 hover:tw-bg-surface-30 [&>*]:tw-size-full`, className), type: 'button', children: loading ? (_jsx(Spinner, { className: 'tw-animate-[spin_2s_linear_infinite]' })) : (_jsx(DotsThreeOutlineVertical, { weight: 'fill' })) }), _jsx(MenuItems, { portal: true, transition: true, anchor: { to: position }, className: cn('tw-min-w-[230px] tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-py-2 tw-shadow-lg tw-transition tw-duration-100 tw-ease-out focus:tw-outline-none data-[closed]:tw-scale-95 data-[closed]:tw-opacity-0', getMenuItemsOrigin(position)), style: { '--anchor-gap': '0.5rem' }, children: purifiedData.map((item) => {
                    return (_jsx(MenuItem, { children: _jsxs("button", { className: cn('tw-flex tw-w-full tw-items-center tw-gap-x-2 tw-p-4 tw-text-start tw-text-sm tw-text-black [&:not(:disabled)]:hover:tw-bg-secondary-20', item.className, { 'tw-cursor-not-allowed': item.disabled }), onClick: item.onClick, disabled: item.disabled, type: 'button', children: [!item.icon ? null : (_jsx("span", { className: 'tw-min-w-[0.5rem]', children: item.icon })), _jsx("span", { children: item.label })] }) }, item.label));
                }) })] }));
}
