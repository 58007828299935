import React from 'react'
import { Card, Container, FormGroup, Label } from 'reactstrap'
import { useFetch } from '../../../../helpers/hooks'
import { getEORContractsAmendments } from '../../../../services/api'
import Pagination from '../../../../components/ui/pagination'
import Loader from '../../../../components/ui/loader'
import NavTabs from '../../../../components/ui/nav-tabs'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { useFilters } from '../cards/use-filters'
import { format } from 'date-fns'
import SearchBar from '../../../../components/SearchBar'
import { useLocation } from 'react-router-dom'
import AmendmentsTable from './components/amendments-table'

function Amendments() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const action = searchParams.get('action')

  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    month: action === 'from-todo' ? null : new Date(),
    status: 'pending',
  })

  const {
    data,
    paginator,
    isLoading,
    startFetch: refresh,
  } = useFetch(
    {
      action: getEORContractsAmendments,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        ...filters,
        month: filters?.month ? format(filters?.month, 'yyyy-MM-dd') : null,
      },
    },
    [filters],
  )

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Amendments</h1>

        <Card>
          <NavTabs
            className='p-3'
            options={[
              {
                label: 'Pending',
                value: 'pending',
                disabled: isLoading,
              },
              {
                label: 'Pending Signature',
                value: 'pending_signature',
                disabled: isLoading,
              },
              { label: 'Signed', value: 'signed', disabled: isLoading },
            ]}
            activeOption={filters.status}
            onClickOption={(option) =>
              handleFiltersChange('status', option.value)
            }
          />
          <div className='d-flex align-items-center p-3 gap-8'>
            <CustomDatePicker
              value={filters.month}
              handleOnChange={(val) =>
                handleFiltersChange('month', val, { action: 'clear' })
              }
              maxDate={new Date()}
              placeholder='Select Month...'
              clearable
              label='Month'
              dateFormat='MM-yyyy'
              wrapperStyles={{ minWidth: 170 }}
              handleClear={() =>
                handleFiltersChange('month', null, { action: 'clear' })
              }
              showMonthYearPicker
              showFullMonthYearPicker
            />
            <FormGroup className='mb-0 flex-grow-1'>
              <Label>Search</Label>
              <SearchBar
                query={filters.search}
                onQueryChanged={(e) =>
                  handleFiltersChange('search', e, { action: 'clear' })
                }
                placeholder='Search by employee name or contract ID'
              />
            </FormGroup>
          </div>

          {isLoading ? (
            <>
              <Loader style={{ minHeight: 300 }} className='w-100' />
            </>
          ) : data?.length === 0 ? (
            <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
              No Amendments Pending
            </div>
          ) : (
            <AmendmentsTable showContractInfo data={data} refresh={refresh} />
          )}
          {showPaginator && !isLoading && (
            <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={paginator?.per_page ?? 10}
                totalItemsCount={paginator?.total ?? 0}
                onChange={(newPage) => handleFiltersChange('page', newPage)}
              />
            </div>
          )}
        </Card>
      </Container>
    </div>
  )
}
export default Amendments
