import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import InputFeedback from '../../../../components/ui/input-feedback'
import { useFetch } from '../../../../helpers/hooks'
import { getLocalCurrencies, simulateFee } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import LabelContent from '../../../Contract/CreateContract/components/label-content'
import SimulatorReview from '../components/SimulatorReview'

function FeeSimulator() {
  const staticData = useSelector((state) => state.Layout.staticData)
  const schema = yup.object().shape({
    amount: yup.number().required('The amount field is required.'),
    base_currency_id: yup.number().required('The currency field is required.'),
    account_currency_id: yup
      .number()
      .required('The withdraw currency field is required.'),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const simulate = useFetch({
    action: simulateFee,
    withAdminAccess: true,
    onError: (error) => {
      toastr.error(error)
    },
  })
  const onSubmit = (values) => {
    simulate.startFetch(values)
  }

  const amountErrors = [
    errors?.amount?.message,
    errors?.base_currency_id?.message,
  ].filter(Boolean)

  return (
    <div className='page-content'>
      <h1 style={{ marginBottom: '2rem' }}>Fee Simulator</h1>

      <Card className='mt-3'>
        <Form
          autoComplete='off'
          className='py-3 py-md-4'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Container fluid>
            <Row>
              <Col md={6} sm={12}>
                <FormGroup className='mb-0'>
                  <Label htmlFor='amount'>
                    <LabelContent required>Amount</LabelContent>
                  </Label>
                  <InputGroup>
                    <ControlledCurrencyInput
                      control={control}
                      name='amount'
                      id='amount'
                      error={errors.amount}
                      className='!tw-rounded-r-none'
                      hideError
                      style={{ height: 42 }}
                    />
                    <InputGroupAddon addonType='append'>
                      <ControlledSelect
                        error={errors.base_currency_id}
                        control={control}
                        name='base_currency_id'
                        options={staticData?.currencies?.map((c) =>
                          mapCurrencyToOption(c, 'id'),
                        )}
                        classNamePrefix='RS-Addon'
                        showError={false}
                      />
                    </InputGroupAddon>
                  </InputGroup>

                  {amountErrors.length <= 0
                    ? null
                    : amountErrors.map((error) => {
                        return (
                          <InputFeedback key={error} className='mt-1'>
                            {error}
                          </InputFeedback>
                        )
                      })}
                </FormGroup>
              </Col>
              <Col md={3} sm={12}>
                <FormGroup className='mb-0'>
                  <Label>
                    <LabelContent required>Withdraw currency</LabelContent>
                  </Label>
                  <ControlledSelect
                    error={errors.account_currency_id}
                    control={control}
                    name='account_currency_id'
                    options={currencies?.data?.map((c) =>
                      mapCurrencyToOption(c, 'id'),
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button
              type='submit'
              loading={simulate.isLoading}
              disabled={simulate.isLoading}
              className='tw-mt-4'
            >
              Simulate
            </Button>
          </Container>
        </Form>

        {simulate?.data && !simulate.isLoading && (
          <SimulatorReview data={simulate.data} />
        )}
      </Card>
    </div>
  )
}

export default FeeSimulator
