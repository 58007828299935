import {
  CheckCircle,
  Circle,
  FlowArrow,
  WarningOctagon,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Alert from '../../../components/ui/alert'
import { BoxIcon } from '../../../components/ui/box'
import Button from '../../../components/ui/button'
import CardButtons from '../../../components/ui/card-button/card-buttons'
import { useFetch } from '../../../helpers/hooks'
import { assignBillApprovalFlow } from '../../../services/api-bill-payments'

function OptionIcon({ active, Icon }) {
  return (
    <BoxIcon className={active && 'tw-bg-primary-20 tw-text-primary-100'}>
      <Icon size={24} />
    </BoxIcon>
  )
}

export default function BillApprovalActionButton({
  approvalFlows = [],
  fetchingApprovalFlows,
  approvalFlow,
  onApprovalFlowChange,
}) {
  const [showModal, setShowModal] = useState(false)
  const [selectedFlow, setSelectedFlow] = useState({
    label: approvalFlow?.name,
    value: approvalFlow?.id,
  })

  const { isLoading: assigningFlow, startFetch: assignBillFlow } = useFetch({
    action: assignBillApprovalFlow,
    onComplete: () => {
      toastr.success(`Successfully assigned "${selectedFlow.label}" for bills.`)
      setShowModal(false)
      onApprovalFlowChange()
    },
    onError: (error) => toastr.error(error),
  })

  return (
    <>
      <span id='flowPicker'>
        <Button
          color='link'
          className='!tw-p-0'
          onClick={() => setShowModal(true)}
          disabled={fetchingApprovalFlows || !approvalFlows.length}
          loading={fetchingApprovalFlows}
        >
          {approvalFlow?.id ? 'Manage' : 'Select'}
        </Button>
      </span>

      {!approvalFlows.length && (
        <UncontrolledTooltip target='flowPicker'>
          You need to add an approval flow to perform this action.
        </UncontrolledTooltip>
      )}

      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        centered
        className='[&>div>div>h5]:!tw-w-full'
      >
        <ModalHeader>
          <span className='tw-flex tw-w-full tw-items-start tw-justify-between'>
            <span>
              <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                Bill Approval
              </p>
              <span className='tw-text-sm tw-text-text-80'>
                Assign a default approval flow for bill payment
              </span>
            </span>

            <ModalCloseButton toggle={() => setShowModal(false)} />
          </span>
        </ModalHeader>

        <ModalBody className='!tw-p-6'>
          <BillApprovalFlowSelector
            approvalFlows={approvalFlows}
            value={selectedFlow?.value}
            onChange={(val) => setSelectedFlow(val)}
          />

          <Alert
            className='tw-mt-4 tw-gap-4 !tw-border-systemRed-20 !tw-bg-systemRed-20 !tw-p-4 tw-text-sm'
            customIcon={
              <WarningOctagon size={24} className='tw-fill-systemRed' />
            }
          >
            Assigning a different flow will reset the status of bills that are
            not yet approved to the initial stage of approval.
          </Alert>
        </ModalBody>
        <ModalFooter className='tw-justify-end tw-gap-2'>
          <Button
            outline
            color='light'
            disabled={assigningFlow}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              assignBillFlow({ approval_flow_id: selectedFlow.value })
            }
            loading={assigningFlow}
            disabled={assigningFlow || !selectedFlow}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function BillApprovalFlowSelector({
  approvalFlows = [],
  value,
  onChange,
}) {
  const customFlows = approvalFlows?.map((flow) => ({
    label: flow.name,
    value: flow.id,
    description: `${flow.steps.length ?? 1}-level sequential approval flow`,
    icon: (active) => <OptionIcon active={active} Icon={FlowArrow} />,
    rightItem: (active) =>
      active ? (
        <CheckCircle size={24} weight='fill' className='tw-fill-primary' />
      ) : (
        <Circle size={24} className='tw-fill-surface-40' />
      ),
  }))

  return (
    <CardButtons
      options={customFlows}
      className='tw-flex tw-flex-col tw-gap-4 [&>*>button>:nth-child(2)]:tw-flex-1 [&>*>button]:tw-gap-4'
      value={value}
      onChange={onChange}
      buttonClassName='tw-p-4'
      labelClassName='tw-text-sm tw-break-all'
      descriptionClassName='tw-text-sm tw-mt-0'
    />
  )
}
