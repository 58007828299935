import { Buildings, CaretUpDown, Gear, SignOut, X } from '@phosphor-icons/react'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { BE_CONTRACT_CATEGORY, userTypes } from '../helpers/enum'
import { usePermissions } from '../helpers/hooks'
import permissions from '../helpers/permissions'
import { saveRoute } from '../store/actions'
import { toggleLeftMenu } from '../store/layout/actions'
import CreateContractBtn, {
  MainSidebarActionIcon,
  mainSidebarActionLabel,
} from './Common/create-contract-btn'
import { EntitiesMenu } from './entities-menu'
import LogoDark from './ui/logo-dark'
import { SidebarLinks } from './VerticalLayout/user-sidebar-content'

export const sideIconStyles = { size: 20, className: 'tw-text-text-60' }

export default function BurgerMenu() {
  const leftMenu = useSelector((state) => state.Layout?.leftMenu)
  const history = useHistory()
  const dispatch = useDispatch()
  const { hasAccess } = usePermissions()
  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const contractorType = userProfile?.contractor_type
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  function closeBurger() {
    dispatch(toggleLeftMenu(false))
  }

  const styles = {
    bmMenuWrap: { top: 0, width: '100%', maxWidth: '375px', zIndex: 1050 },
    bmOverlay: { top: 0, bottom: 0, zIndex: 1040 },
    bmItemList: { height: 'auto', minHeight: '100%' },
  }

  const settingsLinks = []
  if (isClient) {
    settingsLinks.push({
      label: 'Company Settings',
      to: '/settings',
      icon: <Buildings {...sideIconStyles} />,
    })
    settingsLinks.push({
      label: 'Profile Settings',
      to: '/profile-settings',
      icon: <Gear {...sideIconStyles} />,
    })
  } else {
    settingsLinks.push({
      label: 'Profile Settings',
      to: '/settings',
      icon: <Gear {...sideIconStyles} />,
    })
  }

  return (
    <Menu
      isOpen={leftMenu}
      onClose={closeBurger}
      styles={styles}
      crossButtonClassName='!tw-hidden'
      itemListClassName='tw-bg-surface-20'
    >
      <div className='!tw-flex tw-h-[--header-height] tw-flex-shrink-0 tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-bg-white tw-px-4'>
        <Link to='/' className=''>
          <LogoDark />
        </Link>

        <button
          type='button'
          onClick={closeBurger}
          className='-tw-me-1.5 tw-rounded tw-p-1.5 hover:tw-bg-surface-20'
          aria-label='Close side menu'
        >
          <X size={16} weight='bold' />
        </button>
      </div>

      <div className='tw-p-4'>
        <div className='tw-group tw-relative tw-flex tw-items-center tw-justify-between md:tw-hidden'>
          <div className='tw-text-lg tw-font-medium'>
            Hi, <span translate='no'>{user?.first_name}</span>
          </div>

          <CreateContractBtn
            label={mainSidebarActionLabel({ isEmployee })}
            btnProps={{
              icon: null,
              color: 'link',
              iconRight: <MainSidebarActionIcon isEmployee={isEmployee} />,
            }}
            className='!tw-border-none !tw-px-0'
          />
        </div>

        {user?.type === userTypes.COMPANY && (
          <div className='tw-mt-4 tw-rounded tw-bg-white tw-p-2'>
            <EntitiesMenu
              closeMenu={closeBurger}
              menuButtonClassName='tw-p-2 hover:tw-bg-surface-10'
            />
          </div>
        )}

        <div className='tw-mt-4 tw-rounded tw-bg-white tw-p-4'>
          <SidebarLinks closeBurger={closeBurger} />
        </div>

        <div className='tw-mt-4 tw-rounded tw-bg-white tw-p-4'>
          {settingsLinks.map(({ to, label, icon }) => {
            return (
              <Link
                key={to}
                to={to}
                onClick={closeBurger}
                className='tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-p-4 tw-text-sm tw-text-text-80 hover:tw-bg-surface-100/10 hover:tw-text-text-80'
              >
                {icon}
                {label}
              </Link>
            )
          })}
        </div>

        {hasAccess(permissions.switchAccount) && user[0] && user[1] && (
          <div className='tw-mt-4 tw-rounded tw-bg-white tw-p-4'>
            <button
              className='tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-p-4 tw-text-start tw-text-sm tw-text-text-80 hover:tw-bg-surface-100/10'
              onClick={() => {
                history.push('/switch')
                closeBurger()
              }}
            >
              <CaretUpDown {...sideIconStyles} />

              <span>
                Switch to{' '}
                {user?.type === userTypes.CONTRACTOR ? 'client' : 'contractor'}{' '}
                account
              </span>
            </button>
          </div>
        )}

        <div className='tw-mt-4 tw-rounded tw-bg-white tw-p-4'>
          <button
            onClick={() => {
              history.push('/logout')
              dispatch(saveRoute(null))
              closeBurger()
            }}
            className='tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-p-4 tw-text-sm tw-text-systemRed-100 hover:tw-bg-systemRed-80/10'
          >
            <SignOut {...sideIconStyles} className='tw-text-current' />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </Menu>
  )
}
