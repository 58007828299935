import React from 'react'
import ToggleC from 'react-toggle'
import 'react-toggle/style.css'
import { cn } from 'ui'

const Toggle = ({
  check,
  change,
  id,
  disabled,
  className,
  'aria-label': ariaLabel,
  icons = false,
  name,
  style,
  marginRight = 'mr-2',
}) => {
  return (
    <ToggleC
      id={id}
      disabled={disabled}
      className={cn(
        // track classes
        '[&:hover>.react-toggle-track]:!tw-border-surface-50 [&:hover>.react-toggle-track]:!tw-bg-surface-10 [&>.react-toggle-track]:tw-h-6 [&>.react-toggle-track]:tw-w-10 [&>.react-toggle-track]:tw-border [&>.react-toggle-track]:tw-border-surface-40 [&>.react-toggle-track]:tw-bg-white',
        // track classes when checked
        '[&.react-toggle--checked:hover>.react-toggle-track]:!tw-border-transparent [&.react-toggle--checked:hover>.react-toggle-track]:!tw-bg-primary-110 [&.react-toggle--checked>.react-toggle-track]:tw-border-transparent [&.react-toggle--checked>.react-toggle-track]:!tw-bg-primary-100',
        // thumb classes
        '[&>.react-toggle-thumb]:tw-left-1 [&>.react-toggle-thumb]:tw-top-1 [&>.react-toggle-thumb]:tw-h-4 [&>.react-toggle-thumb]:tw-w-4 [&>.react-toggle-thumb]:tw-border-0 [&>.react-toggle-thumb]:tw-bg-surface-40',
        // thumb classes when checked
        '[&.react-toggle--checked>.react-toggle-thumb]:tw-left-5 [&.react-toggle--checked>.react-toggle-thumb]:tw-bg-white',
        marginRight,
        className,
        disabled && 'tw-cursor-not-allowed',
      )}
      icons={icons}
      checked={check}
      onChange={change}
      aria-label={ariaLabel}
      name={name}
      style={style}
    />
  )
}

export default Toggle
