import React, { useState } from 'react'
import { deleteFormStep } from '../../../../../services/api'
import { useFetch } from '../../../../../helpers/hooks'
import Button from '../../../../../components/ui/button'
import { PencilSimple, PlusCircle, TrashSimple } from '@phosphor-icons/react'
import AddOrUpdateField from './add-field'
import FormField from './form-field'

import toastr from 'toastr'
import AddOrEditStep from './add-step'
import IconButton from '../../../../../components/ui/icon-button'

const Step = ({ step, refetch, notEditable }) => {
  const [edit, setEdit] = useState(false)
  const [addField, setAddField] = useState({ show: false, field: null })
  const { startFetch: deleteStep } = useFetch({
    action: deleteFormStep,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Step deleted successfully')
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  return (
    <div className='tw-mb-6 tw-rounded-lg tw-border tw-border-surface-30 tw-bg-white tw-p-6'>
      {/* Step Header */}
      <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-pb-4'>
        <h3 className='tw-mb-0 tw-pr-10'>{step.title}</h3>
        {!notEditable && (
          <div className='overflow-hidden tw-relative tw-flex tw-items-center tw-gap-1'>
            <IconButton
              color='transparent'
              icon={<PencilSimple size={24} />}
              onClick={() => setEdit(true)}
            />
            <IconButton
              color='transparent'
              icon={<TrashSimple color='red' size={24} />}
              onClick={() =>
                deleteStep({ form_step_id: step.id, form_id: step.form_id })
              }
            />
          </div>
        )}
      </div>
      {/* Step content */}
      <div className='tw-flex tw-w-full tw-flex-col'>
        {step.form_fields?.map((field) => (
          <FormField
            onUpdateField={(field) => setAddField({ show: true, field })}
            key={field.id}
            field={field}
            notEditable={notEditable}
            refetch={refetch}
          />
        ))}
        {addField.show && (
          <AddOrUpdateField
            refetch={refetch}
            step={step}
            isOpen={addField.show}
            field={addField.field}
            toggle={() => setAddField({ show: false, field: null })}
          />
        )}
        {!notEditable && (
          <Button
            className='tw-my-4 !tw-border-surface-30'
            color='light'
            textClassName='tw-text-black tw-flex tw-grow-1 tw-items-center tw-gap-2'
            outline
            onClick={() => setAddField({ show: true, field: null })}
          >
            <PlusCircle /> Add Field
          </Button>
        )}
      </div>
      {edit && (
        <AddOrEditStep
          step={step}
          isOpen={edit}
          refetch={refetch}
          toggle={() => setEdit(false)}
        />
      )}
    </div>
  )
}

export default Step
