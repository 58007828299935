import { isBefore } from 'date-fns'
import { toDate } from 'date-fns-tz'
import enGB from 'date-fns/locale/en-GB'
import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import cardKycCheck from '../../../assets/images/card-kyc-check.svg'
import cardKycFail from '../../../assets/images/card-kyc-failed.svg'
import cardKycPending from '../../../assets/images/card-kyc-pending.svg'
import { ReactComponent as CardVerificationRequiredIllustration } from '../../../assets/images/card-verification-required.svg'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import BadgeX from '../../../components/Table/BadgeX'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { finalizeKycProcess, getContractorInfo } from '../../../services/api'
import { kycRejectionReasons } from '../../AdminPanel/pages/cards/cards-kyc'
import { VerificationAction } from '../../ContractorSettings/KYC'

export const KYC_STATUSES = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  FAILED: 'failed',
  FETCHING_ERROR: 'fetching_error',
  NOT_SUBMITTED: 'not_submitted',
  DONE: 'done',
}

export function getKycRejectedText(shouldShowKycModal, reason) {
  let content = 'Your KYC Verification Level 2 has failed'
  let actionText = 'Start again'

  switch (reason) {
    case kycRejectionReasons.OTHER: {
      content = 'Update your personal details'
      actionText = 'Update'
      break
    }
    case kycRejectionReasons.INVALID_POA: {
      content = 'Upload a valid proof of address'
      actionText = 'Upload POA'
      break
    }
    case kycRejectionReasons.INVALID_ID: {
      if (shouldShowKycModal) {
        content = 'Identity re-verification required'
        actionText = 'Get Verified'
      } else {
        content = 'Update your personal details'
        actionText = 'Update'
      }
      break
    }
    default: {
      content = 'Your KYC Verification Level 2 has failed'
      actionText = 'Start again'
    }
  }

  return { content, actionText }
}

const getKycBannerContent = (
  status,
  { onFinalizeKyc, history, shouldShowKycModal, reason, setShowKycModal },
) => {
  switch (status) {
    case KYC_STATUSES.PENDING:
    case KYC_STATUSES.ACCEPTED:
    case KYC_STATUSES.SUBMITTED: {
      return {
        icon: cardKycPending,
        content: 'Your KYC Verification Level 2 is in progress',
        action: null,
        actionText: 'Under review',
        actionColor: 'warning',
      }
    }
    case KYC_STATUSES.NOT_SUBMITTED:
    case KYC_STATUSES.FAILED:
    case KYC_STATUSES.FETCHING_ERROR: {
      return {
        icon: cardKycFail,
        content:
          'Sorry, something went wrong, your KYC Verification Level 2 has failed',
        action: () => {
          history.push('/cards/start-kyc')
        },
        actionText: 'Start again',
        actionColor: 'warning',
      }
    }
    case KYC_STATUSES.DONE:
    case KYC_STATUSES.APPROVED: {
      return {
        icon: cardKycCheck,
        content: 'Congratulations! Your are now verified 🎉',
        action: () => {
          onFinalizeKyc?.()
        },
        actionText: 'Got it',
        actionColor: 'success',
      }
    }
    case KYC_STATUSES.REJECTED: {
      const { content, actionText } = getKycRejectedText(
        shouldShowKycModal,
        reason,
      )
      return {
        icon: cardKycFail,
        content,
        action: () => {
          if (shouldShowKycModal) {
            setShowKycModal(true)
          } else {
            history.push('/cards/start-kyc')
          }
        },
        actionText,
        actionColor: 'success',
      }
    }
    default: {
      return {
        icon: cardKycPending,
        content: 'KYC Verification Level 2 is in progress',
        action: null,
        actionText: '',
        actionColor: '',
      }
    }
  }
}

export default function CardsKycBanner({ kycStatus, refetchCards }) {
  const [showKycModal, setShowKycModal] = useState(false)

  const history = useHistory()
  const kycVerifiedAt = useSelector(
    (state) => state?.userProfile?.userProfile?.kyc_verified_at,
  )
  const buttonRef = useRef(null)
  const status = kycStatus?.status

  const { startFetch: finalizeKyc, isLoading } = useFetch({
    action: finalizeKycProcess,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong')
      } else {
        toastr.success('KYC verification has been completed')
        refetchCards()
      }
    },
  })

  const { hasPassedKycAfterStatusUpdate, reason } = useMemo(() => {
    const reason = kycStatus?.rejection_reason
    const rejectedAt = kycStatus?.rejected_at

    // Could be any GMT+0 time zone, but we are using GMT0
    const timeZone = 'GMT0'
    const options = { timeZone, locale: enGB }

    const rejectedAtClonedDate = toDate(rejectedAt, options)

    const kycVerifiedAtDate = new Date(kycVerifiedAt * 1000)
    const kycVerifiedAtClonedDate = toDate(kycVerifiedAtDate, options)

    const hasPassedKycAfterStatusUpdate = isBefore(
      rejectedAtClonedDate,
      kycVerifiedAtClonedDate,
    )

    return { hasPassedKycAfterStatusUpdate, reason }
  }, [kycStatus?.rejected_at, kycStatus?.rejection_reason, kycVerifiedAt])

  const shouldShowKycModal =
    reason === kycRejectionReasons.INVALID_ID && !hasPassedKycAfterStatusUpdate

  function toggle() {
    setShowKycModal((open) => !open)
  }

  const { icon, content, action, actionText, actionColor } =
    getKycBannerContent(status, {
      history,
      onFinalizeKyc: finalizeKyc,
      shouldShowKycModal,
      setShowKycModal,
      reason,
    })

  return (
    <Card
      className='mb-0 px-4 py-3 flex-row flex-wrap align-items-center justify-content-between rp-shadow-2'
      style={{ gap: '1rem 2rem' }}
    >
      <div className='d-flex align-items-center' style={{ gap: '1rem' }}>
        <img src={icon} alt='' style={{ height: 64 }} />

        <div className='text-gray-h'>
          <p className='h5 mb-0 text-gray-h'>
            <span className='rp-font-bold'>{content}</span>{' '}
            <a
              href='https://help.remotepass.com/en/articles/6811860-what-is-the-verification-process-and-how-long-does-it-take'
              target='_blank'
              rel='noreferrer'
              className='underline'
              style={{ fontSize: 'smaller' }}
            >
              Learn more
            </a>
          </p>
        </div>
      </div>

      {action ? (
        <Button
          onClick={() => action()}
          ref={buttonRef}
          id='verify_kyc_banner'
          loading={isLoading}
          disabled={isLoading}
        >
          {actionText}
        </Button>
      ) : (
        <BadgeX status={actionColor} className='px-4 py-3'>
          {actionText}
        </BadgeX>
      )}

      <KycModal isOpen={showKycModal} toggle={toggle} />
    </Card>
  )
}

function KycModal({ isOpen, toggle }) {
  const { data: contractorInfo, isLoading } = useFetch(
    {
      action: getContractorInfo,
      autoFetch: isOpen,
    },
    [isOpen],
  )

  const provider = contractorInfo?.kyc_provider

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader
        className='border-0 pb-0'
        close={<ModalCloseButton toggle={toggle} />}
      />

      <ModalBody className='p-0 text-center'>
        <CardVerificationRequiredIllustration className='d-inline-block' />

        <p className='h2 mt-3 text-gray-h'>Verification required</p>

        <p
          style={{
            color: 'var(--gray-600)',
            maxWidth: '45ch',
            margin: '0px auto 2rem',
          }}
        >
          Your ID on file is not up to date. Please complete your identity
          verification using a government issued ID or Passport.
        </p>
      </ModalBody>

      <ModalFooter className='border-0'>
        <VerificationAction
          className=''
          kycProvider={provider}
          disabled={isLoading}
        >
          Proceed
        </VerificationAction>
      </ModalFooter>
    </Modal>
  )
}
