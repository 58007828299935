import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Card, NavItem, TabPane } from 'reactstrap'

import backgroundBlue from '../../assets/images/bkblue.svg'
import Head from '../../components/head'
import { PageNav } from '../../components/page-nav'
import { NewBadge } from '../../components/ui/badge-new'
import TabContent from '../../components/ui/tabs'
import FEATURE_FLAGS from '../../config/feature-flags'
import { contractorTypes, userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getCompanyInfo, getPaymentMethods } from '../../services/api'
import ContractorSettings from '../ContractorSettings'
import CompanyHistory from './CompanyHistory'
import CustomFields from './CustomFields'
import Customization from './Customization'
import Integrations from './Integrations'
import PaymentInfo from './PaymentInfo'
import ApiKeys from './api-keys'
import { ApprovalsTab } from './approvals/approval-tab'
import { AutomationsTab } from './components/automations-tab'
import { TimeOffPoliciesTab } from './components/time-off-policies-tab/time-off-policies-tab'
import { CustomFieldsTab } from './custom-fields'
import { DepositsRefundsTab } from './deposits-refunds-tab'
import CompanyEntities from './entities'
import { UsersRoles } from './users-roles'

export default function CompanySetting() {
  const location = useLocation()
  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector(
    (state) => state.userProfile?.userProfile ?? {},
  )
  const [activeTab, setActiveTab] = useState(1)
  const display = useSelector((state) => state.Account?.user?.type)
  const { hasAccess } = usePermissions()

  const paymentMethods = useFetch({
    action: getPaymentMethods,
    autoFetch: true,
  })

  const {
    startFetch: fetchCompanyInfo,
    data: companyInfoData,
    isLoading: companyInfoLoading,
    setData: setCompanyInfoData,
  } = useFetch({
    action: getCompanyInfo,
    autoFetch:
      userProfile.type === userTypes.COMPANY ||
      userProfile?.contractor_type === contractorTypes.ENTITY,
    onComplete: () => {
      window.analytics.track('Viewed company settings', {
        email_id: user?.email,
      })
    },
  })

  const tabsData = useMemo(() => {
    return [
      {
        id: 1,
        key: 'info',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bxs-id-card',
        label: 'Company Info',
        route: '/settings/info',
      },
      {
        id: 2,
        key: 'payment',
        access:
          hasAccess(permissions.ViewCompaniesInfo) &&
          paymentMethods.data?.length > 0,
        icon: 'bx bx-money',
        label: 'Payment',
        route: '/settings/payment',
      },
      FEATURE_FLAGS.DEPOSITS_REFUNDS_CLIENT_TAB && {
        id: 3,
        key: 'deposits-refunds',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-money',
        label: 'Deposits & Refunds',
        route: '/settings/deposits-refunds',
      },
      {
        id: 4,
        key: 'users',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-group',
        label: 'Users & Roles',
        route: '/settings/users',
      },
      FEATURE_FLAGS.TIME_OFF_POLICIES && {
        id: 5,
        key: 'timeOffPolicies',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-group',
        label: 'Time off policies',
        route: '/settings/time-off-policies',
      },
      {
        id: 6,
        key: 'payrollApproval',
        access:
          FEATURE_FLAGS.REMOTEPASS_PAYROLL_APPROVAL_PROCESS &&
          hasAccess(permissions.ViewCompaniesInfo),
        icon: 'bx bx-group',
        label: 'Approvals',
        route: '/settings/approvals',
      },
      {
        id: 7,
        key: 'customFields',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'Custom Fields',
        route: '/settings/customFields',
      },
      FEATURE_FLAGS.AUTOMATION_COMPANIES_TAB && {
        id: 8,
        key: 'automations',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'Automations',
        route: '/settings/automations',
      },
      {
        id: 9,
        key: 'integrations',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'Integrations',
        route: '/settings/integrations',
      },
      // @todo: this tab is not shown
      // {
      //   id: 10,
      //   key: 'customization',
      //   access: false,
      //   icon: 'fas fa-puzzle-piece',
      //   label: 'Customization',
      //   route: '/settings/customization',
      // },
      {
        id: 11,
        key: 'history',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'History',
        route: '/settings/history',
      },
      (companyInfoData?.api_keys_enabled ?? false) && {
        id: 12,
        key: 'apikeys',
        access: hasAccess(permissions.ViewCompaniesInfo),
        icon: 'fas fa-puzzle-piece',
        label: 'API keys',
        route: '/settings/apikeys',
      },
    ]?.filter((e) => Boolean(e) && e.access)
  }, [
    hasAccess,
    companyInfoData?.api_keys_enabled,
    paymentMethods.data?.length,
  ])

  useEffect(() => {
    const foundId = tabsData.find((tab) => {
      return location.pathname === tab.route
    })?.id

    if (!foundId) {
      setActiveTab(1)
    } else if (foundId !== activeTab) {
      setActiveTab(foundId)
    }
  }, [activeTab, location.pathname, tabsData])

  const tabName = tabsData.find((tab) => location.pathname === tab.route)?.label

  return display === userTypes.COMPANY ? (
    <div className='page-content position-relative'>
      <Head title={[tabName, 'Company settings'].filter(Boolean).join(' - ')} />

      <img
        className='position-absolute left-0 right-0 bg-primary w-100'
        style={{
          backgroundRepeat: 'repeat',
          backgroundImage: backgroundBlue ? `url(${backgroundBlue})` : null,
          top: 'var(--header-height)',
          height: 210,
        }}
      />

      <Card className='position-relative rp-shadow-2 mt-4.5 mb-0'>
        <PageNav>
          {tabsData?.map((nav) => {
            return (
              <NavItem key={`companySettingsNav:${nav.id}`}>
                <PageNav.Link
                  isActive={activeTab === nav.id}
                  tag={Link}
                  to={nav.route}
                >
                  {nav.label}{' '}
                  {['timeOffPolicies', 'automations'].includes(nav.key) ? (
                    <NewBadge className='tw-ml-1' />
                  ) : null}
                </PageNav.Link>
              </NavItem>
            )
          })}
        </PageNav>

        <TabContent activeTab={activeTab}>
          {tabsData?.map((nav) => {
            if (activeTab !== nav.id) {
              return null
            }

            switch (nav.key) {
              case 'info': {
                return renderSettingsTab(
                  nav.id,
                  <CompanyEntities
                    companyInfoData={companyInfoData}
                    companyInfoLoading={companyInfoLoading}
                    fetchCompanyInfo={fetchCompanyInfo}
                    setCompanyInfoData={setCompanyInfoData}
                  />,
                )
              }
              case 'payment': {
                return renderSettingsTab(nav.id, <PaymentInfo />)
              }
              case 'deposits-refunds': {
                return renderSettingsTab(nav.id, <DepositsRefundsTab />)
              }
              case 'users': {
                return renderSettingsTab(nav.id, <UsersRoles />)
              }
              case 'timeOffPolicies': {
                return renderSettingsTab(nav.id, <TimeOffPoliciesTab />)
              }
              case 'payrollApproval': {
                return renderSettingsTab(nav.id, <ApprovalsTab />)
              }
              case 'customFields': {
                return renderSettingsTab(
                  nav.id,
                  FEATURE_FLAGS.CUSTOM_FIELDS ? (
                    <CustomFieldsTab />
                  ) : (
                    <CustomFields />
                  ),
                )
              }
              case 'integrations': {
                return renderSettingsTab(nav.id, <Integrations />)
              }
              case 'apikeys': {
                return renderSettingsTab(nav.id, <ApiKeys />)
              }
              case 'customization': {
                return renderSettingsTab(
                  nav.id,
                  <Customization data={companyInfoData?.personalization} />,
                )
              }
              case 'history': {
                return renderSettingsTab(
                  nav.id,
                  <CompanyHistory
                    companyId={companyInfoData?.id}
                    companyInfoLoading={companyInfoLoading}
                  />,
                )
              }
              case 'automations': {
                return renderSettingsTab(nav.id, <AutomationsTab />)
              }
              default: {
                return <></>
              }
            }
          })}
        </TabContent>
      </Card>
    </div>
  ) : (
    <ContractorSettings />
  )
}

export function usePayrollApprovalEnabled() {
  const payrollApprovalEnabled = useSelector(
    (state) =>
      state.userProfile?.userProfile?.company?.is_payroll_approval_enabled?.toString() ===
      '1',
  )

  return payrollApprovalEnabled
}

function renderSettingsTab(navId, component) {
  return (
    <TabPane key={`tab:${navId}`} tabId={navId}>
      {component}
    </TabPane>
  )
}
