import React, { useState } from 'react'
import { useFetch } from '../../../../helpers/hooks'
import {
  downloadEORPartnerMasterOutputFile,
  generateEORPartnerOutputFile,
  getEORPartnersPayrollOutputs,
  getOutputPartners,
  reGenerateEORPartnerOutputFile,
  sendEORPartnerOutputFile,
} from '../../../../services/api'
import DataTable from '../../../../components/ui/data-table'
import Button from '../../../../components/ui/button'
import toastr from 'toastr'
import Pagination from '../../../../components/ui/pagination'
import Loader from '../../../../components/ui/loader'
import { useFilters } from '../cards/use-filters'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { format, isSameMonth, parseISO } from 'date-fns'
import openFileV2 from '../../../../utils/file/open-v2'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { getFullName } from '../../../../utils/get-full-name'

function PayrollOutputsFilters({ handleFiltersChange, filters }) {
  const { startFetch: downloadMasterFile, isLoading: isDownloadingMasterFile } =
    useFetch({
      action: downloadEORPartnerMasterOutputFile,
      withAdminAccess: true,
      onComplete: (data) => {
        openFileV2(data, {
          download: true,
          name: 'master-output-file-' + format(new Date(), 'yyyy-MM') + '.xlsx',
        })
      },
      onError: (err) => {
        toastr.error(err?.data?.error)
      },
    })

  const { data: partners } = useFetch({
    autoFetch: true,
    action: getOutputPartners,
    withAdminAccess: true,
  })

  return (
    <div className='p-4 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center gap-8'>
        <CustomDatePicker
          value={filters.target_date}
          handleOnChange={(val) =>
            handleFiltersChange('target_date', val, { action: 'clear' })
          }
          maxDate={new Date()}
          placeholder='Select Month...'
          clearable
          label='Month'
          dateFormat='MM-yyyy'
          wrapperStyles={{ minWidth: 170 }}
          handleClear={() =>
            handleFiltersChange('target_date', null, { action: 'clear' })
          }
          showMonthYearPicker
          showFullMonthYearPicker
        />
        <CustomSelect
          label='Partner'
          isClearable
          placeholder='Select Partner...'
          defaultValue={filters.month}
          options={partners?.map((partner) => ({
            value: partner.id,
            label: getFullName(partner),
          }))}
          wrapperStyles={{ minWidth: 200 }}
          wrapperClassName='mr-3'
          onChange={(option) =>
            handleFiltersChange('partner_id', option?.value, {
              action: 'clear',
            })
          }
        />
      </div>
      <Button
        loading={isDownloadingMasterFile}
        onClick={() => {
          downloadMasterFile()
        }}
      >
        Download Master file
      </Button>
    </div>
  )
}

function PayrollOutputsActions({ rowData, refreshResluts, targetDate }) {
  const { startFetch: generate, isLoading: isGenerateLoading } = useFetch({
    action: generateEORPartnerOutputFile,
    withAdminAccess: true,
    onComplete: (data) => {
      refreshResluts({})
      toastr.success('Payroll output was successfully generated!')
    },
    onError: (err) => {
      toastr.error(err?.data?.error)
    },
  })

  const { startFetch: regenerate, isLoading: isReGenerateLoading } = useFetch({
    action: reGenerateEORPartnerOutputFile,
    withAdminAccess: true,
    onComplete: (data) => {
      refreshResluts({})
      toastr.success('Payroll output was successfully re-generated!')
    },
    onError: (err) => {
      toastr.error(err?.data?.error)
    },
  })
  const { startFetch: sendOutput, isLoading: isSendLoading } = useFetch({
    action: sendEORPartnerOutputFile,
    withAdminAccess: true,
    onComplete: (data) => {
      refreshResluts({})
      toastr.success('Payroll output was successfully Sent!')
    },
    onError: (err) => {
      toastr.error(err?.data?.error)
    },
  })
  const currentDate = new Date()
  const date = parseISO(targetDate)
  const isCurrentMonth = isSameMonth(currentDate, date)

  return !rowData.output && isCurrentMonth ? (
    <div className='d-flex gap-8'>
      <Button
        size='sm'
        style={{ minWidth: 120 }}
        loading={isGenerateLoading}
        onClick={() => {
          generate({ id: rowData.id })
        }}
      >
        Generate
      </Button>
    </div>
  ) : (
    <div className='d-flex gap-8'>
      {isCurrentMonth &&
        rowData?.output?.can_regenerate === true &&
        rowData?.output?.is_sent === false && (
          <Button
            size='sm'
            outline
            style={{ minWidth: 120 }}
            loading={isReGenerateLoading}
            onClick={() => {
              regenerate({
                partnerId: rowData?.id,
                outputId: rowData?.output?.id,
              })
            }}
          >
            Re-generate
          </Button>
        )}
      {isCurrentMonth && (
        <Button
          size='sm'
          outline
          onClick={() => {
            openFileV2(rowData?.output?.link, {
              download: true,
              isDataUrl: true,
              name: `${rowData?.name}-${targetDate}.xlsx`,
            })
          }}
        >
          Download File
        </Button>
      )}

      {rowData?.output && (
        <Button
          size='sm'
          color='success'
          outline={rowData?.output?.is_sent}
          loading={isSendLoading}
          onClick={() => {
            sendOutput({
              partnerId: rowData?.id,
              outputId: rowData?.output?.id,
            })
          }}
        >
          {rowData?.output?.is_sent ? 'Re-send' : 'Send'}
        </Button>
      )}
    </div>
  )
}

function PayrollOutputs() {
  const [isLoadingForAction, setIsLoadingForAction] = useState(false)
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    target_date: new Date(),
  })

  const {
    data,
    paginator,
    isLoading,
    startFetch: refreshResults,
  } = useFetch(
    {
      action: getEORPartnersPayrollOutputs,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        ...filters,
        target_date: filters?.target_date
          ? format(filters?.target_date, 'yyyy-MM-dd')
          : null,
      },
      onComplete: (data) => {
        setIsLoadingForAction(false)
      },
    },
    [filters],
  )

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const columns = [
    {
      Header: 'Partner',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'No. of empoyees',
      accessor: 'number_of_employees',
    },
    {
      Header: 'Generated at',
      accessor: 'output.generated_at',
    },
    {
      Header: 'Sent at',
      accessor: 'output.sent_at',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd') : null
      },
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ cellData, rowData }) => (
        <PayrollOutputsActions
          refreshResluts={() => {
            refreshResults()
            setIsLoadingForAction(true)
          }}
          rowData={rowData}
          targetDate={format(
            filters.target_date ? filters.target_date : new Date(),
            'yyyy-MM-dd',
          )}
        />
      ),
    },
  ]

  return (
    <>
      <PayrollOutputsFilters
        handleFiltersChange={handleFiltersChange}
        filters={filters}
      />
      {isLoading && !isLoadingForAction ? (
        <Loader style={{ minHeight: 300 }} className='w-100' />
      ) : data?.length === 0 ? (
        <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
          No Adjustments Pending
        </div>
      ) : (
        <DataTable data={data} responsive columns={columns} />
      )}
      {showPaginator && (
        <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={paginator?.per_page ?? 10}
            totalItemsCount={paginator?.total ?? 0}
            onChange={(newPage) => handleFiltersChange('page', newPage)}
          />
        </div>
      )}
    </>
  )
}
export default PayrollOutputs
