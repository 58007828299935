import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import toastr from 'toastr'

import ConfirmationModal from '../../components/Common/ConfirmationModal'
import Toggle from '../../components/Forms/Toggle/Toggle'
import Button from '../../components/ui/button'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { changeUserStatus, resendInvite } from '../../services/api'
import { getFullName } from '../../utils/get-full-name'

export const USER_ARCHIVE_STATUS = {
  archived: 'archived',
  unarchive: 'unarchive',
}

export function ArchiveToggleUser({ user, onUpdate }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  function toggleConfirmModal() {
    setIsConfirmOpen((isOpen) => !isOpen)
  }

  const loggedUser = useSelector((state) => state?.Account?.user)

  const { startFetch: changeStatus, isLoading: changingStatus } = useFetch({
    action: changeUserStatus,
    onComplete: (data, body) => {
      if (data?.success === false) {
        toastr.error(
          data?.message || 'Something went wrong while archiving user',
        )
      } else {
        toastr.success(
          'User ' +
            (body.status === USER_ARCHIVE_STATUS.archived
              ? 'archived'
              : 'un-archived') +
            ' successfully',
        )
        onUpdate?.()
        setIsConfirmOpen(false)

        if (body.status === USER_ARCHIVE_STATUS.archived) {
          window.analytics.track('Archived a user', {
            archived_user_email_id: body?.user_id,
            archived_user_first_name: body?.user?.first_name,
            archived_user_last_name: body?.user?.last_name,
          })
        } else if (body.status !== USER_ARCHIVE_STATUS.unarchive) {
          window.analytics.track('Changed user status', {
            changed_user_id: body?.user_id,
            new_status: body.status,
          })
        }
      }
    },
    onError: (error) => {
      toastr.error(error || 'Something went wrong while archiving user')
    },
  })

  const isArchived = user?.status === USER_ARCHIVE_STATUS.archived

  function handleToggleArchive() {
    if (!isArchived) {
      toggleConfirmModal()
    } else {
      changeStatus({ user_id: user?.id, status: USER_ARCHIVE_STATUS.unarchive })
    }
  }

  if (user.id === loggedUser?.id) {
    return null
  }

  return (
    <>
      <p className='text-dark font-size-14 mb-0 d-md-none'>Action</p>

      {changingStatus ? (
        <Spinner size='sm' color='primary' />
      ) : (
        <Button
          color='link'
          className='-tw-ml-1.5 !tw-px-0'
          onClick={handleToggleArchive}
        >
          {isArchived ? 'UnArchive' : 'Archive'}
        </Button>
      )}

      <DeactivateConfirmModal
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        user={user}
        onConfirm={() =>
          changeStatus({
            user_id: user?.id,
            status: USER_ARCHIVE_STATUS.archived,
          })
        }
        loading={changingStatus}
        actionName='archive'
      />
    </>
  )
}

export function EditStatus({ user, onUpdate }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  function toggleConfirmModal() {
    setIsConfirmOpen((isOpen) => !isOpen)
  }

  const changeStatus = useFetch({
    autoFetch: false,
    action: changeUserStatus,
    onComplete: (_, body) => {
      onUpdate?.()
      toggleConfirmModal()
      if (body.status === USER_ARCHIVE_STATUS.archived) {
        window.analytics.track('Archived a user', {
          archived_user_email_id: body?.user_id,
          archived_user_first_name: body?.user?.first_name,
          archived_user_last_name: body?.user?.last_name,
        })
      } else if (body.status !== USER_ARCHIVE_STATUS.unarchive) {
        window.analytics.track('Changed user status', {
          changed_user_id: body?.user_id,
          new_status: body.status,
        })
      }
    },
  })

  function handleStatusChange(id, status) {
    changeStatus.startFetch({ user_id: id, status })
  }

  const loggedUser = useSelector((state) => state?.Account?.user)

  const resend = useFetch({ action: resendInvite })

  const { hasAccess } = usePermissions()

  const hasManageCompanySettings = hasAccess(permissions.manageCompanySettings)

  function toggleStatus() {
    const isActive = user.status === 'active'
    if (isActive) {
      toggleConfirmModal()
    } else {
      handleStatusChange(user?.id, 'active')
    }
  }

  return (
    <>
      {user.status === 'invited' ? (
        hasManageCompanySettings ? (
          <Button
            color='link'
            onClick={() => {
              resend.startFetch({ email: user?.email })
            }}
            disabled={resend.isLoading}
            loading={resend.isLoading}
            className='!tw-px-0'
          >
            Resend Invitation
          </Button>
        ) : (
          <span className='tw-text-surface-100'>Invitation Sent</span>
        )
      ) : (
        <Toggle
          id={`SwitchStatus-${user.id}`}
          check={user.status === 'active'}
          disabled={
            user.id === loggedUser?.id ||
            user?.role === 'Owner' ||
            !hasManageCompanySettings
          }
          change={toggleStatus}
          marginRight={null}
        />
      )}

      <DeactivateConfirmModal
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        user={user}
        onConfirm={() => handleStatusChange(user?.id, 'inactive')}
        loading={changeStatus.isLoading}
        actionName='deactivate'
      />
    </>
  )
}

function DeactivateConfirmModal({
  isOpen,
  toggle,
  user,
  onConfirm,
  loading,
  actionName = 'archive',
}) {
  const fullName = getFullName(user)

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggle}
      title={`${actionName} User`}
      content={
        <>
          {user?.is_approver ? (
            <span>
              {fullName || 'This user'} is part of{' '}
              <span className='tw-font-bold'>approval workflows</span>. By
              disabling or archiving the user, they will be automatically
              removed from the flows. Are you sure to want to disable this?
            </span>
          ) : (
            <>
              <span className='tw-block tw-text-sm'>
                Are you sure you want to {actionName}{' '}
                <span className={cx(!!fullName && 'tw-font-bold')}>
                  {fullName || 'this user'}
                </span>
                ?
              </span>

              <span className='tw-mt-3 tw-block tw-text-sm'>
                <span className='tw-font-bold'>Note:</span> This action will
                also automatically remove the user from any{' '}
                <span className='tw-font-bold'>approval workflows</span> they
                are currently part of.
              </span>
            </>
          )}
        </>
      }
      confirmLoading={loading}
      onConfirm={onConfirm}
      caption={`${actionName} User`}
      buttonColor='danger'
    />
  )
}
