import { yupResolver } from '@hookform/resolvers/yup'
import React, { forwardRef, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import ControlledInput from '../../../../components/ControlledInput'

import classnames from 'classnames'
import { PlusCircle, Trash } from '@phosphor-icons/react'
import { AvForm } from 'availity-reactstrap-validation'

const schema = yup.object().shape({
  allowances: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .min(2, 'Name must be more than 2 chars')
        .required('Allowance Name is required'),
      amount: yup
        .number()
        .min(0, 'Allowance Amount must be at least 0')
        .required('Allowance Amount is required')
        .typeError('Allowance Amount must be a number'),
    }),
  ),
})

const AllowancesForm = forwardRef(function InfoForm(
  { data, onSubmit, amendData },
  ref,
) {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  })
  const {
    fields: allowancesFields,
    append: appendAllowance,
    remove: removeAllowance,
  } = useFieldArray({
    control,
    name: 'allowances',
  })
  useEffect(() => {
    removeAllowance()
    const allowances = amendData?.allowances ?? data?.allowances ?? []
    allowances.forEach((allowance) => {
      appendAllowance(allowance)
    })
  }, [
    appendAllowance,
    amendData?.allowances,
    data?.allowances,
    removeAllowance,
  ])

  const currencyCode = data?.salary_currency?.code

  return (
    <AvForm onSubmit={handleSubmit(onSubmit)} ref={ref}>
      <div>
        {allowancesFields?.map((item, index) => (
          <div
            className='tw-flex tw-w-full tw-flex-col tw-items-stretch sm:tw-flex-row sm:tw-items-start sm:tw-gap-6'
            key={item.id}
          >
            <FormGroup className='tw-grow'>
              <Label>
                Name <span className='tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name={`allowances.${index}.name`}
                error={errors?.allowances?.[index]?.name}
                placeholder='Name'
              />
            </FormGroup>
            <FormGroup className='tw-flex tw-grow'>
              <div className='tw-flex tw-grow tw-flex-col'>
                <Label>
                  Gross amount <span className='tw-text-red'>*</span>
                </Label>
                <div className='tw-flex tw-grow'>
                  <ControlledInput
                    control={control}
                    type='number'
                    showError={false}
                    className={classnames(
                      '!tw-rounded-br-none !tw-rounded-tr-none',
                      {
                        '!tw-border-red !tw-border-r-surface-30':
                          errors?.allowances?.[index]?.amount?.message,
                      },
                    )}
                    wrapperClassName='tw-grow'
                    name={`allowances.${index}.amount`}
                    placeholder='Gross amount'
                  />
                  <span
                    className={classnames(
                      'tw-flex tw-items-center tw-justify-center tw-rounded-br tw-rounded-tr tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-sm tw-text-secondary-70',
                      {
                        '!tw-border-red !tw-border-l-surface-30':
                          errors?.allowances?.[index]?.amount?.message,
                      },
                    )}
                  >
                    {currencyCode}
                  </span>
                  <button
                    className='tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-border tw-border-surface-30 tw-p-4 sm:tw-ml-6 sm:tw-h-10 sm:tw-w-10 sm:tw-rounded-full sm:tw-p-0'
                    onClick={() => {
                      removeAllowance(index)
                    }}
                  >
                    <Trash size={20} color='red' />
                  </button>
                </div>
                {errors?.allowances?.[index]?.amount && (
                  <span className='tw-mt-3 tw-text-xs tw-text-red'>
                    {errors?.allowances?.[index]?.amount?.message}
                  </span>
                )}
              </div>
            </FormGroup>
          </div>
        ))}
        <Row>
          <Col>
            <button
              className='tw-mb-4 tw-mt-6 tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-sm tw-font-semibold tw-text-primary'
              type='button'
              onClick={() => {
                appendAllowance({ name: '', amount: '' })
              }}
            >
              <PlusCircle size={24} />
              Add Monthly Allowance
            </button>
          </Col>
        </Row>
      </div>
    </AvForm>
  )
})

export default AllowancesForm
