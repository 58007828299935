import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'

import { StyledH5 } from '../../../components/Typo'
import Footer from '../../../components/VerticalLayout/Footer'
import { HeaderAnonym } from '../../../components/ui/header-anonym'
import { updateAdminToken } from '../../../store/auth/login/actions'
import { updateUserAdmin } from '../../../store/auth/register/actions'
import googleGLogo from './../../../assets/images/Google__G__Logo.svg'

const LoginAdmin = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token')
  useEffect(() => {
    if (token) {
      dispatch(updateAdminToken(token))
      history.push('/admin/home')
    }
  }, [token])

  return (
    <React.Fragment>
      <HeaderAnonym />

      <Row className='justify-content-center mt-4 mt-md-5 mb-3 mb-md-4 p-0 m-0'>
        <StyledH5 min='22px' max='32px' className='mb-0'>
          Admin panel
        </StyledH5>
      </Row>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='fas fa-home h2'></i>
        </Link>
      </div>
      <div className='account-pages mt-5 p-0 m-0'>
        <Container className='p-3 p-md-0'>
          <Row className='justify-content-center p-0 m-0'>
            <Col md='8' lg='6' xl='5' className='p-0 m-0'>
              <Card
                className='overflow-hidden'
                style={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 1px 0px #DFE1E6',
                  borderRadius: 4,
                }}
              >
                <div className='p-3 p-md-4'>
                  <AvForm
                    autocomplete='off'
                    className='form-horizontal'
                    onValidSubmit={(e, v) => {
                      if (v.password === '_/qX%Q2*') {
                        dispatch(updateUserAdmin())
                        props.history.push('/private-zone')
                      }
                    }}
                  >
                    <div className='form-group row mb-0'>
                      <Col xs='12'>
                        <a
                          className='btn btn-block btn-outline-light rp-font-bold text-dark text-center google-btn border border-light rounded'
                          href={`${process.env.REACT_APP_API_HOST}/saml2/google/login`}
                        >
                          <img
                            className='mr-3'
                            src={googleGLogo}
                            style={{
                              height: 16,
                              display: 'inline',
                            }}
                            alt=''
                          />
                          Continue with Google
                        </a>
                      </Col>
                    </div>
                  </AvForm>
                </div>
              </Card>

              <Footer className='tw-mb-4 tw-mt-12 tw-text-center' />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LoginAdmin
