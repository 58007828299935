import React, { useState } from 'react'
import { Card, Col, Row } from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../../../helpers/hooks'
import {
  checkAdminReportStatus,
  exportContractorBalancesAdmin,
  getContractorBalancesAdmin,
} from '../../../../services/api'
import BalanceAdjustmentModal from './balance-adjustment-modal'
import BalancesList from './balances-list'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import openFileV2 from '../../../../utils/file/open-v2'

const adjustmentStatuses = [
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
]
let interval

export default function BalancesContractors() {
  const [downloading, setDownloading] = useState(false)

  const [filters, setFilters] = useState({
    status: adjustmentStatuses[0].value,
    page: 1,
  })

  const {
    data: balances,
    paginator: balancesPaginator,
    startFetch: refreshContractorsBalances,
    isLoading: gettingBalances,
  } = useFetch(
    {
      action: getContractorBalancesAdmin,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        status: filters?.status,
        page: filters.page,
        ...(filters?.search && filters?.search.length > 0
          ? { search: filters.search }
          : null),
      },
      onError: () => {
        toastr.error('Something went wrong. Please try again later.')
      },
    },
    [filters],
  )

  const { startFetch: checkStatus } = useFetch({
    action: checkAdminReportStatus,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data.url) {
        openFileV2(data.url, { isDataUrl: true, download: true })
        clearInterval(interval)
        setDownloading(false)
      }
    },
    onError: (err) => {
      clearInterval(interval)
      setDownloading(false)
      toastr.error(err)
    },
  })

  const { startFetch: exportBalances } = useFetch({
    action: exportContractorBalancesAdmin,
    withAdminAccess: true,
    body: { status: filters.status },
    onComplete: (data) => {
      if (data.id) {
        interval = setInterval(() => {
          checkStatus({ id: data?.id })
        }, 1000 * 5)
      } else {
        const name = `balance-contractors.xlsx`
        openFileV2(data, { download: true, name })
        setDownloading(false)
      }
    },
  })

  function refreshBalances() {
    refreshContractorsBalances(filters)
  }

  function handleChange(value, name) {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between align-items-center tw-flex-wrap'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>Balance Contractors</h1>

        <span>
          <BalanceAdjustmentModal refreshData={refreshBalances} />
          <Button
            className='tw-ml-4'
            onClick={() => {
              exportBalances()
              setDownloading(true)
            }}
            loading={downloading}
            disabled={downloading}
          >
            Export balances
          </Button>
        </span>
      </div>

      <Card className='p-3'>
        <BalanceFilters filters={filters} changeFilters={handleChange} />

        <BalancesList
          balances={balances}
          paginator={balancesPaginator}
          loading={gettingBalances}
          refreshData={refreshBalances}
          page={filters.page}
          setPage={(page) => handleChange(page, 'page')}
        />
      </Card>
    </div>
  )
}

function BalanceFilters({ filters, changeFilters }) {
  return (
    <Row>
      <Col md={4} xs={12}>
        <CustomSelect
          value={adjustmentStatuses.find(
            (status) => status.value === filters.status,
          )}
          options={adjustmentStatuses}
          onChange={(option) => changeFilters(option?.value, 'status')}
        />
      </Col>
      <Col md={8} xs={12}>
        <SearchBar
          query={filters?.search}
          placeholder='Search by email'
          onQueryChanged={(e) => changeFilters(e, 'search')}
          className='mr-md-2'
        />
      </Col>
    </Row>
  )
}
