import React from 'react'
import { Alert } from 'reactstrap'

import triggerCsChat from '../../utils/trigger-cs-chat'

const stepMinHeight = '60vh'
export default function TransactionError({
  errorText,
  minHeight = stepMinHeight,
  genericErrorText = 'An error occurred',
}) {
  return (
    <div className='d-flex flex-column' style={{ minHeight }}>
      <div className='p-4 flex-grow-1 d-flex flex-column align-items-center justify-content-center'>
        <h3 className='h2' style={{ color: 'var(--gray-h)' }}>
          Whoops!
        </h3>
        <p className='text-muted font-size-14'>{genericErrorText}</p>
        <button
          className='rp-btn-nostyle d-flex align-items-center text-primary font-weight-bolder'
          style={{ gap: '0.25rem' }}
          onClick={() => {
            triggerCsChat(`I have an error while withdrawing my funds.\n`)
          }}
        >
          <span>Contact support</span>{' '}
          <i className='bx bx-chevron-right font-size-22 mr-n2' />
        </button>
      </div>
      <hr className='w-100 mb-0' />
      <Alert
        color='danger'
        className='py-3 m-4 d-flex align-items-center text-danger'
        style={{ gap: '1rem' }}
      >
        <i className='bx bx-info-circle font-size-18' />
        <span>{errorText}</span>
      </Alert>
    </div>
  )
}
