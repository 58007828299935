import { CheckCircle, FilePlus, X, XCircle } from '@phosphor-icons/react'
import React, { useEffect, useMemo, useState } from 'react'
import { Spinner } from 'reactstrap'

import { v4 as uuid } from 'uuid'

import { cn } from 'ui'
import { useFetch } from '../../helpers/hooks'
import { uploadTempFile } from '../../services/api'
import DropzoneInput from '../../components/Common/dropzone-input'
import IconButton from '../../components/ui/icon-button'
import toastr from 'toastr'
export default function MultiFileUpload({
  name,
  setValue,
  clearErrors,
  accept,
  isOptional,
  filesType,
}) {
  const [filesToUpload, setFilesToUpload] = useState([])
  const [files, setFiles] = useState([])
  const hasError = useMemo(() => files.some((file) => file.error), [files])
  const { startFetch: uploadFile } = useFetch(
    {
      action: uploadTempFile,
      onComplete: (data, body) => {
        setFilesToUpload((prevFiles) => prevFiles.slice(1))
        setFiles((prevFiles) => [
          ...prevFiles.map((file) =>
            file.id === body.id ? { ...file, path: data.path } : file,
          ),
        ])
      },
      onError: (error, _, body) => {
        setFilesToUpload((prevFiles) => prevFiles.slice(1))
        setFiles((prevFiles) => [
          ...prevFiles.map((file) =>
            file.id === body.id ? { ...file, error } : file,
          ),
        ])
        toastr.error(error)
      },
    },
    [],
  )

  useEffect(() => {
    if (filesToUpload.length) {
      uploadFile({
        file: filesToUpload[0].file,
        type: filesType,
        id: filesToUpload[0].id,
      })
    }
  }, [filesToUpload])

  useEffect(() => {
    const uploadedFiles = files.map((file) => file.path).filter(Boolean)
    if (!hasError) {
      setValue(name, uploadedFiles.length ? uploadedFiles : [])
    } else {
      setValue(name, [])
    }
  }, [files, name, setValue, hasError])

  function handleFileDropped(acceptedFiles) {
    clearErrors?.()
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      id: uuid(),
    }))
    setFilesToUpload((prevFilesToUpload) => [...prevFilesToUpload, ...newFiles])
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
  }

  function handleFileDelete(e, id) {
    e.stopPropagation()
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id))
  }
  return (
    <>
      {files.map((file) => (
        <div
          className={cn(
            'tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-p-6',
            {
              'tw-border-systemRed-30 tw-bg-systemRed-10': file.error,
              'tw-bg-systemGreen-10': file.path,
            },
          )}
          key={file.id}
        >
          <div>
            <div className='tw-my-1 tw-text-sm tw-font-bold'>{file.name}</div>
            <div>
              {file.path ? (
                <div className='tw-flex tw-items-center tw-gap-1 tw-text-systemGreen'>
                  <CheckCircle size={16} />
                  Uploaded successfully
                </div>
              ) : file.error ? (
                <div className='tw-flex tw-items-center tw-gap-1 tw-text-systemRed'>
                  <XCircle size={16} />
                  Error Uploading
                </div>
              ) : (
                <div className='tw-flex tw-items-center tw-gap-1 tw-text-primary'>
                  <Spinner
                    size='sm'
                    className='tw-animate-spin !tw-border tw-transition-transform tw-duration-300'
                  />
                  Uploading
                </div>
              )}
            </div>
          </div>
          <div>
            <div className='tw-flex tw-items-center tw-gap-2'>
              <IconButton
                onClick={(e) => handleFileDelete(e, file.id)}
                circle
                color='transparent'
                icon={<X size={20} />}
              />
            </div>
          </div>
        </div>
      ))}
      <DropzoneInput
        className='tw-p-6'
        accept={accept ?? { 'application/pdf': ['.pdf'] }}
        onDropAccepted={handleFileDropped}
        multiple
      >
        <div className='tw-text-base tw-font-medium tw-text-black'>
          Additional Documents
        </div>
        {isOptional ? <div className='tw-text-text-80'>Optional</div> : null}
        <div className='tw-mt-6 tw-flex tw-items-center tw-gap-1 tw-font-bold'>
          <FilePlus size={20} /> <span>Drop files here or click to upload</span>
        </div>
        <div className='tw-mt-2 tw-text-xs tw-text-text-80'>
          *PDF only. Max file size 2MB
        </div>
      </DropzoneInput>
    </>
  )
}
