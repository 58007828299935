import { ListChecks } from '@phosphor-icons/react'
import React from 'react'

import Button from '../../../components/ui/button'

export function BgCheckBanner({ onDismiss }) {
  return (
    <div className='tw-flex tw-min-h-[--top-section-height] tw-shrink-0 tw-flex-col tw-gap-4 tw-rounded tw-bg-white tw-p-6 md:tw-flex-row md:tw-items-center'>
      <ListChecks
        size={64}
        className='tw-shrink-0 tw-rounded-full tw-bg-primary-20 tw-p-4 tw-text-primary-100'
      />

      <div>
        <h3 className='tw-mb-1 tw-text-2xl tw-font-bold tw-text-secondary-130'>
          Request multiple background checks!
        </h3>
        <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
          Select the workers for which you want to request a background check
          and click on Request Background check to proceed.
        </p>
      </div>

      <Button
        outline
        color='light'
        className='!tw-px-6 !tw-py-4 !tw-text-sm max-md:tw-mr-auto md:tw-ml-auto'
        type='button'
        onClick={onDismiss}
      >
        Dismiss
      </Button>
    </div>
  )
}
