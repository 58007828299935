import { Info } from '@phosphor-icons/react'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { cn } from 'ui'

export default function LabelContent({
  children,
  required,
  tooltipText,
  tooltipId,
  className,
  showOptionalLabelText = false,
}) {
  const showTooltip = tooltipText && tooltipId

  return (
    <div className={cn('tw-flex tw-items-center', className)}>
      {children}
      {!required && showOptionalLabelText ? ' (Optional)' : null}
      {!required ? null : (
        <span
          className='text-danger font-size-16'
          style={{ lineHeight: 1, marginLeft: '0.125rem' }}
        >
          *
        </span>
      )}

      {!showTooltip ? null : (
        <>
          <Info size={16} id={tooltipId} className='ml-2' />

          <UncontrolledTooltip placement='top' target={tooltipId}>
            {tooltipText}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}
