import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { pull, filter, includes, map } from 'lodash'
import { XCircle, PlusCircle } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'

export function FilterButtons(props) {
  const { name, filters } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [search, setSearch] = useState('')
  const getLabel = useCallback(
    (value) => {
      const label = filters.all.find((a) => a.filter === name)?.title
      const selectedTexts = map(
        filter(filters[name], (i) => includes(value, i.value)),
        'title',
      )
      return label + (value?.length ? ` : ${selectedTexts}` : ' : All')
    },
    [filters, name],
  )

  const items = useMemo(() => {
    return (filters?.[name] ?? []).filter((i) =>
      i.title.toLowerCase().includes(search.toLowerCase()),
    )
  }, [filters, name, search])

  if (!items.length) return null
  return (
    <Controller
      name={`filters.${name}`}
      render={({ field: { value = [], onChange } }) => (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen((prevState) => !prevState)}
        >
          <DropdownToggle
            tag={(props) => (
              <Button
                outline={!dropdownOpen}
                color='secondary'
                size='sm'
                iconRight={
                  <button
                    style={{ marginLeft: '-5px' }}
                    onClick={() => onChange([])}
                  >
                    {value?.length ? (
                      <XCircle size={20} weight='fill' />
                    ) : (
                      <PlusCircle size={20} weight='fill' />
                    )}
                  </button>
                }
                {...props}
              />
            )}
          >
            {getLabel(value)}
          </DropdownToggle>
          <DropdownMenu className='tw-max-h-[300px] tw-w-[250px] tw-overflow-auto'>
            <div className='tw-flex tw-flex-col tw-gap-2 tw-px-2 tw-py-2'>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Search...'
                id='search-input'
              />
              {items?.map((f, index) => {
                const checked = value?.includes?.(f.value)
                return (
                  <DropdownItem
                    onClick={() =>
                      onChange(
                        checked ? pull(value, f.value) : [...value, f.value],
                      )
                    }
                    key={`${f.title}-${index}`}
                  >
                    <Input defaultChecked={checked} type='checkbox' />
                    {f.title}
                  </DropdownItem>
                )
              })}
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    />
  )
}
