import {
  QB_SUBMIT_ACCOUNT_MAPPING,
  QB_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  QB_UPDATE_CONTRACTOR,
  QB_UPDATE_PRE_DATA,
  QB_UPDATE_REQUEST,
  QB_INIT,
} from './actionTypes'

const initialState = {
  preData: null,
  accountMapping: {},
  contractors: [],
  request: null,
}

function qb(state = initialState, action) {
  switch (action.type) {
    case QB_UPDATE_PRE_DATA: {
      return {
        ...state,
        preData: action.payload,
        contractors: action.payload.contractors,
      }
    }
    case QB_INIT: {
      return initialState
    }
    case QB_SUBMIT_ACCOUNT_MAPPING: {
      return {
        ...state,
        accountMapping: action.payload,
      }
    }
    case QB_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST: {
      const categories = Object.keys(action.payload).map((e) => ({
        rp: e,
        qb: action.payload[e],
      }))

      return {
        ...state,
        accountMapping: action.payload,
        request: { categories },
      }
    }
    case QB_UPDATE_CONTRACTOR: {
      return {
        ...state,
        contractors: state.contractors.map((e) =>
          e.contractor_id === action.payload.contractor_id ? action.payload : e,
        ),
      }
    }
    case QB_UPDATE_REQUEST: {
      if (!action.payload) {
        return {
          ...state,
          request: null,
        }
      }

      const contractors = state?.contractors.map((item) => {
        const object = { rp: item?.contractor_id }

        if (item.categories) {
          if (Array.isArray(item.categories)) {
            object.categories = item.categories.map((k) => ({
              rp: k?.category_id,
              qb: k?.qb_id,
            }))
          } else if (typeof item.categories === 'object') {
            object.categories = Object.keys(item.categories).map((k) => ({
              rp: k,
              qb: item.categories[k],
            }))
          }
        }

        if (item?.xero?.value || item?.xero_id || item?.qb_id) {
          object.xero = item?.xero?.value || item?.xero_id
          object.qb = item?.xero?.value || item?.qb_id
        }
        return object
      })

      if (action.payload?.isUpdate) {
        return {
          ...state,
          request: {
            ...action.payload,
            contractors,
          },
        }
      } else {
        const categories = Object.keys(state?.accountMapping).map((e) => ({
          rp: e,
          qb: state.accountMapping[e],
        }))

        return {
          ...state,
          request: {
            ...action.payload,
            categories,
            contractors,
          },
        }
      }
    }

    default: {
      return state
    }
  }
}

export default qb
