export const selectFieldPadding = '0 0.125rem'
const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--text-40)',
    padding: selectFieldPadding,
  }),

  singleValue: (provided, state) => {
    const color = state.isDisabled ? 'var(--text-70)' : 'var(--text-100)'

    return { ...provided, color, padding: selectFieldPadding }
  },

  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? 'white' : isDisabled ? '#777F9E' : '#0f1035',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  },
  control: (provided, state) => {
    const borderColor = state?.selectProps['aria-invalid']
      ? 'var(--red-100) !important'
      : state.isFocused
        ? '#C6C8E0 !important'
        : '#E7E8F2 !important'

    const bgColor = state.isDisabled
      ? 'var(--surface-30)'
      : provided?.backgroundColor

    return {
      ...provided,
      borderColor,
      '&:hover': { borderColor },
      boxShadow: state.isFocused
        ? '0 0 0 2px rgba(198, 200, 224, 0.4) !important'
        : '',
      minHeight: 42,
      cursor: 'pointer',
      minWidth: 120,
      backgroundColor: bgColor,
    }
  },

  menu: (provided) => ({ ...provided, zIndex: 10 }),
}

export function getCustomStyles({ hasError, selectStyles }) {
  return {
    ...customStyles,
    control: (provided, state) => {
      const prevProvided = customStyles.control(provided, state)
      const borderColor = hasError
        ? 'var(--red) !important'
        : prevProvided?.borderColor

      return {
        ...prevProvided,
        borderColor,
        ...selectStyles?.control(prevProvided, state),
      }
    },
  }
}

export default customStyles
