import React, { forwardRef } from 'react'

const Toolbar = forwardRef(function Toolbar({ quillInstance, id }, ref) {
  return (
    <div
      ref={ref}
      style={{
        height: 48,
        zIndex: 9,
        display: quillInstance.current ? 'flex' : 'none',
        position: 'absolute !important',
        top: '0 !important',
      }}
      className='border-bottom border-light border-right bg-white align-items-center w-100'
      id={id}
    >
      <span className='ql-formats'>
        <button className='ql-bold'></button>
        <button className='ql-italic'></button>
        <button className='ql-underline'></button>
      </span>
      <span className='ql-formats'>
        <button className='ql-list' value='ordered'></button>
        <button className='ql-indent' value='-1'></button>
        <button className='ql-indent' value='+1'></button>
      </span>
      <span className='ql-formats'>
        <button className='ql-link'></button>
      </span>
    </div>
  )
})

export default Toolbar
