import React from 'react'
import { cn } from 'ui'

export function SimpleCard({ className, children }) {
  return (
    <div className={cn('tw-rounded tw-bg-white tw-shadow', className)}>
      {children}
    </div>
  )
}
