import { sub } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import { cn } from 'ui'
import { ControlledNativeSelect } from '../../components/controlled-native-select/controlled-native-select'
import ControlledDatePicker from '../../components/ControlledDatePicker'
import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Shimmer from '../../components/ui/shimmer'
import { BE_CONTRACT_CATEGORY, CONTRACT_STATUS } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { getBankFields, getContractList } from '../../services/api'
import { isProduction } from '../../utils/isProduction'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import { Messages } from './BankAccounts'

export const BANK_ACCOUNT_FORM_FIELDS = 'bank_account_form_fields'

export function getFieldValueFromData(values, key) {
  const keyParts = key.split('.')

  if (keyParts.length > 1) {
    // We only have two layers, we can expand to more if we need to
    return values[keyParts[0]][keyParts[1]]
  } else if (keyParts.length === 1) {
    return values[key]
  } else {
    return null
  }
}

export function AddBankAccountV2Form({
  currency,
  currencies,
  control,
  isEmployee,
  className,
  fieldsClassName,
  isBillVendor = false,
}) {
  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const { data: contractList } = useFetch({
    action: getContractList,
    body: {
      status: [
        CONTRACT_STATUS.PENDING_INVITE.value,
        CONTRACT_STATUS.PENDING_CONTRACTOR_SIGNATURE.value,
        CONTRACT_STATUS.PENDING_CLIENT_SIGNATURE.value,
        CONTRACT_STATUS.ONGOING.value,
      ],
    },
    autoFetch: isDeEmployee,
  })

  const contractCurrencies = useMemo(
    () => [...new Set(contractList?.map((c) => c?.currency?.code))],
    [contractList],
  )

  const { currency: selectedCurrency, type } = useWatch({ control })
  const { setValue, watch } = useFormContext()

  const defaultBody = useMemo(() => {
    return {
      source: currency,
      target: selectedCurrency,
      targetAmount: 300, // arbitrary value
      is_bill_vendor: isBillVendor,
    }
  }, [currency, selectedCurrency, isBillVendor])

  const {
    data: fields,
    setData: setFields,
    startFetch: getFields,
    isLoading: isLoadingFields,
  } = useFetch(
    {
      action: getBankFields,
      autoFetch: !!currency && !!selectedCurrency,
      body: defaultBody,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(
            data?.message || 'An error occurred while fetching bank fields',
          )
          return
        }

        let newData = data

        const newDataTypes = Object.values(newData).map((e) => e.type)
        const isInNewTypes = newDataTypes.includes(type)
        if (newDataTypes.length <= 1 || !isInNewTypes) {
          setValue('type', newDataTypes[0])
        }

        // // Convert data format from `[{}, {}]` to `{key: {}, key2: {}}`
        newData = !Array.isArray(newData)
          ? {}
          : newData.reduce((prev, curr) => {
              return { ...prev, [curr?.type]: curr }
            }, {})

        setFields(newData)
      },
    },
    [currency, selectedCurrency],
  )

  const recipientTypeOptions =
    !fields || Object.keys(fields).length <= 1
      ? []
      : [
          { label: 'Select', value: null, disabled: true, selected: true },
          ...Object.values(fields).map(({ title, type }) => ({
            label: title,
            value: type,
          })),
        ]

  const showUsMessage = selectedCurrency === 'USD' && type === 'swift_code'

  const formFields = useMemo(() => {
    return fields?.[type]?.fields
  }, [fields, type])

  useEffect(() => {
    const subscription = watch((_, { name, values }) => {
      const foundField = formFields?.find((field) => field.key === name)
      if (foundField?.refreshRequirementsOnChange) {
        const details = Object.fromEntries(
          formFields.map((field) => {
            return [field.key, getFieldValueFromData(values, field.key)]
          }),
        )

        const body = { ...defaultBody, details }

        getFields(body)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [defaultBody, formFields, getFields, watch])

  useEffect(() => {
    // Update bank account keys when we have new form fields.
    // This could be used by the calling form to determine which fields are specific
    // to the bank account creation.
    // e.g to create a new bank account
    // e.g to remove the bank account fields from the other form data, if this form is embedded in another form
    setValue(BANK_ACCOUNT_FORM_FIELDS, formFields)
  }, [formFields, setValue])

  return (
    <div className={className}>
      <Messages
        showUsMessage={showUsMessage}
        className='tw-mb-4'
        showBillsAlert={isBillVendor}
      />

      <fieldset className='tw-contents' disabled={isLoadingFields}>
        <div
          className={cn(
            'tw-grid tw-gap-x-6 tw-gap-y-4 sm:tw-grid-cols-2',
            fieldsClassName,
          )}
        >
          <ControlledSelect
            control={control}
            name='currency'
            inputId='currency'
            label='Select the desired currency'
            placeholder='Select the desired currency'
            options={
              isDeEmployee
                ? currencies
                    ?.filter((currency) =>
                      contractCurrencies.includes(currency.code),
                    )
                    .map((c) => mapCurrencyToOption(c))
                : currencies?.map((c) => mapCurrencyToOption(c))
            }
            isDisabled={isEmployee}
          />

          {recipientTypeOptions?.length <= 0 ? null : (
            <ControlledNativeSelect
              control={control}
              name='type'
              id='type'
              label='Type'
              options={recipientTypeOptions}
            />
          )}

          {(!fields || fields.length <= 0) && isLoadingFields
            ? Array.from({ length: 5 }).map((_, index) => {
                return (
                  <div key={index}>
                    <Shimmer height='21px' className='tw-mb-2' />
                    <Shimmer width='100%' />
                  </div>
                )
              })
            : null}

          {!fields || !type || formFields?.length <= 0
            ? null
            : formFields.map((field) => {
                switch (field.type) {
                  case 'text': {
                    return (
                      <ControlledInput
                        control={control}
                        name={field.key}
                        id={field.key}
                        placeholder={field.name}
                        label={field.name}
                        maxLength={field?.maxLength}
                        minLength={field?.minLength}
                        required={field?.required}
                      />
                    )
                  }
                  case 'select':
                  case 'radio': {
                    return (
                      <ControlledNativeSelect
                        control={control}
                        name={field.key}
                        id={field.key}
                        label={field.name}
                        placeholder={`Select ${field.name}`}
                        options={[
                          {
                            label: `Select ${field.name}`,
                            value: null,
                            disabled: true,
                            selected: true,
                          },
                          ...(field.valuesAllowed?.map(({ key, name }) => {
                            return { label: name, value: key }
                          }) ?? []),
                        ]}
                        required={field?.required}
                      />
                    )
                  }
                  case 'date': {
                    return (
                      <ControlledDatePicker
                        control={control}
                        name={field.key}
                        id={field.key}
                        label={field.name}
                        placeholder={field.name}
                        required={field?.required}
                        minDate={sub(new Date(), { years: 118 })}
                        maxDate={sub(new Date(), { years: 18 })}
                      />
                    )
                  }
                  default: {
                    return !isProduction() ? (
                      <div>
                        <b>{field.type}</b> type is not supported.
                      </div>
                    ) : null
                  }
                }
              })}
        </div>
      </fieldset>
    </div>
  )
}
