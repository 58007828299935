import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../components/ui/button'
import ModalBase from './modal-base'

export default function PreConditionsModal({
  isOpen,
  toggle,
  conditions = [],
}) {
  const modalProps = { isOpen, toggle, heading: 'Not Activated' }

  return (
    <ModalBase
      {...modalProps}
      actions={
        <>
          <Button type='button' outline onClick={toggle}>
            Got it
          </Button>
          {/* <Button type='button'>Redeem</Button> */}
        </>
      }
    >
      {conditions?.length <= 0 ? null : (
        <>
          <p className='text-muted'>
            To activate the perks, you need to meet the following conditions:
          </p>
          <ul className='pl-0' style={{ listStyle: 'none' }}>
            {conditions.map((condition) => {
              return <Condition condition={condition} key={condition.title} />
            })}
          </ul>
        </>
      )}
    </ModalBase>
  )
}

function Condition({ condition }) {
  const isCompanyVerificationFalse =
    condition.title === 'Be a verified company.' && !condition.result
  return (
    <li
      className='h5 text-gray-h d-flex align-items-center'
      key={condition}
      style={{ gap: '0.5rem' }}
    >
      {condition.result ? (
        <i className='bx bx-check text-success font-size-28' />
      ) : (
        <i className='bx bx-x text-muted font-size-28' />
      )}
      <span>
        {condition.title}{' '}
        {isCompanyVerificationFalse ? (
          <Link to='/registration-document' style={{ fontSize: 'smaller' }}>
            Verify now
          </Link>
        ) : null}
      </span>
    </li>
  )
}
