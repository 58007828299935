import { useState } from 'react'

export function useFilters(defaultValues, options = {}) {
  const { statusKey = 'kyc_request_status' } = options
  const [filters, setFilters] = useState(defaultValues)

  function handleFiltersChange(key, value, options = {}) {
    const { action } = options

    setFilters((f) => {
      const changes = {}

      const isPageChange = key === 'page'
      if (isPageChange) {
        changes.page = value
      } else {
        if (key === statusKey) {
          changes.page = 1
        } else {
          const isClearingFilter = action === 'clear'
          changes.page = isClearingFilter ? 1 : f.page
        }
        changes[key] = value
      }

      return { ...f, ...changes }
    })
  }

  return [filters, handleFiltersChange]
}
