import { UPDATE_PAGE_INFO } from './action-types'

const initialState = {
  permissions: null,
}

export default function page(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE_INFO: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      break
    }
  }

  return state
}
