import React, { Children, useId } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

export function WithTooltip({ children, tooltipText, showTooltip = true }) {
  const genId = useId()

  if (!showTooltip || !tooltipText) {
    return children
  }

  // Remove first and last character from generated id
  // React generates ids with colon at the beginning and end
  // Example: ":generated-id-1:"
  // We need to remove those to use it as target for tooltip
  const cleanId = genId.slice(1, genId.length - 1)
  const id = `rp-with-tooltip-id-${cleanId}`

  return (
    <>
      <ChildWithId id={id}>{children}</ChildWithId>

      <UncontrolledTooltip placement='top' target={id}>
        {tooltipText}
      </UncontrolledTooltip>
    </>
  )
}

function ChildWithId({ children, id }) {
  if (typeof children === 'string') {
    return <span id={id}>{children}</span>
  }

  if (Children.only(children) && !children?.props?.id) {
    return React.cloneElement(children, { id })
  }

  return <span id={id}>{children}</span>
}
