import React from 'react'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom'

import logoMain from '../../assets/images/identity/logo_main.png'

function InvoiceDetail() {
  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <Col lg='12'>
            <Card>
              <CardBody>
                <div className='invoice-title pb-3'>
                  <h4 className='float-right font-size-16'>Invoice # 12345</h4>
                </div>
                <hr />
                <Row>
                  <Col xs='6'>
                    <address>
                      <strong>Billed To:</strong>
                      <br />
                      John Smith
                      <br />
                      1234 Main
                      <br />
                      Apt. 4B
                      <br />
                      Springfield, ST 54321
                    </address>
                  </Col>
                  <Col xs='6' className='text-right'>
                    <address>
                      <strong>Billed from:</strong>
                      <br />
                      Kenny Rigdon
                      <br />
                      1234 Main
                      <br />
                      Apt. 4B
                      <br />
                      Springfield, ST 54321
                    </address>
                  </Col>
                </Row>
                <Row>
                  <Col xs='6' className='mt-3'>
                    <address>
                      <strong>Paid on:</strong>
                      <br />
                      October 16, 2019
                    </address>
                  </Col>
                  <Col xs='6' className='mt-3 text-right'>
                    <address>
                      <strong>Invoice Date:</strong>
                      <br />
                      October 16, 2019
                      <br />
                      <br />
                    </address>
                  </Col>
                </Row>
                <div className='py-2 mt-3'>
                  <h3 className='font-size-15 rp-font-bold'>Invoice summary</h3>
                </div>
                <div className='table-responsive'>
                  <Table className='table-nowrap text-muted'>
                    <thead>
                      <tr>
                        <th style={{ width: '70px' }}>No.</th>
                        <th>Item</th>
                        <th className='text-right'>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>01</td>
                        <td>FIXED Contract</td>
                        <td className='text-right'>$499.00</td>
                      </tr>

                      <tr>
                        <td>02</td>
                        <td>Milestone Contract</td>
                        <td className='text-right'>$399.00</td>
                      </tr>

                      <tr>
                        <td>03</td>
                        <td>Pay as you go</td>
                        <td className='text-right'>$499.00</td>
                      </tr>
                      <tr>
                        <td colSpan='2' className='text-right'>
                          Sub Total
                        </td>
                        <td className='text-right'>$1397.00</td>
                      </tr>
                      <tr>
                        <td colSpan='2' className='border-0 text-right'>
                          <strong>Shipping</strong>
                        </td>
                        <td className='border-0 text-right'>$13.00</td>
                      </tr>
                      <tr>
                        <td colSpan='2' className='border-0 text-right'>
                          <strong>Total</strong>
                        </td>
                        <td className='border-0 text-right'>
                          <h4 className='m-0'>$1410.00</h4>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Row className='justify-content-between'>
                  <Col>
                    <h6 className='text-secondary font-size-10'>
                      Generated via
                    </h6>
                    <img
                      className='mr-2'
                      alt='RemotePass'
                      width='100'
                      src={logoMain}
                    />
                  </Col>
                  <div className='d-print-none'>
                    <div className='float-right'>
                      {/* <Link to="#" onClick={() => { printInvoice() } } className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link> */}
                      <Link
                        to='#'
                        className='btn btn-primary w-md waves-effect waves-light'
                      >
                        Send
                      </Link>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default InvoiceDetail
