import { Files, ListChecks } from '@phosphor-icons/react'
import React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { EmptyState } from '../../review-center/empty-state'
import { ReviewItemsView } from '../../review-center/review-items-view'
import {
  ReviewLayout,
  defaultFooterHeight,
  getContentMaxHeight,
} from '../../review-center/review-list-layout'
import { ReviewDocumentDetails } from './review-document-details'
import { ReviewDocumentsBulkEdit } from './review-documents-bulk-edit'
import { SearchFilters } from './search-filters'

export function ReviewDocumentsColumns({
  reviewItems = [],
  urlState,
  setUrlState,
  isLoading = false,
  refreshReviewList,

  fetchNextPage,
  isFetchingNextPage,
}) {
  const { selectedItem: selectedId } = urlState

  const activeItem = reviewItems.find(
    (item) => String(item.id) === String(selectedId),
  )?.raw

  const filters = {
    page: Number(urlState.page),
    search_key: urlState.search_key,
    contract_type: urlState.contract_type,
  }

  const noFilters = Object.entries(filters)
    .filter(([key]) => key !== 'page')
    .every(([, value]) => !value)

  const isEmpty =
    !isLoading && (reviewItems?.length === 0 || !reviewItems) && noFilters

  const methods = useForm()
  const { control, reset } = methods

  const isSelecting = urlState?.selecting === '1'
  function toggleSelecting() {
    const isSelecting = urlState?.selecting === '1'

    if (isSelecting) {
      reset()
    }

    setUrlState({ selecting: isSelecting ? undefined : '1' })
  }

  const selectedItems = useWatch({ control, name: 'selectedItems' })?.filter(
    Boolean,
  )

  const contextValue = {
    isSelecting,
    toggleSelecting,
    activeItem,
    setUrlState,
    reviewItems,
    refreshReviewList,
  }

  const detailsMaxHeight = getContentMaxHeight({
    footerHeight: defaultFooterHeight,
    isSelecting,
    topActionsHeight: 0,
  })

  return (
    <ReviewLayout.Wrapper isSelecting={isSelecting}>
      {isEmpty ? (
        <EmptyState pictureElement={<Files size={120} weight='duotone' />} />
      ) : (
        <reviewDocumentContext.Provider value={contextValue}>
          <FormProvider {...methods}>
            <ReviewLayout.Grid>
              <ReviewLayout.Column colSpan={6} maxHeight={detailsMaxHeight}>
                {isLoading && noFilters ? (
                  <Loader minHeight='100%' />
                ) : (
                  <ReviewDocumentList
                    isLoadingFilters={isLoading && !noFilters}
                    filters={filters}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                )}
              </ReviewLayout.Column>
              <ReviewLayout.Column maxHeight={detailsMaxHeight}>
                {isLoading ? (
                  <Loader minHeight='100%' />
                ) : (
                  <ReviewDocumentDetails />
                )}
              </ReviewLayout.Column>
            </ReviewLayout.Grid>

            <ReviewDocumentsBulkEdit selectedItems={selectedItems} />
          </FormProvider>
        </reviewDocumentContext.Provider>
      )}
    </ReviewLayout.Wrapper>
  )
}

function ReviewDocumentList({
  isLoadingFilters,
  filters,
  fetchNextPage,
  isFetchingNextPage,
}) {
  const { activeItem, setUrlState, isSelecting, toggleSelecting, reviewItems } =
    useReviewContext()

  const isFilteredEmpty = reviewItems.length === 0 && !isLoadingFilters

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-[1] tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-border-b tw-border-surface-30 tw-bg-white tw-p-6 md:tw-flex-row'>
        <SearchFilters filters={filters} setUrlState={setUrlState} />

        <Button
          type='button'
          color={isSelecting ? 'light' : 'primary'}
          outline
          icon={isSelecting ? null : <ListChecks size={16} />}
          onClick={() => toggleSelecting()}
          className='max-md:tw-w-full'
        >
          {isSelecting ? 'Cancel' : 'Request Multiple Checks'}
        </Button>
      </div>

      <div className='tw-flex tw-flex-grow tw-flex-col tw-gap-4 tw-overflow-auto tw-p-6'>
        {isLoadingFilters ? (
          <Loader className='tw-flex-grow' />
        ) : isFilteredEmpty ? (
          <div className='tw-flex tw-flex-grow tw-flex-col tw-items-center tw-justify-center tw-gap-4'>
            <div className='tw-text-primary-100'>
              <Files size={120} weight='duotone' />
            </div>

            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              No items found, please update your search
            </p>
          </div>
        ) : (
          <ReviewItemsView
            items={reviewItems}
            activeItem={activeItem}
            onClickItem={(item) => setUrlState({ selectedItem: item.raw?.id })}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isSelecting={isSelecting}
          />
        )}
      </div>
    </>
  )
}

const reviewDocumentContext = React.createContext({
  isSelecting: false,
  toggleSelecting: () => {},
  activeItem: null,
  setUrlState: () => {},
  reviewItems: [],
  refreshReviewList: () => {},
})

export function useReviewContext() {
  const context = React.useContext(reviewDocumentContext)

  if (!context) {
    throw new Error(
      'useReviewContext must be used within a ReviewContextProvider',
    )
  }

  return context
}
