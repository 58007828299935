import { Container } from 'reactstrap'
import ModalHeader from '../../../../components/ModalHeader'
import React, { Fragment, useCallback, useState } from 'react'
import Head from '../../../../components/head'
import { useHistory, useLocation } from 'react-router-dom'
import { Play, PauseCircle } from '@phosphor-icons/react'
import { Action } from './action'
import { Delay } from './delay'
import { Trigger } from './trigger'
import { Divider } from './divider'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { TitleEditText } from './automation-title-edit-text'
import { yupResolver } from '@hookform/resolvers/yup'
import { QuitConfirmModal } from './quit-confirm-modal'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { createAutomation } from '../../../../services/api-automations'
import toastr from 'toastr'
import {
  defaultValue,
  transformAutomationResponse,
  updateAutomation,
} from './tools'
import { useAutomationDetail } from './use-automation-detail'
import Loader from '../../../../components/ui/loader'
import { PlaceholdersProvider } from './placeholders-context'
import { AutomationFilters } from './automation-filters'
import { schema } from './constants'
import isEmpty from 'lodash/isEmpty'
import { PublishConfirmModal } from './publish-confirm-modal'
import { UnpublishConfirmModal } from './unpublish-confirm-modal'
import { SaveConfirmModal } from './save-confirm-modal'

export function NewAutomationModal() {
  const history = useHistory()
  const [quitModal, setQuitModal] = useState(false)
  const [publishModal, setPublishModal] = useState(false)
  const [unpublishModal, setUnublishModal] = useState(false)
  const [saveModal, setSaveModal] = useState(false)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const { isLoading, data } = useAutomationDetail({ id })
  const goToList = () => history.push('/settings/automations')
  const currentAutomation = id ? transformAutomationResponse(data) : null
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      focusedNode: 'trigger',
      ...(id ? {} : defaultValue),
    },
    values: id ? currentAutomation : undefined,
  })

  const stepsField = useFieldArray({
    control: methods.control,
    name: 'steps',
  })

  const { isLoading: isCreating, startFetch: _createAutomation } = useFetch({
    action: createAutomation,
    isOpenApi: true,
    onComplete: (res, data) => {
      _updateSteps({
        ...data,
        id: res._id,
      })
    },
    onError: (err) => toastr.error(err),
  })
  const { isLoading: iCreatingSteps, startFetch: _updateSteps } = useFetch({
    action: updateAutomation,
    isOpenApi: true,
    onComplete: (d, b) => {
      goToList()
      toastr.success(`${b?.name ?? 'Automation'} is saved`, 'Changes saved')
    },
    onError: (err) => toastr.error(err),
  })
  const isDirty = !isEmpty(methods.formState.dirtyFields)

  const handleSubmit = useCallback(
    (values) => {
      const payload = {
        id,
        ...(currentAutomation ? { ...currentAutomation } : {}),
        isTemplate: false,
        ...values,
      }
      if (!id) {
        _createAutomation(payload)
      } else {
        _updateSteps(payload)
      }
    },
    [_createAutomation, _updateSteps, currentAutomation, id],
  )

  if (isLoading)
    return (
      <Container fluid className='tw-px-0'>
        <Head title='Automation set up' />
        <Loader />
      </Container>
    )

  return (
    <PlaceholdersProvider>
      <FormProvider {...methods}>
        <div>
          <Head title='Automation set up' />
          <ModalHeader noExit>
            <div className='tw-flex tw-flex-row tw-gap-2'>
              <div className='tw-flex tw-flex-1 tw-items-center tw-justify-center'>
                <TitleEditText current={currentAutomation} />
              </div>
              <Button
                onClick={() => (isDirty ? setQuitModal(true) : goToList())}
                outline
                className='!tw-bg-white'
                textClassName='!tw-text-black'
                color='surface-30'
              >
                Quit
              </Button>

              <Button
                outline
                loading={isCreating || iCreatingSteps}
                onClick={() => {
                  if (currentAutomation?.isPublished) {
                    methods.setValue('shouldValidate', true)
                    methods.handleSubmit(() => setSaveModal(true))()
                  } else {
                    methods.setValue('isPublished', false)
                    methods.handleSubmit(handleSubmit)()
                  }
                }}
              >
                Save
              </Button>

              {currentAutomation?.isPublished ? (
                <Button
                  color='danger'
                  onClick={() => setUnublishModal(true)}
                  loading={isCreating || iCreatingSteps}
                  outline
                  iconRight={<PauseCircle size={20} />}
                >
                  Unplublish
                </Button>
              ) : (
                <Button
                  loading={isCreating || iCreatingSteps}
                  onClick={() => {
                    methods.setValue('shouldValidate', true)
                    methods.setValue('isPublished', true)
                    methods.handleSubmit(
                      () => setPublishModal(true),
                      () => {
                        toastr.error(
                          'Automation can’t be published',
                          'Make sure all the required fields are populated',
                        )
                      },
                    )()
                  }}
                  iconRight={<Play size={15} />}
                >
                  Publish
                </Button>
              )}
            </div>
          </ModalHeader>
          <div className='tw-m-4 tw-grid tw-grid-cols-1 tw-gap-2 md:tw-m-7 md:tw-grid-cols-3 md:tw-gap-7'>
            <div />
            <div>
              {stepsField.fields.map((step, index, l) => (
                <Fragment key={step.id}>
                  {step.type === 'trigger' ? (
                    <Trigger {...step} index={index} />
                  ) : (
                    <>
                      <Divider
                        id={`first-${id}`}
                        onClick={({ type }) =>
                          stepsField.insert(index, {
                            type,
                            ...(type === 'action' ? defaultValue.steps[1] : {}),
                          })
                        }
                      />
                      {step.type === 'action' ? (
                        <Action
                          {...step}
                          stepsField={stepsField}
                          index={index}
                        />
                      ) : (
                        <Delay
                          {...step}
                          stepsField={stepsField}
                          index={index}
                        />
                      )}

                      {index === l.length - 1 ? (
                        <Divider
                          id={`second-${id}`}
                          onClick={({ type }) => stepsField.append({ type })}
                          last
                        />
                      ) : null}
                    </>
                  )}
                </Fragment>
              ))}
            </div>

            <div className='tw-order-first md:tw-order-last'>
              <AutomationFilters />
            </div>
          </div>
        </div>
        <QuitConfirmModal
          isOpen={quitModal}
          toggle={() => setQuitModal((prevState) => !prevState)}
        />
        <UnpublishConfirmModal
          onUnpulish={() => {
            methods.setValue('isPublished', false)
            methods.handleSubmit(handleSubmit)()
          }}
          isOpen={unpublishModal}
          toggle={() => setUnublishModal((prevState) => !prevState)}
        />
        <PublishConfirmModal
          onPublish={() => methods.handleSubmit(handleSubmit)()}
          isOpen={publishModal}
          toggle={() => setPublishModal((prevState) => !prevState)}
        />
        <SaveConfirmModal
          onSave={() => methods.handleSubmit(handleSubmit)()}
          isOpen={saveModal}
          toggle={() => setSaveModal((prevState) => !prevState)}
        />
      </FormProvider>
    </PlaceholdersProvider>
  )
}
