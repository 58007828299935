import i18n from 'i18next'
import Detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationENG from './locales/eng/translation.json'
import translationAr from './locales/ar/translation.json'
import translationFR from './locales/fr/translation.json'

// the translations
const resources = {
  ar: { translation: translationAr },
  en: { translation: translationENG },
  fr: { translation: translationFR },
}

i18n
  .use(Detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
