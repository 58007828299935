import cx from 'classnames'
import React from 'react'
import { Spinner } from 'reactstrap'

export default function Loader({
  type = 'grow',
  color = 'primary',
  size = 'md',
  minHeight = '20vh',
  className = '',
  style = {},
}) {
  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center',
        className,
      )}
      style={{ minHeight, ...style }}
    >
      <Spinner type={type} color={color} size={size} />
    </div>
  )
}
