import React from 'react'
import { CardHeader } from 'reactstrap'

export default function TabCardHeader({ title, extra, styles }) {
  return (
    <CardHeader
      className='bg-transparent d-flex justify-content-between align-items-start flex-column flex-md-row gap-8 pt-4'
      style={{ minHeight: 78, ...styles }}
    >
      {!title ? (
        <div />
      ) : (
        <h2 className='font-size-24 mb-0 white-space-nowrap'>{title}</h2>
      )}

      {!extra ? null : extra}
    </CardHeader>
  )
}
