import React from 'react'
import { cn } from 'ui'

export const departmentColors = {
  'light-gray': {
    name: 'Light Gray',
    className: 'tw-text-white tw-bg-surface-100',
  },
  'dark-gray': {
    name: 'Dark Gray',
    className: 'tw-text-white tw-bg-secondary-100',
  },
  'jet-black': { name: 'Jet Black', className: 'tw-text-white tw-bg-text-100' },
  'bright-yellow': {
    name: 'Bright Yellow',
    className: 'tw-text-white tw-bg-systemGold-100',
  },
  'crimson-red': {
    name: 'Crimson Red',
    className: 'tw-text-white tw-bg-systemRed-100',
  },
  'forest-green': {
    name: 'Forest Green',
    className: 'tw-text-white tw-bg-systemGreen-100',
  },
  // #21cc51 should be assetsGreen in tw config
  'lime-green': {
    name: 'Lime Green',
    className: 'tw-text-white tw-bg-[#21cc51]',
  },
  // #01d5e2 should be assetsCyan in tw config
  'sky-blue': { name: 'Sky Blue', className: 'tw-text-white tw-bg-[#01d5e2]' },
  'ocean-blue': {
    name: 'Ocean Blue',
    className: 'tw-text-white tw-bg-systemBlue-100',
  },
  'royal-blue': {
    name: 'Royal Blue',
    className: 'tw-text-white tw-bg-primary-100',
  },
}

export function DepartmentColor({ color }) {
  return (
    <div
      className={cn(
        'tw-mr-2 tw-size-5 tw-flex-shrink-0 tw-rounded-full tw-border-2 tw-border-white',
        departmentColors[color].className,
      )}
    />
  )
}
export const departmentColorOptions = Object.entries(departmentColors).map(
  ([key, value]) => ({
    value: key,
    label: value.name,
    icon: <DepartmentColor color={key} />,
  }),
)
