import React, { useMemo } from 'react'
import { Card } from 'reactstrap'
import { Funnel, Info } from '@phosphor-icons/react'
import { AddFilterButtons } from './add-filter-buttons'
import { Controller } from 'react-hook-form'
import entries from 'lodash/entries'
import { FilterButtons } from './filter-buttons'
import { useFetch } from '../../../../helpers/hooks'
import { getFilters } from '../../../../services/api-automations'
import { transformFilters } from './tools'

export function AutomationFilters() {
  const { data = [] } = useFetch({
    autoFetch: true,
    isOpenApi: true,
    action: getFilters,
  })

  const filters = useMemo(() => transformFilters(data), [data])

  return (
    <Card className='!tw-mb-0 tw-gap-3 tw-p-6'>
      <Funnel size={25} />
      <span className='tw-text-xl tw-font-medium'>Filters</span>
      <span>
        Make your alerts more specified based on country, entity, and more.
      </span>

      <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-2'>
        <AddFilterButtons filters={filters} />
        <Controller
          name='filters'
          render={({ field: { value } }) => (
            <>
              {entries(value)?.map(([name]) => (
                <FilterButtons
                  filters={filters}
                  name={name}
                  key={`filters-${name}`}
                />
              ))}
            </>
          )}
        />
      </div>

      <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
        <Info size={20} className='tw-text-text-80' />
        <span className='tw-text-text-80'>
          Only people from the selected items will be concerned.
        </span>
      </div>
    </Card>
  )
}
