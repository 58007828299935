import { CreditCard } from '@phosphor-icons/react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Nav, NavItem, NavLink } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import { useSelectedCard } from '../..'
import FEATURE_FLAGS from '../../../../config/feature-flags'
import { useFetch } from '../../../../helpers/hooks'
import { updateCardStatus } from '../../../../services/api'
import PhysicalCardStates, {
  isPhysicalCardPending,
} from '../../organisms/physical-card-states'
import CARD_TYPES_OPTIONS, {
  CARD_STATUSES,
  CARD_TYPE_ENUM,
} from '../../utils/card-types-status'
import { handleErrorOnComplete } from '../../utils/handle-error-on-complete'
import ActiveCreditCardSection, {
  ActiveCardActions,
  ConfirmationModal,
} from '../active-credit-card-section'
import TerminatedRequestCard from '../terminated-request-card'
import './cc-component.scss'

export const CARD_STATUS_ACTIONS = {
  LOCK: 'freeze',
  UNLOCK: 'unfreeze',
  TERMINATE: 'block',
}

function CreditCardComponent({
  children,
  activeCardData,
  alreadyHasPhysicalCard,
  cardOrder,
  refetchCards,
  allCardsTerminated,
  handleRequestCard,
  cardDetails,
  setAllCardData,
}) {
  const cardRef = useRef(null)
  const { selectedCard } = useSelectedCard()
  const [confirmationModalProps, setConfirmationModalProps] = useState(false)
  const isActiveCardPhysical = selectedCard === CARD_TYPE_ENUM.PHYSICAL
  const activeCardStatus = activeCardData?.cardStatus
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const cardOrderStatus = cardOrder?.status
  const cardOrderType = cardOrder?.card_type
  const physicalCardPending = isPhysicalCardPending({
    status: cardOrderStatus,
    type: cardOrderType,
  })
  const cardOrderIsPhysical =
    !!cardOrder && cardOrder?.card_type === CARD_TYPE_ENUM.PHYSICAL
  const showPhysicalCardState =
    isActiveCardPhysical &&
    (physicalCardPending ||
      !activeCardStatus ||
      (cardOrderIsPhysical &&
        ![
          CARD_STATUSES.ACTIVE,
          CARD_STATUSES.TEMP_BLOCK,
          CARD_STATUSES.TERMINATED,
        ].includes(activeCardStatus)))

  const isCardStatusTerminated = activeCardStatus === CARD_STATUSES.TERMINATED
  const isVirtualCardTerminated = !activeCardData?.id || isCardStatusTerminated
  const isPhysicalCardTerminated =
    alreadyHasPhysicalCard && isCardStatusTerminated && !physicalCardPending

  const isCardTerminated = isActiveCardPhysical
    ? isPhysicalCardTerminated
    : isVirtualCardTerminated

  let isCardActive = true

  const { startFetch: updateCard, isLoading: updatingCardStatus } = useFetch({
    action: updateCardStatus,
    onComplete: (data) => {
      document.body.classList.remove('modal-open')

      handleErrorOnComplete({
        data,
        successMessage: 'Card status updated successfully',
        errorMessage: 'Error updating card status',
        onSuccess: () => {
          hideConfirmationModal()
          refetchCards()
        },
      })
    },
    onError: () => {
      toastr.error('Error updating card status, please retry again')
      hideConfirmationModal()
    },
  })

  function handleToggleFreezeCard() {
    const lastFourDigits = activeCardData?.maskedCardNumber.slice(-4)

    setConfirmationModalProps({
      isOpen: true,
      toggle: hideConfirmationModal,
      title: `Unfreeze card`,
      content: (
        <p className='font-size-16 text-slate-600' style={{ maxWidth: '32ch' }}>
          Are you sure you want to <b>unfreeze</b> this card (**** **** ****{' '}
          {lastFourDigits})?
        </p>
      ),
      onConfirm: () =>
        updateCard({
          id: activeCardData?.id,
          status: CARD_STATUS_ACTIONS.UNLOCK,
          rp_user_id: userProfile?.id,
        }),
      confirmText: `Yes, unfreeze this card`,
      confirmColor: 'danger',
      cancelColor: 'light',
    })
  }

  function hideConfirmationModal() {
    setConfirmationModalProps((prev) => ({ ...prev, isOpen: false }))
    setTimeout(() => {
      setConfirmationModalProps(null)
    }, 200)
  }

  useEffect(() => {
    if (!cardRef.current) return

    const $card = cardRef.current
    const cardBounds = $card.getBoundingClientRect()

    function rotateToMouse(e) {
      const mouseX = e.clientX
      const mouseY = e.clientY
      const centerX = cardBounds.left + cardBounds.width / 2
      const centerY = cardBounds.top + cardBounds.height / 2
      const deltaX = mouseX - centerX
      const deltaY = mouseY - centerY

      const angleX = (deltaY / centerY) * 15
      const angleY = (-deltaX / centerX) * 15

      $card.style.transform = `
      perspective(800px)
      scale(1.1)
      rotateX(${angleX}deg)
      rotateY(${angleY}deg)
      `

      const glowX = mouseX - cardBounds.left
      const glowY = mouseY - cardBounds.top

      const offsetX = glowX - cardBounds.width / 2
      const offsetY = glowY - cardBounds.height / 2

      const adjustedGlowX = cardBounds.width / 2 + offsetX * 2.0 // Adjust the factor as needed
      const adjustedGlowY = cardBounds.height / 2 + offsetY * 2.0 // Adjust the factor as needed

      const glowGradient = `
      radial-gradient(
        circle at ${adjustedGlowX}px ${adjustedGlowY}px,
        rgba(255, 255, 255, 0.2),
        rgba(0, 0, 0, 0.1)
        )
        `

      $card.querySelector('.glow').style.background = glowGradient
    }

    function handleMouseEnter() {
      document.addEventListener('mousemove', rotateToMouse)
    }

    function handleMouseLeave() {
      document.removeEventListener('mousemove', rotateToMouse)
      $card.style.transform = ''
    }
    if (FEATURE_FLAGS.REMOTEPASS_ANIMATED_CARD) {
      $card.addEventListener('mouseenter', handleMouseEnter)
      $card.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      $card.removeEventListener('mouseenter', handleMouseEnter)
      $card.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('mousemove', rotateToMouse)
    }
  }, [cardRef])

  let cardContent = (
    <>
      <ActiveCreditCardSection
        cardData={activeCardData}
        cardDetails={cardDetails}
        allCardsTerminated={allCardsTerminated}
        setAllCardData={setAllCardData}
        refetchCards={refetchCards}
        handleToggleFreezeCard={handleToggleFreezeCard}
      />
    </>
  )

  if (showPhysicalCardState) {
    isCardActive = false
    cardContent = (
      <PhysicalCardStates
        cardOrder={cardOrder}
        handleRequestCard={handleRequestCard}
        alreadyHasPhysicalCard={alreadyHasPhysicalCard}
      />
    )
  }
  if (isCardTerminated) {
    isCardActive = false
    cardContent = (
      <TerminatedRequestCard
        afterRequestNewCard={refetchCards}
        cardType={selectedCard}
      />
    )
  }
  if (activeCardStatus === CARD_STATUSES.PENDING) {
    isCardActive = false
    cardContent = (
      <div className='py-5 px-4 rp-max-w-100 credit-card-content'>
        <CreditCard size={32} weight='duotone' color='white' />

        <p className='font-size-16 mt-2 mb-0 text-white'>Pending Card...</p>
      </div>
    )
  }

  return (
    <>
      <div className='credit-card-component' ref={cardRef}>
        <div className='glow'></div>
        <CardTypeSelector />
        {children}
        {cardContent}
      </div>
      {isCardActive && (
        <ActiveCardActions
          cardData={activeCardData}
          cardDetails={cardDetails}
          allCardsTerminated={allCardsTerminated}
          updateCard={updateCard}
          setConfirmationModalProps={setConfirmationModalProps}
          updatingCardStatus={updatingCardStatus}
          hideConfirmationModal={hideConfirmationModal}
        />
      )}
      <ConfirmationModal
        {...confirmationModalProps}
        loading={updatingCardStatus}
      />
    </>
  )
}

export default CreditCardComponent

function CardTypeSelector() {
  const { selectedCard, setSelectedCard } = useSelectedCard()

  return (
    <Nav
      pills
      className='tw-absolute tw-right-4 tw-top-4 tw-z-10 tw-gap-1 tw-rounded-full tw-border tw-border-surface-30 !tw-p-1'
    >
      {CARD_TYPES_OPTIONS.map((tab) => {
        const active = tab.value === selectedCard

        return (
          <NavItem key={`card-${tab.value}`}>
            <NavLink
              tag='button'
              className={cn(
                '!tw-rounded-full !tw-px-2 !tw-py-0.5 tw-text-xs tw-font-medium tw-leading-5 tw-transition-colors focus-visible:tw-ring-1 focus-visible:tw-ring-primary-100 focus-visible:tw-ring-offset-1 focus-visible:tw-ring-offset-primary-10',
                active
                  ? '!tw-cursor-default !tw-bg-primary-10 !tw-text-secondary-100'
                  : '!tw-bg-transparent !tw-text-white',
              )}
              onClick={() => {
                setSelectedCard(tab.value)
              }}
            >
              {tab?.labelShort}
            </NavLink>
          </NavItem>
        )
      })}
    </Nav>
  )
}
