import { ArrowLeft } from '@phosphor-icons/react'
import React, { useEffect } from 'react'
import { slide as BurgerMenu } from 'react-burger-menu'

import { cn } from 'ui'
import { ModalCloseButton } from '../Common/modal-close-button'

function usePreventScroll({ isOpen, preventScrollBehavior = true } = {}) {
  useEffect(() => {
    if (!preventScrollBehavior) return

    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen, preventScrollBehavior])
}

export function useSideMenuState() {
  const [isMenuOpen, setIsOpen] = React.useState(false)
  function toggleMenu() {
    return setIsOpen((open) => !open)
  }

  return [isMenuOpen, toggleMenu]
}

export function sideMenuGridClass({ rows = 3 } = {}) {
  const commonClass =
    'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
  switch (rows) {
    case 2: {
      return cn(commonClass, 'tw-grid-rows-[auto_1fr]')
    }
    case 3:
    default: {
      return cn(commonClass, 'tw-grid-rows-[auto_1fr_auto]')
    }
  }
}

export function SideMenu({
  children,
  isOpen,
  onClose,
  width,
  className,
  menuClassName,
  itemListClassName,
  right = true,
  preventScrollBehavior = true,
}) {
  usePreventScroll({ isOpen, preventScrollBehavior })

  return (
    <div className='tw-fixed tw-bottom-0 tw-left-0 tw-top-0 tw-z-[1050]'>
      <BurgerMenu
        onClose={onClose}
        className={cn('!tw-w-full tw-max-w-md tw-bg-white', className)}
        isOpen={isOpen}
        width={width}
        right={right}
        customCrossIcon={false}
        menuClassName={menuClassName}
        itemListClassName={itemListClassName}
      >
        {children}
      </BurgerMenu>
    </div>
  )
}

SideMenu.Header = SideMenuHeader
SideMenu.Body = SideMenuBody
SideMenu.Footer = SideMenuFooter
SideMenu.Divider = SideMenuDivider

export function SideMenuHeader({ children, className, toggle }) {
  return (
    <div
      className={cn(
        'tw-flex tw-items-start tw-justify-between tw-gap-3 tw-border-b tw-border-surface-30 tw-p-6 tw-text-xl',
        className,
      )}
    >
      <span className='tw-w-full'>{children}</span>

      {!toggle || typeof toggle !== 'function' ? null : (
        <ModalCloseButton toggle={toggle} />
      )}
    </div>
  )
}

export function SideMenuHeading({ title, subTitle, onClickBack }) {
  return (
    <div className='tw-flex tw-items-start tw-gap-2'>
      {!onClickBack || typeof onClickBack !== 'function' ? null : (
        <button
          className='tw-flex tw-flex-shrink-0 tw-rounded !tw-p-1.5 tw-text-secondary-100 tw-transition-colors hover:tw-bg-surface-20'
          aria-label='Back to details'
          onClick={onClickBack}
        >
          <ArrowLeft size={20} />
        </button>
      )}
      {!title && !subTitle ? null : (
        <div>
          {!title ? null : (
            <h4 className='tw-mb-0 tw-flex tw-items-center tw-gap-1 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {title}
            </h4>
          )}
          {!subTitle ? null : (
            <h5 className='tw-mb-0 tw-text-sm tw-font-medium tw-text-text-80'>
              {subTitle}
            </h5>
          )}
        </div>
      )}
    </div>
  )
}

export function SideMenuBody({ children, className }) {
  return <div className={cn('tw-p-4 md:tw-p-6', className)}>{children}</div>
}

export function SideMenuFooter({ children, className }) {
  return (
    <div
      className={cn(
        'tw-flex tw-justify-end tw-gap-4 tw-border-t tw-border-surface-30 tw-p-6',
        className,
      )}
    >
      {children}
    </div>
  )
}

export function SideMenuDivider({ className }) {
  return <hr className={cn('-tw-mx-4 tw-my-0 md:-tw-mx-6', className)} />
}
