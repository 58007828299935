import cx from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Card } from 'reactstrap'

import Toggle from '../../../../components/Forms/Toggle/Toggle'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import { adminCardsConfig, adminCardsConfigPut } from '../../../../services/api'
import formatAmountFloat from '../../../../utils/format-amount-float'
import SearchBar from '../../../../components/SearchBar'

export function SupportToggle({ onToggle, value, item, name, disabled }) {
  return (
    <Toggle
      change={(e) => onToggle(e, item)}
      check={value}
      name={name}
      disabled={disabled}
      marginRight={null}
    />
  )
}

function getBody(filters) {
  return { ...filters, page: filters.page }
}

function getBeBoolean(bool) {
  return bool ? 1 : 0
}

function getNewBody(item) {
  // attributes to send in the request body
  const attributes = [
    'is_matchmove_phone_code_supported',
    'is_applepay_supported',
    'is_physical_card_supported',
    'is_virtual_card_supported',
    'is_passport_supported',
    'is_id_card_supported',
    'is_poa_required',
    'is_edd',
  ]

  // get the attributes to send in the request body
  const body = Object.entries(item).reduce((acc, [key, value]) => {
    if (attributes.includes(key)) {
      acc[key] = getBeBoolean(value)
    }
    return acc
  }, {})

  return { ...body }
}

export default function AdminCardsSupportedCountries() {
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 40,
    search_key: '',
  })
  const [search, setSearch] = useState('')

  const {
    data: supportedCountries,
    paginator,
    isLoading: gettingCountries,
    startFetch: fetchSupportedCountries,
  } = useFetch(
    {
      action: adminCardsConfig,
      autoFetch: true,
      withAdminAccess: true,
      body: getBody(filters),
    },
    [filters],
  )

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
  )

  const filteredCountries = useMemo(
    () =>
      supportedCountries?.filter((item) => {
        if (!search) return true
        return item.country.name.toLowerCase().includes(search.toLowerCase())
      }),
    [search, supportedCountries],
  )

  const { isLoading: updatingConfig, startFetch: updateCardConfig } = useFetch({
    action: adminCardsConfigPut,
    withAdminAccess: true,
    onComplete: () => fetchSupportedCountries(getBody(filters)),
  })

  const handleToggleSupport = useCallback(
    function handleToggleSupport(event, item) {
      // changed attribute and value
      const changeAttribute = event.target.name
      const changeValue = getBeBoolean(event.target.checked)

      // set the changed attribute and value
      const body = {
        ...getNewBody(item),
        [changeAttribute]: changeValue,
        id: item.id,
      }

      // send the request
      updateCardConfig(body)
    },
    [updateCardConfig],
  )

  function handleFilterChange(value, name) {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  function handleSearch(query) {
    setSearch(query)
    setFilters((filters) => ({ ...filters, search_key: query, page: 1 }))
  }

  const toggleIsDisabled = useMemo(
    () => updatingConfig || gettingCountries || !hasEditPermission,
    [updatingConfig, gettingCountries, hasEditPermission],
  )

  const columns = useMemo(() => {
    return [
      { Header: 'Country', accessor: 'country.name' },
      {
        Header: 'Virtual Card',
        accessor: 'is_virtual_card_supported',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          const name = 'order_virtual_card_fees'
          return (
            <>
              <SupportToggle
                item={rowData}
                onToggle={handleToggleSupport}
                value={supported}
                name={accessor}
                disabled={toggleIsDisabled}
              />
              <CardFeeEditor
                name={name}
                value={rowData?.[name]}
                data={rowData}
                updateCardConfig={updateCardConfig}
                disabled={toggleIsDisabled}
              />
            </>
          )
        },
      },
      {
        Header: 'Physical Card',
        accessor: 'is_physical_card_supported',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          const name = 'order_physical_card_fees'
          return (
            <>
              <SupportToggle
                item={rowData}
                onToggle={handleToggleSupport}
                value={supported}
                name={accessor}
                disabled={toggleIsDisabled}
              />
              <CardFeeEditor
                name={name}
                value={rowData?.[name]}
                data={rowData}
                updateCardConfig={updateCardConfig}
                disabled={toggleIsDisabled}
              />
            </>
          )
        },
      },
      {
        Header: 'Id card',
        accessor: 'is_id_card_supported',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          return (
            <SupportToggle
              item={rowData}
              onToggle={handleToggleSupport}
              value={supported}
              name={accessor}
              disabled={toggleIsDisabled}
            />
          )
        },
      },
      {
        Header: 'Passport',
        accessor: 'is_passport_supported',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          return (
            <SupportToggle
              item={rowData}
              onToggle={handleToggleSupport}
              value={supported}
              name={accessor}
              disabled={toggleIsDisabled}
            />
          )
        },
      },
      {
        Header: 'Proof of address',
        accessor: 'is_poa_required',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          return (
            <SupportToggle
              item={rowData}
              onToggle={handleToggleSupport}
              value={supported}
              name={accessor}
              disabled={toggleIsDisabled}
            />
          )
        },
      },
      {
        Header: 'Is EDD',
        accessor: 'is_edd',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          return (
            <SupportToggle
              item={rowData}
              onToggle={handleToggleSupport}
              value={supported}
              name={accessor}
              disabled={toggleIsDisabled}
            />
          )
        },
      },
      {
        Header: 'Matchmove phone code supported',
        accessor: 'is_matchmove_phone_code_supported',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          return (
            <SupportToggle
              item={rowData}
              onToggle={handleToggleSupport}
              value={supported}
              name={accessor}
              disabled={toggleIsDisabled}
            />
          )
        },
      },
      {
        Header: 'Is ApplePay Supported',
        accessor: 'is_applepay_supported',
        Cell: ({ cellData: supported, rowData, accessor }) => {
          return (
            <SupportToggle
              item={rowData}
              onToggle={handleToggleSupport}
              value={supported}
              name={accessor}
              disabled={toggleIsDisabled}
            />
          )
        },
      },
    ]
  }, [handleToggleSupport, toggleIsDisabled, updateCardConfig])

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0'>Supported Countries</h2>
      </div>

      <Card>
        <div className='d-flex p-3'>
          <SearchBar
            name='search'
            placeholder='Search countries'
            className='w-100'
            style={{ maxWidth: 320 }}
            value={search}
            onQueryChanged={handleSearch}
          />
        </div>

        {gettingCountries ? (
          <Loader minHeight='50vh' />
        ) : filteredCountries?.length <= 0 ? (
          <NoContent className='text-muted font-size-16' minHeight='50vh'>
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable
              rowIdKey='id'
              data={filteredCountries}
              columns={columns}
              responsive
            />

            {!showPaginator ? null : (
              <div className='ml-auto mx-3 mt-2'>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={paginator?.per_page ?? 10}
                  totalItemsCount={paginator?.total ?? 0}
                  onChange={(newPage) => handleFilterChange(newPage, 'page')}
                />
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  )
}

function CardFeeEditor({ name, value, data, updateCardConfig, disabled }) {
  const [editing, setEditing] = useState(null)
  const [newValue, setNewValue] = useState(String(value ?? ''))

  function handleUpdateFee() {
    const body = {
      ...getNewBody({ ...data }),
      [name]: formatAmountFloat(newValue),
      id: data.id,
    }
    updateCardConfig(body)
    setEditing(null)
  }

  return (
    <div className='d-flex gap-8'>
      <div style={{ width: 50 }}>
        <CardCurrencyInput
          onFocus={() => setEditing(name)}
          value={newValue}
          onChange={setNewValue}
          prefix={'$ '}
          disabled={disabled}
        />
      </div>
      <div className='d-flex gap-4' style={{ width: 51 }}>
        {!editing ? null : (
          <>
            <ActionIconButton
              onClick={() => setEditing(null)}
              iconClass='bx bx-x-circle'
              color='danger'
              aria-label='Cancel'
              disabled={disabled}
            />
            <ActionIconButton
              onClick={handleUpdateFee}
              iconClass='bx bxs-check-circle'
              aria-label='Save'
              disabled={disabled}
            />
          </>
        )}
      </div>
    </div>
  )
}

function ActionIconButton({ iconClass, color = 'primary', ...props }) {
  return (
    <button
      className={cx(
        'align-items-center d-flex justify-content-center p-0 rp-btn-nostyle',
        { [`hover:bg-soft-${color}`]: !!color, [`text-${color}`]: !!color },
      )}
      type='button'
      {...props}
    >
      <i
        className={iconClass}
        style={{ fontSize: 20, padding: '0.1rem 0.2rem 0rem 0.2rem' }}
      />
    </button>
  )
}

function CardCurrencyInput({ onChange, ...props }) {
  return (
    <CurrencyInput
      className='form-control form-control-sm rounded'
      onValueChange={onChange}
      // currency input props
      allowDecimals={true}
      decimalsLimit={2}
      decimalSeparator='.'
      groupSeparator=','
      {...props}
    />
  )
}
