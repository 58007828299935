import React, { useState } from 'react'
import { Controller, useFormState } from 'react-hook-form'
import { PencilSimple, ArrowCircleRight } from '@phosphor-icons/react'
import { cn } from 'ui'
import isEmpty from 'lodash/isEmpty'
import BadgeV2 from '../../../../components/ui/badge-v2'

export function TitleEditText(props) {
  const { current } = props
  const [editing, setEditing] = useState(false)
  const { dirtyFields } = useFormState()
  const isDirty = !isEmpty(dirtyFields)
  return (
    <>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
            <div
              className={cn(
                'tw-group tw-flex tw-flex-row tw-items-center tw-gap-1 tw-rounded tw-border tw-border-transparent tw-bg-transparent tw-p-[8px] tw-text-center tw-font-bold tw-text-black',
                editing
                  ? 'tw-border-[2px] tw-border-primary-60'
                  : 'hover:tw-border-surface-50',
              )}
            >
              <input
                type='text'
                onChange={onChange}
                onBlur={(e) => {
                  onBlur(e)
                  setEditing(false)
                }}
                onFocus={() => setEditing(true)}
                value={value}
                style={{ fieldSizing: 'content' }}
                className='tw-min-w-[150px] tw-max-w-[500px] tw-overflow-hidden tw-text-ellipsis tw-text-center tw-outline-none focus:tw-border-none'
              />

              {!editing ? (
                <PencilSimple
                  size={15}
                  className='tw-hidden tw-cursor-pointer tw-text-secondary-100 group-hover:tw-block'
                />
              ) : null}

              {editing ? (
                <ArrowCircleRight
                  weight='fill'
                  size={20}
                  className='tw-cursor-pointer tw-text-primary'
                />
              ) : null}
            </div>
            {current?.isPublished ? (
              <BadgeV2 color='success' pill>
                PUBLISHED
              </BadgeV2>
            ) : (
              <BadgeV2 color='warning' pill>
                UNPUBLISHED
              </BadgeV2>
            )}
            {isDirty ? (
              <BadgeV2 className='tw-text-black' color='light' pill>
                CHANGES NOT SAVED
              </BadgeV2>
            ) : null}
          </div>
        )}
        name='name'
      />
    </>
  )
}
