import { Bank, Check, Plus, TrashSimple } from '@phosphor-icons/react'
import cx from 'classnames'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import { ActionsDropdown } from 'ui'

import globe from '../../assets/images/identity/symbol_main.png'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import BadgeX from '../../components/Table/BadgeX'
import { PermissionTooltip } from '../../components/permission-tooltip'
import { PrimaryAlert } from '../../components/ui/alert'
import { Box } from '../../components/ui/box'
import Button from '../../components/ui/button'
import DataTable from '../../components/ui/data-table'
import NavTabsV2 from '../../components/ui/nav-tabs/nav-tabs-v2'
import Shimmer from '../../components/ui/shimmer'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { useUrlState } from '../../helpers/hooks/use-url-state'
import permissions from '../../helpers/permissions'
import {
  deactivateBankAccount,
  getDepositBalance,
  getDepositBankAccounts,
  getDepositList,
  getRefundsList,
  updateCompanyInfo,
  withdrawCurrencies,
} from '../../services/api'
import { updateProfileCompany } from '../../store/profile/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import ContractRef from '../AdminPanel/components/ContractRef'
import { DepositTransactionStatus } from '../AdminPanel/pages/deposits-refunds/deposits-list'
import { getContractStatusColor } from '../Contract/ContractList/ContractList'
import ContractStatus from '../Contract/ContractList/contract-status'
import { TransactionStatusBadge } from '../Transactions'
import { getAccountNumber } from '../Withdrawal/components/WithdrawMethodCard'
import { NewAddBankForm } from '../withdrawProcess/BankAccounts'
import { PERMISSION_GROUP } from './manage-role'

export function DepositsRefundsTab() {
  return (
    <div className='rounded-top-0 mb-0 p-3 d-flex flex-column gap-24'>
      <RefundBalanceSection />

      <RefundMethodsSection />

      <RefundDepositTableSection />
    </div>
  )
}

function TabSectionHeading({ children, className }) {
  return (
    <h3
      className={cx('text-secondary-100 tw-text-xl tw-font-bold', className)}
      style={{ marginBottom: 0 }}
    >
      {children}
    </h3>
  )
}

function RefundBalanceSection() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const companyCurrency = company.currency?.code

  const { data, isLoading } = useFetch({
    action: getDepositBalance,
    autoFetch: true,
  })

  return (
    <div className='tw-grid tw-gap-4 md:tw-grid-cols-2'>
      <Box className='d-flex align-items-center gap-16'>
        <div>
          <h5 className='uppercase font-size-12 mb-1'>Refund balance</h5>

          <RefundBalance
            loading={isLoading}
            value={data?.balance}
            currency={companyCurrency}
          />
        </div>

        {/* These action will may be implemented later */}
        {/* Hiding for release */}
        {/* {data?.balance === 0 || isLoading ? null : (
          <div className='ml-auto'>
            {data?.balance > 0 ? (
              <Button className='px-0' color='link'>
                Withdraw
              </Button>
            ) : (
              <Button className='px-0' color='link'>
                Settle
              </Button>
            )}
          </div>
        )} */}
      </Box>

      <PrimaryAlert>
        The balance of deposit refunds and billing credits will be displayed
        here. This balance will be automatically used for your future
        transactions.
      </PrimaryAlert>
    </div>
  )
}

function RefundBalance({ value, currency, loading }) {
  const numberValue = Number(value)

  const formatter = getCurrencyFormatter(currency, undefined, {
    signDisplay: 'exceptZero',
  })
  const _value = formatter.format(value)

  return (
    <div
      className={cx('font-size-24 tw-flex tw-items-center tw-tabular-nums', {
        'tw-text-systemRed-100': numberValue < 0,
        'tw-text-systemGreen-100': numberValue > 0,
      })}
      style={{ height: '2rem' }}
    >
      {loading ? <Shimmer width={100} className='h-100' /> : _value}
    </div>
  )
}

function RefundMethodsSection() {
  const {
    data,
    isLoading,
    startFetch: fetchBankAccounts,
  } = useFetch({ action: getDepositBankAccounts, autoFetch: true })

  const { hasAccess } = usePermissions()

  const canManage = hasAccess(permissions.manageCompanySettings)

  const haveBankAccounts = data?.bank_accounts?.length > 0
  const isBankAccountDefault = data?.is_bank_refund_enabled === 1

  return (
    <div>
      <TabSectionHeading className='mb-3'>Refund methods</TabSectionHeading>

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2'>
        <Box className='d-flex align-items-center gap-16'>
          <RemotePassBalanceMethodBlock
            isDefault={!isBankAccountDefault}
            fetchBankAccounts={fetchBankAccounts}
            canManage={canManage}
            isLoading={isLoading}
          />
        </Box>

        <Box borderDashed={!haveBankAccounts} noPadding>
          <RefundBankAccountBlock
            isLoading={isLoading}
            haveBankAccounts={haveBankAccounts}
            data={data}
            canManage={canManage}
            fetchBankAccounts={fetchBankAccounts}
            isDefault={isBankAccountDefault}
          />
        </Box>
      </div>
    </div>
  )
}

function RemotePassBalanceMethodBlock({
  isDefault = false,
  fetchBankAccounts,
  isLoading,
  canManage,
}) {
  const dispatch = useDispatch()

  const { startFetch: updateCompany, isLoading: updatingCompanyInfo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
          fetchBankAccounts?.()
        }
      },
    })

  return (
    <>
      <div
        style={{ height: '2.5rem', width: '2.5rem' }}
        className='bg-primary-10 rounded d-flex justify-content-center align-items-center'
      >
        <img
          style={{ height: '1.5rem', width: 'auto', objectFit: 'contain' }}
          src={globe}
          alt='RemotePass icon logo'
        />
      </div>

      <div>
        <h5 className='mb-1 font-bold tw-text-black'>RemotePass</h5>
        <p className='mb-0 text-secondary-100'>
          Will be added to your refund balance above
        </p>
      </div>

      {isLoading ? null : !isDefault ? (
        <PermissionTooltip
          showing={!canManage}
          id='make-payment-default-btn'
          className='ml-auto'
          area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
        >
          <Button
            className='px-0 ml-auto'
            color='link'
            type='button'
            onClick={() => updateCompany({ is_bank_refund_enabled: 0 })}
            loading={updatingCompanyInfo}
            disabled={updatingCompanyInfo || !canManage}
          >
            Make default
          </Button>
        </PermissionTooltip>
      ) : (
        defaultElement
      )}
    </>
  )
}

const defaultElement = (
  <div className='d-flex align-items-center ml-auto'>
    <BadgeX status='primary' pill size='sm'>
      Default
    </BadgeX>
  </div>
)

const bankIcon = (
  <div
    className='rounded bg-surface-20 d-flex justify-content-center align-items-center'
    style={{ width: '2rem', height: '2rem' }}
  >
    <Bank size={24} />
  </div>
)

function RefundBankAccountBlock({
  isLoading,
  haveBankAccounts,
  data,
  fetchBankAccounts,
  isDefault,
  canManage,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const dispatch = useDispatch()

  const commonClasses = 'd-flex align-items-center gap-16 h-100 p-3'

  const { startFetch: updateCompany, isLoading: updatingCompanyInfo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
          fetchBankAccounts?.()
        }
      },
    })

  const { startFetch: deactivateBank, isLoading: deactivatingBank } = useFetch({
    action: deactivateBankAccount,
    onComplete: (data) => {
      if (data?.success !== false) {
        toastr.success('Bank account deleted successfully')
        setConfirmDeleteOpen(false)
        fetchBankAccounts?.()
      }
    },
  })

  const actionsDropdownLoading = updatingCompanyInfo || deactivatingBank

  if (isLoading) {
    return <Shimmer className={cx('w-100', commonClasses)} />
  }

  if (haveBankAccounts) {
    const { currency, details } = data.bank_accounts[0]
    const accountNumber = getAccountNumber(details)

    return (
      <div className={cx(commonClasses)}>
        {bankIcon}

        <h5 className='mb-0 font-bold tw-text-black'>
          {currency} account ending in{' '}
          {accountNumber?.slice(
            accountNumber?.length - 4,
            accountNumber?.length,
          )}
        </h5>

        <div className='ml-auto d-flex align-items-center gap-16'>
          {!isDefault ? null : defaultElement}

          {canManage ? (
            <>
              <ActionsDropdown
                loading={actionsDropdownLoading}
                data={[
                  !isDefault && {
                    label: 'Make default refund method',
                    icon: <Check size={16} />,
                    onClick: () => {
                      updateCompany({ is_bank_refund_enabled: 1 })
                    },
                  },
                  {
                    label: 'Delete bank account',
                    icon: <TrashSimple size={16} />,
                    className: 'tw-text-red-100',
                    onClick: () => setConfirmDeleteOpen(true),
                  },
                ]}
              />

              <ConfirmationModal
                title='Delete refund bank account'
                content={
                  <p className='tw-text-xl tw-text-text-100'>
                    Are you sure you want to delete the refund bank account?
                  </p>
                }
                toggle={() => setConfirmDeleteOpen((open) => !open)}
                caption='Confirm Delete'
                buttonColor='danger'
                isOpen={confirmDeleteOpen}
                onCancel={() => setConfirmDeleteOpen(false)}
                onConfirm={() => {
                  deactivateBank({ id: data.bank_accounts[0].id })
                }}
                confirmLoading={deactivatingBank}
              />
            </>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <>
      <button
        type='button'
        className={cx(commonClasses, 'p-0 w-100 hover:bg-surface-10')}
        onClick={() => setIsOpen(true)}
      >
        {bankIcon}

        <h5 className='mb-0 font-bold tw-text-black'>Add bank account</h5>

        <div className='ml-auto'>
          <Plus size={24} />
        </div>
      </button>

      <AddBankAccountModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        onSuccess={() => fetchBankAccounts()}
      />
    </>
  )
}

function AddBankAccountModal({ isOpen, toggle, onSuccess }) {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const currency = company.currency?.code

  const { data: currencies } = useFetch(
    { action: withdrawCurrencies, autoFetch: true, body: { currency } },
    [currency],
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>Add refund bank account</ModalHeader>
      <ModalBody className='p-0'>
        <NewAddBankForm
          currency={currency}
          currencies={currencies?.map((c) => mapCurrencyToOption(c))}
          toggle={toggle}
          updateList={() => {
            toggle()
            onSuccess()

            toastr.success('Refund bank account added successfully')
          }}
        />
      </ModalBody>
    </Modal>
  )
}

function RefundDepositTableSection() {
  const [activeTab, setActiveTab] = useUrlState({
    name: 'deposits-tab',
    defaultValue: 'deposits',
  })

  return (
    <div>
      <DepositNav activeTab={activeTab} onTabChange={setActiveTab} />

      {
        {
          deposits: <DepositTable />,
          refunds: <RefundsTable />,
        }[activeTab]
      }
    </div>
  )
}

function DepositNav({ activeTab, onTabChange }) {
  return (
    <NavTabsV2
      options={[
        { value: 'deposits', label: 'Deposits' },
        { value: 'refunds', label: 'Refunds' },
      ]}
      activeOption={activeTab}
      onClickOption={(nav) => onTabChange(nav.value)}
      className='mb-3'
    />
  )
}

function DateCell({ date }) {
  return (
    <>
      <span className='tw-text-text-120'>{format(date, 'MMM dd, yyyy')}</span>
      <br />
      <span className='font-size-12'>{format(date, 'hh:mm:ss')}</span>
    </>
  )
}

function depositColumns({ hasViewContractsAccess }) {
  return [
    { Header: 'Deposit ID', accessor: 'id' },
    {
      Header: 'Date',
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        const date = new Date(cellData * 1000)
        return <DateCell date={date} />
      },
    },
    {
      Header: 'Contract ID',
      accessor: 'contract_id',
      Cell: ({ cellData }) => {
        if (!hasViewContractsAccess) {
          return cellData
        }
        return <ContractRef contractId={cellData} />
      },
    },
    {
      Header: 'Contract status',
      accessor: 'contract_status.name',
      Cell: ({ rowData }) => {
        return (
          <ContractStatus
            contract={{
              amended: rowData?.contract_amended,
              status: rowData?.contract_status,
            }}
            badgeStatus={getContractStatusColor(rowData?.contract_status)}
          />
        )
      },
    },
    { Header: 'Employee', accessor: 'employee_name' },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData?.currency?.code)
        return formatter.format(rowData.amount)
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cellData }) => {
        return <DepositTransactionStatus status={cellData} />
      },
    },
  ]
}

function DepositTable() {
  const { data, isLoading } = useFetch({
    action: getDepositList,
    body: { page: 1 },
    autoFetch: true,
  })

  const { hasAccess } = usePermissions()

  const hasViewContractsAccess = hasAccess(permissions.ViewContracts)

  return isLoading ? (
    <div className='d-flex flex-column gap-4'>
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
    </div>
  ) : (
    <DataTable
      className='font-size-14'
      columns={depositColumns({ hasViewContractsAccess })}
      data={data}
      responsive
    />
  )
}

const refundColumns = [
  { Header: 'Transaction ID', accessor: 'id' },
  { Header: 'Reference', accessor: 'base_transaction_ref' },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ rowData }) => {
      const formatter = getCurrencyFormatter(rowData?.currency?.code)
      return formatter.format(rowData.amount)
    },
  },
  {
    Header: 'Refund status',
    accessor: 'status',
    Cell: ({ rowData }) => {
      return <TransactionStatusBadge order={rowData} />
    },
  },
  {
    Header: 'Refund date',
    accessor: 'created_at',
    Cell: ({ cellData }) => {
      const date = new Date(cellData * 1000)
      return <DateCell date={date} />
    },
  },
]

function RefundsTable() {
  const { data, isLoading } = useFetch({
    action: getRefundsList,
    autoFetch: true,
  })

  return isLoading ? (
    <div className='d-flex flex-column gap-4'>
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
    </div>
  ) : (
    <DataTable columns={refundColumns} data={data} responsive />
  )
}
