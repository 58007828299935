import {
  ZOHO_UPDATE_PRE_DATA,
  ZOHO_SUBMIT_ACCOUNT_MAPPING,
  ZOHO_UPDATE_CONTRACTOR,
  ZOHO_UPDATE_REQUEST,
  ZOHO_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  ZOHO_UPDATE_ORGANIZATIONS,
  ZOHO_UPDATE_ORGANIZATION,
  ZOHO_INIT,
} from './actionTypes'

export const updateZohoPreData = (payload) => {
  return {
    type: ZOHO_UPDATE_PRE_DATA,
    payload,
  }
}

export const updateZohoOrganizations = (payload) => {
  return {
    type: ZOHO_UPDATE_ORGANIZATIONS,
    payload,
  }
}

export const updateZohoOrganization = (payload) => {
  return {
    type: ZOHO_UPDATE_ORGANIZATION,
    payload,
  }
}
export const initZoho = () => {
  return {
    type: ZOHO_INIT,
  }
}

export const submitZohoAccountMapping = (payload) => {
  return {
    type: ZOHO_SUBMIT_ACCOUNT_MAPPING,
    payload,
  }
}

export const updateZohoContractor = (payload) => {
  return {
    type: ZOHO_UPDATE_CONTRACTOR,
    payload,
  }
}

export const updateZohoRequest = (payload) => {
  return {
    type: ZOHO_UPDATE_REQUEST,
    payload,
  }
}
export const submitZohoAccountMappingAndUpdateRequest = (payload) => {
  return {
    type: ZOHO_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
    payload,
  }
}
