import {
  contractTypes,
  CONTRACT_CATEGORY,
  DE_PAYMENT_PROVIDER,
  EMPLOYEE_CONTRACT_TYPE,
} from '../../../../helpers/enum'

function removeNilValues(obj) {
  // check if obj is an object
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})
}

function removeUnrelatedDefaultValues(data) {
  if (data.contract_type !== CONTRACT_CATEGORY.CONTRACTOR) {
    delete data?.contractor_label_id
    delete data?.contractor_name
  }

  if (data?.type !== contractTypes.MILESTONES) {
    delete data?.milestones
  }

  if (data?.employee_type === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    delete data?.country_id
  }

  delete data.contract_doc_type
  delete data.contract_type
  delete data.employee_type
  delete data.de_contract_doc_type

  return data
}

export function formatDataBody(data, { contractorTypes }) {
  let body = {
    ...removeNilValues(data),
  }

  // legacy fields, sent to backend
  if (body?.employee_type !== EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    body.kyc = true
    body.extra = 'extra'
  }

  // legacy field, sent to backend
  if (
    body?.type === contractTypes.PAYG ||
    body?.employee_type === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE
  ) {
    body.client_can_submit = true
  }

  if (body?.employee_type === EMPLOYEE_CONTRACT_TYPE.EOR) {
    body.insurance = data?.insurance ? 1 : 0
    body.work_visa = data?.work_visa ? 1 : 0
    body.trial_period = data?.trial_period || 'No probation'
  }

  if (body?.employee_type === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    const booleanKeys = [
      'first_payment_prorata',
      'insurance',
      'work_visa',
      'is_bonus_clause_enabled',
      'is_annual_plane_ticket_enabled',
      'is_overtime_enabled',
    ]

    booleanKeys.forEach((key) => {
      if (typeof body[key] === 'boolean') {
        body[key] = body[key] ? 1 : 0
      }
    })
  }

  // contractor_label_id to contractor_name
  if (body?.contractor_label_id) {
    const contractorType = contractorTypes.find(
      (type) => type.id === body.contractor_label_id,
    )
    body.contractor_name = contractorType?.name
    delete body.contractor_label_id
  }

  // is_external_payroll_provider to boolean
  if (body?.is_external_payroll_provider) {
    body.is_external_payroll_provider =
      body.is_external_payroll_provider ===
      DE_PAYMENT_PROVIDER.EXTERNAL_PROVIDER
        ? 1
        : 0
  }

  // RemotePass+ eligibility questions
  if (!body?.is_cor) {
    delete body?.eligibility_responses
  }

  // Add custom fields to attributes
  const customFieldsValues = Object.entries(body.custom_field ?? {}).filter(
    ([, value]) => !!value,
  )
  if (customFieldsValues.length > 0) {
    body.attributes = customFieldsValues.map(([id, value]) => ({
      id: Number(id),
      value: value ?? '',
    }))

    delete body.custom_field
  }

  // delete helper fields, and unrelated default values
  body = removeUnrelatedDefaultValues({ ...body })

  return body
}
