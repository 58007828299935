import { AvForm } from 'availity-reactstrap-validation'
import cx from 'classnames'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import TextInput from '../../components/Forms/TextInput'
import RadioGroup from '../../components/RadioGroup'
import { PermissionTooltip } from '../../components/permission-tooltip'
import Button from '../../components/ui/button'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getRoles, segmentBatch, sendUserInvitation } from '../../services/api'
import { LoadingItems } from '../Contract/ContractPage/payment-approvals'
import { PERMISSION_GROUP } from './manage-role'

const addUserForm = 'addUserForm'
const addMemberBtnId = 'add-member-btn'
function AddUserModal({ onComplete }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  const user = useSelector((state) => state?.Account?.user)
  const [loading, setLoading] = useState(false)

  const { data: rolesData, isLoading: loadingRoles } = useFetch(
    {
      action: getRoles,
      autoFetch: isOpen,
    },
    [isOpen],
  )

  const roleOptions = rolesData?.map((role) => ({
    value: role.id,
    label: role.name,
    description: role?.description,
  }))

  const [selectedType, setSelectedType] = useState()

  const isMobile = useResize()
  const formRef = useRef()
  function handleSubmitInvite(e, v) {
    setLoading(true)
    sendUserInvitation(user?.token, { ...v, role_id: selectedType?.value })
      .then((r) => {
        if (r.data.success) {
          window.analytics.track('Added a user', {
            'new-user-first-name': v?.first_name,
            'new-user-last-name': v?.last_name,
            'new-user-email-id': v?.email,
            'new-user-role': selectedType?.label,
          })
          segmentBatch({
            batch: [
              {
                type: 'identify',
                userId: v.email,
                traits: {
                  ...v,
                  role: selectedType?.label,
                },
              },
              {
                type: 'track',
                userId: v.email,
                event: 'Invited-to-Company',
                ...v,
                role: selectedType?.label,
              },
            ],
          }).then()
          toastr.success(r.data.message)
          onComplete()
          toggle()
        } else {
          toastr.error(
            r.data.data?.error ||
              r.data?.error ||
              r.data?.message ||
              'Error occurred',
            'Error',
          )
        }
      })
      .catch(() => {
        toastr.error('An error occurred', 'Error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { hasAccess } = usePermissions()
  const canAddUsers = hasAccess(permissions.InviteUsers)

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const kycNotVerified =
    userProfile?.kyc_verified !== 1 &&
    userProfile?.type === userTypes.COMPANY &&
    userProfile?.is_company_creator

  return (
    <>
      <PermissionTooltip
        showing={!canAddUsers}
        id='add-user-btn-tooltip'
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <Button
          disabled={kycNotVerified || !canAddUsers}
          onClick={toggle}
          id={addMemberBtnId}
          color='link'
          className='!tw-px-0'
        >
          Add New
        </Button>
      </PermissionTooltip>
      {kycNotVerified && (
        <UncontrolledTooltip target={addMemberBtnId}>
          Verify your identity first
        </UncontrolledTooltip>
      )}

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>New User</ModalHeader>
        <ModalBody>
          <AvForm
            className='form-horizontal'
            ref={formRef}
            onValidSubmit={handleSubmitInvite}
            id={addUserForm}
          >
            <FormGroup>
              <TextInput
                name='email'
                type='email'
                className='form-control'
                label='Email'
                id='email'
                required
              />
            </FormGroup>
            <div>
              <legend className='font-size-14'>Role</legend>

              {loadingRoles ? (
                <RolesLoading />
              ) : (
                <RadioGroup
                  value={selectedType?.value}
                  name='role'
                  options={roleOptions ?? []}
                  onChange={setSelectedType}
                />
              )}
            </div>
          </AvForm>
        </ModalBody>
        <ModalFooter className={isMobile ? 'fixed-bottom' : ''}>
          <Button
            outline
            type='button'
            color='light'
            onClick={toggle}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            loading={loading}
            disabled={loading}
            formId={addUserForm}
          >
            Save changes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function RolesLoading() {
  return (
    <LoadingItems
      length={4}
      className='tw-flex tw-flex-col tw-gap-2'
      itemHeight='46px'
      item={({ className, style }) => {
        return (
          <div
            className={cx(
              'tw-flex tw-animate-pulse tw-items-center tw-gap-3 tw-rounded tw-border tw-border-surface-20 tw-p-3',
              className,
            )}
            style={style}
          >
            <div className='tw-h-3 tw-w-3 tw-rounded-full tw-bg-surface-30' />
            <div>
              <div className='tw-h-3 tw-w-32 tw-rounded tw-bg-surface-30' />
              <div className='tw-mt-2 tw-h-3 tw-w-20 tw-rounded tw-bg-surface-30' />
            </div>
          </div>
        )
      }}
    />
  )
}

export default AddUserModal
