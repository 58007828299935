import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import rpLogoWhite from '../../assets/images/identity/logo_main.png'
import Head from '../../components/head'
import { saveRoute, updateDeepLink } from '../../store/actions'

function SimpleHeader() {
  return (
    <div
      className='d-flex align-items-center justify-content-center bg-white py-4 text-center rp-border'
      style={{ height: 70 }}
    >
      <img src={rpLogoWhite} style={{ height: '24px' }} />
    </div>
  )
}

export default function TwoFaReminderPage() {
  const history = useHistory()
  const stateAccount = useSelector((state) => state.Account)
  const {
    user: { type: userType },
    lastPath,
    link,
  } = stateAccount ?? { user: {} }

  const dispatch = useDispatch()

  const settingsLink =
    userType === 'client'
      ? '/profile-settings?tab=security'
      : '/settings#security'

  const linkProps = (() => {
    let href = '/activity'
    let click = () => {}

    const next = history.location.state?.next

    if (lastPath) {
      href = lastPath
      click = () => dispatch(saveRoute(null))
    } else if (link && typeof link === 'string') {
      href = link
      click = () => dispatch(updateDeepLink(null))
    } else if (next) {
      href = next
      click = () => {}
    }

    return { href, click }
  })()

  return (
    <div style={{ minHeight: '100vh' }}>
      <Head title='Two-Factor Authentication' />

      <SimpleHeader />

      <div
        className='d-flex flex-column justify-content-center mx-auto px-4 py-4 px-md-0'
        style={{ maxWidth: 576, minHeight: 'calc(100vh - 74px)' }}
      >
        <h1>Enable Two-Factor Authentication.</h1>
        <h3>Secure your RemotePass account.</h3>
        <p
          className='font-size-16 mt-2 text-text-50'
          style={{ maxWidth: '48ch' }}
        >
          Recommended! Add an extra layer of security for your account by
          enabling <b className='text-text-70'>Two-factor Authentication</b> to
          make sure that you, and only you, can access it.
        </p>

        <div>
          <Link to={settingsLink} className='btn btn-primary font-size-14'>
            Enable 2FA Now
          </Link>
        </div>

        <Link to={linkProps.href} onClick={linkProps.click} className='mt-3'>
          Skip
        </Link>
      </div>
    </div>
  )
}
