import React from 'react'
import { CalendarBlank } from '@phosphor-icons/react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import ControlledInput from '../../components/ControlledInput'

const TopSection = ({ isAccrued, control, error, setValue }) => {
  if (isAccrued) {
    return (
      <div className='tw-flex tw-rounded-t tw-border tw-border-surface-30 tw-p-6'>
        <CalendarBlank
          weight='duotone'
          size={32}
          className='tw-mr-4 tw-text-text-30'
        />
        <div>
          <div className='tw-text-base tw-font-bold tw-text-black'>
            Time off amount
          </div>
          <span className='tw-text-sm tw-text-text-60'>
            How much time off does the worker get per year?
          </span>
        </div>
      </div>
    )
  }

  return (
    <ConfirmFormField
      control={control}
      error={error}
      name='amount_type'
      icon={
        <CalendarBlank weight='duotone' size={32} className='tw-text-text-30' />
      }
      title='Time off amount'
      description='How much time off does the worker get per year?'
      fieldOptions={[
        { label: 'Limited', value: 'limited' },
        { label: 'Unlimited', value: 'unlimited' },
      ]}
      className='tw-mt-4 md:tw-col-span-2'
      transform={{
        output: (event) => {
          if (event.target.value === 'unlimited') {
            setValue('amount', null)
          }
          return event
        },
      }}
    />
  )
}

const InputSection = ({ control }) => {
  return (
    <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
      <InputGroup className='max-md:!tw-flex-nowrap'>
        <ControlledInput
          control={control}
          name='amount'
          id='amount-input'
          placeholder='Insert amount'
          type='number'
          min={1}
          wrapperClassName='tw-flex-grow'
          className='!tw-rounded-r-none'
        />
        <InputGroupAddon addonType='append'>
          <InputGroupText>Working days</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
}

export default function PolicyTimeOffAmount({
  isAccrued,
  isDefault,
  control,
  isLimited,
  error,
  setValue,
}) {
  if (isDefault) {
    return null
  }

  return (
    <>
      <TopSection
        isAccrued={isAccrued}
        control={control}
        error={error}
        setValue={setValue}
      />
      {(isLimited || isAccrued) && <InputSection control={control} />}
    </>
  )
}
