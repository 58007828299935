import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'

import CustomDateRange from '../../components/Forms/custom-date-range'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Head from '../../components/head'
import Loader from '../../components/ui/loader'
import FEATURE_FLAGS from '../../config/feature-flags'
import { BE_CONTRACT_CATEGORY, CONTRACT_STATUS } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { getContractList } from '../../services/api'
import {
  getContractPaidAndUnPaidDays,
  getContractTimeOff,
} from '../../services/api-time-off-policies'
import { TimeOffStats } from '../Contract/ContractPage/time-off-stats'
import { useTimeOffFetch } from './helpers'
import TimeOffDaysCount from './time-off-days-count'
import TimeOffEmpty from './time-off-empty'
import TimeOffList from './time-off-list'
import TimeOffPageHeader from './time-off-page-header'

export default function TimeOffPage() {
  const [contractId, setContractId] = useState(null)

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const isEmployee =
    userProfile?.contractor_type === BE_CONTRACT_CATEGORY.EMPLOYEE

  const {
    timeOffs,
    gettingTimeOffList,
    updateTimeOff,
    paginator,
    filters,
    handleFiltersChange,
  } = useTimeOffFetch({
    contractId,
    isEmployee,
    isContractDetails: false,
    autoFetch: contractId,
  })

  const {
    data: policies,
    isLoading: isLoadingPolicies,
    startFetch: refreshContractPolicies,
  } = useFetch(
    {
      action: getContractTimeOff,
      body: { contract_id: contractId },
      autoFetch: !!contractId,
    },
    [contractId],
  )

  return (
    <div className='page-content'>
      <Head title='Time off' />

      <TimeOffPageHeader
        updateTimeOff={() => {
          updateTimeOff()
          refreshContractPolicies()
        }}
        timeOffs={timeOffs}
      />

      <TimeOffContent
        timeOffs={timeOffs}
        gettingTimeOffList={gettingTimeOffList}
        updateTimeOff={updateTimeOff}
        paginator={paginator}
        filters={filters}
        handleFiltersChange={handleFiltersChange}
        contract={contractId}
        onContractChange={setContractId}
        policies={policies}
        isLoadingPolicies={isLoadingPolicies}
        refreshContractPolicies={refreshContractPolicies}
      />
    </div>
  )
}

function getContractOptions(contract) {
  const label = `${contract?.ref} - ${contract?.name} (${contract?.type})`
  return { label, value: contract?.id }
}

function TimeOffContent({
  timeOffs,
  gettingTimeOffList,
  updateTimeOff,
  paginator,
  filters,
  handleFiltersChange,
  contract,
  onContractChange,
  policies,
  isLoadingPolicies,
  refreshContractPolicies,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const isEmployee =
    userProfile?.contractor_type === BE_CONTRACT_CATEGORY.EMPLOYEE

  const hasFilters =
    Object.keys(filters).length > 2 &&
    Object.keys(filters).some((key) => key !== 'page' && key !== 'contract_id')

  const isListEmpty =
    !timeOffs || timeOffs?.list?.length <= 0 || timeOffs?.length <= 0

  const isPageEmpty = !hasFilters && isListEmpty

  const timeOffsList = timeOffs?.list || []

  const { data: contracts, isLoading: contractsLoading } = useFetch({
    action: getContractList,
    autoFetch: true,
    body: { statuses: CONTRACT_STATUS.ONGOING.value },
    onComplete: (contracts) => {
      if (isEmployee) {
        onContractChange(userProfile?.contract_id)
      } else {
        onContractChange(contracts?.[0]?.id)
      }
    },
  })

  const selectedContract = isEmployee
    ? { id: userProfile?.contract_id }
    : contracts?.find((c) => c?.id === contract)

  const {
    data: paidAndUnPaidDays,
    isLoading: gettingPaidDays,
    startFetch: getPaidAndUnpaidDays,
  } = useFetch(
    {
      action: getContractPaidAndUnPaidDays,
      autoFetch: selectedContract?.id,
      body: selectedContract?.id,
    },
    [selectedContract?.id],
  )

  return (
    <Card>
      <CardHeader className='!tw-bg-transparent !tw-px-0 !tw-pb-0 !tw-pt-6'>
        {FEATURE_FLAGS.TIME_OFF_POLICIES ? (
          <>
            {contracts?.length > 1 && !contractsLoading && (
              <div className='tw-px-6'>
                <CustomSelect
                  placeholder='Select contract'
                  value={getContractOptions(selectedContract)}
                  options={contracts.map(getContractOptions)}
                  onChange={(val) => onContractChange(val?.value)}
                />
              </div>
            )}

            <TimeOffStats
              contract={selectedContract}
              policies={policies}
              isLoadingPolicies={isLoadingPolicies}
              refreshPolicies={() => {
                refreshContractPolicies()
                getPaidAndUnpaidDays()
              }}
              updateTimeOff={updateTimeOff}
            />
          </>
        ) : (
          <div className='tw-flex tw-flex-wrap tw-justify-between tw-gap-1 tw-px-4 md:tw-px-6'>
            <TimeOffDaysCount className='tw-inline-flex' timesOff={timeOffs} />

            <CustomDateRange
              wrapperStyles={{ maxWidth: 240 }}
              wrapperClassName='w-100'
              name='dateRange'
              value={[filters.start_date, filters.end_date]}
              placeholder='Select date range'
              clearable
              handleClear={() => {
                handleFiltersChange('start_date', null, { action: 'clear' })
                handleFiltersChange('end_date', null, { action: 'clear' })
              }}
              onChange={(newDates) => {
                const start = newDates?.[0]
                const end = newDates?.[1]

                handleFiltersChange('start_date', start, { action: 'clear' })
                handleFiltersChange('end_date', end, { action: 'clear' })
              }}
            />
          </div>
        )}
      </CardHeader>

      {gettingTimeOffList || !contract ? (
        <Loader minHeight='30rem' />
      ) : isPageEmpty ? (
        <TimeOffEmpty onSubmit={updateTimeOff} timeOffs={timeOffs} />
      ) : (
        <CardBody className='px-md-0'>
          <TimeOffList
            timeOffsList={timeOffsList}
            paginator={paginator}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
            update={() => {
              updateTimeOff()
              refreshContractPolicies()
              getPaidAndUnpaidDays()
            }}
            gettingTimeOffList={gettingTimeOffList || gettingPaidDays}
            paidDays={paidAndUnPaidDays?.days_paid}
            unPaidDays={paidAndUnPaidDays?.days_unpaid}
          />
        </CardBody>
      )}
    </Card>
  )
}
