import React from 'react'

import BadgeV2 from '../../../components/ui/badge-v2'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'

export default function ContractStatus({ contract, badgeStatus }) {
  return contract?.amended &&
    contract?.type !== CONTRACT_TYPES.DIRECT_EMPLOYEE ? (
    <BadgeV2 status='warning'>Pending amendment</BadgeV2>
  ) : (
    <BadgeV2 status={badgeStatus}>
      {contract?.status?.name.includes('Pending')
        ? 'Pending'
        : contract?.status?.name}
    </BadgeV2>
  )
}
