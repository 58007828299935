import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { deleteFormCondition } from '../../../../../services/api'
import toastr from 'toastr'
import Button from '../../../../../components/ui/button'
import { useFetch } from '../../../../../helpers/hooks'

const DeleteConditionModal = ({
  isOpen,
  toggle,
  refetch,
  formId,
  formConditionId,
}) => {
  const { startFetch: deleteCondition, isLoading: isDeletingCondition } =
    useFetch({
      action: deleteFormCondition,
      withAdminAccess: true,
      onComplete: () => {
        toastr.success('Condition deleted successfully')
        toggle?.()
        refetch?.()
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}>Delete condition</ModalHeader>
      <ModalBody>
        <div className='m-3'>
          Are you sure you want to delete this condition?
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color='light' type='button' outline onClick={toggle}>
          Cancel
        </Button>
        <Button
          color='danger'
          type='button'
          loading={isDeletingCondition}
          disabled={isDeletingCondition}
          onClick={() => {
            deleteCondition({
              form_id: formId,
              form_condition_id: formConditionId,
            })
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteConditionModal
