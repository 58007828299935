import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Container, TabPane } from 'reactstrap'
import toastr from 'toastr'

import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import { StyledH5 } from '../../../components/Typo'
import { useFetch } from '../../../helpers/hooks'
import { confirmOffload as confirmOffloadAction } from '../../../services/api'
import formatAmountFloat from '../../../utils/format-amount-float'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import WithdrawReview from '../../withdrawProcess/withdrawReview'
import FieldAmount from '../components/field-amount'
import TabContent from '../../../components/ui/tabs'
import BalanceWithFlag from '../components/balance-with-flag'

const steps = [
  { label: 'Offload', value: 0, title: 'Offload to Wallet' },
  { label: 'Review', value: 1, title: 'Review' },
]

export default function CardOffload() {
  const amountRef = useRef()
  const history = useHistory()
  const location = history.location

  const [activeStep, setActiveStep] = useState(steps[0].value)
  const [amount, setAmount] = useState(null)
  const [amountError, setAmountError] = useState()

  const user = useSelector((state) => state.userProfile?.userProfile)
  const fullName = [user?.first_name, user?.middle_name, user?.last_name]
    .filter(Boolean)
    .join(' ')

  const [balance] = useState(
    parseInt(location.state?.card?.funds?.available?.amount),
  )

  useEffect(() => {
    setAmount(balance)
  }, [balance])

  const { startFetch: confirmOffload, isLoading: confirmingOffload } = useFetch(
    {
      action: confirmOffloadAction,
      onComplete: (data) => {
        if (
          Object.prototype.hasOwnProperty.call(data, 'success') &&
          data.success === false
        ) {
          toastr.error('Something went wrong, retry later')
        } else {
          toastr.success('Offload successful')
          history.push('/cards')
        }
      },
      onError: (resp) => {
        toastr.error(
          resp?.error?.messages?.[0] ?? 'Something went wrong, please retry',
        )
      },
    },
  )

  function handleDataChange(key, value) {
    if (key === 'amount' && value > balance) {
      let error = null
      if (balance <= 0) {
        error = 'Your card balance is 0'
      } else {
        const formatter = getCurrencyFormatter(
          location.state?.card?.funds?.available?.currency,
        )
        const balanceFormatted = formatter.format(balance)
        error = `You can't select more than ${balanceFormatted}`
      }
      setAmountError(error)
    } else {
      setAmountError(null)
      setAmount(value)
    }
  }

  function handleConfirmOffload() {
    confirmOffload({
      usd_amount: formatAmountFloat(amount),
      card_id: location?.state?.card?.id,
    })
  }

  function handleNext() {
    const MINIMUM_AMOUNT = 1
    if (formatAmountFloat(amount) < MINIMUM_AMOUNT) {
      toastr.error('Please enter a valid amount, minimum is $' + MINIMUM_AMOUNT)
    } else if (activeStep === 0) {
      setActiveStep((step) => step + 1)
    } else {
      handleConfirmOffload()
    }
  }

  function handleBack() {
    setActiveStep((step) => step - 1)
  }

  const reviewData = {
    base_currency: balance?.currency,
    subtotal: amount,
    total: amount,
    details: {
      should_arrive: 'In seconds',
      details: [
        { name: 'Account holder', value: fullName },
        { name: 'Email', value: user?.email },
      ],
    },
  }

  return (
    <div className=''>
      <Container fluid>
        <ModalHeader action={() => history.push('/cards')}>
          <Steps
            activeTab={activeStep}
            data={steps.map((step) => step.label)}
            // noLastAction
            className='d-none d-md-flex'
          />
        </ModalHeader>
      </Container>

      <Container style={{ maxWidth: 552, margin: 'auto' }}>
        <TabContent activeTab={activeStep}>
          <TabPane tabId={steps[0].value}>
            <StepContainer
              index={steps[0].value}
              total={steps.length}
              title={steps[0].title}
              onNext={handleNext}
              onBack={handleBack}
              nextText='Next'
              disableNext={!amount}
              loading={confirmingOffload}
              minHeight='320px'
            >
              <>
                <div className='py-3'>
                  <StyledH5>Card balance</StyledH5>
                  <BalanceWithFlag
                    balance={location.state?.card?.funds?.available}
                  />
                </div>
                <FieldAmount
                  label='Enter an amount'
                  name='offload-amount'
                  value={amount}
                  onValueChange={(v) => handleDataChange('amount', v)}
                  prefix={location.state?.card?.funds?.available?.currency}
                  inputRef={amountRef}
                  error={amountError}
                />
              </>
            </StepContainer>
          </TabPane>
          <TabPane tabId={steps[1].value}>
            <StepContainer
              index={steps[1].value}
              total={steps.length}
              title={steps[1].title}
              onNext={handleNext}
              onBack={handleBack}
              loading={confirmingOffload}
              nextText='Confirm'
              isFlat
              minHeight='320px'
            >
              <WithdrawReview
                title='Offload to RemotePass Wallet'
                receptionLabel='You will receive in your Wallet'
                bankInfo={reviewData}
                onBack={handleBack}
                loadingMinHeight='310px'
              />
            </StepContainer>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}
