import { differenceInYears } from 'date-fns'

export function getAgeBracket(birthDate) {
  const age = differenceInYears(new Date(), new Date(birthDate))
  if (age < 18) {
    return ['0-17']
  } else if (age < 40) {
    return ['18-39']
  } else if (age < 50) {
    return ['40-49']
  } else if (age < 60) {
    return ['50-59']
  } else if (age <= 74) {
    return ['60-74']
  } else {
    return [null]
    // `Unfortunately, the age entered is outside the supported age range`
  }
}
export function getAge(dateString) {
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}
