import { useFetch } from '../../helpers/hooks'
import { getContractorWithdrawAccounts } from '../../services/api'

export default function useWithdrawAccounts(key, source) {
  const {
    startFetch: getAccountsList,
    data: allAccounts,
    isLoading: loading,
  } = useFetch({
    action: getContractorWithdrawAccounts,
    body: { source },
    autoFetch: true,
  })

  const accounts = (key ? allAccounts?.[key] : allAccounts) ?? []

  return { getAccountsList, accounts, loading }
}
