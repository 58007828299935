import React from 'react'
import { cn } from 'ui'

export function Result({
  className,
  IconComponent,
  iconClassName,
  title,
  titleClassName,
  description,
  descriptionClassName,
  actions,
  actionsClassName,
  centered = true,
}) {
  return (
    <div
      className={cn(
        'tw-flex tw-w-full tw-max-w-md tw-flex-col',
        centered ? 'tw-items-center tw-text-center' : 'tw-text-start',
        className,
      )}
    >
      {!IconComponent ? null : (
        <IconComponent className={cn('tw-size-44', iconClassName)} />
      )}

      {!title && !description ? null : (
        <div className='tw-mt-2 tw-w-full'>
          {!title ? null : (
            <h2
              className={cn(
                'tw-mb-0 tw-text-xl tw-font-semibold tw-text-secondary-120',
                titleClassName,
              )}
            >
              {title}
            </h2>
          )}

          {!description ? null : (
            <p
              className={cn(
                'tw-mb-0 tw-text-sm tw-font-medium tw-text-text-80',
                descriptionClassName,
              )}
            >
              {description}
            </p>
          )}
        </div>
      )}

      {!actions ? null : (
        <ResultActions
          actions={actions}
          className={actionsClassName}
          centered={centered}
        />
      )}
    </div>
  )
}

function ResultActions({ actions, className, centered }) {
  const isActionsArray = Array.isArray(actions)

  const classes = cn(
    'tw-mt-10 tw-w-full',
    centered ? 'tw-text-center tw-justify-center' : 'tw-text-start',
    isActionsArray && 'tw-flex tw-gap-4',
    className,
  )

  return <div className={classes}>{actions}</div>
}
