const ADMIN_PERMISSIONS = {
  VIEW_TRANSACTIONS: 'view admin transactions',
  MANAGE_TRANSACTIONS: 'manage admin transactions',
  SEARCH_TRANSACTIONS: 'search admin transactions',

  MANAGE_TRANSACTION_STATUS: 'update admin transactions status',

  VIEW_TRANSFERS: 'view admin transfers',
  MANAGE_TRANSFERS: 'manage admin transfers',

  VIEW_CONTRACTS: 'view admin contracts',
  MANAGE_CONTRACTS: 'manage admin contracts',
  SEARCH_CONTRACTS: 'search admin contracts',

  VIEW_CONTRACT_DETAILS: 'view admin contract details',
  MANAGE_CONTRACT_DETAILS: 'manage admin contract details',

  VIEW_COMPANIES: 'view admin companies',
  MANAGE_COMPANIES: 'manage admin companies',
  SEARCH_COMPANIES: 'search admin companies',

  VIEW_USERS: 'view admin users',
  MANAGE_USERS: 'manage admin users',
  SEARCH_USERS: 'search admin users',

  VIEW_AUTO_WITHDRAWS: 'view admin auto withdraws',
  MANAGE_AUTO_WITHDRAWS: 'manage admin auto withdraws',

  VIEW_TEMPLATES: 'view admin templates',
  MANAGE_TEMPLATES: 'manage admin templates',

  VIEW_EOR_CONTRACTS: 'view admin eor contracts',
  MANAGE_EOR_CONTRACTS: 'manage admin eor contracts',

  VIEW_PARTNERS: 'view admin partners',
  MANAGE_PARTNERS: 'manage admin partners',

  VIEW_CALCULATOR: 'view admin calculator',
  MANAGE_CALCULATOR: 'manage admin calculator',

  VIEW_QUOTE_GENERATOR: 'view admin quote generator',
  MANAGE_QUOTE_GENERATOR: 'manage admin quote generator',

  VIEW_INVOICE_GENERATOR: 'view admin invoice generator',
  MANAGE_INVOICE_GENERATOR: 'manage admin invoice generator',

  VIEW_PAYMENTS: 'view admin payments',

  VIEW_INSURANCE_REQUESTS: 'view admin insurance requests',
  MANAGE_INSURANCE_REQUESTS: 'manage admin insurance requests',

  VIEW_INSURANCE_PAYMENTS: 'view admin insurance payments',
  MANAGE_INSURANCE_PAYMENTS: 'manage admin insurance payments',

  VIEW_CURRENCY_MARKUP: 'view admin currency markup',
  MANAGE_CURRENCY_MARKUP: 'manage admin currency markup',

  USER_IMPERSONATION: 'admin user impersonation',

  VIEW_ADMINS: 'view admins',
  MANAGE_ADMINS: 'manage admins',

  VIEW_ADMIN_PERMISSIONS: 'view admin permissions',
  MANAGE_ADMIN_PERMISSIONS: 'manage admin permissions',

  VIEW_ADMIN_CARDS: 'view admin cards',
  MANAGE_ADMIN_CARDS: 'manage admin cards',

  UPDATE_ADMIN_2FA: 'update admin user 2fa',

  VIEW_PROVIDER_RULES: 'view provider rules',
  CREATE_PROVIDER_RULES: 'create provider rules',
  UPDATE_PROVIDER_RULES: 'update provider rules',
  DELETE_PROVIDER_RULES: 'delete provider rules',

  MANAGE_BANK_ACCOUNTS: 'manage admin bank accounts',

  MANAGE_ADMIN_MARKUPS: 'manage admin markups',

  MANAGE_ADMIN_DEPOSITS: 'manage admin deposits',
}

export default ADMIN_PERMISSIONS
