import { Button } from 'reactstrap'
import React from 'react'
import cx from 'classnames'

export default function IconButton({
  icon,
  loading,
  circle,
  onClick,
  className,
  formId,
  color = 'primary',
  size = 40,
  style = {},
  ...btnProps
}) {
  return (
    <Button
      onClick={onClick}
      color={color}
      className={cx(
        'p-0 d-flex hover:bg-primary-20 tw-items-center tw-justify-center',
        className,
      )}
      {...btnProps}
      style={{
        height: size,
        width: size,
        borderRadius: circle ? 1000 : 4,
        ...style,
      }}
      form={formId}
    >
      {loading ? <i className='bx bx-loader bx-spin font-size-14' /> : icon}
    </Button>
  )
}
