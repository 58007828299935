import { COINBASE } from '../../core/config/payment-methods'
import { getNumberFormatter } from './number'

export const COINBASE_CURRENCY = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC',
}

export const weirdCurrencies = [COINBASE_CURRENCY.USDC]

export function weirdCurrenciesFormatter(currency, provider) {
  const formatter = getNumberFormatter({
    options: {
      maximumSignificantDigits: provider === COINBASE.key ? 6 : undefined,
    },
  })

  return {
    format: (value) => {
      const valueFormatted = formatter.format(value)

      return `${currency} ${valueFormatted}`
    },
  }
}
