import { ClockCountdown, PlusMinus } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'
import toastr from 'toastr'
import { ExpandIcon } from 'ui'

import { PermissionTooltip } from '../../../components/permission-tooltip'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  deleteWork,
  getAdminContractPayment,
  getContractPayment,
} from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { useFilters } from '../../AdminPanel/pages/cards/use-filters'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import AdjustModal from '../components/AdjustModal'
import TabCardHeader from '../components/tab/tab-card-header'
import TabEmpty from '../components/tab/tab-empty'
import {
  PaymentApprovals,
  PaymentStatusBadge,
  PendingApprovalsStatus,
  useApprovalsNotEnabled,
} from './payment-approvals'

export default function Payments({ contract, isAdmin, contractLoading }) {
  const [show, setShow] = useState(false)
  const [expanded, setExpanded] = useState(-1)

  const [filters, handleFiltersChange] = useFilters({ page: 1 })

  const user = useSelector((state) => state.Account?.user)
  const isMobile = useResize()
  const { hasAccess } = usePermissions()

  const bodyFilters = { ...filters, id: contract?.id }

  const autoFetchPayments = !!contract?.id
  const {
    isLoading: isLoadingPayments,
    startFetch: refetchPayments,
    data: paymentsList,
    paginator: paymentsPaginator,
    completed: doneFetchingPayments,
    error: fetchPaymentError,
  } = useFetch(
    {
      action: isAdmin ? getAdminContractPayment : getContractPayment,
      initResult: [],
      body: bodyFilters,
      autoFetch: autoFetchPayments,
      withAdminAccess: !!isAdmin,
      onError: (error) => toastr.error(error),
    },
    [bodyFilters?.page, bodyFilters?.id],
  )

  const loadingPayments = autoFetchPayments
    ? !doneFetchingPayments && !fetchPaymentError
    : isLoadingPayments

  function updatePayments(loading = true) {
    if (contract?.id) {
      refetchPayments(bodyFilters, loading)
    }
  }

  const showAdjustBtn =
    user?.type === userTypes.COMPANY &&
    !isAdmin &&
    contract.type !== 'Full Time'

  const adjustBtnDisabled =
    [6].includes(contract?.status?.id) ||
    !!contract?.amended ||
    !hasAccess(permissions.addAdjustments)

  const approvalsNotEnabled = useApprovalsNotEnabled({ contract })

  return loadingPayments || contractLoading ? (
    <Loader minHeight='30rem' />
  ) : (
    <>
      <Card className='rp-shadow-2'>
        <TabCardHeader
          title='Payments'
          extra={
            !showAdjustBtn ? null : (
              <PermissionTooltip
                showing={adjustBtnDisabled}
                id='add-adjustment-btn'
                area={PERMISSION_GROUP.ADJUST_PAYMENTS.name}
                action=''
              >
                <Button
                  disabled={adjustBtnDisabled}
                  onClick={() => {
                    window.analytics.track('Clicked adjust', {
                      contract_id: contract?.ref,
                      contract_type: contract?.type?.name,
                      contract_status: contract?.status?.name,
                    })
                    setShow(true)
                  }}
                  iconRight={<PlusMinus size={20} />}
                >
                  Adjust
                </Button>
              </PermissionTooltip>
            )
          }
        />

        {paymentsList?.length === 0 ? (
          <TabEmpty
            title='No payments'
            subtitle='Past and upcoming payments will be shown here'
            icon={
              <ClockCountdown
                size={250}
                weight='duotone'
                color='var(--primary)'
              />
            }
          />
        ) : (
          <CardBody className='px-0'>
            <div className='table-responsive'>
              {isMobile ? (
                <div className='p-3' style={{ minHeight: '70vh' }}>
                  {React.Children.toArray(
                    paymentsList?.map((order, key) => (
                      <Line
                        key={key}
                        isAdmin={isAdmin}
                        item={order}
                        expanded={expanded === key}
                        onUpdate={() => updatePayments(false)}
                        loadingPayments={loadingPayments}
                        onExpand={() => {
                          if (expanded === key) {
                            setExpanded(-1)
                          } else {
                            setExpanded(key)
                          }
                        }}
                      />
                    )),
                  )}
                </div>
              ) : (
                <TableComp className='table-centered'>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh />
                      <StyledTh>Month</StyledTh>
                      <StyledTh>Payment ID</StyledTh>
                      <StyledTh>Due Date</StyledTh>
                      <StyledTh>Type</StyledTh>
                      <StyledTh>Amount</StyledTh>
                      <StyledTh>Status</StyledTh>
                      {approvalsNotEnabled ? null : (
                        <StyledTh>Pending approvals</StyledTh>
                      )}
                      <StyledTh>Action</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      paymentsList?.map((order, key) => (
                        <Line
                          key={key}
                          isAdmin={isAdmin}
                          item={order}
                          expanded={expanded === key}
                          onUpdate={() => updatePayments(false)}
                          loadingPayments={loadingPayments}
                          onExpand={() => {
                            if (expanded === key) {
                              setExpanded(-1)
                            } else {
                              setExpanded(key)
                            }
                          }}
                        />
                      )),
                    )}
                  </tbody>
                </TableComp>
              )}
            </div>

            <div className='mt-2 mt-md-0 d-flex justify-content-md-end px-3 pt-4.5'>
              <Pagination
                innerClass='pagination mb-0'
                activePage={filters?.page}
                onChange={(page) => handleFiltersChange('page', page)}
                itemsCountPerPage={paymentsPaginator?.per_page ?? 50}
                totalItemsCount={paymentsPaginator?.total ?? 0}
              />
            </div>
          </CardBody>
        )}
      </Card>

      {show && (
        <AdjustModal
          data={paymentsList}
          contract={contract}
          show={show}
          hide={() => setShow(false)}
          updated={() => {
            updatePayments(false)
            setShow(false)
          }}
        />
      )}
    </>
  )
}

function Line({
  item,
  expanded,
  onExpand,
  onUpdate,
  isAdmin,
  loadingPayments,
}) {
  const [show, setShow] = useState(false)

  function toggleSideMenu() {
    setShow((open) => !open)
  }

  const user = useSelector((state) => state.Account?.user)
  const contract = useSelector((state) => state.Contract?.details)
  const formatter = getCurrencyFormatter(item?.currency?.code)

  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Deleted adjustment', {
        contract_id: contract?.ref,
        contract_type: contract?.type,
        contract_status: contract?.status?.name,
        adjust_id: body?.work?.work_id,
        adjust_type: body?.work?.name,
        amount: body?.work?.amount,
        currency: contract?.currency?.code,
      })
    },
  })
  const handleRemoveWork = (work) => {
    deleteLine.startFetch({ work_id: work?.work_id, work })
  }

  const isMobile = useResize()

  const info = [
    {
      label: 'Amount',
      value: item?.amount ? formatter.format(item?.amount) : '-',
    },
    { label: 'Due Date', value: item?.due_date },
  ]

  const approvalsNotEnabled = useApprovalsNotEnabled({ contract })
  return (
    <>
      {isMobile ? (
        <div className='bg-white border mb-3 position-relative py-0 rounded rp-shadow-2'>
          <Row className='p-3 m-0 border-bottom'>
            <Col xs={8} className='p-0 m-0 font-size-14 rp-font-bold'>
              #{item.payment_ref}
            </Col>
            <Col
              xs={4}
              className='p-0 m-0 justify-content-end align-items-center d-flex'
            >
              <PaymentStatusBadge
                status={item.status}
                approvalStatus={item.approval_status}
                id={item.payment_ref}
              />
            </Col>
          </Row>

          <div className='p-3 d-flex flex-column gap-12'>
            {info.map(({ label, value }, key) => {
              return (
                <div
                  key={key}
                  className='d-flex align-items-center justify-content-between align-items-center'
                >
                  <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                    {label}
                  </h6>
                  <h6 className='mb-0 text-dark font-weight-normal font-size-14'>
                    {value}
                  </h6>
                </div>
              )
            })}
            <PendingApprovalsStatus item={item} />
            <Button
              color='link'
              size='sm'
              className='!tw-text-sm'
              onClick={toggleSideMenu}
            >
              Details
            </Button>
          </div>
        </div>
      ) : (
        <>
          <tr>
            <StyledTd>
              {item?.works?.length !== 0 &&
                item.status !== 'Paid (off-cycle)' && (
                  <ExpandIcon isExpanded={expanded} onClick={onExpand} />
                )}
            </StyledTd>
            <StyledTd>{item.month}</StyledTd>
            <StyledTd>
              <span className='font-size-14'>#{item.payment_ref}</span>
            </StyledTd>
            <StyledTd>{item.due_date}</StyledTd>
            <StyledTd>{item.type}</StyledTd>
            <StyledTd>{formatter.format(item.amount)}</StyledTd>
            <StyledTd>
              <PaymentStatusBadge
                status={item.status}
                approvalStatus={item.approval_status}
                id={item.payment_ref + item.id}
              />
            </StyledTd>
            {approvalsNotEnabled ? null : (
              <StyledTd>
                <PendingApprovalsStatus item={item} />
              </StyledTd>
            )}
            <StyledTd>
              <Button
                color='link'
                size='sm'
                className='!tw-p-0 !tw-text-sm'
                onClick={toggleSideMenu}
              >
                Details
              </Button>
            </StyledTd>
          </tr>

          {expanded && (
            <>
              {item?.works?.map((t, k) => (
                <tr
                  className='font-weight-light font-size-12 bg-gray-bg'
                  key={'_trans' + t?.id}
                >
                  <th className='p-0' style={{ height: 55 }}>
                    <div
                      className='h-100'
                      style={{ borderLeft: '3px solid var(--primary)' }}
                    />
                  </th>
                  <th
                    colSpan={2}
                    className='font-size-14 pr-1 py-3 text-dark'
                    style={{ fontWeight: '400' }}
                  >
                    <div
                      className='d-flex flex-column'
                      style={{ gap: '0.5rem' }}
                    >
                      <p className='text-muted mb-0'>{t?.name}</p>

                      <p
                        style={{ maxWidth: 224 }}
                        className='font-size-14 mb-0'
                      >
                        {t?.details}
                      </p>

                      {t.attributes?.map((a) => (
                        <div key={`attr-${a?.name}`}>
                          <span className='text-muted'>{a?.name}: </span>
                          <span>{a?.value}</span>
                          <br />
                        </div>
                      ))}
                    </div>
                  </th>
                  <th></th>
                  <th></th>

                  <th
                    className='py-3 text-dark font-size-14'
                    style={{ fontWeight: '400' }}
                    colSpan={3}
                  >
                    {formatter.format(t?.amount)}
                  </th>

                  <th
                    className='py-3 px-4 text-dark font-size-14'
                    style={{ fontWeight: '400' }}
                  >
                    {t.can_delete && user?.type === 'client' && (
                      <button
                        className='rp-btn-nostyle text-danger d-flex p-1 hover:bg-soft-danger font-size-18'
                        type='button'
                        onClick={() => handleRemoveWork(t, k)}
                        disabled={deleteLine.isLoading}
                      >
                        {deleteLine.isLoading ? (
                          <i className='bx bx-loader bx-spin' />
                        ) : (
                          <i className='bx bx-trash' />
                        )}
                      </button>
                    )}
                  </th>
                </tr>
              ))}
            </>
          )}
        </>
      )}

      {!show ? null : (
        <PaymentApprovals
          isAdmin={isAdmin}
          state={{ show, data: item }}
          contract={contract}
          toggle={toggleSideMenu}
          onUpdate={onUpdate}
          loadingPayments={loadingPayments}
        />
      )}
    </>
  )
}
