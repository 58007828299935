import React from 'react'

import { assetsStorage } from '../../helpers/config'

export default function Flag({ url, size = '16px', className, style }) {
  return (
    <div
      style={{
        backgroundPosition: 'center',
        borderRadius: 999,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${assetsStorage}/${url})`,
        '--size': size,
        width: 'var(--size)',
        height: 'var(--size)',
        ...style,
      }}
      className={className}
    />
  )
}

export function getFlagUrlFromIso2(iso2) {
  return `flags/${iso2?.toLowerCase()}.svg`
}
