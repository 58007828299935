import ADMIN_PERMISSIONS from './admin-permissions'

const PERMISSIONS_LIST = [
  {
    label: 'All Payment',
    name: 'payment',
    permissions: [{ label: 'view', value: ADMIN_PERMISSIONS.VIEW_PAYMENTS }],
  },
  {
    label: 'Transactions',
    name: 'transactions',
    permissions: [
      {
        label: 'view',
        value: ADMIN_PERMISSIONS.VIEW_TRANSACTIONS,
        requires: [ADMIN_PERMISSIONS.SEARCH_TRANSACTIONS],
      },
      { label: 'search', value: ADMIN_PERMISSIONS.SEARCH_TRANSACTIONS },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_TRANSACTIONS,
        requires: [
          ADMIN_PERMISSIONS.SEARCH_TRANSACTIONS,
          ADMIN_PERMISSIONS.VIEW_TRANSACTIONS,
        ],
      },
    ],
  },
  {
    label: 'Transactions Status',
    name: 'transactions',
    permissions: [
      { label: 'manage', value: ADMIN_PERMISSIONS.MANAGE_TRANSACTION_STATUS },
    ],
  },
  {
    label: 'Transfers',
    name: 'transfers',
    permissions: [
      { label: 'view', value: ADMIN_PERMISSIONS.VIEW_TRANSFERS },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_TRANSFERS,
        requires: [ADMIN_PERMISSIONS.VIEW_TRANSFERS],
      },
    ],
  },
  {
    label: 'Contracts',
    name: 'contracts',
    permissions: [
      {
        label: 'view',
        value: ADMIN_PERMISSIONS.VIEW_CONTRACTS,
        requires: [ADMIN_PERMISSIONS.SEARCH_CONTRACTS],
      },
      { label: 'search', value: ADMIN_PERMISSIONS.SEARCH_CONTRACTS },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_CONTRACTS,
        requires: [
          ADMIN_PERMISSIONS.VIEW_CONTRACTS,
          ADMIN_PERMISSIONS.SEARCH_CONTRACTS,
        ],
      },
    ],
  },
  {
    label: 'Contract details',
    name: 'contract-details',
    permissions: [
      { label: 'view', value: ADMIN_PERMISSIONS.VIEW_CONTRACT_DETAILS },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_CONTRACT_DETAILS,
        requires: [ADMIN_PERMISSIONS.VIEW_CONTRACT_DETAILS],
      },
    ],
  },
  {
    label: 'Companies',
    name: 'companies',
    permissions: [
      {
        label: 'view',
        value: ADMIN_PERMISSIONS.VIEW_COMPANIES,
        requires: [ADMIN_PERMISSIONS.SEARCH_COMPANIES],
      },
      { label: 'search', value: ADMIN_PERMISSIONS.SEARCH_COMPANIES },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_COMPANIES,
        requires: [
          ADMIN_PERMISSIONS.VIEW_COMPANIES,
          ADMIN_PERMISSIONS.SEARCH_COMPANIES,
        ],
      },
    ],
  },
  {
    label: 'Users',
    name: 'users',
    permissions: [
      {
        label: 'view',
        value: ADMIN_PERMISSIONS.VIEW_USERS,
        requires: [ADMIN_PERMISSIONS.SEARCH_USERS],
      },
      { label: 'search', value: ADMIN_PERMISSIONS.SEARCH_USERS },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_USERS,
        requires: [
          ADMIN_PERMISSIONS.VIEW_USERS,
          ADMIN_PERMISSIONS.SEARCH_USERS,
        ],
      },
    ],
  },
  {
    label: 'Cards',
    name: 'cards',
    permissions: [
      {
        label: 'view',
        value: ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS,
      },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
        requires: [ADMIN_PERMISSIONS.VIEW_ADMIN_CARDS],
      },
    ],
  },
  {
    label: 'Auto Withdraw',
    name: 'auto-withdraw',
    permissions: [
      { label: 'view', value: ADMIN_PERMISSIONS.VIEW_AUTO_WITHDRAWS },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_AUTO_WITHDRAWS,
        requires: [ADMIN_PERMISSIONS.VIEW_AUTO_WITHDRAWS],
      },
    ],
  },
  {
    label: 'Template',
    name: 'template',
    permissions: [
      { label: 'view', value: ADMIN_PERMISSIONS.VIEW_TEMPLATES },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_TEMPLATES,
        requires: [ADMIN_PERMISSIONS.VIEW_TEMPLATES],
      },
    ],
  },
  {
    label: 'EOR',
    name: 'eor',
    subpages: [
      {
        label: 'Contracts',
        name: 'contracts',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_EOR_CONTRACTS },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_EOR_CONTRACTS,
            requires: [ADMIN_PERMISSIONS.VIEW_EOR_CONTRACTS],
          },
        ],
      },
      {
        label: 'Partners',
        name: 'partners',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_PARTNERS },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_PARTNERS,
            requires: [ADMIN_PERMISSIONS.VIEW_PARTNERS],
          },
        ],
      },
      {
        label: 'Calculator',
        name: 'calculator',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_CALCULATOR },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_CALCULATOR,
            requires: [ADMIN_PERMISSIONS.VIEW_CALCULATOR],
          },
        ],
      },
      {
        label: 'Quote Generator',
        name: 'quote-generator',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_QUOTE_GENERATOR },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_QUOTE_GENERATOR,
            requires: [ADMIN_PERMISSIONS.VIEW_QUOTE_GENERATOR],
          },
        ],
      },
      {
        label: 'Invoice Generator',
        name: 'invoice-generator',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_INVOICE_GENERATOR,
            requires: [ADMIN_PERMISSIONS.VIEW_INVOICE_GENERATOR],
          },
        ],
      },
    ],
  },
  {
    label: 'Insurance',
    name: 'insurance',
    subpages: [
      {
        label: 'Plans',
        name: 'plans',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_INSURANCE_REQUESTS },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_INSURANCE_REQUESTS,
            requires: [ADMIN_PERMISSIONS.VIEW_INSURANCE_REQUESTS],
          },
        ],
      },
      {
        label: 'Payments',
        name: 'payments',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_INSURANCE_PAYMENTS },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_INSURANCE_PAYMENTS,
            requires: [ADMIN_PERMISSIONS.VIEW_INSURANCE_PAYMENTS],
          },
        ],
      },
    ],
  },
  {
    label: 'Currency markup',
    name: 'currency-markup',
    permissions: [
      { label: 'view', value: ADMIN_PERMISSIONS.VIEW_CURRENCY_MARKUP },
      {
        label: 'manage',
        value: ADMIN_PERMISSIONS.MANAGE_CURRENCY_MARKUP,
        requires: [ADMIN_PERMISSIONS.VIEW_CURRENCY_MARKUP],
      },
    ],
  },
  {
    label: 'Impersonation',
    name: 'impersonation',
    permissions: [
      { label: 'impersonate', value: ADMIN_PERMISSIONS.USER_IMPERSONATION },
    ],
  },
  {
    label: 'Update 2FA',
    name: 'update-2fa',
    permissions: [
      { label: 'Update 2FA', value: ADMIN_PERMISSIONS.UPDATE_ADMIN_2FA },
    ],
  },
  {
    label: 'Bank Beneficiaries',
    name: 'bank-beneficiaries',
    permissions: [
      { label: 'Manage', value: ADMIN_PERMISSIONS.MANAGE_BANK_ACCOUNTS },
    ],
  },
  {
    label: 'Provider Rules',
    name: 'provider-rules',
    permissions: [
      { label: 'view', value: ADMIN_PERMISSIONS.VIEW_PROVIDER_RULES },
      {
        label: 'create',
        value: ADMIN_PERMISSIONS.CREATE_PROVIDER_RULES,
        requires: [ADMIN_PERMISSIONS.VIEW_PROVIDER_RULES],
      },
      {
        label: 'update',
        value: ADMIN_PERMISSIONS.UPDATE_PROVIDER_RULES,
        requires: [ADMIN_PERMISSIONS.VIEW_PROVIDER_RULES],
      },
      {
        label: 'delete',
        value: ADMIN_PERMISSIONS.DELETE_PROVIDER_RULES,
        requires: [ADMIN_PERMISSIONS.VIEW_PROVIDER_RULES],
      },
    ],
  },
  {
    label: 'Admin pages',
    name: 'admin-pages',
    subpages: [
      {
        label: 'All Admins',
        name: 'all-admins',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_ADMINS },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_ADMINS,
            requires: [ADMIN_PERMISSIONS.VIEW_ADMINS],
          },
        ],
      },
      {
        label: 'Admin Roles',
        name: 'admin-roles',
        permissions: [
          { label: 'view', value: ADMIN_PERMISSIONS.VIEW_ADMIN_PERMISSIONS },
          {
            label: 'manage',
            value: ADMIN_PERMISSIONS.MANAGE_ADMIN_PERMISSIONS,
            requires: [ADMIN_PERMISSIONS.VIEW_ADMIN_PERMISSIONS],
          },
        ],
      },
    ],
  },
]

function getDeps(list) {
  return list.reduce((prev, curr) => {
    if (curr?.subpages && curr.subpages.length > 0) {
      return { ...prev, ...getDeps(curr.subpages) }
    }

    curr.permissions.forEach(({ value, requires }) => {
      if (requires && requires.length > 0) {
        requires.forEach((r) => {
          prev[r] = [...(prev[r] || []), value]
        })
      }
    })

    return prev
  }, {})
}

export const PERMISSIONS_DEPENDENCIES = getDeps(PERMISSIONS_LIST)

export default PERMISSIONS_LIST
