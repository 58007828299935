import {
  AirplaneTakeoff,
  AppWindow,
  Asterisk,
  Briefcase,
  Cardholder,
  Certificate,
  Laptop,
  Lightbulb,
  OfficeChair,
  SquaresFour,
  Train,
  UsersFour,
  WifiHigh,
} from '@phosphor-icons/react'
import React from 'react'

export default function BillCategoryIcon({ category }) {
  const className = 'tw-size-5'
  const categotyName = category.toLowerCase()
  if (categotyName.includes('services')) {
    return <Briefcase className={className} />
  }
  if (categotyName.includes('equipment')) {
    return <Laptop className={className} />
  }
  if (categotyName.includes('marketing')) {
    return <Lightbulb className={className} />
  }
  if (categotyName.includes('travel')) {
    return <AirplaneTakeoff className={className} />
  }
  if (categotyName.includes('utilities')) {
    return <WifiHigh className={className} />
  }
  if (categotyName.includes('human resources')) {
    return <UsersFour className={className} />
  }
  if (categotyName.includes('office')) {
    return <OfficeChair className={className} />
  }
  if (categotyName.includes('software')) {
    return <AppWindow className={className} />
  }
  if (categotyName.includes('other')) {
    return <SquaresFour className={className} />
  }
  if (categotyName.includes('licensing')) {
    return <Certificate className={className} />
  }
  if (categotyName.includes('leasing')) {
    return <Cardholder className={className} />
  }
  if (categotyName.includes('transportation & logistics')) {
    return <Train className={className} />
  }
  return <Asterisk className={className} />
}
