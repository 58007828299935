import React from 'react'
import { Controller } from 'react-hook-form'

import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

export function ControlledUnstyledRadioList({
  options,
  name,
  control,
  error,
  transform,
  defaultValue,
  disabled,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      defaultValue={defaultValue}
      render={({ field: { value, onBlur, onChange } }) => {
        function handleChange(event) {
          const newValue = event.target.value
          onChange(newValue)
        }

        return (
          <UnstyledRadioList
            options={options}
            disabled={disabled}
            value={
              typeof transform?.input === 'function'
                ? transform.input(value)
                : value
            }
            onChange={(...args) =>
              typeof transform?.output === 'function'
                ? handleChange(transform.output(...args))
                : handleChange(...args)
            }
            onBlur={onBlur}
            error={error}
            name={name}
            {...props}
          />
        )
      }}
    />
  )
}

function UnstyledRadioList({
  error,
  options,
  name,
  onChange,
  onBlur,
  value: _checkValue,
  label,
  wrapperClassName,
  optionsClassName,
  optionClassName,
  optionInputClassName,
  optionLabelClassName,
  disabled,
}) {
  return (
    <fieldset className={wrapperClassName}>
      {!label ? null : <legend>{label}</legend>}

      <div className={optionsClassName}>
        {options.map((option) => {
          const id = `${name}-${option.value}`

          const checked = option.value === _checkValue

          function getClassNames(className) {
            if (typeof className === 'function') {
              return className({ checked })
            } else {
              return className
            }
          }

          return (
            <label
              key={option.value}
              className={getClassNames(optionClassName)}
            >
              <input
                type='radio'
                id={id}
                name={name}
                value={option.value}
                checked={checked}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                className={getClassNames(optionInputClassName)}
              />
              <div className={getClassNames(optionLabelClassName)}>
                {option.label}
              </div>
            </label>
          )
        })}
      </div>

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </fieldset>
  )
}
