import { HourglassHigh } from '@phosphor-icons/react'
import React from 'react'

import TabEmpty from '../Contract/components/tab/tab-empty'
import { AddTimeOff } from './add-time-off'

export default function TimeOffEmpty({ onSubmit, contract, timeOffs }) {
  return (
    <TabEmpty
      icon={
        <HourglassHigh size={250} color='var(--primary)' weight='duotone' />
      }
      title='No time off history'
      subtitle='Time off history will be shown here'
    >
      <AddTimeOff
        onSubmit={onSubmit}
        contract={contract}
        timeOffs={timeOffs?.list}
      />
    </TabEmpty>
  )
}
