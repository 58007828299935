import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import { IntegrationNav } from '../../../../components/integration-nav'
import { StyledH5 } from '../../../../components/Typo'
import TabContent from '../../../../components/ui/tabs'
import { useFetch } from '../../../../helpers/hooks'
import { getQBIntegrationData, setupQuickbooks } from '../../../../services/api'
import {
  qbSubmitAccountMapping,
  qbUpdatePreData,
  qbUpdateRequest,
} from '../../../../store/qb/actions'
import AccountMappingTab from './accountMappingTab'
import XeroLogs from './Logs'
import VendorsSettingTab from './vendorsSettingsTab'

const UpdateQBIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [submitted, setSubmitted] = useState(false)

  const dispatch = useDispatch()

  function onComplete(data) {
    const accountMapping = {}
    data.categories.forEach((c) => {
      accountMapping[c.category_id] = c.qb_id
    })
    dispatch(qbSubmitAccountMapping(accountMapping))
  }

  const {
    data: qbData,
    startFetch: fetchQbData,
    isLoading: loadingQbData,
  } = useFetch({
    action: getQBIntegrationData,
    successAction: qbUpdatePreData,
    autoFetch: true,
    onError: toastr.error,
    onComplete,
  })

  const request = useSelector((state) => state.qb.request)

  const update = useFetch({
    action: setupQuickbooks,
    onError: toastr.error,
    onComplete: () => {
      setTimeout(() => {
        fetchQbData(null, false)
      }, 800)
      dispatch(qbUpdateRequest(null))
    },
  })

  useEffect(() => {
    if (request && submitted) {
      update.startFetch(request)
      setSubmitted(false)
    }
  }, [request, submitted, update])

  useEffect(() => {
    return () => {
      dispatch(qbUpdateRequest(null))
    }
  }, [])

  function onUpdate() {
    setSubmitted(true)
  }

  const tabs = [
    {
      label: 'Accounts',
      component: (
        <AccountMappingTab
          data={qbData}
          loading={update.isLoading || loadingQbData}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      label: 'Team',
      component: (
        <VendorsSettingTab
          data={qbData}
          loading={update.isLoading || loadingQbData}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      label: 'Invoices',
      component: (
        <XeroLogs
          onSubmitted={onUpdate}
          onUpdate={() => fetchQbData(null, true)}
        />
      ),
    },
  ]
  return (
    <div className='page-content'>
      {loadingQbData ? (
        <Container style={{ minHeight: '100vh' }}>
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <>
          <div className='mb-0 mb-md-2 d-flex'>
            <StyledH5 min='22px' mid='28px' max='32px'>
              Quickbooks
            </StyledH5>
          </div>

          <IntegrationNav tabs className='nav-tabs-custom'>
            {tabs.map((nav, index) => (
              <NavItem key={`integrationNav:${index}`}>
                <NavLink
                  className={cn({ active: activeTab === index })}
                  onClick={() => {
                    setActiveTab(index)
                  }}
                >
                  {nav.label}
                </NavLink>
              </NavItem>
            ))}
          </IntegrationNav>
          <TabContent activeTab={activeTab} className='mt-3'>
            {tabs.map((t, i) => (
              <TabPane key={`tab:${i}`} tabId={i}>
                {t.component}
              </TabPane>
            ))}
          </TabContent>
        </>
      )}
    </div>
  )
}

export default UpdateQBIntegration
