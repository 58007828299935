import { yupResolver } from '@hookform/resolvers/yup'
import { PencilSimpleLine } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import RadioGroup from '../../../../components/RadioGroup'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import Shimmer from '../../../../components/ui/shimmer'
import { SideMenu } from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import {
  getDepositBankAccountsAdmin,
  getDepositPendingInvoicesAdmin,
  getRefundQuoteAdmin,
  refundTransactionAdmin,
  updatePartnerPaymentAdmin,
} from '../../../../services/api'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { getFullName } from '../../../../utils/get-full-name'
import { getContractStatusColor } from '../../../Contract/ContractList/ContractList'
import ContractStatus from '../../../Contract/ContractList/contract-status'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import { TransactionStatusBadge } from '../../../Transactions'
import { getAccountNumber } from '../../../Withdrawal/components/WithdrawMethodCard'
import { formatDate } from '../../../time-off/time-off-list'
import ContractRef from '../../components/ContractRef'
import { InvoiceStatusBadge } from '../eor/partner-invoices'

export function DepositsList({ deposits, updateDeposits, updateTotals }) {
  const [showSideMenu, setShowSideMenu] = React.useState({
    show: false,
    data: null,
  })

  const depositColumns = useMemo(
    () => [
      { Header: 'Deposit ID', accessor: 'id' },
      { Header: 'Client Name', accessor: 'client_name' },
      {
        Header: 'Employee/Contractor',
        accessor: 'employee_name',
        truncated: true,
        style: { maxWidth: 180 },
        Cell: ({ rowData }) => {
          return (
            <>
              <div className='text-truncate'>{rowData?.employee_name}</div>
            </>
          )
        },
      },
      {
        Header: 'Contract info',
        accessor: 'contract_status.name',
        Cell: ({ rowData }) => {
          return (
            <>
              <div className='tw-mb-1 tw-flex tw-gap-2'>
                <ContractRef
                  contractId={rowData?.contract_id}
                  isAdmin
                  withHash
                />
                <span className='tw-text-text-100'>
                  {rowData?.contract_type}
                </span>
              </div>

              <ContractStatus
                contract={{
                  status: rowData?.contract_status,
                  amended: rowData?.amended,
                }}
                badgeStatus={getContractStatusColor(rowData?.contract_status)}
              />
            </>
          )
        },
      },
      {
        Header: 'Deposit type',
        accessor: 'deposit_type',
        Cell: ({ cellData }) => {
          return <BadgeX status='secondary'>{cellData}</BadgeX>
        },
      },
      { Header: 'Transaction ID', accessor: 'transaction_id' },
      {
        Header: 'Amount paid',
        accessor: 'amount',
        Cell: ({ rowData }) => {
          const formatter = getCurrencyFormatter(rowData?.currency?.code)
          return formatter.format(rowData?.amount)
        },
      },
      {
        Header: 'Deposit status',
        accessor: 'status',
        Cell: ({ cellData }) => <DepositTransactionStatus status={cellData} />,
      },
      {
        Header: 'Partner',
        accessor: 'partner.email',
        Cell: ({ rowData }) => getFullName(rowData?.partner),
      },
      {
        Header: 'Paid to partner',
        accessor: 'partner_payment_amount',
        Cell: ({ rowData }) => {
          return (
            <UpdatePartnerPayment
              rowData={rowData}
              refreshData={() => {
                updateDeposits?.()
                updateTotals?.()
              }}
            />
          )
        },
      },
      {
        Header: 'Actions',
        Cell: ({ rowData }) => {
          if (rowData?.status !== 'Paid') {
            return null
          }
          return (
            <Button
              size='sm'
              onClick={() => setShowSideMenu({ show: true, data: rowData })}
            >
              Process refunds
            </Button>
          )
        },
      },
    ],
    [updateDeposits, updateTotals],
  )

  function closeSideMenu() {
    setShowSideMenu({ show: false })
  }

  return (
    <>
      <DepositDetailsSlider
        showSideMenu={showSideMenu}
        closeSideMenu={closeSideMenu}
        updateDeposits={updateDeposits}
      />

      <DataTable
        responsive
        columns={depositColumns}
        data={deposits}
        bodyCellClassName='!tw-py-3.5'
        className='tw-text-sm'
      />
    </>
  )
}

function DepositDetailsSlider({
  showSideMenu = { show: false, data: null },
  closeSideMenu,
  updateDeposits,
}) {
  const [refundMethod, setRefundMethod] = useState()

  const companyId = showSideMenu?.data?.company_id

  const { data: depositBankAccounts, isLoading: loadingDepositBankAccounts } =
    useFetch(
      {
        action: getDepositBankAccountsAdmin,
        autoFetch: showSideMenu.show,
        withAdminAccess: true,
        body: { company_id: companyId },
      },
      [showSideMenu.show, companyId],
    )

  const {
    data: depositPendingInvoices,
    isLoading: loadingDepositPendingInvoices,
  } = useFetch(
    {
      action: getDepositPendingInvoicesAdmin,
      autoFetch: showSideMenu.show,
      withAdminAccess: true,
      body: { company_id: companyId },
    },
    [showSideMenu.show, companyId],
  )

  const { startFetch: refundTransaction } = useFetch({
    action: refundTransactionAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          data?.message || 'An error occurred while refunding the deposit',
          'Error',
        )
      } else {
        toastr.success('Transaction refunded successfully', 'Success')
        closeSideMenu?.()
        updateDeposits?.()
      }
    },
  })

  const { startFetch: getRefundQuote, isLoading: loadingRefundQuote } =
    useFetch({
      action: getRefundQuoteAdmin,
      withAdminAccess: true,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(
            data?.message ||
              'An error occurred while creating the refund quote',
            'Error',
          )
        } else {
          refundTransaction({ quote_id: data?.quote_id })
        }
      },
      onError: (error) => {
        toastr.error(
          error || 'An error occurred while creating the refund quote',
          'Error',
        )
      },
    })

  function handleRefund() {
    const body = {
      deposit_id: showSideMenu?.data?.id,
      method_id: refundMethod?.value === 'balance' ? 12 : 3,
    }

    if (refundMethod?.value === 'bank') {
      body.account_id = depositBankAccounts?.bank_accounts[0]?.id
    }

    getRefundQuote(body)
  }

  const actionLoading = loadingRefundQuote

  const loading = loadingDepositBankAccounts || loadingDepositPendingInvoices

  function isDefaultSuffix(method) {
    let suffix = ''

    switch (method) {
      case 'balance': {
        suffix =
          depositBankAccounts?.is_bank_refund_enabled === 0
            ? '(Client default)'
            : ''
        break
      }
      case 'bank': {
        suffix =
          depositBankAccounts?.is_bank_refund_enabled === 1
            ? '(Client default)'
            : ''
        break
      }
      default: {
        suffix = ''
      }
    }

    return <span className='tw-font-bold'>{suffix}</span>
  }

  const bankAccounts = depositBankAccounts?.bank_accounts
  const { currency, details, holder_name: holderName } = bankAccounts?.[0] ?? {}
  const accountNumber = details && getAccountNumber(details)

  const bankOptionLabel = [holderName, accountNumber, currency]
    .filter(Boolean)
    .join(' - ')

  const refundOptions = [
    {
      value: 'balance',
      label: <>{isDefaultSuffix('balance')} RemotePass Balance</>,
    },
    bankAccounts?.length > 0 && {
      value: 'bank',
      label: <>{isDefaultSuffix('bank')} Refund bank account</>,
      description: bankOptionLabel,
    },
  ].filter(Boolean)

  const usdFormatter = getCurrencyFormatter()

  return (
    <SideMenu
      onClose={() => {
        setRefundMethod(null)
        closeSideMenu?.()
      }}
      isOpen={showSideMenu.show}
      className='tw-max-w-[800px] tw-p-4'
    >
      <h3>Deposit details</h3>

      {loading ? (
        <Loader minHeight='max(50vh, 300px)' />
      ) : (
        <>
          <h4>Unpaid COR invoices</h4>
          {depositPendingInvoices?.unpaid_cor_transactions.length <= 0 ? (
            <TabEmpty subtitle='No invoices found' minHeight='100px' />
          ) : (
            <DataTable
              columns={corInvoiceColumns}
              data={depositPendingInvoices?.unpaid_cor_transactions}
              className='mb-3'
              responsive
            />
          )}

          <h4>Unpaid EOR invoices</h4>
          {depositPendingInvoices?.unpaid_eor_invoices.length <= 0 ? (
            <TabEmpty subtitle='No invoices found' minHeight='100px' />
          ) : (
            <DataTable
              columns={eorInvoiceColumns}
              data={depositPendingInvoices?.unpaid_eor_invoices}
              className='mb-3'
              responsive
            />
          )}

          <h4>Refund info</h4>
          {[
            { label: 'Deposit ID', value: showSideMenu?.data?.id },
            {
              label: 'Amount',
              value: usdFormatter.format(showSideMenu?.data?.amount),
            },
          ].map(({ label, value }, index) => (
            <div
              className='tw-mb-2 tw-flex tw-items-center tw-gap-2 tw-text-sm'
              key={index}
            >
              <span className='tw-min-w-[75px] tw-text-text-50'>{label}</span>
              <span className='tw-text-text-90'>{value}</span>
            </div>
          ))}

          <h4 className='mt-3'>Refund options</h4>
          <RadioGroup
            name='refund-method'
            options={refundOptions}
            onChange={(option) => setRefundMethod(option)}
            value={refundMethod?.value}
            className='font-size-14'
          />

          <div className='tw-mt-3 tw-flex tw-justify-end'>
            <Button
              onClick={handleRefund}
              disabled={actionLoading || !refundMethod}
              loading={actionLoading}
            >
              Refund
            </Button>
          </div>
        </>
      )}
    </SideMenu>
  )
}

const corInvoiceColumns = [
  { Header: 'Ref', accessor: 'ref' },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ rowData }) => {
      const formatter = getCurrencyFormatter(rowData?.currency?.code)
      return formatter.format(rowData?.amount)
    },
  },
  { Header: 'Transaction Type', accessor: 'type.name' },
  {
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({ rowData }) => {
      return formatDate(new Date(rowData?.created_at * 1000))
    },
  },
]

const eorInvoiceColumns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Submitted at', accessor: 'submitted_at' },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ rowData }) => {
      const formatter = getCurrencyFormatter()
      return formatter.format(rowData?.amount)
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ rowData }) => {
      return <InvoiceStatusBadge status={rowData?.status.name} />
    },
  },
  {
    Header: 'Payment Status',
    accessor: 'payment_status',
    Cell: ({ rowData }) => {
      return (
        <TransactionStatusBadge order={{ status: rowData?.payment_status }} />
      )
    },
  },
]

const partnerPaymentFormId = 'partner-payment-form'
function UpdatePartnerPayment({ rowData, refreshData }) {
  const [editing, setEditing] = useState(false)

  const formatter = getCurrencyFormatter()

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      partner_payment_amount: rowData?.partner_payment_amount,
    },
    resolver: yupResolver(
      yup.object().shape({
        partner_payment_amount: yup.number().nullable(),
      }),
    ),
  })

  function toggle() {
    setEditing((editing) => {
      if (!editing) {
        setValue('partner_payment_amount', rowData?.partner_payment_amount)
      }
      return !editing
    })
  }

  const {
    startFetch: updatePartnerPayment,
    isLoading: loadingUpdatePartnerPayment,
  } = useFetch({
    action: updatePartnerPaymentAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          data?.message || 'An error occurred while updating the payment',
          'Error',
        )
      } else {
        toastr.success('Payment updated successfully', 'Success')
        toggle()
        refreshData?.()
      }
    },
  })

  function onSubmit(data) {
    updatePartnerPayment({
      deposit_id: rowData?.id,
      amount: data?.partner_payment_amount,
    })
  }

  return (
    <>
      <button
        onClick={toggle}
        className='border tw-flex tw-items-center tw-gap-3 tw-rounded tw-p-1 tw-transition-colors tw-duration-75 hover:tw-bg-surface-20'
      >
        <PencilSimpleLine size={16} />
        <span className='tw-text-base'>
          {formatter.format(rowData?.partner_payment_amount)}
        </span>
      </button>

      <Modal isOpen={editing} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update partner payment</ModalHeader>
        <ModalBody>
          <form
            className='tw-flex tw-gap-2'
            id={partnerPaymentFormId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledCurrencyInput
              label='Partner payment amount'
              control={control}
              name='partner_payment_amount'
              placeholder='Partner payment amount'
              prefix={rowData?.currency?.symbol}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            color='light'
            outline
            disabled={loadingUpdatePartnerPayment}
          >
            Cancel
          </Button>
          <Button
            formId={partnerPaymentFormId}
            type='submit'
            disabled={loadingUpdatePartnerPayment}
            loading={loadingUpdatePartnerPayment}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function DepositTotals({ totals, loading }) {
  const formatter = getCurrencyFormatter()

  const totalDeposits = totals?.total_deposits
  const depositsWithPartners = totals?.deposits_with_partners
  const depositsWithRp = totals?.deposits_with_rp

  return (
    <div className='tw-mb-2 tw-grid tw-gap-3 md:tw-grid-cols-3 xl:tw-grid-cols-4'>
      <TotalDisplay
        label='Total Deposits'
        loading={loading}
        value={formatter.format(totalDeposits)}
        bold
      />
      <TotalDisplay
        loading={loading}
        label='Deposits With RemotePass'
        value={formatter.format(depositsWithRp)}
      />
      <TotalDisplay
        loading={loading}
        label='Deposits With Partners'
        value={formatter.format(depositsWithPartners)}
      />
    </div>
  )
}

function TotalDisplay({ label, value, bold, loading }) {
  if (loading) {
    return (
      <div className='tw-overflow-hidden tw-rounded-lg tw-border tw-border-surface-30 tw-bg-white tw-px-2 tw-py-3 sm:tw-p-3'>
        <Shimmer height={16} />
        <Shimmer height={28} width={150} className='tw-mt-1' />
      </div>
    )
  }

  return (
    <div className='tw-overflow-hidden tw-rounded-lg tw-border tw-border-surface-30 tw-bg-white tw-px-2 tw-py-3 sm:tw-p-3'>
      <dt
        className={cx(
          'tw-truncate tw-text-xs tw-text-text-50',
          bold ? 'tw-font-semibold' : 'tw-font-normal',
        )}
      >
        {label}
      </dt>
      <dd
        className={cx(
          'tw-mb-0 tw-mt-1 tw-text-xl tw-font-semibold tw-tracking-tight',
          bold ? 'tw-text-text-90' : 'tw-text-text-70',
        )}
      >
        {value}
      </dd>
    </div>
  )
}

export function DepositTransactionStatus({ status }) {
  const colors = {
    Paid: 'info',
    'Refund Processing': 'warning',
    Processing: 'warning',
    Refunded: 'success',
  }
  const color = colors[status] || 'secondary'

  return (
    <BadgeX status={color} size='md'>
      {status}
    </BadgeX>
  )
}
