import { format } from 'date-fns'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row } from 'reactstrap'
import { ActionsDropdown } from 'ui'

import { contractStatusKey } from '.'
import DataTable from '../../../../../components/ui/data-table'
import { userTypes } from '../../../../../helpers/enum'
import { useFetch } from '../../../../../helpers/hooks'
import {
  archiveAdminContract,
  archiveContract,
  getContractDetail,
} from '../../../../../services/api'
import { setContractToDuplicate } from '../../../../../store/contract/actions'
import { getContractStatusColor } from '../../../../Contract/ContractList/ContractList'
import ClientCell from '../../../../Contract/ContractList/client-cell'
import ContractStatus from '../../../../Contract/ContractList/contract-status'
import UserCell from '../../../../Contract/ContractList/user-cell'
import ContractRef from '../../../components/ContractRef'
import { FilterField } from '../../cards/filter-field'

export function CorContractList({ contracts, onUpdate, isArchived }) {
  const isAdmin = location.pathname.startsWith('/admin')

  const columns = [
    {
      Header: 'Contract ID',
      accessor: 'ref',
      className: 'sticky-col col-sticky',
      headerClassName: 'sticky-col col-sticky',
      style: { '--col-width': '132.5px', '--col-left': '0px' },
      Cell: ({ cellData }) => {
        return (
          <ContractRef
            contractId={cellData}
            isAdmin={isAdmin}
            target={isAdmin ? '_blank' : null}
          />
        )
      },
    },
    isAdmin && {
      Header: 'Created on',
      accessor: 'created_at',
      Cell: ({ cellData: createdAt }) => {
        return format(new Date(createdAt), 'MM/dd/yyyy hh:mm')
      },
    },
    { Header: 'Role', accessor: 'name' },
    { Header: 'Type', accessor: 'type' },
    {
      Header: 'Rate',
      accessor: 'total',
      Cell: ({ cellData }) => {
        const total = cellData?.split('/') ?? []

        return (
          <span className='h6 rp-font-normal'>
            {total[0]} <span className='text-secondary'>/{total[1]}</span>
          </span>
        )
      },
    },
    isAdmin && { Header: 'Company', accessor: 'company' },
    {
      Header: 'Name',
      Cell: ({ rowData }) => {
        return <UserCell isAdmin item={rowData} onUpdate={onUpdate} />
      },
    },
    isAdmin && {
      Header: 'Client',
      Cell: ({ rowData }) => (
        <ClientCell isAdmin item={rowData} onUpdate={onUpdate} />
      ),
    },
    {
      Header: 'Status',
      Cell: ({ rowData: item }) => {
        return (
          <ContractStatus
            contract={item}
            badgeStatus={getContractStatusColor(item?.status)}
          />
        )
      },
    },
    {
      Header: '',
      Cell: ({ rowData }) => {
        return (
          <ActionsCell
            rowData={rowData}
            isAdmin={isAdmin}
            onUpdate={onUpdate}
            isArchived={isArchived}
          />
        )
      },
    },
  ].filter(Boolean)

  return (
    <div style={{ '--custom-min-h': '220px' }}>
      <DataTable
        data={contracts}
        columns={columns}
        responsive
        cssModule={{ 'table-responsive': 'table-responsive custom-min-h' }}
      />
    </div>
  )
}

function ActionsCell({ rowData, isAdmin, onUpdate, isArchived }) {
  const user = useSelector((state) => state?.Account?.user)
  const dispatch = useDispatch()

  const { startFetch: archive, isLoading: archiving } = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate()
      if (!isAdmin) {
        window.analytics.track('Archived a contract', {
          contract_id: body?.contract.ref,
          contract_status: body?.contract?.status?.name,
          contract_type: body?.contract?.type,
        })
      }
    },
  })

  const { startFetch: duplicateContract, isLoading: duplicatingContract } =
    useFetch({
      action: getContractDetail,
      onComplete: (data) => {
        dispatch(setContractToDuplicate(data))
        history.push('/contract/create')
      },
    })

  const canArchive = rowData?.status?.id !== 4 || isAdmin
  //  && hasAccess(permissions.archiveContracts)

  const canDuplicate = rowData?.type !== 'Full Time' && !isAdmin
  //  && hasAccess(permissions.archiveContracts)

  const actionsOptions = []

  if (canArchive) {
    actionsOptions.push({
      label: isArchived ? 'Unarchive' : 'Archive',
      onClick: () => {
        archive({
          contract_id: rowData.id,
          status: isArchived ? 0 : 1,
          contract: rowData,
        })
      },
    })
  }

  if (canDuplicate && user?.type !== userTypes.CONTRACTOR) {
    actionsOptions.push({
      label: 'Duplicate',
      onClick: () => {
        duplicateContract({ id: rowData?.ref })
      },
    })
  }

  const isLoading = archiving || duplicatingContract

  return (
    actionsOptions?.length > 0 && (
      <ActionsDropdown data={actionsOptions} loading={isLoading} />
    )
  )
}

export function CorFilters({
  filters,
  onChange,
  contractStatuses,
  companyList,
}) {
  const filtersFields = [
    {
      name: contractStatusKey,
      label: 'Status',
      options: contractStatuses,
      type: 'select',
      isClearable: true,
    },
    {
      name: 'company_id',
      label: 'Company',
      options: companyList,
      type: 'select',
      isClearable: true,
    },
    { name: 'archived', label: 'Archived', type: 'toggle', colSize: 3 },
    {
      name: 'search',
      type: 'text',
      label: 'Search',
      placeholder: 'Search by name, email, or contract ID',
      colSize: 6,
    },
  ]

  return (
    <Container fluid className='px-0'>
      <Row className='mx-n3'>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}
