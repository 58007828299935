import React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'

import customStyles from '../../../../components/Common/react-select-custom-styles'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { isProduction } from '../../../../utils/isProduction'

export function FilterField({
  name,
  label,
  options,
  type,
  value,
  minDate,
  maxDate,
  isClearable,
  onChange,
  onClear,
  placeholder,
  colSize = 4,
}) {
  switch (type) {
    case 'email':
    case 'text': {
      return (
        <Col xs={12} md={colSize} className='px-2' key={name}>
          <FormGroup>
            {!label ? null : <Label htmlFor={name}>{label}</Label>}
            <Input
              type={type}
              placeholder={placeholder}
              id={name}
              value={value}
              onChange={(e) => onChange(name, e.target.value)}
            />
          </FormGroup>
        </Col>
      )
    }
    case 'select': {
      return (
        <Col xs={12} md={colSize} className='px-2' key={name}>
          <FormGroup>
            {!label ? null : <Label htmlFor={name}>{label}</Label>}
            <CustomSelect
              styles={customStyles}
              value={value}
              inputId={name}
              onChange={(option, actionType) => {
                onChange(name, option, { action: actionType.action })
              }}
              options={options}
              isClearable={isClearable}
            />
          </FormGroup>
        </Col>
      )
    }
    case 'date': {
      return (
        <Col xs={12} md={colSize} className='px-2' key={name}>
          <FormGroup>
            {!label ? null : <Label htmlFor={name}>{label}</Label>}
            <CustomDatePicker
              placeholder={placeholder}
              handleOnChange={onChange}
              minDate={minDate}
              clearable={isClearable}
              handleClear={onClear}
              maxDate={maxDate}
              value={value}
              name={name}
            />
          </FormGroup>
        </Col>
      )
    }
    case 'toggle': {
      return (
        <Col
          xs={12}
          md={colSize}
          className='d-flex align-items-end px-2'
          key={name}
        >
          <FormGroup check style={{ marginBottom: '1rem' }}>
            <Input
              type='checkbox'
              placeholder={placeholder}
              id={name}
              name={name}
              checked={value}
              onChange={(e) => onChange(name, e.target.checked)}
            />
            {!label ? null : <Label htmlFor={name}>{label}</Label>}
          </FormGroup>
        </Col>
      )
    }
    default: {
      return !isProduction()
        ? `Type ${type} not supported in FilterField`
        : null
    }
  }
}
