import React from 'react'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import Table from 'reactstrap/lib/Table'

import { getCurrencyFormatter } from '../../../../utils/formatters/currency'

export default function BeneficiariesModal({
  dependents,
  item,
  toggle,
  isOpen,
  currency,
}) {
  const formatter = getCurrencyFormatter(currency)

  return (
    <Modal isOpen={isOpen} toggle={toggle} role='dialog' centered>
      <ModalBody>
        <h5 className='mb-4 text-secondary'>
          List of dependents for{' '}
          <span className='text-body'>
            {item?.user?.first_name} {item?.user?.last_name}
          </span>
        </h5>
        <Table className='mb-0'>
          <tr>
            <th>Dependent</th>
            <th>Age</th>
            <th>Price</th>
          </tr>
          {dependents.map(({ age, price }, index) => {
            const isContractor = index === 0
            return (
              <tr key={index}>
                <td className='h6 mb-0'>
                  {isContractor ? 'Contractor' : `Dependent ${index}`}
                </td>
                <td>{age}</td>
                <td>{formatter.format(price)}</td>
              </tr>
            )
          })}
        </Table>
      </ModalBody>
    </Modal>
  )
}
