import React from 'react'
import { Card, Container } from 'reactstrap'
import QuotationForm from '../../../Contract/RequestContract/QuotationForm'
import { useFetch } from '../../../../helpers/hooks'
import { generateAdminFulltimeQuotation } from '../../../../services/api'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'

const QuotationGenerator = () => {
  const generate = useFetch({
    action: generateAdminFulltimeQuotation,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, 'Quotation.pdf')
    },
  })
  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_QUOTE_GENERATOR,
  )

  return (
    <div className='page-content'>
      <h1 style={{ marginBottom: '2rem' }}>Quote generator</h1>

      <Card className='mt-3'>
        <Container fluid className='p-0 m-0'>
          <QuotationForm
            forGenerator
            onSubmit={(v) => {
              generate.startFetch(v)
            }}
            readOnly={!hasEditPermission}
          />
        </Container>
      </Card>
    </div>
  )
}

export default QuotationGenerator
