import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

// i18n
import i18n from '../../../i18n'
import { withTranslation } from 'react-i18next'

// falgs
import usFlag from '../../../assets/images/flags/us.jpg'
import frFlag from '../../../assets/images/flags/french.jpg'
import arFlag from '../../../assets/images/flags/arabic.png'

const LanguageDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [flag, setFlag] = useState(usFlag)
  const [lng, setLng] = useState('English')

  useEffect(() => {
    const savedLang = i18n.language
    console.log('savedLang', savedLang)
    changeLanguageAction(savedLang)
  }, [])

  function changeLanguageAction(lng) {
    // set language as i18n
    i18n.changeLanguage(lng)

    if (lng === 'fr') {
      setFlag(frFlag)
      setLng('French')
    } else if (lng === 'en') {
      setFlag(usFlag)
      setLng('English')
    } else if (lng === 'ar') {
      setFlag(arFlag)
      setLng('Arabic')
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className='d-inline-block'
      >
        <DropdownToggle className='btn header-item waves-effect' tag='button'>
          <img
            src={flag}
            alt=''
            className='mr-1 d-inline'
            style={{ height: 16 }}
          />
          <span className='align-middle'>{props.t(lng)}</span>
        </DropdownToggle>
        <DropdownMenu className='language-switch' right>
          <DropdownItem
            tag='a'
            href='#'
            onClick={() => changeLanguageAction('en')}
            className='notify-item d-flex align-items-center gap-8'
          >
            <img src={usFlag} alt='' style={{ height: 12 }} />
            <span className='align-middle'>{props.t('English')}</span>
          </DropdownItem>
          <DropdownItem
            tag='a'
            href='#'
            onClick={() => changeLanguageAction('fr')}
            className='notify-item d-flex align-items-center gap-8'
          >
            <img src={frFlag} alt='' style={{ height: 12 }} />
            <span className='align-middle'>{props.t('French')}</span>
          </DropdownItem>{' '}
          <DropdownItem
            tag='a'
            href='#'
            onClick={() => changeLanguageAction('ar')}
            className='notify-item d-flex align-items-center gap-8'
          >
            <img src={arFlag} alt='' style={{ height: 12 }} />
            <span className='align-middle'>{props.t('Arabic')}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(LanguageDropdown)
