export const TIMEOFF_EVENTS = {
  ADD_POLICY: 'Add time off policy',
  SAVED_POLICY: 'Save time off policy',
  EDITED_POLICY: 'Edit time off policy',
  ASSIGNED_WORKER: 'Assign policy / worker',
  UNASSIGNED_WORKER: 'Unassign policy / worker',
  CHANGED_POLICY: 'Change policy',
  REQUESTED: 'Request time off',
  APPROVE: 'Approve time off',
}
