import { SAPLING_REQUEST, SAPLING_UPDATE_CONTRACTORS } from './actionTypes'

export const SaplingRequest = (data) => {
  return {
    type: SAPLING_REQUEST,
    data,
  }
}

export const SaplingUpdateContractors = (payload) => {
  return {
    type: SAPLING_UPDATE_CONTRACTORS,
    payload,
  }
}
