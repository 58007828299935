import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { WarningOctagon } from '@phosphor-icons/react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/ui/button'

export function QuitConfirmModal(props) {
  const { isOpen, toggle } = props
  const history = useHistory()

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <WarningOctagon size={25} className='tw-text-systemRed-100' />
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <span className='tw-text-base tw-font-bold'>Unsaved changes</span>
        <span className='tw-text-s tw-text-secondary'>
          You have made some changes in this page, how do you want to proceed?
        </span>
      </ModalBody>
      <ModalFooter>
        <Button
          className='tw-mb-2 !tw-bg-white'
          textClassName='!tw-text-black'
          color='surface-30'
          outline={true}
          onClick={toggle}
        >
          Stay on this page
        </Button>
        <Button
          color='danger'
          onClick={() => {
            history.push('/settings/automations')
            toggle()
          }}
        >
          Quit without saving
        </Button>
      </ModalFooter>
    </Modal>
  )
}
