/** Managing localStorage */

/** Setting an item in localStorage */
function setItem(key, value) {
  localStorage.setItem(key, value)
}

/** Getting an item from localStorage */
function getItem(key) {
  return localStorage.getItem(key)
}

export { setItem, getItem }
