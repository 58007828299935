import React from 'react'
import EORDataCollectionForm from './form'
import { Card, Col, Container, Row } from 'reactstrap'
import ModalHeader from '../../../components/ModalHeader'
import { useHistory } from 'react-router-dom'
import Alert from '../../../components/ui/alert'
import { useFetch } from '../../../helpers/hooks'
import {
  getEmployeeDataCollection,
  submitEmployeeDataCollection,
} from '../../../services/api'
import toastr from 'toastr'
import Shimmer from '../../../components/ui/shimmer'
import NoContent from '../../../components/ui/no-content'
import { useSelector } from 'react-redux'
import ControlledInput from '../../../components/ControlledInputNew'
import { useForm } from 'react-hook-form'
import ControlledSelect from '../../../components/ControlledSelectNew'

const statuses = {
  pending: 'Pending review',
  declined: 'Declined',
  approved: 'Approved',
}

function SectionHeader({ title }) {
  return <h4 className='tw-mb-6'>{title}</h4>
}

function EmployeeJoiningFormPage() {
  const history = useHistory()
  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/activity')
  }

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const { data: form, isLoading } = useFetch({
    autoFetch: true,
    action: getEmployeeDataCollection,
  })

  const { control } = useForm({
    defaultValues: {
      ...userProfile,
      nationality: userProfile?.Country_of_Citizenship?.name,
    },
  })

  const { startFetch: submitForm, isLoading: isSubmissionLoading } = useFetch({
    action: submitEmployeeDataCollection,
    onComplete: () => {
      handleReturn()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const isPending = form?.status === statuses.pending
  const isDeclined = form?.status === statuses.declined
  const isApproved = form?.status === statuses.approved

  return (
    <Container fluid className='px-0'>
      <ModalHeader action={handleReturn}></ModalHeader>
      <h1 className='tw-my-8 tw-text-center'>{form?.form?.name}</h1>
      <Card className='tw-p-6' style={{ maxWidth: 820, margin: '1.5rem auto' }}>
        <Alert color='info' className='tw-mb-4 tw-items-center'>
          To change the grayed information, please go to your profile settings.
        </Alert>
        <SectionHeader title='Personal Information' />
        <Row className='tw-mb-6'>
          <Col className='tw-mb-6 md:tw-pr-3' sm='6'>
            <ControlledInput
              control={control}
              name='first_name'
              disabled
              label='First Name'
              placeholder='First Name'
            />
          </Col>
          <Col className='md:tw-pl-3' sm='6'>
            <ControlledInput
              control={control}
              name='last_name'
              disabled
              label='Last Name'
              placeholder='Last Name'
            />
          </Col>
        </Row>
        <Row className='tw-mb-6'>
          <Col className='tw-mb-6 md:tw-pr-3' sm='6'>
            <ControlledInput
              control={control}
              name='middle_name'
              disabled
              label='Middle Name (optional)'
              placeholder='Middle Name (optional)'
            />
          </Col>
          <Col className='md:tw-pl-3' sm='6'>
            <ControlledInput
              control={control}
              name='birth_date'
              disabled
              label='Birth Date'
              placeholder='Birth Date'
            />
          </Col>
        </Row>
        <Row className='tw-mb-6'>
          <Col className='tw-mb-6 md:tw-pr-3' sm='6'>
            <ControlledInput
              control={control}
              name='city'
              disabled
              label='Place of birth'
              placeholder='Place of birth'
            />
          </Col>
          <Col className='md:tw-pl-3' sm='6'>
            <ControlledSelect
              control={control}
              name='nationality'
              disabled
              options={[
                {
                  label: userProfile?.Country_of_Citizenship?.name,
                  value: userProfile?.Country_of_Citizenship?.name,
                  flag: userProfile?.Country_of_Citizenship?.svg
                    ? '/' + userProfile?.Country_of_Citizenship?.svg
                    : null,
                },
              ]}
              label='Nationality'
            />
          </Col>
        </Row>
        <Row className='tw-mb-6'>
          <Col className='tw-mb-6 md:tw-pr-3' sm='6'>
            <ControlledInput
              control={control}
              name='phone'
              disabled
              label='Phone number'
              placeholder='Phone number'
            />
          </Col>
          <Col className='md:tw-pl-3' sm='6'>
            <ControlledInput
              control={control}
              name='email'
              disabled
              label='Email address'
              placeholder='Email address'
            />
          </Col>
        </Row>

        <hr className='!tw-mx-[-1.5rem]' />
        <SectionHeader title='Address' />

        <Row className='tw-mb-6'>
          <Col sm='12'>
            <ControlledInput
              control={control}
              name='address'
              disabled
              label='Address'
              placeholder='Address'
            />
          </Col>
        </Row>
        <Row className='tw-mb-6'>
          <Col className='tw-mb-6 md:tw-pr-3' sm='6'>
            <ControlledInput
              control={control}
              name='zip_code'
              disabled
              label='Postal / Zip code'
              placeholder='Postal / Zip code'
            />
          </Col>
          <Col className='md:tw-pl-3' sm='6'>
            <ControlledInput
              control={control}
              name='city'
              disabled
              label='City'
              placeholder='City'
            />
          </Col>
        </Row>
      </Card>
      <Card style={{ maxWidth: 820, margin: '0 auto' }}>
        {isLoading ? (
          <div className='tw-p-6'>
            <Shimmer className='tw-mb-2 !tw-w-full' />
            <Shimmer className='tw-mb-2 !tw-w-full' />
            <Shimmer className='tw-mb-2 !tw-w-full' />
          </div>
        ) : !form || form?.is_enabled ? (
          <NoContent>There are no data forms waiting for review </NoContent>
        ) : (
          <>
            {isDeclined ? (
              <Alert
                className='tw-mx-6 !tw-mb-0 tw-mt-6 tw-items-center'
                color='danger'
              >
                {form?.decline_reason}
              </Alert>
            ) : null}
            {isPending ? (
              <Alert
                className='tw-mx-6 !tw-mb-0 tw-mt-6 tw-items-center'
                color='warning'
              >
                {`Form is under review. You can't edit the form until it's
                approved`}
              </Alert>
            ) : null}
            {isApproved ? (
              <Alert
                className='tw-mx-6 !tw-mb-0 tw-mt-6 tw-items-center'
                color='success'
              >
                The data has been approved
              </Alert>
            ) : null}
            <EORDataCollectionForm
              onComplete={(data) => submitForm(data)}
              form={form?.form}
              isSubmissionLoading={isSubmissionLoading}
              readOnly={isPending || isApproved}
              canDeleteFiles
            />
          </>
        )}
      </Card>
    </Container>
  )
}

export default EmployeeJoiningFormPage
