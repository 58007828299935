import { DownloadSimple, Eye, Swap } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import toastr from 'toastr'

import { cn, ExpandIcon } from 'ui'
import emptyMilestoneImage from '../../../assets/images/milestones-empty.png'
import noInvoicesImage from '../../../assets/images/noinvoices.svg'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import SearchBar from '../../../components/SearchBar'
import TableComp from '../../../components/Table/TableComp'
import TableHeader from '../../../components/Table/TableH'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Shimmer from '../../../components/ui/shimmer'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  checkExportStatus,
  downloadContractAllInvoices,
  downloadInvoiceV2,
  getContractInvoices,
  getContractInvoicesMonths,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { InvoiceActions } from '../../review-center/review-details'
import { DetailsInfoList } from '../../review-center/review-layout-details-components'
import { AddMilestoneModal } from '../components/add-milestone-modal'
import { TimelineType2 } from './item-timeline'

function getMonthDate(date) {
  const myDate = new Date(date)
  const month = myDate.getMonth() + 1
  const year = myDate.getFullYear()

  return `${year}-${String(month).padStart(2, '0')}`
}
export function Invoices({
  contract,
  updateContract,
  setShowConfirmationModal,
  setEndedModal,
}) {
  const [addMilestone, setAddMilestone] = useState(false)
  const [search, setSearch] = useState('')
  const [expanded, setExpanded] = useState(-1)
  const thisMonth = getMonthDate(new Date())

  const [pageFilters, setPageFilters] = useState({
    month: thisMonth,
    search_key: '',
  })
  const userIsPartner = useSelector(
    (state) => state.userProfile?.userProfile?.is_partner,
  )
  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  const {
    data: contractInvoices,
    isLoading: contractInvoicesLoading,
    startFetch: updateContractInvoices,
    completed: contractInvoicesCompleted,
  } = useFetch(
    {
      action: getContractInvoices,
      autoFetch: !!pageFilters?.month?.value && !!contract?.id,
      body: {
        id: contract?.id,
        search_key: pageFilters?.search_key,
        month: pageFilters?.month?.value,
      },
      onError: (err) => {
        toastr.error(err)
      },
    },
    [pageFilters?.month?.value, pageFilters?.search_key, contract?.id],
  )
  const {
    data: months,
    isLoading: isLoadingMonths,
    completed: monthsCompleted,
  } = useFetch({
    action: getContractInvoicesMonths,
    autoFetch: true,
    body: { id: contract?.id },
    onComplete: (data) => {
      if (data?.length > 0) {
        setPageFilters((prev) => ({
          ...prev,
          month: data?.[0],
        }))
      }
    },
  })

  const dataLoading =
    !contractInvoicesCompleted ||
    !monthsCompleted ||
    contractInvoicesLoading ||
    isLoadingMonths

  const hasViewInvoices = hasAccess(permissions.ViewInvoices)
  function handleSearch(query) {
    setSearch(query)
    setPageFilters((filters) => ({ ...filters, search_key: query }))
  }
  if (!hasViewInvoices) {
    return (
      <Card className='rp-shadow-2 tw-p-4'>
        <PrimaryAlert>
          You do not have permission to view {PERMISSION_GROUP.INVOICES.name}.
          Please contact your administrator.
        </PrimaryAlert>
      </Card>
    )
  }

  return (
    <>
      {!addMilestone ? null : (
        <AddMilestoneModal
          contract={contract}
          isOpen={addMilestone}
          toggle={() => setAddMilestone((open) => !open)}
          onMilestoneAdded={updateContract}
        />
      )}

      {contract?.milestones?.length <= 0 ? (
        <NoContent
          customBottom={
            user?.type === userTypes.CONTRACTOR ||
            (hasAccess(permissions.CreateMilestones) &&
              contract?.can_approve &&
              contract?.type !== CONTRACT_TYPES.FULL_TIME) ||
            [5, 6, 7].includes(contract?.status?.id) ? (
              <AddMilestoneDropdown
                contract={contract}
                setShowConfirmationModal={setShowConfirmationModal}
                setEndedModal={setEndedModal}
                setAddMilestone={setAddMilestone}
              />
            ) : null
          }
          headline={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? 'No Invoice added'
              : 'No Milestone added'
          }
          subtitle='All work submissions will be shown here and will be subject to approval by the client.'
          image={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? noInvoicesImage
              : emptyMilestoneImage
          }
          actionTitle={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? 'Add Invoice'
              : 'Add Milestone'
          }
          onAction={() => {
            setAddMilestone(true)
          }}
          advanced
        />
      ) : (
        <Card className='mb-0 rp-shadow-2'>
          <TableHeader
            title={
              contract?.type === CONTRACT_TYPES.FULL_TIME || userIsPartner
                ? 'Invoices'
                : CONTRACT_TYPES.MILESTONES
            }
            leftSide={
              user?.type === userTypes.CONTRACTOR ||
              contract?.type !== CONTRACT_TYPES.FULL_TIME ||
              [5, 6, 7].includes(contract?.status?.id) ? (
                userIsPartner ? (
                  <Button onClick={() => setAddMilestone(true)}>
                    Add Invoice
                  </Button>
                ) : (
                  <AddMilestoneDropdown
                    contract={contract}
                    setShowConfirmationModal={setShowConfirmationModal}
                    setEndedModal={setEndedModal}
                    setAddMilestone={setAddMilestone}
                  />
                )
              ) : null
            }
          />
          <div className='tw-flex tw-flex-col tw-gap-3 tw-px-5 tw-pb-4 max-md:tw-flex-wrap sm:tw-flex-row'>
            {isLoadingMonths ? (
              <>
                <div className='tw-grid tw-flex-grow tw-grid-cols-12 tw-gap-3 sm:tw-min-w-96'>
                  <Shimmer
                    width='100%'
                    height='42px'
                    className='tw-col-span-12 sm:tw-col-span-5'
                  />
                  <Shimmer
                    width='100%'
                    height='42px'
                    className='tw-col-span-12 sm:tw-col-span-7'
                  />
                </div>
                <Shimmer
                  width='100%'
                  height='42px'
                  className='tw-col-span-12 tw-max-w-48'
                />
              </>
            ) : (
              <>
                <div className='tw-grid tw-flex-grow tw-grid-cols-12 tw-gap-y-3 sm:tw-min-w-96'>
                  <CustomSelect
                    options={months}
                    value={pageFilters.month}
                    onChange={(newDate) => {
                      setPageFilters((prev) => ({ ...prev, month: newDate }))
                    }}
                    withSearch
                    wrapperClassName='tw-col-span-12 sm:tw-col-span-5 lg:tw-col-span-4 [&_.RS-Control\_\_control]:sm:tw-rounded-r-none [&_.RS-Control\_\_control]:sm:tw-border-r-0 [&_.RS-Control\_\_control]:tw-h-[42px]'
                  />

                  <SearchBar
                    inputClassName='sm:!tw-rounded-l-none'
                    onQueryChanged={(query) => handleSearch(query)}
                    query={search}
                    className='tw-col-span-12 sm:tw-col-span-7 lg:tw-col-span-8'
                  />
                </div>

                <DownloadInvoicesButton
                  invoices={contractInvoices}
                  filters={pageFilters}
                  contract={contract}
                  icon={<DownloadSimple />}
                />
              </>
            )}
          </div>

          {dataLoading ? (
            <Loader minHeight='max(50vh, 550px)' />
          ) : (
            <>
              <div className='md:tw-hidden'>
                {contractInvoices?.map((invoice, key) => {
                  return (
                    <MilestoneMobileCard
                      key={'_invoice_' + key}
                      invoice={invoice}
                      onUpdateSuccess={() => {
                        updateContractInvoices()
                      }}
                    />
                  )
                })}
              </div>

              <div className='table-with-dropdown tw-mb-1 tw-hidden md:tw-block'>
                <TableComp className='table-centered'>
                  <thead className='tw-text-black'>
                    <tr>
                      <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'></th>
                      <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                        Invoice
                      </th>
                      <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                        Total
                      </th>
                      <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                        Created on
                      </th>
                      <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {contractInvoices?.map((invoice, key) => {
                      return (
                        <InvoiceCard
                          key={'_invoice_' + key}
                          invoice={invoice}
                          expanded={expanded === key}
                          onExpand={() => {
                            if (expanded === key) {
                              setExpanded(-1)
                            } else {
                              setExpanded(key)
                            }
                          }}
                          updateContractInvoices={updateContractInvoices}
                        />
                      )
                    })}
                  </tbody>
                </TableComp>
              </div>
            </>
          )}
        </Card>
      )}
    </>
  )
}

function AddMilestoneDropdown({
  contract,
  setShowConfirmationModal,
  setEndedModal,
  setAddMilestone,
}) {
  const [menu, setMenu] = useState(false)

  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  const history = useHistory()

  return (
    <PermissionTooltip
      area={PERMISSION_GROUP.MILESTONES.name}
      showing={!hasAccess(permissions.CreateMilestones)}
      id='add-milestone-tooltip'
    >
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (contract.status.id === CONTRACT_STATUS.TERMINATED.value) {
            setShowConfirmationModal(true)
          } else if (contract.status.id === CONTRACT_STATUS.ENDED.value) {
            setEndedModal(true)
          } else {
            if (contract?.type === CONTRACT_TYPES.FULL_TIME) {
              history.push({
                pathname: '/scan-invoice',
                state: { contractId: contract?.id, ...contract },
              })
            } else if (user?.type === userTypes.CONTRACTOR) {
              setMenu(!menu)
            } else {
              setAddMilestone(true)
            }
          }
        }}
      >
        <DropdownToggle
          tag={Button}
          block
          disabled={
            !(
              user?.type === userTypes.CONTRACTOR ||
              hasAccess(permissions.CreateMilestones) ||
              contract?.can_approve
            ) || [5, 6, 7].includes(contract?.status?.id)
          }
        >
          {contract?.type === CONTRACT_TYPES.FULL_TIME
            ? 'Add Invoice'
            : 'Add milestone'}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setAddMilestone(true)}>
            Add it Manually
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              history.push({
                pathname: '/scan-invoice',
                state: { contractId: contract?.id, ...contract },
              })
            }}
          >
            Upload Invoice
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </PermissionTooltip>
  )
}

function MilestoneMobileCard({ invoice, key, onUpdateSuccess }) {
  const formatter = getCurrencyFormatter(invoice?.currency_trans)

  return (
    <div
      key={key}
      className='tw-mx-4 tw-mb-4 tw-rounded tw-border tw-border-surface-30 tw-bg-white'
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-p-4'>
        <MilestoneSideMenu
          invoice={invoice}
          onUpdateSuccess={onUpdateSuccess}
        />
      </div>

      <div className='tw-flex tw-flex-col tw-gap-5 tw-py-4 tw-text-sm'>
        <div className='tw-flex tw-items-center tw-justify-between tw-px-4'>
          <span>Invoice ref</span>

          {invoice?.invoice_ref}
        </div>

        <div className='tw-flex tw-items-center tw-justify-between tw-px-4'>
          <span>Total</span>

          <span className='tw-font-semibold tw-text-black'>
            {formatter.format(invoice?.total)}
          </span>
        </div>
      </div>
    </div>
  )
}

export function MilestoneSideMenu({
  invoice,
  onReplace,
  isContractor,
  isReplacing,
  onUpdateSuccess,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function toggle() {
    setIsMenuOpen((isOpen) => !isOpen)
  }

  const formatter = getCurrencyFormatter(invoice.currency?.code)

  const firstWork = invoice?.works?.[0]
  const workIsPendingApproval = firstWork?.status?.id === 2

  const noActions = !workIsPendingApproval && !isContractor

  function onUpdate() {
    toggle()
    onUpdateSuccess?.()
  }

  return (
    <>
      <Button
        color='link'
        onClick={toggle}
        type='button'
        className='!tw-px-0 !tw-py-1'
        icon={<Eye size={20} className='tw-text-primary' />}
      >
        Details
      </Button>

      <SideMenu
        onClose={toggle}
        isOpen={isMenuOpen}
        className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr]'
      >
        <SideMenuHeader toggle={toggle} className='!tw-items-start'>
          <div>
            <p className='font-size-20 tw-mb-0 tw-font-semibold'>
              Invoice details
            </p>
            <p className='font-size-14 tw-mb-0 tw-text-text-60'>
              {invoice?.invoice_ref}
            </p>
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <DetailsInfoList
            className='!tw-p-0'
            items={[
              {
                label: 'Created on',
                value: format(invoice?.invoice_date * 1000, 'MM/dd/yyyy'),
              },
              { label: 'Amount', value: formatter.format(invoice?.total) },
              {
                label: 'Invoice document',
                className: '!tw-py-1',
                value: <DownloadInvoiceAttachment item={invoice} />,
              },
            ]}
          />

          <h4 className='tw-mt-6 tw-font-semibold'>Summary</h4>
          <WorkAttributes invoice={invoice} />

          <TimelineType2 flowTimeline={invoice?.timeline} />
        </SideMenuBody>

        {noActions ? null : (
          <SideMenuFooter className='tw-justify-between'>
            {isContractor ? (
              <Button
                icon={<Swap />}
                className='tw-flex-1'
                onClick={onReplace}
                loading={isReplacing}
                disabled={isReplacing}
              >
                Replace
              </Button>
            ) : workIsPendingApproval ? (
              <InvoiceActions
                onUpdateSuccess={onUpdate}
                onDeclineSuccess={onUpdate}
                item={{ ...invoice, id: invoice.works?.[0]?.id }}
              />
            ) : null}
          </SideMenuFooter>
        )}
      </SideMenu>
    </>
  )
}

export function DownloadInvoiceAttachment({ item }) {
  const { startFetch: downloadAttachment, isLoading: downloadingAttachment } =
    useFetch({
      action: downloadInvoiceV2,
      onComplete: (data) => {
        openFileV2(data, {
          download: true,
          name: `invoice-${item?.invoice_ref}.pdf`,
          type: 'application/pdf',
        })
      },
    })

  function handleDownloadInvoice() {
    window.analytics.track('Clicked download invoice', {
      invoice_id: item?.invoice_ref,
    })

    downloadAttachment({ ref: item.invoice_ref, invoiceToken: item.token })
  }

  return (
    <Button
      onClick={handleDownloadInvoice}
      disabled={downloadingAttachment}
      loading={downloadingAttachment}
      color='link'
      iconRight={<Eye size={20} />}
      className='!tw-px-0 !tw-py-2.5'
    >
      Download
    </Button>
  )
}

function InvoiceCard({ invoice, onExpand, expanded, updateContractInvoices }) {
  const formatter = getCurrencyFormatter(invoice?.currency_trans)

  return (
    <>
      <tr>
        <td className='!tw-px-6'>
          {!onExpand ? null : (
            <ExpandIcon isExpanded={expanded} onClick={onExpand} />
          )}
        </td>
        <td className='!tw-px-6'>{invoice?.invoice_ref}</td>
        <td className='!tw-px-6 tw-text-sm tw-text-black'>
          {formatter.format(invoice?.total)}
        </td>
        <td className='!tw-px-6'>
          {format(invoice?.invoice_date * 1000, 'MM/dd/yyyy')}
        </td>

        <td className='!tw-px-6'>
          <MilestoneSideMenu
            invoice={invoice}
            onUpdateSuccess={updateContractInvoices}
          />
        </td>
      </tr>
      {expanded &&
        React.Children.toArray(
          invoice?.works?.map((work, index) => (
            <tr className='tw-bg-primary-10' key={`work-${index}`}>
              <td className='!tw-px-6' />
              <td className='!tw-px-6'>
                <div>
                  <p className='tw-text-sm tw-text-text-100'>{work?.name}</p>
                  <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
                    {work?.details}
                  </p>
                </div>
              </td>
              <td className='!tw-px-6 tw-text-sm tw-text-text-80' colSpan={3}>
                {formatter.format(work?.amount)}
              </td>
            </tr>
          )),
        )}
    </>
  )
}
function WorkAttributes({ invoice, className }) {
  const formatter = getCurrencyFormatter(invoice?.currency_trans)
  return (
    <div
      className={cn(
        'tw-mt-2 tw-flex tw-flex-col tw-gap-1 empty:tw-hidden',
        className,
      )}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
        <div>
          <p className='font-size-14 mb-0 tw-font-semibold tw-text-text-100'>
            Total
          </p>
        </div>
        <div className='font-size-14 !tw-px-6 tw-text-text-80'>
          {formatter.format(invoice?.total)}
        </div>
      </div>
      {invoice?.works?.map((attribute, key) => (
        <div
          key={'_attribute_' + key}
          className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'
        >
          <div>
            <p className='font-size-14 mb-0 tw-font-semibold tw-text-text-100'>
              {attribute?.name}
            </p>
            <p className='font-size-14 mb-0 mb-0 tw-text-text-80'>
              {attribute?.details}
            </p>
          </div>
          <div className='font-size-14 !tw-px-6 tw-text-text-80'>
            {formatter.format(attribute?.amount)}
          </div>
        </div>
      ))}
    </div>
  )
}

const DownloadInvoicesButton = ({
  invoices,
  filters,
  contract,
  icon,
  className,
}) => {
  const [loading, setLoading] = useState(false)

  const ExportStatuses = {
    FAILED: 'failed',
    READY: 'ready',
    PENDING: 'pending',
  }

  const checkDownloadStatus = useFetch(
    {
      action: checkExportStatus,
      autoFetch: false,
      onComplete: (data) => {
        if (data?.status === ExportStatuses.FAILED) {
          toastr.error('Failed to export')
          setLoading(false)
        } else if (data?.status === ExportStatuses.READY) {
          const link = document.createElement('a')
          link.href = data?.url
          link.setAttribute(
            'download',
            sendExportRequest.data?.path || 'AllInvoices.zip',
          )
          document.body.appendChild(link)
          link.click()

          setLoading(false)
        }
      },
      onError: (err) => {
        setLoading(false)
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  const sendExportRequest = useFetch(
    {
      action: downloadContractAllInvoices,
      autoFetch: false,
      body: {
        month: filters?.month?.value,
        search_key: filters?.search_key,
        id: contract?.id,
      },
      onError: (err) => {
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
      if (sendExportRequest.data?.id) {
        checkDownloadStatus.startFetch({
          exportId: sendExportRequest.data?.id,
        })
      }
    }, 5000)

    if (checkDownloadStatus.data?.url) {
      clearInterval(timeIntervalId)
    }

    return () => clearInterval(timeIntervalId)
  }, [sendExportRequest.data, checkDownloadStatus.data])

  const startDownloadProcess = () => {
    setLoading(true)
    sendExportRequest.startFetch()
  }

  return invoices?.length === 0 ? null : (
    <Button
      onClick={() => startDownloadProcess()}
      disabled={loading}
      loading={loading}
      icon={icon ?? null}
      className={className}
    >
      Download invoices
    </Button>
  )
}
