import cx from 'classnames'
import React from 'react'

import { getData } from '../../../../components/ui/data-table'

export default function CardsList({ columns, itemHeader, data, className }) {
  return (
    <div className={cx('d-flex flex-column gap-12', className)}>
      {data?.map((row, index) => {
        return (
          <div className='border border-gray-b rounded' key={index}>
            {!itemHeader ? null : (
              <div className='p-3 border-bottom border-gray-b d-flex justify-content-between align-items-center'>
                {itemHeader(row, index)}
              </div>
            )}

            <div className='d-flex flex-column gap-12 p-3'>
              {columns?.map((col, index) => {
                const data = getData(row, col?.accessor)

                const child = col.Cell ? (
                  <col.Cell
                    cellData={data}
                    index={index}
                    rowData={row}
                    accessor={col?.accessor}
                  />
                ) : (
                  data
                )

                return (
                  <div
                    className='d-flex justify-content-between align-items-center gap-4 font-size-14'
                    key={index}
                  >
                    <div className='text-slate-500'>{col.Header}</div>
                    <div>{child}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
