import React from 'react'
import Toggle from './Forms/Toggle/Toggle'

export default function ApprovalOverride({ shouldOverride, onOverride }) {
  return (
    <div className='tw-mt-4 tw-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-p-4'>
      <div>
        <div className='tw-font-semibold'>Override approvals</div>
        <div className='tw-text-text-80'>
          Approve the item and bypass the flow.
        </div>
      </div>
      <Toggle marginRight='' check={shouldOverride} change={onOverride} />
    </div>
  )
}
