import React, { useEffect, useMemo, useState } from 'react'
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import {
  addMondayDotComTemplate,
  editMondayDotComTemplate,
  getMondayDotComTemplates,
  submitMondayDotComTemplateData,
} from '../../../../../services/api'
import { useFetch } from '../../../../../helpers/hooks'
import { format } from 'date-fns'
import CustomSelect from '../../../../../components/Forms/CustomSelectNew/CustomSelect'
import { Check } from '@phosphor-icons/react'
import Button from '../../../../../components/ui/button'
import toastr from 'toastr'

export function SubmitMondayDotComTemplate({ contract, refetchContract }) {
  const [onboardingTemplate, setOnboardingTemplate] = useState(null)
  const [openEditTemplateModal, setOpenEditTemplateModal] = useState(null)

  const {
    startFetch: submitMondayTemplateData,
    isLoading: isSubmittingMondayTemplate,
  } = useFetch({
    action: submitMondayDotComTemplateData,
    onComplete: () => {
      toastr.success('Data submitted')
      if (typeof refetchContract === 'function') {
        refetchContract({ id: contract?.id }, false)
      }
    },
    withAdminAccess: true,
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { data: mondayTemplates, startFetch: fetchMondayDotComTemplates } =
    useFetch({
      action: getMondayDotComTemplates,
      initResult: [],
      body: {
        type: 'onboarding',
      },
      withAdminAccess: true,
      autoFetch: true,
      onError: (error) => {
        toastr.error(error)
      },
    })

  const mondayTemplateOptions = useMemo(
    () =>
      mondayTemplates?.map((v) => ({
        ...v,
        label: v.name,
        value: v.id,
      })),
    [mondayTemplates],
  )

  const lastSubmittedMondayTemplate = useMemo(
    () => contract?.monday_onboarding_data_submission_history?.at(-1),
    [contract?.monday_onboarding_data_submission_history],
  )

  useEffect(() => {
    if (lastSubmittedMondayTemplate) {
      setOnboardingTemplate(
        mondayTemplateOptions?.find(
          (m) => m.id === lastSubmittedMondayTemplate?.monday_template_id,
        ),
      )
    }
  }, [mondayTemplateOptions, lastSubmittedMondayTemplate])

  return (
    <>
      <Row className='tw-border-t tw-border-t-secondary-30'>
        <Col>
          <Label className='text-gray-h font-size-16 my-4' tag='div'>
            Onboarding template
          </Label>
          <Row>
            <Col md={6} sm={12} className='mb-3'>
              <CustomSelect
                options={mondayTemplateOptions}
                value={onboardingTemplate}
                label='Select a template'
                onChange={(t) => setOnboardingTemplate(t)}
                classNamePrefix=''
              />
              <div className='tw-flex tw-flex-row tw-flex-wrap tw-items-baseline tw-gap-x-1'>
                <Button
                  text='Add'
                  color='transparent'
                  textClassName='tw-text-primary'
                  className='p-0 mt-2'
                  onClick={() => {
                    setOpenEditTemplateModal('add')
                  }}
                />
                or
                <Button
                  text='Edit'
                  color='transparent'
                  textClassName='tw-text-primary'
                  className='p-0 mt-2'
                  onClick={() => {
                    if (!onboardingTemplate) {
                      toastr.error('Select a template to edit')
                      return
                    }
                    setOpenEditTemplateModal('edit')
                  }}
                />
                a selected template
              </div>
            </Col>
            <Col md={6} sm={12} className='mb-3'>
              {lastSubmittedMondayTemplate ? (
                <>
                  <div
                    title={`Submitted by ${lastSubmittedMondayTemplate?.submitted_by}`}
                    className='py-3 tw-flex tw-items-center tw-justify-center tw-gap-1 tw-rounded tw-bg-primary-20'
                  >
                    <Check
                      size={20}
                      className='tw-rounded-full tw-bg-primary-20 tw-text-primary-100'
                    />
                    <span className='rp-font-medium font-size-14 tw-text-primary'>
                      {`Data submitted on ${format(
                        new Date(lastSubmittedMondayTemplate?.date),
                        'dd/MM/yyyy',
                      )}`}
                    </span>
                  </div>
                  <Button
                    text='Re-submit'
                    color='transparent'
                    textClassName='tw-text-primary'
                    className='p-0 mt-2'
                    type='button'
                    onClick={() => {
                      submitMondayTemplateData({
                        contract_id: contract?.id,
                        monday_template_id: onboardingTemplate?.value,
                      })
                    }}
                    loading={isSubmittingMondayTemplate}
                  />
                </>
              ) : (
                <Button
                  size='lg'
                  className='tw-h-fit tw-w-full'
                  textClassName='font-size-14 py-1'
                  text='Submit Data'
                  type='button'
                  loading={isSubmittingMondayTemplate}
                  onClick={() => {
                    submitMondayTemplateData({
                      contract_id: contract?.id,
                      monday_template_id: onboardingTemplate?.value,
                    })
                  }}
                  disabled={!onboardingTemplate}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {openEditTemplateModal && (
        <ModalAddOrEditMondayDotComTemplate
          isOpen={openEditTemplateModal}
          isEdit={openEditTemplateModal === 'edit'}
          toggle={() => {
            setOpenEditTemplateModal(null)
            if (!lastSubmittedMondayTemplate && onboardingTemplate) {
              setOnboardingTemplate(null)
            }
          }}
          type='onboarding'
          selectedTemplate={onboardingTemplate}
          fetchMondayDotComTemplates={fetchMondayDotComTemplates}
        />
      )}
    </>
  )
}

export function ModalAddOrEditMondayDotComTemplate({
  isOpen,
  toggle,
  isEdit,
  fetchMondayDotComTemplates,
  selectedTemplate = null,
  type,
}) {
  const [templateName, setTemplateName] = useState(
    isEdit ? selectedTemplate?.label : '',
  )
  const [templateIdentifier, setTemplateIdentifier] = useState(
    isEdit ? selectedTemplate?.identifier : '',
  )

  const { startFetch: editMondayTemplate, isLoading: isEditingMondayTemplate } =
    useFetch({
      action:
        selectedTemplate && isEdit
          ? editMondayDotComTemplate
          : addMondayDotComTemplate,
      onComplete: () => {
        toastr.success('Template updated/added')
        fetchMondayDotComTemplates?.({ type })
        toggle?.()
      },
      withAdminAccess: true,
      onError: (error) => {
        toastr.error(error)
      },
    })

  const submitTemplate = () => {
    if (!templateIdentifier || !templateName) {
      toastr.error('Template name and identifier is required')
      return
    }
    editMondayTemplate({
      name: templateName,
      id: selectedTemplate?.id,
      identifier: templateIdentifier,
      type,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose>
      <ModalHeader toggle={toggle}>
        {isEdit ? 'Edit' : 'Add'} monday.com template
      </ModalHeader>

      <ModalBody className='p-0'>
        <div className='px-3 py-4'>
          <FormGroup>
            <Label for='template-name' className='font-size-14'>
              Template name
              <span className='text-danger font-size-16 ml-1'>*</span>
            </Label>
            <Input
              type='text'
              className='form-control'
              id='template-name'
              onChange={(e) => {
                setTemplateName(e.target.value)
              }}
              value={templateName}
            />
            <Label for='template-id' className='font-size-14 tw-pt-3'>
              Template id
              <span className='text-danger font-size-16 ml-1'>*</span>
            </Label>
            <Input
              type='number'
              placeholder='Enter id number'
              className='form-control'
              id='template-id'
              onChange={(e) => {
                setTemplateIdentifier(e.target.value)
              }}
              disabled={isEdit}
              value={templateIdentifier}
            />
          </FormGroup>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={isEditingMondayTemplate}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            submitTemplate()
          }}
          disabled={isEditingMondayTemplate}
          loading={isEditingMondayTemplate}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}
