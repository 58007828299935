import { CheckCircle, WarningOctagon } from '@phosphor-icons/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { Spinner } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import ControlledSelect from '../../../../../components/ControlledSelect'
import Button from '../../../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../../../core/config/contract-types'
import { useFetch } from '../../../../../helpers/hooks'
import { getContractDetailAdmin } from '../../../../../services/api'
import { getDefaultData, sendData } from '../../../../../services/webhooks'
import { getFullName } from '../../../../../utils/get-full-name'

const STATES = {
  GETTING_CONTRACT_DATA: 'GETTING_CONTRACT_DATA',
  GETTING_DEFAULT_DATA: 'GETTING_DEFAULT_DATA',
  SEND_DATA_TO_WEBHOOK: 'SEND_DATA_TO_WEBHOOK',
  WAITING_FOR_INPUT: 'WAITING_FOR_INPUT',
  ERROR: 'ERROR',
  ERROR_CONTRACT_NOT_FULL_TIME: 'ERROR_CONTRACT_NOT_FULL_TIME',
  OPERATION_SUCCESSFUL: 'OPERATION_SUCCESSFUL',
}

export function SendContractData({ contractId }) {
  const { data: defaultData, isLoading: gettingDefaultData } = useQuery({
    queryKey: 'defaultData',
    queryFn: () => {
      return getDefaultData()
    },
    select: (data) => data?.data,
  })

  const defaultDataHasOptions = Object.values(defaultData ?? {}).some(
    (value) => Array.isArray(value) && value.length > 0,
  )
  const defaultDataFields = Object.entries(defaultData ?? {}).filter(
    ([, value]) => Array.isArray(value) && value.length > 0,
  )

  const schema = defaultDataHasOptions
    ? defaultDataFields.reduce(
        (acc, [key]) => ({
          ...acc,
          [key]: yup.string().required(),
        }),
        {},
      )
    : {}

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(yup.object().shape(schema)),
  })

  const {
    data: contractData,
    error: contractDataError,
    isLoading: gettingContractData,
  } = useFetch(
    {
      autoFetch: !!contractId,
      action: getContractDetailAdmin,
      withAdminAccess: true,
      body: { id: contractId },
    },
    [contractId],
  )

  const {
    mutate: sendContractDataToWebhook,
    isPending: isSendDataPending,
    isError: isSendDataError,
    data: sendDataResponse,
  } = useMutation({
    mutationFn: (contractData) => {
      return sendData(null, contractData)
    },
  })

  const alreadySentData =
    sendDataResponse || isSendDataPending || isSendDataError

  let state = STATES.GETTING_CONTRACT_DATA
  if (gettingContractData) {
    state = STATES.GETTING_CONTRACT_DATA
  } else if (gettingDefaultData) {
    state = STATES.GETTING_DEFAULT_DATA
  } else if (defaultDataHasOptions && !alreadySentData) {
    state = STATES.WAITING_FOR_INPUT
  } else if (contractDataError) {
    state = STATES.ERROR
  } else if (contractData?.type !== CONTRACT_TYPES.FULL_TIME) {
    state = STATES.ERROR_CONTRACT_NOT_FULL_TIME
  } else if (isSendDataError) {
    state = STATES.ERROR
  } else if (isSendDataPending) {
    state = STATES.SEND_DATA_TO_WEBHOOK
  } else {
    state = STATES.OPERATION_SUCCESSFUL
  }

  function onSubmit(values) {
    const immigrationRequired = contractData?.work_visa === 1

    const webHookData = {
      contract_id: contractData.ref,
      company_name: contractData.company_name,
      country_code: contractData.employee?.working_from_country?.iso2,
      employment_type: immigrationRequired ? 'E' : 'L',
      employee_email: contractData.employee?.email,
      employee_name: getFullName(contractData.employee),
      rp_admin_link: `${process.env.REACT_APP_OG_URL}/admin/contract-detail?id=${contractData.ref}`,
      ...defaultData,
      start_date: contractData.start_date,
      ...values,
    }

    sendContractDataToWebhook(webHookData)
  }

  return (
    <div className='tw-min-h-[calc(100vh-var(--header-height))] tw-p-6'>
      <div className='tw-flex tw-items-center tw-gap-4'>
        <h6 className='tw-mb-0'>
          <StateMessage state={state} />
        </h6>

        <div className='tw-flex tw-min-h-6 tw-items-center tw-justify-center'>
          {[STATES.ERROR, STATES.ERROR_CONTRACT_NOT_FULL_TIME].includes(
            state,
          ) ? (
            <WarningOctagon
              size={21}
              className='tw-text-systemRed-90'
              weight='bold'
            />
          ) : [STATES.OPERATION_SUCCESSFUL].includes(state) ? (
            <CheckCircle
              size={21}
              className='tw-text-systemGreen-90'
              weight='bold'
            />
          ) : ![
              STATES.GETTING_CONTRACT_DATA,
              STATES.SEND_DATA_TO_WEBHOOK,
            ].includes(state) ? null : (
            <Spinner size='sm' color='secondary' />
          )}
        </div>
      </div>

      {gettingDefaultData || sendDataResponse || isSendDataError ? null : (
        <form onSubmit={handleSubmit(onSubmit)} className='tw-mt-4 tw-max-w-lg'>
          <fieldset
            className='tw-flex tw-flex-col tw-gap-2'
            disabled={isSendDataPending}
          >
            {defaultDataFields.map(([key, value]) => {
              return (
                <ControlledSelect
                  key={key}
                  control={control}
                  name={key}
                  inputId={key}
                  options={value.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                />
              )
            })}

            <Button type='submit' size='sm' className='tw-self-start'>
              Submit data
            </Button>
          </fieldset>
        </form>
      )}
    </div>
  )
}

function StateMessage({ state }) {
  switch (state) {
    case STATES.GETTING_CONTRACT_DATA: {
      return <>Getting contract data</>
    }
    case STATES.GETTING_DEFAULT_DATA: {
      return <>Getting default data</>
    }
    case STATES.WAITING_FOR_INPUT: {
      return <>Waiting for input</>
    }
    case STATES.SEND_DATA_TO_WEBHOOK: {
      return <>Sending data to webhook</>
    }
    case STATES.ERROR: {
      return <>Error</>
    }
    case STATES.OPERATION_SUCCESSFUL: {
      return <>Operation successful</>
    }
    case STATES.ERROR_CONTRACT_NOT_FULL_TIME: {
      return <>Contract is not a a full time contract</>
    }
    default: {
      return null
    }
  }
}
