import React, { useEffect } from 'react'
import { Card } from 'reactstrap'
import {
  CaretDown,
  CaretUp,
  EnvelopeSimple,
  Play,
  TrashSimple,
} from '@phosphor-icons/react'
import { cn } from 'ui'
import { useFormContext } from 'react-hook-form'
import { ActionEmailForm } from './action-email-form'

export function Action(props) {
  const {
    id,
    index,
    stepsField: { fields: steps, remove },
  } = props
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const focused = watch('focusedNode') === id
  const hasError = errors?.steps?.[index]
  const actions = steps.filter((s) => s.type === 'action')
  const isFirstAction = actions?.[0]?.id === id
  useEffect(
    () => (!isFirstAction ? setValue('focusedNode', id) : () => {}),
    [setValue, id, isFirstAction],
  )

  return (
    <div
      className={cn(
        'tw-rounded-md',
        hasError ? 'tw-border-2 tw-border-red' : undefined,
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
      )}
    >
      <Card className='!tw-mb-0 tw-flex tw-flex-col'>
        <div className='tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'>
          <div
            className={cn(
              'tw-rounded tw-bg-secondary-20 tw-p-2',
              focused && 'tw-bg-primary-20',
              hasError && 'tw-bg-red-20',
            )}
          >
            <Play
              size={25}
              className={cn(
                'tw-text-secondary-100',
                focused && 'tw-text-primary',
                hasError && 'tw-text-red',
              )}
            />
          </div>
          <div className='tw-flex tw-flex-1 tw-flex-col'>
            <span className='tw-font-medium'>
              {isFirstAction ? 'Then do this' : 'And do this'}
            </span>
            <span>Select action</span>
          </div>
          {!isFirstAction ? (
            <button
              className='tw-rounded tw-bg-secondary-20 tw-p-2'
              onClick={() => remove(index)}
            >
              <TrashSimple size={25} className='tw-text-secondary' />
            </button>
          ) : null}
          <button
            onClick={() => setValue('focusedNode', focused ? null : id)}
            className='tw-rounded tw-bg-primary-20 tw-p-2'
          >
            {focused ? (
              <CaretUp size={25} className='tw-text-primary' />
            ) : (
              <CaretDown size={25} className='tw-text-primary' />
            )}
          </button>
        </div>
        {focused ? (
          <>
            <div className='tw-h-0.5 tw-bg-secondary-20' />
            <div className='tw-flex tw-flex-col tw-gap-3 tw-p-4'>
              <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
                <EnvelopeSimple size={20} className='tw-text-primary' />
                <span className='tw-font-bold'>Customize your email</span>
              </div>
              <ActionEmailForm index={index} />
            </div>
          </>
        ) : null}
      </Card>
    </div>
  )
}
