import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig } from './api'

export function getOrganizationChart(token, _, extra) {
  return api.get('organization_charts/hierarchy', getConfig(token, extra))
}

export function getDepartmentsData(token, _, extra) {
  return api.get('organization_charts/departments', getConfig(token, extra))
}

export function getValidManagers(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get('contract/valid_managers' + sp, getConfig(token, extra))
}

export function createDepartment(token, data) {
  return api.post('departments', data, getConfig(token))
}

export function updateDepartment(token, data) {
  const { id } = data
  return api.put(`departments/${id}`, data, getConfig(token))
}

export function deleteDepartment(token, data) {
  const { id } = data
  return api.delete(`departments/${id}`, getConfig(token))
}

export function getDepartmentsList(token, _, extra) {
  return api.get('departments/list', getConfig(token, extra))
}
