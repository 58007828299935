import { CONTRACT_TYPES } from '../../../../core/config/contract-types'
import { DE_PAYMENT_PROVIDER } from '../../../../helpers/enum'
import {
  DATE_PICKER_FORMAT,
  rpFormatDate,
} from '../../../../utils/formatters/date-picker-date-format'
import { getContractTypeFields } from './fe-be-types-mapping'

export function getDefaultDuplicateData({ contract }) {
  const contractType = contract?.type

  let defaultValues = {
    ...getContractTypeFields(contractType),

    start_date: rpFormatDate(
      contract?.start_date,
      'MMM dd, yyyy',
      DATE_PICKER_FORMAT,
    ),
    amount: contract?.amount,
    name: contract?.name,
    nationality_id: contract?.nationality?.id,
    scope: contract?.scope,

    country_id: contract?.jurisdiction_country?.id,
    notice_period: contract?.notice_period,
    currency_id: contract?.currency?.id,
    tax_residence_id: contract?.tax_residence?.id,
    frequency_id: contract?.frequency?.id,
    occurrence_id: contract?.occurrence?.id,
    rate_id: contract?.rate_id?.id,

    // DE default values
    jurisdiction_id: contract?.jurisdiction_id,
    employment_term: contract?.employment_term,
    employment_type: contract?.employment_type,
    seniority: contract?.seniority,
    department: contract?.department,
    is_annual_plane_ticket_enabled:
      contract?.is_annual_plane_ticket_enabled === 1,
    is_bonus_clause_enabled: contract?.is_bonus_clause_enabled === 1,
    is_overtime_enabled: contract?.is_overtime_enabled === 1,
    is_external_payroll_provider:
      contract?.is_external_payroll_provider === 1
        ? DE_PAYMENT_PROVIDER.EXTERNAL_PROVIDER
        : DE_PAYMENT_PROVIDER.RP_PROVIDER,
    paid_vacation_days: contract?.paid_vacation_days,
    time_off_days_left: contract?.time_off_days_left,
    probation_period: contract?.probation_period,

    contractor_label_id: 1,
    first_payment_prorata: false,
    milestones: [],

    // EOR default values - duplication is disabled for EOR
    // When duplication is enabled, we need to add these fields
    // insurance: false,
    // work_visa: false,
    // trial_period: 'No probation',
    // contract_doc_type: CONTRACT_DOC_TYPE.RP_TEMPLATE,
  }

  if (contractType === CONTRACT_TYPES.MILESTONES) {
    defaultValues = {
      ...defaultValues,
      end_date: rpFormatDate(
        contract?.end_date,
        'MMM dd, yyyy',
        DATE_PICKER_FORMAT,
      ),
      first_payment_date: rpFormatDate(
        contract?.first_payment_date,
        DATE_PICKER_FORMAT,
        DATE_PICKER_FORMAT,
      ),
    }
  }

  return defaultValues
}
