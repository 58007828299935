import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig } from './api'

export function getExternalInvoiceClients(token, _, extra) {
  return api.get('/external_invoice_clients', getConfig(token, extra))
}

export function createExternalInvoiceClient(token, data) {
  return api.post('/external_invoice_clients', data, getConfig(token))
}

export function deleteExternalInvoiceClient(token, data) {
  return api.delete(`/external_invoice_clients/${data.id}`, getConfig(token))
}

export function getExternalClientsList(token) {
  return api.get('external_invoice_clients', getConfig(token))
}

export function getExternalInvoicesList(token, data) {
  const sp = searchParamsFromObject(data)

  return api.get('/external_invoices' + sp, getConfig(token))
}

export function createExternalInvoice(token, data) {
  return api.post('/external_invoices', data, getConfig(token))
}

export function updateExternalInvoice(token, data) {
  return api.put('/external_invoices/' + data?.id, data, getConfig(token))
}
