import cx from 'classnames'
import React from 'react'
import { Col } from 'reactstrap'

export function FormCol({ children, className, md = 6, noMargin = false }) {
  return (
    <Col md={md} className={cx('px-3', { 'mb-3': !noMargin }, className)}>
      {children}
    </Col>
  )
}
