// Code is from https://github.com/geordee/eid/blob/main/index.html
// Adjusted to work for this project

function computeCheckDigit(a) {
  // luhn algorithm for check digit
  let s = 0
  let x = true
  const l = a.length

  for (let n = l - 1; n >= 0; n--) {
    const c = a.charAt(n)
    let i = parseInt(c, 10)

    if (x && (i *= 2) > 9) i -= 9

    s += i
    x = !x
  }
  const z = (s * 9) % 10

  return z
}

export function validateEID(eid) {
  // regex for emirates id with birth dates between 1900 and 2099
  const re = /^784[-]*\d{4}[-]*\d{7}[-]*\d$/

  if (!re.test(eid)) {
    return [false, 'Emirates ID format is incorrect.']
  }

  let valid = false
  const digits = eid.replace(/\D/g, '')

  const sequence = digits.substr(0, digits.length - 1)
  const checkDigit = digits.substr(-1)
  const checksum = computeCheckDigit(sequence)

  valid = Number(checksum) === Number(checkDigit)

  // validate checkDigit
  if (!valid) {
    return [false, 'Check digit is incorrect. Expected ' + checksum + '.']
  }

  return [true, 'Emirates ID is valid.']
}
