import React from 'react'
import { Helmet } from 'react-helmet'

export default function Head({ title, children }) {
  return (
    <Helmet>
      {!title ? null : <title>{title} | RemotePass</title>}

      {children}
    </Helmet>
  )
}
