import {
  ArrowSquareOut,
  ClockClockwise,
  CurrencyCircleDollar,
  DownloadSimple,
  UserCircle,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import {
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import BadgeX from '../../../components/Table/BadgeX'
import { StyledH1, StyledH2 } from '../../../components/Typo'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import { activateAllowanceInsuranceClient } from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { downloadFile } from '../utils/helpers'

const InsurancePlans = ({
  features,
  title,
  price,
  desc,
  currency,
  plan,
  ctaText,
  contractUser,
  contract,
  onAddAllowanceUpdate,
  hasAddPermission,
}) => {
  const isMobile = useResize()

  const formatter = getCurrencyFormatter(currency?.code)

  const [openConfirmAllowanceModal, setOpenConfirmAllowanceModal] =
    useState(false)

  const [openPdf, setOpenPdf] = useState(null)
  const onSelectPlan = () => {
    setOpenConfirmAllowanceModal(true)
  }

  return (
    <Card className='mt-3 border border-1 p-4'>
      <BadgeX
        className={cx(
          'p-2 mb-2 font-size-10 rounded-pill',
          title === 'Premium Plan' ? '' : 'invisible',
        )}
        color='primary'
        style={{ alignSelf: 'flex-start' }}
        size='sm'
      >
        POPULAR
      </BadgeX>

      <StyledH1
        style={{ fontWeight: 'bold', marginBottom: 24 }}
        min='16px'
        max='24px'
      >
        {title}
      </StyledH1>
      <StyledH2
        style={{ fontWeight: 'bold', marginBottom: 12 }}
        min='16px'
        max='24px'
      >
        {formatter.format(price)}
        <span className='text-secondary'>/mo</span>
      </StyledH2>
      <h3
        style={{ marginBottom: 32, fontWeight: '400' }}
        className='text-secondary font-size-12'
      >
        {desc}
      </h3>
      <Row>
        {features.map((e, i) => (
          <Col
            key={`feature-${i}`}
            style={{ minWidth: isMobile ? '100%' : '20vw' }}
          >
            <Row className='p-0 m-0 align-items-center mb-4'>
              {e?.included ? (
                <div
                  className='bg-soft-success rounded-circle align-items-center justify-content-center mr-2'
                  style={{
                    height: 24,
                    width: 24,
                    paddingLeft: 2,
                    paddingTop: 2,
                  }}
                >
                  <i className='bx bx-check text-success font-size-20 text-center' />
                </div>
              ) : (
                <div
                  className='bg-soft-danger rounded-circle align-items-center justify-content-center mr-2'
                  style={{ height: 24, width: 24, paddingLeft: 3 }}
                >
                  <i
                    className='mdi mdi-window-close text-danger font-size-18 text-center'
                    style={{ lineHeight: '24px' }}
                  />
                </div>
              )}
              <h1 className='font-size-14 rp-font-bold my-0'>{e.name}</h1>
              <i
                id={`feature-${plan?.id}-${i}`}
                className='bx bx-help-circle mx-2 text-secondary font-size-18'
              />
              <UncontrolledTooltip
                placement='top'
                target={`feature-${plan?.id}-${i}`}
              >
                {e.details}
              </UncontrolledTooltip>
            </Row>
          </Col>
        ))}
      </Row>

      <button
        className='btn p-0 d-flex align-items-center rp-font-medium font-size-16 text-dark'
        onClick={() => {
          setOpenPdf(plan?.handbook_url)
        }}
      >
        See Details <ArrowSquareOut className='ml-1' size={20} />
      </button>

      <PermissionTooltip
        id='add-allowance-plan'
        area={PERMISSION_GROUP.EXPENSES.name}
        showing={!hasAddPermission}
      >
        <Button
          onClick={onSelectPlan}
          className='mt-4'
          disabled={!hasAddPermission}
          block
        >
          {ctaText}
        </Button>
      </PermissionTooltip>

      <ConfirmAllowanceModal
        isOpen={openConfirmAllowanceModal}
        toggle={() => setOpenConfirmAllowanceModal(false)}
        plan={plan}
        contractUser={contractUser}
        contract={contract}
        onUpdate={onAddAllowanceUpdate}
      />

      {openPdf && (
        <ViewInsurancePlanPdf
          toggle={() => {
            setOpenPdf(null)
          }}
          pdfUrl={openPdf}
          planName={plan?.name}
        />
      )}
    </Card>
  )
}

const ConfirmAllowanceModal = ({
  isOpen,
  toggle,
  plan,
  contractUser,
  contract,
  onUpdate,
}) => {
  const buyAllowanceInsuranceReq = useFetch({
    action: activateAllowanceInsuranceClient,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
      } else {
        onUpdate?.startFetch({ contract_id: contract?.id })
        toggle()
      }
    },
    onError: (error) => {
      toastr.error(
        typeof error === 'string'
          ? error
          : typeof error?.message === 'string'
            ? error.message
            : 'An error occurred while adding allowance.',
      )
    },
  })

  const buyAllowanceInsurance = () => {
    let occurrenceId = 17 // 1st of every month
    if (new Date().getDate() < 15) {
      occurrenceId = 31 // 15th of every month
    }
    if (contract?.id && plan?.id) {
      buyAllowanceInsuranceReq.startFetch({
        contract_id: contract?.id,
        plan_id: plan?.id,
        occurrence_id: occurrenceId,
      })
    }
  }

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}>Confirm allowance</ModalHeader>
      <ModalBody className='text-center'>
        {/* <Card className='p-4 mb-4 border border-1'>
          <div className='d-flex justify-content-between'>
            <span className='text-muted font-size-14'>Option</span>
            <span className='rp-font-bold font-size-16 text-primary'>
              The Allowance {plan?.name}
            </span>
          </div>
        </Card> */}

        <Card className='p-4 mb-0 border border-1'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column align-items-start'>
              <UserCircle size={24} color='var(--secondary)' />
              <span className='font-size-14 text-gray-600'>Contractor</span>
              <span className='font-size-14 rp-font-semibold'>
                {contractUser?.full_name}
              </span>
            </div>
            <div className='d-flex flex-column align-items-start'>
              <CurrencyCircleDollar size={24} color='var(--secondary)' />
              <span className='font-size-14 text-gray-600'>Amount</span>
              <span className='font-size-14 rp-font-semibold'>
                {plan.currency.symbol} {plan.amount}
              </span>
            </div>
            <div className='d-flex flex-column align-items-start'>
              <ClockClockwise size={24} color='var(--secondary)' />
              <span className='font-size-14 text-gray-600'>Occurrence</span>
              <span className='font-size-14 rp-font-semibold'>Every 15th</span>
            </div>
          </div>

          <hr />

          <div className='font-size-14 text-left'>
            Contractors have the right to use this allowance for whatever
            purpose they wish. This includes, but is not limited to health
            insurance.
          </div>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={buyAllowanceInsurance}
          color='primary'
          type='button'
          className='w-100'
          disabled={buyAllowanceInsuranceReq.isLoading}
        >
          {buyAllowanceInsuranceReq.isLoading ? (
            <i className='bx bx-loader bx-spin align-middle mr-2' />
          ) : (
            <span className='d-flex align-items-center'>
              <i className='bx bx-check font-size-24' /> Add Allowance
            </span>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const ViewInsurancePlanPdf = ({ pdfUrl, toggle, planName }) => {
  return (
    <Modal
      style={{
        minWidth: '80%',
        minHeight: '90%',
      }}
      isOpen={!!pdfUrl}
      toggle={toggle}
    >
      <div className='modal-header align-items-center'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <span className='rp-font-bold h5 m-0'>{planName}</span>
          <button
            className='btn btn-primary d-flex align-items-center mr-3'
            onClick={() => {
              downloadFile(pdfUrl, `${planName}.pdf`)
            }}
          >
            <DownloadSimple size={20} weight='fill' className='mr-1' />
            <span>Download PDF</span>
          </button>
        </div>
        <button type='button' className='close' onClick={toggle}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <ModalBody style={{ height: '100%', minHeight: '80vh' }}>
        <iframe
          id='insurancePdfFrame'
          src={`${pdfUrl}`}
          title={planName + ' PDF document'}
          width='100%'
          style={{
            border: 'none',
            height: 'calc(80vh - 1rem)',
          }}
          aria-labelledby='PDF document'
        />
      </ModalBody>
    </Modal>
  )
}

export default InsurancePlans
