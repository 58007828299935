import React, { useState } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import { getAdminContractPDF } from '../../../../../services/api'
import { Modal } from 'reactstrap'
export function ContractReviewModal({
  isOpenPreview,
  setIsOpenPreview,
  contract,
  onInviteClient,
  invite,
}) {
  const [pdfUrl, setPdfUrl] = useState()

  useFetch({
    action: getAdminContractPDF,
    autoFetch: true,
    body: contract,
    withAdminAccess: true,
    onComplete: (data) => {
      const objectUrl = URL.createObjectURL(data)
      setPdfUrl(objectUrl)
    },
  })
  return (
    <Modal
      size='lg'
      centered
      isOpen={isOpenPreview}
      toggle={() => setIsOpenPreview(false)}
    >
      <div
        style={{ backgroundColor: '#F4F5F7' }}
        className='modal-body p-3 p-md-4'
      >
        <iframe
          frameBorder='0'
          id='signatureFrame'
          src={pdfUrl}
          title='Contract view'
          width='100%'
          style={{
            backgroundColor: '#fff',
            overflow: 'auto',
            minHeight: '80vh',
          }}
        />
        <button
          className='btn btn-primary btn-block mb-5'
          onClick={onInviteClient}
        >
          {invite.isLoading && (
            <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
          )}
          Review & Send
        </button>
      </div>
    </Modal>
  )
}
