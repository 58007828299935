import React from 'react'
import { Card } from 'reactstrap'

import Button from '../../../components/ui/button'
import Heading from '../components/heading'

export default function PerksStatusPage({
  illustration: Illustration,
  heading = 'Perks and Discounts',
  body,
  actionText,
  actionBtnProps,
  children,
}) {
  return (
    <Card
      className='d-flex align-items-center justify-content-center flex-column px-4 pt-5 pb-5 mb-0'
      style={{ minHeight: '75vh' }}
    >
      <Illustration
        style={{
          width: '100%',
          maxWidth: 410,
          height: 'auto',
          marginBottom: '2rem',
        }}
      />
      {!heading ? null : <Heading>{heading}</Heading>}
      {!body ? null : (
        <p
          className='text-center text-muted font-size-16'
          style={{ lineHeight: '24px', maxWidth: 480 }}
        >
          {body}
        </p>
      )}
      {!children ? null : children}
      {!actionText ? null : <Button {...actionBtnProps}>{actionText}</Button>}
    </Card>
  )
}
