import { HIBOB_REQUEST, HIBOB_UPDATE_CONTRACTORS } from './actionTypes'

const initialState = {
  request: {
    auto_create: false,
    contract_types: [
      {
        name: 'fixed',
        label: 'Fixed contracts',
        status: true,
        note: 'Pay a fixed rate monthly, bi-weekly or weekly',
      },
      {
        name: 'payg',
        label: 'Pay as you Go contracts',
        status: true,
        note: 'Pay per day or per hour based on timesheets',
      },
      {
        name: 'milestone',
        label: 'Milestone contracts',
        status: true,
        note: 'Pay as milestones are completed',
      },
      {
        name: 'full-time',
        label: 'Full-time employees',
        status: true,
        note: 'Hire a full-time employee',
      },
    ],
    fields: [
      {
        name: 'basic-infos',
        label: 'Basic information',
        status: false,
        note: 'First, Middle, Last name, email address',
      },
      {
        name: 'contact-infos',
        label: 'Contact information',
        status: false,
        note: 'Phone number',
      },
      {
        name: 'job-infos',
        label: 'Job information',
        status: false,
        note: 'Job title, salary, employment type, salary currency, pay period, start date',
      },
    ],
  },
  preData: null,
  contractors: [],
}

const hiBob = (state = initialState, action) => {
  switch (action.type) {
    case HIBOB_REQUEST:
      return {
        ...state,
        request: action.data,
      }
    case HIBOB_UPDATE_CONTRACTORS:
      return {
        ...state,
        contractors: action.payload,
      }

    default:
      return state
  }
}

export default hiBob
