import React, { useState } from 'react'
import { Card } from 'reactstrap'

import Pagination from '../../components/ui/pagination'
import { useFetch } from '../../helpers/hooks'
import { getAdminInvoicesList } from '../../services/api'
import { useFilters } from '../AdminPanel/pages/cards/use-filters'
import { InvoicesTable } from './invoices-table'

export function AdminInvoiceList({ contractId }) {
  const [expanded, setExpanded] = useState(-1)

  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    contract_id: contractId,
  })

  const {
    data: invoices,
    isLoading,
    paginator,
  } = useFetch(
    {
      action: getAdminInvoicesList,
      autoFetch: !!contractId,
      withAdminAccess: true,
      body: filters,
    },
    [filters],
  )

  const showPagination = paginator?.first_page_url !== paginator?.last_page_url

  return (
    <Card className='max-md:tw-p-4'>
      <InvoicesTable
        orders={invoices}
        dataLoading={isLoading}
        isAdmin
        expanded={expanded}
        setExpanded={setExpanded}
      />

      {showPagination && (
        <div className='tw-mt-4 tw-flex tw-justify-end'>
          <Pagination
            activePage={filters.page}
            onChange={(page) => handleFiltersChange('page', page)}
            itemsCountPerPage={paginator?.per_page ?? 50}
            totalItemsCount={paginator?.total ?? 0}
            innerClass='pagination tw-mb-0'
          />
        </div>
      )}
    </Card>
  )
}
