import { Plus } from '@phosphor-icons/react'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Container, Row, Table } from 'reactstrap'

import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import { useFetch } from '../../../../helpers/hooks'
import {
  downloadGeneratedInvoice,
  getGeneratedInvoicesList,
} from '../../../../services/api'
import openFile from '../../../../utils/file/open'

function GeneratedInvoices() {
  const [date, setDate] = useState(moment().format('YYYY-MM'))
  const history = useHistory()

  const invoices = useFetch(
    {
      action: getGeneratedInvoicesList,
      withAdminAccess: true,
      autoFetch: true,
      onError: console.error,
      body: { month: date },
    },
    [date],
  )

  return (
    <div className='page-content'>
      <h1 style={{ marginBottom: '2rem' }}>Generated Invoices</h1>

      <Card>
        {invoices.isLoading ? (
          <Loader minHeight='30rem' />
        ) : (
          <>
            <Container fluid>
              <Row className='p-3' style={{ gap: '0.75rem' }}>
                <Col md={4} className='p-0'>
                  <CustomDatePicker
                    showMonthYearPicker
                    placeholder='Filter by month'
                    dateFormat='MMM - yyyy'
                    value={date}
                    handleOnChange={(v) => {
                      setDate(moment(v).format('YYYY-MM'))
                    }}
                  />
                </Col>
                <Col md={2} className='p-0'>
                  <Button
                    block
                    onClick={() => setDate(moment().format('YYYY-MM'))}
                  >
                    Clear Filter
                  </Button>
                </Col>
                <Col md={2} className='p-0'>
                  <Button
                    block
                    onClick={() => history.push('/admin/invoice-generation')}
                    icon={<Plus size={12} weight='bold' />}
                  >
                    New Invoice
                  </Button>
                </Col>
              </Row>
            </Container>

            <Table
              responsive
              className='table-centered table-nowrap text-muted'
            >
              <thead>
                <tr>
                  <th className='border-top-0'>Ref</th>
                  <th className='border-top-0'>Partner name</th>
                  <th className='border-top-0'>Created at</th>
                  <th className='border-top-0'>total</th>
                </tr>
              </thead>
              <tbody>
                {invoices?.data?.map((raw, key) => {
                  return <GeneratedInvoiceLine invoice={raw} key={key} />
                })}
              </tbody>
            </Table>
          </>
        )}
      </Card>
    </div>
  )
}

function GeneratedInvoiceLine({ invoice, key }) {
  const [downloading, setDownloading] = useState(null)
  const { startFetch: downloadInvoice, isLoading: downloadingInvoice } =
    useFetch({
      action: downloadGeneratedInvoice,
      withAdminAccess: true,
      onComplete: (data, body) => {
        openFile(data, `generated-invoice-${body?.invoice_id}.pdf`)
        setDownloading(null)
      },
    })
  function handlePreviewInvoice(invoiceId) {
    return function () {
      downloadInvoice({ invoice_id: invoiceId })
      setDownloading(invoiceId)
    }
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: invoice?.currency?.code || 'USD',
  })

  const isDownloading = downloadingInvoice && downloading === invoice.id
  return (
    <React.Fragment key={'_invoice_' + key}>
      <tr>
        <td>{invoice?.ref}</td>
        <td>{invoice?.partner_name}</td>
        <td>{moment.unix(invoice?.created_at).format('MM-DD-YYYY')}</td>
        <td>{formatter.format(invoice?.total)}</td>
        <td>
          <Button
            size='sm'
            outline
            color='secondary'
            onClick={handlePreviewInvoice(invoice.id)}
            disabled={isDownloading}
            loading={isDownloading}
          >
            Download
          </Button>
        </td>
      </tr>
    </React.Fragment>
  )
}

export const CLIENT_INVOICE_STATUSES = {
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING_APPROVAL: 'Pending approval',
}

export default GeneratedInvoices
