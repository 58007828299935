import React from 'react'
import { Card } from 'reactstrap'

import { ReactComponent as CardRequestPhysical } from '../../../assets/images/card-request-physical.svg'
import { ReactComponent as CardIllustration } from '../../../assets/images/card-request.svg'
import { SectionHeading } from './section-heading'
import { CARD_TYPE_ENUM } from '../utils/card-types-status'

export default function AddNewCard({ requestedCard, cardLabel, action }) {
  const requestedCardIsPhysical = requestedCard === CARD_TYPE_ENUM.PHYSICAL

  return (
    <Card
      className='d-flex flex-row align-items-center flex-wrap rp-shadow-2 p-4 mb-0'
      style={{ gap: '2rem', minHeight: 292 }}
    >
      <div className='px-4 py-4 py-md-0'>
        {requestedCardIsPhysical ? (
          <CardRequestPhysical />
        ) : (
          <CardIllustration width='231' />
        )}
      </div>
      <div>
        <SectionHeading>Request your {cardLabel?.toLowerCase()}</SectionHeading>
        <p
          className='text-secondary font-size-16'
          style={{ maxWidth: '62ch', lineHeight: '24px' }}
        >
          {requestedCardIsPhysical
            ? 'Physical cards are accepted globally on the Mastercard Network.'
            : 'A virtual copy of your physical card. Use it to pay in your online shopping.'}
        </p>
        {action}
      </div>
    </Card>
  )
}
