import { useDispatch } from 'react-redux'

import { useFetch } from '../../../helpers/hooks'
import { updateContractorProfile } from '../../../services/api'
import { updateUserInfo } from '../../../store/actions'
import { updateUserProfileInfo } from '../../../store/profile/actions'

export default function useUpdateAddress() {
  const dispatch = useDispatch()

  const { startFetch: updateContractor } = useFetch({
    autoFetch: false,
    initResult: null,
    action: updateContractorProfile,
    onComplete: (data) => {
      dispatch(updateUserInfo(data || {}))
      dispatch(updateUserProfileInfo(data || {}))
    },
  })

  function updateContractorAddress(residentialAddress) {
    const address = `${residentialAddress.address_1} ${residentialAddress.address_2}`

    updateContractor({
      address,
      city: address?.city,
      zip_code: address?.zipcode,
    })
  }

  return { updateContractorAddress }
}
