import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  occurrenceDaysLabel,
  rangeOccurrences,
  TRIGGER_OCCURRENCE,
} from './constants'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'

export function TriggerOccurrences({ index }) {
  const { watch } = useFormContext()
  const occurrence = watch(`steps.${index}.event`)?.occurrence
  if (!occurrence) return null

  return (
    <div className='tw-flex tw-flex-row tw-gap-3.5'>
      <Controller
        shouldUnregister={false}
        render={({ field }) => (
          <div className='form-control !tw-flex !tw-h-auto tw-flex-1'>
            <input
              id='input'
              type='number'
              value={field.value}
              onChange={field.onChange}
              className='tw-flex-1 focus:tw-outline-none'
              onBlur={field.onBlur}
              placeholder={occurrenceDaysLabel[occurrence.type]}
            />
            <span className='tw-text-text-80'>Days</span>
          </div>
        )}
        name={`steps.${index}.occurrenceDays`}
      />

      {occurrence?.type === TRIGGER_OCCURRENCE.RANGE ? (
        <Controller
          defaultValue={rangeOccurrences[0]}
          shouldUnregister={false}
          name={`steps.${index}.typeOfOccurrence`}
          render={({ field }) => (
            <CustomSelect
              value={field.value}
              options={rangeOccurrences}
              placeholder='Occurence'
              onChange={field.onChange}
              wrapperClassName='tw-flex-1'
            />
          )}
        />
      ) : null}
    </div>
  )
}
