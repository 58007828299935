import {
  Cake,
  CaretRight,
  ClockCountdown,
  Confetti,
  Megaphone,
  MegaphoneSimple,
  Money,
  Timer,
  UserPlus,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap'

import { StyledH2 } from '../../components/Typo'
import Button from '../../components/ui/button'
import Shimmer from '../../components/ui/shimmer'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  getCurrentMonthPayments,
  getOutstandingPayments,
  getPendingApprovalPayments,
  getRecentContracts,
  getUpcomingEvents,
  getUpcomingPayments,
} from '../../services/api'
import { deleteUserPassword } from '../../store/actions'
import { track } from '../../utils/analytics'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import usePrevious from '../../utils/use-previous'
import { usePayrollApprovalEnabled } from '../CompanySetting'
import ContractList from '../Contract/ContractList/ContractList'
import {
  ChartCard,
  ChartHeading,
  Dimmer,
  HeadCountPerCountry,
  SpendingPerMonth,
} from '../reports'
import ContractorOverview from './ContractorOverview'
import EmployeeOverview from './EmployeeOverview'
import { CenterOverviewClient } from './center-overview-client'
import { ClientBanners } from './client-banners'
import { StylePart, StyledMiniWidget } from './mini-widget'
import Loader from '../../components/ui/loader'
import { Avatar, cn } from 'ui'
import { PolicyTypeIcon } from '../new-time-off-policy'
import { format, isSameDay, isWithinInterval } from 'date-fns'
import { v4 as uuid } from 'uuid'
import { EmptyState } from '../review-center/empty-state'

export function useHasChanged(val) {
  const prevVal = usePrevious(val)
  return prevVal?.id !== val?.id
}

export default function Activity() {
  const user = useSelector((state) => state.Account?.user)
  const userType = user?.type

  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()

  const isClient = userType === userTypes.COMPANY
  const hasTodoPermission = !isClient || hasAccess(permissions.ViewContracts)

  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const hasCompanyChanged = useHasChanged(company)

  useEffect(() => {
    // This is to ensure we are deleting the user password passed for 2fa

    dispatch(deleteUserPassword())
  }, [dispatch])

  return isClient ? (
    <>
      <div className='page-content'>
        <Container fluid className='p-0 m-0'>
          <ClientBanners />

          <h1 className='tw-mb-1 tw-text-2xl sm:tw-text-3xl lg:tw-text-4xl'>
            Hi, <span translate='no'>{user?.first_name}</span>
          </h1>
          <h2 className='tw-mb-6 tw-text-base tw-font-normal tw-text-text-80'>
            Here is a recap of your activity
          </h2>

          {!hasTodoPermission ? null : <CenterOverviewClient />}

          {hasAccess(permissions.ViewContractsActivity) && (
            <Col className='p-0 m-0'>
              <div style={{ marginBottom: 32 }}>
                <Row className='mx-n2' style={{ gap: '1rem 0' }}>
                  <UpcomingPaymentsWidget />
                  <OutstandingPaymentsWidget />
                  <CurrentMonthPaymentsWidget />
                </Row>
              </div>
            </Col>
          )}

          {hasAccess(permissions.viewDashboardGraph) && (
            <>
              <Row className='mx-n3' style={{ gap: 'var(--size-32) 0' }}>
                <Col xs={12} className='px-3'>
                  <SpendingPerMonth hasCompanyChanged={hasCompanyChanged} />
                </Col>
                <Col md={6} className='px-3'>
                  <HeadCountPerCountry hasCompanyChanged={hasCompanyChanged} />
                </Col>
                <Col md={6} className='px-3'>
                  <UpcomingEvents />
                </Col>
                {!hasAccess(permissions.ViewContracts) && (
                  <Col xs={12} className='px-3'>
                    <RecentContracts />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  ) : isEmployee ? (
    <EmployeeOverview />
  ) : (
    <ContractorOverview />
  )
}

function RecentContracts() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)
  const { data: contracts, isLoading } = useFetch(
    {
      action: getRecentContracts,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  return (
    <ChartCard className={{ 'd-none d-block': contracts?.length > 0 }}>
      <ChartHeading
        title={
          contracts?.length > 0 ? (
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='mb-1 font-size-24 text-gray-h rp-font-bold'>
                Recent contracts
              </h4>
              <Link
                to='/contracts'
                className='text-primary ml-2 font-size-14 d-flex align-items-center align-self-end'
              >
                View all
                <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
              </Link>
            </div>
          ) : (
            'Recent contracts'
          )
        }
      />
      <Container
        fluid
        className='position-relative rounded-bottom'
        style={{ minHeight: 400 }}
      >
        {isLoading ? (
          <div style={{ '--s-height': '44px' }}>
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          </div>
        ) : (
          <>
            {contracts?.length === 0 && (
              <Dimmer>
                <p className='h4 mb-0 text-slate-500'>No recent contracts</p>
              </Dimmer>
            )}
            <ContractList Orders={contracts} />
          </>
        )}
      </Container>
    </ChartCard>
  )
}
function UpcomingPaymentsWidget() {
  const user = useSelector((state) => state.Account?.user)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)

  const { data: upcomingPayments, isLoading } = useFetch(
    {
      action: getUpcomingPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const formatter = getCurrencyFormatter(company?.currency?.code)

  const { hasAccess } = usePermissions()

  if (!hasAccess(permissions.ViewUpcomingPayments)) {
    return null
  }

  return isLoading ? (
    <Col lg={4} className='px-2'>
      <div className='bg-white p-3'>
        <Shimmer width='100%' height={140} />
      </div>
    </Col>
  ) : upcomingPayments ? (
    <Col lg={4} className='px-2'>
      <StyledMiniWidget>
        <StylePart
          className='border-bottom justify-content-between align-items-center d-flex gap-6'
          style={{ minHeight: 83 }}
        >
          <div className='justify-content-between align-items-center d-flex gap-12'>
            <Money weight='duotone' size={32} />

            <h5 className='mb-0 text-gray-700'>
              Upcoming Payments
              <>
                <i
                  id='info-repo'
                  className='bx bx-info-circle font-size-16 ml-1'
                />
                <UncontrolledTooltip placement='top' target='info-repo'>
                  List of payments in the current payment cycle, including due
                  and overdue payments
                </UncontrolledTooltip>
              </>
            </h5>
          </div>

          <Button
            tag={Link}
            onClick={() => {
              track('Clicked upcoming payment', { email_id: user?.email })
            }}
            to='/upcoming-payment'
            color='link'
            className='px-0'
            iconRight={<CaretRight />}
          >
            Details
          </Button>
        </StylePart>

        <StylePart>
          <div>
            <StyledH2
              style={{ fontWeight: '600' }}
              max='24px'
              min='22px'
              className='text-gray-h mb-0'
            >
              {formatter.format(upcomingPayments)}
            </StyledH2>
          </div>
        </StylePart>
      </StyledMiniWidget>
    </Col>
  ) : null
}

function OutstandingPaymentsWidget() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)
  const { data: outstandingPayments, isLoading } = useFetch(
    {
      action: getOutstandingPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const { hasAccess } = usePermissions()
  const user = useSelector((state) => state.Account?.user)

  const formatter = getCurrencyFormatter(company?.currency?.code)

  let buttonProps = {
    tag: Link,
    onClick: () => {
      track('Clicked pay', { email_id: user?.email })
    },
    to: '/payment',
    disabled: outstandingPayments?.due <= 0,
  }

  const hasPayAccess = hasAccess([
    permissions.ViewTransactions,
    permissions.PrepareTransactions,
    permissions.CreateTransferTransactions,
    permissions.CreateCCTransactions,
    permissions.VerifyCCTransactions,
  ])

  if (hasPayAccess) {
    buttonProps = {
      ...buttonProps,
      color: 'link',
      className: 'px-0',
      iconRight: <CaretRight />,
    }
  }

  return isLoading ? (
    <Col lg={4} className='px-2'>
      <div className='bg-white p-3'>
        <Shimmer width='100%' height={140} />
      </div>
    </Col>
  ) : outstandingPayments ? (
    <Col lg={4} className='px-2'>
      <StyledMiniWidget>
        <StylePart
          className='border-bottom justify-content-between align-items-center d-flex gap-6'
          style={{ minHeight: 83 }}
        >
          <div className='justify-content-between align-items-center d-flex gap-12'>
            <ClockCountdown weight='duotone' size={32} />

            <h5 className='mb-0 text-gray-700'>
              Due
              <i
                id='info-repo-outs'
                className='bx bx-info-circle font-size-16 ml-2'
              />
              <UncontrolledTooltip placement='top' target='info-repo-outs'>
                List of payments due within 5 days and overdue payments
              </UncontrolledTooltip>
            </h5>
          </div>

          {!hasAccess(permissions.ViewUnpaidPayments) ? null : (
            <Button {...buttonProps}>{hasPayAccess ? 'Pay' : 'Details'}</Button>
          )}
        </StylePart>

        <StylePart>
          <div>
            <StyledH2
              style={{ fontWeight: '600' }}
              max='24px'
              min='22px'
              className='text-gray-h mb-0'
            >
              {formatter.format(outstandingPayments?.due)}
            </StyledH2>
            {outstandingPayments?.overdue <= 0 ? null : (
              <StyledH2
                style={{ fontWeight: '600' }}
                max='18px'
                min='18px'
                className='text-danger mb-0'
              >
                {formatter.format(outstandingPayments?.overdue)} (Overdue)
              </StyledH2>
            )}
          </div>
        </StylePart>
      </StyledMiniWidget>
    </Col>
  ) : null
}

function CurrentMonthPaymentsWidget() {
  const user = useSelector((state) => state.Account?.user)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company ?? {},
  )

  const hasCompanyChanged = useHasChanged(company)

  const { data: pendingApprovalPayments, isPendingLoading } = useFetch(
    {
      action: getPendingApprovalPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )
  const { data: currentMonthPayments, isLoading } = useFetch(
    {
      action: getCurrentMonthPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const payrollApprovalEnabled = usePayrollApprovalEnabled()
  const isPending = payrollApprovalEnabled && pendingApprovalPayments?.amount

  const isNotApprover = !pendingApprovalPayments?.is_approver

  const formatter = getCurrencyFormatter(company?.currency?.code)

  return isLoading || isPendingLoading ? (
    <Col lg={4} className='px-2'>
      <div className='bg-white p-3'>
        <Shimmer width='100%' height={140} />
      </div>
    </Col>
  ) : pendingApprovalPayments || currentMonthPayments ? (
    <Col lg={4} className='px-2'>
      <StyledMiniWidget>
        <StylePart
          className='border-bottom justify-content-between align-items-center d-flex gap-6'
          style={{ minHeight: 83 }}
        >
          <div className='justify-content-between align-items-center d-flex gap-12'>
            {isPending ? (
              <Timer weight='duotone' size={32} />
            ) : (
              <ClockCountdown weight='duotone' size={32} />
            )}

            <h5 className='mb-0 text-gray-700'>
              {isPending ? 'Pending review' : 'Paid this month'}
            </h5>
          </div>

          {isPending ? (
            <Button
              tag={Link}
              onClick={() => {
                track('Clicked pending review', { email_id: user?.email })
              }}
              to='/review-payments'
              color='link'
              className='!tw-px-0'
              iconRight={<CaretRight />}
            >
              {isNotApprover ? 'Details' : 'Review'}
            </Button>
          ) : (
            <Button
              tag={Link}
              onClick={() => {
                track('Clicked paid this month', { email_id: user?.email })
              }}
              to='/transactions'
              color='link'
              className='!tw-px-0'
              iconRight={<CaretRight />}
            >
              Details
            </Button>
          )}
        </StylePart>

        <StylePart>
          <div>
            <StyledH2
              style={{ fontWeight: '600' }}
              max='24px'
              min='22px'
              className='text-gray-h mb-0'
            >
              {formatter.format(
                isPending
                  ? pendingApprovalPayments?.amount
                  : currentMonthPayments,
              )}
            </StyledH2>
          </div>
        </StylePart>
      </StyledMiniWidget>
    </Col>
  ) : null
}

function UpcomingEvents() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)
  const { data: upcomingEvents, isLoading } = useFetch(
    {
      action: getUpcomingEvents,
      autoFetch: true,
      initResult: [],
    },
    [hasCompanyChanged],
  )

  const events = Object.values(upcomingEvents)

  return (
    <ChartCard>
      <ChartHeading
        title='Upcoming Events'
        subtitle='For the next 7 days'
        icon={
          <MegaphoneSimple
            weight='duotone'
            size={24}
            className='tw-text-secondary-100'
          />
        }
      />

      <div
        className={cx('tw-h-[480px] tw-overflow-y-auto tw-py-4', {
          'tw-pointer-events-none': events?.length <= 0,
        })}
      >
        {isLoading || !events ? (
          <Loader className='tw-h-full tw-w-full' />
        ) : events?.length === 0 ? (
          <EmptyState
            pictureElement={<Megaphone size={164} color='var(--primary)' />}
            className='tw-h-full !tw-shadow-none'
            title='No upcoming Events to show'
            textElement={
              <div className='tw-text-text-80'>
                Upcoming Events will be shown here
              </div>
            }
          />
        ) : (
          <div className='tw-px-6'>
            {events.map((event, index) => (
              <EventLine key={index} index={index} event={event} />
            ))}
          </div>
        )}
      </div>
    </ChartCard>
  )
}
function EventLine({ event, index }) {
  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-py-4'>
      <div className='tw-flex tw-items-start tw-gap-4'>
        <Avatar size='lg' photo={event.picture} flag={event.flag} />
        <div className='tw-flex tw-flex-col tw-items-start tw-gap-1'>
          <Link
            className='tw-font-semibold tw-text-black'
            to={`contract/detail?id=${event.entityId}`}
          >
            {event.title}
          </Link>
          <div className='tw-flex tw-flex-col tw-items-start'>
            {event.events.map((ev, index) => (
              <div className='tw-text-secondary-80' key={index}>
                {getEventText(ev)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='-tw-ml-1 tw-flex tw-items-center'>
        {mergeEvents(event.events)?.map((ev, i) => (
          <EventItem onlyIcon key={i} event={ev} />
        ))}
      </div>
    </div>
  )
}

const timeOffEventColorClassNames = {
  'Public Holiday': 'tw-text-secondary-110 tw-bg-secondary-30',
  Vacation: 'tw-text-systemGold-120 tw-bg-systemGold-40',
  'Sick leave': 'tw-text-systemRed-110 tw-bg-systemRed-20',
  'Parental leave': 'tw-text-green-120 tw-bg-green-20',
  Religious: 'tw-text-systemBlue-120 tw-bg-systemBlue-20',
  Bereavement: 'tw-text-cyan-120 tw-bg-cyan-30',
  'Default Time Off': 'tw-text-text-90 tw-bg-text-10',
}

export function getEventColorsClasses(event) {
  switch (event.type) {
    case 'birthday':
      return 'tw-text-systemGreen-130 tw-bg-systemGreen-30'
    case 'time_off':
      return timeOffEventColorClassNames[event.metadata?.timeOffType]
    case 'work_anniversary':
      return 'tw-text-yellow-130 tw-bg-yellow-30'
    case 'new_joiner':
      return 'tw-text-primary-120 tw-bg-primary-20'
    default:
      return 'tw-bg-surface-70 tw-text-secondary'
  }
}
export function EventItem({ event, onlyIcon, whiteBorder = true }) {
  const isActive = isEventActive(event)
  const id = `${event.type}_${uuid()}`

  return (
    <>
      <div
        id={id}
        className={cn(
          'tw-relative tw-flex tw-shrink-0 tw-items-center tw-gap-1 tw-rounded-full tw-px-3 tw-py-2 tw-text-[10px] tw-font-semibold tw-uppercase',
          {
            '-tw-ml-2 tw-h-8 tw-w-8 tw-items-center tw-justify-center !tw-gap-0 tw-border-2 !tw-p-0':
              onlyIcon,
          },
          whiteBorder ? 'tw-border-white' : 'tw-border-surface-10',
          getEventColorsClasses(event),
        )}
      >
        {isActive && (
          <div
            className={cn(
              'tw-absolute -tw-bottom-[5px] tw-rounded-full tw-border-2 tw-border-white tw-bg-green',
              onlyIcon ? 'tw-h-2.5 tw-w-2.5' : 'tw-h-3 tw-w-3',
            )}
            style={{
              left: 'calc(50% - 5px)',
            }}
          ></div>
        )}
        <EventContent onlyIcon={onlyIcon} event={event} />
      </div>
      <UncontrolledTooltip
        className='[&_.tooltip-inner]:!tw-max-w-[300px]'
        target={id}
        placement='bottom'
      >
        <div className='tw-flex tw-flex-col tw-items-start tw-pt-1 tw-text-left'>
          <div>{getEventTooltipText(event).title}</div>
          <div className='tw-flex-shrink-0 tw-text-text-30'>
            {getEventTooltipText(event).text}
          </div>
          {event.extraPeriods?.map((period, index) => (
            <div key={index} className='tw-flex-shrink-0 tw-text-text-30'>
              <div className='tw-my-1 tw-h-[1px] tw-w-10 tw-bg-secondary-100'></div>
              {getEventTooltipText(period).text}
            </div>
          ))}
        </div>
      </UncontrolledTooltip>
    </>
  )
}
function isEventActive(event) {
  const today = new Date()
  function isTimeOffEventActive(event) {
    const eventStartDate = new Date(event.metadata.from)
    const eventEndDate = new Date(event.metadata.to)
    return (
      isSameDay(today, eventStartDate) ||
      isSameDay(today, eventEndDate) ||
      isWithinInterval(today, { start: eventStartDate, end: eventEndDate })
    )
  }
  const eventDate = new Date(event.date)
  if (event.type === 'time_off') {
    if (
      event.extraPeriods?.length &&
      event.extraPeriods.some((period) => isTimeOffEventActive(period))
    ) {
      return true
    }
    return isTimeOffEventActive(event)
  }
  return isSameDay(today, eventDate)
}

export function EventContent({ event, onlyIcon }) {
  switch (event.type) {
    case 'birthday':
      return (
        <>
          <Cake size={16} />
          {!onlyIcon && <span>Birthday</span>}
        </>
      )
    case 'time_off':
      return (
        <>
          <PolicyTypeIcon
            typeId={getTimeOffTypeId(event.metadata?.timeOffType)}
            size={16}
          />
          {!onlyIcon && <span>{event.metadata?.timeOffType}</span>}
        </>
      )
    case 'work_anniversary':
      return (
        <>
          <Confetti size={16} />
          {!onlyIcon && <span>WORK ANNIVERSARY</span>}
        </>
      )
    case 'new_joiner':
      return (
        <>
          <UserPlus size={16} />
          {!onlyIcon && <span>NEW JOINER</span>}
        </>
      )
    default:
      return '' // add more event types
  }
}

function getEventText(event) {
  switch (event.type) {
    case 'birthday':
      return `${format(new Date(event.date), 'MMMM dd')} - Happy Birthday!`
    case 'time_off': {
      const fromDate = format(new Date(event.metadata?.from), 'dd/MM/yyyy')
      const toDate = format(new Date(event.metadata.to), 'dd/MM/yyyy')
      const name = getTimeOffName(event.metadata?.timeOffType)?.toLowerCase()
      return isEventActive(event)
        ? `On ${name} from ${fromDate} to ${toDate}`
        : `Upcoming ${name} from ${fromDate} to ${toDate}`
    }
    case 'work_anniversary':
      return `${format(new Date(event.date), 'MMMM dd')} ${event.metadata.ordinalYear} year anniversary!`
    case 'new_joiner':
      return `${format(new Date(event.date), 'MMMM dd')} - Joining soon!`
    default:
      return `Event on ${event.date}` // add more event types
  }
}

function getEventTooltipText(event) {
  const today = new Date()
  const eventDate = new Date(event.date)
  const daysRemaining = Math.ceil((eventDate - today) / (1000 * 60 * 60 * 24))
  switch (event.type) {
    case 'birthday':
      return {
        title: isEventActive(event)
          ? 'Happy Birthday!'
          : `Birthday in ${daysRemaining} days`,
        text: format(new Date(event.date), 'dd/MM/yyyy'),
      }
    case 'time_off': {
      return {
        title: `${isEventActive(event) ? 'On ' : 'Upcoming '}${getTimeOffName(event.metadata?.timeOffType)}`,
        text: `From ${format(new Date(event.metadata?.from), 'dd/MM/yyyy')} to ${format(new Date(event.metadata.to), 'dd/MM/yyyy')}`,
      }
    }
    case 'work_anniversary':
      return {
        title: `Work anniversary in ${daysRemaining} days`,
        text: format(new Date(event.date), 'dd/MM/yyyy'),
      }
    case 'new_joiner':
      return {
        title: `New joiner Starting ${daysRemaining} days`,
        text: format(new Date(event.date), 'dd/MM/yyyy'),
      }
    default:
      return { title: `Event`, text: format(new Date(event.date)) }
  }
}

function getEventName(event) {
  switch (event.type) {
    case 'birthday':
      return 'Birthday'
    case 'time_off':
      return getTimeOffName(event.metadata?.timeOffType)
    case 'work_anniversary':
      return 'Work Anniversary'
    case 'new_joiner':
      return 'New Joiner'
    default:
      return 'Event'
  }
}

function getTimeOffName(type) {
  switch (type) {
    case 'Religious':
      return 'Religious leave'
    case 'Vacation':
      return 'Vacation leave'
    case 'Default Time Off':
      return 'Default time off leave'
    case 'Bereavement':
      return 'Bereavement leave'
    case 'Other':
      return 'On Leave'
    default:
      return type
  }
}

function getTimeOffTypeId(type) {
  switch (type) {
    case 'Public Holiday':
      return 1
    case 'Vacation':
      return 2
    case 'Sick leave':
      return 3
    case 'Parental leave':
      return 4
    case 'Religious':
      return 5
    case 'Bereavement':
      return 6
    case 'Other':
      return 7
    default:
      return 8
  }
}

export function mergeEvents(data) {
  const types = {}
  const mergedEvents = []
  data?.forEach((event) => {
    const type = event.metadata?.timeOffType
    if (!type) {
      mergedEvents.push(event)
      return
    }
    if (types[type]) {
      types[type].push(event)
    } else {
      types[type] = [event]
    }
  })
  Object.keys(types).forEach((key) => {
    const events = types[key]
    if (events.length === 1) {
      mergedEvents.push(events[0])
      return
    }
    const event = { ...events[0], extraPeriods: [] }
    events.forEach((e, index) => {
      if (index === 0) return
      event.extraPeriods.push(e)
    })
    mergedEvents.push(event)
  })
  return mergedEvents.sort((a, b) => new Date(a.date) - new Date(b.date))
}
