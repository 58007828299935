import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import { ReactComponent as CardIllustration } from '../../../assets/images/card-request.svg'
import { ReactComponent as CardXIllustration } from '../../../assets/images/card-x.svg'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  cardsPreConditions,
  requestCardForExistingUser,
} from '../../../services/api'
import { CARD_TYPE_ENUM } from '../utils/card-types-status'
import { ArrowRight, CreditCard } from '@phosphor-icons/react'

export default function TerminatedRequestCard({
  afterRequestNewCard,
  cardType,
}) {
  const isPhysicalCard = cardType === CARD_TYPE_ENUM.PHYSICAL
  const isVirtualCard = cardType === CARD_TYPE_ENUM.VIRTUAL

  const [showModal, setShowModal] = useState(false)

  function toggle() {
    setShowModal((o) => !o)
  }

  const buttonProps = { className: 'mt-2 p-0' }

  if (isPhysicalCard) {
    buttonProps.tag = Link
    buttonProps.to = '/cards/request-physical'
  } else if (isVirtualCard) {
    buttonProps.type = 'button'
    buttonProps.onClick = toggle
  }

  return (
    <>
      <div className='py-5 px-4 rp-max-w-100 credit-card-content'>
        <CreditCard size={32} weight='duotone' color='white' />

        <p className='font-size-16 mt-2 mb-0 text-white'>
          Your <u>{cardType}</u> card was terminated.
        </p>

        <Button
          style={{ height: 40 }}
          color='transparent'
          textClassName='text-secondary-50 font-weight-bold'
          iconRight={<ArrowRight size={16} color='var(--secondary-50)' />}
          {...buttonProps}
        >
          Request another card
        </Button>
      </div>

      {!isVirtualCard ? null : (
        <RequestNewVirtualCard
          isOpen={showModal}
          toggle={toggle}
          afterRequestNewCard={afterRequestNewCard}
        />
      )}
    </>
  )
}

function formatRequestAnotherCard(data) {
  return { card: { type: data?.card_type } }
}

function RequestNewVirtualCard({ isOpen, toggle, afterRequestNewCard }) {
  const { startFetch: requestCard, isLoading } = useFetch({
    action: requestCardForExistingUser,
    onComplete: () => {
      toggle()
      document.body.classList.remove('modal-open')
      if (typeof afterRequestNewCard === 'function') {
        afterRequestNewCard()
      }
    },
  })

  const { data: preConditions, isLoading: gettingPreConditions } = useFetch({
    action: cardsPreConditions,
    autoFetch: true,
  })

  const isVirtualCardSupported =
    preConditions?.card_configurations?.is_virtual_card_supported

  function handleRequestNewCard() {
    const body = formatRequestAnotherCard({ card_type: CARD_TYPE_ENUM.VIRTUAL })

    requestCard(body)
  }

  const submitDisabled =
    gettingPreConditions || !isVirtualCardSupported || isLoading

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='text-center mt-4'>
        {gettingPreConditions ? (
          <Loader minHeight='calc(211px - 2rem)' />
        ) : !isVirtualCardSupported ? (
          <>
            <CardXIllustration style={{ height: 120 }} />

            <p className='mt-4 mx-auto text-muted' style={{ maxWidth: '35ch' }}>
              You <u>cannot</u> request a new virtual card.
            </p>
          </>
        ) : (
          <>
            <CardIllustration style={{ height: 120 }} />

            <p className='mt-4 mx-auto text-muted' style={{ maxWidth: '35ch' }}>
              You can request a new virtual card.
            </p>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          disabled={isLoading}
          color='light'
          outline
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          disabled={submitDisabled}
          loading={isLoading}
          color='primary'
          onClick={handleRequestNewCard}
        >
          Request my card
        </Button>
      </ModalFooter>
    </Modal>
  )
}
