import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  OTP_VERIFICATION,
  OTP_VERIFICATION_SUCCESSFUL,
  OTP_VERIFICATION_FAILED,
  LOGOUT,
  LOGIN_USER_SUCCESSFUL,
  UPDATE_USER_INFO,
  UPDATE_DEEP_LINK,
  UPDATE_USER_ADMIN,
  UPDATE_RECAPTCHA_REQUIRED,
  REGISTER_CLEAR_ERROR,
  UPDATE_USER_DATA,
  UPDATE_USER,
  TOGGLE_LOGIN,
  LOGIN_ORIGINAL_USER_SUCCESSFUL,
  SAVE_ROUTE,
  SAVE_USER_PASSWORD,
  DELETE_USER_PASSWORD,
} from './actionTypes'

export const registerUser = (type, data, token, onSuccess) => {
  return {
    type: REGISTER_USER,
    payload: { type, data, token, onSuccess },
  }
}

export const toggleLogin = (value) => {
  return {
    type: TOGGLE_LOGIN,
    payload: value,
  }
}

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const loginUserSuccessful = (user) => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: user,
  }
}
export const loginOriginalUserSuccessful = (user) => {
  return {
    type: LOGIN_ORIGINAL_USER_SUCCESSFUL,
    payload: user,
  }
}

export const saveUserPassword = (password) => {
  return {
    type: SAVE_USER_PASSWORD,
    payload: password,
  }
}

export const deleteUserPassword = () => {
  return {
    type: DELETE_USER_PASSWORD,
  }
}

export const registerUserFailed = (user) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const clearError = () => {
  return {
    type: REGISTER_CLEAR_ERROR,
  }
}

export const verifyOTPRequest = (payload) => {
  return {
    type: OTP_VERIFICATION,
    payload,
  }
}

export const updateDeepLink = (payload) => {
  return {
    type: UPDATE_DEEP_LINK,
    payload,
  }
}

export const updateUserInfo = (payload) => {
  return {
    type: UPDATE_USER_INFO,
    payload,
  }
}

export const updateRecaptchaRequired = (payload) => {
  return {
    type: UPDATE_RECAPTCHA_REQUIRED,
    payload,
  }
}

export const updateUserAdmin = () => {
  return {
    type: UPDATE_USER_ADMIN,
  }
}

export const OTPSuccessful = (user) => {
  return {
    type: OTP_VERIFICATION_SUCCESSFUL,
    payload: user,
  }
}

export const OTPFailed = (user) => {
  return {
    type: OTP_VERIFICATION_FAILED,
    payload: user,
  }
}
export const updateUserData = (payload) => {
  return {
    type: UPDATE_USER_DATA,
    payload,
  }
}
export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    payload,
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
  }
}
export const saveRoute = (payload) => {
  return {
    type: SAVE_ROUTE,
    payload,
  }
}
