import cx from 'classnames'
import React from 'react'
import { Table } from 'reactstrap'

const TableComp = ({ children, className, tag, responsive }) => {
  const Tag = tag || Table

  return (
    <Tag
      responsive={responsive}
      className={cx('table table-nowrap tw-relative tw-mb-0', className)}
    >
      {children}
    </Tag>
  )
}

export default TableComp
