import React from 'react'

import LogoDark from './logo-dark'

export function HeaderAnonym() {
  return (
    <div
      className='bg-white d-flex justify-content-center align-items-center'
      style={{
        height: 'var(--header-height-anonym)',
        boxShadow: '0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea',
      }}
    >
      <LogoDark height={24} />
    </div>
  )
}
