import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Input, Label } from 'reactstrap'

import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'
import styles from './radio-list.module.scss'

export default function ControlledRadioList({
  options,
  name,
  control,
  error,
  transform,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, onChange } }) => {
        function handleChange(event) {
          const newValue = event.target.value
          onChange(newValue)
        }

        return (
          <RadioList
            options={options}
            value={
              typeof transform?.input === 'function'
                ? transform.input(value)
                : value
            }
            onChange={(...args) =>
              typeof transform?.output === 'function'
                ? handleChange(transform.output(...args))
                : handleChange(...args)
            }
            onBlur={onBlur}
            error={error}
            name={name}
            {...props}
          />
        )
      }}
    />
  )
}

function RadioList({
  options,
  name,
  onChange,
  onBlur,
  error,
  value: _checkValue,
  otherOption,
  label,
  labelClassName,
  fancyLabelClassName,
  horizontal,
  isFancy,
  ...props
}) {
  return (
    <div>
      {!label ? null : <label className={labelClassName}>{label}</label>}

      <div
        className={cx(
          'tw-flex',
          isFancy ? 'tw-gap-4' : 'tw-gap-1.5',
          !horizontal && 'tw-flex-col',
        )}
      >
        {options.map(({ value, label, icon: Icon, className }, i) => {
          const isChecked = value === _checkValue

          return (
            <RadioItemLabel
              key={`drop-${value ?? i}`}
              isFancy={isFancy}
              isChecked={isChecked}
              className={className}
            >
              <Input
                checked={value === _checkValue}
                type='radio'
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                style={{ marginTop: '0.25rem' }}
                invalid={!!error}
                className={isFancy ? 'tw-sr-only' : ''}
                {...props}
              />
              {!Icon ? null : (
                <span
                  style={{
                    '--size': '1.325rem',
                    width: 'var(--size)',
                    height: 'var(--size)',
                    color: isChecked ? null : 'var(--gray-500)',
                  }}
                  className='tw-flex tw-items-center tw-justify-center'
                >
                  <Icon isActive={isChecked} />
                </span>
              )}

              <span className={isFancy ? fancyLabelClassName : null}>
                {typeof label === 'function'
                  ? label({ isActive: isChecked })
                  : label}
              </span>
            </RadioItemLabel>
          )
        })}

        {!otherOption ? null : (
          <RadioItemLabel
            key='drop-other'
            isFancy={isFancy}
            isChecked={_checkValue === 'other'}
          >
            <Input
              checked={_checkValue === 'other'}
              type='radio'
              name={name}
              value='other'
              onChange={onChange}
              onBlur={onBlur}
              style={{ marginTop: '0.25rem' }}
              invalid={!!error}
              className={isFancy ? 'tw-sr-only' : ''}
              {...props}
            />{' '}
            <span>Other</span>
          </RadioItemLabel>
        )}
      </div>

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

function RadioItemLabel({ className, children, isFancy, isChecked }) {
  return (
    <Label
      className={cx(
        'tw-relative tw-mb-0 tw-flex tw-gap-1 tw-rounded tw-text-sm has-[:disabled]:tw-opacity-50',
        className,
        isFancy
          ? 'tw-items-center tw-justify-center tw-p-4'
          : 'tw-py-1 tw-pr-2',
        {
          [styles.active]: isChecked && isFancy,
          'tw-cursor-pointer has-[:not(:disabled)]:hover:tw-bg-surface-10':
            !isChecked,
        },
        isFancy && styles.rlOptionLabel,
      )}
      style={{
        paddingLeft: isFancy ? null : '1.625rem',
        flex: isFancy ? 1 : null,
      }}
    >
      {children}
    </Label>
  )
}
