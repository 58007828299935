import React from 'react'

import { cn } from 'ui'
import styles from './shimmer.module.css'

export default function Shimmer({
  rounded = true,
  duration = '1s',
  width = 100,
  height = 40,
  className,
}) {
  return (
    <div
      className={cn(
        'tw-relative tw-overflow-hidden tw-bg-surface-20',
        { 'tw-rounded': rounded },
        className,
      )}
      style={{
        width,
        height,
        '--duration': duration,
      }}
    >
      <div className={styles.shimmer} />
    </div>
  )
}
