import { Textbox } from '@phosphor-icons/react'
import React, { useState } from 'react'
import toastr from 'toastr'

import { PermissionTooltip } from '../../../components/permission-tooltip'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getAttributes, postCustomField } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { formatDate } from '../../time-off/time-off-list'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../components/settings-section-heading'
import { PERMISSION_GROUP } from '../manage-role'
import { EditCustomField } from './edit-custom-field'
import { FIELD_CONTRACT_TYPES, ManageCustomField } from './manage-custom-field'

export const CONTRACT_TYPE_LABEL_MAP = {
  fixed: CONTRACT_TYPES.FIXED,
  milestone: CONTRACT_TYPES.MILESTONES,
  payg: CONTRACT_TYPES.PAY_AS_YOU_GO,
  eor: CONTRACT_TYPES.FULL_TIME,
  full_time: CONTRACT_TYPES.FULL_TIME,
  direct_employee: CONTRACT_TYPES.DIRECT_EMPLOYEE,
}

export function CustomFieldsTab() {
  const columns = [
    { Header: 'Field title', accessor: 'name' },
    {
      Header: 'Type',
      accessor: 'attribute_type.name',
      className: 'tw-capitalize',
    },
    { Header: 'Area', accessor: 'area', className: 'tw-capitalize' },
    {
      Header: 'Contract type',
      accessor: 'contract_types',
      Cell: ({ cellData }) => {
        const hasAllContractTypes =
          cellData.length === Object.keys(FIELD_CONTRACT_TYPES).length

        return hasAllContractTypes
          ? 'All'
          : cellData.map((cell) => CONTRACT_TYPE_LABEL_MAP[cell]).join(', ')
      },
      className: 'tw-capitalize',
    },
    {
      Header: 'Required',
      accessor: 'required',
      Cell: ({ cellData }) => (Number(cellData) === 1 ? 'Yes' : 'No'),
    },
    {
      Header: 'Created on',
      accessor: 'created_at',
      Cell: ({ cellData }) => formatDate(cellData * 1000),
    },
    {
      Header: 'Visibility',
      accessor: 'active',
      Cell: ({ cellData }) => {
        const badgeProps = !cellData
          ? { status: 'danger', children: 'Hidden' }
          : { status: 'success', children: 'Visible' }

        return <BadgeV2 {...badgeProps} />
      },
    },
    {
      Header: '',
      accessor: 'status',
      Cell: ({ rowData }) => (
        <EditCustomField item={rowData} refetchFields={refetchFields} />
      ),
    },
  ]

  const {
    completed,
    data: customFieldsData,
    isLoading: loadingCustomFields,
    startFetch: refetchFields,
  } = useFetch({ action: getAttributes, autoFetch: true })

  if (loadingCustomFields) {
    return <Loader minHeight='28rem' />
  }

  return (
    <div className='tw-flex tw-flex-col tw-p-6' style={{ minHeight: '28rem' }}>
      <div className='tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/9015015-custom-fields'
            learnMoreTitle='Learn more about custom fields'
          >
            Custom fields
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            Add custom fields to contract details, timesheets, and work
            submissions
          </SettingSectionSubHeading>
        </div>

        <AddNewCustomField refetchFields={refetchFields} />
      </div>

      {(customFieldsData?.length <= 0 || !customFieldsData) && completed ? (
        <div className='tw-flex tw-h-72 tw-flex-grow tw-flex-col tw-items-center tw-justify-center'>
          <Textbox
            size={240}
            weight='duotone'
            className='tw-text-primary-100'
          />

          <p className='tw-mb-0 tw-text-2xl tw-font-medium'>No custom fields</p>
          <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
            Click on “Add New” to create a custom field.
          </p>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={customFieldsData}
          striped
          responsive
          fontClassName='!tw-text-text-100'
          className='tw-text-sm'
          bodyCellClassName='!tw-py-1'
          rowClassName='odd:!tw-bg-surface-10'
        />
      )}
    </div>
  )
}

function AddNewCustomField({ refetchFields }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  const { hasAccess } = usePermissions()
  const hasAddAttributeAccess = hasAccess(permissions.AddAttribute)

  const { isLoading: addingCustomField, startFetch: addCustomField } = useFetch(
    {
      action: postCustomField,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data.message || 'Failed to add custom field')
        } else {
          toastr.success('Custom field added successfully')
          toggle()
          refetchFields?.()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    },
  )

  return (
    <>
      <PermissionTooltip
        showing={!hasAddAttributeAccess}
        id='add-custom-field-tooltip-2'
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <Button
          color='link'
          className='!tw-px-0'
          type='button'
          onClick={toggle}
          disabled={!hasAddAttributeAccess}
        >
          Add New
        </Button>
      </PermissionTooltip>

      <ManageCustomField
        title='New custom field'
        subtitle='Create a custom field'
        state={{ show: isOpen }}
        toggle={toggle}
        onSubmit={(values) => {
          addCustomField(values)
        }}
        actionsLoading={addingCustomField}
      />
    </>
  )
}
