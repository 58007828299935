import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { BE_CONTRACT_CATEGORY, userTypes } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import { getFullTimeContractDetails } from '../../../services/api'
import { updateContract } from '../../../store/contract/actions'
import EmployeeDocsList from './employee-docs-list'

export default function EmployeeDocuments() {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const contractorType = userProfile?.contractor_type
  const userType = userProfile?.type

  const dispatch = useDispatch()
  const history = useHistory()

  const isFteEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  if (!isFteEmployee) {
    if (userType === userTypes.COMPANY) {
      history.replace('/activity')
    } else {
      history.replace('/documents')
    }
  }

  const contractDetails = useFetch({
    action: getFullTimeContractDetails,
    autoFetch: true,
    onComplete: (data) => {
      dispatch(updateContract(data))
    },
  })

  const isContractOngoing = contractDetails.data?.status?.id === 4

  return (
    <div className='page-content'>
      <EmployeeDocsList isContractOngoing={isContractOngoing} />
    </div>
  )
}
