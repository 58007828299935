import React, { useState } from 'react'
import { ApiKeysPaginatedTable } from './paginated-table'
import { GenerateApiKeyModal } from './generate-api-key-modal'
import { ArrowSquareOut } from '@phosphor-icons/react'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { getApiKeys } from '../../../services/openapi'
import toastr from 'toastr'
import Loader from '../../../components/ui/loader'
import ApiKeysEmptyState from './empty-state'
import Pagination from '../../../components/ui/pagination'

export default function ApiKeys({ limitPerPage = 10 }) {
  function onItemDeleted({ id, name }) {
    setListData((oldData) => {
      return oldData.filter((item) => item._id !== id)
    })
    const currentPage = paginator?.currentPage ?? 1

    if (!items.length || (items.length === 1 && items[0]._id === id)) {
      if (currentPage === 1) {
        fetchApiKeys({ page: 1, limit: limitPerPage })
      } else {
        const newPage = currentPage - 1
        fetchApiKeys({ page: newPage, limit: limitPerPage })
      }
    }
  }

  const {
    isLoading: isFetchingLoading,
    error: fetchApiKeysError,
    startFetch: fetchApiKeys,
    data: items,
    setData: setListData,
    paginator,
  } = useFetch({
    isOpenApi: true,
    autoFetch: true,
    body: { page: 1, limit: limitPerPage },
    action: getApiKeys,
    onError: (error) => {
      toastr.error(error)
    },
  })
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <GenerateApiKeyModal
        onNewKeyGenerated={() => fetchApiKeys({ page: 1, limit: limitPerPage })}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />
      <div className='tw-my-4 tw-flex tw-px-10'>
        <div className='tw-my-auto tw-flex tw-w-fit tw-font-bold'>
          API keys
          <a
            target='_blank'
            rel='noreferrer'
            className='tw-my-auto'
            href={`${process.env.REACT_APP_OPEN_API_HOST}`}
          >
            <ArrowSquareOut
              size='16px'
              weight='bold'
              className='tw-my-auto tw-ml-1 tw-text-secondary-100'
            />
          </a>
        </div>
        <div className='tw-ml-auto'>
          <div>
            <Button
              color='link'
              textClassName='tw-text-primary-100'
              onClick={() => setIsOpen(true)}
            >
              Generate API Key
            </Button>
          </div>
        </div>
      </div>
      <div className='tw-flex tw-w-full tw-justify-center'>
        {isFetchingLoading ? (
          <Loader minHeight='max(40vh,400px)' />
        ) : !items || items?.length === 0 ? (
          <ApiKeysEmptyState onGenerateClicked={() => setIsOpen(true)} />
        ) : (
          <div className='tw-w-full'>
            <ApiKeysPaginatedTable
              listItems={items}
              onItemDeleted={onItemDeleted}
            />
            <div className='tw-mt-8 tw-flex tw-justify-center'>
              <Pagination
                activePage={paginator?.currentPage ?? 1}
                onChange={(page) => fetchApiKeys({ page, limit: limitPerPage })}
                itemsCountPerPage={limitPerPage}
                totalItemsCount={paginator?.totalItemsCount ?? 0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
