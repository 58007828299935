import { ShieldCheck } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Alert, Col, Row } from 'reactstrap'

import KycPending from '../../assets/images/kyc-pending.svg'
import KycVerification from '../../assets/images/kyc-verification.svg'
import KycVerified from '../../assets/images/kyc-verified.svg'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import { requestKYC } from '../../services/api'
import { updateContractRef } from '../../store/profile/actions'
import capitalizeFirstLetter from '../../utils/capitalize-first-letter'

const NEEDS_INFO_STATUS = 'needs-info'
const KYC_PROVIDERS = { VERIFF: 'veriff', IDWISE: 'idwise' }

export default function Kyc({ isClient, userInfo }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const contractRef = useSelector((state) => state?.userProfile?.contractRef)

  const location = history.location
  const needsReverification = location.state?.needsReverification
  const missingDocs = !!needsReverification

  useEffect(() => {
    window.analytics.track('Viewed KYC', {
      email_id: user?.email,
    })
  }, [])

  useEffect(() => {
    if (
      userInfo?.kyc_verified === 1 &&
      !!contractRef &&
      contractRef?.type === 'kyc'
    ) {
      history.push(`/contract/detail?id=${contractRef?.ref}`)
      dispatch(updateContractRef(null))
    }
  }, [contractRef, userInfo?.kyc_verified, dispatch, history])

  const kycStatus = useMemo(() => {
    const firstName = userProfile?.first_name
    const lastName = userProfile?.last_name
    const birthDate = userProfile?.birth_date
    const country = userProfile?.country

    const needsInfo =
      !firstName || !lastName || (isClient ? !country : !birthDate)

    const kycStatus = needsInfo ? NEEDS_INFO_STATUS : userInfo?.kyc_status

    return kycStatus
  }, [
    userProfile?.first_name,
    userProfile?.last_name,
    userProfile?.birth_date,
    userProfile?.country,
    isClient,
    userInfo?.kyc_status,
  ])

  return (
    <Row className='p-4'>
      <Col>
        <div className='text-center py-3' style={{ minHeight: 330 }}>
          <img
            src={getKycProfile(kycStatus).illustration.src}
            alt={getKycProfile(kycStatus).illustration.alt}
            className='d-block mx-auto'
            style={{ height: '200px', width: '50%' }}
          />
          <h2 style={{ color: '#424652' }}>{getKycProfile(kycStatus).title}</h2>

          <VerificationDescription
            description={getKycProfile(kycStatus).description}
            provider={userInfo?.kyc_provider}
          />

          {kycStatus !== 'pending' ? null : (
            <Alert
              color='info'
              style={{ marginTop: '-1rem', maxWidth: '66ch' }}
              className='mx-auto'
            >
              Make sure you’ve entered your name exactly as it is shown on your
              passport or ID card. After you’ve been verified, you will not be
              able to edit it.
            </Alert>
          )}

          {kycStatus !== 'failed' ? null : (
            <Alert
              color='warning'
              style={{ marginTop: '-1rem', maxWidth: '66ch' }}
              className='mx-auto'
            >
              You’re last verification failed, please try again.
            </Alert>
          )}

          <RenderKYCAction
            status={kycStatus}
            missingDocs={missingDocs}
            kycProvider={userInfo?.kyc_provider}
            verifiedAt={userInfo?.kyc_verified_at}
            isClient={isClient}
          />
        </div>
      </Col>
    </Row>
  )
}

function VerificationDescription({ description, provider = '' }) {
  if (!description) return null

  if (typeof description === 'string') {
    return (
      <p
        className='text-muted w-75 font-size-16 mx-auto'
        style={{ marginBottom: '1.825rem' }}
      >
        {description}
      </p>
    )
  }
  if (typeof description === 'function') {
    return (
      <p
        className='text-muted w-75 font-size-16 mx-auto'
        style={{ marginBottom: '1.825rem' }}
      >
        {description({ provider })}
      </p>
    )
  }

  return React.cloneElement(description, {
    className: 'text-muted w-75 font-size-16 mx-auto',
    style: { marginBottom: '1.825rem' },
  })
}

function RenderKYCAction({
  status,
  kycProvider,
  verifiedAt,
  missingDocs,
  isClient,
}) {
  switch (status) {
    case NEEDS_INFO_STATUS: {
      return (
        <Link
          to={isClient ? '/profile-settings?tab=general' : '/settings#profile'}
          className='btn btn-primary'
        >
          Complete your profile
        </Link>
      )
    }

    case 'verified': {
      return (
        <>
          <VerifiedLine verifiedAt={verifiedAt} />

          {!missingDocs ? null : (
            <>
              <br />

              <p className='text-muted'>
                Your verification has expired. Please, re-verify.
              </p>

              <VerificationAction kycProvider={kycProvider} />
            </>
          )}
        </>
      )
    }

    case 'submitted': {
      return null
    }

    default: {
      return <VerificationAction kycProvider={kycProvider} />
    }
  }
}

export function VerificationAction({
  kycProvider,
  children = 'Get Verified Now',
  className = 'mx-auto',
  disabled = false,
}) {
  const user = useSelector((state) => state?.Account?.user)

  const currentPath =
    window.location.pathname + window.location.search + window.location.hash
  const callback = process.env.REACT_APP_OG_URL + currentPath

  const { startFetch: getVeriffLink, isLoading } = useFetch({
    action: requestKYC,
    body: { callback },
    onComplete: (data) => {
      window.location.replace(data?.url)
    },
  })

  function handleRequestKYC() {
    window.analytics.track('Clicked KYC button', {
      email_id: user?.email,
    })

    getVeriffLink()
  }

  return kycProvider === KYC_PROVIDERS.IDWISE ? (
    <Button
      tag={Link}
      to='/idwise-verification'
      className={className}
      disabled={disabled}
    >
      {children}
    </Button>
  ) : (
    <Button
      onClick={handleRequestKYC}
      loading={isLoading}
      disabled={isLoading || disabled}
      className={className}
    >
      {children}
    </Button>
  )
}

function VerifiedLine({ verifiedAt }) {
  return (
    <div className='tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-systemGreen-100'>
      <ShieldCheck weight='fill' className='tw-size-4' />

      <p className='tw-mb-0 tw-text-sm'>
        Identity verified
        {!verifiedAt
          ? null
          : ` on ${format(new Date(verifiedAt * 1000), 'MM/dd/yyyy hh:mm')}`}
      </p>
    </div>
  )
}

function getKycProfile(status) {
  switch (status) {
    case NEEDS_INFO_STATUS: {
      return {
        illustration: {
          src: KycVerification,
          alt: 'kyc verification illustration',
        },
        title: 'Identity unverified',
        description: (
          <p>
            Your profile info are not complete. Please add{' '}
            <strong>your personal information</strong> before starting the
            verification.
          </p>
        ),
      }
    }

    case 'failed':
    case 'pending': {
      return {
        illustration: {
          src: KycVerification,
          alt: 'kyc verification illustration',
        },
        title: 'Identity unverified',
        description: ({ provider }) =>
          `Identity verification only takes a few minutes. Have your ID handy and you will be taken to our ID partner ${capitalizeFirstLetter(
            provider,
          )}.`,
      }
    }
    case 'submitted': {
      return {
        illustration: {
          src: KycPending,
          alt: 'kyc verification pending illustration',
        },
        title: 'Verification in progress',
        description: '',
      }
    }
    case 'verified': {
      return {
        illustration: {
          src: KycVerified,
          alt: 'kyc verified illustration',
        },
        title: 'Verification completed',
        description: '',
      }
    }

    default: {
      return {
        illustration: { src: '', alt: '' },
        title: '',
        description: '',
      }
    }
  }
}
