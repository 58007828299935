export default function openFileV2(
  data,
  { callBack, type, isDataUrl, name, download } = {
    callBack: () => {},
    download: false,
  },
) {
  const url = isDataUrl ? data : URL.createObjectURL(new Blob([data], { type }))

  const link = document.createElement('a')
  link.href = url
  if (download) {
    link.download = name
  } else {
    link.target = '_blank'
    link.rel = 'noopener'
  }

  document.body.appendChild(link)
  link.click()
  if (!isDataUrl) {
    URL.revokeObjectURL(link.href)
  }
  link.remove()

  callBack?.()
}
