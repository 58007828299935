import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import uniqueId from 'lodash/uniqueId'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  Button as BsButton,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { CLIENT_INVOICE_STATUSES } from '.'
import ControlledSelect from '../../../../components/ControlledSelect'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import {
  getPartnerInvoiceDetails as getPartnerInvoiceDetailsAction,
  previewContractInvoice,
  submitContractInvoice,
} from '../../../../services/api'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'

// const MGT_FEES_STRING_OLD = 'Management fees'
export const MGT_FEES_STRING = 'Management fees (Inc. tax)'
const DESCRIPTION_STRING = 'Description'
const CAT_STRING = 'Category'
const AMOUNT_STRING = 'Amount'

const defaultFields = [
  {
    id: uniqueId(),
    desc_field: { name: DESCRIPTION_STRING },
    amount_field: { name: AMOUNT_STRING },
    classification: { name: CAT_STRING },
  },
]

function validate(arr) {
  if (!Array.isArray(arr) || arr.length === 0 || arr.every((a) => a[0])) {
    return true
  }

  const falseCheck = arr.find((a) => !a[0])
  const falseCheckFunc = falseCheck[1]
  if (typeof falseCheckFunc === 'function') {
    falseCheckFunc()
  }
  return false
}

const InvoiceForm = ({ onSubmit }) => {
  const [fieldsArray, setFieldsArray] = useState(defaultFields)
  const [invoiceName, setInvoiceName] = useState(null)
  const [dueDate, setDueDate] = useState(moment())

  const classifications = useSelector(
    (state) => state?.Layout?.staticData?.payment_classifications,
  )
  const classOptions = useMemo(() => {
    return classifications
      ? classifications.map(({ id, name }) => {
          return { value: id, label: name }
        })
      : []
  }, [classifications])

  const history = useHistory()
  const { state: contract } = useLocation()

  const generate = useFetch({
    action: previewContractInvoice,
    withAdminAccess: true,
    onError: (error) => {
      toastr.error(error)
    },
    onComplete: (data) => {
      downloadFile(data, 'Invoice-preview.pdf')
    },
  })
  const submitData = useFetch({
    action: submitContractInvoice,
    withAdminAccess: true,
    onComplete: () => {
      history.push('/admin/contracts-invoices')
      toastr.success('Invoice submitted successfully')
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }

  const {
    data: partnerInvoiceDetails,
    isLoading: partnerDetailsLoading,
    completed: partnerDetailsCompleted,
  } = useFetch({
    action: getPartnerInvoiceDetailsAction,
    withAdminAccess: true,
    autoFetch: true,
    body: { partner_invoice_id: contract?.partner_invoice_id },
    onComplete: (data) => {
      setCurrencyMarkup(data.markup_percentage)
      setFieldsArray((prevArray) => {
        const newData = []
        if (data.management_fee) {
          newData.push({
            id: uniqueId(),
            desc_field: { name: DESCRIPTION_STRING, value: MGT_FEES_STRING },
            amount_field: {
              name: AMOUNT_STRING,
              value: data.management_fee.toString(),
            },
          })
        }

        return [...prevArray, ...newData]
      })
    },
  })

  function validateFields() {
    const hasEmptyValue = fieldsArray.find(
      (e) =>
        e.desc_field?.value === undefined ||
        e.amount_field?.value === undefined ||
        e.classification?.value?.value === undefined,
    )

    const fieldsValid = validate([
      [!!invoiceName, () => toastr.error('Please enter invoice name')],
      [!!dueDate, () => toastr.error('Please enter due date')],
      [!hasEmptyValue, () => toastr.error('Please fill all invoice lines')],
    ])

    return fieldsValid
  }

  function submitForm() {
    const fieldsValid = validateFields()
    if (!fieldsValid) {
      return
    }

    const bodyData = {
      name: invoiceName,
      due_date: moment(dueDate).format('YYYY-MM-DD'),
      partner_invoice_id: contract?.partner_invoice_id,
      items: fieldsArray.map((e) => {
        const newItem = {
          desc: e.desc_field.value,
          amount: e.amount_field.value,
        }
        newItem.classification = e.classification?.value?.value
        return newItem
      }),
    }

    const resubmitting =
      contract?.client_invoice_status === CLIENT_INVOICE_STATUSES.DECLINED

    if (resubmitting) {
      bodyData.client_invoice_id = contract?.client_invoice_id
    }

    submitData.startFetch(bodyData)
  }

  function handlePreview() {
    const fieldsValid = validateFields()
    if (!fieldsValid) {
      return
    }

    generate.startFetch({
      due_date: moment(dueDate).format('YYYY-MM-DD'),
      contract_id: contract?.id,
      items: fieldsArray.map((e) => {
        return {
          desc: e.desc_field.value,
          amount: e.amount_field.value,
        }
      }),
    })
  }

  const schema = yup.object().shape({
    payroll: yup.array().of(
      yup.object().shape({
        desc_field: yup
          .object()
          .shape({
            name: yup.string().required(),
            value: yup.number().required(),
          })
          .required(),
        amount_field: yup
          .object()
          .shape({
            name: yup.string().required(),
            value: yup.number().required(),
          })
          .required(),
        classification: yup
          .object()
          .shape({
            name: yup.string().required(),
            value: yup.number().required(),
          })
          .required(),
      }),
    ),
    due_date: yup.string().required(),
    invoice_name: yup.string().required(),
  })

  const { handleSubmit, setValue, formState, control } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      contract_id: contract?.contract_id,
    },
  })

  const onAppend = () => {
    const newValue = {
      ...defaultFields,
      id: uniqueId(),
    }
    const newArray = [...fieldsArray, newValue]

    setFieldsArray(newArray)

    setTimeout(() => {
      setValue('payroll', newArray)
    }, 500)
  }

  const onChangeValue = (i, name, value) => {
    const newArray = fieldsArray.map((field, index) => {
      const newField = { ...field }
      if (index === i) {
        newField[name] = { ...newField[name], value }
      }
      return newField
    })
    setFieldsArray(newArray)
  }

  const validateArray = () => {
    return !fieldsArray.find(
      (e) =>
        e.desc_field?.value === undefined ||
        e.amount_field?.value === undefined,
    )
  }

  const onValidate = (v) => {
    if (validateArray()) {
      onSubmit({
        ...v,
        desc_field: fieldsArray.map((e) => e.desc_field),
        amount_field: fieldsArray.map((e) => e.amount_field),
        additional_text: '',
      })
    }
  }

  const usdFormatter = getCurrencyFormatter()
  const contractFormatter = getCurrencyFormatter(contract?.currency)

  const invoiceTotal = useMemo(() => {
    const value = fieldsArray.reduce(
      (prev, curr) => Number(prev || 0) + Number(curr.amount_field?.value || 0),
      0,
    )

    return value
  }, [fieldsArray])

  const totalDifference = useMemo(() => {
    let value = 0

    if (partnerInvoiceDetails?.total > 0) {
      value = invoiceTotal - partnerInvoiceDetails.total
    }

    return value
  }, [invoiceTotal, partnerInvoiceDetails?.total])

  const [currencyMarkup, setCurrencyMarkup] = useState()
  function handlePopulateFields() {
    const newFields = partnerInvoiceDetails.items.map(({ desc, amount }) => {
      // Value + markup
      const value = (
        Number(amount) +
        Number(amount) * (Number(currencyMarkup ?? 0) / 100)
      ).toString()

      return {
        id: uniqueId(),
        desc_field: { name: DESCRIPTION_STRING, value: desc },
        amount_field: {
          name: AMOUNT_STRING,
          value: parseFloat(value).toFixed(2),
        },
      }
    })

    if (partnerInvoiceDetails.management_fee) {
      const value = partnerInvoiceDetails.management_fee.toString()
      newFields.push({
        id: uniqueId(),
        desc_field: { name: DESCRIPTION_STRING, value: MGT_FEES_STRING },
        amount_field: { name: AMOUNT_STRING, value },
      })
    }

    setFieldsArray(newFields)
  }

  return (
    <CardBody className='m-0 p-0'>
      <Form
        autoComplete='off'
        className='form-horizontal m-0 pt-3 pt-md-4'
        onSubmit={handleSubmit(onValidate)}
      >
        <Row className='p-0 m-0'>
          <Col md={6} className='mb-2 mb-md-0'>
            <p className='m-0 font-size-17'>{contract.contractor_name}</p>
            <p className='m-0 font-size-14'>
              <strong>Contract ID:</strong> {contract?.id}
            </p>
            <p className='m-0 font-size-14'>{contract?.contract_name}</p>
            <p className='m-0 font-size-14'>{contract.client_name}</p>
            <p className='m-0 font-size-14'>
              {contractFormatter.format(contract?.salary)}
            </p>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor='invoice-name'>Invoice name:</Label>
              <Input
                type='text'
                className={cx({
                  'form-control': true,
                  'border-danger': !invoiceName && formState.submitCount > 0,
                })}
                id='invoice-name'
                value={invoiceName}
                onChange={(e) => {
                  setInvoiceName(e.target.value)
                }}
                placeholder='Invoice name'
              />
            </FormGroup>
            <FormGroup>
              <Label>Invoice due date:</Label>
              <CustomDatePicker
                placeholder='Filter by month'
                dateFormat='yyyy-MM-dd'
                value={dueDate}
                handleOnChange={(v) => {
                  setDueDate(v)
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Container fluid>
          <Row>
            <Col md={4} className='pr-0'>
              <div className='bg-light py-4 rounded'>
                {partnerDetailsLoading || !partnerDetailsCompleted ? (
                  <div className='px-4'>Loading partner invoice details...</div>
                ) : (
                  <>
                    <h5 className='px-4'>{partnerInvoiceDetails.details}</h5>
                    <Row className='m-0 flex-grow-1' style={{ gap: '1rem' }}>
                      <Col
                        xs={12}
                        className='d-flex flex-column'
                        style={{ gap: '0.125rem' }}
                      >
                        {(partnerInvoiceDetails.items ?? []).map(
                          ({ desc, amount }, index) => {
                            return (
                              <Row
                                key={index}
                                className='mx-n2'
                                style={{ borderBottom: '1px solid #dee5f7' }}
                              >
                                <Col xs={6} className='rp-font-bold px-2'>
                                  {desc}
                                </Col>
                                <Col
                                  xs={6}
                                  className='text-right rp-font-mono px-2'
                                >
                                  {usdFormatter.format(amount)}
                                </Col>
                              </Row>
                            )
                          },
                        )}
                      </Col>
                      <div className='d-flex justify-content-end pr-4 w-100'>
                        <ShowTotal
                          label='Total:'
                          total={usdFormatter.format(
                            partnerInvoiceDetails.total,
                          )}
                        />
                      </div>
                    </Row>
                  </>
                )}
              </div>
            </Col>
            <Col md={8}>
              <div
                className='d-flex justify-content-between align-items-baseline border p-2 mb-3 rounded'
                style={{ gap: '1rem' }}
              >
                <h5 className='mb-0'>Auto-populate</h5>
                <div
                  className='d-flex align-items-baseline'
                  style={{ gap: '0.75rem' }}
                >
                  <InputGroup size='sm' style={{ width: 165 }}>
                    <Input
                      bsSize='sm'
                      name='invoice-markup'
                      placeholder='Markup: 0%, 1%, 5%, ...'
                      type='number'
                      className='rounded-left'
                      value={currencyMarkup}
                      onChange={(event) =>
                        setCurrencyMarkup(event.target.value)
                      }
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText className='rounded-right'>
                        %
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <BsButton
                    size='sm'
                    color='info'
                    className='rounded'
                    style={{ whiteSpace: 'nowrap' }}
                    type='button'
                    onClick={handlePopulateFields}
                    disabled={partnerDetailsLoading || !partnerDetailsCompleted}
                  >
                    Auto-populate
                  </BsButton>
                </div>
              </div>
              {fieldsArray.map(
                (
                  {
                    id,
                    desc_field: descField,
                    amount_field: amountField,
                    classification,
                  },
                  index,
                ) => {
                  const isDisabledField = [MGT_FEES_STRING].includes(
                    descField?.value,
                  )

                  const showDeleteButton = !isDisabledField

                  return (
                    <Container
                      key={`ww-${id}`}
                      className='px-0 rounded hover:bg-light'
                    >
                      <Row id={`ww-${id}`} className='mx-n1 px-1 pt-1 pb-2'>
                        <Col md={4} className='px-1'>
                          <FormGroup className='mb-0'>
                            <Label>{DESCRIPTION_STRING}:</Label>
                            <Input
                              className={cx({
                                'border-danger':
                                  !descField?.value &&
                                  formState.submitCount > 0,
                              })}
                              placeholder={DESCRIPTION_STRING}
                              value={descField?.value}
                              onChange={(v) => {
                                onChangeValue(
                                  index,
                                  'desc_field',
                                  v.target.value,
                                )
                              }}
                              disabled={[MGT_FEES_STRING].includes(
                                descField?.value,
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4} className='px-1'>
                          <FormGroup className='mb-0'>
                            <Label>{AMOUNT_STRING}:</Label>
                            <InputGroup>
                              <CurrencyInput
                                className={cx(
                                  {
                                    'border-danger':
                                      !amountField?.value &&
                                      formState.submitCount > 0,
                                  },
                                  'form-control rounded-left',
                                )}
                                placeholder={AMOUNT_STRING}
                                value={amountField?.value}
                                onValueChange={(value) =>
                                  onChangeValue(index, 'amount_field', value)
                                }
                                disabled={[MGT_FEES_STRING].includes(
                                  descField?.value,
                                )}
                                // currency input props
                                allowDecimals={true}
                                decimalsLimit={2}
                                decimalSeparator='.'
                                groupSeparator=','
                              />
                              {/* TODO: remove InputGroupAddon components */}
                              <InputGroupAddon addonType='append'>
                                <InputGroupText>
                                  {contract?.currency}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={4} className='px-1'>
                          <FormGroup className='mb-0'>
                            <Label>{CAT_STRING}:</Label>
                            <ControlledSelect
                              name='classification'
                              control={control}
                              placeholder={CAT_STRING}
                              controlOnChange={(v) => {
                                onChangeValue(index, 'classification', v)
                              }}
                              controlValue={() =>
                                classOptions.find(
                                  (e) => e.value === classification?.value,
                                )?.value
                              }
                              options={classOptions}
                              error={
                                !classification?.value &&
                                formState.submitCount > 0
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {!showDeleteButton ? null : (
                        <div className='d-flex justify-content-end pb-1 pr-1'>
                          <button
                            className='rp-btn-nostyle text-danger font-size-14 hover:bg-soft-danger'
                            type='button'
                            onClick={() => {
                              const newArray = [...fieldsArray]
                              newArray.splice(index, 1)
                              setFieldsArray(newArray)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </Container>
                  )
                },
              )}
              <Row>
                <Col className='d-flex justify-content-end py-1'>
                  <button
                    className='rp-btn-nostyle text-primary font-size-14 hover:bg-soft-primary'
                    type='button'
                    onClick={onAppend}
                  >
                    Add field
                  </button>
                </Col>
                <Col md={{ size: 6, offset: 6 }} className='mt-2'>
                  <ShowTotal
                    label='Total:'
                    total={contractFormatter.format(invoiceTotal)}
                  />
                </Col>
                <Col md={{ size: 6, offset: 6 }}>
                  <ShowTotal
                    label='Difference:'
                    total={contractFormatter.format(totalDifference)}
                    textColor={
                      totalDifference === 0
                        ? 'text-info'
                        : totalDifference < 0
                          ? 'text-danger'
                          : 'text-success'
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div
          className='d-flex justify-content-center pb-3 pt-4 px-4'
          style={{ gap: '0 1rem' }}
        >
          <Button
            loading={generate.isLoading}
            disabled={generate.isLoading}
            onClick={handlePreview}
          >
            Preview
          </Button>
          <Button
            color='success'
            loading={submitData.isLoading}
            disabled={submitData.isLoading}
            onClick={submitForm}
          >
            Submit
          </Button>
        </div>
      </Form>
    </CardBody>
  )
}

export function ShowTotal({ label, total, textColor = 'text-muted' }) {
  return (
    <>
      <span className='rp-font-bold font-size-14'>{label}</span>
      <span className={cx('font-size-14 rp-font-mono pl-2', textColor)}>
        {total}
      </span>
    </>
  )
}

export default InvoiceForm
