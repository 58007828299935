import { ArrowSquareOut } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'

export function SettingSectionHeading({
  children,
  learnMoreLink,
  learnMoreTitle = '',
}) {
  return (
    <h4 className='tw-mb-0.5 tw-flex tw-items-center tw-gap-2 tw-text-base tw-font-bold'>
      <span>{children}</span>
      {!learnMoreLink ? null : (
        <a
          href={learnMoreLink}
          target='_blank'
          rel='noreferrer'
          className='tw-text-current'
          title={learnMoreTitle}
        >
          <ArrowSquareOut size={20} />
        </a>
      )}
    </h4>
  )
}

export function SettingSectionSubHeading({ children, className }) {
  return (
    <p className={cx('tw-max-w-prose tw-text-sm tw-text-text-80', className)}>
      {children}
    </p>
  )
}
