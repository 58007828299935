import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowDown, ArrowRight } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Input, Row } from 'reactstrap'
import * as yup from 'yup'

import ControlledSelect from '../../../components/ControlledSelect'
import { freshSubmitAccountMapping } from '../../../store/fresh/actions'
import { submitNetSuiteAccountMapping } from '../../../store/ns/actions'
import { qbSubmitAccountMapping } from '../../../store/qb/actions'
import { submitAccountMapping } from '../../../store/xero/actions'
import { submitZohoAccountMapping } from '../../../store/zoho/actions'

const XeroAccountMapping = ({
  onSubmitted = () => {},
  context,
  contractor,
  isUpdate,
  platform = 'Xero',
}) => {
  const [categories, setCategories] = useState([])

  const data = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.preData
      case 'Quickbooks':
        return state.qb?.preData
      case 'Freshbooks':
        return state.fresh?.preData
      case 'Zoho':
        return state.zoho?.preData
      case 'NetSuite':
        return state.ns?.preData
    }
  })

  const defaultMapping = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.accountMapping
      case 'Quickbooks':
        return state.qb?.accountMapping
      case 'Freshbooks':
        return state.fresh?.accountMapping
      case 'Zoho':
        return state.zoho?.accountMapping
      case 'NetSuite':
        return state.ns?.accountMapping
    }
  })
  useEffect(() => {
    setCategories(data?.categories || [])
  }, [data])

  const dispatch = useDispatch()

  let defaultValues = {}
  if (
    contractor?.categories?.length > 0 &&
    Array.isArray(contractor?.categories)
  ) {
    contractor.categories.forEach((e) => {
      switch (platform) {
        case 'Xero':
          defaultValues[e.category_id] = e.xero_id
          break
        case 'Quickbooks':
          defaultValues[e.category_id] = e.qb_id
          break
        case 'Freshbooks':
          defaultValues[e.category_id] = e.fresh_id
          break
        case 'Zoho':
          defaultValues[e.category_id] = e.zoho_id
          break
        case 'NetSuite':
          defaultValues[e.category_id] = e.ns_id
          break
      }
    })
  } else if (
    !!contractor?.categories &&
    !Array.isArray(contractor?.categories)
  ) {
    defaultValues = contractor?.categories
  } else {
    defaultValues = defaultMapping
  }

  function getSchema() {
    const schema = {}
    categories.forEach((c) => {
      schema[`${c.category_id}`] = yup.string().required()
    })
    return yup.object().shape(schema)
  }
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
    defaultValues,
  })

  function onSubmit(values) {
    onSubmitted(values)
    if (!contractor && !isUpdate) {
      switch (platform) {
        case 'Xero':
          dispatch(submitAccountMapping(values))
          break
        case 'Freshbooks':
          dispatch(freshSubmitAccountMapping(values))
          break
        case 'Quickbooks':
          dispatch(qbSubmitAccountMapping(values))
          break
        case 'Zoho':
          dispatch(submitZohoAccountMapping(values))
          break
        case 'NetSuite':
          dispatch(submitNetSuiteAccountMapping(values))
          break
      }
    }
  }

  const accounts =
    platform === 'Freshbooks'
      ? data?.accounts?.reduce(
          (acc, current) => [
            ...acc,
            { value: current.account_id.toString(), label: `${current.name}` },
            ...current.list.map((a) => ({
              value: a.account_id.toString(),
              label: ` ${a.name}`,
              isChild: true,
            })),
          ],
          [],
        )
      : data?.accounts?.map((a) => ({
          value: a.account_id,
          label: platform === 'Xero' ? `${a.account_id} - ${a.name}` : a.name,
        }))

  return (
    <form
      id={`xeroAccountMappingFrom-${context}`}
      autoComplete='off'
      className='tw-mt-4'
      onSubmit={(event) => {
        if (event.target.id === `xeroAccountMappingFrom-${context}`) {
          handleSubmit(onSubmit)(event)
        }
      }}
    >
      <Row className='!tw-mx-0 !tw-hidden tw-w-full tw-items-center tw-justify-between md:!tw-flex'>
        <Col className='tw-mb-3 !tw-pl-0' tag='h6'>
          Categories
        </Col>

        <Col className='tw-mb-3' tag='h6'>
          Accounts
        </Col>
      </Row>
      {categories.map((e, i) => {
        return (
          <Row
            key={`${e.category_id}-${i}`}
            className='!tw-mx-0 tw-mb-6 tw-w-full tw-flex-col tw-items-center tw-justify-between tw-p-0 md:tw-mb-4 md:tw-flex-row'
          >
            <Col className='!tw-px-0 md:!tw-pr-4'>
              <Input value={e.name} name={e.category_id} disabled />
            </Col>
            <div className='tw-w-5 tw-text-primary-100'>
              <ArrowRight
                weight='bold'
                className='tw-hidden tw-size-full md:tw-block'
              />
              <ArrowDown
                weight='bold'
                className='tw-my-1 tw-size-full md:tw-hidden'
              />
            </div>
            <Col md={6} className='!tw-px-0 md:!tw-pl-4'>
              <ControlledSelect
                name={`${e.category_id}`}
                error={errors[`${e.category_id}`]}
                control={control}
                options={accounts}
              />
            </Col>
          </Row>
        )
      })}
      <button
        form={`xeroAccountMappingFrom-${context}`}
        type='submit'
        id={`submit-xero-account-mapping-${context}`}
        className='tw-hidden'
      />
    </form>
  )
}

export default XeroAccountMapping
