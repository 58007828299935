import {
  CheckCircle,
  FilePlus,
  Spinner,
  X,
  XCircle,
} from '@phosphor-icons/react'
import React from 'react'
import { Progress } from 'reactstrap'

import { cn } from 'ui'
import ControlledDropzoneInput from '../../../components/controlled-dropzone-input'
import Button from '../../../components/ui/button'
import { IconButtonSimple } from '../../../components/ui/icon-button-simple'
import { MAX_SIZE_READABLE } from '../utils/constants'

export const STATES = {
  INITIAL: 'INITIAL',
  UPLOADING: 'UPLOADING',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_FAILED: 'UPLOAD_FAILED',
}

export function UploadFlow({
  control,
  name,
  id,
  title,
  description,
  endClassName,
  startClassName,
  accept,
  acceptLabel,
  maxSize,
  state = STATES.INITIAL,
  files,
  error,
  progressValue = 0,
  onClickUpload,
  onClickRemove,
}) {
  const classes =
    'tw-min-h-[98px] tw-rounded tw-border tw-border-surface-30 tw-bg-primary-20 tw-p-6'

  if (state === STATES.INITIAL) {
    return (
      <ControlledDropzoneInput
        control={control}
        name={name}
        id={id || name}
        className={cn(
          classes,
          'tw-flex tw-w-full tw-flex-wrap tw-items-start tw-justify-between tw-gap-4 tw-border-dashed tw-border-primary-100 tw-text-start',
        )}
        accept={accept}
        onDropAccepted={onClickUpload}
      >
        <UploadAreaContent
          startClassName={startClassName}
          endClassName={endClassName}
          title={title}
          description={description}
          acceptLabel={acceptLabel}
          maxSize={maxSize}
        />
      </ControlledDropzoneInput>
    )
  } else if (state === STATES.UPLOADING) {
    return (
      <div className={classes}>
        <div className='tw-flex tw-items-start tw-justify-between tw-gap-3'>
          <div>
            <p className='tw-mb-0 tw-text-base tw-font-bold'>Contracts</p>
            {files?.map((file, index) => {
              return (
                <p
                  className={cn(
                    'tw-mb-0 tw-text-sm tw-text-text-80',
                    index === 0 ? 'tw-mt-2' : 'tw-mt-0.5',
                  )}
                  key={file.name}
                >
                  {file.name}
                </p>
              )
            })}
          </div>
          <div className='tw-text-end'>
            <div className='tw-flex tw-items-center tw-gap-2 tw-text-primary-100'>
              <Spinner
                size={20}
                className='tw-animate-[spin_2s_linear_infinite]'
              />
              <p className='tw-mb-0'>Uploading</p>
            </div>
            <p className='tw-mb-0 tw-mt-2 tw-text-xs tw-text-secondary-80'>
              {progressValue}%
            </p>
          </div>
        </div>
        <Progress
          value={progressValue}
          className='tw-mt-4 !tw-h-1 !tw-bg-primary-50'
        />
      </div>
    )
  } else if (state === STATES.UPLOAD_SUCCESS) {
    return (
      <div
        className={cn(
          classes,
          'tw-flex tw-items-center tw-justify-between tw-gap-3 tw-bg-systemGreen-10',
        )}
      >
        <div className='tw-space-y-2'>
          <div className='tw-space-y-1'>
            {files?.map((file) => {
              return (
                <div key={file.name} className='tw-text-base tw-font-bold'>
                  {file.name}
                </div>
              )
            })}
          </div>
          <div className='tw-flex tw-items-center tw-gap-2 tw-text-systemGreen-100'>
            <CheckCircle size={16} />
            <p className='tw-mb-0 tw-text-sm tw-text-systemGreen-100'>
              Uploaded successfully!
            </p>
          </div>
        </div>
        <IconButtonSimple icon={<X size={20} />} onClick={onClickRemove} />
      </div>
    )
  } else if (state === STATES.UPLOAD_FAILED) {
    return (
      <div
        className={cn(
          classes,
          'tw-flex tw-items-center tw-justify-between tw-gap-3 tw-bg-systemRed-10',
        )}
      >
        <div>
          <div className='tw-flex tw-items-center tw-gap-2 tw-text-systemRed-100'>
            <XCircle size={16} />
            <p className='tw-mb-0 tw-text-sm tw-text-systemRed-100'>
              Upload failed!
            </p>
          </div>
          {!error ? null : (
            <div className='tw-mt-2 tw-text-sm tw-font-semibold tw-text-systemRed-120'>
              {error}
            </div>
          )}
        </div>
        <IconButtonSimple icon={<X size={20} />} onClick={onClickRemove} />
      </div>
    )
  }

  return <div>Impossible state</div>
}

export function UploadAreaContent({
  title,
  description,
  maxSize = MAX_SIZE_READABLE,
  acceptLabel = 'PDF only',
  startClassName,
  endClassName,
}) {
  const acceptText = [
    !acceptLabel ? null : `*${acceptLabel}`,
    !maxSize ? null : `Max file size ${maxSize}`,
  ]
    .filter(Boolean)
    .join('. ')

  return (
    <>
      <div className={startClassName}>
        <p className='tw-mb-0 tw-text-base tw-font-bold'>{title}</p>
        <p className='tw-mb-0 tw-mt-2 tw-text-sm tw-text-text-80'>
          {description}
        </p>
      </div>

      <div className={cn('md:tw-text-end', endClassName)}>
        <Button
          icon={<FilePlus size={20} />}
          color='link'
          className='!tw-p-0'
          tag='div'
        >
          <div className='tw-hidden md:tw-block'>
            Drop file here or click to upload
          </div>
          <div className='md:tw-hidden'>Click to upload</div>
        </Button>

        {!acceptText ? null : (
          <p className='tw-mb-0 tw-mt-2 tw-text-xs tw-text-secondary-80'>
            {acceptText}
          </p>
        )}
      </div>
    </>
  )
}
