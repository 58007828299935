import React, { useState } from 'react'
import {
  AddBenefitDocumentToFTContract,
  assignInsuranceProviderToFTContract,
  getAdminEORRegionConfig,
  uploadTempFileAdmin,
} from '../../../../../services/api'
import { useFetch } from '../../../../../helpers/hooks'
import classnames from 'classnames'
import { CheckCircle, Circle, FastForward } from '@phosphor-icons/react'
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import DataTable from '../../../../../components/ui/data-table'
import {
  FILE_SIZE_LIMITS_IN_BYTES,
  MAX_SIZE_5MB_READABLE,
} from '../../../../Contract/utils/constants'
import DropzoneInput from '../../../../../components/Common/dropzone-input'
import ControlledDatePicker from '../../../../../components/ControlledDatePicker'
import * as yup from 'yup'
import toastr from 'toastr'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Loader from '../../../../../components/ui/loader'
import Button from '../../../../../components/ui/button'
import ControlledSelect from '../../../../../components/ControlledSelect'
import ControlledInput from '../../../../../components/ControlledInput'
const documentsTypes = [
  { value: 'health_insurance', label: 'Health insurance' },
  { value: 'other', label: 'Other' },
]
export default function BenefitsStep({ contract, refetchContract }) {
  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => {
    setIsOpen(false)
  }

  const {
    working_from_state: regionState,
    working_from_country: regionCountry,
  } = contract?.employee ?? {}
  const { data: regionConfig } = useFetch(
    {
      action: getAdminEORRegionConfig,
      autoFetch: regionCountry?.id || regionState?.id,
      withAdminAccess: true,
      body: {
        region_id: regionState?.id ?? regionCountry?.id,
        region_type: regionState?.id ? 'state' : 'country',
      },
    },
    [regionCountry, regionState],
  )
  const assignInsurance = useFetch({
    action: assignInsuranceProviderToFTContract,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Health provider assigned successfully')
      refetchContract({ id: contract?.id }, false)
    },
  })
  const columns = [
    {
      Header: 'Identifier',
      accessor: 'identifier',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Issue Date',
      accessor: 'issue_date',
    },
    {
      Header: 'Expiry Date',
      accessor: 'expiry_date',
    },
  ]
  return (
    <>
      <Label className='text-gray-h font-size-16 my-4' tag='div'>
        Health Insurance
      </Label>
      <Row>
        <Col
          className='d-flex align-items-center font-size-14 mb-3'
          style={{ gap: 8 }}
        >
          {contract?.insurance &&
          regionConfig?.supported_insurance_providers?.length > 0 ? (
            <div className='tw-grid tw-grid-cols-2 tw-gap-4 tw-rounded-bl tw-rounded-br tw-border-b tw-border-l tw-border-r tw-border-surface-30 tw-bg-surface-10 tw-p-4 sm:tw-grid-cols-3'>
              {[
                ...regionConfig.supported_insurance_providers,
                !contract?.insurance_provider?.id && { id: 'later' },
              ]
                .filter(Boolean)
                ?.map((item) => {
                  const isSelected =
                    contract?.insurance_provider?.id === item.id
                  return (
                    <div className='tw-p-2' key={item.id}>
                      <label
                        onClick={() =>
                          assignInsurance.startFetch({
                            contract_id: contract?.id,
                            insurance_provider_id: item?.id,
                          })
                        }
                        className='tw-block tw-h-full tw-w-full tw-cursor-pointer'
                      >
                        <div
                          className={classnames(
                            'tw-h-full tw-rounded tw-p-4 tw-outline',
                            isSelected
                              ? 'tw-bg-primary-10 tw-outline-2 tw-outline-primary'
                              : 'tw-bg-white tw-outline-1 tw-outline-surface-30',
                          )}
                        >
                          <div className='tw-flex tw-h-full tw-items-start tw-justify-between'>
                            <div className='tw-flex tw-h-full tw-w-[80%] tw-flex-col tw-justify-center'>
                              {item.id === 'later' ? (
                                <>
                                  <FastForward
                                    size={24}
                                    color='var(---secondary)'
                                  />
                                  <div className='tw-mt-2 tw-font-semibold'>
                                    Choose Later
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <img
                                      className='tw-mb-2 tw-max-h-6 tw-w-auto'
                                      src={item.logo}
                                      alt={item.name}
                                    />
                                  </div>
                                  <div className='tw-mb-2 tw-text-sm tw-font-semibold tw-text-black'>
                                    {item.title}
                                  </div>
                                  <div className='tw-font-semibold'>
                                    {item.name}
                                  </div>
                                  <div className='tw-font-semibold'>
                                    {item.starting_price}
                                  </div>
                                </>
                              )}
                            </div>
                            {isSelected ? (
                              <CheckCircle
                                size={20}
                                weight='fill'
                                color='var(--primary)'
                              />
                            ) : (
                              <Circle size={20} color='var(--secondary-50)' />
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                  )
                })}
            </div>
          ) : null}
        </Col>
      </Row>
      {contract?.benefit_documents?.length > 0 && (
        <div className='tw-mb-6'>
          <DataTable
            responsive
            columns={columns}
            data={contract?.benefit_documents}
          />
        </div>
      )}
      <Button block color='primary' onClick={() => setIsOpen(true)}>
        Add benefit document
      </Button>
      {isOpen && (
        <AddBenefitDocument
          isOpen={isOpen}
          closeModal={closeModal}
          refetch={refetchContract}
          contractId={contract?.id}
        />
      )}
    </>
  )
}

function AddBenefitDocument({ isOpen, closeModal, refetch, contractId }) {
  const schema = yup.object().shape({
    identifier: yup.string().required(),
    type: yup.string().required(),
    name: yup.string().when('type', {
      is: (val) => val === 'other',
      then: (schema) => schema.required('Name is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    issue_date: yup.string().required(),
    expiry_date: yup.string().required(),
    file: yup.mixed().required('Document file is required'),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const { startFetch: uploadFile, isLoading: isUploading } = useFetch({
    action: uploadTempFileAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      setValue('file', data?.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: submitBenefitDocument, isLoading: isSubmitting } =
    useFetch({
      action: AddBenefitDocumentToFTContract,
      withAdminAccess: true,
      onComplete: () => {
        toastr.success('Benefit document added successfully')
        closeModal()
        refetch?.()
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const onSubmit = (data) => {
    submitBenefitDocument({
      ...data,
      contract_id: contractId,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Upload benefit document</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Label htmlFor='identifier' className='tw-mt-4'>
            Identifier
            <span className='text-danger font-size-16 mx-1'>*</span>
          </Label>
          <ControlledInput
            control={control}
            name='identifier'
            error={errors?.identifier}
          />
          <Label htmlFor='type' className='tw-mt-4'>
            Document type <span className='tw-text-sm tw-text-red'>*</span>
          </Label>
          <ControlledSelect
            control={control}
            name='type'
            error={errors?.type?.message}
            options={documentsTypes}
          />
          {watch('type') === 'other' ? (
            <>
              <Label htmlFor='name' className='tw-mt-4'>
                Name <span className='tw-text-sm tw-text-red'>*</span>
              </Label>
              <ControlledInput
                control={control}
                name='name'
                error={errors?.name}
              />
            </>
          ) : null}
          <Label htmlFor='issue_date' className='tw-mt-4'>
            Issue date <span className='tw-text-sm tw-text-red'>*</span>
          </Label>
          <ControlledDatePicker
            control={control}
            name='issue_date'
            error={errors?.issue_date}
            maxDate={new Date()}
          />
          <Label htmlFor='issue_date' className='tw-mt-4'>
            Expiry Date <span className='tw-text-sm tw-text-red'>*</span>
          </Label>
          <ControlledDatePicker
            control={control}
            name='expiry_date'
            error={errors?.expiry_date}
            minDate={new Date()}
          />
          <DropzoneInput
            className='tw-mt-4'
            onDropAccepted={(acceptedFiles) => {
              uploadFile({
                file: acceptedFiles[0],
                type: 'benefit_documents',
              })
            }}
            accept='application/pdf'
            maxSize={FILE_SIZE_LIMITS_IN_BYTES.FIVE_MB}
            error={errors?.file?.message}
          >
            <div className='tw-flex tw-h-[100px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center'>
              {isUploading ? (
                <Loader />
              ) : watch('file') ? (
                <>
                  <span>File Uploaded</span>
                  <span className='tw-w-full tw-overflow-hidden tw-text-ellipsis tw-text-sm tw-text-text-80'>
                    {watch('file')}
                  </span>
                </>
              ) : (
                <>
                  <span>Drop file or click here</span>
                  <span className='tw-text-sm tw-text-text-60'>
                    Max file size {MAX_SIZE_5MB_READABLE}
                  </span>
                </>
              )}
            </div>
          </DropzoneInput>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
