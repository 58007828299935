import React from 'react'

import { cn } from 'ui'

export function IconButtonSimple({
  icon,
  onClick,
  className,
  type = 'button',
  ariaLabel,
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={cn(
        'tw-rounded tw-bg-secondary-10 tw-p-2 tw-transition-all hover:tw-bg-secondary-20 focus-visible:tw-ring-2 focus-visible:tw-ring-primary-20 focus-visible:tw-ring-offset-1 focus-visible:tw-ring-offset-secondary-40',
        className,
      )}
      aria-label={ariaLabel}
    >
      {icon}
    </button>
  )
}
