import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { exportWithdraw } from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'

const statuses = [
  { label: 'All', value: '' },
  { label: 'Processed', value: 'processed' },
  { label: 'Confirmed', value: 'confirmed' },
]

const schema = yup.object().shape({
  provider: yup.string().required('The provider field is required.'),
})

const exportFormId = 'export-trx-form-id'

function ExportTrxModal({ isOpen, toggle, title }) {
  const providers = useSelector(
    (state) => state?.Layout?.staticData?.payment_providers,
  )

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      provider: providers?.[0]?.code,
      status: statuses[0].value,
    },
  })

  function exportFile(data, body) {
    const fileName = [
      body.month,
      body.status?.value || body.status || 'all',
      'transactions',
      body.provider,
    ]
      .filter(Boolean)
      .join('_')

    const nameWithExt = fileName + '.xlsx'

    openFileV2(data, {
      download: true,
      name: nameWithExt,
      callBack: toggle,
    })
  }

  const withdrawExport = useFetch({
    action: exportWithdraw,
    withAdminAccess: true,
    onComplete: exportFile,
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(values) {
    const body = { ...values }

    if (values?.month) {
      body.month = format(new Date(values.month), 'yyyy-MM')
    }

    withdrawExport.startFetch(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)} id={exportFormId}>
          <FormGroup>
            <ControlledSelect
              name='provider'
              inputId='provider'
              label='Provider'
              required
              control={control}
              options={
                providers
                  ? providers.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))
                  : []
              }
            />
          </FormGroup>

          <FormGroup>
            <ControlledDatePicker
              showMonthYearPicker
              placeholder='Select a month'
              dateFormat='MMM - yyyy'
              control={control}
              name='month'
              id='month'
              label='Month'
            />
          </FormGroup>

          <FormGroup>
            <ControlledSelect
              options={statuses}
              control={control}
              name='status'
              id='status'
              label='Status'
            />
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          disabled={withdrawExport.isLoading}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={exportFormId}
          disabled={withdrawExport.isLoading}
          loading={withdrawExport.isLoading}
        >
          Export
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ExportTrxModal
