import React, { useState } from 'react'
import {
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap'
import toastr from 'toastr'

import { ActionsDropdown } from 'ui'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  getLocalCurrencies,
  getMarkup,
  updateMarkup,
} from '../../../../services/api'
import Pagination from '../../../../components/ui/pagination'

const CurrencyMarkup = () => {
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)
  const list = useFetch(
    {
      action: getMarkup,
      withAdminAccess: true,
      body: { page, perPage: 40 },
      autoFetch: true,
      onError: toastr.error,
    },
    [page],
  )

  function refetchMarkups(loading) {
    list.startFetch({ page, perPage: 40 }, loading)
  }

  const showPaginator =
    list.paginator?.first_page_url !== list.paginator?.last_page_url
  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    onError: toastr.error,
  })

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_CURRENCY_MARKUP,
  )

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>Currency Markup</h1>

        {list.isLoading || !hasEditPermission ? null : (
          <Button onClick={() => setShowModal(true)}>Add Markup</Button>
        )}
      </div>

      <Card>
        <Col className='p-0 m-0'>
          {list.isLoading ? (
            <Loader minHeight='20rem' />
          ) : (
            <>
              <div className='table-responsive'>
                <Table className='table-centered table-nowrap text-muted mb-5'>
                  <thead className='thead-light'>
                    <tr>
                      <th className='border-top-0 text-muted'>From</th>
                      <th className='border-top-0 text-muted'>To</th>
                      <th className='border-top-0 text-muted'>Current rate</th>
                      <th className='border-top-0 text-muted'>New rate</th>
                      <th className='border-top-0 text-muted'>Markup</th>
                      {!hasEditPermission ? null : (
                        <th className='border-top-0' />
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {list.data?.map((item) => (
                      <MarkupLine
                        key={`${item.from}-${item.to}`}
                        item={item}
                        onUpdate={() => refetchMarkups(false)}
                        hasEditPermission={hasEditPermission}
                        currencies={currencies}
                      />
                    ))}
                  </tbody>
                </Table>
                {!showPaginator || list.isLoading ? null : (
                  <div className='ml-auto mx-3 mt-2 w-100 d-flex justify-content-end p-3'>
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={list.paginator?.per_page ?? 10}
                      totalItemsCount={list.paginator?.total ?? 0}
                      onChange={(newPage) => setPage(newPage)}
                    />
                  </div>
                )}
              </div>
              <MarkupModal
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                onUpdate={() => refetchMarkups(false)}
                currencies={currencies}
              />
            </>
          )}
        </Col>
      </Card>
    </div>
  )
}

const MarkupLine = ({ item, onUpdate, hasEditPermission, currencies }) => {
  const [showUpdate, setShowUpdate] = useState(false)

  const options = [
    {
      label: 'Update',
      onClick: () => {
        setShowUpdate(true)
      },
    },
  ]

  return (
    <>
      <tr>
        <td>{item.from}</td>
        <td>{item.to}</td>
        <td>{item.current_rate}</td>
        <td>{item.new_rate}</td>
        <td>{item.value} %</td>
        {!hasEditPermission ? null : (
          <td>
            <ActionsDropdown data={options} />
          </td>
        )}
      </tr>
      <MarkupModal
        isOpen={showUpdate}
        toggle={() => setShowUpdate(false)}
        item={item}
        onUpdate={onUpdate}
        currencies={currencies}
      />
    </>
  )
}

const MarkupModal = ({ isOpen, toggle, item, onUpdate, currencies }) => {
  const [value, setValue] = useState(item?.value)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)

  const update = useFetch({
    action: updateMarkup,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Markup added successfully')
      toggle()
      onUpdate()
    },
    onError: toastr.error,
  })
  const onConfirmUpdate = () => {
    update.startFetch({
      from: item?.from || from?.code,
      to: item?.to || to?.code,
      value,
    })
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {item ? 'Update' : 'Add'} Markup
      </ModalHeader>
      <ModalBody>
        {item ? null : (
          <>
            <FormGroup>
              <Label>From</Label>
              <CustomSelect
                onChange={setFrom}
                value={from}
                options={currencies.data?.map((e) => ({
                  value: e.id,
                  label: `${e.code} - ${e.name}`,
                  code: e.code,
                }))}
              />
            </FormGroup>
            <FormGroup>
              <Label>To</Label>
              <CustomSelect
                onChange={setTo}
                value={to}
                options={currencies.data?.map((e) => ({
                  value: e.id,
                  label: `${e.code} - ${e.name}`,
                  code: e.code,
                }))}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label>Markup value</Label>
          <Input
            value={value ? `${value}%` : ''}
            onChange={(e) => {
              setValue(e.target.value.replace('%', ''))
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onConfirmUpdate}
          loading={update.isLoading}
          disabled={update.isLoading}
        >
          {item ? 'Update' : 'Add'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CurrencyMarkup
