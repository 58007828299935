import qs from 'query-string'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import toastr from 'toastr'

import { SentryRoute } from '../../App'
import { getKycNotCompleted } from '../../components/Common/create-contract-btn'
import Loader from '../../components/ui/loader'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { getClientInfo, getContractorInfo } from '../../services/api'
import {
  logout,
  saveRoute,
  updateDeepLink,
} from '../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../store/profile/actions'
import { getIntercomData } from '../../utils/analytics'
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies'
import { TFA_REMINDER } from '../../utils/cookies/cookie-names'

function AuthMiddleware({
  component: Component,
  layout: Layout,
  ...propsWithoutLayout
}) {
  const { user, lastPath, loggedIn } = useSelector(
    (state) => state.Account ?? {},
  )
  const userProfile = useSelector((state) => state.userProfile.userProfile)
  const isEmployee = userProfile?.contractor_type === 'employee'

  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()

  const { history, location, permissions } = propsWithoutLayout

  const userInfo = useFetch({
    initResult: null,
    action: user?.type === 'client' ? getClientInfo : getContractorInfo,
    body: null,
    successAction: updateUserProfileInfo,
    onComplete: (data) => {
      const intercomData = getIntercomData(data)
      window.analytics.identify(data?.id, intercomData)
    },
    onError: (_, status) => {
      if (status === 401) {
        toastr.error('Your session has expired, login again to continue.')
        history.push('/logout')
      }
    },
    autoFetch: loggedIn && location.pathname !== '/logout',
  })

  const isContractorAndKycProfileNotCompleted = useMemo(
    () => getKycNotCompleted({ userProfile, user }),
    [userProfile, user],
  )

  useEffect(() => {
    let idleTime = 0
    // Increment the idle time counter every minute.
    const idleInterval = setInterval(timerIncrement, 60000) // 1 minute

    function resetIdleTime() {
      idleTime = 0
    }

    window.addEventListener('mousemove', resetIdleTime)
    window.addEventListener('keypress', resetIdleTime)
    window.addEventListener('mousedown', resetIdleTime)

    function timerIncrement() {
      if (loggedIn) idleTime = idleTime + 1
      // 30 minutes
      if (idleTime > 29) {
        let routeToSave = location.pathname + location.search
        if (location.pathname === '/withdraw') {
          routeToSave = '/activity'
        }

        dispatch(saveRoute(routeToSave))
        dispatch(logout())
        clearInterval(idleInterval)
      }
    }

    return () => {
      clearInterval(idleInterval)
    }
  }, [dispatch, loggedIn, location.pathname, location.search])

  return (
    <SentryRoute
      {...propsWithoutLayout}
      render={(props) => {
        if (userInfo.isLoading && !userProfile) {
          return <Loader minHeight='100vh' />
        }
        // here you can apply condition
        if (
          user &&
          !loggedIn &&
          user?.['2fa'] &&
          !user?.is_trusted_device &&
          location.pathname !== '/two-factor-authentication' &&
          location.pathname !== '/logout'
        ) {
          return (
            <Redirect
              to={{
                pathname: '/two-factor-authentication',
                state: { from: location },
              }}
            />
          )
        }
        if (
          user &&
          !loggedIn &&
          user?.length > 1 &&
          location.pathname !== '/select-profile' &&
          location.pathname !== '/logout'
        ) {
          return (
            <Redirect
              to={{
                pathname: '/select-profile',
                state: { from: location },
              }}
            />
          )
        }
        if (!loggedIn) {
          const stateFrom = history.location
          if (stateFrom) {
            dispatch(
              updateDeepLink(`${stateFrom?.pathname}${stateFrom?.search}`),
            )
          }
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        }
        if (permissions && userProfile && !hasAccess(permissions)) {
          return <Redirect to={{ pathname: '/no-access' }} />
        }
        if (
          user?.new &&
          !['/switch', '/complete-profile'].includes(location.pathname)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/complete-profile',
                state: { from: location },
              }}
            />
          )
        }
        if (
          !user?.must_select_type &&
          user?.must_complete_entity &&
          !['/switch', '/complete-profile'].includes(location.pathname)
        ) {
          return (
            <Redirect
              to={{ pathname: '/complete-profile', state: { from: location } }}
            />
          )
        }
        if (
          user?.must_select_type &&
          !['/account-type', '/complete-company'].includes(location.pathname)
        ) {
          return (
            <Redirect
              to={{ pathname: '/account-type', state: { from: location } }}
            />
          )
        }

        if (
          navigator.cookieEnabled &&
          location.pathname !== '/login' &&
          location.pathname !== '/2fa-reminder' &&
          location.pathname !== '/switch' &&
          !!userProfile
        ) {
          const twoFa = userProfile?.is_2fa_enabled
          const cookieValue = getCookie(TFA_REMINDER)

          if (twoFa) {
            if (cookieValue) {
              deleteCookie(TFA_REMINDER)
            }
          } else {
            if ((!cookieValue || cookieValue !== user?.token) && !lastPath) {
              setCookie(TFA_REMINDER, user?.token)

              const next = location.pathname + location.search

              return (
                <Redirect to={{ pathname: `/2fa-reminder`, state: { next } }} />
              )
            }
          }
        }
        if (
          location.pathname === '/insurance' &&
          (user?.type === userTypes.COMPANY || isEmployee)
        ) {
          return (
            <Redirect
              to={{ pathname: '/activity', state: { from: location } }}
            />
          )
        }
        const params = qs.parse(location.search)
        if (!!params.type && user?.type !== params.type) {
          dispatch(
            updateDeepLink(
              `${location.pathname}?${qs.stringify({ id: params?.id })}`,
            ),
          )
          return (
            <Redirect
              to={{
                pathname: '/switch',
                state: {
                  entity_id: params.entity_id,
                  notSwitch: userProfile.company?.id !== params.entity_id,
                },
              }}
            />
          )
        }
        if (
          !!params.entity_id &&
          userProfile &&
          userProfile?.company?.id !== params.entity_id
        ) {
          dispatch(
            updateDeepLink(
              `${location.pathname}?${qs.stringify({ id: params?.id })}`,
            ),
          )
          return (
            <Redirect
              to={{
                pathname: '/switch',
                state: { entity_id: params.entity_id, notSwitch: true },
              }}
            />
          )
        }
        if (
          ['/contract/create'].includes(location.pathname) &&
          isContractorAndKycProfileNotCompleted
        ) {
          dispatch(
            updateDeepLink(
              `${location.pathname}?${qs.stringify({ id: params?.id })}`,
            ),
          )
          return (
            <Redirect
              to={{ pathname: '/activity', state: { from: location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default withRouter(AuthMiddleware)
