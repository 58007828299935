import React, { Children } from 'react'
import { TabContent as OriginalTabContent } from 'reactstrap'

const withConditionalTabContent = (TabContent) => {
  return function ConditionalTabContent({ activeTab, children, ...props }) {
    const originalChildren = Children.toArray(children).filter(
      (child) => child !== null && child !== false,
    )
    let tabs = Children.toArray(children).filter((child) => {
      return child.props?.tabId !== undefined && child.props?.tabId !== null
    })
    if (tabs.length !== originalChildren.length) {
      tabs = children
    } else {
      tabs = Children.toArray(children).filter((item) => {
        return item?.props?.tabId === activeTab
      })
    }
    return (
      <TabContent activeTab={activeTab} {...props}>
        {tabs}
      </TabContent>
    )
  }
}

const TabContent = withConditionalTabContent(OriginalTabContent)
export default TabContent
