import cx from 'classnames'
import React, { useState } from 'react'

import { ReactComponent as CardPendingIllustration } from '../../../assets/images/card-pending.svg'
import EmptyContent from './empty-content'
import { ArrowRight, Barcode, Package } from '@phosphor-icons/react'
import Button from '../../../components/ui/button'
import { Popover, PopoverBody } from 'reactstrap'
import { useHistory } from 'react-router'

export default function PendingCard({
  className,
  cardLabel = 'RemotePass Card',
  wrapperProps = {},
  illustrationStyles,
}) {
  const wProps = { minHeight: 'calc(100vh - 300px)', ...wrapperProps }
  return (
    <EmptyContent className={cx('py-4 px-4 px-md-0', className)} {...wProps}>
      <CardPendingIllustration className='px-4' style={illustrationStyles} />

      <div className='text-center'>
        <h2 className='text-gray-h'>Your card is on the way</h2>
        <p className='mb-0 text-gray-600' style={{ maxWidth: '48ch' }}>
          You requested a <b>{cardLabel}</b>. You will get a notification when
          your card is approved.
        </p>
      </div>
    </EmptyContent>
  )
}
export function PendingPhysicalCard({ cardOrder }) {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const history = useHistory()
  // ready to ship, shipped
  const cardOrderShippingStatus = cardOrder?.shipping_details?.shipping_status
  const canActivatePhysicalCard = cardOrderShippingStatus === 'shipped'
  const activateCardButtonDisabled =
    !cardOrderShippingStatus || !canActivatePhysicalCard

  function handleActivateCard() {
    history.push('/cards/activate-physical-card')
    window.scroll(0, 0)
  }

  return (
    <div className='py-5 px-4 rp-max-w-100 credit-card-content'>
      {canActivatePhysicalCard ? (
        <>
          {' '}
          <Barcode size={32} weight='duotone' color='white' />
          <p className='font-size-16 mt-2 mb-0 text-white'>
            Activate your card
          </p>
          <p className='mb-0 text-text-30 font-size-12'>
            Two quick steps before you can start using your new card
          </p>
        </>
      ) : (
        <>
          <Package size={32} weight='duotone' color='white' />

          <p className='font-size-16 mt-2 mb-0 text-white'>In delivery...</p>

          <p className='mb-0 text-text-30 font-size-12'>
            Your Physical card is on its way, it should arrive within 15
            business days.
          </p>
        </>
      )}

      <div className='position-relative d-inline-block'>
        <Button
          style={{ height: 40 }}
          className='p-0'
          color='transparent'
          textClassName='text-secondary-50 font-weight-bold'
          onClick={handleActivateCard}
          iconRight={<ArrowRight size={16} color='var(--secondary-50)' />}
          disabled={activateCardButtonDisabled}
        >
          Activate card
        </Button>
        <div
          className={`position-absolute inset-0 rounded ${
            activateCardButtonDisabled ? '' : 'd-none'
          }`}
          onMouseOver={() => setPopoverOpen(true)}
          onMouseOut={() => setPopoverOpen(false)}
          id='activate-card-button'
        />
      </div>
      <Popover
        placement='top'
        target='activate-card-button'
        trigger='click'
        isOpen={popoverOpen}
        popperClassName='z-1010 shadow-lg'
      >
        <PopoverBody
          className='text-center text-gray-h'
          style={{ maxWidth: '24ch' }}
        >
          Your card should arrive within 15 business days.
        </PopoverBody>
      </Popover>
    </div>
  )
}
