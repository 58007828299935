import { format, isValid, parse } from 'date-fns'

export const DATE_FORMAT = {
  FORMAT_1: 'MMM d, yyyy',
  YYYY_MM_DD: 'yyyy-MM-dd',
  DD_MM_YYYY: 'dd-MM-yyyy',
}

export const DATE_PICKER_FORMAT = DATE_FORMAT.YYYY_MM_DD
export const DATE_PICKER_FORMAT_2 = DATE_FORMAT.DD_MM_YYYY

export function datePickerDateFormat(date) {
  return isValidDate(date) ? format(new Date(date), DATE_PICKER_FORMAT) : date
}

export function isValidDate(date) {
  return isValid(new Date(date))
}

export function rpFormatDate(dateString, dateFormatString, formatString) {
  if (!dateString) return null

  return isValidDate(dateString)
    ? format(parse(dateString, dateFormatString, new Date()), formatString)
    : dateString
}
