import { useEffect } from 'react'

export default function useTriggerField({ watch, trigger }) {
  useTriggerFields({
    watch,
    trigger,
    fields: [],
    options: { shouldTriggerCurrentField: true },
  })
}

export function useTriggerFields({
  watch,
  trigger,
  fields = [],
  options: { shouldTriggerCurrentField = false } = {},
}) {
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      fields.forEach((field) => trigger(field))

      if (shouldTriggerCurrentField) {
        trigger(name)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [fields, shouldTriggerCurrentField, trigger, watch])
}
