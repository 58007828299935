import { Invoice, Money, Plus, Storefront } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import { useReadLocalStorage } from 'usehooks-ts'
import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Head from '../../../components/head'
import { PageNav, useActiveTab } from '../../../components/page-nav'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import SearchBar from '../../../components/SearchBar'
import Button from '../../../components/ui/button'
import { CheckItem } from '../../../components/ui/check-item'
import DataTable from '../../../components/ui/data-table'
import PageHeading from '../../../components/ui/page-heading'
import Pagination from '../../../components/ui/pagination'
import { Result } from '../../../components/ui/result'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import { useUrlStateV2 } from '../../../helpers/hooks/use-url-state'
import permissions from '../../../helpers/permissions'
import {
  getBillApprovalFlow,
  getBillCategories,
  getBills,
  getVendors,
} from '../../../services/api-bill-payments'
import { updateToPayList } from '../../../store/payment/actions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { mapListToOption } from '../../../utils/map-to-option'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import BillCategoryIcon from '../bill-category-icon'
import BillDetailsButton, {
  BillDueDate,
  BillStatusBadge,
} from './bill-details-button'
import {
  BillOnboardingModal,
  lsBillOnboardingShown,
} from './bill-onboarding-modal'
import { useBillModuleActive } from '../bill-permission-hook'

export const BILL_STATUS = {
  DRAFT: 'draft',
  PENDING: 'pending_approval',
  READY: 'pending_payment',
  COMPLETED: 'completed',
}

const PageHeader = ({ actionsDisabled }) => {
  const { hasAccess } = usePermissions()
  const canManageVendors = hasAccess(permissions.ManageVendors)
  return (
    <PageHeading className='tw-mb-4'>
      <PageHeading.Title>Bill pay</PageHeading.Title>
      <PageHeading.ActionGroup className='tw-flex-wrap tw-gap-x-2'>
        <PermissionTooltip
          showing={!canManageVendors}
          id='manage-vendors-btn'
          area={PERMISSION_GROUP.MANAGE_VENDORS.name}
          action=''
        >
          <Button
            className='!tw-border-0 !tw-bg-white !tw-text-black'
            icon={<Storefront size={20} />}
            to='/bills/vendors'
            tag={Link}
            disabled={!canManageVendors || actionsDisabled}
          >
            Manage Vendors
          </Button>
        </PermissionTooltip>

        <AddBillInvoiceButton actionsDisabled={actionsDisabled} />
      </PageHeading.ActionGroup>
    </PageHeading>
  )
}

function AddBillInvoiceButton({ actionsDisabled }) {
  const { hasAccess } = usePermissions()

  const canManageBills = hasAccess([
    permissions.ManageAllBills,
    permissions.ManageOwnBills,
  ])

  return (
    <PermissionTooltip
      showing={!canManageBills}
      id='manage-bills-btn'
      area={PERMISSION_GROUP.CREATE_BILL.name}
      action=''
    >
      <Button
        icon={<Plus size={20} />}
        tag={Link}
        to='/bills/create'
        disabled={!canManageBills || actionsDisabled}
      >
        Add Invoice
      </Button>
    </PermissionTooltip>
  )
}

const tabsData = [
  {
    label: 'Draft',
    key: BILL_STATUS.DRAFT,
  },
  { label: 'For Approval', key: BILL_STATUS.PENDING },
  {
    label: 'Ready To Pay',
    key: BILL_STATUS.READY,
  },
  { label: 'Completed', key: BILL_STATUS.COMPLETED },
]

const TabHeader = ({ activeTab }) => (
  <PageNav className='!tw-mb-4 !tw-flex-wrap tw-rounded tw-bg-white'>
    {tabsData.map((data) => (
      <PageNav.Item key={data.key}>
        <PageNav.Link
          to={`/bills?tab=${data.key}`}
          tag={Link}
          isActive={activeTab === data.key}
          className='!tw-flex tw-items-center tw-gap-2'
        >
          {data.label}
        </PageNav.Link>
      </PageNav.Item>
    ))}
  </PageNav>
)

const billFiltersDefaultValues = {
  due_date: undefined,
  min_amount: undefined,
  max_amount: undefined,
  vendor: undefined,
  category: undefined,
  selectedItem: undefined,
}

export function BillInfoText({ title, subTitle, className }) {
  return (
    <span
      className={cn(
        'tw-text-right tw-text-sm tw-font-normal tw-text-text-60',
        className,
      )}
    >
      <div className='tw-text-text'>{title}</div>
      {subTitle}
    </span>
  )
}

const Body = ({ activeTab }) => {
  const { hasAccess } = usePermissions()
  const canManageBills = hasAccess([
    permissions.ManageBill,
    permissions.ManageOwnBills,
  ])
  const {
    value: {
      selectedItem,
      due_date: dueDate,
      vendor,
      category,
      min_amount: minAmount,
      max_amount: maxAmount,
    },
    updater: setUrlState,
  } = useUrlStateV2(billFiltersDefaultValues)
  function setSelectedItem(item) {
    setUrlState({ selectedItem: item })
  }

  const [selectedItems, setSelectedItems] = useState([])
  const history = useHistory()
  const location = history.location
  const searchParams = new URLSearchParams(location.search)
  const currentPage = searchParams.get('page') ?? 1

  const { data: categories, isLoading: fetchingCategories } = useFetch({
    action: getBillCategories,
    autoFetch: true,
  })

  const { id: selectedCategoryId, name: selectedCategoryName } =
    categories?.find((cat) => cat.name === category) ?? {}
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()

  const {
    data: bills,
    isLoading: isLoadingBills,
    startFetch: fetchBills,
    paginator,
  } = useFetch(
    {
      action: getBills,
      autoFetch: true,
      body: {
        vendor_id: vendor,
        due_date: dueDate,
        min_amount: minAmount,
        max_amount: maxAmount,
        category_id: selectedCategoryId,
        status: activeTab,
        page: currentPage,
      },
      onError: (error) => toastr.error(error),
    },
    [
      vendor,
      dueDate,
      minAmount,
      maxAmount,
      selectedCategoryId,
      activeTab,
      currentPage,
    ],
  )
  const isAllSelected = selectedItems.length === bills?.length
  const isFiltering =
    vendor || dueDate || selectedCategoryId || maxAmount || minAmount
  const hasIntegration = categories?.[0]?.type !== 'rp'

  const { data: approvalFlow } = useFetch({
    action: getBillApprovalFlow,
    autoFetch: true,
  })

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([])
    } else {
      const selectedBills = []
      bills?.forEach((bill) => selectedBills.push(bill))
      setSelectedItems(selectedBills)
    }
  }

  const columns = [
    {
      Header: hasIntegration ? 'GL Account' : 'Category',
      accessor: 'category',
      Cell: ({ cellData }) =>
        !cellData ? (
          'N/A'
        ) : (
          <div className='tw-flex tw-gap-2'>
            {!hasIntegration && <BillCategoryIcon category={cellData?.name} />}
            {cellData?.name}
          </div>
        ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>Created on</span>
      ),
      accessor: 'created_at',
      Cell: ({ cellData, rowData }) => (
        <BillInfoText
          title={format(new Date(cellData), 'dd/MM/yyyy')}
          subTitle={
            rowData.creator_name &&
            `By ${rowData.creator_name} ${user.id === rowData.creator_id ? '(You)' : ''}`
          }
          className='tw-text-left'
        />
      ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>Due date</span>
      ),
      accessor: 'due_date',
      Cell: ({ cellData }) => <BillDueDate date={cellData} />,
    },
    { Header: 'Vendor', accessor: 'vendor.name' },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>Amount</span>
      ),
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData.currency.code)
        return <span>{formatter.format(rowData.amount)}</span>
      },
    },
    { Header: 'Invoice Number', accessor: 'details' },
    activeTab === BILL_STATUS.PENDING
      ? {
          Header: 'Next Approver',
          accessor: 'next_approver',
          Cell: ({ cellData }) => {
            const approverIndex = billApprovalFlow?.steps?.findIndex(
              (step) => step.user_id === cellData.id,
            )
            return (
              <span className='tw-flex tw-gap-2'>
                <span className='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-sm tw-font-bold tw-text-black'>
                  {`${approverIndex + 1}/${billApprovalFlow?.steps?.length}`}
                </span>
                <span className='tw-flex tw-items-center tw-gap-1 tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1'>
                  <Avatar photo={cellData.photo} name={cellData.name} />
                  <span className='tw-text-sm tw-font-semibold tw-text-black'>
                    {cellData.name}
                  </span>
                </span>
              </span>
            )
          },
        }
      : {
          Header: 'Status',
          accessor: 'status.name',
          Cell: ({ cellData }) => <BillStatusBadge status={cellData} />,
        },
    {
      Cell: ({ rowData }) => (
        <BillDetailsButton
          bill={rowData}
          fetchBills={fetchBills}
          activeTab={tabsData.find((tab) => tab.key === activeTab)}
          onPayClick={() => {
            onPayClick({
              paymentIds: [rowData.payment_item_id],
              location,
              dispatch,
              history,
            })
          }}
          activeItem={selectedItem || undefined}
          showItem={() => setSelectedItem(rowData?.name)}
          closeItem={() => setSelectedItem(undefined)}
          approvalFlow={approvalFlow}
        />
      ),
    },
    activeTab === BILL_STATUS.READY &&
      hasAccess(permissions.PrepareTransactions) &&
      canManageBills && {
        Header: (
          <Button color='link' onClick={onSelectAll} className='tw-w-28'>
            {isAllSelected ? `Deselect ` : 'Select '} All
          </Button>
        ),
        Cell: ({ rowData }) => {
          const isItemSelected = selectedItems.find(
            (item) => item.id === rowData.id,
          )
          return (
            <span className='tw-flex tw-justify-center'>
              <CheckItem
                className='tw-w-fit'
                checked={isAllSelected || isItemSelected}
                onChange={() => {
                  setSelectedItems(
                    isItemSelected
                      ? [
                          ...selectedItems.filter(
                            (item) => item.id !== rowData.id,
                          ),
                        ]
                      : [...selectedItems, rowData],
                  )
                }}
              />
            </span>
          )
        },
      },
  ].filter(Boolean)

  const companyId = useSelector(
    (state) => state.userProfile?.userProfile?.company?.id,
  )

  const billOnboardingLsValue = useReadLocalStorage(lsBillOnboardingShown)
  const billOnboardingShown = Array.isArray(billOnboardingLsValue)
    ? billOnboardingLsValue.includes(companyId)
    : false
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)
  const canOnboard = hasAccess([permissions.manageCompanySettings])

  const {
    data: billApprovalFlow,
    isLoading: isLoadingBillApprovalFlow,
    startFetch: fetchBillApprovalFlow,
  } = useFetch({
    action: getBillApprovalFlow,
    autoFetch: true,
    onError: (error) => toastr.error(error),
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while fetching the approval flow')
        return
      }
      if (!isOnboardingOpen) {
        const billsAreSetup = !!data?.id
        setIsOnboardingOpen(!billsAreSetup)
      }
    },
  })

  const billsAreSetup = !!billApprovalFlow?.id
  const loadingData = isLoadingBills || isLoadingBillApprovalFlow
  const hideTabsAndActions = loadingData || !billsAreSetup

  const showOnboardingModal =
    isOnboardingOpen && !loadingData && !billOnboardingShown

  const shouldBillBeSetupButCannot = showOnboardingModal && !canOnboard

  const showAddInvoiceButton = !isFiltering && activeTab === BILL_STATUS.DRAFT
  const noBills = bills?.length === 0

  return (
    <>
      <PageHeader actionsDisabled={hideTabsAndActions} />

      {hideTabsAndActions ? null : <TabHeader activeTab={activeTab} />}

      <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
        {showOnboardingModal || !billsAreSetup ? null : (
          <BodyHeader
            isLoading={isLoadingBills}
            location={location}
            history={history}
            dispatch={dispatch}
            itemsToPay={selectedItems}
            noBills={noBills}
          />
        )}
        {!shouldBillBeSetupButCannot ? null : (
          <Result
            IconComponent={(props) => (
              <Invoice
                {...props}
                weight='duotone'
                className={cn(
                  props.className,
                  'tw-size-64 tw-text-primary-100',
                )}
              />
            )}
            title='You can’t start processing bills'
            description='Contact an admin user to first setup an approval flow for bill payments. '
            className='tw-mx-auto tw-min-h-[550px] tw-justify-center'
          />
        )}
        {!(noBills && !isFiltering) && (
          <FilterRow
            isLoading={(isLoadingBills && !isFiltering) || fetchingCategories}
            categories={categories}
            selectedCategory={
              selectedCategoryId
                ? { label: selectedCategoryName, value: selectedCategoryId }
                : undefined
            }
            hasIntegration={hasIntegration}
          />
        )}
        {isLoadingBills ? (
          <span className='tw-flex tw-flex-col tw-gap-2'>
            <Shimmer width='100%' height={50} />
            <Shimmer width='100%' height={50} />
            <Shimmer width='100%' height={50} />
          </span>
        ) : shouldBillBeSetupButCannot ? null : noBills ? (
          <Result
            IconComponent={(props) => (
              <Invoice
                {...props}
                weight='duotone'
                className={cn(
                  props.className,
                  'tw-size-64 tw-text-primary-100',
                )}
              />
            )}
            title='You have no bill to show'
            description={
              !billsAreSetup
                ? 'To get started with bills, please follow the onboarding process.'
                : showAddInvoiceButton
                  ? 'You can click Add Invoice button and create one.'
                  : ''
            }
            actions={
              !billsAreSetup ? (
                <Button type='button' onClick={() => setIsOnboardingOpen(true)}>
                  Get Started
                </Button>
              ) : !showAddInvoiceButton ? null : (
                <AddBillInvoiceButton actionsDisabled={hideTabsAndActions} />
              )
            }
            className='tw-mx-auto'
          />
        ) : (
          <>
            <DataTable columns={columns} data={bills} striped responsive />
            <span className='tw-flex tw-justify-end'>
              <Pagination
                activePage={Number(currentPage)}
                onChange={(page) => {
                  searchParams.set('page', page)
                  history.replace(
                    `${history.location.pathname}?${searchParams.toString()}`,
                  )
                }}
                itemsCountPerPage={paginator?.per_page}
                totalItemsCount={paginator?.total ?? 0}
              />
            </span>
          </>
        )}
      </div>

      {!showOnboardingModal || !canOnboard ? null : (
        <BillOnboardingModal
          isOpen={isOnboardingOpen}
          toggle={() => {
            setIsOnboardingOpen((open) => !open)
          }}
          onApprovalFlowUpdated={fetchBillApprovalFlow}
          billApprovalFlow={billApprovalFlow}
        />
      )}
    </>
  )
}

const onPayClick = ({ dispatch, history, paymentIds, location }) => {
  dispatch(updateToPayList(paymentIds))
  history.push(
    `/pay-invoices?from=${encodeURIComponent(location.pathname + location.search)}&only_bank=true`,
  )
}

const BodyHeader = ({
  isLoading,
  location,
  dispatch,
  history,
  itemsToPay = [],
  noBills,
}) => {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })
  const companyCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )
  const formatter = getCurrencyFormatter(companyCurrency)
  const { hasAccess } = usePermissions()
  const paymentIds = itemsToPay.map((item) => item.payment_item_id)
  const canManageBills = hasAccess([
    permissions.ManageAllBills,
    permissions.ManageOwnBills,
  ])
  return (
    <span className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-2'>
      <span>
        {!isLoading ? (
          <div className='tw-text-2xl'>
            {tabsData.find((data) => data.key === activeTab).label}
          </div>
        ) : (
          <Shimmer className='tw-mb-2' width={130} />
        )}
      </span>

      {activeTab === BILL_STATUS.READY &&
        hasAccess(permissions.PrepareTransactions) &&
        canManageBills &&
        !noBills && (
          <Button
            disabled={isLoading || itemsToPay.length === 0}
            icon={<Money />}
            onClick={() =>
              onPayClick({ paymentIds, location, dispatch, history })
            }
          >
            {itemsToPay.length === 0
              ? 'Select To Pay'
              : 'Pay ' +
                formatter.format(
                  itemsToPay.reduce((acc, item) => acc + item.trans_amount, 0),
                )}
          </Button>
        )}
    </span>
  )
}

const FilterRowWrapper = ({ children, className }) => (
  <span
    className={cn(
      'tw-flex tw-flex-wrap tw-gap-x-2 tw-gap-y-4 [&>*]:!tw-w-full [&>*]:md:!tw-min-w-60 [&>*]:md:tw-flex-1',
      className,
    )}
  >
    {children}
  </span>
)

const FilterRow = ({
  isLoading,
  categories,
  selectedCategory,
  hasIntegration,
}) => {
  const { data: vendors, isLoading: fetchingVendors } = useFetch({
    action: getVendors,
    autoFetch: true,
    onError: (error) => toastr.error(error),
  })
  const {
    updater: setUrlState,
    value: {
      min_amount: minAmount,
      max_amount: maxAmount,
      due_date: dueDate,
      vendor,
    },
  } = useUrlStateV2(billFiltersDefaultValues, { replaceRoute: true })

  if (isLoading) {
    return (
      <FilterRowWrapper>
        {new Array(5).fill(0).map((_, index) => (
          <Shimmer key={index} height={56} />
        ))}
      </FilterRowWrapper>
    )
  }

  return (
    <FilterRowWrapper>
      <CustomDatePicker
        placeholder='Due Date'
        clearable
        handleClear={() => setUrlState({ due_date: null })}
        wrapperClassName='[&>div>div>div]:tw-h-[56px] [&>div>div>div]:!tw-flex [&>div>div>div]:tw-items-center'
        inputClassName='!tw-h-[56px]'
        value={dueDate}
        handleOnChange={(value) => {
          const formattedDate = format(value, 'yyyy-MM-dd')
          setUrlState({ due_date: formattedDate })
          return formattedDate
        }}
      />

      <CustomSelect
        placeholder={hasIntegration ? 'GL Account' : 'Category'}
        isClearable
        options={categories?.map((category) => mapListToOption(category))}
        onChange={(val) => setUrlState({ category: val?.label })}
        value={selectedCategory}
        selectStyles={{ control: () => ({ height: '56px' }) }}
      />

      <CustomSelect
        placeholder='Vendor'
        isClearable
        options={vendors?.map(mapListToOption)}
        onChange={(val) => setUrlState({ vendor: val?.value })}
        value={
          vendor
            ? mapListToOption(
                vendors?.find((_vendor) => _vendor.id === Number(vendor)),
              )
            : undefined
        }
        selectStyles={{ control: () => ({ height: '56px' }) }}
        isDisabled={fetchingVendors}
        isLoading={fetchingVendors}
      />

      <SearchBar
        type='number'
        placeholder='Min Amount'
        inputClassName='!tw-h-14 !tw-px-3'
        hideIcon
        query={minAmount}
        onQueryChanged={(val) => setUrlState({ min_amount: val || undefined })}
      />

      <SearchBar
        type='number'
        placeholder='Max Amount'
        inputClassName='!tw-h-14 !tw-px-3'
        hideIcon
        query={maxAmount}
        onQueryChanged={(val) => setUrlState({ max_amount: val || undefined })}
      />
    </FilterRowWrapper>
  )
}

export default function Bills() {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })
  useBillModuleActive()
  return (
    <div className='page-content'>
      <Head title='Bill pay' />
      <Body activeTab={activeTab} />
    </div>
  )
}
