import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'
import ExpenseDetailsSideMenu from '../../expenses/components/expense-details-modal'
import { Eye } from '@phosphor-icons/react'

export function ExpenseActions({ item, contract, refreshData, isAdmin }) {
  const [showExpenseDetails, setShowExpenseDetails] = useState({
    show: false,
    expense: null,
  })

  const user = useSelector((state) => state?.Account?.user)

  function openDetails() {
    setShowExpenseDetails({ show: true, expense: item })
  }

  function onUpdateSuccess() {
    refreshData()
    setShowExpenseDetails({ show: false, expense: null })
  }

  return (
    <>
      <Button
        color='link'
        className='!tw-p-0'
        onClick={openDetails}
        icon={<Eye size={20} className='tw-text-primary' />}
      >
        Details
      </Button>

      <ExpenseDetailsSideMenu
        show={showExpenseDetails.show}
        contract={contract}
        isClient={user?.type === userTypes.COMPANY}
        expense={showExpenseDetails.expense}
        onApprove={onUpdateSuccess}
        onDelete={onUpdateSuccess}
        onReject={onUpdateSuccess}
        onToggle={() => {
          setShowExpenseDetails((prev) => ({ ...prev, show: false }))
        }}
        isAdmin={isAdmin}
      />
    </>
  )
}
