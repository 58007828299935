import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap'
import * as yup from 'yup'
import ControlledInput from '../../../../components/ControlledInput'
import Button from '../../../../components/ui/button'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
const schema = yup.object().shape({
  provider: yup.string().required('Provider name is required'),
  provider_ref: yup.string().required('Provider ref is required'),
})
export const ManualProcessModal = ({
  isOpen,
  toggle,
  onConfirm,
  trx,
  isLoading,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  })
  const formatter = useMemo(() => {
    return getCurrencyFormatter(trx?.currency?.code, undefined, {
      maximumSignificantDigits: trx?.provider === 'coinbase' ? 6 : undefined,
    })
  }, [trx?.currency?.code, trx?.provider])
  const info = useMemo(() => {
    return [
      { label: 'Transaction ref:', value: trx?.transaction_ref },
      { label: 'Amount:', value: formatter.format(trx?.total) },
    ]
  }, [formatter, trx?.total, trx?.transaction_ref])
  useEffect(() => {
    setValue('provider', trx?.provider)
    setValue('provider_ref', '')
  }, [trx?.transaction_ref, trx?.provider, setValue])
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Process Transaction</ModalHeader>

      <ModalBody>
        {info.map(({ label, value }, index) => {
          return (
            <div className='mb-2' key={index}>
              <span className='rp-font-bold text-dark'>{label}</span> {value}
            </div>
          )
        })}
        <Row className='justify-content-center mb-3'>
          <Col>
            <ControlledInput
              label='Provider name:'
              labelClassName='rp-font-bold text-dark'
              control={control}
              placeholder=''
              name='provider'
              id={`provider-${trx?.id}`}
              error={errors.provider}
              disabled={isLoading}
            />
          </Col>
        </Row>
        <Row className='justify-content-center mb-3'>
          <Col>
            <ControlledInput
              label='Provider id:'
              labelClassName='rp-font-bold text-dark'
              control={control}
              placeholder=''
              name='provider_ref'
              id={`provider_ref${trx?.transaction_ref}`}
              error={errors.provider_ref}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          onClick={handleSubmit(onConfirm)}
          disabled={isLoading}
          loading={isLoading}
        >
          Process
        </Button>
      </ModalFooter>
    </Modal>
  )
}
