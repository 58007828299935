import React, { createContext, useState } from 'react'

const createVendorContext = createContext({
  vendorFormData: null,
  setVendorFormData: () => {},
})

export function CreateVendorContextProvider({ children, defaultData }) {
  const [vendorFormData, setVendorFormData] = useState(defaultData)

  function handleSetData(data) {
    setVendorFormData((prevData) => ({ ...prevData, ...data }))
  }

  return (
    <createVendorContext.Provider
      value={{ vendorFormData, setVendorFormData: handleSetData }}
    >
      {children}
    </createVendorContext.Provider>
  )
}

export function useCreateVendorFlowContext() {
  return React.useContext(createVendorContext)
}
