import {
  QB_UPDATE_PRE_DATA,
  QB_SUBMIT_ACCOUNT_MAPPING,
  QB_UPDATE_CONTRACTOR,
  QB_UPDATE_REQUEST,
  QB_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  QB_INIT,
} from './actionTypes'

export const qbUpdatePreData = (payload) => {
  return {
    type: QB_UPDATE_PRE_DATA,
    payload,
  }
}

export const initQB = () => {
  return {
    type: QB_INIT,
  }
}

export const qbSubmitAccountMapping = (payload) => {
  return {
    type: QB_SUBMIT_ACCOUNT_MAPPING,
    payload,
  }
}

export const qbUpdateContractor = (payload) => {
  return {
    type: QB_UPDATE_CONTRACTOR,
    payload,
  }
}

export const qbUpdateRequest = (payload) => {
  return {
    type: QB_UPDATE_REQUEST,
    payload,
  }
}
export const qbSubmitAccountMappingAndUpdateRequest = (payload) => {
  return {
    type: QB_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
    payload,
  }
}
