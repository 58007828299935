import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { ActionsDropdown } from 'ui'
import Button from '../../../../../components/ui/button'
import { useFetch } from '../../../../../helpers/hooks'
import { adminChangeEmail } from '../../../../../services/api'

const MODAL_TYPES = {
  CHANGE_USER_EMAIL: 'CHANGE_USER_EMAIL',
}

export default function UserActions({ user, refreshData }) {
  const [showModal, setShowModal] = useState(null)

  function handleToggleModal(modalKey) {
    setShowModal((prevModal) => (prevModal === modalKey ? null : modalKey))
  }

  const userActions = [
    {
      label: 'Change Email',
      onClick: () => {
        handleToggleModal(MODAL_TYPES.CHANGE_USER_EMAIL)
      },
    },
  ]

  return (
    <>
      <ActionsDropdown data={userActions} />

      <ChangeUserEmailModal
        isOpen={showModal === MODAL_TYPES.CHANGE_USER_EMAIL}
        toggle={() => handleToggleModal(MODAL_TYPES.CHANGE_USER_EMAIL)}
        user={user}
        refreshData={refreshData}
      />
    </>
  )
}

const changeEmailId = 'change-user-email'
function ChangeUserEmailModal({ toggle, isOpen, user, refreshData }) {
  const { startFetch: changeEmail, isLoading } = useFetch({
    action: adminChangeEmail,
    withAdminAccess: true,
    checkSuccess: true,
    onError: (error) => {
      toastr.error(error)
    },
    onComplete: () => {
      toggle()
      toastr.success('Email changed successfully')
      refreshData()
    },
  })

  function handleChangeEmail(_, values) {
    changeEmail(values)
  }

  return (
    <GenericModal
      toggle={toggle}
      isOpen={isOpen}
      header='Change user email'
      confirmButtonFormId={changeEmailId}
      confirmButtonText='Change email'
      confirmButtonType='submit'
      confirmButtonLoading={isLoading}
      confirmButtonDisabled={isLoading}
      cancelBtnDisabled={isLoading}
    >
      <AvForm id={changeEmailId} onValidSubmit={handleChangeEmail}>
        <AvField
          label='Current Email:'
          name='email'
          type='email'
          value={user?.email}
          readOnly
        />

        <AvField label='New Email:' name='new_email' type='email' />
      </AvForm>
    </GenericModal>
  )
}

function GenericModal({
  toggle,
  isOpen,
  header,
  children,
  onCancel = () => {},
  hideCancelButton = false,
  confirmButtonType,
  confirmButtonClick,
  confirmButtonFormId,
  confirmButtonText = 'Confirm',
  confirmButtonLoading,
  confirmButtonDisabled,
  cancelBtnDisabled,
}) {
  function handleCancel() {
    let cancelDefaultBehaviour = true
    if (typeof onCancel === 'function') {
      cancelDefaultBehaviour = onCancel()
    }
    if (!cancelDefaultBehaviour) toggle()
  }

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {hideCancelButton ? null : (
          <Button
            type='button'
            onClick={handleCancel}
            color='light'
            outline
            disabled={cancelBtnDisabled}
          >
            Cancel
          </Button>
        )}
        <Button
          type={confirmButtonType}
          onClick={confirmButtonClick}
          formId={confirmButtonFormId}
          loading={confirmButtonLoading}
          disabled={confirmButtonDisabled}
        >
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
