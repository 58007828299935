import { Check } from '@phosphor-icons/react'
import { format, parse } from 'date-fns'
import React, { useState } from 'react'

import { cn } from 'ui'
import BadgeV2 from '../../../../components/ui/badge-v2'
import BottomSheet from '../../../../components/ui/bottom-sheet/bottom-sheet'
import { CashBackIcon } from '../../../../components/ui/cashback-icon'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import { getCashbackHistory } from '../../../../services/api'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'

function getDateFromIso(date) {
  if (!date || typeof date !== 'string' || date === '') {
    return ''
  }

  return date.substring(0, 10)
}
function parseTheDate(date) {
  return parse(date, 'yyyy-MM-dd', new Date())
}
function getFormatter(formatString) {
  return function (date) {
    return format(parseTheDate(getDateFromIso(date)), formatString)
  }
}

const formatMonth = getFormatter('MMMM')
const formatDate = getFormatter('MMM dd, yyyy')

function formatAmount(currency, amount) {
  const formatter = getCurrencyFormatter(currency)
  return formatter.format(amount)
}
const getCollectedBadge = (isCredited) => {
  return isCredited ? (
    <BadgeV2
      status='primary'
      size='sm'
      rightIcon={<Check size={12} />}
      name='Collected'
    />
  ) : (
    <BadgeV2 status='warning' size='sm' name='Not Collected' />
  )
}

export default function CashbackHistoryModal({ isOpen, toggle }) {
  const [page, setPage] = useState(1)
  const columns = [
    {
      Header: 'Month',
      accessor: 'month',
      className: '!tw-p-4',
      Cell: (data) => formatMonth(data?.rowData?.created_at),
    },
    {
      Header: 'Date',
      accessor: 'date',
      className: '!tw-p-4',
      Cell: (data) => formatDate(data?.rowData?.created_at),
    },
    {
      Header: 'Amount',
      accessor: 'cashback_amount',
      className: '!tw-p-4',
      Cell: (data) =>
        formatAmount(
          data?.rowData?.cashback_currency_code,
          data?.rowData?.cashback_amount,
        ),
    },
    {
      Header: 'Status',
      accessor: 'is_credited',
      className: '!tw-p-4',
      Cell: (data) => getCollectedBadge(data?.rowData?.is_credited),
    },
  ]
  const { data, isLoading } = useFetch(
    {
      action: getCashbackHistory,
      body: { page: page - 1 },
      autoFetch: true,
    },
    [page],
  )
  return (
    <BottomSheet
      toggle={toggle}
      isOpen={isOpen}
      header='Cashback history'
      content={
        isLoading ? (
          <Loader className='w-100' />
        ) : data?.items?.length < 1 ? (
          <div
            className='d-flex align-items-center justify-content-center flex-column cbh-empty'
            style={{ height: 460 }}
          >
            <CashBackIcon size={194} color='var(--primary)' />
            <div className='font-size-24 font-weight-medium mb-2'>
              No cashback history
            </div>
            <div className='font-size-14 text-text-80'>
              Cashback collected will be listed here
            </div>
          </div>
        ) : (
          <>
            <DataTable
              className='tw-hidden tw-text-black md:tw-table'
              headClassName='!tw-p-4'
              responsive
              columns={columns}
              data={data?.items}
            />
            <CashbackCards className='md:tw-hidden' items={data?.items} />
            {data?.total_pages_count > 1 && (
              <Pagination
                innerClass='align-items-center d-flex justify-content-center mt-3 pagination w-100'
                activePage={page}
                itemsCountPerPage={data?.limit}
                totalItemsCount={data?.total_items_count}
                onChange={(page) => setPage(page)}
              />
            )}
          </>
        )
      }
    />
  )
}

function CashbackCards({ items, className }) {
  return items?.map((item, index) => {
    const infoItems = [
      { label: 'Month', value: formatMonth(item?.created_at) },
      { label: 'Date', value: formatDate(item?.created_at) },
      {
        label: 'Amount',
        value: formatAmount(
          item?.cashback_currency_code,
          item?.cashback_amount,
        ),
      },
      { label: 'status', value: getCollectedBadge(item?.is_credited) },
    ]
    return (
      <div
        key={`cb-${index}`}
        className={cn(
          'tw-mb-4 tw-flex tw-flex-col tw-gap-4 tw-rounded-md tw-border tw-border-surface-30 tw-p-4',
          className,
        )}
      >
        {infoItems.map((row, index) => (
          <div
            className='tw-flex tw-items-center tw-justify-between tw-text-base'
            key={`cb-card-${index}`}
          >
            <span className='tw-text-text-80'>{row.label}</span>
            <span>{row.value}</span>
          </div>
        ))}
      </div>
    )
  })
}
