import React from 'react'
import { components } from 'react-select'

import { FlagIcon } from '../../../components/ui/flag-icon'

export default function CustomBalanceControl(props) {
  const balance = props.getValue()?.[0]

  return (
    <components.Control
      className='font-size-17'
      {...props}
      innerProps={{ ...props.innerProps, style: { height: 56 } }}
    >
      {!balance ? null : (
        <div
          className='d-flex align-items-center align-self-stretch px-2'
          style={{
            gap: '0.5rem',
            minWidth: 78,
            borderRight: '1px solid #E7E8F2',
          }}
        >
          <FlagIcon
            code={balance?.currency?.code}
            size='16px'
            className='border'
          />

          <span style={{ color: '#777F9E' }}>{balance?.currency?.code}</span>
        </div>
      )}
      {props.children}
    </components.Control>
  )
}
