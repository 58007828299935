import cx from 'classnames'
import React from 'react'
import { components } from 'react-select'

const { Option } = components

export const customOption = (props) => (
  <Option {...props}>
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              objectFit: 'cover',
            }}
            alt={props.data.code}
          />
        )}
        {props.data.icon}
        <span className='font-weight-light text-dark rp-font-bold ml-2'>
          {props.data.code}
        </span>
        <span
          className={cx(
            'font-weight-light ml-2',
            props?.isSelected ? 'text-slate-700' : 'text-secondary',
          )}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.name}
        </span>
      </div>
    </div>
  </Option>
)

export const customSingleValue = (props) => (
  <div className='input-select'>
    <div className='input-select__single-value d-flex flex-row align-items-center justify-content-center'>
      {props.data.flag && (
        <img
          src={props.data.flag}
          style={{
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
            objectFit: 'cover',
          }}
          alt={props.data.code}
        />
      )}
      {props.data.icon}
      <span
        className='font-weight-light text-secondary pl-1'
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {props.data.code}
      </span>
    </div>
  </div>
)
