import React from 'react'
import { ReactComponent as ReferPayroll } from '../../../assets/images/referPayroll.svg'
import { ReactComponent as ReferPerson } from '../../../assets/images/referPerson.svg'
import { ReactComponent as ReferGift } from '../../../assets/images/referGift.svg'

import { useResize } from '../../../helpers/hooks'
const sections = [
  {
    text: 'Invite companies in your network to RemotePass.',
    icon: <ReferPerson />,
  },
  {
    text: 'Companies sign up, get their first month free, and process their first payroll.',
    icon: <ReferPayroll />,
  },
  {
    text: 'You earn up to $400 in gift cards!!!',
    icon: <ReferGift />,
  },
]
const InstructionSection = () => {
  const isMobile = useResize()
  const style = {
    width: '1px',
    backgroundColor: '#E7E8F2',
    position: 'absolute',
    left: '26px',
    bottom: 0,
  }
  const poStyle = (index) => {
    if (index === 0) return { bottom: 0 }
    if (index === 2) return { top: 0 }
  }
  return (
    <div
      className={`d-flex flex-column p-3 card mb-0 rp-shadow-2 ${
        isMobile ? 'mt-2' : ''
      }`}
      style={{ width: isMobile ? '100%' : '40%' }}
    >
      <p className='m-0 font-size-24 font-weight-600'>How it works</p>
      <div className='pt-4'>
        {sections?.map((item, key) => (
          <div
            className='d-flex align-items-center py-3'
            key={key}
            style={{ position: 'relative' }}
          >
            <div
              className={`${key === 1 ? 'h-100' : 'h-50'}`}
              style={{ ...style, ...poStyle(key) }}
            />
            <div className='pr-1'>
              <div
                className='bg-white d-flex align-items-center justify-content-center rounded-circle border position-relative'
                style={{ height: 52, width: 52, zIndex: 10 }}
              >
                {item?.icon}
              </div>
            </div>
            <div className='font-size-16 pl-3'>{item?.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default InstructionSection
