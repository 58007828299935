import React from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import Button from '../../../components/ui/button'
import ModalBase from './modal-base'

const questionsFormId = 'questionsFormId'

export default function ModalQuestions({
  isOpen,
  modalHeading = 'Eligibility questions',
  fields = [],
  toggle = () => {},
  isSubmitting,
  onSubmit,
}) {
  const modalProps = { isOpen, toggle, heading: modalHeading }

  function handleSubmit(_, values) {
    onSubmit(values)
  }

  return (
    <ModalBase
      {...modalProps}
      actions={
        <>
          <Button
            type='button'
            color='light'
            outline
            onClick={toggle}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            formId={questionsFormId}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Redeem
          </Button>
        </>
      }
    >
      <AvForm id={questionsFormId} onValidSubmit={handleSubmit}>
        {fields?.map((field, index) => {
          return <AvField key={index} {...field} />
        })}
      </AvForm>
    </ModalBase>
  )
}
