import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import uniqueId from 'lodash/uniqueId'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { CLIENT_INVOICE_STATUSES } from '.'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { getPartners, submitGeneratedInvoice } from '../../../../services/api'
import openFile from '../../../../utils/file/open'
import {
  customOption,
  customSingleValue,
} from '../../../Contract/RequestContract/FullTimeForm'

const DESCRIPTION_STRING = 'Description'
const AMOUNT_STRING = 'Amount'

const defaultFields = [
  {
    id: uniqueId(),
    desc_field: { name: DESCRIPTION_STRING },
    amount_field: { name: AMOUNT_STRING },
  },
]

function validate(arr) {
  if (!Array.isArray(arr) || arr.length === 0 || arr.every((a) => a[0])) {
    return true
  }

  const falseCheck = arr.find((a) => !a[0])
  const falseCheckFunc = falseCheck[1]
  if (typeof falseCheckFunc === 'function') {
    falseCheckFunc()
  }
  return false
}

const InvoiceGenerationForm = ({ initialData, onSubmit }) => {
  const [fieldsArray, setFieldsArray] = useState(defaultFields)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [selectedPartner, setSelectedPartner] = useState(null)

  const currencies = useSelector(
    (state) => state?.Layout?.staticData?.currencies,
  )

  const history = useHistory()
  const { state: contract } = useLocation()

  const submitData = useFetch({
    action: submitGeneratedInvoice,
    withAdminAccess: true,
    onComplete: (data) => {
      openFile(data, `generated-invoice.pdf`)
      history.push('/admin/contracts-invoices')
      toastr.success('Invoice submitted successfully')
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleSelectCurrency(type) {
    setSelectedCurrency(type)
  }
  function handleSelectPartner(type) {
    setSelectedPartner(type)
  }
  const requestsPartners = useFetch({
    action: getPartners,
    withAdminAccess: true,
    autoFetch: true,
  })
  function validateFields() {
    const hasEmtyValue = fieldsArray.find(
      (e) =>
        e.desc_field?.value === undefined ||
        e.amount_field?.value === undefined,
    )

    const fieldsValid = validate([
      [!hasEmtyValue, () => toastr.error('Please fill all invoice lines')],
    ])

    return fieldsValid
  }

  function submitForm() {
    const fieldsValid = validateFields()
    if (!fieldsValid) {
      return
    }
    if (!selectedCurrency || !selectedPartner) {
      toastr.error('Please fill all invoice lines')
      return
    }

    const bodyData = {
      currency_id: selectedCurrency?.id,
      partner_id: selectedPartner?.value,
      items: fieldsArray.map((e) => {
        const newItem = {
          description: e.desc_field.value,
          amount: e.amount_field.value,
        }
        return newItem
      }),
    }

    const resubmitting =
      contract?.client_invoice_status === CLIENT_INVOICE_STATUSES.DECLINED

    if (resubmitting) {
      bodyData.client_invoice_id = contract?.client_invoice_id
    }

    submitData.startFetch(bodyData)
  }

  const schema = yup.object().shape({
    payroll: yup.array().of(
      yup.object().shape({
        desc_field: yup
          .object()
          .shape({
            name: yup.string().required(),
            value: yup.number().required(),
          })
          .required(),
        amount_field: yup
          .object()
          .shape({
            name: yup.string().required(),
            value: yup.number().required(),
          })
          .required(),
      }),
    ),
  })

  const { handleSubmit, setValue, formState } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onAppend = () => {
    const newValue = {
      ...defaultFields,
      id: uniqueId(),
    }
    const newArray = [...fieldsArray, newValue]

    setFieldsArray(newArray)

    setTimeout(() => {
      setValue('payroll', newArray)
    }, 500)
  }

  const onChangeValue = (i, name, value) => {
    const newArray = fieldsArray.map((field, index) => {
      const newField = { ...field }
      if (index === i) {
        newField[name] = { ...newField[name], value }
      }
      return newField
    })
    setFieldsArray(newArray)
  }

  const validateArray = () => {
    return !fieldsArray.find(
      (e) =>
        e.desc_field?.value === undefined ||
        e.amount_field?.value === undefined,
    )
  }

  const onValidate = (v) => {
    if (validateArray()) {
      onSubmit({
        ...v,
        desc_field: fieldsArray.map((e) => e.desc_field),
        amount_field: fieldsArray.map((e) => e.amount_field),
        additional_text: initialData?.additional_text || '',
      })
    }
  }

  const contractFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: selectedCurrency?.code || 'USD',
  })
  const invoiceTotal = useMemo(() => {
    const value = fieldsArray.reduce(
      (prev, curr) => Number(prev || 0) + Number(curr.amount_field?.value || 0),
      0,
    )

    return value
  }, [fieldsArray])

  return (
    <CardBody className='m-0 p-0'>
      <Form
        autoComplete='off'
        className='form-horizontal m-0 pt-3 pt-md-4'
        onSubmit={handleSubmit(onValidate)}
      >
        <Container fluid>
          <Row>
            <Col md={6}>
              <FormGroup className='select2-container'>
                <Label htmlFor='cardnumberInput'>
                  Partner
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <CustomSelect
                  name='partner'
                  value={selectedPartner}
                  onChange={(s) => {
                    handleSelectPartner(s)
                  }}
                  options={
                    requestsPartners?.data
                      ? requestsPartners.data?.map((c) => ({
                          value: c.id,
                          label: c.company_name,
                        }))
                      : []
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className='select2-container'>
                <Label htmlFor='cardnumberInput'>
                  Currency
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <CustomSelect
                  name='currency'
                  value={selectedCurrency}
                  onChange={(s) => {
                    handleSelectCurrency(s)
                  }}
                  options={currencies?.map((e) => ({
                    label: e.code,
                    value: e.code,
                    id: e.id,
                    provider: e.provider,
                    country: e?.country,
                    code: e.code,
                    name: e.name,
                    icon: (
                      <div
                        className={`currency-flag mr-2 currency-flag-${e.code.toLowerCase()}`}
                      />
                    ),
                  }))}
                  customComponents={{
                    Option: customOption,
                    Value: customSingleValue,
                    SingleValue: customSingleValue,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {fieldsArray.map(
                (
                  { id, desc_field: descField, amount_field: amountField },
                  index,
                ) => {
                  return (
                    <div key={`ww-${id}`} className='px-0'>
                      <Row id={`ww-${id}`}>
                        <Col md={6}>
                          <FormGroup className='mb-0'>
                            <Label>
                              {DESCRIPTION_STRING}
                              <span className='text-danger font-size-16 ml-1'>
                                *
                              </span>
                            </Label>
                            <Input
                              className={cx({
                                'border-danger':
                                  !descField?.value &&
                                  formState.submitCount > 0,
                              })}
                              placeholder={DESCRIPTION_STRING}
                              value={descField?.value}
                              onChange={(v) => {
                                onChangeValue(
                                  index,
                                  'desc_field',
                                  v.target.value,
                                )
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className='mb-0'>
                            <Label>
                              {AMOUNT_STRING}
                              <span className='text-danger font-size-16 ml-1'>
                                *
                              </span>
                            </Label>
                            <Input
                              className={cx({
                                'border-danger':
                                  !amountField?.value &&
                                  formState.submitCount > 0,
                              })}
                              placeholder={AMOUNT_STRING}
                              value={amountField?.value}
                              onChange={(v) => {
                                onChangeValue(
                                  index,
                                  'amount_field',
                                  v.target.value,
                                )
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {fieldsArray.length <= 1 ? null : (
                        <div className='d-flex justify-content-end pb-1 pr-1'>
                          <button
                            className='rp-btn-nostyle text-danger font-size-14 hover:bg-soft-danger'
                            type='button'
                            onClick={() => {
                              const newArray = [...fieldsArray]
                              newArray.splice(index, 1)
                              setFieldsArray(newArray)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  )
                },
              )}
              <Row>
                <Col className='d-flex justify-content-end py-1'>
                  <button
                    className='rp-btn-nostyle text-primary font-size-14 hover:bg-soft-primary'
                    type='button'
                    onClick={onAppend}
                  >
                    Add field
                  </button>
                </Col>
                <Col md={{ size: 6, offset: 6 }} className='mt-2'>
                  <ShowTotal
                    label='Total:'
                    total={contractFormatter.format(invoiceTotal)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div
          className='d-flex justify-content-end pb-3 pt-4 px-4'
          style={{ gap: '0 1rem' }}
        >
          <Button color='primary' onClick={() => history.goBack()} outline>
            Cancel
          </Button>
          <Button
            color='primary'
            loading={submitData.isLoading}
            disabled={submitData.isLoading}
            onClick={submitForm}
          >
            Create Invoice
          </Button>
        </div>
      </Form>
    </CardBody>
  )
}

export function ShowTotal({ label, total, textColor = 'text-muted' }) {
  return (
    <>
      <span className='rp-font-bold font-size-14'>{label}</span>
      <span className={cx('font-size-14 rp-font-mono pl-2', textColor)}>
        {total}
      </span>
    </>
  )
}

export default InvoiceGenerationForm
