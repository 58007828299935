import React, { useState } from 'react'
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'

import paymentConfirmation from '../../assets/images/payment_confirmation.svg'
import Loader from '../../components/ui/loader'
import { useFetch, useResize } from '../../helpers/hooks'
import { downloadTransactionDEWSFile } from '../../services/api'
import openFileV2 from '../../utils/file/open-v2'
import { CaretDown } from '@phosphor-icons/react'

const ConfirmationPage = ({
  onComplete,
  loading,
  confirmed,
  onDownload,
  payload,
}) => {
  const {
    has_dews_contributions: hasContributions,
    id: transactionID,
    ref,
  } = JSON.parse(payload || '{}')
  const isMobile = useResize()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const { startFetch: downloadDEWSFile } = useFetch({
    action: downloadTransactionDEWSFile,
    onComplete: (data) => {
      openFileV2(data, {
        name: `DEWS-${ref}.csv`,
        download: true,
      })
    },
  })

  return loading ? (
    <Loader minHeight='30rem' />
  ) : (
    <Row className='justify-content-center'>
      <Col className='p-0'>
        <Row
          className='justify-content-center align-items-center'
          style={{ minHeight: '75vh' }}
        >
          <Col>
            <Row
              className='justify-content-center'
              style={{ fontSize: '10rem' }}
            >
              {confirmed ? (
                <img
                  height={isMobile ? 200 : 400}
                  src={paymentConfirmation}
                  alt=''
                />
              ) : (
                <i className='bx bx-x-circle text-danger' />
              )}
            </Row>
            {confirmed && (
              <Row className='justify-content-center text-center font-size-16'>
                <Col>
                  <p className='text-muted'>
                    Your transaction is currently being processed. You will
                    receive a notification as soon as your transaction is
                    confirmed.
                  </p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div className='tw-flex tw-items-center tw-justify-center tw-gap-2'>
          <Button
            onClick={onComplete}
            className='my-2 rp-font-bold'
            color='primary'
          >
            Go to Transactions
          </Button>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              className='!tw-flex tw-items-center tw-gap-2 !tw-px-6'
              color='primary'
            >
              Download Files <CaretDown size={20} />
            </DropdownToggle>
            <DropdownMenu>
              {!hasContributions ? null : (
                <DropdownItem
                  onClick={() =>
                    downloadDEWSFile({ transaction_id: transactionID })
                  }
                >
                  Download DEWS File
                </DropdownItem>
              )}
              {!onDownload ? null : (
                <DropdownItem onClick={onDownload}>
                  Download Statement
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </Col>
    </Row>
  )
}

export default ConfirmationPage
