import { yupResolver } from '@hookform/resolvers/yup'
import { FilePlus, XCircle } from '@phosphor-icons/react'
import cx from 'classnames'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useForm, useWatch } from 'react-hook-form'
import Pagination from 'react-js-pagination'
import Select from 'react-select'
import {
  Button as BsButton,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import archiveIconHover from '../../../../assets/images/archive.svg'
import archiveIcon from '../../../../assets/images/archiveBlack.svg'
import unArchiveIcon from '../../../../assets/images/unarchive.svg'
import unArchiveIconHover from '../../../../assets/images/unarchiveHover.svg'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import customStyles from '../../../../components/Common/react-select-custom-styles'
import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import SearchBar from '../../../../components/SearchBar'
import BadgeX from '../../../../components/Table/BadgeX'
import { StyledH5, StyledH6 } from '../../../../components/Typo'
import ControlledDropzoneInput from '../../../../components/controlled-dropzone-input'
import Button from '../../../../components/ui/button'
import { CheckItem } from '../../../../components/ui/check-item'
import Loader from '../../../../components/ui/loader'
import NavTabs from '../../../../components/ui/nav-tabs'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import FEATURE_FLAGS from '../../../../config/feature-flags'
import { useFetch, useResize } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  approveCompany,
  approveKYB as approveKYBAction,
  archiveCompany,
  getAdminCompanyDetails,
  getCompaniesListAdmin,
  getPaymentAccount,
  rejectKYB as rejectKYBAction,
  screenCompany,
  screenContractorEntity,
  unArchiveCompany,
  updateCompanyAdmin as updateCompanyAdminAction,
  updateCompanyPerks,
} from '../../../../services/api'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'
import { ImageIcon } from '../../../Contract/ContractList/ContractList'
import LabelContent from '../../../Contract/CreateContract/components/label-content'
import { FILE_SIZE_LIMITS_IN_BYTES } from '../../../Contract/utils/constants'
import { ConsultingFeeManagement } from './consulting-fee-management'
import { ManageEorMarkupPercentage } from './manage-eor-markup-percentage'
import { ManageUsdHandlingFee } from './manage-usd-handling-fee'
import { ManagementFeeManagement } from './management-fee-management'
import { ServiceFeeManagement } from './service-fee-management'

const kybStatuses = [
  { label: 'Pending submission', value: 'pending' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Approved', value: 'approved' },
  { label: 'Declined', value: 'declined' },
]

const archivedOptions = [
  { label: 'Active', value: 0 },
  { label: 'Archived', value: 1 },
]

const tabs = ['Active', 'Archived', 'Contractors']

function getKyb(tab, value) {
  if (tab === 2) {
    return undefined
  } else {
    return value
  }
}

function getUserType(tab) {
  if (tab === 2) {
    return 'contractor'
  } else {
    return 'client'
  }
}

function getCompaniesBody(
  { activePage, activeTab, searchQuery, contractorsArchived, kybStatus },
  { sort_by: sortBy, sort_direction: sortDirection } = {},
) {
  const body = {
    page: activePage,
    search: searchQuery,
    archived: activeTab === 2 ? contractorsArchived?.value : activeTab,
    user_type: getUserType(activeTab),
    kyb_status: getKyb(activeTab, kybStatus?.value),
  }
  if (sortBy) {
    body.sort_by = sortBy
  }
  if (sortDirection) {
    body.sort_direction = sortDirection
  }

  return body
}

function SortableColumn({ children, onClick, asc, className }) {
  return (
    <th onClick={onClick} className={cx(className, 'cursor-pointer')}>
      {children}{' '}
      {asc === undefined ? (
        <i className='bx bxs-sort-alt text-muted' />
      ) : asc ? (
        <span className='text-primary'>&#9650;</span>
      ) : (
        <span className='text-primary'>&#9660;</span>
      )}
    </th>
  )
}

const Companies = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [show, setShow] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [archiving, setArchiving] = useState(-1)
  const [searchQuery, setSearchQuery] = useState('')
  const [kybStatus, setKybStatus] = useState(null)
  const [contractorsArchived, setContractorsArchived] = useState(
    archivedOptions[0],
  )

  const companyList = useFetch(
    {
      action: getCompaniesListAdmin,
      withAdminAccess: true,
      autoFetch: true,
      body: getCompaniesBody({
        activePage,
        activeTab,
        searchQuery,
        contractorsArchived,
        kybStatus,
      }),
      initResult: [],
    },
    [
      activePage,
      searchQuery,
      activeTab,
      kybStatus?.value,
      contractorsArchived?.value,
    ],
  )

  function refetchCompanyList(loading) {
    const sortBy = companyList?.paginator?.sort_by
    const sortDirection = companyList?.paginator?.sort_direction

    companyList.startFetch(
      getCompaniesBody(
        {
          activePage,
          activeTab,
          searchQuery,
          contractorsArchived,
          kybStatus,
        },
        { sort_by: sortBy, sort_direction: sortDirection },
      ),
      loading,
    )
  }

  function handleSort(columnId) {
    const sortBy = companyList?.paginator?.sort_by
    const sortDirection = companyList?.paginator?.sort_direction

    const newSortBy = columnId
    const newSortDirection =
      columnId === sortBy ? (sortDirection === 'desc' ? 'asc' : 'desc') : 'asc'

    const body = getCompaniesBody(
      {
        activePage,
        activeTab,
        searchQuery,
        contractorsArchived,
        kybStatus,
      },
      { sort_by: newSortBy, sort_direction: newSortDirection },
    )

    companyList.startFetch(body)
  }

  const update = useFetch({
    action: updateCompanyAdminAction,
    withAdminAccess: true,
    onComplete: () => {
      refetchCompanyList()
    },
  })

  const archive = useFetch({
    action: archiveCompany,
    withAdminAccess: true,
    onComplete: refetchCompanyList,
  })

  const isArchivePopEnabled = FEATURE_FLAGS.COMPANY_ARCHIVE_POPUP

  const [perksEditId, setPerksEditId] = useState()
  const { startFetch: manageCompanyPerks, isLoading: updatingPerks } = useFetch(
    {
      action: updateCompanyPerks,
      withAdminAccess: true,
      onComplete: () => {
        refetchCompanyList()
      },
    },
  )

  function hasMethod(company, id) {
    const ids = company?.payment_method_ids?.split(',')

    return ids?.includes(`${id}`)
  }

  function togglePaymentStatus(item, methodId) {
    if (
      !item?.payment_method_ids ||
      typeof item?.payment_method_ids !== 'string'
    ) {
      return
    }

    const currentMethods = item?.payment_method_ids
      ?.split(',')
      ?.map((e) => Number(e))

    const methodEnabled = currentMethods?.includes(methodId)

    let newMethods = [...currentMethods]

    if (methodEnabled) {
      newMethods = currentMethods.filter((f) => f !== methodId)
    } else {
      newMethods.push(methodId)
    }

    update.startFetch({
      company_id: item?.id,
      payment_method_ids: newMethods,
    })
  }
  const approve = useFetch({
    action: approveCompany,
    withAdminAccess: true,
    onComplete: refetchCompanyList,
  })

  const unArchive = useFetch({
    action: unArchiveCompany,
    withAdminAccess: true,
    onComplete: refetchCompanyList,
  })

  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_COMPANIES)

  const perksStatusOptions = [
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Pending', value: 'pending', isDisabled: true },
  ]

  function toggleCompanyPerks(company, newStatus) {
    const { id } = company
    setPerksEditId(id)
    manageCompanyPerks({
      id,
      perk_request_status: newStatus,
    })
  }

  function getAsc(name) {
    const sortBy = companyList?.paginator?.sort_by
    const sortDirection = companyList?.paginator?.sort_direction

    const isAsc =
      !sortBy || sortBy !== name
        ? undefined
        : sortDirection === 'asc' && sortBy === name

    return isAsc
  }

  return (
    <div className='page-content'>
      <CompanyDetails
        company={selectedCompany}
        hide={() => setShow(false)}
        show={show}
      />

      <div>
        <StyledH5 min='22px' max='32px' className='mb-3 mb-md-4'>
          All Companies
        </StyledH5>

        <Card className='m-0'>
          <CardBody className='p-0'>
            <Container className='d-flex flex-column mb-3 gap-16' fluid>
              <NavTabs
                className='pt-3 pb-2'
                options={tabs.map((e, i) => ({ label: e, value: i }))}
                activeOption={activeTab}
                onClickOption={(option) => setActiveTab(option?.value)}
              />

              <Row>
                <Col xs={12} md={4}>
                  {activeTab === 2 ? (
                    <CustomSelect
                      options={archivedOptions}
                      value={contractorsArchived}
                      onChange={setContractorsArchived}
                    />
                  ) : (
                    <CustomSelect
                      options={kybStatuses}
                      value={kybStatus}
                      onChange={setKybStatus}
                      backspaceRemovesValue
                      isClearable
                    />
                  )}
                </Col>
                <Col xs={12} md={8} className='pl-md-0'>
                  <SearchBar
                    query={searchQuery}
                    onQueryChanged={setSearchQuery}
                    placeholder='search by name'
                    className='mr-md-2'
                  />
                </Col>
              </Row>
            </Container>
            {companyList.isLoading ? (
              <Loader minHeight={300} />
            ) : (
              <>
                <div
                  className='table-responsive'
                  style={{ paddingBottom: 110 }}
                >
                  <Table className='table-centered table-nowrap'>
                    <thead>
                      <tr>
                        <SortableColumn
                          asc={getAsc('id')}
                          onClick={() => handleSort('id')}
                          className='first-company-col sticky-col'
                        >
                          Company ID
                        </SortableColumn>
                        <SortableColumn
                          asc={getAsc('name')}
                          onClick={() => handleSort('name')}
                          className='second-company-col sticky-col'
                        >
                          Company Name
                        </SortableColumn>
                        {activeTab !== 2 && (
                          <SortableColumn
                            asc={getAsc('dba')}
                            onClick={() => handleSort('dba')}
                            className='third-company-col sticky-col'
                          >
                            Doing Business as
                          </SortableColumn>
                        )}
                        <SortableColumn
                          asc={getAsc('created_at')}
                          onClick={() => handleSort('created_at')}
                        >
                          Created on
                        </SortableColumn>

                        <th>Funnel</th>
                        <th>First transaction date</th>
                        <th>First contract created on</th>
                        <th>Type</th>
                        <th>Creator name</th>
                        <th>Creator email</th>
                        <th># of active contracts</th>
                        <th># of pending contracts</th>
                        <th>Last Payment by</th>
                        <th>Country</th>
                        <th>Payment Account</th>
                        <th>Payment Currency</th>
                        {!hasEditPermission || activeTab === 2 ? null : (
                          <>
                            <th>Activate</th>
                            <th>Perks</th>
                            <th>API</th>
                            <th>EOR</th>
                            <th>DE</th>
                            <th>RemotePass+</th>
                            <th>CC</th>
                            <th>SEPA</th>
                            <th>ACH</th>
                            <th>CB</th>
                            <th>Bill</th>
                          </>
                        )}
                        <th>KYB</th>
                        {!hasEditPermission || activeTab === 2 ? null : (
                          <>
                            <th>Service Fee</th>
                            <th>Consulting fee</th>
                            <th>EOR fee</th>
                            <th>EOR markup percentage</th>
                            <th>USD handling fee</th>
                          </>
                        )}
                        {!hasEditPermission ? null : activeTab === 1 ? (
                          <th>Unarchive</th>
                        ) : (
                          <th>Archive</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {companyList.data?.map((company, key) => {
                        return (
                          <tr key={`comp-${company.id}`}>
                            <td className='first-company-col sticky-col'>
                              {company?.id}
                            </td>
                            <td className='second-company-col sticky-col'>
                              <button
                                onClick={() => {
                                  setSelectedCompany(company)
                                  setShow(true)
                                }}
                                className='px-0 rp-btn-nostyle text-primary'
                              >
                                {company?.name}
                              </button>
                            </td>
                            {activeTab !== 2 && (
                              <td
                                className='third-company-col sticky-col text-truncate'
                                title={company?.dba}
                              >
                                {company?.dba}
                              </td>
                            )}
                            <td>
                              {moment(company?.created_at * 1000).format(
                                'DD/MM/YYYY',
                              )}
                            </td>
                            <td>{company?.funnel}</td>
                            <td>
                              {company?.first_transaction_date &&
                                moment(
                                  company?.first_transaction_date * 1000,
                                ).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              {company?.first_contract_created_on &&
                                moment(
                                  company?.first_contract_created_on * 1000,
                                ).format('DD/MM/YYYY')}
                            </td>
                            <td>{company?.type?.name}</td>
                            <td>{company?.creator_name}</td>
                            <td>{company?.creator_email}</td>
                            <td>{company?.active_contracts}</td>
                            <td>{company?.pending_contracts}</td>
                            <td>{company?.last_payment_by}</td>
                            <td>{company?.country?.name}</td>
                            <td>
                              <PaymentAccount
                                company={company}
                                hasEditPermission={hasEditPermission}
                                onUpdate={refetchCompanyList}
                              />
                            </td>
                            <td>{company?.currency?.name}</td>
                            {!hasEditPermission || activeTab === 2 ? null : (
                              <>
                                <td>
                                  <Toggle
                                    id={`approveCompany${key}`}
                                    check={!!company.approved}
                                    change={(event) => {
                                      const checked = event.target.checked
                                      approve.startFetch({
                                        company_id: company?.id,
                                        approved: checked ? 1 : 0,
                                      })
                                    }}
                                    disabled={
                                      !company.approved && activeTab === 1
                                    }
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  {!company.perk_request_status ? null : (
                                    <code className='d-inline-block mr-1 text-capitalize text-secondary'>
                                      {company.perk_request_status}
                                    </code>
                                  )}
                                  <CustomSelect
                                    wrapperClassName='d-inline-block'
                                    classNamePrefix=''
                                    options={perksStatusOptions.map((o) => {
                                      if (
                                        o.value === company.perk_request_status
                                      )
                                        return {
                                          ...o,
                                          isDisabled:
                                            o.value ===
                                            company.perk_request_status,
                                        }

                                      return o
                                    })}
                                    styles={customStyles}
                                    defaultValue={
                                      perksStatusOptions.find(
                                        (o) =>
                                          o.value ===
                                          company.perk_request_status,
                                      ) ?? null
                                    }
                                    onChange={(v) => {
                                      toggleCompanyPerks(company, v?.value)
                                    }}
                                    isDisabled={
                                      updatingPerks &&
                                      perksEditId === company.id
                                    }
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={company?.api_keys_enabled === 1}
                                    change={() => {
                                      update.startFetch({
                                        company_id: company?.id,
                                        api_keys_enabled:
                                          company?.api_keys_enabled === 1
                                            ? 0
                                            : 1,
                                      })
                                    }}
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={company?.eor === 1}
                                    change={() => {
                                      update.startFetch({
                                        company_id: company?.id,
                                        eor: company?.eor === 1 ? 0 : 1,
                                      })
                                    }}
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={
                                      !!company?.is_direct_employee_enabled ||
                                      company?.is_direct_employee_enabled === 1
                                    }
                                    change={() => {
                                      update.startFetch({
                                        company_id: company?.id,
                                        is_direct_employee_enabled:
                                          company?.is_direct_employee_enabled ===
                                          1
                                            ? 0
                                            : 1,
                                      })
                                    }}
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={!!company.is_cor_enabled}
                                    change={(event) => {
                                      const checked = event.target.checked
                                      update.startFetch({
                                        company_id: company?.id,
                                        is_cor_enabled: checked ? 1 : 0,
                                      })
                                    }}
                                    disabled={
                                      !company.is_cor_enabled && activeTab === 1
                                    }
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={hasMethod(company, '1')}
                                    change={() =>
                                      togglePaymentStatus(company, 1)
                                    }
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={hasMethod(company, '4')}
                                    change={() =>
                                      togglePaymentStatus(company, 4)
                                    }
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={hasMethod(company, '5')}
                                    change={() =>
                                      togglePaymentStatus(company, 5)
                                    }
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={hasMethod(company, '18')}
                                    change={() =>
                                      togglePaymentStatus(company, 18)
                                    }
                                    className='d-block'
                                  />
                                </td>
                                <td>
                                  <Toggle
                                    check={!!company.is_bill_enabled}
                                    change={(event) => {
                                      const checked = event.target.checked
                                      update.startFetch({
                                        company_id: company?.id,
                                        is_bill_enabled: checked ? 1 : 0,
                                      })
                                    }}
                                    disabled={
                                      !company.is_bill_enabled &&
                                      activeTab === 1
                                    }
                                    className='tw-block'
                                  />
                                </td>
                              </>
                            )}
                            <td>
                              <CompanyDetailsColumn
                                company={company}
                                refreshData={() => refetchCompanyList(false)}
                                hasEditPermission={hasEditPermission}
                              />
                            </td>
                            {!hasEditPermission || activeTab === 2 ? null : (
                              <>
                                <td>
                                  <ServiceFeeManagement
                                    company={company}
                                    updateCompaniesList={refetchCompanyList}
                                  />
                                </td>
                                <td>
                                  <ConsultingFeeManagement
                                    company={company}
                                    updateCompaniesList={refetchCompanyList}
                                  />
                                </td>
                                <td>
                                  <ManagementFeeManagement
                                    company={company}
                                    updateCompaniesList={refetchCompanyList}
                                  />
                                </td>
                                <td>
                                  <ManageEorMarkupPercentage
                                    company={company}
                                    updateCompaniesList={refetchCompanyList}
                                  />
                                </td>
                                <td>
                                  <ManageUsdHandlingFee
                                    company={company}
                                    updateCompaniesList={refetchCompanyList}
                                  />
                                </td>
                              </>
                            )}

                            {!hasEditPermission ? null : (
                              <td>
                                {archiving === key &&
                                archive.isLoading &&
                                !isArchivePopEnabled ? (
                                  <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                                ) : (
                                  <button
                                    className='rp-btn-nostyle p-1'
                                    onClick={() => {
                                      if (activeTab === 1) {
                                        unArchive.startFetch({
                                          company_id: company?.id,
                                        })
                                        return
                                      }

                                      if (isArchivePopEnabled) {
                                        setArchiving(key)
                                      } else {
                                        archive.startFetch({
                                          company_id: company?.id,
                                          status: activeTab === 1 ? 0 : 1,
                                        })
                                      }
                                    }}
                                  >
                                    <ImageIcon
                                      icon={
                                        activeTab === 1
                                          ? unArchiveIcon
                                          : archiveIcon
                                      }
                                      hoverIcon={
                                        activeTab === 1
                                          ? unArchiveIconHover
                                          : archiveIconHover
                                      }
                                      alt={
                                        activeTab === 1
                                          ? 'unarchive icon'
                                          : 'archive icon'
                                      }
                                    />
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className='p-3 d-flex justify-content-end'>
                  <Pagination
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={companyList.paginator?.per_page}
                    totalItemsCount={companyList.paginator?.total ?? 10}
                    onChange={setActivePage}
                    innerClass='pagination mb-0'
                  />
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      {archiving > -1 && isArchivePopEnabled && (
        <ArchiveModal
          company={companyList.data[archiving]}
          isOpen={archiving > -1 && isArchivePopEnabled}
          toggle={() => setArchiving(-1)}
          activeTab={activeTab}
          onUpdate={refetchCompanyList}
        />
      )}
    </div>
  )
}

function PaymentAccount({ company, hasEditPermission, onUpdate }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState(null)

  const {
    data: paymentAccounts,
    isLoading,
    startFetch: getAccounts,
  } = useFetch({
    action: getPaymentAccount,
    withAdminAccess: true,
    autoFetch: false,
  })

  const { isLoading: updatingCompany, startFetch: updateCompanyAdmin } =
    useFetch({
      action: updateCompanyAdminAction,
      withAdminAccess: true,
      onComplete: () => {
        setIsModalOpen(false)
        if (typeof onUpdate === 'function') {
          onUpdate()
        }
      },
    })

  useEffect(() => {
    document.body.classList.toggle('modal-open', isModalOpen)
  }, [isModalOpen])

  useEffect(() => {
    if (isModalOpen) {
      getAccounts()
      setSelectedPaymentAccount({
        label: company?.payment_account_name,
        value: company?.payment_account_id,
      })
    }
  }, [company?.payment_account_id, company?.payment_account_name, isModalOpen])

  const paymentAccountChanged = useMemo(
    () => selectedPaymentAccount?.value !== company?.payment_account_id,
    [company?.payment_account_id, selectedPaymentAccount?.value],
  )

  const paymentAccountOptions = useMemo(() => {
    return (paymentAccounts ?? [])?.map((account) => ({
      label: account?.name,
      value: account?.id,
    }))
  }, [paymentAccounts])

  const paymentAccountData = useMemo(() => {
    if (
      !paymentAccounts ||
      paymentAccounts?.length <= 0 ||
      !selectedPaymentAccount?.value
    ) {
      return []
    }

    return paymentAccounts.find(({ id }) => id === selectedPaymentAccount.value)
      ?.data
  }, [paymentAccounts, selectedPaymentAccount?.value])

  function toggle() {
    setIsModalOpen((o) => !o)
  }

  function handleSave() {
    if (!company?.id || !selectedPaymentAccount?.value) return

    updateCompanyAdmin({
      company_id: company.id,
      payment_account_id: selectedPaymentAccount.value,
    })
  }

  return (
    <>
      <button
        type='button'
        className='rp-btn-nostyle text-primary'
        onClick={toggle}
      >
        {company?.payment_account_name}
      </button>
      <Modal isOpen={isModalOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Payment account</ModalHeader>
        <ModalBody className='d-flex flex-column' style={{ gap: '1rem' }}>
          <Select
            options={paymentAccountOptions}
            styles={customStyles}
            value={selectedPaymentAccount}
            onChange={setSelectedPaymentAccount}
            isDisabled={!hasEditPermission}
          />
          {isLoading ? (
            <div className='d-flex justify-content-center align-items-center'>
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <div className='d-flex flex-column' style={{ gap: '1rem' }}>
              {paymentAccountData.map(({ name, value }, index) => {
                return (
                  <div
                    key={index}
                    className='d-flex flex-column'
                    style={{ gap: '0.25rem' }}
                  >
                    <div className='text-secondary'>{name}:</div>
                    <div className='font-size-14'>{value}</div>
                  </div>
                )
              })}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <BsButton
            color='light'
            outline
            disabled={updatingCompany}
            onClick={toggle}
          >
            Cancel
          </BsButton>
          {!hasEditPermission ? null : (
            <BsButton
              color='primary'
              loading={updatingCompany}
              disabled={!paymentAccountChanged || updatingCompany}
              onClick={handleSave}
            >
              Save new payment account
            </BsButton>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

const EditCompanyMsaAgreement = ({
  isOpen,
  toggle,
  company,
  onEditSuccess,
}) => {
  const dateFormat = 'yyyy-MM-dd'
  const msaEditFormID = 'msaAgreementFormId'

  const schema = yup.object().shape({
    date_signed: yup.string().required('Signature date is required'),
    msa_agreement_document: yup
      .mixed()
      .required('MSA Agreement Document is required'),
  })
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      date_signed: null,
      msa_agreement_document: null,
    },
  })

  const {
    msa_agreement_document: msaAgreementDoc,
    date_signed: dateMsaSigned,
  } = useWatch({ control })

  const { startFetch: updateMsaAgreement, isLoading: updatingMsaAgreement } =
    useFetch({
      action: updateCompanyAdminAction,
      withAdminAccess: true,
      onComplete: () => {
        onEditSuccess()
        toggle()
      },
      onError: (err) => {
        toastr.error(err)
      },
    })

  function onSubmit(data) {
    updateMsaAgreement({
      company_id: company?.id,
      msa_agreement_document_signed_date: dateMsaSigned,
      msa_agreement_document: data.msa_agreement_document[0],
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        close={<ModalCloseButton toggle={toggle} />}
        tag='div'
        cssModule={{ 'modal-title': 'flex-grow-1' }}
      >
        Edit {company?.name}&apos;s MSA Agreement
      </ModalHeader>

      <ModalBody>
        <form id={msaEditFormID} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <ControlledDatePicker
              control={control}
              name='date_signed'
              label={<LabelContent required>Signature Date</LabelContent>}
              placeholder='Select date signed'
              dateFormat={dateFormat}
              wrapperClassName='mb-3'
              error={errors?.date_signed?.message}
            />
          </FormGroup>

          <FormGroup>
            <LabelContent required>MSA Agreement Document</LabelContent>
            <ControlledDropzoneInput
              maxSize={FILE_SIZE_LIMITS_IN_BYTES.TWO_MB}
              control={control}
              name='msa_agreement_document'
              className={cx(
                'd-flex gap-12 align-items-center flex-wrap font-size-14 cursor-pointer px-3 py-2 bg-primary-20',
                errors?.msa_agreement_document
                  ? 'text-danger bg-soft-danger'
                  : 'text-primary-100 bg-primary-20 border-primary-100',
                {
                  'justify-content-between': !!msaAgreementDoc?.[0]?.name,
                },
              )}
              error={errors.msa_agreement_document}
              accept={{ 'application/pdf': ['.pdf'] }}
              style={{ minHeight: 180 }}
            >
              {msaAgreementDoc?.[0]?.name ? (
                <>
                  {msaAgreementDoc?.[0]?.name}

                  <button
                    className='p-0.5 rp-btn-nostyle text-red-90 d-flex'
                    type='button'
                    onClick={(e) => {
                      e.stopPropagation()
                      setValue('msa_agreement_document', null)
                    }}
                  >
                    <XCircle size={20} weight='fill' />
                  </button>
                </>
              ) : (
                <>
                  <FilePlus size={20} weight='duotone' />
                  <div className='d-none d-md-block'>
                    Drop files here or click to upload
                  </div>
                  <div className='d-md-none'>Click to upload</div>

                  <div className='text-secondary-80 rp-font-normal'>
                    Max file size 2MB
                  </div>
                </>
              )}
            </ControlledDropzoneInput>
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={updatingMsaAgreement}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={msaEditFormID}
          disabled={updatingMsaAgreement}
          loading={updatingMsaAgreement}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const CompanyDetails = ({ hide, show, company }) => {
  const isMobile = useResize()
  const [openEditMsa, setOpenEditMsa] = useState(false)

  const getCompanyDetails = useFetch(
    {
      action: getAdminCompanyDetails,
      withAdminAccess: true,
      autoFetch: !!company?.id,
      body: { id: company?.id },
    },
    [company],
  )
  const isContractorEntity = company?.user_type === 'Contractor/Entity'

  const { startFetch: screeningCompany, isLoading: screening } = useFetch({
    action: isContractorEntity ? screenContractorEntity : screenCompany,
    withAdminAccess: true,
    onComplete: () => {
      getCompanyDetails.startFetch({ id: company?.id })
    },
  })
  const downloadFile = (base64, name) => {
    const link = document.createElement('a')
    link.href = base64
    link.setAttribute('download', `${name}.pdf`) // or any other extension
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <EditCompanyMsaAgreement
        isOpen={openEditMsa}
        company={company}
        toggle={() => setOpenEditMsa(!openEditMsa)}
        onEditSuccess={() => getCompanyDetails.startFetch({ id: company?.id })}
      />
      <Menu
        onClose={hide}
        className='bg-white'
        isOpen={show}
        width={isMobile ? '100%' : '30%'}
        right
      >
        {getCompanyDetails.isLoading ? (
          <Row
            style={{ minHeight: '30rem' }}
            className='d-flex p-0 m-0 justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        ) : (
          <Row className='p-0 m-0'>
            <Col md={12} className='p-0 m-0'>
              <Card>
                <CardBody className='m-0 p-4'>
                  {getCompanyDetails.data?.map((item, i) => {
                    return (
                      <div key={i}>
                        <div className='d-flex justify-content-between'>
                          <StyledH6 min='20px' max='30px'>
                            {item?.title}
                          </StyledH6>
                          {item?.title === 'MSA Agreement' && (
                            <Button
                              className='mt-1 mb-3'
                              size='sm'
                              onClick={() => {
                                setOpenEditMsa(!openEditMsa)
                                hide()
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </div>

                        {item?.value?.map((val) => {
                          const value = val?.value
                            ? typeof val?.value === 'string'
                              ? val?.value
                              : JSON.stringify(val?.value)
                            : 'N/A'

                          return (
                            <div key={val?.title}>
                              <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                                {val?.title}
                              </h5>
                              {val?.type === 'file' && val?.value ? (
                                <Button
                                  className='mt-1 mb-3'
                                  size='sm'
                                  outline
                                  onClick={() =>
                                    downloadFile(val.value, val.title)
                                  }
                                >
                                  Download
                                </Button>
                              ) : val?.type === 'status' &&
                                val?.value === 'pending' ? (
                                <Button
                                  className='mt-1 mb-3'
                                  size='sm'
                                  outline
                                  onClick={() =>
                                    screeningCompany({
                                      company_id: company?.id,
                                    })
                                  }
                                  disabled={screening}
                                  loading={screening}
                                >
                                  {isContractorEntity
                                    ? 'Screen Entity'
                                    : 'Screen Company & Signatory'}
                                </Button>
                              ) : (
                                <p
                                  className='p-2 rounded'
                                  style={{
                                    border: '1px solid #E7E8F2',
                                    color: 'var(--gray-600)',
                                  }}
                                >
                                  {value}
                                </p>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Menu>
    </div>
  )
}

const kybStatusColor = {
  pending: 'secondary',
  submitted: 'warning',
  approved: 'success',
  rejected: 'danger',
}

function CompanyDetailsColumn({
  company,
  refreshData = () => {},
  hasEditPermission,
}) {
  const [showModal, setShowModal] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)

  const companyName = company.dba ?? company.name

  function onCompleteAction() {
    setShowModal(false)
    refreshData()
  }

  const { startFetch: updateCompany, isLoading: updating } = useFetch({
    action: updateCompanyAdminAction,
    withAdminAccess: true,
    onComplete: onCompleteAction,
  })

  function toggle() {
    setShowModal((o) => !o)
  }
  function requestToConfirmCompanyDetails() {
    const body = {
      company_id: company.id,
      details_request: 1,
      details_request_email: sendEmail ? 1 : 0,
    }

    updateCompany(body)
  }

  if (!hasEditPermission) {
    return (
      <BadgeX status={kybStatusColor[company.kyb_status]}>
        {capitalizeFirstLetter(company.kyb_status)}
      </BadgeX>
    )
  }

  if (company.details_request === 1) {
    return (
      <div className='d-flex align-items-center' style={{ gap: '0.25rem' }}>
        <Toggle check={true} disabled className='d-block' />

        {company.kyb_status === 'submitted' ? (
          <KYBActions company={company} onCompleteAction={onCompleteAction} />
        ) : (
          <>
            <BadgeX status={kybStatusColor[company.kyb_status]}>
              {capitalizeFirstLetter(company.kyb_status)}
            </BadgeX>

            <RejectApprovedKYB
              company={company}
              onCompleteAction={onCompleteAction}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <Toggle check={company.details_request === 1} change={toggle} />

      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Company details not confirmed</ModalHeader>
        <ModalBody>
          <p>
            Do you want to request company details for{' '}
            <span className='rp-font-bold'>{companyName}</span>?
          </p>
          <div>
            <CheckItem
              label='Send email notification'
              name='send-email-notif'
              onChange={(e) => setSendEmail(e.target.checked)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline disabled={updating} color='light' onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={updating}
            loading={updating}
            onClick={requestToConfirmCompanyDetails}
          >
            Request company details
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function KYBActions({ company, onCompleteAction }) {
  const [showRejectReason, setShowRejectReason] = useState(false)

  const { startFetch: approveKYB, isLoading: approving } = useFetch({
    action: approveKYBAction,
    withAdminAccess: true,
    onComplete: onCompleteAction,
  })

  function handleApprove() {
    approveKYB({ company_id: company.id })
  }

  const { startFetch: rejectKYB, isLoading: rejecting } = useFetch({
    action: rejectKYBAction,
    withAdminAccess: true,
    onComplete: () => {
      setShowRejectReason(false)
      onCompleteAction()
    },
  })

  return (
    <div className='d-flex' style={{ gap: '0.25rem' }}>
      <Button
        size='sm'
        color='success'
        outline
        onClick={handleApprove}
        loading={approving}
        disabled={
          approving || rejecting || company.screening_status !== 'approved'
        }
      >
        Approve
      </Button>

      <RejectKybAction
        rejectKYB={rejectKYB}
        showRejectReason={showRejectReason}
        setShowRejectReason={setShowRejectReason}
        loading={rejecting}
        disabled={approving || rejecting}
        companyId={company.id}
      />
    </div>
  )
}

function RejectKybAction({
  rejectKYB,
  showRejectReason,
  setShowRejectReason,
  loading,
  disabled,
  companyId,
}) {
  const [declineReason, setDeclineReason] = useState('')

  function handleReject() {
    if (declineReason.length <= 0) {
      toastr.error('Please enter a reason for rejecting the KYB submission')
    } else {
      rejectKYB({ company_id: companyId, reason: declineReason })
    }
  }

  function toggle() {
    setShowRejectReason((o) => !o)
  }

  return (
    <>
      <Button
        size='sm'
        color='danger'
        outline
        onClick={() => setShowRejectReason(true)}
        loading={loading}
        disabled={disabled}
      >
        Reject
      </Button>

      <Modal isOpen={showRejectReason} toggle={toggle}>
        <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
          Decline KYB
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for='reason'>Decline reason:</Label>
            <Input
              type='textarea'
              name='reason'
              id='reason'
              placeholder='Reason'
              value={declineReason}
              onChange={(e) => setDeclineReason(e.target.value)}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color='light' outline onClick={toggle} disabled={disabled}>
            Cancel
          </Button>

          <Button
            onClick={handleReject}
            disabled={disabled}
            loading={loading}
            color='danger'
          >
            Decline Submission
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function RejectApprovedKYB({ company, onCompleteAction }) {
  const [showRejectReason, setShowRejectReason] = useState(false)

  const { startFetch: rejectKYB, isLoading: rejecting } = useFetch({
    action: rejectKYBAction,
    withAdminAccess: true,
    onComplete: () => {
      setShowRejectReason(false)
      onCompleteAction()
    },
  })

  if (company.kyb_status !== 'approved') {
    return null
  }

  return (
    <RejectKybAction
      rejectKYB={rejectKYB}
      showRejectReason={showRejectReason}
      setShowRejectReason={setShowRejectReason}
      loading={rejecting}
      disabled={rejecting}
      companyId={company.id}
    />
  )
}

function ArchiveModal({ isOpen, toggle, company, activeTab, onUpdate }) {
  const reasonsForDeactivationOptions = [
    { value: 'irrelevant', label: 'Irrelevant' },
    { value: 'compliance', label: 'Compliance' },
    { value: 'fraud', label: 'Fraud' },
    {
      value: 'no_response',
      label: 'No response',
    },
    { value: 'no_fit', label: 'No fit' },
    { value: 'other', label: 'Other' },
  ]
  const [updateCrm, setUpdateCrm] = useState(false)
  const [deactivateCompanyUsers, setDeactivateCompanyUsers] = useState(false)
  const [deactivationReason, setDeactivationReason] = useState(
    reasonsForDeactivationOptions[0].value,
  )
  const [note, setNote] = useState('')
  const archive = useFetch({
    action: archiveCompany,
    withAdminAccess: true,
    onComplete: () => {
      onUpdate()
      toggle()
    },
  })

  const handleArchive = () => {
    archive.startFetch({
      company_id: company?.id,
      status: activeTab === 1 ? 0 : 1,
      note,
      update_crm: updateCrm,
      reason: deactivationReason,
      deactivate_users: deactivateCompanyUsers,
    })
  }

  const infos = [
    { label: 'Company name', value: company?.name },
    { label: 'User', value: company?.creator_name },
    { label: 'Email', value: company?.creator_email },
  ]

  return (
    <Modal unmountOnClose centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Disable & Archive</ModalHeader>
      <ModalBody>
        <div>
          {infos.map((info, index) => {
            return (
              <p key={index} className='d-flex gap-12'>
                <span className='text-muted' style={{ minWidth: 100 }}>
                  {info.label}:
                </span>
                <span>{info.value}</span>
              </p>
            )
          })}

          <label className='d-flex'>
            <Toggle
              check={deactivateCompanyUsers}
              change={(event) => {
                setDeactivateCompanyUsers(event.target.checked)
              }}
            />
            <span>Deactivate Company Users</span>
          </label>

          <label className='d-flex py-2'>
            <Toggle
              check={updateCrm}
              change={(event) => {
                setUpdateCrm(event.target.checked)
              }}
            />
            <span>Update CRM (move to Lost stage)</span>
          </label>

          <FormGroup>
            <CustomSelect
              label='Reason'
              placeholder='Reason'
              searchable={false}
              defaultValue={reasonsForDeactivationOptions[0]}
              options={reasonsForDeactivationOptions}
              onChange={(v) => setDeactivationReason(v.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>Why are you closing this account?</Label>
            <Input
              type='textarea'
              name='reason'
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <FormText>
              This reason will be shared on slack and added to the CRM.
            </FormText>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle} disabled={archive.isLoading}>
          Cancel
        </Button>

        <Button
          color='primary'
          onClick={handleArchive}
          loading={archive.isLoading}
          disabled={archive.isLoading}
        >
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Companies
