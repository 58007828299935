import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import accessDenied from '../../assets/images/access-denied.webp'

const AccessDenied = () => {
  return (
    <Container fluid className='bg-white' style={{ marginBottom: '-2rem' }}>
      <Row className='align-items-center py-4' style={{ minHeight: '100vh' }}>
        <Col>
          <Row className='justify-content-center'>
            <img
              src={accessDenied}
              style={{ height: '400px', width: 'auto' }}
            />
          </Row>
          <Row className='justify-content-center'>
            <Link to='/' className='btn btn-primary'>
              Go To Home Page
            </Link>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default AccessDenied
