import React, { useCallback, useMemo } from 'react'
import toastr from 'toastr'

import { Container, Row } from 'reactstrap'
import DataTable from '../../../../../components/ui/data-table'
import { useFetch } from '../../../../../helpers/hooks'
import { updateCorSupportedCountry } from '../../../../../services/api'
import EmptyContent from '../../../../remotepass-cards/components/empty-content'
import { FilterField } from '../../cards/filter-field'
import { SupportToggle } from '../../cards/supported-countries'

export default function CorCountriesList({
  countries,
  isLoading,
  updateCountries,
}) {
  const { startFetch: updateCountry, isLoading: isUpdatingCountry } = useFetch({
    action: updateCorSupportedCountry,
    withAdminAccess: true,
    onComplete: () => {
      toastr.clear()
      toastr.success('Country updated successfully')

      updateCountries?.()
    },
  })

  const handleToggleSupport = useCallback(
    function handleToggleSupport(event, value) {
      const checked = event?.target?.checked
      const body = { id: value?.id, cor_enabled: checked ? 1 : 0 }

      updateCountry(body)
    },
    [updateCountry],
  )

  const toggleDisabled = useMemo(
    () => isLoading || isUpdatingCountry,
    [isLoading, isUpdatingCountry],
  )

  const columns = useMemo(
    () =>
      [
        { Header: 'Country', accessor: 'country.name' },
        {
          Header: 'RemotePass+ Enabled',
          accessor: 'cor_enabled',
          Cell: ({ cellData: supported, rowData, accessor }) => {
            return (
              <SupportToggle
                item={rowData}
                onToggle={handleToggleSupport}
                value={supported}
                name={accessor}
                disabled={toggleDisabled}
              />
            )
          },
        },
      ].filter(Boolean),
    [handleToggleSupport, toggleDisabled],
  )

  if (countries?.length <= 0) {
    return (
      <EmptyContent isCard={false} shadow={false} minHeight='30rem'>
        No countries found
      </EmptyContent>
    )
  }

  return (
    <div style={{ '--custom-min-h': '220px' }}>
      <DataTable
        data={countries}
        columns={columns}
        responsive
        cssModule={{ 'table-responsive': 'table-responsive custom-min-h' }}
      />
    </div>
  )
}

export function CorCountriesFilters({ filters, onChange }) {
  const filtersFields = [
    {
      label: 'Search',
      name: 'searchKey',
      type: 'text',
      colSize: 6,
      placeholder: 'Search by country name',
    },
  ]

  return (
    <Container fluid className='px-0'>
      <Row className='mx-n3'>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}
