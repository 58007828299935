import { SealCheck } from '@phosphor-icons/react'
import React from 'react'

import Button from '../../../components/ui/button'
import { getFullName } from '../../../utils/get-full-name'
import { ContractBadge } from '../../Contract/ContractDetail/contract-timeline'
import { RequestCheck } from './request-check-section'
import { useReviewContext } from './review-documents-columns'

export function ReviewDocumentsBulkEdit({ selectedItems }) {
  const {
    isSelecting,
    reviewItems,
    // refreshReviewList,
    // toggleSelecting,
    // setUrlState,
  } = useReviewContext()

  if (!isSelecting) {
    return null
  }

  const itemsToCheck = selectedItems
    ?.filter((item) => item.selected)
    .map((item) => {
      const currentItem = reviewItems[item.index].raw
      return {
        id: item.id,
        selected: true,
        name: getFullName(currentItem?.contractor),
        status: (
          <ContractBadge
            amended={currentItem?.amended}
            status={currentItem?.status}
            lastTimeline={
              currentItem?.timeline?.[currentItem?.timeline?.length - 1]
            }
          />
        ),
        raw: currentItem,
      }
    })

  const selectedItemsCount = !selectedItems
    ? 0
    : selectedItems?.reduce((acc, item) => (item?.selected ? acc + 1 : acc), 0)
  const noSelectedItems = selectedItemsCount <= 0

  return (
    <div className='tw-absolute tw-bottom-[calc(-1*var(--padding-content-bottom))] tw-left-[calc(-1*var(--padding-content-x))] tw-right-[calc(-1*var(--padding-content-x))] tw-z-20 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 tw-bg-white tw-px-[--padding-content-x] tw-py-3'>
      <span className='lg:tw-justify-self-center'>
        {noSelectedItems ? (
          'Select items'
        ) : (
          <>
            {selectedItemsCount} item{selectedItemsCount === 1 ? '' : 's'}{' '}
            selected
          </>
        )}
      </span>

      <RequestCheck
        items={itemsToCheck}
        customAction={({ onClick }) => {
          return (
            <Button
              icon={<SealCheck weight='fill' size={20} />}
              onClick={onClick}
            >
              Request background check
            </Button>
          )
        }}
      />
    </div>
  )
}
