import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import { Buildings, IdentificationBadge, Info } from '@phosphor-icons/react'
import contractor from '../../assets/images/contractor2.svg'
import contractorHover from '../../assets/images/contractorhover.svg'
import { BE_CONTRACT_CATEGORY } from '../../helpers/enum'
import { Contractor } from '../../helpers/SvgIcons'

export function useContractorType() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const contractorType = searchParams.get('contractor_type')
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  return { isDeEmployee }
}

const UserTypeSelect = ({
  onUserTypeChanged,
  value,
  disable,
  withEmployee,
  isDeEmployee,
}) => {
  const [selected, setSelected] = useState(value)

  const isSelectedOption = (optionId) => {
    return optionId === selected
  }

  useEffect(() => {
    if (isDeEmployee) {
      onUserTypeChanged(1)
    }
  }, [isDeEmployee])

  const options = isDeEmployee
    ? [
        {
          title: 'An employee',
          description: 'I am hired through RemotePass',
          id: 1,
          icon: (
            <IdentificationBadge
              size={48}
              weight='duotone'
              style={{
                color: 'var(--text-30)',
              }}
            />
          ),
          hoverIcon: (
            <IdentificationBadge
              size={48}
              weight='duotone'
              style={{
                color: 'var(--primary-100)',
              }}
            />
          ),
        },
      ]
    : disable && disable === 'contractor'
      ? [
          {
            title: "I'm a Contractor",
            description: 'I want to work compliantly and get paid',
            id: 1,
            icon: <Contractor />,
            hoverIcon: <Contractor isActive />,
          },
        ]
      : [
          {
            title: "I'm a Company",
            description:
              'I want to hire and pay contractors and employees globally',
            id: 0,
            icon: (
              <Buildings
                size={48}
                weight='duotone'
                style={{
                  color: 'var(--text-30)',
                }}
              />
            ),
            hoverIcon: (
              <Buildings
                size={48}
                weight='duotone'
                style={{
                  color: 'var(--primary-100)',
                }}
              />
            ),
          },
          {
            title: "I'm a Contractor",
            description: 'I want to work compliantly and get paid',
            id: 1,
            icon: <Contractor />,
            hoverIcon: <Contractor isActive />,
          },
          {
            title: "I'm an Employee",
            description: 'I am hired through RemotePass',
            id: 2,
            icon: (
              <IdentificationBadge
                size={48}
                weight='duotone'
                style={{
                  color: 'var(--text-30)',
                }}
              />
            ),
            disabled: !isDeEmployee,
            hoverIcon: (
              <IdentificationBadge
                size={48}
                weight='duotone'
                style={{
                  color: 'var(--primary-100)',
                }}
              />
            ),
          },
        ]

  if (withEmployee) {
    options.push({
      title: 'Employee',
      id: 2,
      description: 'Get paid by my employer',
      icon: contractor,
      hoverIcon: contractorHover,
    })
  }
  return (
    <Container fluid={true} className='p-0 m-0'>
      <Row className='justify-content-between p-0 m-0'>
        <Col className='m-0 p-0'>
          {options.map((option, i) => (
            <div
              key={`option-${option?.title}`}
              className='cursor-pointer'
              onClick={
                disable || option?.disabled
                  ? null
                  : () => {
                      setSelected(option?.id)
                      onUserTypeChanged(option?.id)
                    }
              }
            >
              <Card
                style={{ boxShadow: 'none' }}
                outline
                color={isSelectedOption(option?.id) ? 'primary' : 'secondary'}
                className={
                  isSelectedOption(option?.id)
                    ? 'text-primary border-primary border mb-3 mb-md-4'
                    : 'mb-3 mb-md-4 text-secondary border border-light'
                }
              >
                <CardHeader
                  className={classnames(
                    'p-0 m-0',
                    option?.disabled ? 'surface-surface-30' : 'bg-transparent',
                  )}
                >
                  <div className='d-flex align-items-center align-items-start p-3 p-md-4 gap-8 flex-row flex-md-row'>
                    <div className='pr-2 pb-2'>
                      {option?.customIcon ? (
                        <option.customIcon
                          isActive={isSelectedOption(option?.id)}
                          style={{ width: '100%', height: 'auto' }}
                        />
                      ) : isSelectedOption(option?.id) ? (
                        option.hoverIcon
                      ) : (
                        option.icon
                      )}
                    </div>
                    <div>
                      <div
                        className={classnames(
                          'font-size-16 rp-font-bold',
                          isSelectedOption(option?.id)
                            ? 'text-primary-100'
                            : 'text-text-black',
                        )}
                      >
                        {option.title}
                      </div>
                      <div
                        className={classnames(
                          'font-size-14 rp-font-normal',
                          isSelectedOption(option?.id)
                            ? 'text-primary-100'
                            : 'text-text-60',
                        )}
                      >
                        {option.description}
                      </div>
                    </div>
                    {option?.disabled && (
                      <>
                        <Info
                          id='disabledOptionTooltip'
                          style={{ marginLeft: 'auto' }}
                          size={24}
                        />
                        <UncontrolledTooltip
                          placement='bottom'
                          target='disabledOptionTooltip'
                        >
                          In order to set up an employee account, you will
                          receive an email invitation from the company.
                        </UncontrolledTooltip>
                      </>
                    )}
                  </div>
                </CardHeader>
              </Card>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default UserTypeSelect
