import React from 'react'

import { cn } from 'ui'
import { getInputErrorMessage } from '../../../../components/Forms/get-input-error-message'
import { ControlledUnstyledRadioList } from '../../../../components/controlled-radio-list/unstyled'
import InputFeedback from '../../../../components/ui/input-feedback'

export function ConfirmFormField({
  control,
  name,
  error,
  className,
  icon,
  title,
  description,
  disabled,
  defaultValue,
  fieldOptions,
  itemClassName,
  innerClassName,
  transform,
}) {
  return (
    <BlockFormInput
      icon={icon}
      title={title}
      description={description}
      className={className}
      innerClassName={innerClassName}
      error={error}
      inputElement={
        <ControlledUnstyledRadioList
          control={control}
          disabled={disabled}
          name={name}
          defaultValue={defaultValue}
          transform={transform}
          options={fieldOptions}
          optionsClassName='tw-flex tw-gap-3 tw-h-full tw-items-start max-md:tw-flex-wrap'
          optionClassName={({ checked }) => {
            return cn(
              'tw-mb-0 tw-px-6 tw-py-4 tw-border tw-rounded tw-border-surface-30 focus-within:tw-outline-2 focus-within:tw-outline  focus-within:tw-outline-offset-2 focus-within:tw-outline-surface-40',
              {
                'tw-border-primary-100 tw-text-primary-100 tw-font-bold tw-ring-1 tw-ring-primary-100':
                  checked,
                'tw-cursor-pointer tw-text-text-60': !checked,
                'tw-cursor-default tw-text-text-40': disabled,
              },
              itemClassName,
            )
          }}
          optionInputClassName='tw-appearance-none tw-absolute tw-opacity-0 tw-size-0'
        />
      }
    />
  )
}

export function BlockFormInput({
  className,
  innerClassName,
  icon,
  title,
  description,
  error,
  inputElement,
}) {
  return (
    <div className={className}>
      <div
        className={cn(
          'tw-flex tw-flex-wrap tw-justify-between tw-gap-3 tw-rounded tw-border tw-border-surface-30 tw-p-4',
          { '!tw-border-red': !!error },
          innerClassName,
        )}
      >
        {(icon || title || description) && (
          <div className='tw-flex tw-items-start tw-gap-4'>
            {!icon ? null : <div className='tw-size-8 tw-shrink-0'>{icon}</div>}

            <div>
              <h5 className='tw-text-sm tw-font-bold'>{title}</h5>

              {!description ? null : (
                <p className='tw-mb-0 tw-text-sm tw-text-text-60'>
                  {description}
                </p>
              )}
            </div>
          </div>
        )}

        {inputElement}
      </div>

      {!error ? null : (
        <InputFeedback className='tw-mt-2'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}
