import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import * as serviceWorker from './serviceWorker'
import Maintenance from './pages/maintenance'
import WrapperApp from './wrapper-app'

const isInMaintenance =
  JSON.parse(process.env.REACT_APP_MAINTENANCE ?? 'false') &&
  !document.cookie.includes('test-migration')

const App = () => (isInMaintenance ? <Maintenance /> : <WrapperApp />)

const root = createRoot(document.getElementById('app') as HTMLElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
serviceWorker.unregister()
