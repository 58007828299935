import React from 'react'
import { Nav } from 'reactstrap'
import { cn } from 'ui'

export function IntegrationNav({ className, ...props }) {
  return (
    <Nav
      {...props}
      className={cn(
        'tw-space-x-6 md:tw-space-x-10 [&_.nav-link]:!tw-px-0 [&_.nav-link]:!tw-py-4 [&_.nav-link]:after:!tw-h-[3px]',
        className,
      )}
    />
  )
}
