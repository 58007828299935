import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { Stepper } from 'ui'
import Head from '../../../../components/head'
import ModalHeader from '../../../../components/ModalHeader'
import StepContainer from '../../../../components/Steps/StepContainer'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import { CreateVendorContextProvider } from './create-vendor-context'
import { vendorDetailFields } from './create-vendor-step-1'
import { useBillModuleActive } from '../../bill-permission-hook'

const CreateVendorStep1 = lazy(() => import('./create-vendor-step-1'))
const CreateVendorStep2 = lazy(() => import('./create-vendor-step-2'))

const BASE_PATH = '/bills/vendors/create'
export function getVendorFormStepId(step) {
  return `create-vendor-form-step-${step.key}`
}
function getPrevStepLink(step, steps, basePath) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `${basePath ?? BASE_PATH}/${prevStepKey}`
}
function getNextStepLink(step, steps, basePath) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `${basePath ?? BASE_PATH}/${nextStepKey}`
}

// Custom hook to make sure that we have data from the first step
// Otherwise, redirect back to the first step
export function useCheckFirstStepData(formData, history, basePath) {
  useEffect(() => {
    const _basePath = basePath ?? BASE_PATH
    const currentPath = history.location.pathname

    // If we don't have the data from the first step, redirect back to the first step
    if (!formData?.[vendorDetailFields.name] && _basePath !== currentPath) {
      history.push(_basePath)
    }
  }, [basePath, formData, history])
}

export default function CreateVendor({ basePath }) {
  useBillModuleActive()
  return (
    <CreateVendorContextProvider>
      <CreateVendorFlow basePath={basePath} />
    </CreateVendorContextProvider>
  )
}

function CreateVendorFlow({ basePath }) {
  const history = useHistory()
  const params = useParams()
  const { stepKey } = params

  const stepperSteps = createVendorSteps.map((step) => step.name)
  const stepKeys = createVendorSteps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')
  const activeStep = createVendorSteps[activeIndex]

  const isLastStep = activeIndex === createVendorSteps.length - 1

  const pageTitleWithStepName = [activeStep?.name, 'Create vendor']
    .filter(Boolean)
    .join(' - ')

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink(step, createVendorSteps, basePath)
    history.push(prevStepLink ?? basePath ?? BASE_PATH, {
      backRoute: history.location.state?.backRoute,
    })
  }
  function handleNext(step) {
    const nextStepLink = getNextStepLink(step, createVendorSteps, basePath)
    if (nextStepLink) {
      history.push(nextStepLink, {
        backRoute: history.location.state?.backRoute,
      })
    }
  }

  const [loading, setLoading] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  const disableNext = loading || disabledNext

  const isBankDetails = stepKey === 'bank-details'

  const skipBankDetailsContent = (
    <div className='!tw-ml-auto'>
      <Button
        color='link'
        className='!tw-text-text-90'
        tag={Link}
        to='/bills/vendors'
      >
        Skip
      </Button>
    </div>
  )

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/bills/vendors')
  }

  return (
    <Container fluid className='px-0'>
      <Head title={pageTitleWithStepName} />

      <ModalHeader action={handleReturn}>
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4 tw-pb-11'>
        <Switch>
          {createVendorSteps.map((step, index) => {
            const { key, Component, name } = step
            return (
              <Route path={`${basePath ?? BASE_PATH}/${key}`} key={index} exact>
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={createVendorSteps.length}
                  nextType='submit'
                  nextFormId={getVendorFormStepId(step)}
                  nextText={isLastStep ? 'Save' : 'Continue'}
                  footerContent={isBankDetails ? skipBankDetailsContent : null}
                  noBack={index === 0}
                  disableNext={disableNext}
                  loading={loading}
                  onBack={() => handlePrev(step)}
                  cardStyles={{ maxWidth: 800 }}
                >
                  <Suspense fallback={<Loader minHeight='100%' />}>
                    <Component
                      step={step}
                      goToNextStep={() => handleNext(step)}
                      basePath={basePath}
                      setLoading={setLoading}
                      setDisabledNext={setDisabledNext}
                    />
                  </Suspense>
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
    </Container>
  )
}

const createVendorSteps = [
  {
    name: 'Vendor details',
    key: '',
    Component: CreateVendorStep1,
    next: 'bank-details',
  },
  {
    name: 'Bank details',
    key: 'bank-details',
    Component: CreateVendorStep2,
  },
]
