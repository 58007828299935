import React, { useState } from 'react'
import { Container, Row } from 'reactstrap'

import { DownloadSimple, Info } from '@phosphor-icons/react'
import BorderedNavTabs from '../../../../components/ui/nav-tabs/bordered'
import { useFetch } from '../../../../helpers/hooks'
import {
  adminDownloadEORQuote,
  downloadEORQuote,
} from '../../../../services/api'
import { downloadFile } from '../../utils/helpers'
import EmployerTaxView from './EmployerTaxView'

export function numberWithCommas(number) {
  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const DownloadPDFButton = ({ downloadQuote }) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        downloadQuote.startFetch()
      }}
      className='d-flex bg-white border-0 justify-content-center align-items-center font-weight-medium text-primary cursor-pointer font-size-14'
    >
      <DownloadSimple className='mb-0.5 mr-1' size={18} /> Download PDF
    </button>
  )
}

const QuoteView = ({ quote, customerCurrencyCode }) => {
  const [period, setPeriod] = useState('monthly')

  const isAdmin = location.pathname.startsWith('/admin')

  const downloadQuote = useFetch({
    action: isAdmin ? adminDownloadEORQuote : downloadEORQuote,
    withAdminAccess: isAdmin,
    body: {
      country_code: quote?.input?.code,
      annual_salary: quote?.input?.salary,
      currency: customerCurrencyCode,
    },
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      downloadFile(url, 'Quotation.pdf')
    },
  })
  return (
    <>
      <Container fluid className='mx-auto mx-md-0'>
        <Row style={{ marginBottom: '24px' }}>
          <BorderedNavTabs
            options={[
              { label: 'Monthly', value: 'monthly' },
              { label: 'Yearly', value: 'yearly' },
            ]}
            activeOption={period}
            trail={<DownloadPDFButton downloadQuote={downloadQuote} />}
            onClickOption={(option) => {
              setPeriod(option.value)
            }}
          />
        </Row>
        <EmployerTaxView
          customerCurrencyCode={customerCurrencyCode}
          quote={quote}
          isMonthly={period === 'monthly'}
        />

        <Row className='custom-alert bg-blue-20 border rounded p-4 font-size-14 font-weight-light flex-nowrap'>
          <span className='text-blue-110 pr-3 mt-0.5'>
            <Info size={20} />
          </span>
          <span className='alert-text'>
            Employment cost simulation for {quote?.input?.name} is an estimate
            based on the country’s local taxation and compliance, excluding
            RemotePass management fee & taxes. Contributions may vary based on
            employee’s personal information.
          </span>
        </Row>
      </Container>
    </>
  )
}

export default QuoteView
