import { UPDATE_WITHDRAW_AMOUNT } from './actionTypes'

const initialState = {
  withdrawAmount: null,
}

const Withdraw = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WITHDRAW_AMOUNT: {
      state = {
        ...state,
        withdrawAmount: action.payload,
      }
      break
    }
    default: {
      break
    }
  }
  return state
}

export default Withdraw
