import cx from 'classnames'
import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

import './nav-tabs.scss'

export default function NavTabs({
  className,
  options,
  activeOption,
  onClickOption,
  linkClassName,
  noBorder = true,
}) {
  return (
    <Nav
      tabs
      className={cx('rp-nav-tabs', className, noBorder ? '!tw-border-b-0' : '')}
    >
      {options?.map((option) => (
        <NavItem key={option?.value}>
          <NavLink
            className={cx(
              'tw-bg-transparent tw-px-2 tw-py-1 tw-text-sm',
              {
                'active cursor-default': activeOption === option?.value,
                'cursor-default text-gray-400': option.disabled,
                'text-gray-600':
                  activeOption !== option?.value && !option.disabled,
              },
              linkClassName,
            )}
            onClick={() => onClickOption(option)}
            tag='button'
            disabled={option.disabled}
            type='button'
          >
            {option?.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}
