import { useInfiniteQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocalStorage } from 'usehooks-ts'

import { Avatar, cn } from 'ui'
import Head from '../../../components/head'
import PageHeading from '../../../components/ui/page-heading'
import { getFlagUrl } from '../../../helpers/config'
import { useFetch } from '../../../helpers/hooks'
import { useUrlStateV2 } from '../../../helpers/hooks/use-url-state'
import {
  getBackgroundCheckContracts,
  getCountryFeatures,
} from '../../../services/api-compliance'
import { getFullName } from '../../../utils/get-full-name'
import { ContractBadge } from '../../Contract/ContractDetail/contract-timeline'
import { ReviewLayout } from '../../review-center/review-list-layout'
import { OnBoardingModal } from '../components/on-boarding-modal'
import { BgCheckBanner } from './bg-check-banner'
import { ReviewDocumentsColumns } from './review-documents-columns'

const bgCheckBannerVisibleKey = 'is-menu-collapsed'

export function ClientDocuments() {
  const [showBgCheckModal, setShowBgCheckModal] = useState(false)

  const { value: urlStateValue, updater: setUrlState } = useUrlStateV2({
    selectedItem: undefined,
    search_key: '',
    contract_type: undefined,
    page: 1,
    selecting: undefined,
  })

  const {
    selectedItem,
    search_key: searchKey,
    contract_type: contractType,
  } = urlStateValue

  const [bannerIsVisible, setBannerIsVisible] = useLocalStorage(
    bgCheckBannerVisibleKey,
    false,
  )

  const user = useSelector((state) => state.Account?.user)

  const {
    fetchNextPage,
    isFetchingNextPage,
    data,
    refetch: refetchReviewList,
    status,
  } = useInfiniteQuery({
    queryKey: [user?.token, 'client-docs-list', searchKey, contractType],
    queryFn: ({ pageParam, signal }) => {
      const userToken = user?.token

      return getBackgroundCheckContracts(
        userToken,
        { page: pageParam, search_key: searchKey, contract_type: contractType },
        { signal },
      )
    },
    select: (data) => {
      return {
        pages: data?.pages.map((page) => page.data),
        pageParams: data?.pageParams,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.paginator.current_page
      const hasMorePages = lastPage.data.paginator.has_more_pages

      const itemToSelect = lastPage.data.data[0]?.id

      if (currentPage === 1 && !selectedItem && !!itemToSelect) {
        setUrlState({ selectedItem: itemToSelect })
      }

      const nextPage = hasMorePages ? currentPage + 1 : undefined

      return nextPage
    },
  })

  const { data: countryFeaturesData } = useFetch({
    action: getCountryFeatures,
    autoFetch: true,
    body: { limit: 500 },
  })

  const supportedCountries = countryFeaturesData
    ?.filter((country) => country?.background_check_enabled)
    .map((country) => country?.country?.iso3)

  const reviewFlatItems = data?.pages?.map((item) => item.data)?.flat()

  const reviewItems = reviewFlatItems?.map((item) => {
    return {
      id: item.id,
      disabled: !supportedCountries?.includes(item.contractor?.country?.iso3),
      left: {
        top: getFullName(item.contractor),
        bottom: item.type,
      },
      right: {
        top: (
          <ContractBadge
            amended={item?.amended}
            status={item?.status}
            lastTimeline={item?.timeline?.[item?.timeline?.length - 1]}
          />
        ),
        bottom: '',
      },
      icon: (
        <Avatar
          size='xl'
          photo={item.contractor.photo}
          name={getFullName(item.contractor)}
          flag={getFlagUrl(item?.contractor?.flag)}
          flagIsAbsolute
        />
      ),
      raw: item,
    }
  })

  return (
    <ReviewLayout.Page
      className={cn(
        'page-content',
        bannerIsVisible
          ? '[--top-section-height:112px] md:[--top-section-height:112px] lg:[--top-section-height:112px]'
          : '[--top-section-height:0px] md:[--top-section-height:0px] lg:[--top-section-height:0px]',
      )}
    >
      <Head title='Documents' />

      <PageHeading.Title>Documents</PageHeading.Title>

      {!bannerIsVisible ? null : (
        <BgCheckBanner onDismiss={() => setBannerIsVisible(false)} />
      )}

      <ReviewDocumentsColumns
        isLoading={status === 'pending'}
        reviewItems={reviewItems}
        refreshReviewList={() => {
          refetchReviewList()
        }}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        urlState={urlStateValue}
        setUrlState={setUrlState}
      />

      <OnBoardingModal
        open={showBgCheckModal}
        onClose={() => setShowBgCheckModal(false)}
      />
    </ReviewLayout.Page>
  )
}
