import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { updateCompanyAdmin } from '../../../../services/api'
import {
  FeeValueDisplay,
  InfoList,
  getCompanyInfo,
} from './service-fee-management'

export function ConsultingFeeManagement({ company, updateCompaniesList }) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => !open)
  }

  const { startFetch: updateData, isLoading: isUpdating } = useFetch({
    action: updateCompanyAdmin,
    withAdminAccess: true,
    onComplete: () => {
      updateCompaniesList?.()
    },
  })

  function onSubmit(values) {
    const body = {
      company_id: values.company_id,
      consulting_fee: Number(values.consulting_fee),
      consulting_fee_min: Number(values.consulting_fee_min),
      consulting_fee_max: Number(values.consulting_fee_max),
    }

    updateData(body)
  }

  return (
    <>
      <FeeValueDisplay
        isPercentage
        value={company?.consulting_fee}
        onEdit={toggle}
        max={company?.consulting_fee_max}
        min={company?.consulting_fee_min}
      />

      {!isOpen ? null : (
        <ConsultingFeeModal
          company={company}
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={onSubmit}
          loading={isUpdating}
        />
      )}
    </>
  )
}

const consultingFeeFormId = 'consulting-fee-form'
function ConsultingFeeModal({ company, onSubmit, isOpen, toggle, loading }) {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      consulting_fee: company.consulting_fee,
      consulting_fee_min: company.consulting_fee_min,
      consulting_fee_max: company.consulting_fee_max,
    },
    resolver: yupResolver(
      yup.object().shape({
        consulting_fee: yup.number().typeError('Invalid value').required(),
        consulting_fee_min: yup
          .number()
          .typeError('Invalid minimum value')
          .required('The minimum value is required'),
        consulting_fee_max: yup
          .number()
          .typeError('Invalid maximum value')
          .required('The maximum value is required'),
      }),
    ),
  })

  const companyInfo = getCompanyInfo(company)

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Service Fee</ModalHeader>
      <ModalBody>
        <div className='bg-surface-10 border mb-3 px-2.5 py-2 rounded'>
          <InfoList items={companyInfo} />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='d-flex flex-column gap-14'
          id={consultingFeeFormId}
        >
          <input
            type='hidden'
            {...register('company_id', { value: company?.id })}
          />

          <ControlledCurrencyInput
            control={control}
            id='consulting_fee'
            name='consulting_fee'
            label='Consulting Fee'
            prefix='% '
          />

          <ControlledCurrencyInput
            control={control}
            id='consulting_fee_min'
            name='consulting_fee_min'
            label='Consulting Fee Min'
            prefix='$ '
          />

          <ControlledCurrencyInput
            control={control}
            id='consulting_fee_max'
            name='consulting_fee_max'
            label='Consulting Fee Max'
            prefix='$ '
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={consultingFeeFormId}
          disabled={loading}
          loading={loading}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}
