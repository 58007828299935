import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Check } from '@phosphor-icons/react/dist/ssr';
import { Fragment } from 'react';
import { cn } from '../lib/utils/cn';
export function Stepper({ steps = [], activeStep, className, }) {
    return (_jsx("div", { className: cn('tw-hidden tw-items-center tw-gap-2 md:tw-flex', className), children: steps.map((step, index) => {
            const isLast = index === steps.length - 1;
            const isDone = index < activeStep;
            const isCurrent = index === activeStep;
            const activeState = isDone || isCurrent;
            return (_jsxs(Fragment, { children: [_jsxs("div", { className: cn('tw-flex tw-items-center tw-gap-2', isDone && 'tw-opacity-50'), children: [_jsx("div", { className: cn('tw-flex tw-h-5 tw-w-5 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-text-[10px] tw-tabular-nums tw-leading-none tw-transition-colors', activeState
                                    ? 'tw-bg-primary-100 tw-text-white'
                                    : 'tw-bg-surface-30 tw-text-text-60'), children: isDone ? _jsx(Check, {}) : index + 1 }), _jsx("div", { className: cn('tw-text-xs tw-font-semibold tw-transition-colors', activeState ? 'tw-text-primary-100' : 'tw-text-text-60'), children: step })] }), isLast ? null : (_jsx("div", { className: cn('tw-h-px tw-flex-grow tw-transition-colors', activeState ? 'tw-bg-primary-100' : 'tw-bg-secondary-30', isDone && 'tw-opacity-50') }))] }, index));
        }) }));
}
