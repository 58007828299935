import cx from 'classnames'
import React from 'react'

import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { FlagIcon } from '../../../components/ui/flag-icon'

export default function BalanceWithFlag({ balance }) {
  const { currency, amount } = balance ?? {}

  const formatter = getCurrencyFormatter(currency)

  const isUnavailable = Number.isNaN(amount) || !amount

  return (
    <div className='d-flex justify-content-between align-items-center px-3 rp-font-medium border rounded'>
      <div
        className={cx('font-size-24 text-primary', { 'py-2': isUnavailable })}
      >
        {isUnavailable ? 'Unavailable' : formatter.format(amount)}
      </div>
      {isUnavailable ? null : (
        <div
          className='d-flex align-items-center py-2 border-left pl-3'
          style={{ gap: '0.5rem' }}
        >
          <FlagIcon code={currency} className='border' size='24px' />
          <div className='font-size-24 text-secondary'>{currency}</div>
        </div>
      )}
    </div>
  )
}
