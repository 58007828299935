import React from 'react'
import { Col, Row } from 'reactstrap'

import coinbaseSelected from '../../../assets/images/coinbase_icon.svg'
import coinbase from '../../../assets/images/coinbase_icon_inactive.svg'
import { useFetch, useResize } from '../../../helpers/hooks'
import { deleteCoinbaseAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'
import { PaymentMethodCard } from './PaypalMethodCard'

const PaypalMethodCard = ({ account, onUpdate, withRemove, checked }) => {
  const deleteCard = useFetch({
    action: deleteCoinbaseAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_method: 'Paypal',
        is_default: false,
      })
    },
  })
  const isMobile = useResize()

  return (
    <PaymentMethodCard
      className={checked ? 'border-primary' : ''}
      withRemove={withRemove}
      item={account}
      deleteCard={deleteCard}
    >
      <Row
        className={`${isMobile ? 'p-md-3 p-3' : 'p-md-0 p-0'} py-md-3 py-3 m-0`}
      >
        <Col xs={12} md={2} className='iconi p-0 m-0'>
          <div
            style={{ height: '100%', width: '100%' }}
            className={`d-flex align-items-center ${
              isMobile ? 'justify-content-start' : 'justify-content-center'
            }`}
          >
            <img
              className='mb-3 mb-md-0'
              src={checked ? coinbaseSelected : coinbase}
              alt=''
              style={{ width: '1.8rem' }}
            />
          </div>
        </Col>

        <Col xs={12} md={10} className='p-0 m-0'>
          <h5
            className={`${
              checked ? 'text-primary' : 'text-dark'
            } mb-2 font-size-16`}
          >
            Coinbase
          </h5>
          <div
            className={`${
              checked ? 'text-primary' : 'text-secondary'
            } font-size-14 mb-2`}
          >
            {account.email} {account.currency ? ` - ${account.currency}` : ''}{' '}
          </div>
        </Col>
      </Row>
    </PaymentMethodCard>
  )
}

export default PaypalMethodCard
