import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import * as yup from 'yup'

import toastr from 'toastr'
import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import { FlagIcon } from '../../../../components/ui/flag-icon'
import { useFetch } from '../../../../helpers/hooks'
import { requestAWalletAdjustment } from '../../../../services/api'
import {
  getOptionFromList,
  mapCurrencyToOption,
} from '../../../../utils/map-to-option'
import { ControlledContractorSelector } from '../Transactions/ExportReportModal'

const adjustmentMethods = [
  { value: 12, label: 'Balance refund' },
  { value: 13, label: 'Balance deduction' },
]

const addAdjustmentFormId = 'addAdjustmentFormId'

const schema = yup.object().shape({
  amount: yup
    .number()
    .positive('The amount should be a positive number')
    .required('Amount is required'),
  currency_id: yup.string().required('Currency is required'),
  method_id: yup.string().required('Adjustment method is required'),
  user_id: yup
    .object()
    .shape({
      value: yup.string().required('Contractor is required'),
      label: yup.string().required('Contractor is required'),
    })
    .required(),
  note: yup.string().required('Note is required'),
})
const columns = [
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ cellData }) => cellData?.name,
  },
  {
    Header: 'Amount',
    accessor: 'balance',
  },
]
function handleErrorRequest(resp) {
  toastr.error(resp)
}
export default function BalanceAdjustmentModal({ refreshData }) {
  const [isOpen, setIsOpen] = useState(false)

  const { startFetch: requestAdjustment, isLoading: adjusting } = useFetch({
    action: requestAWalletAdjustment,
    withAdminAccess: true,
    onComplete: () => {
      refreshData()
      toggle()
    },
    onError: handleErrorRequest,
  })

  const currencies = useSelector(
    (state) => state?.Layout?.staticData?.currencies,
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  function toggle() {
    setIsOpen((open) => {
      if (open) {
        reset()
      }

      return !open
    })
  }

  const selectedCurrency = getOptionFromList(
    currencies,
    watch('currency_id'),
    'id',
  )

  function onSubmit(values) {
    const body = Object.entries(values).reduce((acc, [key, value]) => {
      if (key === 'user_id') {
        acc[key] = value.value
      } else {
        acc[key] = value
      }

      return acc
    }, {})

    requestAdjustment(body)
  }
  const selectedContractor = watch('user_id')

  return (
    <>
      <Button onClick={toggle}>Add adjustment</Button>

      <Modal isOpen={isOpen} toggle={toggle} unmountOnClose>
        <ModalHeader toggle={toggle}>Add adjustment</ModalHeader>

        <ModalBody>
          <Form id={addAdjustmentFormId} onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>Contractor</Label>

              <ControlledContractorSelector
                control={control}
                name='user_id'
                error={errors?.user_id?.value?.message}
              />
              {selectedContractor &&
              selectedContractor?.balances?.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={selectedContractor?.balances}
                  responsive
                />
              ) : null}
              <InvalidFormFeedback error={errors?.user_id?.value?.message} />
            </FormGroup>

            <FormGroup>
              <ControlledSelect
                control={control}
                label='Method'
                name='method_id'
                error={errors?.method_id?.message}
                options={adjustmentMethods}
              />
            </FormGroup>

            <FormGroup>
              <ControlledSelect
                control={control}
                label='Currency'
                name='currency_id'
                error={errors?.currency_id?.message}
                options={currencies?.map((c) => mapCurrencyToOption(c, 'id'))}
              />
            </FormGroup>

            <FormGroup>
              <Label for='amount'>Amount</Label>
              <InputGroup>
                {!selectedCurrency?.code ? null : (
                  <InputGroupText
                    className='border-right-0 d-flex gap-4'
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <FlagIcon code={selectedCurrency.code} />{' '}
                    {selectedCurrency.code}
                  </InputGroupText>
                )}

                <ControlledCurrencyInput
                  control={control}
                  name='amount'
                  id='amount'
                  labelFor='amount'
                  // currency input props
                  autoComplete='off'
                />
              </InputGroup>

              <InvalidFormFeedback error={errors?.amount?.message} />
            </FormGroup>

            <FormGroup>
              <ControlledInput
                type='textarea'
                control={control}
                label='Note'
                name='note'
                error={errors?.note?.message}
                id='note'
                labelFor='note'
              />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color='light' outline onClick={toggle} disabled={adjusting}>
            Cancel
          </Button>

          <Button
            formId={addAdjustmentFormId}
            type='submit'
            disabled={adjusting}
            loading={adjusting}
          >
            Add adjustment
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function InvalidFormFeedback({ error }) {
  if (!error) return null

  return <small className='form-text text-danger'>{error}</small>
}
