import { getItem, setItem } from '../../utils/ls'

const TD_GUID_LS_KEY = 'td-guid'

/** TD stands for trusted device */
export default function useTdGuid() {
  const guid = getItem(TD_GUID_LS_KEY)

  function setGuid(newGuid) {
    setItem(TD_GUID_LS_KEY, newGuid)
  }

  return { guid, setGuid }
}
