import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { cardTransactions } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import LatestTransactions from './latest-transactions'
import { SectionHeading } from './section-heading'
import TransactionDetails from './transactions-details'

export default function CardsLatestTransactions() {
  const [trxDetailsOpen, setTrxDetailsOpen] = useState(false)
  const [selectedTrx, setSelectedTrx] = useState([])

  const { data: transactions, isLoading } = useFetch({
    action: cardTransactions,
    body: { page: '0', limit: '5' },
    autoFetch: true,
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })

  return (
    <>
      <Card className='mb-0 p-4 p-md-0 rp-shadow-2'>
        {transactions?.items?.length <= 0 || isLoading ? null : (
          <div
            className='p-md-3 pb-3 d-flex justify-content-between align-items-baseline'
            style={{ gap: '0.75rem' }}
          >
            <SectionHeading className='mb-0'>
              Latest Transactions
            </SectionHeading>

            <Button
              tag={Link}
              color='light'
              outline
              className='font-size-14'
              to='/cards/transactions'
            >
              See All
            </Button>
          </div>
        )}

        <LatestTransactions
          data={transactions?.items}
          isLoading={isLoading}
          setSelectedTrx={setSelectedTrx}
          setShow={setTrxDetailsOpen}
          withArrow
        />
      </Card>

      <TransactionDetails
        hide={() => setTrxDetailsOpen(false)}
        show={trxDetailsOpen}
        setShow={setTrxDetailsOpen}
        selectedTrx={selectedTrx}
      />
    </>
  )
}
