import React from 'react'
import { Card, Container } from 'reactstrap'

import { useFetch } from '../../../../helpers/hooks'
import { generateAdminFulltimeQuotation } from '../../../../services/api'
import InvoiceGenerationForm from './InvoiceGenerationForm'

const GenerateInvoice = () => {
  const generate = useFetch({
    action: generateAdminFulltimeQuotation,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, 'Quotation.pdf')
    },
  })
  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  return (
    <div className='page-content'>
      <h1 style={{ marginBottom: '2rem' }}>Invoice generator</h1>
      <Card className='mt-3'>
        <Container fluid className='p-0 m-0'>
          <InvoiceGenerationForm forGenerator onSubmit={generate.startFetch} />
        </Container>
      </Card>
    </div>
  )
}

export default GenerateInvoice
