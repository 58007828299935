import {
  ArrowLeft,
  ArrowsCounterClockwise,
  ArrowSquareOut,
  CaretRight,
  CheckCircle,
  FlowArrow,
  Plus,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader, TabPane } from 'reactstrap'
import toastr from 'toastr'
import { useLocalStorage } from 'usehooks-ts'

import { cn } from 'ui'
import netSuiteLogo from '../../../assets/images/brands/net-suite-2.svg'
import quickBooksLogo from '../../../assets/images/brands/quick-books.png'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import { NewBadge } from '../../../components/ui/badge-new'
import { Box, BoxIcon } from '../../../components/ui/box'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { Result } from '../../../components/ui/result'
import TabContent from '../../../components/ui/tabs'
import { useFetch } from '../../../helpers/hooks'
import {
  createApprovalFlow,
  editNetSuite,
  getApprovalFlows,
  getIntegrationList,
  getQBIntegrationData,
  getUsers,
  setupQuickbooks,
  updateNetSuite,
} from '../../../services/api'
import { assignBillApprovalFlow } from '../../../services/api-bill-payments'
import { mapListToOption } from '../../../utils/map-to-option'
import ApprovalFlowForm, {
  getUsersList,
} from '../../CompanySetting/approvals/approval-flow-form'
import { BillApprovalFlowSelector } from '../../CompanySetting/approvals/bill-approval-action-button'
import billOnboardingIllustration from './bill-onboarding-illustration.svg'

export const lsBillOnboardingShown = 'ls-bill-onboarding-shown'
export function useSetBillOnboarding() {
  const [value, setBillLsValue] = useLocalStorage(lsBillOnboardingShown, false)

  const companyId = useSelector(
    (state) => state.userProfile?.userProfile?.company?.id,
  )

  function handleSetBillLsValue() {
    let newValue = null
    if (Array.isArray(value)) {
      newValue = [...new Set([...value, companyId])]
    } else {
      newValue = [companyId]
    }

    setBillLsValue(newValue)
  }

  return { setBillLsValue: handleSetBillLsValue }
}

const STEP_KEY = {
  welcome: 0,
  assign_flow: 1,
  integration: 2,
  add_flow: 3,
}

export function BillOnboardingModal({
  billApprovalFlow,
  onApprovalFlowUpdated,
  isOpen,
  toggle,
}) {
  const [step, setStep] = useState(STEP_KEY.welcome)

  const [selectedFlow, setSelectedFlow] = useState(
    !billApprovalFlow ? null : mapListToOption(billApprovalFlow),
  )

  const { setBillLsValue } = useSetBillOnboarding()

  function handleNext() {
    if (step === STEP_KEY.assign_flow) {
      if (selectedFlow?.value) {
        if (selectedFlow.value !== billApprovalFlow?.id) {
          assignBillFlow({ approval_flow_id: selectedFlow.value })
        } else {
          setStep(STEP_KEY.integration)
        }
      }
    } else if (step === STEP_KEY.integration) {
      onApprovalFlowUpdated?.()
      toggle()
      setBillLsValue()
      toastr.success(
        'Start creating and managing bills seamlessly.',
        'You’re all set!',
      )
    } else {
      setStep(STEP_KEY.assign_flow)
    }
  }

  const { isLoading: assigningFlow, startFetch: assignBillFlow } = useFetch({
    action: assignBillApprovalFlow,
    onComplete: () => {
      toastr.success(`Successfully assigned "${selectedFlow.label}" for bills.`)
      setStep(STEP_KEY.integration)
      onApprovalFlowUpdated?.(null, false)
    },
    onError: (error) => toastr.error(error),
  })

  const { data: approvalFlows, isLoading: loadingApprovalFlows } = useFetch(
    {
      action: getApprovalFlows,
      autoFetch: step === STEP_KEY.assign_flow,
    },
    [step],
  )

  const { startFetch: createFlow, isLoading: isCreatingFlow } = useFetch({
    action: createApprovalFlow,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Error creating approval flow')
      } else {
        toastr.success('Approval flow created successfully')

        // go back to assign flow step
        setStep(STEP_KEY.assign_flow)
        // Select the created flow
        setSelectedFlow(mapListToOption(data))
      }
    },
    onError: toastr.error,
  })

  const actionsLoading = assigningFlow || loadingApprovalFlows || isCreatingFlow

  let ctaProps = {
    type: 'button',
    onClick: handleNext,
    loading: actionsLoading,
    disabled:
      actionsLoading || (step === STEP_KEY.assign_flow && !selectedFlow.value),
    text: step === STEP_KEY.welcome ? 'Get started' : 'Continue',
  }

  if (step === STEP_KEY.add_flow) {
    ctaProps = {
      ...ctaProps,
      formId: approvalFlowFormId,
      text: 'Save approval flow',
      onClick: null,
      type: 'submit',
    }
  }

  return (
    <Modal isOpen={isOpen} centered size='lg' toggle={toggle}>
      <ModalBody className='tw-h-[616px] tw-overflow-auto !tw-p-0'>
        <TabContent activeTab={step} className='tw-h-full'>
          <TabPane tabId={STEP_KEY.welcome}>
            <div className='tw-relative'>
              <ModalCloseButton
                toggle={toggle}
                className='tw-absolute tw-right-4 tw-top-4'
              />
              <img src={billOnboardingIllustration} aria-hidden />
            </div>
            <div className='tw-p-6'>
              <NewBadge className='tw-mb-2' />
              <h2 className='tw-mb-2 tw-text-3xl tw-font-bold'>
                Streamline Your Bill Management
              </h2>
              <p className='tw-mb-2 tw-text-sm tw-font-medium tw-text-text-80'>
                Our new bills feature helps you stay organized and efficient by
                enabling you to create, manage, and pay bills directly within
                RemotePass. Simplify your payment processes, gain better
                financial oversight, and save valuable time.
              </p>
              <Button
                color='link'
                size='sm'
                className='!tw-px-0 !tw-text-secondary-100'
                iconRight={<ArrowSquareOut size={16} />}
              >
                Learn more
              </Button>
            </div>
          </TabPane>
          <TabPane tabId={STEP_KEY.assign_flow}>
            <Step2IntegrationApprovalFlow
              approvalFlows={approvalFlows}
              goToAddFlow={() => setStep(STEP_KEY.add_flow)}
              selectedFlow={selectedFlow}
              setSelectedFlow={setSelectedFlow}
              toggle={toggle}
            />
          </TabPane>
          <TabPane tabId={STEP_KEY.add_flow}>
            <Step4AddFlow
              toggle={toggle}
              goToAssignFlow={() => setStep(STEP_KEY.assign_flow)}
              onAddFlow={createFlow}
            />
          </TabPane>
          <TabPane tabId={STEP_KEY.integration} className='tw-p-6'>
            <Step3IntegrationSync />
          </TabPane>
        </TabContent>
      </ModalBody>

      <ModalFooter className='tw-grid-cols-3 sm:!tw-grid [&>*:nth-child(3n)]:!tw-ml-auto [&>*:nth-child(3n+1)]:!tw-mr-auto [&>*:nth-child(3n+2)]:!tw-mx-auto'>
        {[STEP_KEY.welcome, STEP_KEY.add_flow].includes(step) ? (
          <div />
        ) : (
          <Button
            color='light'
            outline
            onClick={() => {
              setStep((step) => {
                const backStepMap = {
                  [STEP_KEY.assign_flow]: STEP_KEY.welcome,
                  [STEP_KEY.integration]: STEP_KEY.assign_flow,
                  [STEP_KEY.add_flow]: STEP_KEY.assign_flow,
                }
                const backStep = backStepMap[step] ?? step - 1
                return backStep < 0 ? STEP_KEY.welcome : backStep
              })
            }}
            disabled={assigningFlow}
          >
            Back
          </Button>
        )}

        {step === STEP_KEY.add_flow ? (
          <div />
        ) : (
          <div className='tw-flex tw-gap-1'>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className={cn(
                  'tw-size-2 tw-rounded-full tw-border tw-transition-colors',
                  index === step
                    ? 'tw-border-secondary-100 tw-bg-secondary-100'
                    : 'tw-border-secondary-100/5 tw-bg-secondary-30',
                )}
              />
            ))}
          </div>
        )}

        <Button {...ctaProps} />
      </ModalFooter>
    </Modal>
  )
}

function Step2IntegrationApprovalFlow({
  approvalFlows,
  selectedFlow,
  setSelectedFlow,
  toggle,
  goToAddFlow,
}) {
  return (
    <>
      <OnboardingHeader
        toggle={toggle}
        IconComponent={FlowArrow}
        title='Set the default approval flow for your bill payments'
        description='You can always manage this in the company settings page approvals tab.'
      />

      <div className='tw-p-6'>
        <Box noPadding borderDashed className='tw-mb-4'>
          <button
            type='button'
            className='tw-flex tw-w-full tw-items-center tw-gap-4 tw-p-4 tw-text-left hover:tw-bg-surface-10'
            onClick={goToAddFlow}
          >
            <BoxIcon className='tw-bg-primary-10 tw-text-primary-100'>
              <Plus size={24} />
            </BoxIcon>

            <div>
              <p className='tw-mb-0 tw-flex tw-items-center tw-gap-1 tw-font-bold tw-text-black'>
                Create a new flow
              </p>
              <p className='tw-mb-0 tw-mt-0.5 tw-text-secondary-100'>
                Create a sequence of approvers
              </p>
            </div>

            <div className='ml-auto'>
              <CaretRight size={24} />
            </div>
          </button>
        </Box>

        <BillApprovalFlowSelector
          approvalFlows={approvalFlows}
          value={selectedFlow?.value}
          onChange={(val) => setSelectedFlow(val)}
        />
      </div>
    </>
  )
}

function Step3IntegrationSync() {
  const { isLoading: loadingIntegrations, startFetch: fetchIntegrations } =
    useFetch({
      action: getIntegrationList,
      autoFetch: true,
      onComplete: (data) => {
        const accountingIntegrations =
          data?.filter((i) => i.category === 'Accounting')?.[0]?.list ?? []

        const nsIntegration = accountingIntegrations?.find(
          (i) => i.code === INTEGRATION_BE_CODE.netsuite,
        )
        const isNsConnected = nsIntegration?.connected === 1

        const qbIntegration = accountingIntegrations?.find(
          (i) => i.code === INTEGRATION_BE_CODE.quickbooks,
        )
        const isQbConnected = qbIntegration?.connected === 1

        if (!isNsConnected && !isQbConnected) {
          return
        }

        const selectedIntegration = isNsConnected
          ? nsIntegration
          : isQbConnected
            ? qbIntegration
            : null

        if (isNsConnected) {
          getNsData()
        } else if (isQbConnected) {
          getQbData()
        }

        setIntegration(
          selectedIntegration &&
            getIntegrationStuff({ integration: selectedIntegration }),
        )
      },
    })

  function handleDataFetched(data) {
    setIntegration((prev) => ({
      ...prev,
      bills_auto_sync: data.bills_auto_sync,
    }))
  }

  const { startFetch: getQbData, isLoading: loadingQbData } = useFetch({
    action: getQBIntegrationData,
    onError: toastr.error,
    onComplete: handleDataFetched,
  })

  const { startFetch: getNsData, isLoading: loadingNsData } = useFetch({
    action: editNetSuite,
    onError: toastr.error,
    onComplete: handleDataFetched,
  })

  const [integration, setIntegration] = useState(null)

  const { startFetch: updateIntegration, isLoading: updatingIntegration } =
    useFetch({
      action: integration?.updateAction,
      onError: toastr.error,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Something went wrong while updating the integration')
        } else {
          toastr.success('Integration updated successfully')
          fetchIntegrations()
        }
      },
    })

  const hasIntegrationConnected = !!integration

  const integrationTitle = hasIntegrationConnected
    ? `Seems like you have a ${integration.name} Integration in place.`
    : 'Streamline payments with QuickBooks and NetSuite!'

  const integrationDataLoading =
    loadingIntegrations || loadingQbData || loadingNsData
  const integrationToggleLoading = integrationDataLoading && !integration

  function handleToggleSync(integration) {
    updateIntegration({
      bills_auto_sync: !integration?.bills_auto_sync,
      isUpdate: true,
    })
  }

  return (
    <Result
      IconComponent={(props) => {
        return <CheckCircle {...props} weight='duotone' />
      }}
      iconClassName='tw-text-systemGreen-100'
      className='tw-mx-auto'
      title='Default Approval Flow Assigned!'
      description='To customize the approvals for each vendor, you can select a different approval flow from vendor details.'
      actions={
        <Result
          className='tw-rounded tw-bg-primary-10 tw-p-6'
          IconComponent={ArrowsCounterClockwise}
          iconClassName='tw-size-6 tw-text-primary-100'
          title='Auto-sync Bills'
          description={integrationTitle}
          actions={
            integrationToggleLoading ? (
              ''
            ) : hasIntegrationConnected ? (
              <IntegrationAutoSync
                integration={integration}
                onToggleSync={handleToggleSync}
                loading={updatingIntegration || integrationDataLoading}
              />
            ) : (
              [
                { alt: 'QuickBooks logo', src: quickBooksLogo },
                { alt: 'NetSuite logo', src: netSuiteLogo },
              ].map(({ alt, src }, index) => {
                return (
                  <img
                    key={index + 1}
                    alt={alt}
                    src={src}
                    height={40}
                    width={40}
                  />
                )
              })
            )
          }
          actionsClassName={
            !hasIntegrationConnected && '*:tw-size-10 *:tw-object-contain'
          }
        />
      }
    />
  )
}

function getIntegrationStuff({ integration }) {
  const code = integration?.code
  switch (code) {
    case INTEGRATION_BE_CODE.quickbooks:
      return {
        name: integration.name,
        iconSrc: quickBooksLogo,
        updateAction: setupQuickbooks,
      }
    case INTEGRATION_BE_CODE.netsuite:
      return {
        name: integration.name,
        iconSrc: netSuiteLogo,
        updateAction: updateNetSuite,
      }
    default: {
      return null
    }
  }
}

const INTEGRATION_BE_CODE = {
  quickbooks: 'quickbooks',
  netsuite: 'netsuite',
}

function IntegrationAutoSync({ integration = {}, onToggleSync, loading } = {}) {
  const syncBills = integration?.bills_auto_sync

  return (
    <div className='tw-flex tw-items-center tw-gap-2 tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-p-4 tw-text-start'>
      {integration.iconSrc ? (
        <img
          src={integration.iconSrc}
          aria-hidden
          width={40}
          height={40}
          className='tw-shrink-0 tw-object-contain'
        />
      ) : null}
      <div>
        <h4 className='tw-mb-0 tw-text-sm tw-font-bold'>{integration.name}</h4>
        <p className='tw-mb-0 tw-text-xs tw-text-text-80'>
          Automatically synchronize your bills with {integration.name}
        </p>
      </div>
      <Toggle
        check={syncBills}
        change={() => onToggleSync(integration)}
        disabled={loading}
        marginRight=''
        className='tw-ml-auto'
      />
    </div>
  )
}

const approvalFlowFormId = 'approvalFlowForm'
function Step4AddFlow({ toggle, goToAssignFlow, onAddFlow }) {
  const { data: users, isLoading: usersLoading } = useFetch({
    action: getUsers,
    autoFetch: true,
  })

  return (
    <>
      <OnboardingHeader
        toggle={toggle}
        IconComponent={(props) => {
          return (
            <button
              type='button'
              onClick={goToAssignFlow}
              className='tw-mb-2 tw-text-secondary-100'
            >
              <ArrowLeft size={props.size} />
            </button>
          )
        }}
        iconClassName='tw-text-primary'
        title='Create new approval flow'
        description='All created bills will need to be approved accordingly'
      />

      <div className='tw-p-6'>
        {usersLoading ? (
          <Loader className='tw-h-full' />
        ) : (
          <ApprovalFlowForm
            formId={approvalFlowFormId}
            usersList={getUsersList(users)}
            onSubmit={onAddFlow}
          />
        )}
      </div>
    </>
  )
}

function OnboardingHeader({
  toggle,
  IconComponent,
  iconClassName,
  title,
  description,
}) {
  return (
    <ModalHeader close={<ModalCloseButton toggle={toggle} />} tag='div'>
      <IconComponent
        size={20}
        className={cn('tw-mb-2 tw-text-primary-100', iconClassName)}
      />
      {!title ? null : (
        <h5 className='tw-mb-0 tw-text-xl tw-font-semibold tw-text-secondary-120'>
          {title}
        </h5>
      )}
      {!description ? null : (
        <p className='tw-mb-0 tw-text-sm tw-text-text-80'>{description}</p>
      )}
    </ModalHeader>
  )
}
