import cx from 'classnames'
import React from 'react'

export default function RelatedFieldContent({ children, className }) {
  return (
    <div
      className={cx(
        'bg-slate-50 border border-gray-b border-top-0 p-3 rounded-bottom text-slate-600',
        className,
      )}
    >
      {children}
    </div>
  )
}
