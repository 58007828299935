import { Minus } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'

import './ExpandIcon.scss' // Import the CSS for styling

const iconSize = 16

function ExpandIcon({ isExpanded, className, onClick }) {
  return (
    <button
      className={cx(
        'expand-icon tw-relative tw-items-center tw-justify-center',
        className,
        { expanded: isExpanded },
      )}
      onClick={onClick}
      style={{
        '--size': iconSize + 4 + 'px',
        width: 'var(--size)',
        height: 'var(--size)',
      }}
    >
      <Minus size={iconSize} weight='bold' className='minus' />
      <Minus size={iconSize} weight='bold' className='minus rotated' />
    </button>
  )
}

export default ExpandIcon
