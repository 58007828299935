import React from 'react'
import { Col, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const CardDetailsModal = ({ details, isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} scrollable centered toggle={toggle}>
      <ModalHeader toggle={toggle}>Card Details</ModalHeader>
      <ModalBody>
        <div>
          <Col className='p-0'>
            <Label className='col-form-label pt-0 font-weight-light'>
              Holder Name
            </Label>
            <div className='form-control mb-2'>{details.holder_name}</div>
          </Col>
          <Col className='p-0'>
            <Label className='col-form-label pt-0 font-weight-light'>
              Card No.
            </Label>
            <div className='form-control mb-2'>
              **** **** **** {details.last_4_digits}
            </div>
          </Col>
          <Col className='p-0'>
            <Label className='col-form-label pt-0 font-weight-light'>
              Expiration Date
            </Label>
            <div className='form-control mb-2'>{details.expiration_date}</div>
          </Col>
          <Col className='p-0'>
            <Label className='col-form-label pt-0 font-weight-light'>
              Currency
            </Label>
            <div className='form-control mb-2'>{details.currencyCode}</div>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CardDetailsModal
