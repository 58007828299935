import React from 'react'
import NavTabs from '.'

import './bordered-nav-tabs.scss'

function BorderedNavTabs({
  className,
  options,
  activeOption,
  onClickOption,
  trail,
}) {
  return (
    <div className='bordered-nav-tabs'>
      <NavTabs
        className={className}
        options={options}
        activeOption={activeOption}
        onClickOption={onClickOption}
      />
      {trail && <div className='trail'>{trail}</div>}
    </div>
  )
}

export default BorderedNavTabs
