import React from 'react'
import DatePicker from 'react-datepicker'

import InputFeedback from '../../ui/input-feedback'
import { CustomDateInput } from '../CustomDatePicker/CustomDatePicker'
import { getInputErrorMessage } from '../get-input-error-message'

export default function CustomDateRange({
  wrapperClassName,
  wrapperStyles,
  label,
  error,
  inputClassName,
  disabled,
  labelClassName,
  labelFor,
  id,
  name,
  inputRef,
  placeholder,
  clearable,
  handleClear,

  onChange,
  value,
}) {
  return (
    <div className={wrapperClassName} style={wrapperStyles}>
      {!label ? null : <label>{label}</label>}

      {!label ? null : (
        <label className={labelClassName} htmlFor={id ?? labelFor}>
          {label}
        </label>
      )}

      <DateRange
        inputRef={inputRef}
        inputClassName={inputClassName}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
        clearable={clearable}
        handleClear={handleClear}
      />

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

function DateRange({
  inputRef,
  error,
  inputClassName,
  placeholder,
  disabled,
  name,
  onChange,
  value,
  clearable,
  handleClear,
}) {
  const startDate = value?.[0]
  const endDate = value?.[1]

  return (
    <DatePicker
      ref={inputRef}
      name={name}
      onChange={onChange}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      clearable={clearable}
      placeholderText={placeholder}
      customInput={
        <CustomDateInput
          hasError={!!error}
          className={inputClassName}
          disabled={disabled}
          clearable={clearable}
          handleClear={handleClear}
        />
      }
    />
  )
}
