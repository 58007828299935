export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESSFUL = 'REGISTER_USER_SUCCESSFUL'
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'
export const REGISTER_CLEAR_ERROR = 'REGISTER_CLEAR_ERROR'

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const UPDATE_RECAPTCHA_REQUIRED = 'UPDATE_RECAPTCHA_REQUIRED'
export const UPDATE_USER_ADMIN = 'UPDATE_USER_ADMIN'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESSFUL = 'LOGIN_USER_SUCCESSFUL'
export const LOGIN_ORIGINAL_USER_SUCCESSFUL = 'LOGIN_ORIGINAL_USER_SUCCESSFUL'
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED'
export const SAVE_USER_PASSWORD = 'SAVE_USER_PASSWORD'
export const DELETE_USER_PASSWORD = 'DELETE_USER_PASSWORD'

export const LOGOUT = 'LOGOUT'
export const SAVE_ROUTE = 'SAVE_ROUTE'

export const UPDATE_DEEP_LINK = 'UPDATE_DEEP_LINK'

export const OTP_VERIFICATION = 'OTP_VERIFICATION'
export const OTP_VERIFICATION_SUCCESSFUL = 'OTP_VERIFICATION_SUCCESSFUL'
export const OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER = 'UPDATE_USER'
