import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../../components/ui/button'
import { ModalFooterBtn } from '../../../components/ui/slider-modal/slider-modal'
import { generateApiKey } from '../../../services/openapi'
import { Check, Copy, Key, Warning } from '@phosphor-icons/react'
import copyToClipboard from 'copy-to-clipboard'
import { useFetch } from '../../../helpers/hooks'
import toastr from 'toastr'
import { PrimaryAlert } from '../../../components/ui/alert'

function CopyToClipboard({ text, onCopied = () => {} }) {
  const [copied, setCopied] = useState(false)

  async function copy() {
    copyToClipboard(text)
    setCopied(true)
    onCopied()
  }

  return (
    <button
      className='rp-btn-nostyle text-primary rounded'
      onClick={copy}
      type='button'
    >
      {copied ? (
        <div className='tw-flex'>
          <div className='tw-my-auto'>Copied</div>
          <div className='tw-my-auto tw-ml-1'>
            <div>
              <Check className='tw-text-2xl tw-font-bold' />
            </div>
          </div>
        </div>
      ) : (
        <div className='tw-flex tw-w-full tw-justify-end'>
          <div className='tw-justify-end'>
            <Copy className='tw-text-2xl tw-font-bold tw-text-secondary-100' />
          </div>
        </div>
      )}
    </button>
  )
}

function GeneratedApiKeyField({ generatedKey }) {
  return (
    <div className='tw-rounded-sm tw-border-x-[0.5px] tw-border-y-[0.5px] tw-border-[#E8E8E8] tw-px-2 tw-py-2'>
      <div className='tw-grid tw-grid-cols-12'>
        <div className='tw-col-span-10 tw-flex'>
          <div className='tw-my-auto tw-h-fit tw-justify-start'>
            <Key className='tw-text-2xl tw-text-secondary-100' />
          </div>
          <div className='tw-m-auto tw-ml-2 tw-h-fit tw-flex-wrap tw-break-all'>
            {generatedKey}
          </div>
        </div>
        <div className='tw-col-span-2 tw-my-auto tw-h-fit'>
          <div className='tw-right-0 tw-flex tw-w-full tw-justify-end'>
            <CopyToClipboard
              options={{ message: 'Copied' }}
              text={generatedKey}
              onCopy={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function ErrorModal({
  error,
  isLoading,
  onCancelClicked,
  onRetryClicked,
  isOpen,
  toggle,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <div>
          <Warning className='tw-text-2xl tw-font-bold tw-text-red-100' />
        </div>
        <div className='tw-mt-2 tw-text-lg tw-font-medium'>
          Oops! Something went wrong
        </div>
      </ModalHeader>

      <ModalBody>
        <div>{error}</div>
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={onCancelClicked}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type='button'
          color='red-100'
          onClick={onRetryClicked}
          disabled={isLoading}
          loading={isLoading}
          className='tw-capitalize'
        >
          Retry
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function GenerateApiKeyModal({ isOpen, toggle, onNewKeyGenerated }) {
  const [name, setName] = useState('')
  const [generatedKey, setGeneratedKey] = useState('')
  const [error, setError] = useState(null)
  const { isLoading, startFetch: generateKey } = useFetch({
    isOpenApi: true,
    action: generateApiKey,
    onError: (error) => {
      toastr.error(error)
      setError(error)
    },
    onComplete: (result) => {
      setGeneratedKey(result.key)
    },
  })

  async function onGenerateClicked() {
    generateKey({ name })
  }

  if (error?.length) {
    return (
      <ErrorModal
        isOpen={isOpen && error.length}
        toggle={() => {
          toggle()
          if (error?.length) {
            setError(null)
          }
        }}
        error={error}
        isLoading={isLoading}
        onCancelClicked={() => {
          toggle()
          if (error?.length) {
            setError(null)
          }
        }}
        onRetryClicked={onGenerateClicked}
      />
    )
  }
  if (!isOpen && generatedKey.length) {
    onNewKeyGenerated(generatedKey)
    setGeneratedKey('')
    setName('')
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <div>
          {!generatedKey ? 'Generate an API Key' : 'Key generated successfully'}
        </div>
        <div className='tw-mt-1 tw-text-xs tw-font-normal tw-text-text-60'>
          {!generatedKey
            ? 'Give a name to your key then click on generate'
            : 'Your new API Key is unique to your account'}
        </div>
      </ModalHeader>

      <ModalBody>
        {!generatedKey && (
          <input
            id='apiKeyName'
            name='apiKeyName'
            type='text'
            className='form-control'
            placeholder='Name *'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}

        {generatedKey && <GeneratedApiKeyField generatedKey={generatedKey} />}
      </ModalBody>

      {!generatedKey && (
        <>
          <hr className='tw-mt-0' />
          <ModalFooterBtn
            disabled={isLoading || name.trim().length === 0}
            className='tw-mx-12 tw-mb-4'
            onClick={onGenerateClicked}
            loading={isLoading}
          >
            <span className='tw-text-md tw-font-medium'>Generate</span>
          </ModalFooterBtn>
        </>
      )}

      {generatedKey && (
        <>
          <ModalFooter>
            <PrimaryAlert>
              This is the only time your API key will be displayed. Please copy
              and store it in a secure location.
            </PrimaryAlert>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
