import { format } from 'date-fns'
import React from 'react'

import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../helpers/hooks'
import { getContractTimeOff } from '../../services/api-time-off-policies'
import { getOptionFromList } from '../../utils/map-to-option'

const statusOptions = [
  { label: 'Pending approval', value: 2 },
  { label: 'Approved', value: 3 },
  { label: 'Declined', value: 4 },
  { label: 'Cancelled', value: 6 },
]

export function TimeOffFilters({ contract, filters, handleFiltersChange }) {
  const contractId = contract?.id
  const { data: policies, isLoading: gettingPolicies } = useFetch(
    {
      action: getContractTimeOff,
      body: { contract_id: contractId },
      autoFetch: contractId !== undefined,
    },
    [contractId],
  )

  const policyTypeOptions =
    policies?.map((policy) => ({
      label: policy?.policy?.name,
      value: policy?.policy?.time_off_type_id,
    })) ?? []

  return (
    <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3 tw-pb-4 md:tw-px-6 [&>*]:tw-min-w-full md:[&>*]:tw-min-w-56'>
      <CustomDatePicker
        name='month'
        placeholder='Month'
        showMonthYearPicker
        dateFormat='MM/yyyy'
        value={filters?.month && new Date(filters?.month)}
        onChange={(newDate) => {
          const formattedDate = format(newDate, 'yyyy-MM')
          handleFiltersChange('month', formattedDate, { action: 'clear' })
        }}
        clearable
        handleClear={() => {
          handleFiltersChange('month', null, { action: 'clear' })
        }}
      />

      <CustomSelect
        name='status_id'
        placeholder='Status'
        options={statusOptions}
        value={getOptionFromList(statusOptions, filters?.status_id)}
        onChange={(newStatus) => {
          handleFiltersChange('status_id', newStatus?.value, {
            action: 'clear',
          })
        }}
        isClearable
      />

      {!contractId ? null : (
        <CustomSelect
          name='time_off_type_id'
          placeholder='Policy'
          options={policyTypeOptions}
          isDisabled={gettingPolicies}
          isLoading={gettingPolicies}
          value={getOptionFromList(
            policyTypeOptions,
            filters?.time_off_type_id,
          )}
          onChange={(newPolicyType) => {
            handleFiltersChange('time_off_type_id', newPolicyType?.value, {
              action: 'clear',
            })
          }}
          isClearable
        />
      )}

      {/* <SearchBar className='tw-flex-shrink tw-flex-grow' /> */}
    </div>
  )
}
