import React from 'react'

import EmployeeDocsList from '../../../Fulltime/employee-documents/employee-docs-list'

export default function DocumentRequests() {
  const isAdmin = location.pathname.startsWith('/admin')

  return (
    <div className='page-content'>
      <EmployeeDocsList isAdmin={isAdmin} />
    </div>
  )
}
