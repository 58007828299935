import cx from 'classnames'
import React from 'react'
import { Spinner } from 'reactstrap'

export default function FullscreenLoader({
  type = 'border',
  color = 'primary',
  size = 'md',
  className = '',
  style = {},
}) {
  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center position-fixed',
        className,
      )}
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(128, 128, 128, 0.2)',
        zIndex: 1090,
        ...style,
      }}
    >
      <Spinner type={type} color={color} size={size} />
    </div>
  )
}
