import { X } from '@phosphor-icons/react'
import React from 'react'
import { Input } from 'reactstrap'

import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'

// @todo re-use this array
const filterContractOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Pay As You Go', value: 'payg' },
  { label: 'Milestone', value: 'milestone' },
  { label: 'Full Time', value: 'full_time' },
  { label: 'Direct Employee', value: 'direct_employee' },
]

export function SearchFilters({ filters, setUrlState }) {
  return (
    <div className='tw-grid tw-w-full tw-flex-grow tw-gap-2 md:tw-grid-cols-2'>
      <div className='tw-relative'>
        <Input
          type='text'
          placeholder='Search by name'
          value={filters?.search_key}
          onChange={(e) =>
            setUrlState?.({ search_key: e.target.value || undefined })
          }
        />

        {!filters?.search_key ? null : (
          <button
            type='button'
            className='tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 tw-p-1'
            onClick={() => {
              setUrlState?.({ search_key: undefined })
            }}
          >
            <X size={16} weight='bold' />
          </button>
        )}
      </div>

      <CustomSelect
        placeholder='Type'
        options={filterContractOptions}
        value={filterContractOptions?.find(
          (option) => option.value === filters?.contract_type,
        )}
        onChange={(selected) => {
          setUrlState?.({ contract_type: selected?.value || undefined })
        }}
        isClearable
      />
    </div>
  )
}
