import React from 'react'
import { Col, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { getBankAccountDetails } from '../../../services/api'

const BankDetailsModal = ({ isOpen, toggle, accountId }) => {
  const details = useFetch({
    action: getBankAccountDetails,
    body: { account_id: accountId },
    autoFetch: true,
  })

  return (
    <Modal isOpen={isOpen} scrollable centered toggle={toggle}>
      <ModalHeader toggle={toggle}>Bank account details</ModalHeader>
      <ModalBody className='p-4'>
        {details.isLoading ? (
          <Loader minHeight='370px' />
        ) : (
          <div>
            {details.data?.map((e, i) => (
              <Col key={`bankAccountInfo-${i}`} className='p-0'>
                <Label className='col-form-label pt-0 font-weight-light'>
                  {e.name}
                </Label>
                <div
                  className='form-control mb-2'
                  id='bankAccountInfo_Beneficiary'
                >
                  {e.value}
                </div>
              </Col>
            ))}
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default BankDetailsModal
