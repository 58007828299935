import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useCallback, useMemo, useState } from 'react'
import {
  Card,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import {
  adminDownloadPhysicalRequestDetails,
  adminPhysicalCardMarkIssued,
  adminPhysicalCardMarkShipped,
  adminPhysicalCardsList,
  adminSetReadyToShip,
  getCardEnumerations,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import { getCardStatus } from './cards-all'
import { dateCell } from './cell'
import { FilterField } from './filter-field'
import { useFilters } from './use-filters'

function getBodyFromFilters(filters) {
  const { shipping_status: status, ...rest } = filters
  return {
    ...rest,
    status: status?.value,
    page: filters.page - 1,
  }
}

const cardOrderStatusColor = {
  pending: 'danger',
  issued: 'warning',
  ready_to_ship: 'info',
  shipped: 'info',
  received: 'success',
}

export default function AdminCardsPhysical() {
  const [filters, handleFiltersChange] = useFilters(
    { page: 1 },
    { statusKey: 'shipping_status' },
  )

  const {
    isLoading,
    data,
    startFetch: refetchPhysicalCardsList,
  } = useFetch(
    {
      action: adminPhysicalCardsList,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(filters),
    },
    [filters],
  )

  const refetchData = useCallback(
    function refetchData() {
      refetchPhysicalCardsList(getBodyFromFilters(filters))
    },
    [filters, refetchPhysicalCardsList],
  )

  const { data: enumerations, isLoading: loadingEnumerations } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
    withAdminAccess: true,
  })

  const cardStatusOptions =
    loadingEnumerations || !enumerations
      ? []
      : enumerations.filterable_card_order_statuses.map(getCardStatus)

  const items = data?.items ?? []

  const columns = useMemo(
    () => [
      { Header: 'Requested on', accessor: 'created_at', Cell: dateCell },
      { Header: 'First name', accessor: 'user.name.first' },
      { Header: 'Middle name', accessor: 'user.name.middle' },
      { Header: 'Last name', accessor: 'user.name.last' },
      { Header: 'Email', accessor: 'user.email' },
      {
        Header: 'Shipping Phone',
        accessor: 'shipping_details.shipping_contact.mobile',
        Cell: ({ cellData }) => '+' + cellData?.country_code + cellData?.number,
      },
      {
        Header: 'Address 1',
        accessor: 'shipping_details.shipping_address.address_1',
      },
      {
        Header: 'Address 2',
        accessor: 'shipping_details.shipping_address.address_2',
      },
      {
        Header: 'City',
        accessor: 'shipping_details.shipping_address.city',
      },
      {
        Header: 'Country',
        accessor: 'shipping_details.shipping_address.country',
      },
      {
        Header: 'State',
        accessor: 'shipping_details.shipping_address.state',
      },
      {
        Header: 'Zip code',
        accessor: 'shipping_details.shipping_address.zipcode',
      },
      {
        Header: 'Card type',
        accessor: 'card_type',
        className: 'rp-capitalize',
      },
      {
        Header: 'Order Status',
        accessor: 'order_status',
        Cell: ({ cellData }) => (
          <BadgeX
            className='rp-capitalize'
            status={cardOrderStatusColor[cellData]}
          >
            {cellData}
          </BadgeX>
        ),
      },
      {
        Header: 'Shipping Status',
        accessor: 'shipping_details.shipping_status',
        className: 'rp-capitalize',
      },
      {
        Header: 'Actions',
        Cell: ({ rowData }) => (
          <ActionsCell rowData={rowData} refreshData={refetchData} />
        ),
      },
      { Header: 'Download', Cell: DownloadCell },
    ],
    [refetchData],
  )

  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0'>Physical Cards</h2>
      </div>
      <Card>
        <div className='py-3'>
          <Filters
            cardStatusOptions={cardStatusOptions}
            filters={filters}
            onChange={handleFiltersChange}
          />
        </div>

        {isLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : items?.length <= 0 ? (
          <NoContent
            className='text-muted font-size-16'
            minHeight='max(50vh, 550px)'
          >
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable responsive data={items ?? []} columns={columns} />

            <div className='d-flex justify-content-end px-3'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={data?.limit ?? 10}
                totalItemsCount={data?.total_items_count ?? 0}
                onChange={(v) => handleFiltersChange('page', v)}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

function ActionsCell({ rowData, refreshData }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const orderStatus = rowData.order_status
  const shippingStatus = rowData.shipping_details.shipping_status

  function toggle() {
    setIsModalOpen((isOpen) => {
      if (isOpen) {
        document.body.classList.remove('modal-open')
      }
      return !isOpen
    })
  }

  if (shippingStatus === 'ready_to_ship') {
    return (
      <>
        <MarkShippedModal
          isOpen={isModalOpen}
          toggle={toggle}
          cardId={rowData.id}
          refreshData={refreshData}
        />
        <Button size='sm' onClick={toggle}>
          Mark as shipped
        </Button>
      </>
    )
  } else if (orderStatus === 'pending' && shippingStatus !== 'shipped') {
    return (
      <>
        <MarkIssuedModal
          isOpen={isModalOpen}
          toggle={toggle}
          cardId={rowData.id}
          refreshData={refreshData}
        />
        <Button size='sm' onClick={toggle}>
          Issue card
        </Button>
      </>
    )
  }
  return null
}

function DownloadCell({ rowData }) {
  const { startFetch: downloadDetails, isLoading: downloadingDetails } =
    useFetch({
      action: adminDownloadPhysicalRequestDetails,
      withAdminAccess: true,
      onComplete: (data, body) => {
        openFileV2(data, {
          download: true,
          name: `card-request-details-${body.id}.pdf`,
        })
      },
    })

  function downloadCardDetails() {
    downloadDetails({ id: rowData.id })
  }

  return (
    <Button
      size='sm'
      color='info'
      outline
      onClick={downloadCardDetails}
      disabled={downloadingDetails}
      loading={downloadingDetails}
      style={{ minWidth: 133 }}
    >
      Download details
    </Button>
  )
}

const markIssuedFormId = 'mark-issued-form'
const setMarkAsReadyToShipFormId = 'mark-ready-to-ship-form'
function MarkIssuedModal({ isOpen, toggle, cardId, refreshData }) {
  const { startFetch: markIssued, isLoading: markingIssued } = useFetch({
    action: adminPhysicalCardMarkIssued,
    withAdminAccess: true,
    onComplete: () => {
      toggle()
      refreshData()
    },
    onError: (error) => {
      toastr.error(error.error?.messages?.join(', '), 'Something went wrong')
    },
  })

  const { startFetch: setReadyToShip, isLoading: settingReadyToShip } =
    useFetch({
      action: adminSetReadyToShip,
      withAdminAccess: true,
      onComplete: () => {
        toggle()
        refreshData()
      },
      onError: (error) => {
        toastr.error(error.error?.messages?.join(', '), 'Something went wrong')
      },
    })

  function handleSubmit(e, data) {
    const body = { ...data, card_order_id: cardId }

    const isMarkAsReadyToShip =
      e.nativeEvent?.submitter?.name === setMarkAsReadyToShipFormId

    if (isMarkAsReadyToShip) {
      setReadyToShip({ id: cardId })
    } else {
      markIssued(body)
    }
  }

  const isLoading = markingIssued || settingReadyToShip

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Mark as issued</ModalHeader>
      <ModalBody>
        <AvForm id={markIssuedFormId} onValidSubmit={handleSubmit}>
          <AvField name='assoc_number' label='Assoc number' />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button
          formId={markIssuedFormId}
          type='submit'
          loading={isLoading}
          disabled={isLoading}
          color='info'
          name={setMarkAsReadyToShipFormId}
        >
          Issue without ASSOC number
        </Button>

        <Button
          formId={markIssuedFormId}
          type='submit'
          loading={isLoading}
          disabled={isLoading}
        >
          Mark as issued
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const markShippedFormId = 'mark-shipped-form'
function MarkShippedModal({ isOpen, toggle, cardId, refreshData }) {
  const { startFetch: markShipped, isLoading } = useFetch({
    action: adminPhysicalCardMarkShipped,
    withAdminAccess: true,
    onComplete: () => {
      toggle()
      refreshData()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleSubmit(_, data) {
    const body = { ...data, card_order_id: cardId }
    markShipped(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Mark as shipped</ModalHeader>
      <ModalBody>
        <AvForm id={markShippedFormId} onValidSubmit={handleSubmit}>
          <AvField
            name='shipping_id'
            label='Shipping Id'
            validate={{
              required: {
                value: true,
                errorMessage: 'Shipping Id is required',
              },
            }}
          />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button
          formId={markShippedFormId}
          type='submit'
          loading={isLoading}
          disabled={isLoading}
        >
          Mark as shipped
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function Filters({ filters, onChange, cardStatusOptions }) {
  const filtersFields = [
    {
      name: 'shipping_status',
      label: 'Shipping Status',
      options: cardStatusOptions,
      type: 'select',
      isClearable: true,
    },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'name', label: 'Name', type: 'text' },
  ]

  return (
    <Container fluid className='px-3'>
      <Row className='mx-n2'>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}
