import cx from 'classnames'
import { Coins, StarFour } from '@phosphor-icons/react'
import React from 'react'

export const CashBackIcon = ({ color, size, style, className }) => {
  const baseCoinsSize = 28

  const coinsScalingFactor = size / baseCoinsSize
  const starFourLargeSize = 15 * coinsScalingFactor

  const starFourSmallSize = 8 * coinsScalingFactor
  const starFourSmallOffset = {
    top: -5 * coinsScalingFactor,
    right: 6 * coinsScalingFactor,
  }

  return (
    <div
      className={cx(
        'position-relative d-inline-block tw-pr-px tw-pt-px',
        className,
      )}
      style={style}
    >
      <Coins
        style={{ transform: 'rotate(15.788deg)' }}
        size={size}
        color={color}
      />
      <StarFour
        size={starFourLargeSize}
        weight='bold'
        className='position-absolute'
        style={{ top: -3 * coinsScalingFactor, right: -5 * coinsScalingFactor }}
        color={color}
      />
      <StarFour
        size={starFourSmallSize}
        weight='bold'
        className='position-absolute'
        style={{
          top: starFourSmallOffset.top,
          right: starFourSmallOffset.right,
        }}
        color={color}
      />
    </div>
  )
}
