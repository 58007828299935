import {
  DownloadSimple,
  Files,
  NewspaperClipping,
  Spinner,
  TrashSimple,
  Warning,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import { ActionsDropdown } from 'ui'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../../helpers/enum'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadAdminPayslip,
  downloadDePayslip,
  downloadPayslip,
  getAdminPayslipsList,
  getContractList,
  getDePayslipsList,
  getFullTimeContractDetails,
  getPayslipsList,
  regenerateDePayslip,
} from '../../../services/api'
import { updateContract } from '../../../store/contract/actions'
import openFileV2 from '../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import CardsList from '../../Contract/components/tab/card-list'
import TabCardHeader from '../../Contract/components/tab/tab-card-header'
import TabEmpty from '../../Contract/components/tab/tab-empty'
import AddPayslipModal from './addPayslipModal'

const Payslips = ({ contractData, isAdmin }) => {
  const storeContract = useSelector((state) => state?.Contract?.details)
  const contract = contractData || storeContract

  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const isDeRelated = isDeEmployee || isDeContract

  const [show, setShow] = useState(false)
  const [page, setPage] = useState(1)
  const list = useFetch(
    {
      action: isDeRelated
        ? getDePayslipsList
        : isAdmin
          ? getAdminPayslipsList
          : getPayslipsList,
      body:
        isEmployee || !contract?.id
          ? { page, perPage: 40 }
          : { contract_id: contract?.id, page, perPage: 40 },
      autoFetch: !!contract?.id,
      withAdminAccess: isAdmin,
    },
    [contract?.id, page],
  )

  const dispatch = useDispatch()
  const { isLoading: gettingContractInfo } = useFetch({
    action: isDeEmployee ? getContractList : getFullTimeContractDetails,
    autoFetch: !contractData,
    body: isDeEmployee ? { status: [4] } : null,
    onComplete: (data) => {
      let contract = data

      if (isDeEmployee) {
        contract = data?.[0]
      }

      dispatch(updateContract(contract))
    },
  })

  const showAddPayslip =
    (user?.type === userTypes.CONTRACTOR || isAdmin) &&
    !isEmployee &&
    !isDeEmployee

  const isEmpty = !list?.data || list?.data?.length === 0

  const showPaginator =
    list.paginator?.first_page_url !== list.paginator?.last_page_url
  const loading = list.isLoading || gettingContractInfo

  return (
    <>
      <Card className='rp-shadow-2'>
        <TabCardHeader
          title='Payslips'
          extra={
            showAddPayslip && !isEmpty && !list.isLoading ? (
              <Button onClick={() => setShow(!show)}>Add a New Payslip</Button>
            ) : null
          }
        />

        <CardBody>
          {loading ? (
            <Loader minHeight='35rem' />
          ) : isEmpty ? (
            <TabEmpty
              minHeight='35rem'
              title='No payslips'
              subtitle={
                isDeEmployee
                  ? 'Past payslips will be shown here'
                  : 'Your payslips will be shown here when added by the employer'
              }
              icon={
                <NewspaperClipping
                  size={250}
                  color='var(--primary)'
                  weight='duotone'
                />
              }
            >
              {showAddPayslip ? (
                <Button onClick={() => setShow(!show)}>
                  Add a New Payslip
                </Button>
              ) : null}
            </TabEmpty>
          ) : isDeRelated ? (
            <DePayslipsList
              data={list.data}
              onUpdate={() => {
                list.startFetch()
              }}
            />
          ) : (
            <PayslipList isAdmin={isAdmin} data={list.data} />
          )}
          {!showPaginator || loading ? null : (
            <div className='ml-auto mx-3 mt-2 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={page}
                itemsCountPerPage={list.paginator?.per_page ?? 10}
                totalItemsCount={list.paginator?.total ?? 0}
                onChange={(newPage) => setPage(newPage)}
              />
            </div>
          )}
        </CardBody>
      </Card>

      {show && (
        <AddPayslipModal
          show={show}
          isAdmin={isAdmin}
          contractId={contract?.id}
          hide={() => setShow(false)}
          onSuccessAdd={() => {
            list.startFetch()
          }}
        />
      )}
    </>
  )
}

export const PayslipList = ({ data, isAdmin }) => {
  const listItems = data?.map((e, i) => (
    <PayslipCard isAdmin={isAdmin} key={`payslip-${i}`} item={e} />
  ))

  return (
    <>
      <div className='d-none d-md-block'>
        <TableComp>
          <thead className='thead-light'>
            <tr>
              <StyledTh>Name</StyledTh>
              <StyledTh className='text-right'>Actions</StyledTh>
            </tr>
          </thead>
          {listItems}
        </TableComp>
      </div>

      <div className='d-md-none'>{listItems}</div>
    </>
  )
}

const PayslipCard = ({ item, update = () => {}, isAdmin }) => {
  const dateV2 = format(new Date(item?.year, item?.month - 1), 'MMM - yyyy')
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const isMobile = useResize()

  const download = useFetch({
    action: isAdmin ? downloadAdminPayslip : downloadPayslip,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `payslip-${dateV2}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const deleteDoc = useFetch({
    action: deleteContractorDocument,
    onComplete: update,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    download.startFetch({
      payslip_id: item.id,
    })
  }
  const handleDelete = () => {
    deleteDoc.startFetch({
      payslip_id: item.id,
    })
  }
  return !isMobile ? (
    <tr>
      <StyledTd>{dateV2}</StyledTd>
      <StyledTd>
        <div className='tw-flex tw-items-center tw-justify-end tw-gap-2'>
          {!isEmployee &&
            false &&
            (deleteDoc.isLoading ? (
              <Spinner
                size={16}
                weight='bold'
                className='tw-animate-[spin_2s_linear_infinite]'
              />
            ) : (
              <button
                onClick={handleDelete}
                className='tw-rounded tw-p-1 tw-text-systemRed-100 hover:tw-bg-systemRed-10'
              >
                <TrashSimple size={20} />
              </button>
            ))}

          <Button
            onClick={handleDownload}
            color='light'
            outline
            loading={download?.isLoading}
            disabled={download?.isLoading}
            icon={<DownloadSimple size={18} />}
            size='sm'
          >
            {download?.isLoading ? null : 'Download'}
          </Button>
        </div>
      </StyledTd>
    </tr>
  ) : (
    <div className='tw-mb-4 tw-flex tw-items-center tw-rounded tw-border tw-border-surface-30 tw-p-6'>
      <div className='tw-flex tw-flex-1 tw-items-center'>
        <Files size={24} weight='duotone' className='tw-text-primary-100' />

        <p className='tw-mb-0 tw-ml-2 tw-text-sm tw-font-bold tw-text-text-80'>
          {dateV2}
        </p>
      </div>

      <div className='tw-flex tw-flex-1 tw-justify-end tw-gap-2'>
        <Button
          onClick={handleDownload}
          color='light'
          outline
          loading={download?.isLoading}
          disabled={download?.isLoading}
          style={{ minWidth: 100 }}
          icon={<DownloadSimple size={18} />}
          size='sm'
        >
          {download?.isLoading ? null : 'Download'}
        </Button>

        {!isEmployee && false && (
          <Button
            type='button'
            color='danger'
            outline
            onClick={handleDelete}
            loading={deleteDoc?.isLoading}
            text='Remove'
            icon={<TrashSimple size={16} />}
            size='sm'
          />
        )}
      </div>
    </div>
  )
}

function DePayslipsList({ data, onUpdate }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        Cell: ({ rowData }) => {
          return (
            <div className='tw-flex tw-items-center tw-gap-1'>
              {rowData?.show_warning && (
                <>
                  <Warning
                    id={rowData.ref}
                    size={20}
                    className='tw-fill-systemGold-110'
                  />
                  <UncontrolledTooltip target={rowData.ref} placement='right'>
                    Some payments for this cycle are not included in the
                    payslip. Update payslip if needed.
                  </UncontrolledTooltip>
                </>
              )}
              <span>{rowData.month}</span>
            </div>
          )
        },
      },
      {
        Header: 'Amount',
        Cell: ({ rowData }) => {
          const formatter = getCurrencyFormatter(rowData.currency?.code)
          return formatter.format(rowData.total)
        },
      },
      {
        Header: 'Generated at',
        Cell: ({ rowData }) => {
          return format(new Date(rowData.generated_at), 'yyyy-MM-dd')
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ rowData }) => (
          <PayslipActions rowData={rowData} onUpdate={onUpdate} />
        ),
      },
    ],
    [],
  )

  return (
    <>
      <div className='d-none d-md-block'>
        <DataTable columns={columns} data={data} centered />
      </div>

      <CardsList columns={columns} data={data} className='d-md-none' />
    </>
  )
}

function PayslipActions({ rowData, onUpdate }) {
  const { startFetch: downloadPayslip, isLoading } = useFetch({
    action: downloadDePayslip,
    body: { payslip_id: rowData?.id },
    onComplete: (data) => {
      openFileV2(data, {
        download: true,
        name: `payslip-${rowData?.ref}-${rowData?.month}.pdf`,
      })
    },
    onError: () => {
      toastr.error('Something went wrong while downloading payslip')
    },
  })

  const { startFetch: updatePayslip, isUpdatingPayslip } = useFetch({
    action: regenerateDePayslip,
    body: { payslip_id: rowData?.id },
    onComplete: () => {
      onUpdate?.()
    },
    onError: () => {
      toastr.error('Something went wrong while updating payslip')
    },
  })

  const options = [{ label: 'Download', onClick: () => downloadPayslip() }]

  if (rowData?.show_warning) {
    options.push({
      label: 'Update',
      onClick: () => updatePayslip(),
    })
  }

  return (
    <ActionsDropdown data={options} loading={isLoading || isUpdatingPayslip} />
  )
}

export default Payslips
