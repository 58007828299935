import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Container, TabPane } from 'reactstrap'
import toastr from 'toastr'

import customStyles from '../../../components/Common/react-select-custom-styles'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import TabContent from '../../../components/ui/tabs'
import { useFetch } from '../../../helpers/hooks'
import {
  confirmTopup as confirmTopupAction,
  prepareTopup,
} from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { getErrorMessage } from '../../../utils/get-errors'
import WithdrawReview from '../../withdrawProcess/withdrawReview'
import CustomBalanceControl from '../components/custom-balance-control'
import { CustomBalanceOption } from '../components/custom-balance-option'
import FieldAmount from '../components/field-amount'
import { getBalanceOption, mapBalancesToOptions } from '../utils/balance-utils'
import { handleErrorOnComplete } from '../utils/handle-error-on-complete'

const steps = [
  { label: 'Top up', value: 0, title: 'Top Up to Card' },
  { label: 'Review', value: 1, title: 'Review' },
]

export default function CardTopup() {
  const user = useSelector((state) => state.userProfile?.userProfile)
  const balances = user?.balances ?? []

  const fullName = [user?.first_name, user?.middle_name, user?.last_name]
    .filter(Boolean)
    .join(' ')

  const history = useHistory()
  const location = history.location
  const amountRef = useRef()
  const [balance, setBalance] = useState(getBalanceOption(balances[0]))
  const [amount, setAmount] = useState(null)
  const [amountError, setAmountError] = useState(null)
  const [activeStep, setActiveStep] = useState(steps[0].value)
  useEffect(() => {
    if (!location?.state?.card?.id) {
      history.push('/cards')
    }
  }, [])
  const {
    startFetch: getDetails,
    data: reviewData,
    isLoading: dataLoading,
  } = useFetch({
    action: prepareTopup,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message ?? 'Something went wrong')
      }
    },
  })

  function goBackToCards() {
    history.push('/cards')
  }

  const { startFetch: confirmTopup, isLoading: confirmingTopup } = useFetch({
    action: confirmTopupAction,
    onComplete: (data) => {
      handleErrorOnComplete({
        data,
        errorMessage: 'Something went wrong, retry later',
        successMessage: 'Topup successful',
        onSuccess: goBackToCards,
      })
    },
    onError: (errors) => {
      toastr.error(getErrorMessage(errors) ?? 'Something went wrong')
    },
  })

  useEffect(() => {
    setAmount(balance?.balance)
  }, [balance])

  const balanceFormatter = getCurrencyFormatter(balance?.currency?.code)

  function handleFirstStepNext() {
    const MINIMUM_AMOUNT_TO_DRAW = 1
    if (balance?.balance === 0) {
      toastr.error('You have no balance to topup')
    } else if (amount < MINIMUM_AMOUNT_TO_DRAW) {
      const balanceFormatted = balanceFormatter.format(MINIMUM_AMOUNT_TO_DRAW)
      toastr.error(`Sorry, you can't select less than ${balanceFormatted}`)
      amountRef.current.focus()
    } else {
      setActiveStep((step) => step + 1)
      getDetails({ amount, currency: balance?.currency?.code?.toLowerCase() })
    }
  }

  function handleAmountChange(value) {
    if (value > balance?.balance) {
      const balanceFormatted = balanceFormatter.format(balance?.balance)
      setAmountError(`You can't select more than ${balanceFormatted}`)
    } else {
      setAmountError(null)
      setAmount(value)
    }
  }

  function handleBalanceChange(value) {
    setBalance(value)
    setAmount('')
  }

  function handleConfirmTopup() {
    const body = {
      cardId: location?.state?.card?.id,
      quote_id: reviewData?.quote_id,
    }

    confirmTopup(body)
  }

  const reviewDataFormatted = {
    base_currency: { code: reviewData?.source_currency },
    subtotal: reviewData?.source_amount,
    total: reviewData?.destination_amount,
    exchangeRate: reviewData?.rate,
    details: {
      should_arrive: 'In seconds',
      details: [
        { name: 'Account holder', value: fullName },
        { name: 'Email', value: user?.email },
      ],
    },
  }

  return (
    <div>
      <Container fluid>
        <ModalHeader action={goBackToCards}>
          <Steps
            activeTab={activeStep}
            data={steps.map((step) => step.label)}
            // noLastAction
            className='d-none d-md-flex'
          />
        </ModalHeader>
      </Container>

      <Container style={{ maxWidth: 552, margin: 'auto' }}>
        <TabContent activeTab={activeStep}>
          <TabPane tabId={steps[0].value}>
            <StepContainer
              index={steps[0].value}
              total={steps.length}
              title={steps[0].title}
              onNext={handleFirstStepNext}
              onBack={() => setActiveStep((step) => step - 1)}
              nextText='Next'
              disableNext={!amount}
              minHeight='320px'
            >
              <div className='pt-3 pb-4'>
                <h5 className='text-gray-h'>RemotePass Wallet</h5>

                <CustomSelect
                  options={mapBalancesToOptions(balances, {
                    disabledEmptyBalance: true,
                  })}
                  styles={customStyles}
                  value={balance}
                  onChange={handleBalanceChange}
                  components={{
                    IndicatorSeparator: null,
                    Option: CustomBalanceOption,
                    Control: CustomBalanceControl,
                  }}
                  menuIsOpen={balances?.length <= 1 ? false : undefined}
                />
              </div>
              {!balance ? null : (
                <FieldAmount
                  label='Enter an amount'
                  name='topup-amount'
                  value={amount}
                  onValueChange={handleAmountChange}
                  prefix={balance?.currency?.code}
                  inputRef={amountRef}
                  helperText={`Balance maximum ${balanceFormatter.format(
                    balance?.balance,
                  )}`}
                  error={amountError}
                />
              )}
            </StepContainer>
          </TabPane>
          <TabPane tabId={steps[1].value}>
            <StepContainer
              index={steps[1].value}
              total={steps.length}
              title={steps[1].title}
              onNext={handleConfirmTopup}
              onBack={() => setActiveStep((step) => step - 1)}
              loading={confirmingTopup}
              nextText='Confirm'
              isFlat
              minHeight='320px'
            >
              <WithdrawReview
                title='Top Up to Card Balance'
                receptionLabel='You will receive in your Card'
                bankInfo={reviewDataFormatted}
                loading={dataLoading}
                onBack={() => setActiveStep((step) => step - 1)}
                loadingMinHeight='310px'
              />
            </StepContainer>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}
