import React, { cloneElement } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { cn } from 'ui'
import Button from '../ui/button'
import { DownloadSimple } from '@phosphor-icons/react'
import openFileV2 from '../../utils/file/open-v2'

export default function UploadPreview({
  preview,
  className = 'tw-h-full tw-w-full',
  imagePreviewClassName,
  style,
  withDownload = false,
}) {
  const classList = cn('tw-rounded tw-border', className)

  return (
    <>
      {preview?.type === 'application/pdf' ? (
        <iframe
          src={preview?.data + '#toolbar=0&navpanes=0&scrollbar=0'}
          className={classList}
          style={style}
        />
      ) : (
        <div
          style={style}
          className={cn('tw-overflow-auto', imagePreviewClassName)}
        >
          <img alt='' src={preview?.data} className={classList} />
        </div>
      )}
      {withDownload && (
        <Button
          className='tw-absolute tw-bottom-2 tw-right-2 !tw-bg-surface-10'
          size='sm'
          onClick={() => {
            openFileV2(preview?.data, { isDataUrl: true })
          }}
          color='link'
          icon={<DownloadSimple size={20} />}
        >
          Download
        </Button>
      )}
    </>
  )
}

export function DocumentPreviewModal({ isOpen, toggle, ...props }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <UploadPreview {...props} />
      </ModalBody>
    </Modal>
  )
}

export function DocumentPreview({ children, ...props }) {
  const [isOpen, setIsOpen] = React.useState(false)
  function toggle() {
    return setIsOpen((prev) => !prev)
  }

  return (
    <>
      {typeof children === 'function'
        ? children({ toggle })
        : cloneElement(children, { onClick: toggle })}

      <DocumentPreviewModal isOpen={isOpen} toggle={toggle} {...props} />
    </>
  )
}
