import React from 'react'
import { Col, Container, Label, Row, Spinner } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toastr from 'toastr'

const AccountInfo = ({ loading, data, withCopy = true }) => {
  return loading ? (
    <Container style={{ minHeight: '30rem' }}>
      <Col style={{ minHeight: '30rem' }}>
        <Row
          style={{ minHeight: '30rem' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      </Col>
    </Container>
  ) : (
    <div>
      <Col className='pt-2'>
        {!!data &&
          data?.data?.map((e, i) => (
            <Col key={`bankInfo-${i}`} className='p-0'>
              <Label className='col-form-label pt-0 font-weight-light'>
                {e.name}
              </Label>
              <div
                name='end-date'
                className='form-control mb-2'
                id='bankInfo_Beneficiary'
              >
                {e.value}
              </div>
              {withCopy && (
                <CopyToClipboard
                  text={e.value}
                  onCopy={() => toastr.success(`${e.name} copied`)}
                >
                  <span
                    style={{
                      position: 'absolute',
                      right: '10px',
                      bottom: '10px',
                    }}
                  >
                    <i className='fas fa-copy 16 text-primary' />
                  </span>
                </CopyToClipboard>
              )}
            </Col>
          ))}
      </Col>
    </div>
  )
}

export default AccountInfo
