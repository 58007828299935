import {
  Briefcase,
  CircleNotch,
  TrashSimple,
  UserCircle,
  UserSquare,
  Warning,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import toastr from 'toastr'

import { cn } from 'ui'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import {
  deleteExternalInvoiceClient,
  getExternalInvoiceClients,
} from '../../services/api-external-invoices'
import { getFullName } from '../../utils/get-full-name'
import { AddNewClient } from './add-new-client'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../components/ui/button'

export const EXTERNAL_CLIENT_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTITY: 'ENTITY',
}

export function ClientsList({ onClientSelect, selectedClient }) {
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: null })
  const {
    data: externalClients,
    isLoading: loadingExternalClients,
    startFetch: refreshClients,
    completed: clientsCompleted,
  } = useFetch({
    action: getExternalInvoiceClients,
    autoFetch: true,
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: deleteClient, isLoading: deletingClient } = useFetch({
    action: deleteExternalInvoiceClient,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to delete client')
      } else {
        toastr.success('Client deleted successfully')
      }
      setDeleteModal({ id: null, isOpen: false })
      refreshClients()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  if (loadingExternalClients || !clientsCompleted) {
    return <Loader minHeight='393px' />
  }

  if (!externalClients || externalClients?.length <= 0) {
    return (
      <div className='tw-text-center'>
        <UserSquare
          weight='duotone'
          size={250}
          className='tw-inline-block tw-text-primary-100'
        />
        <p className='tw-mb-0 tw-mt-4 tw-text-2xl tw-font-medium'>
          No clients found
        </p>
        <p className='tw-text-sm tw-font-semibold tw-text-text-80'>
          The client you add will be listed here
        </p>

        <AddNewClient
          btnProps={{
            className: 'tw-mt-4',
            color: 'primary',
            text: 'Add Client',
          }}
          onCreateSuccess={() => refreshClients()}
        />
      </div>
    )
  }

  return (
    <div>
      <div className='tw-flex tw-items-center tw-justify-between'>
        <h4 className='tw-mb-0 tw-text-xl tw-font-semibold'>Select client</h4>

        <AddNewClient
          btnProps={{ className: '!tw-px-0' }}
          onCreateSuccess={() => refreshClients()}
        />
      </div>

      <ul className='tw-mt-8 tw-grid tw-gap-4 md:tw-grid-cols-2'>
        {externalClients
          .map((client) => ({
            id: client.id,
            fullName: getFullName(client),
            type: client.type,
            raw: client,
          }))
          .map((client) => {
            const { id, fullName, type } = client
            const selected = selectedClient === id

            return (
              <li
                key={id}
                className={cn(
                  'tw-group tw-relative tw-flex tw-items-center tw-gap-4 tw-rounded tw-border tw-p-4 tw-transition-colors',
                  selected
                    ? 'tw-border-primary-100 tw-bg-primary-10 tw-ring-1 tw-ring-primary-100 focus-within:tw-ring-2 focus-within:tw-ring-primary-30 focus-within:tw-ring-offset-1 focus-within:tw-ring-offset-primary-100'
                    : 'tw-border-surface-30 focus-within:tw-border-primary-100 focus-within:tw-ring-2 focus-within:tw-ring-primary-20 hover:tw-border-surface-50 hover:tw-bg-surface-10',
                )}
              >
                <div
                  className={cn(
                    'tw-relative tw-size-12 tw-shrink-0 tw-rounded-full tw-p-3 [&>*]:tw-size-full',
                    selected
                      ? 'tw-bg-primary-20 tw-text-primary-100'
                      : 'tw-bg-secondary-20 tw-text-secondary-100',
                  )}
                >
                  {type === EXTERNAL_CLIENT_TYPES.INDIVIDUAL ? (
                    <UserCircle className='tw-size-6' />
                  ) : (
                    <Briefcase className='tw-size-6' />
                  )}
                </div>
                <div className='tw-relative tw-grow'>
                  <p className='tw-mb-0 tw-text-sm tw-font-semibold'>
                    {fullName}
                  </p>
                  <p className='tw-mb-0 tw-text-sm tw-font-medium tw-text-text-80'>
                    {type}
                  </p>
                </div>
                {typeof onClientSelect !== 'function' ? null : (
                  <button
                    type='button'
                    className={cn(
                      'tw-absolute tw-inset-0 tw-rounded',
                      selected ? '!tw-cursor-default' : '',
                    )}
                    onClick={() => onClientSelect(client)}
                  />
                )}
                <div className='tw-relative tw-hidden tw-animate-fadeIn group-hover:tw-block'>
                  <button
                    type='button'
                    className='tw-rounded tw-p-1.5 tw-transition-colors hover:tw-bg-surface-20'
                    onClick={() => setDeleteModal({ id, isOpen: true })}
                    disabled={deletingClient}
                    aria-disabled={deletingClient}
                  >
                    {deletingClient ? (
                      <CircleNotch
                        className='tw-animate-[spin_2s_linear_infinite]'
                        size={20}
                      />
                    ) : (
                      <TrashSimple size={20} />
                    )}
                  </button>
                </div>
              </li>
            )
          })}
      </ul>
      {deleteModal.isOpen && (
        <Modal
          isOpen={deleteModal.isOpen}
          toggle={() => setDeleteModal({ id: null, isOpen: false })}
        >
          <ModalHeader
            className='!tw-border-none'
            toggle={() => setDeleteModal({ id: null, isOpen: false })}
          >
            <Warning color='red' size={24} />
          </ModalHeader>
          <ModalBody className='!tw-pt-0'>
            <div className='tw-mb-2 tw-text-xl tw-font-semibold'>
              Do you want to delete this client?
            </div>
            <div>This action cannot be undone</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              outline
              onClick={() => setDeleteModal({ id: null, isOpen: false })}
            >
              No, Close
            </Button>
            <Button
              loading={deletingClient}
              isDisabled={deletingClient}
              color='danger'
              onClick={() => deleteClient({ id: deleteModal.id })}
            >
              Yes, Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
