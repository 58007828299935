import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  LOGIN_ORIGINAL_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  OTP_VERIFICATION,
  OTP_VERIFICATION_SUCCESSFUL,
  OTP_VERIFICATION_FAILED,
  LOGOUT,
  LOGIN_USER_SUCCESSFUL,
  UPDATE_USER_INFO,
  UPDATE_DEEP_LINK,
  UPDATE_USER_ADMIN,
  UPDATE_RECAPTCHA_REQUIRED,
  REGISTER_CLEAR_ERROR,
  UPDATE_USER_DATA,
  UPDATE_USER,
  TOGGLE_LOGIN,
  SAVE_ROUTE,
  SAVE_USER_PASSWORD,
  DELETE_USER_PASSWORD,
} from './actionTypes'

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  OTPError: null,
  OTPmessage: null,
  OTPloading: false,
  data: null,
  lastPath: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case TOGGLE_LOGIN:
      state = {
        ...state,
        loggedIn: action.payload,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: action.payload,
      }
      break
    case REGISTER_CLEAR_ERROR:
      state = {
        ...state,
        registrationError: null,
      }
      break
    case OTP_VERIFICATION:
      state = {
        ...state,
        OTPloading: true,
        OTPError: null,
      }
      break
    case OTP_VERIFICATION_SUCCESSFUL:
      state = {
        ...state,
        OTPloading: false,
        user: action.payload,
        OTPError: null,
      }
      break
    case OTP_VERIFICATION_FAILED:
      state = {
        ...state,
        OTPloading: false,
        OTPError: action.payload,
      }
      break
    case LOGOUT:
      state = {
        ...state,
        user: null,
        loggedIn: false,
        isAdmin: false,
        originalUser: null,
        data: null,
      }
      break
    case SAVE_ROUTE:
      state = {
        ...state,
        lastPath: action.payload,
      }
      break
    case LOGIN_USER_SUCCESSFUL:
      state = {
        ...state,
        user: Array.isArray(action.payload)
          ? action.payload
          : { ...state.user, ...action.payload },
        loggedIn: !!action.payload?.token,
      }
      break
    case LOGIN_ORIGINAL_USER_SUCCESSFUL:
      state = {
        ...state,
        originalUser: Array.isArray(action.payload)
          ? action.payload
          : { ...state.user, ...action.payload },
      }
      break
    case UPDATE_USER_INFO:
      state = {
        ...state,
        user: { ...state.user, ...action.payload },
      }
      break
    case UPDATE_DEEP_LINK:
      state = {
        ...state,
        link: action.payload,
      }
      break
    case UPDATE_USER_ADMIN:
      state = {
        ...state,
        isAdmin: true,
      }
      break
    case UPDATE_RECAPTCHA_REQUIRED:
      state = {
        ...state,
        isRecaptchaRequired: action.payload,
      }
      break
    case UPDATE_USER_DATA:
      state = {
        ...state,
        data: action?.payload,
      }
      break
    case UPDATE_USER:
      state = {
        ...state,
        user: { ...state?.user, ...action?.payload },
      }
      break
    case SAVE_USER_PASSWORD:
      state = {
        ...state,
        password: action?.payload,
      }
      break
    case DELETE_USER_PASSWORD:
      state = {
        ...state,
        password: '',
      }
      break
  }
  return state
}

export default account
