import React from 'react'

export function BackgroundDots() {
  return (
    <svg className='tw-absolute tw-left-0 tw-top-0 tw-size-full'>
      <pattern
        id='pattern-1-1'
        x='8'
        y='10'
        width='20'
        height='20'
        patternUnits='userSpaceOnUse'
      >
        <circle cx='1' cy='1' r='1' fill='#91919a'></circle>
      </pattern>
      <rect
        x='0'
        y='0'
        width='100%'
        height='100%'
        fill='url(#pattern-1-1)'
      ></rect>
    </svg>
  )
}
