export const getHighestOrder = (items) => {
  if (items.length === 0) {
    return 1
  }
  return items.reduce((acc, item) => {
    if (item.order > acc) {
      return item.order
    }
    return acc
  }, 0)
}

// TODO: maybe we should just somehow use typeOptions in add-field.jsx?
export const getFieldTypeText = (type) => {
  switch (type) {
    case 'short_text_input':
      return 'Text'
    case 'long_text_input':
      return 'Text Area'
    case 'date_picker':
      return 'Date'
    case 'dropdown':
      return 'Select'
    case 'country_dropdown':
      return 'Country'
    case 'religion_dropdown':
      return 'Religion'
    case 'religious_faith_dropdown':
      return 'Religious faith'
    case 'marital_status_dropdown':
      return 'Marital status'
    case 'passport_type_dropdown':
      return 'Passport type'
    case 'language_dropdown':
      return 'Language'
    case 'multi_select_dropdown':
      return 'Multi Select'
    case 'file_upload':
      return 'File'
    case 'multi_file_upload':
      return 'Files'
    default:
      return 'Text'
  }
}

export const filterNullProps = (obj) => {
  for (const prop in obj) {
    if (obj[prop] === null) {
      delete obj[prop]
    }
  }
  return obj
}
