import { eachDayOfInterval, format, parse } from 'date-fns'
import { useCallback, useState } from 'react'
import toastr from 'toastr'
import { useFetch } from '../../helpers/hooks'
import {
  getAdminTimesOff,
  getAllTimeOffs,
  getClientTimeOffs,
  getFullTimeTimeOffs,
} from '../../services/api'
import logger from '../../services/logger'

export const getTimeOffStatusColor = (status) => {
  switch (status) {
    case 'Pending approval':
      return 'warning'
    case 'Approved':
      return 'success'
    case 'Declined':
      return 'danger'
    case 'Unpaid':
      return 'danger'
    case 'Cancelled':
      return 'danger'
    default:
      return 'primary'
  }
}

function formatRangeDate(date) {
  if (!date) return null

  return format(date, 'yyyy-MM-dd')
}

function getBodyFromFilters(filters, contractId) {
  const start = formatRangeDate(filters?.start_date)
  const end = formatRangeDate(filters?.end_date)

  return {
    contract_id: contractId,
    ...filters,
    start_date: start,
    end_date: end,
  }
}

export function useTimeOffFetch({
  contractId,
  isAdmin = false,
  isEmployee,
  isContractDetails,
  autoFetch = true,
} = {}) {
  const [filters, setFilters] = useState({ page: 1 })

  function handleFiltersChange(key, value, options = {}) {
    const { action } = options

    setFilters((f) => {
      const changes = {}

      const isPageChange = key === 'page'
      if (isPageChange) {
        changes.page = value
      } else {
        const isClearingFilter = action === 'clear'
        changes.page = isClearingFilter ? 1 : f.page
        changes[key] = value
      }

      return { ...f, ...changes }
    })
  }

  const {
    data: timeOffs,
    isLoading: gettingTimeOffList,
    startFetch: refreshTimeOffs,
    paginator,
    completed,
  } = useFetch(
    {
      action: isAdmin
        ? getAdminTimesOff
        : isContractDetails
          ? getClientTimeOffs
          : isEmployee
            ? getFullTimeTimeOffs
            : getAllTimeOffs,
      withAdminAccess: isAdmin,
      autoFetch,
      body: getBodyFromFilters(filters, contractId),
      onError: (err) => {
        toastr.error(err)
      },
    },
    [filters, autoFetch],
  )

  const updateTimeOff = useCallback(
    function updateTimeOff() {
      refreshTimeOffs(
        getBodyFromFilters(filters, contractId, {
          isClient: isContractDetails,
        }),
      )
    },
    [refreshTimeOffs, filters, contractId, isContractDetails],
  )

  return {
    timeOffs,
    gettingTimeOffList,
    updateTimeOff,
    paginator,
    filters,
    handleFiltersChange,
    willAutoFetch: autoFetch,
    doneFetching: completed,
  }
}

export function parseDate(date) {
  return date ? parse(date, 'yyyy-MM-dd', new Date()) : null
}

export function getExcludedDates(timeOffs = []) {
  return timeOffs
    ?.filter((t) => [2, 3].includes(t.status?.id))
    ?.reduce((prev, curr) => {
      const start = parseDate(curr?.from)
      const end = parseDate(curr?.to)

      let toBeExcluded = []
      try {
        toBeExcluded = eachDayOfInterval({ start, end })
      } catch (e) {
        logger.error(e)
      }
      return [...prev, ...toBeExcluded]
    }, [])
}
