import { CheckCircle, Circle } from '@phosphor-icons/react'
import React from 'react'
import { cn } from 'ui'
import InputFeedback from '../input-feedback'
import { getInputErrorMessage } from '../../Forms/get-input-error-message'

export function RadioButtonsV2({
  options = [],
  name,
  label,
  labelDescription,
  onChange,
  value: globalValue,
  className,
  optionClassName,
  optionsClassName,
  error,
  fieldRef,
}) {
  return (
    <div className={className}>
      <fieldset className='tw-space-y-6'>
        {!label && !labelDescription ? null : (
          <div>
            {!label ? null : (
              <legend className='tw-mb-0 tw-text-base'>{label}</legend>
            )}
            {!labelDescription ? null : (
              <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
                {labelDescription}
              </p>
            )}
          </div>
        )}

        <div className={cn('tw-flex tw-flex-col tw-gap-3', optionsClassName)}>
          {options.map(({ value, label, suffix, disabled }, index) => {
            const checked = globalValue ? value === globalValue : undefined
            return (
              <label
                key={value}
                className={cn(
                  'tw-group tw-mb-0 tw-flex tw-cursor-pointer tw-items-start tw-gap-3 tw-rounded tw-p-4 tw-ring-1 tw-ring-surface-30 hover:tw-bg-surface-10 has-[:disabled]:tw-cursor-not-allowed has-[:disabled]:hover:tw-bg-transparent',
                  'tw-text-black tw-transition-all has-[:checked]:tw-bg-primary-10 has-[:checked]:tw-text-primary-100 has-[:checked]:tw-ring-2 has-[:checked]:tw-ring-primary-100',
                  optionClassName,
                )}
                htmlFor={value}
                // Only set the ref on the first radio item
                // This is to allow programmatic focus on the first radio item
                ref={index === 0 ? fieldRef : null}
              >
                <div className='tw-relative tw-size-5 tw-shrink-0 tw-text-secondary-50 tw-transition-colors tw-duration-75 has-[:checked]:tw-text-primary-100'>
                  <input
                    type='radio'
                    name={name}
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    id={value}
                    className='tw-peer tw-absolute tw-size-5 tw-appearance-none checked:tw-outline-none'
                    onChange={onChange}
                  />
                  <CheckCircle
                    className='tw-absolute tw-size-5 tw-scale-50 tw-opacity-0 tw-transition-all peer-checked:tw-scale-100 peer-checked:tw-opacity-100'
                    weight='fill'
                  />
                  <Circle className='tw-absolute tw-size-5 tw-transition-transform tw-duration-100 peer-checked:tw-scale-50' />
                </div>
                <div className='tw-flex tw-flex-grow tw-items-center tw-justify-between tw-gap-1 group-has-[:disabled]:tw-text-text-50'>
                  {!label ? null : <div>{label}</div>}
                  {!suffix ? null : (
                    <div className='tw-text-text-70'>{suffix}</div>
                  )}
                </div>
              </label>
            )
          })}
        </div>
      </fieldset>

      {!error ? null : (
        <InputFeedback className='tw-mt-2'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}
