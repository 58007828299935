import { AvForm, AvInput } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../../helpers/hooks'
import { adjustPayment } from '../../../services/api'

const AdjustModal = ({ show, hide, data, contract, updated }) => {
  const staticData = useSelector((state) => state?.Layout?.staticData)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.currency?.code || 'USD',
  })
  const [actionType, setActionType] = useState(null)
  const [amount, setAmount] = useState('')
  const [reason, setReason] = useState('')
  const [details, setDetails] = useState(null)
  const availableCycle = data?.filter(
    (e) => e.status === 'Unpaid' && !e.is_adjustment && e.amount !== 0,
  )
  const [cycle, setCycle] = useState(null)

  const adjust = useFetch({
    action: adjustPayment,
    onComplete: (data, body) => {
      updated()
      window.analytics.track('Added adjustment', {
        contract_id: contract?.ref,
        contract_type: contract?.type?.name,
        contract_status: contract?.status?.name,
        adjust_id: data?.id,
        adjust_type: data?.name,
        adjust_reason: body?.reason_id,
        amount: body?.amount,
        currency: contract?.currency?.code,
      })
    },
    onError: (error) => toastr.error(error),
  })
  useEffect(() => {
    if (
      actionType?.value === 2 ||
      (actionType?.value === 1 && reason?.value === 4)
    ) {
      setAmount('')
      setCycle({
        label: `${availableCycle[0].payment_ref} (${formatter?.format(
          availableCycle[0].amount,
        )})`,
        value: availableCycle[0]?.id,
        amount: availableCycle[0].amount,
      })
    } else if (actionType?.value === 1) {
      setCycle(null)
    }
    setReason(null)
  }, [actionType])

  return (
    <Modal isOpen={show} toggle={hide} className='p-0 p-md-5' unmountOnClose>
      <ModalHeader toggle={hide}>Adjust Payment</ModalHeader>
      <AvForm
        onValidSubmit={(e, v) => {
          if (amount && actionType && reason) {
            adjust.startFetch({
              contract_id: contract?.id,
              type_id: actionType?.value,
              reason_id: reason?.value,
              details,
              amount,
              vat_status: v?.vat_status ? 1 : 0,
              payment_id: cycle?.value,
            })
          } else {
            toastr.error('You need to fill all required fields')
          }
        }}
      >
        <ModalBody>
          <FormGroup className='justify-content-center'>
            <label className='col-form-label pt-0'>
              Action type
              <span className='text-danger font-size-16 ml-1'>*</span>
            </label>
            <CustomSelect
              className='border-0'
              name='Action_id'
              onChange={setActionType}
              isSearchable={false}
              value={actionType}
              options={staticData?.adjustment_types?.map((e) => ({
                label: e?.name,
                value: e?.id,
                disabled: e?.id === 2 && availableCycle?.length === 0,
                reasons: e?.reasons,
              }))}
              isOptionDisabled={(option) => option.disabled}
            />
          </FormGroup>
          {actionType?.value === 2 && (
            <FormGroup className='justify-content-center'>
              <label className='col-form-label pt-0'>Cycle</label>
              <CustomSelect
                className='border-0'
                name='cycle1'
                onChange={setCycle}
                isSearchable={false}
                value={cycle}
                options={availableCycle.map((e) => ({
                  label: `${e.payment_ref} (${formatter?.format(e.amount)})`,
                  value: e?.id,
                  amount: e.amount,
                }))}
              />
            </FormGroup>
          )}
          <FormGroup className='justify-content-center'>
            <label className='col-form-label pt-0'>
              Reason<span className='text-danger font-size-16 ml-1'>*</span>
            </label>
            <CustomSelect
              className='border-0'
              name='reason_id'
              onChange={setReason}
              isSearchable={false}
              value={reason}
              options={actionType?.reasons?.map((e) => ({
                label: e?.name,
                value: e?.id,
              }))}
            />
          </FormGroup>
          {actionType?.value === 1 && reason?.value === 4 && (
            <FormGroup className='justify-content-center'>
              <label className='col-form-label pt-0'>Cycle</label>
              <Select
                className='border-0'
                name='cycle1'
                onChange={(s) => {
                  setCycle(s)
                }}
                isSearchable={false}
                value={cycle}
                options={availableCycle.map((e) => ({
                  label: `${e.payment_ref} (${formatter?.format(e.amount)})`,
                  value: e?.id,
                  amount: e.amount,
                }))}
              />
            </FormGroup>
          )}
          <FormGroup className='justify-content-center'>
            <label className='col-form-label pt-0'>Note</label>
            <Input
              placeholder='Note'
              className='form-control'
              name='details'
              onChange={(s) => {
                setDetails(s.target.value)
              }}
              value={details}
            />
          </FormGroup>
          <FormGroup>
            <Label className='col-form-label pt-0'>
              Amount<span className='text-danger font-size-16 ml-1'>*</span>
            </Label>
            <InputGroup>
              <Input
                placeholder='Amount'
                name='amount'
                type='text'
                onKeyPress={(e) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
                autoComplete='off'
                value={amount}
                onChange={(e) => {
                  if (
                    ((!!cycle && e.target.value <= cycle?.amount) || !cycle) &&
                    e.target.value >= 0
                  ) {
                    setAmount(e.target.value)
                  }
                }}
              />
              <InputGroupAddon
                // style={{ width: '25%' }}
                addonType='append'
              >
                {contract?.currency?.code}
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          {actionType?.value === 1 && !!contract?.vat && (
            <FormGroup>
              <div
                className='custom-control custom-switch align-items-center'
                dir='ltr'
              >
                <AvInput
                  name='vat_status'
                  type='checkbox'
                  className='custom-control-input'
                  id='customvat_status'
                />
                <label
                  className='custom-control-label'
                  htmlFor='customvat_status'
                >
                  Apply VAT.
                </label>
              </div>
            </FormGroup>
          )}
        </ModalBody>
        <div className='modal-footer'>
          <button className='btn btn-primary' type='submit'>
            {adjust?.isLoading && (
              <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
            )}
            Adjust
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

export default AdjustModal
