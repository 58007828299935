import {
  ArrowsLeftRight,
  ChartPieSlice,
  CirclesThreePlus,
  Coins,
  CreditCard,
  Gift,
  HourglassHigh,
  House,
  IdentificationCard,
  Invoice,
  NewspaperClipping,
  Paperclip,
  Receipt,
  ShieldPlus,
  UsersThree,
} from '@phosphor-icons/react'
import MetisMenu from 'metismenujs'
import React, { useEffect } from 'react'
import { Button as RAButton, TooltipTrigger } from 'react-aria-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { cn } from 'ui'
import FEATURE_FLAGS from '../../config/feature-flags'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getContractList } from '../../services/api'
import { updateContract } from '../../store/contract/actions'
import {
  SB_INSURANCE,
  SB_PERKS,
  SB_REFERS,
} from '../../utils/cookies/cookie-names'
import { setNewBadge, showNewBadge } from '../../utils/cookies/sidebar'
import CreateContractBtn, {
  mainSidebarActionLabel,
} from '../Common/create-contract-btn'
import { EntitiesMenu } from '../entities-menu'
import { TooltipV2 } from './tooltip-v2'

function iconDefaultProps({ isCurrentPath }) {
  return {
    weight: isCurrentPath ? 'fill' : null,
    color: isCurrentPath ? 'var(--primary)' : null,
    size: '100%',
  }
}

function links({
  isEmployee,
  isDeEmployee,
  isCompany,
  isContractor,
  hasAccess,
  t,
  storeContract,
  profile,
}) {
  const showReports =
    FEATURE_FLAGS.REMOTEPASS_REPORTS_PAGE &&
    isCompany &&
    hasAccess(permissions.viewDashboardGraph)

  const showDocuments =
    ((hasAccess(permissions.ViewCompaniesDocuments) &&
      (isCompany ? FEATURE_FLAGS.DOCUMENTS_BG_CHECK : true)) ||
      isContractor) &&
    !isEmployee

  const showBillPayments =
    FEATURE_FLAGS.BILL_PAYMENTS &&
    isCompany &&
    hasAccess([
      permissions.ViewAllBills,
      permissions.ViewOwnBills,
      permissions.ManageVendors,
    ]) &&
    profile.company?.is_bill_enabled

  return [
    {
      show: true,
      label: isEmployee || isDeEmployee ? 'Overview' : 'Activity',
      to: '/activity',
      highlighted: ['/payment', '/upcoming-payment'],
      getIcon: (isCurrentPath) => (
        <House {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show:
        (hasAccess(permissions.ViewContracts) || isContractor) &&
        !isEmployee &&
        !isDeEmployee,
      label: 'Contracts',
      to: '/contracts',
      highlighted: ['/contract'],
      getIcon: (isCurrentPath) =>
        isCompany ? (
          <UsersThree {...iconDefaultProps({ isCurrentPath })} />
        ) : (
          <IdentificationCard {...iconDefaultProps({ isCurrentPath })} />
        ),
    },
    {
      show: isDeEmployee,
      label: 'Contract',
      highlighted: ['/contract'],
      to: storeContract?.ref
        ? '/contract/detail?id=' + storeContract?.ref
        : '/contracts',
      getIcon: (isCurrentPath) => (
        <IdentificationCard {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show:
        (hasAccess(permissions.ViewInvoices) || isContractor) &&
        !isEmployee &&
        !isDeEmployee,
      label: 'Invoices',
      to: '/invoices',
      getIcon: (isCurrentPath) => (
        <Receipt {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: showBillPayments,
      label: 'Bill pay',
      to: '/bills',
      getIcon: (isCurrentPath) => (
        <Invoice {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: showReports,
      label: 'Reports',
      to: '/reports',
      getIcon: (isCurrentPath) => (
        <ChartPieSlice {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: !isCompany && FEATURE_FLAGS.SIDEBAR_LINK_EXPENSES,
      label: t('Expenses'),
      to: '/expenses',
      getIcon: (isCurrentPath) => (
        <Coins {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: !isCompany && FEATURE_FLAGS.SIDEBAR_LINK_TIME_OFF,
      label: t('Time Off'),
      to: '/time-off',
      getIcon: (isCurrentPath) => (
        <HourglassHigh {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: showDocuments,
      label: 'Documents',
      to: '/documents',
      getIcon: (isCurrentPath) => (
        <Paperclip {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: isDeEmployee || isEmployee,
      label: 'Payslips',
      to: '/payslips',
      getIcon: (isCurrentPath) => (
        <NewspaperClipping {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: isEmployee,
      label: 'My documents',
      to: '/my-documents',
      getIcon: (isCurrentPath) => (
        <Paperclip {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show:
        (hasAccess(permissions.ViewTransactions) || isContractor) &&
        !isEmployee,
      label: 'Transactions',
      to: '/transactions',
      getIcon: (isCurrentPath) => (
        <ArrowsLeftRight {...iconDefaultProps({ isCurrentPath })} />
      ),
    },
    {
      show: isContractor && !isEmployee && !isDeEmployee,
      label: 'Insurance',
      to: '/insurance',
      getIcon: (isCurrentPath) => (
        <ShieldPlus {...iconDefaultProps({ isCurrentPath })} />
      ),
      newBadgeCookie: SB_INSURANCE,
    },
    {
      show:
        FEATURE_FLAGS.REMOTEPASS_CARDS &&
        isContractor &&
        !isEmployee &&
        !isDeEmployee,
      label: 'Cards',
      to: '/cards',
      getIcon: (isCurrentPath) => (
        <CreditCard {...iconDefaultProps({ isCurrentPath })} />
      ),
      betaBadge: true,
    },
    {
      show: FEATURE_FLAGS.REMOTEPASS_PERKS && isCompany,
      label: 'Perks',
      to: '/perks',
      getIcon: (isCurrentPath) => (
        <CirclesThreePlus {...iconDefaultProps({ isCurrentPath })} />
      ),
      newBadgeCookie: SB_PERKS,
    },
    {
      show: FEATURE_FLAGS.REMOTEPASS_REFERRALS,
      label: 'Refer & Earn',
      to: '/referrals',
      getIcon: (isCurrentPath) => (
        <Gift {...iconDefaultProps({ isCurrentPath })} />
      ),
      newBadgeCookie: SB_REFERS,
    },
  ]
}

const menuListId = 'side-menu-list'

export function UserSidebarContent({ closeBurger }) {
  const user = useSelector((state) => state.Account?.user)
  const userType = user?.type

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const isCompany = userType === userTypes.COMPANY

  const dispatch = useDispatch()

  useFetch(
    {
      action: getContractList,
      autoFetch: isDeEmployee,
      onComplete: (data) => {
        let contract = data

        if (isDeEmployee) {
          contract = data?.[0]
          dispatch(updateContract(contract))
        }
      },
    },
    [],
  )

  const location = useLocation()
  const pathName = location.pathname

  useEffect(() => {
    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#' + menuListId)
      let matchingMenuItem = null
      const ul = document.getElementById(menuListId)
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [pathName])

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add('mm-active')
          }
        }
      }
      return false
    }
    return false
  }

  return (
    <>
      <div className='tw-relative tw-flex tw-flex-col tw-overflow-auto md:tw-h-[calc(100vh-var(--header-height))]'>
        <div className='tw-group tw-relative tw-hidden tw-self-center tw-p-4 md:tw-block'>
          <TooltipTrigger>
            <CreateContractBtn
              containerClassName='tw-relative'
              className='tw-transition-all hover:tw-scale-110'
              tag={RAButton}
              isSideBar
            />
            <TooltipV2>{mainSidebarActionLabel({ isEmployee })}</TooltipV2>
          </TooltipTrigger>
        </div>

        <div className='tw-mx-2'>
          <SidebarLinks closeBurger={closeBurger} className='tw-gap-3' />
        </div>

        {isCompany && (
          <div className='tw-mt-auto tw-hidden tw-p-4 md:tw-block'>
            <EntitiesMenu />
          </div>
        )}
      </div>
    </>
  )
}

export function SidebarLinks({ closeBurger, className }) {
  const user = useSelector((state) => state.Account?.user)
  const userType = user?.type

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const isCompany = userType === userTypes.COMPANY
  const isContractor = userType === userTypes.CONTRACTOR

  const { hasAccess } = usePermissions()

  const storeContract = useSelector((state) => state?.Contract?.details)

  const { t } = useTranslation()

  const history = useHistory()
  const location = history.location
  const pathName = location.pathname

  return (
    <ul
      className={cn('!tw-mb-0 tw-flex tw-flex-col', className)}
      id={menuListId}
    >
      {links({
        isEmployee,
        isDeEmployee,
        isCompany,
        isContractor,
        profile: userProfile,
        hasAccess,
        t,
        storeContract,
      }).map(
        (
          { label, to, getIcon, highlighted, show, newBadgeCookie, betaBadge },
          index,
        ) => {
          const isCurrentPath =
            pathName.startsWith(to) ||
            (highlighted &&
              highlighted.filter((p) => pathName.startsWith(p)).length > 0)
          const icon = getIcon(isCurrentPath)

          if (!show) return null

          const showNewItemBadge =
            newBadgeCookie && showNewBadge(newBadgeCookie, user?.id)

          return (
            <li key={index}>
              <TooltipTrigger>
                <RAButton
                  onClick={() => {
                    if (closeBurger) closeBurger()

                    if (showNewItemBadge) {
                      setNewBadge(newBadgeCookie, user?.id)
                    }
                  }}
                  className='tw-w-full'
                >
                  <Link
                    to={to}
                    className={cn(
                      'tw-relative tw-flex tw-w-full tw-items-center tw-rounded tw-py-4 tw-transition-all tw-duration-75 max-lg:tw-gap-2 max-lg:tw-px-4 lg:tw-flex-col lg:tw-justify-center lg:tw-py-2',
                      isCurrentPath
                        ? 'tw-bg-primary-20 hover:tw-cursor-default'
                        : 'tw-bg-transparent hover:tw-bg-secondary-20',
                    )}
                  >
                    <div
                      className={cn(
                        'tw-relative tw-flex tw-size-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-text-secondary-80',
                        !isCurrentPath && 'group-hover:tw-text-secondary-90',
                      )}
                    >
                      {betaBadge || showNewItemBadge ? (
                        <div className='tw-absolute tw-end-0 tw-top-0 tw-size-1 tw-rounded-full tw-bg-primary-100 tw-ring-1 tw-ring-white'></div>
                      ) : null}

                      {icon}
                    </div>

                    <span
                      className={cn(
                        'tw-text-center tw-font-semibold tw-transition-all tw-duration-75 lg:tw-text-[10px]',
                        isCurrentPath
                          ? 'tw-text-black'
                          : 'tw-text-text-60 group-hover:tw-text-secondary-100',
                      )}
                    >
                      {label}
                    </span>
                  </Link>
                </RAButton>

                <TooltipV2 className='tw-hidden lg:tw-block'>
                  {label}
                  {betaBadge ? ' (Beta)' : showNewItemBadge ? ' (New)' : null}
                </TooltipV2>
              </TooltipTrigger>
            </li>
          )
        },
      )}
    </ul>
  )
}
