import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Shimmer from '../../../../../components/ui/shimmer'

function RequestLoading() {
  return (
    <div className='page-content'>
      <Container fluid>
        <Row className='tw-mb-6'>
          <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
        </Row>
        <Row>
          <Col sm={4}>
            <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
            <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
            <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
          </Col>
          <Col sm={8}>
            <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
            <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
            <Shimmer className='tw-my-4 !tw-w-full tw-flex-grow' />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RequestLoading
