import React, { useState, useCallback } from 'react'
import { Col, Row } from 'reactstrap'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
export const EmployerTaxView = ({ quote, isMonthly, customerCurrencyCode }) => {
  const employer = quote?.employer

  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = (e) => {
    e.preventDefault()
    setShowDetails(!showDetails)
  }

  const formatter = useCallback(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'code',
        minimumFractionDigits: 0,
        currency: customerCurrencyCode || 'USD',
      }),
    [customerCurrencyCode],
  )

  return (
    <Row className='text-sm md:text-base group relative hover:shadow-lg'>
      <Row className='p-0 m-0 w-100 justify-content-center mb-4'>
        <Col className='px-0'>
          <div className='bg-primary-20 border border-primary rounded p-4'>
            <div className='justify-content-center d-flex text-secondary mb-2'>
              Total {isMonthly ? 'monthly' : 'annual'} cost of employment
            </div>
            <h6 className='justify-content-center d-flex rp-font-bold font-size-24 text-primary'>
              {formatter().format(
                isMonthly ? employer?.cost?.monthly : employer?.cost?.annual,
              )}
            </h6>
          </div>
        </Col>
      </Row>
      <div className='d-flex flex-column mb-4 p-4 border rounded w-100 font-size-14'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <span className='rp-font-light'>
            Gross {isMonthly ? 'monthly' : 'annual'} salary costs
          </span>
          <span className='rp-font-medium'>
            {formatter().format(
              isMonthly ? employer?.gross?.monthly : employer?.gross?.annual,
            )}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <span className='rp-font-light'>
            Estimated taxes &amp; contributions
          </span>
          <span className='rp-font-medium'>
            {formatter().format(
              isMonthly
                ? employer?.total_taxes?.monthly
                : employer?.total_taxes?.annual,
            )}
          </span>
        </div>
        {showDetails && (
          <ul className='mb-4 pl-3 font-size-14 text-text-80 font-weight-light'>
            {employer?.taxes?.map((taxItem) => (
              <li
                className='pt-2 pb-2 pl-0 pr-0'
                key={`${Object.keys(taxItem)[0]}`}
              >
                <div
                  className='d-flex align-items-center justify-content-center'
                  key={`${Object.keys(taxItem)[0]}`}
                >
                  <div style={{ flex: 1 }}>{Object.keys(taxItem)[0]} </div>
                  <div>
                    {formatter().format(
                      isMonthly
                        ? Object.values(taxItem)[0]?.monthly
                        : Object.values(taxItem)[0]?.annual,
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}

        {!showDetails && <div className='border-top w-100 h-1 mb-4 mt-4'></div>}

        <button
          className='d-flex align-items-center justify-content-center font-size-14 text-primary font-weight-medium cursor-pointer bg-white border-0'
          onClick={toggleDetails}
        >
          Show {showDetails ? 'Less' : 'More'}
          {showDetails ? (
            <CaretUp className='ml-1' size={20} />
          ) : (
            <CaretDown className='ml-1' size={20} />
          )}
        </button>
      </div>
    </Row>
  )
}

export default EmployerTaxView
