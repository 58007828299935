import cx from 'classnames'
import React from 'react'

export default function Footer({ className, style }) {
  return (
    <footer className={cx('tw-text-text-60', className)} style={style}>
      © {new Date().getFullYear()} RemotePass.
    </footer>
  )
}
