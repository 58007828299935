import React from 'react'

class ModalLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div>{this.props.children}</div>
      </React.Fragment>
    )
  }
}

ModalLayout.propTypes = {}

export default ModalLayout
