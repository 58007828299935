import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import SearchBar from '../../components/SearchBar'
import Button from '../../components/ui/button'
import Flag from '../../components/ui/flag'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { getContractDetail, updateOrgChartContract } from '../../services/api'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import {
  ControlledContractSelections,
  ManageOrgChartFields,
} from './missing-managers'
import { UserProfile } from './organization-chart'
import { ArrowLeft } from '@phosphor-icons/react'
import { PermissionTooltip } from '../../components/permission-tooltip'
import permissions from '../../helpers/permissions'
import ContractRef from '../AdminPanel/components/ContractRef'

const searchWorkersFormId = 'search-workers-form'
export function SearchWorkers({
  isOpen,
  toggleSidebar,
  allContracts,
  onSuccess,
  openSpecificContract,
  isManagerView,
  findNode,
}) {
  const [search, setSearch] = useState('')

  const { hasAccess } = usePermissions()
  const hasPermission = hasAccess(permissions.ManageOrgChart)

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contractId: openSpecificContract
        ? parseInt(openSpecificContract)
        : undefined,
    },
  })
  const selectedContractId = useWatch({ control, name: 'contractId' })

  const { data: contractDetails, isLoading: gettingContractDetails } = useFetch(
    {
      action: getContractDetail,
      autoFetch: !!selectedContractId,
      body: { id: selectedContractId },
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Failed to fetch contract details')
        } else {
          setValue('managerId', data?.manager_contract_id)
          setValue('departmentId', data?.department_id)
          setValue('isHead', data?.is_head_of_department ? 'yes' : 'no')
        }
      },
    },
    [selectedContractId],
  )
  const worker = contractDetails?.employee ?? contractDetails?.contractor

  const filteredContracts = allContracts.filter((contract) => {
    return contract.name.toLowerCase().includes(search.toLowerCase())
  })

  const selectedContract = allContracts.find(
    (contract) => contract.id === selectedContractId,
  )

  const { startFetch: updateManager, isLoading: isUpdatingManager } = useFetch({
    action: updateOrgChartContract,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to assign manager')
      } else {
        toastr.success('Manager was updated successfully')
        toggleSidebar()
        onSuccess?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  function unAssignManager() {
    updateManager({
      contract_id: selectedContractId,
      manager_contract_id: null,
    })
  }

  function onSubmit(data) {
    const body = {
      contract_id: data.contractId,
      manager_contract_id: data.managerId,
      department_id: data.departmentId || undefined,
      is_head_of_department: data.isHead === 'yes',
    }
    updateManager(body)
  }

  return (
    <SideMenu
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName={cn(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
        selectedContractId
          ? 'tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
          : 'tw-grid-rows-[minmax(auto,max-content)_1fr]',
      )}
      isOpen={isOpen}
      onClose={toggleSidebar}
    >
      <SideMenuHeader toggle={toggleSidebar} className='tw-items-start'>
        <div className='tw-flex-grow'>
          <div className='tw-flex tw-items-start tw-gap-2'>
            {selectedContract ? null : (
              <div className='tw-flex tw-flex-col'>
                <div className='tw-font-semibold'>Find a member</div>
              </div>
            )}
          </div>
          {!selectedContract ? null : (
            <div className='tw-flex tw-items-center tw-gap-4'>
              {openSpecificContract ? null : (
                <Button
                  color='link'
                  size='sm'
                  className='!tw-p-0.5 !tw-text-secondary-100'
                  type='button'
                  onClick={() => {
                    setValue('contractId', null)
                    setValue('managerId', null)
                    setValue('departmentId', null)
                  }}
                  icon={
                    <ArrowLeft
                      size={20}
                      className='tw-flex-shrink-0'
                      weight='bold'
                    />
                  }
                />
              )}

              <Avatar
                name={selectedContract.name}
                photo={selectedContract.photo}
                size='lg'
                customFlag={
                  !selectedContract.flag ? null : (
                    <Flag
                      url={selectedContract.flag}
                      className='tw-absolute -tw-left-1.5 -tw-top-1.5 tw-block !tw-h-5 !tw-w-5 tw-rounded-full tw-border-2 tw-border-solid tw-border-white tw-object-cover'
                    />
                  )
                }
              />
              <div className='tw-flex tw-flex-col'>
                <div className='!tw-text-xl'>{selectedContract.name}</div>
                <div className='!tw-text-xs tw-font-light tw-text-primary'>
                  <ContractRef
                    withHash
                    contractId={selectedContract.contract_ref}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </SideMenuHeader>
      <SideMenuBody className='tw-flex-grow !tw-px-0'>
        <form onSubmit={handleSubmit(onSubmit)} id={searchWorkersFormId}>
          {!selectedContract ? (
            <>
              <div className='tw-px-6'>
                <SearchBar query={search} onQueryChanged={setSearch} />
              </div>

              <ControlledContractSelections
                control={control}
                name='contractId'
                isSearch
                findNode={findNode}
                options={filteredContracts}
              />
            </>
          ) : (
            <>
              <h4 className='tw-z-[1] tw-mb-1 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-bold'>
                <span>About</span>
              </h4>

              <DetailsInfoList
                className='-tw-my-6'
                items={[
                  { label: 'Email', value: worker?.email },
                  { label: 'Phone', value: worker?.phone },
                  {
                    label: 'Country of residence',
                    value: (
                      <div className='tw-flex tw-items-center tw-gap-2'>
                        {worker?.country?.name} <Flag url={worker?.flag} />
                      </div>
                    ),
                  },
                ]}
              />

              <hr className='tw-my-8' />

              <h4 className='tw-z-[1] tw-mb-1 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-bold'>
                <span>Contract details</span>
              </h4>

              <DetailsInfoList
                className='-tw-my-6'
                items={[
                  { label: 'Role', value: contractDetails?.name },
                  {
                    label: 'Start date',
                    value: contractDetails?.start_date,
                  },
                  { label: 'Contract type', value: contractDetails?.type },
                ]}
              />

              <hr className='tw-my-8' />
              <h4 className='tw-z-[1] tw-mb-3 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-bold'>
                <span>Organization</span>
              </h4>
              <div className='tw-px-6'>
                <ManageOrgChartFields
                  control={control}
                  errors={errors}
                  disabled={!hasPermission}
                  contractId={selectedContractId}
                />
              </div>

              <hr className='tw-my-8' />

              <h4 className='tw-z-[1] tw-mb-1 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-bold'>
                <span>Direct reports</span>
              </h4>

              <div className='tw-mt-3 tw-flex tw-flex-col tw-gap-4 tw-px-6'>
                {!contractDetails?.managed_contracts ||
                contractDetails.managed_contracts.length <= 0
                  ? 'No direct reports'
                  : contractDetails?.managed_contracts?.map(
                      ({ name, id, title, photo, flag }) => {
                        return (
                          <div key={id}>
                            <UserProfile
                              data={{
                                name,
                                role: title,
                                flag,
                                photo,
                                handless: true,
                                className: 'tw-w-auto',
                              }}
                            />
                          </div>
                        )
                      },
                    )}
              </div>
            </>
          )}
        </form>
      </SideMenuBody>
      {!selectedContract ? null : (
        <SideMenuFooter>
          <div className='tw-flex tw-w-full tw-grow tw-items-center tw-justify-between'>
            <div>
              {isManagerView &&
              selectedContract &&
              selectedContract.manager_contract_id !== null ? (
                <PermissionTooltip
                  showing={!hasPermission}
                  id='un-assign-btn-tooltip'
                  customMessage='You do not have permission to manage org chart'
                  area={permissions.ManageOrgChart}
                >
                  <Button
                    type='button'
                    color='transparent'
                    textClassName='tw-text-red'
                    onClick={unAssignManager}
                    disabled={
                      isUpdatingManager ||
                      !hasPermission ||
                      gettingContractDetails
                    }
                  >
                    Unassign
                  </Button>
                </PermissionTooltip>
              ) : null}
            </div>
            <div className='tw-flex tw-items-center tw-gap-3'>
              <Button
                type='button'
                color='light'
                outline
                onClick={toggleSidebar}
                disabled={isUpdatingManager || gettingContractDetails}
              >
                Cancel
              </Button>
              <PermissionTooltip
                showing={!hasPermission}
                id='save-manager-btn-tooltip'
                customMessage='You do not have permission to manage org chart'
                area={permissions.ManageOrgChart}
              >
                <Button
                  type='submit'
                  formId={searchWorkersFormId}
                  loading={isUpdatingManager}
                  disabled={
                    isUpdatingManager ||
                    !hasPermission ||
                    gettingContractDetails
                  }
                >
                  Save
                </Button>
              </PermissionTooltip>
            </div>
          </div>
        </SideMenuFooter>
      )}
    </SideMenu>
  )
}
