import React from 'react'
import { Avatar } from 'ui'

import { getFullName } from '../../utils/get-full-name'

export default function ContractorDisplay({ contractor }) {
  return (
    <div className='align-items-center flex-nowrap d-flex gap-12'>
      <Avatar
        photo={contractor?.photo}
        name={contractor?.first_name}
        flag={contractor?.flag}
      />
      <div className='rp-font-bold'>{getFullName(contractor)}</div>
    </div>
  )
}
