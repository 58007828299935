import React, { createContext, useState } from 'react'

const bulkOperationsContext = createContext({
  operationsFormData: null,
  setOperationsFormData: () => {},
})

export function BulkOperationsContextProvider({ children, defaultData }) {
  const [operationsFormData, setOperationsFormData] = useState(defaultData)

  function handleSetData(data) {
    setOperationsFormData((prevData) => ({ ...prevData, ...data }))
  }

  return (
    <bulkOperationsContext.Provider
      value={{ operationsFormData, setOperationsFormData: handleSetData }}
    >
      {children}
    </bulkOperationsContext.Provider>
  )
}

export function useBulkOperationsFlowContext() {
  return React.useContext(bulkOperationsContext)
}
