import React from 'react'
import { StyledH5 } from '../../../../components/Typo'
import { Card, Col, Row } from 'reactstrap'
import XeroAccountMapping from '../XeroAccountMapping'
import { useDispatch } from 'react-redux'
import { submitAccountMappingAndUpdateRequest } from '../../../../store/xero/actions'

const AccountMappingTab = ({ loading, onSubmitted }) => {
  const dispatch = useDispatch()

  return (
    <Card>
      <Col className='pb-4'>
        <div className='mb-1 mt-4'>
          <StyledH5 className='font-size-16 rp-font-bold'>
            Select the xero account to use for each bill
          </StyledH5>
        </div>
        <Row className='align-items-center w-100 p-0 m-0'>
          <p className='text-muted'>
            Remotepass categories on the left will be posted to the Xero
            accounts on the right
          </p>
        </Row>
        <XeroAccountMapping
          isUpdate
          context='tab'
          onSubmitted={(values) => {
            dispatch(submitAccountMappingAndUpdateRequest(values))
            onSubmitted()
          }}
        />
        <Row className='justify-content-end p-0 m-0 mt-4'>
          <button
            onClick={() => {
              document.getElementById('submit-xero-account-mapping-tab').click()
            }}
            className='btn btn-primary'
          >
            {loading && <i className='bx bx-loader bx-spin' />} Save
          </button>
        </Row>
      </Col>
    </Card>
  )
}

export default AccountMappingTab
