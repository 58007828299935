import {
  Archive,
  CalendarCheck,
  Eye,
  FileArrowUp,
  PencilSimple,
  UserCircle,
  Warning,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toastr from 'toastr'

import { ActionsDropdown, cn } from 'ui'
import Alert from '../../../../components/ui/alert'
import Loader from '../../../../components/ui/loader'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import {
  archivePolicy,
  getTimeOffPolicies,
  unArchivePolicy,
} from '../../../../services/api-time-off-policies'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import {
  PolicyTypeIcon,
  policyTypeIconColors,
} from '../../../new-time-off-policy'
import { AddTimeOffPolicy } from '../manage-time-off-policy'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { ManagePolicyWorkers } from './manage-policy-workers'
import { EditAction, ViewPolicy } from './view-policy'
import { PageNav, useActiveTab } from '../../../../components/page-nav'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import Button from '../../../../components/ui/button'
import { DEFAULT_TIME_OFF_ID } from '../../../Contract/utils/constants'

const TABS = { ACTIVE: 'active', ARCHIVE: 'archive' }
const tabsData = [
  {
    label: 'Active policies',
    key: TABS.ACTIVE,
  },
  {
    label: 'Archive',
    key: TABS.ARCHIVE,
  },
]

function TabHeader({ activeTab }) {
  return (
    <PageNav className='!tw-mb-4 !tw-flex-wrap tw-rounded tw-bg-white'>
      {tabsData.map((data) => (
        <PageNav.Item key={data.key}>
          <PageNav.Link
            to={`/settings/time-off-policies?tab=${data.key}`}
            tag={Link}
            isActive={activeTab === data.key}
            className='!tw-flex tw-items-center tw-gap-2'
          >
            {data.label}
          </PageNav.Link>
        </PageNav.Item>
      ))}
    </PageNav>
  )
}

export function TimeOffPoliciesTab() {
  const { hasAccess } = usePermissions()
  const canManageCompanySettings = hasAccess(permissions.manageCompanySettings)
  const { activeTab } = useActiveTab({ defaultTab: TABS.ACTIVE })

  return (
    <div className='tw-p-6'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/9573740-default-time-off-time-off-policies#h_9ec797822e'
            learnMoreTitle='Learn more about time off policies'
          >
            Time Off Policies
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            Create customized time off policies and assign them to your workers
          </SettingSectionSubHeading>
        </div>

        <AddTimeOffPolicy hasPermission={canManageCompanySettings} />
      </div>

      <TabHeader activeTab={activeTab} />
      <div className='tw-mt-4'>
        <TimeOffPolicyList activeTab={activeTab} />
      </div>
    </div>
  )
}

function TimeOffPolicyList({ activeTab }) {
  const {
    data: policies,
    isLoading: policiesLoading,
    completed: policiesCompleted,
    startFetch: refetchPolicies,
    error: policiesError,
  } = useFetch(
    {
      action: getTimeOffPolicies,
      autoFetch: true,
      body: { archived: activeTab === TABS.ACTIVE ? '0' : '1' },
    },
    [activeTab],
  )

  const isEmpty = policies?.length <= 0

  const groupedPolicies = policies
    ?.reduce((acc, policy) => {
      const policyTypeId = policy?.type?.id

      const prevPolicies = acc.map((p) => p.type.id)

      if (prevPolicies.includes(policyTypeId)) {
        const foundIndex = acc.findIndex((p) => p.type.id === policyTypeId)
        acc[foundIndex].policies.push(policy)
      } else {
        acc.push({
          type: policy?.type,
          policies: [policy],
        })
      }

      return acc
    }, [])
    .sort((a, b) => a.type.order - b.type.order)

  return (
    <>
      {policiesError ? (
        <Alert color='danger' className='tw-mt-6' innerTag='div'>
          <h6 className='tw-mb-2 tw-text-sm tw-font-bold tw-text-current'>
            Something went wrong while loading the time off policies
          </h6>
          <code className='tw-mb-0 tw-bg-systemRed-100/10 tw-text-current'>
            {policiesError}
          </code>
        </Alert>
      ) : policiesLoading || !policiesCompleted ? (
        <Loader minHeight='max(50vh, 550px)' />
      ) : isEmpty ? (
        <TabEmpty
          title={
            activeTab === TABS.ACTIVE
              ? 'No time off policy'
              : 'No archived Policies to show'
          }
          subtitle={
            activeTab === TABS.ACTIVE
              ? 'Time off policies will be listed here'
              : 'Archived policies will be shown here'
          }
          icon={
            activeTab === TABS.ACTIVE ? (
              <CalendarCheck
                size={250}
                weight='duotone'
                className='tw-text-primary'
              />
            ) : (
              <Archive
                size={250}
                weight='duotone'
                className='tw-text-primary-100'
              />
            )
          }
        />
      ) : (
        <div className='tw-mt-6'>
          {groupedPolicies?.map((group) => (
            <PolicyGroup
              key={group.type.id}
              title={group.type.name}
              policies={group.policies}
              refetchPolicies={refetchPolicies}
              companyPolicies={policies}
              onArchive={refetchPolicies}
              activeTab={activeTab}
            />
          ))}
        </div>
      )}
    </>
  )
}

function UnArchiveButton({ onSuccess, policy }) {
  const [showUnarchive, setShowUnarchive] = useState(false)

  function toggleShowUnArchive() {
    setShowUnarchive((showUnarchive) => !showUnarchive)
  }

  const { isLoading: isUnArchiving, startFetch: _unArchivePolicy } = useFetch({
    action: unArchivePolicy,
    onComplete: () => {
      toastr.success('Policy unarchived successfully')
      onSuccess()
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred while unarchiving policy')
    },
  })

  return (
    <>
      <Button
        icon={<FileArrowUp size={20} />}
        outline
        className='tw-mt-4 tw-w-full !tw-text-primary hover:!tw-bg-white'
        type='button'
        onClick={toggleShowUnArchive}
      >
        Unarchive
      </Button>

      <ConfirmationModal
        isOpen={showUnarchive}
        toggle={toggleShowUnArchive}
        negativeCaption='No, close'
        caption='Yes, Unarchive'
        onConfirm={() => _unArchivePolicy(policy.id)}
        confirmLoading={isUnArchiving}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemGold-100' />
              <ModalCloseButton toggle={toggleShowUnArchive} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to unarchive this policy?
            </h4>
          </>
        }
      />
    </>
  )
}

function PolicyGroup({
  title,
  policies,
  refetchPolicies,
  companyPolicies,
  onArchive,
  activeTab,
}) {
  const [showDetails, setShowDetails] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showArchive, setShowArchive] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState({})

  function toggleShowDetails() {
    setShowDetails((open) => !open)
  }

  function toggleShowEdit() {
    setShowEdit((showEdit) => !showEdit)
  }

  function toggleShowArchive() {
    setShowArchive((showArchive) => !showArchive)
  }

  const { isLoading: isArchiving, startFetch: _archivePolicy } = useFetch({
    action: archivePolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data.message || 'An error occurred while archiving policy')
      } else {
        toastr.success('Policy archived successfully')
        onArchive()
      }
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred while archiving policy')
    },
  })

  return (
    <div className='tw-border-t tw-border-surface-30 tw-py-6 first:tw-border-t-0'>
      <SettingSectionHeading>{title}</SettingSectionHeading>

      <div className='tw-mt-4 tw-grid tw-gap-3 md:tw-grid-cols-3 xl:tw-grid-cols-4'>
        {policies?.map((item) => {
          const contractCount = item?.contracts ? item.contracts.length : 0
          const isDefaultPolicy = item.id === DEFAULT_TIME_OFF_ID
          return (
            <div
              key={item.id}
              className='tw-rounded tw-border tw-border-surface-30 tw-p-4'
            >
              <div className='tw-flex tw-flex-wrap tw-justify-between tw-gap-1'>
                <div
                  className={cn(
                    'tw-rounded tw-bg-systemGold-20 tw-p-2 tw-text-systemGold-110',
                    policyTypeIconColors({ typeId: item?.type?.id }),
                  )}
                >
                  <PolicyTypeIcon typeId={item?.type?.id} size={24} />
                </div>

                <ActionsDropdown
                  data={[
                    {
                      label: 'View details',
                      icon: <Eye />,
                      onClick: () => {
                        toggleShowDetails()
                        setSelectedPolicy(item)
                      },
                    },
                    {
                      label: 'Edit policy',
                      icon: <PencilSimple />,
                      onClick: () => {
                        toggleShowEdit()
                        setSelectedPolicy(item)
                      },
                    },
                    activeTab === TABS.ACTIVE &&
                      contractCount === 0 &&
                      !isDefaultPolicy && {
                        label: 'Archive',
                        icon: <Archive />,
                        onClick: () => {
                          toggleShowArchive()
                          setSelectedPolicy(item)
                        },
                      },
                  ].filter(Boolean)}
                />
              </div>

              <h4 className='tw-mb-0.5 tw-mt-4 tw-text-sm tw-font-bold'>
                {item.name}
              </h4>
              <p className='tw-mb-1 tw-text-xs tw-font-medium tw-text-text-80'>
                {item.type?.is_accrued === 1
                  ? 'Accrued time off balance'
                  : 'Flexible policy'}
              </p>

              <div className='tw-mt-2 tw-flex tw-items-center tw-gap-2'>
                <UserCircle size={16} className='tw-text-secondary-100' />
                <div className='tw-text-xs tw-font-semibold tw-text-text-80'>
                  {contractCount} worker{contractCount === 1 ? '' : 's'}{' '}
                  assigned
                </div>
              </div>

              {activeTab === TABS.ACTIVE ? (
                <ManagePolicyWorkers
                  policy={item}
                  onSuccess={refetchPolicies}
                  companyPolicies={companyPolicies}
                />
              ) : (
                <UnArchiveButton onSuccess={refetchPolicies} policy={item} />
              )}
            </div>
          )
        })}
      </div>

      <ViewPolicy
        policy={selectedPolicy}
        refetchPolicies={refetchPolicies}
        toggleMenu={toggleShowDetails}
        isOpen={showDetails}
      />

      <EditAction
        policy={selectedPolicy}
        isOpen={showEdit}
        toggle={toggleShowEdit}
      />

      <ConfirmationModal
        isOpen={showArchive}
        toggle={toggleShowArchive}
        negativeCaption='No, close'
        caption='Yes, Archive'
        onConfirm={() => _archivePolicy(selectedPolicy.id)}
        confirmLoading={isArchiving}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemGold-100' />
              <ModalCloseButton toggle={toggleShowArchive} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to archive this policy?
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              You won’t be able to assign workers to this policy. Archived
              policies can be found in the Archive tab.
            </p>
          </>
        }
      />
    </div>
  )
}
