import cx from 'classnames'
import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'

const SimulatorReview = ({ data }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.base_currency || 'USD',
  })
  const withdrawFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.withdraw_currency || 'USD',
  })

  const info = [
    { title: 'Amount', value: formatter.format(data?.base_amount) },
    { title: 'Transfer Fee', value: formatter.format(data?.base_fee) },
    {
      title: 'You will receive',
      value: withdrawFormatter.format(data?.withdraw_amount),
      textMuted: false,
    },
    { title: 'Should arrive', value: data?.should_arrive },
    { title: 'Provider', value: data?.provider },
  ]

  return (
    <Container
      fluid
      className='d-flex flex-column pt-3 pb-4'
      style={{ gap: '1rem' }}
    >
      <Row>
        <Col
          md={6}
          sm={12}
          className='d-flex flex-column'
          style={{ gap: '1rem' }}
        >
          {info.map((item, index) => {
            return <InfoDisplay key={index} {...item} />
          })}
        </Col>
      </Row>
      <Row>
        <Col>
          <CopyToClipboard text={getText(info)} />
        </Col>
      </Row>
    </Container>
  )
}

function InfoDisplay({ title, value, textMuted = true }) {
  return (
    <div
      className={cx('d-flex justify-content-between', {
        'text-muted': textMuted,
      })}
    >
      <p className='mb-0'>{title}</p>
      <p className='mb-0'>{value}</p>
    </div>
  )
}

function getText(data) {
  const text = data.map((item) => `${item.title}: ${item.value}`)

  return [
    `Here is a withdraw to bank simulation for your review:`,
    ...text,
  ].join('\n  - ')
}

function CopyToClipboard({ text, onCopied = () => {} }) {
  const [copied, setCopied] = useState(false)

  async function copy() {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    onCopied()
  }

  return (
    <button
      className='rp-btn-nostyle text-primary bg-soft-primary rounded'
      onClick={copy}
      type='button'
    >
      {copied ? 'Copied' : 'Copy to clipboard'}
    </button>
  )
}

export default SimulatorReview
