import { yupResolver } from '@hookform/resolvers/yup'
import { XCircle } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { completeContractorEntity } from '../../components/ActionCenter'
import ControlledDropzoneInput from '../../components/controlled-dropzone-input'
import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import { contractorTypes, userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import {
  createEntity,
  getCompanyInfo,
  getNotificationList,
  updateCompanyInfo,
} from '../../services/api'
import {
  updateContractRef,
  updateProfileCompany,
  updateUserNotifications,
} from '../../store/profile/actions'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import { switchingContractorToEntity } from '../ContractorSettings/ProfileInfoNew'
import './save-info-button.css'
import LabelContent from '../Contract/CreateContract/components/label-content'
import permissions from '../../helpers/permissions'
import { PermissionTooltip } from '../../components/permission-tooltip'
import { PERMISSION_GROUP } from './manage-role'

const schema = (countries, requireDoc, isOnboarding, addNumOfEmployees) => {
  let baseSchema = yup.object().shape({
    country_id: yup.string().required('Country is required'),
    state_id: yup.string().when('country_id', ([value]) => {
      if (countries?.find((c) => '' + c.id === value)?.states?.length) {
        return yup.string().required('State is required')
      }
    }),
    city: yup.string().required('City is required'),
    type_id: yup.string().required('Company Type is required'),
    currency_id: yup.string().required('Currency is required'),
    name: yup.string().required('Company Legal Name is required'),
    registration_no: yup.string().required('Registration Number is required'),
    vat: yup.string().nullable(),
    po_number: yup.string().nullable(),
    duns_number: yup.string().nullable(),
    tax_number: yup.string().nullable(),
    address: yup.string().required('Address is required'),
    registration_document: requireDoc
      ? yup.mixed().required('Registration Document is required')
      : yup.mixed().notRequired(),
  })

  if (isOnboarding) {
    baseSchema = baseSchema.shape({
      dba: yup.string().nullable(),
    })
  }

  if (addNumOfEmployees) {
    baseSchema = baseSchema.shape({
      nb_employees: yup
        .number()
        .required('Number of employees is required')
        .typeError('Invalid numeric value')
        .positive()
        .integer(),
    })
  }

  return baseSchema
}

function getDefaultValues(data) {
  return {
    ...data,
    country_id: data?.country?.id,
    state_id: data?.state?.id,
    type_id: data?.type?.id,
    currency_id: data?.currency?.id || 1,
    registration_document: null,
    nb_employees: data?.nb_employees,
  }
}

const CompanyInfo = React.forwardRef(function CompanyInfo(
  {
    isCompletion,
    onComplete = () => {},
    isCreation,
    countries,
    data,
    setLoading = () => {},
    isOnboarding,
    onUpdate = () => {},
  },
  ref,
) {
  const [isCompanyActive, setIsCompanyActive] = React.useState(
    data?.approved === 1,
  )

  const history = useHistory()
  const locationState = history.location.state

  const completeEntityDetails =
    locationState?.action === completeContractorEntity
  const switchContractorToEntity =
    locationState?.action === switchingContractorToEntity
  const defaultValues = getDefaultValues(data)
  const path = history?.location?.pathname
  const user = useSelector((state) => state.Account?.user)
  const staticData = useSelector((state) => state.Layout?.staticData)
  const { contractRef, userProfile } = useSelector(
    (state) => state?.userProfile,
  )
  const { contractor_type: contractorType, kyb_status: kybStatus } =
    userProfile ?? {}
  const isEntity = contractorType === contractorTypes.ENTITY

  const isKybSubmitted = kybStatus === 'submitted'
  const isKybRejected = kybStatus === 'rejected'
  const isCompanySettings = path === '/settings'
  const { hasAccess } = usePermissions()

  const canUpdateCompanyInfo = hasAccess(permissions.manageCompanySettings)
  const disabledFormFields =
    !isCreation &&
    ((isCompanyActive && !isKybRejected) ||
      isKybSubmitted ||
      !canUpdateCompanyInfo)

  const showNumberOfEmployeesInput =
    !isEntity && user?.type === userTypes.COMPANY

  const isNumberOfEmployeeRequired =
    showNumberOfEmployeesInput &&
    ((isCreation && isOnboarding) || switchContractorToEntity)

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(
      schema(
        countries,
        isCompletion || completeEntityDetails,
        isOnboarding,
        isNumberOfEmployeeRequired,
      ),
    ),
    defaultValues,
  })

  const { registration_document: registrationDoc } = useWatch({ control })

  const dispatch = useDispatch()
  const notifications = useFetch({
    autoFetch: !!user?.id,
    initResult: null,
    action: getNotificationList,
    onComplete: (data) => {
      dispatch(updateUserNotifications(data))
    },
  })

  const create = useFetch({
    action: createEntity,
    onComplete,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const edit = useFetch({
    action: updateCompanyInfo,
    onError: (err) => toastr.error(err),
    onComplete: (data) => {
      if (isOnboarding) {
        window.analytics.track('onboarding_add_company_info', {
          email_id: user?.email,
        })
        onComplete(data)
      } else {
        if (data?.success === false) {
          toastr.error(data?.message || 'Error updating company info')
        } else {
          toastr.success('Updated Successfully')
          window.analytics.track('Updated company settings', {
            email_id: user?.email,
          })
          onUpdate()
          dispatch(updateProfileCompany(data))
          if (isCompletion) {
            onComplete()
          }
          notifications.startFetch()
          if (contractRef) {
            history.push(`/contract/detail?id=${contractRef?.ref}`)
            dispatch(updateContractRef(null))
          }
        }
      }
    },
  })

  const onSubmit = ({ registration_document: registrationDocument, ...v }) => {
    if (!isCreation && !canUpdateCompanyInfo) {
      toastr.error('You do not have permissions to update company information')
      return
    }
    if (isKybSubmitted) {
      toastr.error(
        'Your KYB is submitted and under review. You cannot update company information during this time',
      )
      return
    }
    if (
      isCompanyActive &&
      !isKybRejected &&
      v?.po_number === defaultValues?.po_number &&
      v?.vat === defaultValues?.vat &&
      v?.duns_number === defaultValues?.duns_number &&
      v?.tax_number === defaultValues?.tax_number &&
      v?.currency_id === defaultValues?.currency_id
    ) {
      toastr.error(
        'Your company is already active. You cannot update company information',
      )
      return
    }
    let values = { ...v, logo: typeof v?.logo === 'string' ? null : v?.logo }
    delete values.official_logo
    delete values.cropped_official_logo

    if (isCompletion && registrationDocument?.[0]) {
      values.registration_document = registrationDocument[0]
    }

    if (!isKybRejected) {
      if (completeEntityDetails) {
        values = {
          registration_document: registrationDocument[0],
          currency_id: v.currency_id,
        }
      } else if (
        isCompanyActive &&
        v?.po_number === defaultValues?.po_number &&
        v?.vat === defaultValues?.vat &&
        v?.duns_number === defaultValues?.duns_number &&
        v?.tax_number === defaultValues?.tax_number
      ) {
        values = { currency_id: v.currency_id }
      }
    }

    if ((isCreation && isOnboarding) || !isCreation) {
      let body = { ...values }
      if (!isEntity) {
        if (data?.approved === 1) {
          body = {
            po_number: values?.po_number,
            vat: values?.vat,
            duns_number: values?.duns_number,
            tax_number: values?.tax_number,
            currency_id: values?.currency_id,
          }
        }
      } else {
        delete body.nb_employees
      }
      edit.startFetch(body)
    } else {
      create.startFetch(values)
    }
  }

  const onError = (errors) => {
    setLoading(false)

    const errEntries = Object.entries(errors)
    let errorStr = ''
    errEntries.forEach(([, value]) => {
      const msg = value?.message
      errorStr += `<p>${msg}</p>`
    })

    toastr.error(errorStr, 'Errors', { allowHtml: true })
  }

  useFetch({
    action: getCompanyInfo,
    autoFetch: completeEntityDetails,
    onComplete: (data) => {
      reset(getDefaultValues(data))
      setIsCompanyActive(data?.approved === 1)
    },
  })

  const companyInfoFormID = 'company-info-form'

  return (
    <CardBody className='px-0 pb-0 pt-3 pt-md-4'>
      <Container fluid>
        <Row>
          <Col>
            <Alert color='info' className='mb-0'>
              You cannot change these details after your account is verified.
            </Alert>
          </Col>
        </Row>
      </Container>

      <Form
        id={companyInfoFormID}
        ref={ref}
        autoComplete='off'
        className='pt-3 pt-md-4'
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Container fluid>
          <Row>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='company-name'
                  className='p-0 mb-2 font-size-14'
                >
                  <LabelContent required>Company Legal Name</LabelContent>
                </Label>
                <ControlledInput
                  control={control}
                  name='name'
                  id='company-name'
                  error={errors.name}
                  disabled={disabledFormFields}
                  translate='no'
                />
              </FormGroup>
            </Col>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label className='p-0 mb-2 font-size-14'>
                  <LabelContent required>Company Type</LabelContent>
                </Label>
                <ControlledSelect
                  name='type_id'
                  error={errors.type_id}
                  control={control}
                  options={staticData?.company_types?.map((e) => ({
                    value: e.id,
                    label: e.name,
                  }))}
                  isDisabled={disabledFormFields}
                />
              </FormGroup>
            </Col>
            {user?.type === userTypes.COMPANY && !isOnboarding && (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline m-0'
                  row={!isCompletion}
                  disabled={disabledFormFields}
                >
                  <Label htmlFor='dba' className='p-0 mb-2 font-size-14'>
                    Doing Business As (Optional)
                  </Label>
                  <ControlledInput
                    control={control}
                    name='dba'
                    id='dba'
                    error={errors.dba}
                    disabled={disabledFormFields}
                    translate='no'
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label
                  htmlFor='registration_no'
                  className='p-0 mb-2 font-size-14'
                >
                  <LabelContent required>Registration Number</LabelContent>
                </Label>
                <ControlledInput
                  control={control}
                  name='registration_no'
                  id='registration_no'
                  error={errors.registration_no}
                  disabled={disabledFormFields}
                />
              </FormGroup>
            </Col>

            {showNumberOfEmployeesInput && (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline m-0'
                  row={!isCompletion}
                >
                  <ControlledInput
                    label={
                      <LabelContent required={isNumberOfEmployeeRequired}>
                        Number of employees
                      </LabelContent>
                    }
                    labelClassName='p-0 mb-2 font-size-14'
                    control={control}
                    name='nb_employees'
                    id='nb_employees'
                    error={errors.nb_employees}
                    disabled={disabledFormFields}
                  />
                </FormGroup>
              </Col>
            )}

            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label className='p-0 mb-2 font-size-14'>
                  <LabelContent required>Currency</LabelContent>
                </Label>
                <ControlledSelect
                  error={errors.currency_id}
                  control={control}
                  name='currency_id'
                  options={
                    staticData?.company_currencies
                      ? staticData.company_currencies.map((c) =>
                          mapCurrencyToOption(c, 'id'),
                        )
                      : []
                  }
                />
              </FormGroup>
            </Col>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label htmlFor='address' className='p-0 mb-2 font-size-14'>
                  <LabelContent required>Address</LabelContent>
                </Label>

                <ControlledInput
                  control={control}
                  name='address'
                  id='address'
                  error={errors.address}
                  disabled={disabledFormFields}
                  translate='no'
                />
              </FormGroup>
            </Col>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label className='p-0 mb-2 font-size-14'>
                  <LabelContent required>Country</LabelContent>
                </Label>
                <ControlledSelect
                  error={errors.country_id}
                  control={control}
                  name='country_id'
                  options={
                    countries
                      ? countries.map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                          flag: `/${c.svg}`,
                        }))
                      : []
                  }
                  isDisabled={disabledFormFields}
                />
              </FormGroup>
            </Col>
            {!!countries?.find((e) => e.id === watch('country_id'))?.states
              ?.length && (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup
                  className='d-inline m-0'
                  row={!isCompletion}
                  disabled={disabledFormFields}
                >
                  <Label className='p-0 mb-2 font-size-14'>
                    <LabelContent required>State</LabelContent>
                  </Label>
                  <ControlledSelect
                    error={errors.state_id}
                    control={control}
                    name='state_id'
                    options={
                      watch('country_id')
                        ? countries
                            ?.find((e) => e.id === watch('country_id'))
                            ?.states?.map((s) => ({
                              label: s.name,
                              value: s.id,
                            }))
                        : []
                    }
                    isDisabled={disabledFormFields}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='d-inline m-0'
                row={!isCompletion}
                disabled={disabledFormFields}
              >
                <Label htmlFor='city' className='p-0 mb-2 font-size-14'>
                  <LabelContent required>City</LabelContent>
                </Label>
                <ControlledInput
                  control={control}
                  name='city'
                  id='city'
                  error={errors.city}
                  disabled={disabledFormFields}
                  translate='no'
                />
              </FormGroup>
            </Col>
            {isCompanySettings ? (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup className='d-inline m-0' row={!isCompletion}>
                  <Label htmlFor='po_number' className='p-0 mb-2 font-size-14'>
                    PO Number
                  </Label>
                  <ControlledInput
                    control={control}
                    name='po_number'
                    id='po_number'
                    error={errors.po_number}
                    translate='no'
                  />
                </FormGroup>
              </Col>
            ) : null}
            {isCompanySettings ? (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup className='d-inline m-0' row={!isCompletion}>
                  <Label htmlFor='vat' className='p-0 mb-2 font-size-14'>
                    VAT Number
                  </Label>
                  <ControlledInput
                    control={control}
                    name='vat'
                    id='vat'
                    error={errors.vat}
                    translate='no'
                  />
                </FormGroup>
              </Col>
            ) : null}
            {isCompanySettings ? (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup className='d-inline m-0' row={!isCompletion}>
                  <Label htmlFor='tax_number' className='p-0 mb-2 font-size-14'>
                    Tax Number
                  </Label>
                  <ControlledInput
                    control={control}
                    name='tax_number'
                    id='tax_number'
                    error={errors.tax_number}
                    translate='no'
                  />
                </FormGroup>
              </Col>
            ) : null}
            {isCompanySettings ? (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup className='d-inline m-0' row={!isCompletion}>
                  <Label
                    htmlFor='duns_number'
                    className='p-0 mb-2 font-size-14'
                  >
                    DUNS Number
                  </Label>
                  <ControlledInput
                    control={control}
                    name='duns_number'
                    id='duns_number'
                    error={errors.duns_number}
                    translate='no'
                  />
                </FormGroup>
              </Col>
            ) : null}
            {!isCompletion ? null : (
              <Col sm={12} className='mb-3'>
                <FormGroup className='m-0' row={!isCompletion}>
                  <Label
                    htmlFor='registration_document'
                    className='p-0 mb-2 font-size-14'
                  >
                    <LabelContent required>Registration Document</LabelContent>
                  </Label>
                  <ControlledDropzoneInput
                    control={control}
                    name='registration_document'
                    className={cx(
                      'd-flex gap-12 align-items-center flex-wrap font-size-14 cursor-pointer px-3 py-2 bg-primary-20',
                      errors?.registration_document
                        ? 'text-danger bg-soft-danger'
                        : 'text-primary-100 bg-primary-20 border-primary-100',
                      {
                        'justify-content-between': !!registrationDoc?.[0]?.name,
                      },
                    )}
                    error={errors.registration_document}
                    accept={{ 'application/pdf': ['.pdf'] }}
                    style={{ minHeight: 180 }}
                  >
                    {registrationDoc?.[0] ? (
                      <>
                        {registrationDoc?.[0]?.name}
                        <button
                          className='p-0.5 rp-btn-nostyle text-red-90 d-flex'
                          type='button'
                          onClick={(e) => {
                            e.stopPropagation()
                            setValue('registration_document', null)
                          }}
                        >
                          <XCircle size={20} weight='fill' />
                        </button>
                      </>
                    ) : (
                      'Accepts PDF'
                    )}
                  </ControlledDropzoneInput>
                </FormGroup>
              </Col>
            )}
          </Row>
        </Container>

        {isCompletion ? (
          <Container>
            <Row
              className={cx('pb-4 pt-2 m-0', {
                'd-none': isOnboarding,
              })}
            >
              <Button
                type='submit'
                block={!isCreation}
                loading={edit.isLoading || create.isLoading}
                disabled={edit.isLoading || create.isLoading}
                formId={companyInfoFormID}
              >
                {isCreation
                  ? 'Create'
                  : completeEntityDetails || isCompletion
                    ? 'Update'
                    : 'Next'}
              </Button>
            </Row>
          </Container>
        ) : (
          <>
            <div
              className={`w-100 divider border-top my-md-4 my-3 ${
                isOnboarding && 'd-none'
              }`}
            />

            <Row
              className={cx(
                'px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-start w-100',
                isOnboarding && 'd-none',
              )}
            >
              <PermissionTooltip
                showing={!hasAccess(permissions.manageCompanySettings)}
                id='submit-company-info-form'
                area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
              >
                <Button
                  text='Save'
                  loading={edit.isLoading || create.isLoading}
                  disabled={
                    edit.isLoading ||
                    create.isLoading ||
                    !hasAccess(permissions.manageCompanySettings)
                  }
                  id='submit-company-info-form'
                  formId={companyInfoFormID}
                  className='save-info-button'
                  type='submit'
                />
              </PermissionTooltip>
            </Row>
          </>
        )}
      </Form>
    </CardBody>
  )
})

export default CompanyInfo
