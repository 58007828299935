import React from 'react'
import { cn } from 'ui'

export function FlagIcon({ className, size = 16, code }) {
  return (
    <div
      className={cn(
        `currency-flag tw-rounded-full currency-flag-${code?.toLowerCase()}`,
        className,
      )}
      style={{ width: size, height: size }}
    />
  )
}
