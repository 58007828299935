import React from 'react'
import { useParams } from 'react-router-dom'

import ModalHeader from '../../../../../components/ModalHeader'
import Head from '../../../../../components/head'
import { SendContractData } from './send-contract-data'

export function SendDataContract() {
  const params = useParams()

  return (
    <div>
      <Head title='Send Data Contract' />

      <ModalHeader noExit />

      {params.contractId ? (
        <SendContractData contractId={params.contractId} />
      ) : (
        <ErrorNoId />
      )}
    </div>
  )
}

function ErrorNoId() {
  return <div className='tw-p-4'>Contract ID not found</div>
}
