import { PencilSimpleLine } from '@phosphor-icons/react'
import React, { useEffect, useRef } from 'react'

function Editable({
  editable,
  content,
  quillEditorContainer,
  onChangeActive,
  isActive,
  withIcon,
  readOnly,
}) {
  // The element that will display my editable's content when the editor is not active
  const contentEl = useRef()
  // The element that will contain the quill editor element when I'm the active Editable
  const quillEditorParent = useRef()

  // Set the contents of my contentEl when it changes
  useEffect(() => {
    contentEl.current.innerHTML = content
  }, [content])

  /**
   * When prop isActive is true, detach the quill editor element
   * from the temporary container of App, moving it to my own container for it.
   *
   * Also, toggle the visibility of contentEl and quillEditorParent depending
   * on if I'm active or not.
   */
  useEffect(() => {
    if (isActive) {
      quillEditorParent.current.appendChild(quillEditorContainer.current)
    }

    quillEditorParent.current.style.display = isActive ? 'inline' : 'none'
    contentEl.current.style.display = isActive ? 'none' : 'block'
  }, [quillEditorParent, quillEditorContainer, isActive])

  /**
   * If I'm the active Editable, listen for user hitting the escape button
   * and deactivate me.
   */
  useEffect(() => {
    function onKeyUp(event) {
      if (event.code === 'Escape') {
        activate(false)
      }
    }

    if (isActive) {
      document.addEventListener('keyup', onKeyUp)
      return () => document.removeEventListener('keyup', onKeyUp)
    }
  }, [isActive])

  /**
   * Message App that I want to be the active editable.
   */
  function activate(active) {
    onChangeActive(editable, active)
  }

  return (
    <div
      className='editable'
      id={editable.id}
      onClick={readOnly ? () => {} : () => activate(true)}
    >
      {withIcon && (
        <PencilSimpleLine
          size={26}
          className='tw-pointer-events-none tw-absolute tw-right-3 tw-top-3 tw-z-[1] tw-rounded-full tw-border tw-border-primary-100 tw-p-1 tw-text-primary-100'
          weight='bold'
        />
      )}
      <div
        className='focus-within:tw-bg-primary-30 hover:tw-bg-primary-30 focus:tw-bg-primary-30 focus:tw-outline-none'
        ref={quillEditorParent}
      />
      <div
        className='tw-px-4 tw-py-3 tw-text-sm [&>ol]:tw-pl-5 [&>p]:tw-mb-2 [&>ul]:tw-pl-5'
        ref={contentEl}
      />
    </div>
  )
}

export default Editable
