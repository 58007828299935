import moment from 'moment'
import React from 'react'
import { Modal, ModalHeader, Spinner } from 'reactstrap'

import StyledTd from '../../../../components/Table/StyledTd'
import StyledTh from '../../../../components/Table/StyledTh'
import { StyledH5 } from '../../../../components/Typo'
import { useFetch } from '../../../../helpers/hooks'
import { invitationHistory } from '../../../../services/api'

function InvitationHistoryModal({ show, hide, user }) {
  const historyList = useFetch({
    action: invitationHistory,
    withAdminAccess: true,
    autoFetch: true,
    body: { email: user?.email },
  })
  return (
    <Modal
      className='custom-modal-style'
      isOpen={show}
      size='lg'
      toggle={hide}
      scrollable
    >
      <ModalHeader>
        <StyledH5
          min='14px'
          max='16px'
          className='modal-title has-text-dark mt-0'
          id='myLargeModalLabel'
        >
          Email history
        </StyledH5>
      </ModalHeader>
      <div className='modal-body p-0' style={{ minHeight: '60vh' }}>
        {historyList.isLoading ? (
          <div
            style={{ minHeight: '30rem' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : (
          <div className='table-responsive'>
            <thead className='thead-light'>
              <tr>
                <StyledTh>Status</StyledTh>
                <StyledTh>Message</StyledTh>
                <StyledTh>Last Event</StyledTh>
                <StyledTh>Opens</StyledTh>
                <StyledTh>Clicks</StyledTh>
              </tr>
            </thead>
            <tbody>
              {React.Children.toArray(
                historyList?.data?.map((order, index) => (
                  <Line item={order} key={index} />
                )),
              )}
            </tbody>
          </div>
        )}
      </div>
    </Modal>
  )
}

const Line = ({ item }) => {
  return (
    <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
      <StyledTd>{item?.status}</StyledTd>
      <StyledTd>{item?.subject}</StyledTd>
      <StyledTd>
        <div style={{ minWidth: '150px' }}>
          {moment(item?.last_event_time).format('MM/DD, h:mm a')}
        </div>
      </StyledTd>
      <StyledTd>{item?.opens_count}</StyledTd>
      <StyledTd>{item?.clicks_count}</StyledTd>
    </tr>
  )
}

export default InvitationHistoryModal
