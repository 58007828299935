import {
  ArrowLeft,
  ArrowSquareOut,
  CaretDown,
  CaretUp,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { Fragment, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useSelector } from 'react-redux'
import { Row, Spinner } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import BadgeX from '../../../../components/Table/BadgeX'
import Flag from '../../../../components/ui/flag'
import { useFetch, useResize } from '../../../../helpers/hooks'
import { getScreeningDetails } from '../../../../services/api'
import {
  getScreeningHistory,
  screenTransaction,
} from '../../../../services/api-compliance'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'
import { getFullName } from '../../../../utils/get-full-name'
import { parseJwt } from '../../../../utils/parseJwt'

export const getStatus = (value) => {
  switch (value.toLowerCase()) {
    case 'passed':
      return { label: 'PASSED', color: 'success' }
    case 'approved':
      return { label: 'APPROVED', color: 'success' }
    case 'in_progress':
      return { label: 'IN PROGRESS', color: 'warning' }
    case 'declined':
      return { label: 'DECLINED', color: 'danger' }
    case 'failed':
      return { label: 'FAILED', color: 'danger' }
    case 'review':
      return { label: 'UNDER REVIEW', color: 'warning' }
  }
}

const status = {
  passed: 'passed',
  approved: 'approved',
  in_progress: 'in_progress',
  declined: 'declined',
  failed: 'failed',
  review: 'review',
}

const DetailItem = ({ label, value, isUrl }) => (
  <div className='tw-mb-6'>
    <div className='tw-mb-2'>{label}</div>
    {isUrl ? (
      <a
        href={value}
        className='tw-font-semibold'
        target='_blank'
        rel='noreferrer'
      >
        {value}
      </a>
    ) : (
      <div className='tw-font-semibold'>{value}</div>
    )}
  </div>
)

const Reason = ({ className = '', onApproveClick, onDeclineClick }) => {
  const [reason, setReason] = useState('')
  return (
    <div
      className={`-tw-mx-4 tw-mt-4 tw-border-t tw-border-t-surface-30 tw-px-8 tw-py-6 ${className}`}
    >
      <div className='tw-mb-2'>Reason</div>
      <textarea
        name='reason'
        className='tw-mb-6 tw-h-28 tw-w-full tw-rounded tw-border tw-border-surface-30 tw-p-4'
        placeholder='Insert note here to inform the decision'
        onChange={(text) => setReason(text.target.value)}
        value={reason}
      />

      <div className='tw-flex tw-justify-between'>
        <button
          className='btn btn-danger'
          type='button'
          onClick={() => onDeclineClick(reason)}
          disabled={reason.length < 3}
        >
          Decline
        </button>
        <button
          className='btn btn-success'
          type='button'
          onClick={() => onApproveClick(reason)}
          disabled={reason.length < 3}
        >
          Approve
        </button>
      </div>
    </div>
  )
}

export function ScreeningDetails({ id, hide, isOpen, onAction }) {
  const [selectedHistory, setSelectedHistory] = useState(null)
  const [showDetailedRecords, setShowDetailedRecords] = useState(false)
  const loginToken = useSelector((state) => state.Login?.loginToken)
  const [viewFile, setViewFile] = useState(false)

  const isMobile = useResize()
  const background = showDetailedRecords ? 'tw-bg-surface-30' : 'tw-bg-white'
  const screeningDetails = useFetch(
    {
      action: getScreeningDetails,
      withAdminAccess: true,
      autoFetch: !!id,
      body: { id },
    },
    [id],
  )

  const screeningHistory = useFetch(
    {
      action: getScreeningHistory,
      autoFetch: !!id,
      body: { id },
      withAdminAccess: true,
    },
    [id],
  )

  const { startFetch: screen, isLoading: screening } = useFetch({
    action: screenTransaction,
    autoFetch: false,
    onComplete: () => {
      toastr.success('Transaction screening is successful')
      hide()
      setShowDetailedRecords(false)
      onAction(null)
    },
    withAdminAccess: true,
  })

  const onHistoryClick = (history) => {
    if (history?._id === selectedHistory?._id) setSelectedHistory(null)
    else setSelectedHistory(history)
  }

  const handleScreening = (reason, status) => {
    screen({
      updated_by: `${getFullName(parseJwt(loginToken))}`,
      note: reason,
      status,
      id: selectedHistory._id,
    })
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1100,
      }}
    >
      <Menu
        onClose={() => {
          hide()
          setSelectedHistory(null)
          setShowDetailedRecords(false)
          setViewFile(false)
        }}
        className={`${background} tw-p-4`}
        isOpen={isOpen}
        width={
          isMobile || showDetailedRecords
            ? '100%'
            : window.innerWidth < 1400
              ? '40%'
              : '30%'
        }
        right
        menuClassName='scrollbar-hidden'
      >
        {screeningDetails.isLoading ||
        screeningHistory.isLoading ||
        screening ? (
          <Row
            style={{ minHeight: '30rem' }}
            className='d-flex p-0 m-0 justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        ) : !showDetailedRecords ? (
          <>
            {isMobile && <ArrowLeft size={28} onClick={() => hide()} />}
            <div className='h2'>Screening</div>
            <div className='d-flex flex-column'>
              {screeningDetails?.data?.map((data) => (
                <div
                  key={data.title}
                  className={cn(
                    'tw-flex tw-border-b-[1px] tw-border-b-surface-30 tw-py-4',
                    { 'tw-border-b-0': viewFile },
                  )}
                >
                  <div className='tw-flex-1 tw-text-sm tw-font-normal tw-text-text'>
                    {capitalizeFirstLetter(data.title)}
                  </div>

                  {!Array.isArray(data.value) ? (
                    <div className='tw-text-sm tw-font-semibold'>
                      {!(
                        data.title.includes('KYC') ||
                        data.title.includes('Date')
                      )
                        ? data.value
                        : format(
                            new Date(
                              typeof data.value === 'number'
                                ? data.value * 1000
                                : data.value,
                            ),
                            'MMM dd, yyyy',
                          )}
                    </div>
                  ) : (
                    <button onClick={() => setViewFile((val) => !val)}>
                      {!viewFile ? (
                        <CaretDown size={20} />
                      ) : (
                        <CaretUp size={20} />
                      )}
                    </button>
                  )}

                  {data.flag && (
                    <Flag url={data.flag} size='20px' className='tw-ml-2' />
                  )}
                </div>
              ))}
            </div>

            {viewFile && (
              <>
                {screeningDetails.data
                  ?.filter((detail) => detail.title === 'File')[0]
                  .value.map((item, index) => (
                    <Fragment key={item.title + index}>
                      <label className='tw-mt-1'>{item.title}</label>
                      <img
                        src={item.value}
                        alt={item.title}
                        key={item.title}
                        className='tw-mb-2 tw-w-full'
                      />
                    </Fragment>
                  ))}
                <div className='tw-border-b-[1px] tw-border-b-surface-30' />
              </>
            )}

            {screeningHistory?.data?.map((history) => (
              <div
                key={history._id}
                className='tw-mt-4 tw-flex tw-flex-col tw-rounded tw-border tw-border-surface-30'
              >
                <div className='tw-flex tw-items-center tw-p-4'>
                  <div className='tw-mr-4 tw-flex tw-h-10 tw-w-10 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-surface-30 tw-font-bold'>
                    {history.result.length
                      ? parseFloat(
                          (
                            history.result.reduce(
                              (acc, current) => acc + current.confidence_score,
                              0,
                            ) / history.result.length
                          ).toFixed(2),
                        )
                      : 0}
                  </div>

                  <div className='tw-flex-1 tw-flex-col'>
                    <div>{`${history.result.length} Match(es)`} </div>
                    <span className='tw-text-text-60'>
                      {format(new Date(history.created_at), 'MMM dd, yyyy')}
                    </span>
                  </div>

                  <BadgeX
                    className='tw-mr-4 tw-uppercase'
                    status={getStatus(history.status).color}
                    pill
                  >
                    {getStatus(history.status).label}
                  </BadgeX>

                  <button type='button' onClick={() => onHistoryClick(history)}>
                    {selectedHistory?._id === history._id ? (
                      <CaretUp size={20} color='black' />
                    ) : (
                      <CaretDown size={20} color='black' />
                    )}
                  </button>
                </div>

                {selectedHistory?._id === history._id && (
                  <>
                    {getStatus(selectedHistory.status).label !==
                      'IN_PROGRESS' &&
                      getStatus(selectedHistory.status).label !==
                        'UNDER REVIEW' && (
                        <>
                          <div className='tw-mx-4 tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                            <span className='tw-mr-4 tw-flex-1'>Reviewer</span>
                            <span className='tw-flex-1 tw-text-right tw-font-bold'>
                              {selectedHistory.updated_by}
                            </span>
                          </div>

                          <div className='tw-mx-4 tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                            <span className='tw-mr-4 tw-flex-1'>
                              Review time
                            </span>
                            {selectedHistory.updated_at && (
                              <span className='tw-flex-1 tw-text-right tw-font-bold'>
                                {format(
                                  new Date(selectedHistory.updated_at),
                                  'MMM dd, yyyy',
                                )}
                              </span>
                            )}
                          </div>

                          <div className='tw-mx-4 tw-mb-4 tw-border-b tw-border-b-surface-30 tw-py-4'>
                            <div className='tw-mb-2 tw-mr-4 tw-flex-1'>
                              Reason
                            </div>
                            <span className='tw-flex-1 tw-text-right tw-font-bold'>
                              {selectedHistory.note}
                            </span>
                          </div>
                        </>
                      )}

                    <button
                      type='button'
                      className='text-primary tw-mx-4 tw-flex tw-h-12 tw-items-center tw-justify-center tw-self-stretch tw-rounded tw-border tw-border-primary tw-font-extrabold last:tw-mb-4'
                      onClick={() => setShowDetailedRecords(true)}
                    >
                      View Detailed Records
                      <ArrowSquareOut
                        size={20}
                        className='text-primary tw-ml-2'
                      />
                    </button>

                    {history?.result?.map((match, index) => (
                      <div
                        className='tw-flex tw-flex-col tw-bg-surface-10 tw-p-4'
                        key={match.data_hash}
                      >
                        <div className='tw-font-bold'>{`Match ${
                          index + 1
                        }`}</div>
                        <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                          <span className='tw-mr-4 tw-flex-1'>Match Score</span>
                          <span className='tw-flex-1 tw-text-right tw-font-bold'>
                            {match.confidence_score}
                          </span>
                        </div>

                        <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                          <span className='tw-mr-4 tw-flex-1'>Name</span>
                          <span className='tw-flex-1 tw-text-right tw-font-bold'>
                            {match.name}
                          </span>
                        </div>

                        {match.country_residence && (
                          <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                            <span className='tw-mr-4 tw-flex-1'>
                              Country of residence
                            </span>
                            <span className='tw-flex-1 tw-text-right tw-font-bold'>
                              {match.country_residence}
                            </span>
                          </div>
                        )}

                        <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                          <span className='tw-mr-4 tw-flex-1'>Matched in</span>
                          <span className='tw-flex-1 tw-text-right tw-font-bold'>
                            {match.data_source.name}
                          </span>
                        </div>

                        {match.remarks && (
                          <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
                            <span className='tw-mr-4 tw-flex-1'>Remark</span>
                            <span className='tw-flex-1 tw-text-right tw-font-bold'>
                              {match.remarks}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}

                    {(selectedHistory.status.toLowerCase() ===
                      status.in_progress ||
                      selectedHistory.status.toLowerCase() ===
                        status.review) && (
                      <Reason
                        onApproveClick={(reason) =>
                          handleScreening(reason, 'PASSED')
                        }
                        onDeclineClick={(reason) =>
                          handleScreening(reason, 'FAILED')
                        }
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className='tw-flex tw-flex-col tw-bg-surface-30'>
            <button
              className='tw-mb-8 tw-flex tw-items-center'
              type='button'
              onClick={() => setShowDetailedRecords(false)}
            >
              <ArrowLeft size={28} />
              <span className='tw-ml-2 tw-text-2xl tw-font-medium'>
                Detailed records
              </span>
            </button>

            {selectedHistory.result.map((match, index) => (
              <div
                className='tw-mb-6 tw-rounded tw-bg-white tw-p-6'
                key={match.reason_id}
              >
                <div className='tw-mb-4 tw-text-sm tw-font-bold'>{`Match ${
                  index + 1
                }`}</div>
                <DetailItem label='Score' value={match.confidence_score} />
                {match.remarks && (
                  <DetailItem label='Remark' value={match.remarks} />
                )}
                <DetailItem label='Name' value={match.name} />
                <DetailItem label='Entity type' value={match.entity_type} />

                {match.alt_names && (
                  <DetailItem
                    label='Alt names'
                    value={match.alt_names.join(', ')}
                  />
                )}

                {match.title && (
                  <DetailItem label='Title' value={match.title} />
                )}

                {match.position && (
                  <>
                    <p className='tw-font-semibold'>Position:</p>
                    {match.position?.map((item) => (
                      <div
                        key={item.title}
                        className='tw-mb-6 tw-rounded tw-border tw-border-surface-30 tw-bg-primary-20 tw-p-4'
                      >
                        <p>
                          Title:{' '}
                          <span className='tw-font-semibold'>{item.title}</span>
                        </p>

                        <p>
                          Organization:{' '}
                          <span className='tw-font-semibold'>
                            {item.organization}
                          </span>
                        </p>

                        {item.start_date && (
                          <p className='tw-mb-auto'>
                            Start date:{' '}
                            <span className='tw-font-semibold'>
                              {item.start_date}
                            </span>
                          </p>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {match.country_residence && (
                  <DetailItem
                    label='Country of residence'
                    value={match.country_residence.join(', ')}
                  />
                )}

                {match.nationality && (
                  <DetailItem
                    label='Nationality'
                    value={match.nationality.join(', ')}
                  />
                )}

                {match.citizenship && (
                  <DetailItem
                    label='Citizenship'
                    value={match.citizenship.join(', ')}
                  />
                )}

                {match.address && (
                  <DetailItem
                    label='Address'
                    value={match.address.join(', ')}
                  />
                )}

                {match.date_of_birth && (
                  <DetailItem
                    label='Date of birth'
                    value={match.date_of_birth.join(', ')}
                  />
                )}

                {match.place_of_birth && (
                  <DetailItem
                    label='Place of birth'
                    value={match.place_of_birth.join(', ')}
                  />
                )}

                <DetailItem
                  label='Source list URL'
                  value={match.source_list_url}
                  isUrl
                />

                <DetailItem
                  label='Data source'
                  value={`${match.data_source.name} (${match.data_source.short_name})`}
                />

                <DetailItem
                  label='Start date'
                  value={format(
                    new Date(selectedHistory.created_at),
                    'yyyy-MM-dd',
                  )}
                />

                {match.source_information_url && (
                  <DetailItem
                    label='Source information URL'
                    value={match.source_information_url}
                    isUrl
                  />
                )}

                <DetailItem label='Program' value={match.program?.join(', ')} />

                {match.additional_information && (
                  <DetailItem
                    label='Additional information'
                    value={
                      match.additional_information?.url ??
                      match.additional_information?.gender
                    }
                    isUrl={!!match.additional_information?.url}
                  />
                )}
                <DetailItem label='SI identifier' value={match.si_identifier} />
                <DetailItem label='Entity number' value={match.entity_number} />
                <DetailItem label='Data hash' value={match.data_hash} />
              </div>
            ))}

            {(selectedHistory.status.toLowerCase() === status.in_progress ||
              selectedHistory.status.toLowerCase() === status.review) && (
              <Reason
                className='tw-rounded tw-bg-white'
                onApproveClick={(reason) => handleScreening(reason, 'PASSED')}
                onDeclineClick={(reason) => handleScreening(reason, 'FAILED')}
              />
            )}
          </div>
        )}
      </Menu>
    </div>
  )
}
