import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { Stepper } from 'ui'
import Head from '../../../components/head'
import ModalHeader from '../../../components/ModalHeader'
import StepContainer from '../../../components/Steps/StepContainer'
import Loader from '../../../components/ui/loader'
import {
  BulkOperationsContextProvider,
  useBulkOperationsFlowContext,
} from './bulk-operations-context'

const OperationsStep1 = lazy(() => import('./operations-step-1'))
const OperationsStep2 = lazy(() => import('./operations-step-2'))
const OperationsStep3 = lazy(() => import('./operations-step-3'))
const OperationsStep4 = lazy(() => import('./operations-step-4'))

const BASE_PATH = '/contract/bulk-operations'
export function getStepFormId(step) {
  return `bulk-operations-form-step-${step.key}`
}
function getPrevStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `${BASE_PATH}/${prevStepKey}`
}
function getNextStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `${BASE_PATH}/${nextStepKey}`
}

// Custom hook to make sure that we have data from the first step
// Otherwise, redirect back to the first step
export function useCheckFirstStepData(formData, history) {
  useEffect(() => {
    // If we don't have the data from the first step, redirect back to the first step
    if (!formData?.update_type || !formData?.contract_type) {
      history.push(BASE_PATH)
    }
  }, [formData, history])
}

export function ContractBulkOperations() {
  return (
    <BulkOperationsContextProvider>
      <BulkOperationsFlow />
    </BulkOperationsContextProvider>
  )
}

function BulkOperationsFlow() {
  const history = useHistory()
  const { stepKey } = useParams()

  const stepperSteps = bulkOperationsSteps.map((step) => step.name)
  const stepKeys = bulkOperationsSteps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')
  const activeStep = bulkOperationsSteps[activeIndex]

  const isLastStep = activeIndex === bulkOperationsSteps.length - 1

  const pageTitleWithStepName = [activeStep?.name, 'Bulk Contract Actions']
    .filter(Boolean)
    .join(' - ')

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink(step, bulkOperationsSteps)
    history.push(prevStepLink ?? BASE_PATH)
  }

  const [loading, setLoading] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  const disableNext = loading || disabledNext

  const isSelectingStep = stepKey === 'select-workers'

  const { operationsFormData } = useBulkOperationsFlowContext()
  const selectedItems = operationsFormData?.selectedItems ?? []
  const selectStepFooterContent = (
    <div className='!tw-ml-auto !tw-mr-8 tw-text-end'>
      <div className='tw-w-[110px] tw-text-base tw-font-semibold'>
        {selectedItems.length <= 0 ? 'No' : selectedItems.length} Contract
        {selectedItems.length !== 1 && 's'}
      </div>
      <div className='tw-text-text-60'>Selected</div>
    </div>
  )

  function handleNext(step) {
    const nextStepLink = getNextStepLink(step, bulkOperationsSteps)
    if (nextStepLink) {
      history.push(nextStepLink)
    }
  }

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/contracts')
  }

  return (
    <Container fluid className='px-0'>
      <Head title={pageTitleWithStepName} />

      <ModalHeader action={handleReturn}>
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4 tw-pb-11'>
        <Switch>
          {bulkOperationsSteps.map((step, index) => {
            const { key, Component, name } = step
            return (
              <Route path={`${BASE_PATH}/${key}`} key={index} exact>
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={bulkOperationsSteps.length}
                  nextType='submit'
                  nextFormId={getStepFormId(step)}
                  nextText={isLastStep ? 'Submit' : 'Continue'}
                  footerContent={
                    isSelectingStep ? selectStepFooterContent : null
                  }
                  noBack={index === 0}
                  disableNext={disableNext}
                  loading={loading}
                  onBack={() => {
                    handlePrev(step)
                    setDisabledNext(false)
                  }}
                  cardStyles={
                    activeStep?.needsWideScreen ? { maxWidth: 1320 } : null
                  }
                >
                  <Suspense fallback={<Loader minHeight='100%' />}>
                    <Component
                      step={step}
                      goToNextStep={() => handleNext(step)}
                      setLoading={setLoading}
                      setDisabledNext={setDisabledNext}
                    />
                  </Suspense>
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
    </Container>
  )
}

const bulkOperationsSteps = [
  {
    name: 'Update',
    key: '',
    Component: OperationsStep1,
    next: 'select-workers',
  },
  {
    name: 'Select workers',
    key: 'select-workers',
    Component: OperationsStep2,
    next: 'upload',
    needsWideScreen: true,
  },
  {
    name: 'Upload',
    key: 'upload',
    Component: OperationsStep3,
    next: 'review',
  },
  {
    name: 'Review',
    key: 'review',
    Component: OperationsStep4,
    needsWideScreen: true,
  },
]
