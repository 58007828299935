import React, { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import Header from './Header'
import Sidebar from './Sidebar'
import Footer from '../../../../components/VerticalLayout/Footer'

export const menuCollapsedKey = 'is-menu-collapsed'

export default function AdminPanelLayoutContent({
  children,
  leftSideBarTheme,
  routePermissions,
}) {
  const [isMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
  )

  const [isMenuCollapsed, setIsMenuCollapsed] = useLocalStorage(
    menuCollapsedKey,
    false,
  )

  function toggleMenuCollapse() {
    setIsMenuCollapsed((collapsed) => !collapsed)

    document.body.classList.toggle('vertical-collpsed')
  }

  return (
    <div id='layout-wrapper'>
      <Header routePermissions={routePermissions} />

      <Sidebar
        theme={leftSideBarTheme}
        isMobile={isMobile}
        routePermissions={routePermissions}
        isMenuCollapsed={isMenuCollapsed}
        toggleMenuCollapse={toggleMenuCollapse}
      />

      <div
        className='main-content'
        style={{ transition: 'margin 0.2s ease 0s' }}
      >
        {children}
      </div>

      <Footer
        style={{ transition: 'margin 0.2s ease 0s' }}
        className='footer tw-px-[--padding-content-x] tw-pb-5 lg:tw-ml-[18.2vw]'
      />
    </div>
  )
}
