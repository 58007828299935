import cx from 'classnames'
import React, { useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'

import '../phoneInput/phoneInput.css'
import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'

const CustomPhoneInput = ({
  value,
  onChange,
  name,
  label,
  id,
  enableAreaCodes,
  error,
  required,
  ...props
}) => {
  const inputId = useMemo(() => id ?? name ?? 'phone', [id, name])

  return (
    <div className='position-relative'>
      {!label ? null : (
        <label htmlFor={inputId}>
          {label}
          {required ? (
            <span
              className='text-danger font-size-16 ml-1'
              style={{ lineHeight: 1 }}
            >
              *
            </span>
          ) : null}
        </label>
      )}
      <PhoneInput
        enableAreaCodes={enableAreaCodes}
        inputClass={cx({ 'border-danger': !!error })}
        value={value}
        country='ae'
        onChange={onChange}
        {...props}
        inputProps={{ name: name ?? 'phone', id: inputId }}
      />
      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

export default CustomPhoneInput
