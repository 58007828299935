import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  DotsThreeCircleVertical,
  DotsThreeOutlineVertical,
  Plus,
  Spinner,
} from '@phosphor-icons/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Avatar, cn } from 'ui'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { switchEntity } from '../../services/api'
import { updateProfileCompany } from '../../store/profile/actions'

export function EntitiesMenu({ closeMenu, menuButtonClassName }) {
  const profile = useSelector((state) => state?.userProfile?.userProfile)
  const history = useHistory()
  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()

  const canManageEntity = hasAccess(permissions.addEntity)

  const { startFetch: entitySwitch, isLoading: switchingEntity } = useFetch({
    action: switchEntity,
    onComplete: (data) => {
      closeMenu?.()
      dispatch(updateProfileCompany(data))
      history.push('/activity')
    },
  })

  return (
    <Menu>
      <MenuButton className='tw-group tw-peer tw-w-full'>
        <div
          className={cn(
            'tw-flex tw-items-center tw-gap-2 tw-rounded tw-text-start lg:tw-hidden',
            menuButtonClassName,
          )}
        >
          <div className='tw-flex tw-size-10 tw-items-center tw-justify-center'>
            {switchingEntity ? (
              <Spinner
                size={40}
                className='tw-animate-[spin_1.5s_linear_infinite] tw-rounded-full tw-bg-surface-20 tw-p-2'
              />
            ) : (
              <Avatar
                photo={profile?.company?.logo}
                name={profile?.company?.name}
                size='lg'
              />
            )}
          </div>
          <div className='tw-flex tw-flex-col'>
            <span className='tw-text-sm tw-font-semibold'>
              {profile?.company?.dba ?? profile?.company?.name}
            </span>
            <span className='tw-text-sm tw-text-text-80'>Entity</span>
          </div>
          <DotsThreeOutlineVertical
            size={24}
            weight='fill'
            className='tw-ml-auto tw-text-secondary-80'
          />
        </div>

        <div className='tw-relative tw-mx-auto tw-hidden tw-size-10 lg:tw-block'>
          {switchingEntity ? (
            <Spinner className='tw-size-full tw-animate-[spin_1.5s_linear_infinite] tw-rounded-full tw-bg-surface-20 tw-p-2' />
          ) : (
            <>
              <Avatar
                photo={profile?.company?.logo}
                name={profile?.company?.name}
                className='tw-inline-flex'
                size='lg'
              />

              <div className='tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-black/70 tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100 group-data-[open]:tw-opacity-100'>
                <DotsThreeCircleVertical className='tw-size-6 tw-text-white' />
              </div>
            </>
          )}
        </div>
      </MenuButton>

      <div className='tw-fixed tw-inset-0 tw-z-[1110] tw-hidden tw-bg-black/50 tw-opacity-0 tw-transition-opacity peer-data-[open]:tw-opacity-100 max-lg:peer-data-[open]:tw-block lg:tw-hidden'></div>

      <MenuItems
        transition
        anchor={{ to: 'bottom end', padding: 8, gap: 8 }}
        className={cn(
          'tw-z-[1100] tw-rounded-t tw-border tw-border-surface-40 tw-bg-white tw-p-2 tw-text-sm/6 tw-transition tw-duration-100 tw-ease-out focus:tw-outline-none data-[closed]:tw-translate-y-2 data-[closed]:tw-opacity-0 lg:tw-origin-top-right lg:tw-rounded-lg',
          'max-lg:!tw-fixed max-lg:!tw-bottom-0 max-lg:!tw-left-0 max-lg:!tw-right-0 max-lg:!tw-top-auto max-lg:!tw-max-w-full',
        )}
      >
        {profile?.entities?.map((entity) => {
          return (
            <MenuItem key={entity?.entity_id} className=''>
              <button
                className='tw-group tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-p-2 disabled:tw-cursor-not-allowed disabled:tw-text-text-60 data-[focus]:tw-bg-primary-20'
                type='button'
                onClick={() => {
                  entitySwitch({ entity_id: entity?.entity_id })
                }}
                disabled={switchingEntity}
              >
                <Avatar photo={entity?.logo} name={entity?.name} size='sm' />
                <div className='tw-truncate tw-whitespace-nowrap tw-text-sm tw-font-semibold group-data-[focus]:tw-text-primary-100'>
                  {entity?.name}
                </div>
              </button>
            </MenuItem>
          )
        })}

        {!canManageEntity ? null : (
          <div>
            <div className='tw-my-2 tw-h-px tw-bg-surface-40' />
            <MenuItem className=''>
              <button
                className='tw-group tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded-lg tw-p-2 tw-text-xs tw-font-bold data-[focus]:tw-bg-primary-20 data-[focus]:tw-text-primary-100'
                type='button'
                onClick={() => {
                  history.push('/entity/add')
                  window.scrollTo(0, 0)
                }}
              >
                <Plus className='tw-size-6 tw-p-1' weight='bold' />
                Create a new Company
              </button>
            </MenuItem>
          </div>
        )}
      </MenuItems>
    </Menu>
  )
}
