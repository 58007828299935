import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const TermsModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='xl'>
      <ModalHeader toggle={toggle}>Terms and Conditions</ModalHeader>
      <ModalBody className='px-0 pb-0'>
        <div className='p-2 px-3'>
          <div className='pb-4'>
            The RemotePass Referral Program (“Program”) has been created to
            reward you, our loyal Customers, (“you,” “Partner,” “Referrer,”
            “Participant”) for doing what you would already do – recommending us
            to your peers who sign on to be a RemotePass customer (“Referred,”
            “Participant”). It was also created to reward these new customers
            for listening to your great advice in referring us, by offering them
            a reward for signing up at your referral! These Program Terms and
            Conditions are a binding agreement between you and RemotePass and
            will govern your participation in any and all Program offers. You
            are not authorized to participate in the Program, if you do not
            agree to these Program Terms and Conditions in their entirety.
            RemotePass reserves the right to modify these Program Terms and
            Conditions at any time, at its sole discretion, and without notice
            to you. RemotePass may also, in its sole discretion, change, cancel,
            suspend, or modify any aspect of the Program without notice.
            RemotePass also reserves the right to disqualify any customers or
            prospective customers at any time from participation in the Program.
          </div>
          <h4>How the Program works</h4>
          <div className='px-3'>
            <ol>
              <li>You Refer us to your network</li>
              <li>
                The company signs with RemotePass and processes the first
                month’s payroll
              </li>
              <li>
                You earn a $30 Gift Card per contractor if your referral adds
                less than 5 contractors
              </li>
              <li>
                You earn an additional $250 Gift Card if your referral adds more
                than 5 contractors or 1 Full Time Employee
              </li>
              <li>
                After using your referral link to Sign up, your referral gets
                their first-month subscription for free!
              </li>
            </ol>
          </div>
          <h4>
            Here are a few scenarios where you would not earn your gift card:
          </h4>
          <div className='px-3'>
            <ol>
              <li>The referral already has an account</li>
              <li>The referral has not processed payment</li>
              <li>
                The referral has previously accepted another invitation to
                onboard
              </li>
            </ol>
          </div>
          <h5>Eligibility.</h5>
          <div className='pb-2'>
            To be eligible for participation in the Program, both the Referrer
            and the Referred must be at least 18 years of age. The Referred
            Customer must remain signed up for RemotePass services for at least
            twelve months. RemotePass reserves the right to find any ineligible
            Participant in the Program at its sole discretion.
          </div>
          <h5>Conduct.</h5>
          <div className='pb-2'>
            RemotePass reserves the right, at its sole discretion, to prohibit
            any Partner from participating in any aspect of the Program if
            RemotePass deems or suspects that such Partner has engaged in or has
            attempted to engage in any of the following: a) acting in violation
            of these Program Terms and Conditions; b) damaging, tampering with
            or corrupting the operation of the Program or Site; c) activity
            deemed in the sole discretion of RemotePass to be generally
            inconsistent with the intended operation of the Program. RemotePass
            shall be the sole determiner in cases of suspected abuse, fraud, or
            breach of these Program Terms and Conditions or intent of these
            Program Terms and Conditions.
          </div>
          <h4>Liability</h4>
          <h5>Binding Effects.</h5>
          <div className='pb-2'>
            By participating in the Program, you agree to and are bound by the
            Program Terms and Conditions. If you do not wish to agree to and
            abide by the Program Terms and Conditions in their entirety, you are
            not authorized to participate in the Program.
          </div>
          <h5>Release.</h5>
          <div className='pb-2'>
            By participating in the Program, Participants release RemotePass,
            and their respective directors, officers, employees, and agents from
            any and all liability for any loss, harm, damages, cost, or expense,
            including, without limitation, property damages, personal injury
            and/or death, arising out of or in any way connected to the Program
            and/or the use of any Program rewards.
          </div>
          <h5>Indemnification.</h5>
          <div className='pb-2'>
            Participants agree to indemnify, defend, and hold RemotePass and its
            representatives and agents harmless from and against any and all
            third-party claims, demands, liabilities, costs, or expenses,
            including attorney’s fees and costs, arising from or related to any
            breach by the participant of any of these Program Terms and
            Conditions or any violation by Participant of applicable law.
          </div>
          <h4>Disclaimer</h4>
          <h5>Reserved Right.</h5>
          <div className='pb-2'>
            RemotePass reserves the right to cancel or suspend the Program
            should it determine, in its sole discretion, that the
            administration, security, or fairness of the Program has been
            compromised in any way. Disclaimer of Warranties: PARTICIPANTS AND
            PROSPECTIVE PARTICIPANTS EXPRESSLY UNDERSTAND AND AGREE THAT: (A)
            YOUR USE OF THE PROGRAM IS AT YOUR SOLE RISK, THE PROGRAM IS
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE”. BASIS AND THE COMPANY
            EXPRESSLY DISCLAIMS ALL WARRANTIES, CONDITIONS AND TERMS
            (COLLECTIVELY, “PROMISES”) OF ANY KIND, WHETHER EXPRESS OR IMPLIED
            BY STATUTE, COMMON LAW OR CUSTOM, INCLUDING, BUT NOT LIMITED TO,
            PROMISES AS TO PRODUCTS OR SERVICES OFFERED THROUGH THE USE OF THE
            PROGRAM, IMPLIED PROMISES OF MERCHANTABILITY, SATISFACTORY QUALITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B)RECOLLECT
            SYSTEMS INC. MAKES AND GIVES NO PROMISE THAT (i) THE PROGRAM WILL
            MEET YOUR REQUIREMENTS, (ii) BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR-FREE, (iii) THE RESULTS OBTAINED FROM THE USE OF THE PROGRAM
            WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS,
            SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE
            PROGRAM WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE
            SERVICE WILL BE CORRECTED, AND (C) ANY MATERIAL DOWNLOADED OR
            OTHERWISE OBTAINED THROUGH THE USE OF THE PROGRAM IS ACCESSED AT
            YOUR OWN DISCRETION AND RISK.
          </div>
          <h4>Other Terms</h4>
          <h5>General Terms.</h5>
          <div className='pb-2'>
            These Program Terms and Conditions constitute the entire agreement
            between Participants and RemotePass concerning Participants’ use of
            the Program. The failure of RemotePass to exercise or enforce any
            right or provision of these Program Terms and Conditions shall not
            constitute a waiver of such right or provision.
          </div>
          <h5>Term.</h5>
          <div className='pb-2'>
            The term of this Agreement will begin upon our acceptance of your
            Program application and will end when terminated by either party.
            Either you or we may terminate this Agreement at any time, with or
            without cause, by giving the other party notice of termination.
            Notice by email, to your address on our records, is considered
            sufficient notice to terminate this Agreement.
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default TermsModal
