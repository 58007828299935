import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-datepicker/dist/react-datepicker.css'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import store, { persistor } from './store'
import 'currency-flags/dist/currency-flags.min.css'
import './assets/scss/custom/custom_currency_flags.css'
import { ErrorBoundaryFallback } from './components/error-boundary-fallback'

toastr.options.extendedTimeOut = 20

export default function WrapperApp() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}
