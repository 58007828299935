import React, { createContext, useState } from 'react'

const timeOffFlowContext = createContext({
  policyFormData: null,
  setPolicyFormData: () => {},
})

export function TimeOffFlowContextProvider({ children, defaultData }) {
  const [policyFormData, setPolicyFormData] = useState(defaultData)

  function handleSetData(data) {
    setPolicyFormData((prevData) => ({ ...prevData, ...data }))
  }

  return (
    <timeOffFlowContext.Provider
      value={{ policyFormData, setPolicyFormData: handleSetData }}
    >
      {children}
    </timeOffFlowContext.Provider>
  )
}

export function useTimeOffFlowContext() {
  return React.useContext(timeOffFlowContext)
}
