import React from 'react'
import { Button, Col, Row } from 'reactstrap'

import withdrawProcessing from '../../assets/images/withdraw_processing.svg'
import {
  PAYMENT_METHODS_BY_ID,
  TOP_UP_CARD,
} from '../../core/config/payment-methods'
import { useResize } from '../../helpers/hooks'

const ConfirmationPage = ({ onComplete, data, bankInfo, paymentMethod }) => {
  const isMobile = useResize()
  return (
    <Row
      className='justify-content-center align-items-center'
      style={{ minHeight: '80vh' }}
    >
      <Col className='p-0'>
        <Row className='justify-content-center' style={{ fontSize: '10rem' }}>
          <img
            style={{ height: isMobile ? 200 : 400 }}
            src={withdrawProcessing}
            alt=''
          />
        </Row>
        <Row className='justify-content-center p-3'>
          <h2>
            {paymentMethod?.id === TOP_UP_CARD.id
              ? 'Withdrawal processed'
              : 'Withdrawal processing'}
          </h2>
        </Row>
        <Row className='justify-content-center text-center text-secondary'>
          <Col md={8}>
            {paymentMethod?.id === 4 ? (
              <p>
                Your request is processing, you will receive an email
                confirmation with your Reference ID. You’ll need to present the{' '}
                <span className='rp-font-bold text-dark'>Reference ID</span>{' '}
                along with your{' '}
                <span className='rp-font-bold text-dark'>identification</span>{' '}
                (passport or government id) to collect your funds from any of
                the{' '}
                <span className='rp-font-bold text-dark'>
                  Sroor Exchange cash points
                </span>
                .
              </p>
            ) : paymentMethod?.id === 1 ? (
              <p>
                Your withdrawal request has been received and currently
                processing. You should have the money in your account.
                <span className='rp-font-bold text-dark'>
                  {' '}
                  {bankInfo?.details['should_arrive"']}.
                </span>
                <br /> Your Transaction ID: {data?.ref}{' '}
              </p>
            ) : paymentMethod?.id === TOP_UP_CARD.id ? (
              <p>
                Your withdrawal request has been processed successfully, and the
                corresponding amount has been credited to your card balance.
                <br />
                Your Transaction ID: {data?.ref}{' '}
              </p>
            ) : (
              <p>
                Your withdrawal request has been received and currently
                processing. Allow a few hours to receive your funds on your{' '}
                {PAYMENT_METHODS_BY_ID[paymentMethod?.id]?.label ?? ''} account.
                <br />
                Your Transaction ID: {data?.ref}{' '}
              </p>
            )}
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Button
            onClick={onComplete}
            className='my-2 rp-font-bold'
            color='primary'
          >
            Back to Dashboard
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default ConfirmationPage
