import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabPane,
} from 'reactstrap'

import NoContent from '../../../../../components/NoContent'
import StyledTh from '../../../../../components/Table/StyledTh'
import TableComp from '../../../../../components/Table/TableComp'
import TableH from '../../../../../components/Table/TableH'
import Head from '../../../../../components/head'
import TabContent from '../../../../../components/ui/tabs'
import { CONTRACT_TYPES } from '../../../../../core/config/contract-types'
import { CONTRACT_STATUS } from '../../../../../helpers/enum'
import { useFetch } from '../../../../../helpers/hooks'
import { getContractDetailAdmin } from '../../../../../services/api'
import { updateContract } from '../../../../../store/contract/actions'
import ContractDetail from '../../../../Contract/ContractDetail'
import { MilestoneCard } from '../../../../Contract/ContractPage'
import Adjustments from '../../../../Contract/ContractPage/Adjustments'
import Documents from '../../../../Contract/ContractPage/Documents'
import Expenses from '../../../../Contract/ContractPage/Expenses'
import PaymentHistory from '../../../../Contract/ContractPage/Payments'
import Settings from '../../../../Contract/ContractPage/Settings'
import DataCollectionTab from '../../../../Contract/ContractPage/data-collection-tab'
import AdminEmployeeDocuments from '../../../../Contract/ContractPage/employee-documents'
import { WorkCard } from '../../../../Contract/ContractPage/submitted-work'
import { TimesOffTab } from '../../../../Contract/ContractPage/times-off-tab'
import Payslips from '../../../../Fulltime/Payslips'
import { AdminInvoiceList } from '../../../../user-invoices/admin-invoice-list'
import emptyDocs from './../../../../../assets/images/empty_docs.png'

const tabs = [
  { label: 'Contract Details', id: '5' },
  { label: 'Settings', id: '12' },
  { label: 'Data Collection', id: '16' },
  { label: 'Payments', id: '6' },
  {
    label: 'Adjustments',
    id: '15',
    show: (type) => type === CONTRACT_TYPES.FULL_TIME,
  },
  { label: 'Documents', id: '7' },
  {
    label: 'Employee Documents',
    id: '13',
    show: (type) => type === CONTRACT_TYPES.FULL_TIME,
  },
  {
    label: 'Milestones',
    id: '8',
    show: (type) => type === CONTRACT_TYPES.MILESTONES,
  },
  {
    label: 'Submitted Work',
    id: '8',
    show: (type) => type === CONTRACT_TYPES.PAY_AS_YOU_GO,
  },
  { label: 'Expenses', id: '9' },
  { label: 'Time off', id: '10' },
  { label: 'Invoices', id: '11' },
  {
    label: 'Payslips',
    id: '14',
    show: (type) => type === CONTRACT_TYPES.FULL_TIME,
  },
]

const ContractDetailAdmin = (props) => {
  const [activeTab, setActiveTab] = useState('5')
  // const history = useHistory()
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const id = new URLSearchParams(props.location.search).get('id')
  const contractDetails = useFetch(
    {
      autoFetch: true,
      action: getContractDetailAdmin,
      withAdminAccess: true,
      body: { id },
      onComplete: (data) => {
        dispatch(updateContract(data))
      },
    },
    [id],
  )

  const isContractOngoing =
    contractDetails.data?.status?.id === CONTRACT_STATUS.ONGOING.value

  function updateContractDetails() {
    contractDetails.startFetch({ id })
  }
  useEffect(() => {
    return () => {
      dispatch(updateContract(null))
    }
  }, [])

  const contractId = contractDetails.data?.id

  return (
    <div className='page-content'>
      <Head
        title={['Contract', contractId ? `#${contractId}` : null]
          .filter(Boolean)
          .join(' ')}
      />

      {contractDetails.isLoading ? (
        <Col style={{ minHeight: '30rem' }}>
          <Row
            style={{ minHeight: '30rem' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <Container fluid className='px-0'>
          <Nav
            tabs
            className='nav-tabs-custom nav-tabs-custom--bg-transparent pb-0.5'
          >
            {tabs.map(({ id, label, show }) => {
              const showTab =
                typeof show === 'function'
                  ? show(contractDetails.data?.type)
                  : true

              if (!showTab) return null

              return (
                <NavItem key={id}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({ active: activeTab === id })}
                    onClick={() => setActiveTab(id)}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>

          <TabContent activeTab={activeTab} className='p-0'>
            <TabPane tabId='5' className='py-3'>
              <ContractDetail
                isAdmin
                history={props.history}
                id={id}
                data={contractDetails.data}
                updateContractDetail={updateContractDetails}
              />
            </TabPane>
            <TabPane tabId='9' className='py-3'>
              <Expenses />
            </TabPane>
            <TabPane tabId='10' className='py-3'>
              <TimesOffTab contract={contractDetails.data} />
            </TabPane>
            <TabPane tabId='6' className='py-3'>
              <PaymentHistory contract={contractDetails.data} isAdmin />
            </TabPane>
            <TabPane tabId='7' className='py-3'>
              {contractDetails.data?.contractor ? (
                <Documents
                  isAdmin
                  docs={[contractDetails.data?.contractor]}
                  isContract
                  contractId={contractDetails.data?.id}
                />
              ) : (
                <Card>
                  <CardBody>
                    <NoContent
                      headline='No documents added'
                      subtitle={
                        user?.type === 'client'
                          ? 'All compliance documents will be shown here when added by the contractor'
                          : 'Your KYC & compliance documents will be shown here'
                      }
                      image={emptyDocs}
                    />
                  </CardBody>
                </Card>
              )}
            </TabPane>
            <TabPane tabId='13' className='py-3'>
              <AdminEmployeeDocuments isContractOngoing={isContractOngoing} />
            </TabPane>
            {contractDetails.data?.type === CONTRACT_TYPES.PAY_AS_YOU_GO && (
              <TabPane tabId='8' className='py-3'>
                {contractDetails.data?.works?.length > 0 && (
                  <Card className='py-1 rp-shadow-2'>
                    <div className='table-with-dropdown'>
                      <TableComp className='table-centered'>
                        <thead className='thead-light'>
                          <tr>
                            <StyledTh>Name</StyledTh>
                            <StyledTh>Date</StyledTh>
                            <StyledTh>Rate</StyledTh>
                            <StyledTh>Status</StyledTh>
                            <StyledTh />
                          </tr>
                        </thead>
                        <tbody>
                          {contractDetails.data?.works?.map((work, i) => (
                            <WorkCard key={`work-${i}`} work={work} isAdmin />
                          ))}
                        </tbody>
                      </TableComp>
                    </div>
                  </Card>
                )}
              </TabPane>
            )}
            {contractDetails.data?.type === CONTRACT_TYPES.MILESTONES && (
              <TabPane tabId='8' className='py-3'>
                <Card className='pb-1 rp-shadow-2'>
                  {contractDetails.data?.milestones?.length > 0 && (
                    <div className='overflow-visible'>
                      <TableH title={CONTRACT_TYPES.MILESTONES} leftSide='' />
                      <div className='table-with-dropdown'>
                        <TableComp>
                          <thead className='thead-light'>
                            <tr>
                              <StyledTh>Name</StyledTh>
                              <StyledTh>Amount</StyledTh>
                              <StyledTh>Status</StyledTh>
                              <StyledTh></StyledTh>
                            </tr>
                          </thead>
                          <tbody>
                            {contractDetails.data?.milestones?.map(
                              (milestone) => {
                                return (
                                  <MilestoneCard
                                    key={milestone?.id}
                                    milestone={milestone}
                                    data={contractDetails.data}
                                    isAdmin
                                  />
                                )
                              },
                            )}
                          </tbody>
                        </TableComp>
                      </div>
                    </div>
                  )}
                </Card>
              </TabPane>
            )}
            <TabPane tabId='11' className='py-3'>
              <AdminInvoiceList contractId={contractDetails.data?.id} />
            </TabPane>
            <TabPane tabId='12' className='py-3'>
              <Settings isAdmin onUpdate={updateContractDetails} />
            </TabPane>
            <TabPane tabId='14' className='py-3'>
              <Payslips
                contractData={contractDetails.data}
                isAdmin
                onUpdate={updateContractDetails}
              />
            </TabPane>
            <TabPane tabId='15' className='py-3'>
              <Adjustments
                contract={contractDetails.data}
                contractLoading={contractDetails.isLoading}
                isAdmin
              />
            </TabPane>
            <TabPane tabId='16' className='py-3'>
              <DataCollectionTab
                contractId={contractDetails.data?.id}
                contractLoading={contractDetails.isLoading}
                isAdmin
              />
            </TabPane>
          </TabContent>
        </Container>
      )}
    </div>
  )
}

export default ContractDetailAdmin
