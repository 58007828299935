import React from 'react'
import { connect } from 'react-redux'

import * as defaults from './../../config/defaults'

const wootricScriptId = 'wootric-settings'
const wootricBeaconId = 'wootric-beacon'

class Wootric extends React.Component {
  componentDidMount() {
    const { user } = this.props
    const setupScript = document.createElement('script')
    setupScript.type = 'text/javascript'
    setupScript.id = wootricScriptId
    setupScript.async = true
    setupScript.innerHTML = `
      wootric_no_surveyed_cookie = ${defaults.WOOTRIC_NO_SURVEYED_COOKIE};
      wootric_survey_immediately = ${defaults.WOOTRIC_SURVEY_IMMEDIATELY};
      window.wootricSettings = {
        email: '${user?.email}',
        created_at: ${~~(Date.now() / 1000)},
        account_token: "${defaults.WOOTRIC_ACCOUNT_TOKEN}",
        properties:{
          first_name: '${user.first_name}',
          last_name: '${user.last_name}',
          user_id: '${user.id}',
          email: '${user?.email}'
        }
      };
    `
    if (document.getElementById(wootricScriptId) === null) {
      document.body.appendChild(setupScript)
    }

    // Beacon
    const beacon = document.createElement('script')
    beacon.type = 'text/javascript'
    beacon.id = wootricBeaconId
    beacon.async = true

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js'
    beacon.onload = function () {
      window.wootric('run')
    }
    if (document.getElementById(wootricBeaconId) === null) {
      document.body.appendChild(beacon)
    }
  }

  render() {
    return <div />
  }
}

export default connect(
  (state) => ({ user: state.Account?.user }),
  null,
)(Wootric)
