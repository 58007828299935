import { MagnifyingGlass, X } from '@phosphor-icons/react'
import React, { forwardRef, useState } from 'react'
import { Input } from 'reactstrap'

import { cn } from 'ui'

const SearchBar = forwardRef(function SearchBar(
  {
    onQueryChanged,
    roundedLeftNone,
    placeholder,
    query,
    className,
    inputClassName,
    isClearable,
    style,
    hideIcon,
    type = 'text',
  },
  ref,
) {
  const [inputValue, setInputValue] = useState(query || '')
  const [timeoutId, setTimeoutId] = useState(null)

  const handleInputChange = (newValue) => {
    setInputValue(newValue)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const newTimeoutId = setTimeout(() => {
      onQueryChanged?.(newValue)
    }, 500)
    setTimeoutId(newTimeoutId)
  }

  return (
    <div style={style} className={cn('search-box tw-relative', className)}>
      {!hideIcon && (
        <MagnifyingGlass
          size={16}
          weight='bold'
          className='tw-pointer-events-none tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2'
        />
      )}

      <Input
        ref={ref}
        type={type}
        className={cn(
          '!tw-h-11',
          { '!tw-rounded-l-none': roundedLeftNone },
          inputClassName,
        )}
        placeholder={placeholder || 'Search...'}
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
      />

      {isClearable && inputValue && inputValue !== '' ? (
        <button
          type='button'
          className='tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 tw-cursor-pointer tw-p-1'
          onClick={() => handleInputChange('')}
        >
          <X size={16} weight='bold' />
        </button>
      ) : null}
    </div>
  )
})

export default SearchBar
