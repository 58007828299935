import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import toastr from 'toastr'

import Button from '../../../../components/ui/button'
import {
  SideMenu,
  sideMenuGridClass,
  SideMenuHeading,
} from '../../../../components/ui/side-menu'
import {
  VendorDetailForm,
  vendorDetailSchema,
} from '../create/create-vendor-step-1'
import { useFetch } from '../../../../helpers/hooks'
import { updateVendor } from '../../../../services/api-bill-payments'

const editVendorFormId = 'editVendorFormId'
export function EditVendorDetailsSideMenu({
  isOpen,
  toggle,
  updateVendors,
  vendor,
}) {
  const def = {
    name: vendor?.name,
    category_id: vendor?.category?.id,
    country_id: vendor?.country?.id,
    state_id: vendor?.state_id,
    city: vendor?.city === '' ? undefined : vendor?.city,
    address: vendor?.address === '' ? undefined : vendor?.address,
  }

  const { control, handleSubmit } = useForm({
    defaultValues: { ...def },
    resolver: yupResolver(vendorDetailSchema),
  })

  const { startFetch: _updateVendor, isLoading: isUpdatingVendor } = useFetch({
    action: updateVendor,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while updating the vendor')
      } else {
        toastr.success('Vendor updated successfully')
        toggle?.()
        updateVendors?.()
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  if (!isOpen) return null

  function onSubmit(values) {
    _updateVendor({ ...values, id: vendor?.id })
  }

  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName={sideMenuGridClass()}
    >
      <SideMenu.Header toggle={toggle}>
        <SideMenuHeading
          title='Vendor details'
          subTitle='Edit vendor details'
          onClickBack={toggle}
        />
      </SideMenu.Header>
      <SideMenu.Body>
        <form onSubmit={handleSubmit(onSubmit)} id={editVendorFormId}>
          <VendorDetailForm control={control} className='tw-space-y-4' />
        </form>
      </SideMenu.Body>
      <SideMenu.Footer>
        <Button
          type='button'
          onClick={toggle}
          outline
          color='light'
          disabled={isUpdatingVendor}
        >
          Cancel
        </Button>
        <Button
          formId={editVendorFormId}
          type='submit'
          disabled={isUpdatingVendor}
          loading={isUpdatingVendor}
        >
          Save
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}
