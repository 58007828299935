import React, { useState } from 'react'
import Button from '../../../../../components/ui/button'
import EditFormCondition from './edit-condition'
import DeleteConditionModal from './delete-condition'

import { PencilSimple, PlusCircle, TrashSimple } from '@phosphor-icons/react'
import { ActionsDropdown } from 'ui'

export function FormConditions({ form, refetch }) {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [formConditionIdToDelete, setFormConditionIdToDelete] = useState(null)
  const [conditionToEdit, setConditionToEdit] = useState(null)

  const getStepNameFromFieldId = (fieldId) => {
    return form.form_steps?.find((step) =>
      step.form_fields?.find((field) => field.id === fieldId),
    )?.title
  }

  const getFormStepName = (stepId) => {
    return form.form_steps?.find((step) => step.id === stepId)?.title
  }

  const getFormFieldName = (fieldId) => {
    return form.form_steps
      ?.map((step) => step.form_fields)
      .flat()
      .find((field) => field.id === fieldId)?.title
  }

  const removeUnderscores = (str) => {
    return str.replaceAll('_', ' ')
  }

  const isEnabled = form?.is_enabled

  return (
    <>
      {form?.conditions?.length ? (
        <h2 className='tw-text-xl tw-font-semibold'>
          {form?.conditions?.length +
            ' condition' +
            (form?.conditions?.length > 1 ? 's' : '')}
        </h2>
      ) : null}

      <div>
        {form?.conditions?.map((condition) => (
          <div
            className='tw-mb-6 tw-rounded tw-border tw-border-surface-30 tw-p-4'
            key={condition.id}
          >
            <div className='align-items-center justify-content-between tw-mb-4 tw-flex tw-gap-3'>
              <div className='tw-text-base tw-font-semibold'>
                <span className='tw-capitalize'>{condition.type}</span>{' '}
                {condition.target_item_type}{' '}
                {condition.target_item_type === 'step' && (
                  <span className='tw-text-primary'>
                    {getFormStepName(condition.target_item_id)}
                  </span>
                )}
                {condition.target_item_type === 'field' && (
                  <>
                    <span className='tw-text-primary'>
                      {getFormFieldName(condition.target_item_id)}
                    </span>{' '}
                    in step{' '}
                    <span className='tw-text-primary'>
                      {getStepNameFromFieldId(condition.target_item_id)}
                    </span>
                  </>
                )}{' '}
                if{' '}
                <i className='tw-text-primary'>
                  {condition.rules_evaluation_strategy}
                </i>{' '}
                of the rules below{' '}
                {condition.rules_evaluation_strategy === 'all' ? 'are' : 'is'}{' '}
                true:
              </div>

              <div className='tw-flex tw-gap-3'>
                {isEnabled ? null : (
                  <ActionsDropdown
                    data={[
                      {
                        label: 'Update',
                        icon: <PencilSimple size={16} />,
                        onClick: () => {
                          setConditionToEdit(condition)
                          setEditModalOpen(true)
                        },
                      },
                      {
                        label: 'Delete',
                        icon: <TrashSimple size={16} />,
                        onClick: () => {
                          setFormConditionIdToDelete(condition.id)
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </div>
            <div>
              {condition.rules.map((rule) => (
                <div
                  className='tw-mb-2 tw-rounded tw-border tw-border-surface-30 tw-bg-surface-10 tw-p-6 tw-text-sm'
                  key={condition.created_at + rule.value}
                >
                  <span className='tw-font-bold tw-capitalize tw-text-primary'>
                    {getFormFieldName(rule.form_field_id)}
                  </span>{' '}
                  in step {getStepNameFromFieldId(rule.form_field_id)}{' '}
                  <i className='tw-font-bold tw-text-primary'>
                    {removeUnderscores(rule.operator)}
                  </i>{' '}
                  &ldquo;{rule.value}&ldquo;
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {isEnabled ? null : (
        <Button
          className='tw-my-4'
          block
          textClassName='tw-flex tw-items-center tw-gap-2'
          outline
          color='light'
          onClick={() => setEditModalOpen(true)}
        >
          <PlusCircle size={20} /> Add condition
        </Button>
      )}

      {editModalOpen && (
        <EditFormCondition
          form={form}
          isOpen={editModalOpen}
          toggle={() => {
            setEditModalOpen(!editModalOpen)
            setConditionToEdit(null)
          }}
          editCondition={conditionToEdit}
          refetch={refetch}
        />
      )}

      {!!formConditionIdToDelete && (
        <DeleteConditionModal
          form={form}
          isOpen={!!formConditionIdToDelete}
          toggle={() => {
            setFormConditionIdToDelete(null)
          }}
          formId={form?.id}
          formConditionId={formConditionIdToDelete}
          refetch={refetch}
        />
      )}
    </>
  )
}
