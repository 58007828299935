export function getErrors(err) {
  if (!err || !Array.isArray(err?.error?.messages)) {
    return ''
  }

  return err.error.messages.join('<br/>')
}

export function getErrorMessage(err) {
  if (typeof err === 'string') {
    return err
  }

  if (Array.isArray(err?.messages)) {
    return err.messages.join('<br/>')
  }

  return typeof err === 'object'
    ? err?.message || err?.[0]?.message
    : 'Error Occurred'
}
