import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import * as yup from 'yup'

import ControlledSelect from '../../../../components/ControlledSelect'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import {
  approveClientAdjustment,
  approveWalletAdjustment,
  assignAdjustmentApprover,
  assignRequestApprover,
  getClientRequestApprovers,
  getWalletAdjustmentApprovers,
  rejectClientAdjustment,
  rejectWalletAdjustment,
} from '../../../../services/api'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { InvalidFormFeedback } from './balance-adjustment-modal'

export function getFullName(user) {
  return [user?.first_name, user?.middle_name, user?.last_name]
    .filter(Boolean)
    .join(' ')
}

function handleModalClassNames(isOpen) {
  if (isOpen) {
    document.body.classList.remove('modal-open')
  } else {
    document.body.classList.add('modal-open')
  }
}

const statusColor = {
  pending: 'warning',
  approved: 'success',
  rejected: 'danger',
}

export default function BalancesList({
  balances,
  paginator,
  page,
  setPage,
  loading,
  refreshData,
  isCompany,
}) {
  const [changeApproverModalOpen, setChangeApproverModalOpen] = useState(false)
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)

  const { startFetch: acceptAdjustment, isLoading: accepting } = useFetch({
    action: isCompany ? approveClientAdjustment : approveWalletAdjustment,
    withAdminAccess: true,
    onComplete: refreshData,
  })

  const columns = useMemo(
    () =>
      [
        !isCompany ? null : { accessor: 'transaction_ref', Header: 'TRX ID' },
        {
          Header: 'Approver',
          accessor: 'approver',
          Cell: ({ cellData }) => getFullName(cellData),
        },
        {
          Header: 'Requester',
          accessor: 'requester',
          Cell: ({ cellData }) => getFullName(cellData),
        },
        {
          Header: 'Requested at',
          accessor: 'created_at',
          Cell: ({ cellData }) => format(cellData * 1000, 'dd/MM/yyyy HH:mm'),
        },
        {
          Header: isCompany ? 'Client' : 'Contractor',
          accessor: isCompany ? 'company_name' : 'user',
          Cell: ({ cellData }) => {
            return isCompany
              ? cellData
              : `${getFullName(cellData)} (${cellData?.email})`
          },
        },
        isCompany ? null : { Header: 'Contractor ID', accessor: 'user.id' },
        {
          accessor: 'amount',
          Header: 'Amount',
          Cell: ({ rowData }) => {
            const formatter = getCurrencyFormatter(rowData?.currency?.code)
            return formatter.format(rowData?.amount)
          },
        },
        isCompany
          ? null
          : {
              Header: 'USD Amount',
              accessor: 'amount_usd',
              Cell: ({ cellData }) => getCurrencyFormatter().format(cellData),
            },
        {
          Header: 'Type',
          accessor: isCompany ? 'type' : 'method',
          Cell: ({ cellData }) => {
            return isCompany ? cellData : cellData?.name
          },
        },
        { accessor: 'note', Header: 'Note' },
        {
          accessor: 'status',
          Header: 'Status',
          Cell: ({ cellData: status }) => {
            return (
              <BadgeX className='rp-capitalize' status={statusColor[status]}>
                {status}
              </BadgeX>
            )
          },
        },
        {
          Header: 'Actions',
          Cell: ({ rowData }) => {
            const isApprover = rowData?.is_approver
            const isRequester = rowData?.is_requester

            if (['approved', 'rejected'].includes(rowData?.status)) {
              return null
            }

            return (
              <div className='d-flex gap-8'>
                {!isApprover ? null : (
                  <>
                    <Button
                      size='sm'
                      color='success'
                      onClick={() =>
                        acceptAdjustment(
                          isCompany
                            ? { request_id: rowData?.id }
                            : { adjustment_id: rowData?.id },
                        )
                      }
                      disabled={accepting}
                      loading={accepting}
                    >
                      Accept
                    </Button>
                    <Button
                      size='sm'
                      color='danger'
                      outline
                      onClick={() => triggerReject(rowData)}
                      disabled={accepting}
                      loading={accepting}
                    >
                      Reject
                    </Button>
                  </>
                )}

                {!isRequester ? null : (
                  <Button
                    size='sm'
                    color='info'
                    outline
                    onClick={() => openChangeApproverModal(rowData)}
                    disabled={accepting}
                    loading={accepting}
                  >
                    Change approver
                  </Button>
                )}
              </div>
            )
          },
        },
      ].filter(Boolean),
    [acceptAdjustment, accepting, isCompany],
  )

  function openChangeApproverModal(data) {
    setChangeApproverModalOpen(true)
    setSelectedRowData(data)
  }

  function toggleApproverModal() {
    setChangeApproverModalOpen((open) => {
      handleModalClassNames(open)
      return !open
    })
  }

  function toggleRejectionModal() {
    setRejectionModalOpen((open) => {
      handleModalClassNames(open)
      return !open
    })
  }

  function triggerReject(data) {
    setRejectionModalOpen(true)
    setSelectedRowData(data)
  }

  function handleRefreshData() {
    refreshData()
    setSelectedRowData(null)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <ChangeApproverModal
        isOpen={changeApproverModalOpen}
        toggle={toggleApproverModal}
        data={selectedRowData}
        refreshData={handleRefreshData}
        isCompany={isCompany}
      />

      <RejectionModal
        isOpen={rejectionModalOpen}
        toggle={toggleRejectionModal}
        data={selectedRowData}
        refreshData={handleRefreshData}
        isCompany={isCompany}
      />

      <DataTable columns={columns} data={balances} responsive />

      <div className='d-flex justify-content-end mt-3 px-4'>
        <Pagination
          activePage={page}
          onChange={setPage}
          itemsCountPerPage={paginator?.per_page ?? 50}
          totalItemsCount={paginator?.total ?? 0}
        />
      </div>
    </>
  )
}

const changeApproverFormId = 'changeApproverFormId'

function ChangeApproverModal({ isOpen, toggle, data, refreshData, isCompany }) {
  const { data: approvers } = useFetch(
    {
      action: isCompany
        ? getClientRequestApprovers
        : getWalletAdjustmentApprovers,
      withAdminAccess: true,
      autoFetch: isOpen,
    },
    [isOpen],
  )

  const { startFetch: assignApprover, isLoading: assigning } = useFetch({
    action: isCompany ? assignRequestApprover : assignAdjustmentApprover,
    withAdminAccess: true,
    onComplete: () => {
      toggle()
      refreshData()
    },
  })

  const approverOptions = approvers?.map((approver) => {
    const fullName = [approver?.first_name, approver?.last_name]
      .filter(Boolean)
      .join(' ')

    return {
      ...approver,
      label: fullName,
      value: approver?.id,
    }
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        approver_id: yup.string().required('Approver is required'),
      }),
    ),
  })

  function onSubmit(values) {
    const body = {
      ...values,
      ...(isCompany ? { request_id: data?.id } : { adjustment_id: data?.id }),
    }

    assignApprover(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Approver</ModalHeader>
      <ModalBody>
        <div className='mb-3'>
          <div>Old approver</div>
          <div
            className='align-items-center border border-light d-flex mt-1 px-2 py-2 rounded text-secondary'
            style={{ height: 42 }}
          >
            {getFullName(data?.approver)}
          </div>
        </div>

        <Form id={changeApproverFormId} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <ControlledSelect
              control={control}
              label='New approver'
              name='approver_id'
              error={errors?.approver_id?.message}
              options={approverOptions}
            />
            <InvalidFormFeedback error={errors?.approver_id?.message} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={assigning}>
          Cancel
        </Button>

        <Button
          formId={changeApproverFormId}
          type='submit'
          disabled={assigning}
          loading={assigning}
        >
          Change Approver
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function RejectionModal({ isOpen, toggle, data, refreshData, isCompany }) {
  const [reason, setReason] = useState('')

  const { startFetch: rejectAdjustment, isLoading: rejecting } = useFetch({
    action: isCompany ? rejectClientAdjustment : rejectWalletAdjustment,
    withAdminAccess: true,
    onComplete: () => {
      refreshData()
      toggle()
      setReason('')
    },
  })

  function handleReject() {
    rejectAdjustment(
      isCompany
        ? { request_id: data?.id, reason }
        : { adjustment_id: data?.id, reason },
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{`Reject ${
        isCompany ? 'request' : 'adjustment'
      }`}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for='reason'>Rejection reason</Label>
          <Input
            type='textarea'
            name='reason'
            id='reason'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={rejecting}>
          Cancel
        </Button>

        <Button
          onClick={handleReject}
          disabled={rejecting}
          loading={rejecting}
          color='danger'
        >
          Reject adjustment
        </Button>
      </ModalFooter>
    </Modal>
  )
}
