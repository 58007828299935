import React from 'react'

import EmployeeDocsList from '../../Fulltime/employee-documents/employee-docs-list'

export default function AdminEmployeeDocuments({ isContractOngoing }) {
  const isAdmin = location.pathname.startsWith('/admin')

  return (
    <EmployeeDocsList isAdmin={isAdmin} isContractOngoing={isContractOngoing} />
  )
}
