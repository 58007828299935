import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledInput from '../../../components/ControlledInput'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { useFetch } from '../../../helpers/hooks'
import {
  addMilestone,
  getContractCustomFields,
  uploadTempFile,
} from '../../../services/api'
import { CONTRACT_TYPE_MAP } from '../ContractPage/settings/custom-fields-section'
import LabelContent from '../CreateContract/components/label-content'
import { AttachmentField, AttributeFields } from './submit-work-modal'

export function getCustomFieldSchema(schema, fields) {
  return schema.test({
    name: 'custom_field',
    message: 'This custom field is required',
    test: (value) => {
      const isAllRequiredFieldsFilled = fields
        .filter((field) => field.required === 1)
        .every((field) => !!value?.[field.id])

      return isAllRequiredFieldsFilled
    },
  })
}

const formId = 'submit-milestone-form'
export function AddMilestoneModal({
  contract,
  isOpen,
  toggle,
  onMilestoneAdded,
}) {
  const [showAttachment, setShowAttachment] = useState(false)
  function toggleAttachment() {
    setShowAttachment((open) => {
      // if closing, reset the attachment
      if (open) setValue('attachment', [])

      return !open
    })
  }

  const isFte = contract?.type === CONTRACT_TYPES.FULL_TIME
  const itemType = isFte ? 'invoice' : 'milestone'

  const { data: fields, isLoading: isFieldsLoading } = useFetch(
    {
      action: getContractCustomFields,
      autoFetch: !!contract?.id && !isFte,
      initResult: [],
      body: {
        area: 'work',
        contract_type: CONTRACT_TYPE_MAP[contract?.type],
        active: 1,
        contractId: contract?.id,
      },
    },
    [contract?.id, isFte],
  )

  const {
    startFetch: uploadWorkFile,
    isLoading: isUploadingWorkFile,
    data: uploadedWork,
  } = useFetch({
    action: uploadTempFile,
  })

  const { startFetch: addMilestoneWork, isLoading: addingMilestone } = useFetch(
    {
      action: addMilestone,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message)
        } else {
          toastr.success(itemType + ' added successfully')
          onMilestoneAdded?.()
          toggle?.()
        }
      },
      onError: (error) => {
        toastr.error(error)
      },
    },
  )

  const actionsDisabled =
    isFieldsLoading || isUploadingWorkFile || addingMilestone

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      amount: null,
      attachment: [],
      // This default is important to avoid `custom_field` being an array
      custom_field: {},
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        amount: yup.number().required('Amount is required'),
        attachment: yup.mixed().when('$', {
          is: () => showAttachment,
          then: (schema) =>
            schema
              .test({
                message: 'Attachment is required',
                test: (value) => value?.length > 0,
              })
              .required('Attachment is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        custom_field: getCustomFieldSchema(yup.object(), fields),
      }),
    ),
  })
  const attachment = watch('attachment')

  function onSubmit(values) {
    const body = {
      contract_id: contract.id,
      name: values.name,
      amount: values.amount,
    }

    // Add the file if uploaded
    if (values.attachment?.length > 0 && showAttachment) {
      body.file = uploadedWork?.path
    }

    // add custom fields
    const customFieldsValues = Object.entries(values.custom_field).filter(
      ([, value]) => !!value,
    )
    if (fields?.length > 0 && customFieldsValues.length > 0) {
      body.attributes = customFieldsValues.map(([id, value]) => ({
        id: Number(id),
        value: value ?? '',
      }))
    }

    addMilestoneWork(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Add {itemType}
      </ModalHeader>
      <ModalBody>
        {isFieldsLoading ? (
          <Loader minHeight='240px' />
        ) : (
          <form
            className='tw-flex tw-flex-col tw-gap-4'
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledInput
              control={control}
              name='name'
              id='name'
              label={<LabelContent required>Name</LabelContent>}
              placeholder='Name'
            />

            <FormGroup className='!tw-mb-0'>
              <label htmlFor='amount'>
                <LabelContent required>Amount</LabelContent>
              </label>

              <InputGroup className='tw-w-full !tw-items-start'>
                <ControlledInput
                  control={control}
                  name='amount'
                  id='amount'
                  placeholder='Value'
                  type='number'
                  step='0.1'
                  wrapperClassName='tw-flex-grow'
                  className='!tw-rounded-r-none'
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>{contract?.currency?.code}</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>

            {isFte ? null : (
              <AttributeFields
                fields={fields}
                control={control}
                errors={errors}
              />
            )}

            <AttachmentField
              control={control}
              errors={errors}
              setValue={setValue}
              attachment={attachment}
              isUploadingWorkFile={isUploadingWorkFile}
              uploadWorkFile={uploadWorkFile}
              showAttachement={showAttachment}
              toggleAttachement={toggleAttachment}
            />
          </form>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggle}
          color='light'
          outline
          disabled={actionsDisabled}
        >
          Cancel
        </Button>

        <Button
          type='submit'
          formId={formId}
          disabled={actionsDisabled}
          loading={addingMilestone}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}
