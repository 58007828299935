function SpawnLogger(name = 'RPLOGGER', separator = ' : ') {
  const logLevels = {
    info: 'info',
    debug: 'log',
    warn: 'warn',
    error: 'error',
    log: 'log',
  }

  function baseLog(level = logLevels.log) {
    return function (message) {
      const date = new Date().toISOString()
      const formattedLog = [
        name,
        date,
        String(level).toUpperCase(),
        JSON.stringify(message),
      ]
        .filter(Boolean)
        .join(` ${separator} `)

      // eslint-disable-next-line no-console
      const logFunc = console?.[level] ?? console.log

      logFunc(formattedLog)
    }
  }

  return {
    info: baseLog(logLevels.info),
    debug: baseLog(logLevels.debug),
    warn: baseLog(logLevels.warn),
    error: baseLog(logLevels.error),
  }
}

const logger = new SpawnLogger()

export default logger
