import React from 'react'
import { Input } from 'reactstrap'

export function SearchFiltersBar({
  filters,
  setUrlState,
  placeholder = 'Search by contract reference or contractor name',
}) {
  return (
    <div className='tw-grid tw-flex-grow tw-gap-2'>
      <Input
        type='text'
        placeholder={placeholder}
        value={filters?.search_key}
        onChange={(e) =>
          setUrlState({ search_key: e.target.value || undefined, page: 1 })
        }
      />
    </div>
  )
}
