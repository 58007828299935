import React from 'react'
import cx from 'classnames'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { Spinner } from 'reactstrap'

export default function BalanceDisplay({
  balance,
  className,
  loadingCardDetails,
}) {
  const { currency, amount } = balance ?? {}

  const formatter = getCurrencyFormatter(currency)

  const isUnavailable = Number.isNaN(amount) || !amount
  return (
    <div
      className={cx('justify-content-between align-items-center', className)}
    >
      <div className='font-size-20'>Balance</div>
      {loadingCardDetails ? (
        <Spinner className='mr-3' color='var(--secondary-100)' size='sm' />
      ) : (
        <div>
          <span className='font-size-20 font-weight-bold mr-2'>
            {isUnavailable ? 'Unavailable' : formatter.format(amount)}
          </span>
          {isUnavailable ? null : (
            <span className='font-size-20 text-secondary'>{currency}</span>
          )}
        </div>
      )}
    </div>
  )
}
