import React from 'react'
import { Controller } from 'react-hook-form'

import DropzoneInput from '../Common/dropzone-input'
import { getInputErrorMessage } from '../Forms/get-input-error-message'

export default function ControlledDropzoneInput({
  control,
  name,
  children,
  error: externalError,
  onDropAccepted,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, onChange }, fieldState: { error } }) => {
        const theError = getInputErrorMessage(externalError || error)

        return (
          <DropzoneInput
            name={name}
            invalid={error}
            error={theError}
            {...props}
            onDropAccepted={(...args) => {
              onChange(...args)
              onDropAccepted?.(...args)
            }}
          >
            {children}
          </DropzoneInput>
        )
      }}
    />
  )
}
