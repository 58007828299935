import cx from 'classnames'
import React from 'react'
import { Col, Row } from 'reactstrap'

import { Stepper } from 'ui'

export default function Steps({
  data,
  activeTab,
  noLastAction,
  className,
  userType,
}) {
  // Using old stepper until the new one is tested
  // Especially for the userType & noLastAction props
  const STEPPER_NEW = false

  if (STEPPER_NEW) {
    return (
      <Stepper steps={data} activeStep={activeTab} className='tw-flex-grow' />
    )
  }

  return (
    <LegacySteps
      data={data}
      activeTab={activeTab}
      noLastAction={noLastAction}
      className={className}
      userType={userType}
    />
  )
}

function LegacySteps({ data, activeTab, noLastAction, className, userType }) {
  const getIndicator = (key) => {
    let indicator = <span></span>
    const style = { width: 16, height: 16, borderRadius: 8, borderWidth: 10 }
    if (userType === 1) {
      if (key === activeTab) {
        indicator = (
          <span className='border-primary border-indicator' style={style} />
        )
      } else if (key < activeTab) {
        indicator = (
          <span
            className='bg-primary border border-primary text-center'
            style={style}
          >
            <i className='bx bx-check text-white font-weight-bolder' />
          </span>
        )
      } else {
        indicator = <span className='border border-light' style={style} />
      }
    } else {
      if (key === activeTab) {
        if (noLastAction && data?.length - 1 === key) {
          indicator = (
            <span
              className='bg-primary border border-primary text-center'
              style={style}
            >
              <i className='bx bx-check text-white font-weight-bolder' />
            </span>
          )
        } else {
          indicator = (
            <span className='border-primary border-indicator' style={style} />
          )
        }
      }
      if (key > activeTab) {
        indicator = <span className='border border-light' style={style} />
      }
      if (key < activeTab) {
        indicator = (
          <span
            className='bg-primary border border-primary text-center'
            style={style}
          >
            <i className='bx bx-check text-white font-weight-bolder' />
          </span>
        )
      }
      if (data[key] === '') {
        indicator = <span style={{ height: 16 }} />
      }
    }
    return indicator
  }

  return (
    <Row
      className={cx(
        'align-items-start flex-nowrap flex-grow-1 font-size-11 m-0',
        className,
      )}
      style={{ paddingTop: '0.125rem' }}
    >
      {data?.map((e, key) => (
        <Wrapper
          key={`step-${key}`}
          isCol={key !== data?.length - 1 && key !== 0}
        >
          <Row className='align-items-center mb-1'>
            <Col
              className={
                activeTab >= key ||
                (activeTab === key && key === data?.length - 1 && noLastAction)
                  ? 'bg-primary p-0 m-0'
                  : 'bg-light p-0 m-0'
              }
              style={{
                height: key !== 0 ? 1 : 0,
                marginLeft: -16,
                marginRight: -16,
              }}
            />
            {getIndicator(key)}
            <Col
              className={
                activeTab > key ||
                (activeTab === key + 1 &&
                  key === data?.length - 2 &&
                  noLastAction)
                  ? 'bg-primary p-0 m-0'
                  : 'bg-light p-0 m-0'
              }
              style={{
                height: key !== data?.length - 1 ? 1 : 0,
                marginLeft: -16,
                marginRight: -16,
              }}
            />
          </Row>
          <Row
            className={`${
              activeTab >= key ? 'text-primary' : 'text-muted'
            } text-center justify-content-center align-items-center p-0 m-0`}
            style={{ textAlign: 'center', minWidth: 100 }}
          >
            {e}
          </Row>
        </Wrapper>
      ))}
    </Row>
  )
}

function Wrapper({ isCol, children }) {
  return isCol ? (
    <Col>{children}</Col>
  ) : (
    <div className='flex-grow-1'>{children}</div>
  )
}
