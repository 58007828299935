export default function kFormatter(num) {
  const number = parseFloat(num)

  if (isNaN(number)) {
    return ''
  }

  if (Math.abs(number) >= 1000000) {
    return Math.sign(number) * (Math.abs(number) / 1000000).toFixed(1) + 'm'
  } else if (Math.abs(number) >= 1000) {
    return Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + 'k'
  } else {
    return Math.sign(number) * Math.abs(number)
  }
}
