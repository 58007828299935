import {
  NETSUITE_SUBMIT_ACCOUNT_MAPPING,
  NETSUITE_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  NETSUITE_UPDATE_CONTRACTOR,
  NETSUITE_UPDATE_ORGANIZATION,
  NETSUITE_UPDATE_ORGANIZATIONS,
  NETSUITE_UPDATE_PRE_DATA,
  NETSUITE_UPDATE_REQUEST,
  NETSUITE_INIT,
} from './actionTypes'

const initialState = {
  preData: null,
  accountMapping: {},
  contractors: [],
  request: null,
}

const ns = (state = initialState, action) => {
  let categories = []
  switch (action.type) {
    case NETSUITE_UPDATE_PRE_DATA:
      return {
        ...state,
        preData: action.payload,
        contractors: action.payload.contractors,
      }
    case NETSUITE_UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      }
    case NETSUITE_UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      }
    case NETSUITE_INIT:
      return initialState
    case NETSUITE_SUBMIT_ACCOUNT_MAPPING:
      return {
        ...state,
        accountMapping: action.payload,
      }
    case NETSUITE_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST:
      categories = Object.keys(action.payload).map((e) => ({
        rp: e,
        ns: action.payload[e],
      }))
      return {
        ...state,
        accountMapping: action.payload,
        request: { categories },
      }
    case NETSUITE_UPDATE_CONTRACTOR:
      return {
        ...state,
        contractors: state.contractors.map((e) =>
          e.contractor_id === action.payload.contractor_id ? action.payload : e,
        ),
      }
    case NETSUITE_UPDATE_REQUEST:
      if (!action.payload) {
        return {
          ...state,
          request: null,
        }
      }

      if (!action.payload?.isUpdate) {
        categories = Object.keys(state?.accountMapping).map((e) => ({
          rp: e,
          ns: state.accountMapping[e],
        }))
      }
      // eslint-disable-next-line no-case-declarations
      const contractors = state?.contractors.map((e) => {
        const object = { rp: e?.contractor_id }
        if (e.categories && typeof e.categories === 'object') {
          object.categories = Object.keys(e.categories).map((k) => ({
            rp: k,
            ns: e.categories[k],
          }))
        }
        if (e.categories && Array.isArray(e.categories)) {
          object.categories = e.categories.map((k) => ({
            rp: k?.category_id,
            ns: k?.netsuite_id,
          }))
        }
        if (e?.xero?.value || e?.netsuite_id) {
          object.ns = e?.xero?.value || e?.netsuite_id
        }
        return object
      })
      if (action.payload?.isUpdate) {
        return {
          ...state,
          request: {
            ...action.payload,
            contractors,
          },
        }
      } else {
        return {
          ...state,
          request: {
            ...action.payload,
            categories,
            contractors,
          },
        }
      }

    default:
      return state
  }
}

export default ns
