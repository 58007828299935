import { XCircle } from '@phosphor-icons/react'
import React from 'react'
import { cn } from 'ui'

export default function InputFeedback({
  children,
  className,
  icon = <XCircle className='tw-size-4 tw-flex-shrink-0' />,
}) {
  return (
    <small
      className={cn(
        'tw-flex tw-gap-1 tw-text-xs tw-text-systemRed-100 first-letter:tw-capitalize',
        className,
      )}
    >
      {icon}
      {children}
    </small>
  )
}
