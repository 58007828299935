import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Card, Container } from 'reactstrap'
import { useFetch } from '../../../../helpers/hooks'
import {
  deleteAdminForm,
  disableAdminForm,
  enableAdminForm,
  getAdminForm,
  updateFormName,
} from '../../../../services/api'
import Button from '../../../../components/ui/button'
import AddStep from './components/add-step'
import Step from './components/step'
import Loading from './components/loading'
import FormField from './components/form-field'
import AddOrUpdateField from './components/add-field'
import toastr from 'toastr'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import IconButton from '../../../../components/ui/icon-button'
import { ArrowLeft, PlusCircle, TrashSimple } from '@phosphor-icons/react'
import { Badge, TextInput, Toggle } from 'ui'
import NavTabs from '../../../../components/ui/nav-tabs'
import { FormPreview } from './components/form-preview'
import { FormConditions } from './components/form-conditions'
import { ConfirmationModal } from '../../../remotepass-cards/components/active-credit-card-section'

function FormPage() {
  const [showAddStep, setShowAddStep] = useState(false)
  const [activeTab, setActiveTab] = useState('builder')
  const [showAddField, setShowAddField] = useState({ show: false, field: null })
  const [formName, setFormName] = useState('')
  const [deleteFormModal, setDeleteFormModal] = useState(false)
  const { id } = useParams()
  const history = useHistory()
  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/admin/forms')
  }

  const {
    data: form,
    startFetch: refetch,
    isLoading,
  } = useFetch({
    action: getAdminForm,
    withAdminAccess: true,
    onError: (error) => {
      toastr.error(error)
    },
    onComplete: (data) => {
      setFormName(data.name)
    },
    autoFetch: true,
    body: { id },
  })

  const { startFetch: enableForm, isLoading: isEnabling } = useFetch({
    action: enableAdminForm,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Form enabled successfully')
      refetch()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: disableForm, isLoading: isDisabling } = useFetch({
    action: disableAdminForm,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Form disabled successfully')
      refetch()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: updateForm, isLoading: updatingForm } = useFetch({
    action: updateFormName,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Form Renamed successfully')
      refetch()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: deleteForm, isLoading: isDeleting } = useFetch({
    action: deleteAdminForm,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Form deleted successfully')
      setDeleteFormModal(null)
      history.pop()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleFormNameUpdate = () => {
    updateForm({ id, name: formName })
  }

  if (!form || (isLoading && !form)) {
    return <Loading />
  }
  const isFormEnabled = form?.is_enabled
  return (
    <Container className='tw-mt-24 tw-flex tw-flex-col tw-gap-4'>
      <div className='tw-flex tw-flex-col tw-items-start'>
        <IconButton
          color='transparent'
          size={24}
          onClick={handleReturn}
          icon={<ArrowLeft size={24} />}
        />
        <h1 className='tw-py-4'>{form?.name}</h1>
      </div>
      <NavTabs
        className='tw-bg-white'
        linkClassName='!tw-p-4'
        options={[
          { label: 'Builder', value: 'builder', disabled: isLoading },
          { label: 'Preview', value: 'preview', disabled: isLoading },
          { label: 'Conditions', value: 'conditions', disabled: isLoading },
        ]}
        activeOption={activeTab}
        onClickOption={(option) => setActiveTab(option.value)}
      />
      <div className='tw-flex tw-gap-4'>
        <div className='tw-flex tw-flex-1 tw-items-center tw-justify-between tw-bg-white tw-p-6'>
          <div>
            <div className='tw-text-base tw-font-bold'>Enable Form</div>
            <div className='tw-text-sm tw-text-text-60'>
              To edit this form, you need to disable it first
            </div>
          </div>
          <div className='tw-flex tw-items-center tw-gap-4'>
            <Badge color={isFormEnabled ? 'primary' : 'red'}>
              {isFormEnabled ? 'ENABLED' : 'DISABLED'}
            </Badge>
            <Toggle
              onChange={() => {
                isFormEnabled
                  ? disableForm({ form_id: id })
                  : enableForm({ form_id: id })
              }}
              isDisabled={isEnabling || isDisabling || isLoading}
              className='tw-mb-0'
              isSelected={isFormEnabled}
            />
          </div>
        </div>
        <div className='tw-flex tw-flex-1 tw-items-center tw-justify-between tw-bg-white tw-p-6'>
          <div className='tw-flex-[2]'>
            <div className='tw-text-base tw-font-bold'>Form Name</div>
            <div className='tw-text-sm tw-text-text-60'>
              Edit your form name here
            </div>
          </div>
          <div className='tw-flex tw-flex-[3] tw-items-center tw-gap-4'>
            <TextInput
              wrapperClassName='tw-flex-grow'
              placeholder='Name'
              value={formName}
              disabled={updatingForm || isFormEnabled}
              onChange={(ev) => setFormName(ev.target.value)}
            />
            <Button
              onClick={handleFormNameUpdate}
              size='sm'
              outline
              disabled={updatingForm || isFormEnabled}
            >
              Change
            </Button>
          </div>
        </div>
      </div>
      {activeTab === 'builder' && (
        <div>
          {form?.form_steps?.length > 0 ? null : (
            <Card className='tw-p-4'>
              {form.form_fields?.map((field) => (
                <FormField
                  onUpdateField={(field) =>
                    setShowAddField({ show: true, field })
                  }
                  notEditable={isFormEnabled}
                  key={field.id}
                  field={field}
                  refetch={refetch}
                />
              ))}
              {showAddField.show && (
                <AddOrUpdateField
                  refetch={refetch}
                  form={form}
                  isOpen={showAddField.show}
                  field={showAddField.field}
                  toggle={() => setShowAddField({ show: false, field: null })}
                />
              )}
              {!isFormEnabled && (
                <Button
                  className='tw-my-4 !tw-border-surface-30'
                  color='light'
                  textClassName='tw-text-black tw-flex tw-grow-1 tw-items-center tw-gap-2'
                  outline
                  block
                  onClick={() => setShowAddField({ show: true, field: null })}
                >
                  <PlusCircle /> Add Field
                </Button>
              )}
            </Card>
          )}
          <div>
            {form?.form_steps?.map((step) => (
              <Step
                notEditable={isFormEnabled}
                refetch={refetch}
                key={step.id}
                step={step}
              />
            ))}
            {showAddStep && (
              <AddStep
                toggle={() => setShowAddStep(false)}
                refetch={refetch}
                isOpen={showAddStep}
                form={form}
              />
            )}
            {!form.form_fields?.length && !isFormEnabled && (
              <>
                <div className='tw-mb-6 tw-rounded tw-bg-white tw-p-6'>
                  <Button
                    color='primary'
                    textClassName='tw-flex tw-place-items-center tw-gap-2'
                    outline
                    block
                    onClick={() => setShowAddStep(true)}
                  >
                    <PlusCircle size={18} /> Add Step
                  </Button>
                </div>
              </>
            )}
          </div>
          {isFormEnabled ? null : (
            <div className='tw-mb-6 tw-rounded tw-bg-white tw-p-6'>
              <Button
                textClassName='tw-flex tw-place-items-center tw-gap-2 tw-text-systemRed'
                color='transparent'
                onClick={() => setDeleteFormModal(true)}
              >
                <TrashSimple size={20} /> Delete Form
              </Button>
            </div>
          )}
        </div>
      )}
      {activeTab === 'preview' && (
        <Card className='tw-p-6'>
          <FormPreview form={form} />
        </Card>
      )}
      {activeTab === 'conditions' && (
        <Card className='tw-p-6'>
          <FormConditions form={form} refetch={refetch} />
        </Card>
      )}
      <ConfirmationModal
        isOpen={deleteFormModal}
        title='Delete Form'
        onConfirm={() => {
          deleteForm({ form_id: form.id })
        }}
        content={
          <div className='tw-text-center tw-text-lg'>
            Are you sure you want to delete this form?
          </div>
        }
        confirmColor='danger'
        centered
        confirmText='Delete'
        loading={isDeleting}
        toggle={() => setDeleteFormModal(false)}
      />
    </Container>
  )
}

export default FormPage
