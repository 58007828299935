import { AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { FormGroup, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../../helpers/hooks'
import { requestContractTermination } from '../../../services/api'
import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { format } from 'date-fns'
import Alert from '../../../components/ui/alert'
import { Check, Info } from '@phosphor-icons/react'
import Loader from '../../../components/ui/loader'
import Button from '../../../components/ui/button'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'

const reasons = [
  {
    value: 'employer termination',
    label: 'Employer termination',
  },
  {
    value: 'employee resignation',
    label: 'Employee resignation',
  },
]
const RequestTerminationModal = ({ show, hide, contract, updated }) => {
  const [reason, setReason] = useState(null)
  const [tentativeLastDay, setTentativeLastDay] = useState(null)
  const [details, setDetails] = useState(null)
  const [requestCompleted, setRequestCompleted] = useState(false)

  const today = new Date()
  const tomorrowDate = today.setDate(today.getDate() + 1)

  const requestTermination = useFetch({
    action: requestContractTermination,
    onComplete: () => {
      setRequestCompleted(true)
    },
    onError: (e) => {
      toastr.error(e)
    },
  })
  return (
    <Modal isOpen={show} toggle={hide} className='p-0 p-md-5' unmountOnClose>
      <ModalHeader close={<ModalCloseButton toggle={hide} />}>
        {!requestCompleted && !requestTermination?.isLoading
          ? 'Request Termination'
          : ''}
      </ModalHeader>
      <AvForm
        onValidSubmit={() => {
          if (reason && details) {
            requestTermination.startFetch({
              contract_id: contract?.id,
              reason: reason?.value,
              note: details,
              ...(tentativeLastDay
                ? { tentative_last_day: format(tentativeLastDay, 'yyyy-MM-dd') }
                : {}),
            })
          } else {
            toastr.error('You need to fill all required fields')
          }
        }}
      >
        <ModalBody>
          {requestTermination?.isLoading ? (
            <div className='w-100 py-4 text-center'>
              <div className='d-flex flex-column justify-content-around pt-4'>
                <Loader />
              </div>
            </div>
          ) : requestCompleted ? (
            <div className='w-100 py-4 text-center'>
              <div className='d-flex flex-column align-items-center pt-3'>
                <Check size={138} color='#114EF7' />
                <p className='rp-font-medium font-size-24'>
                  Termination request sent
                </p>
                <p className='rp-font-semibold text-text-60 font-size-14 pb-3'>
                  Our team will review it and reach out to you shortly.
                </p>
                <Button
                  onClick={() => {
                    updated(false)
                  }}
                >
                  OK, Thanks
                </Button>
              </div>
            </div>
          ) : (
            <>
              <FormGroup className='justify-content-center'>
                <label className='col-form-label pt-0'>
                  Reason<span className='text-danger font-size-16 ml-1'>*</span>
                </label>
                <CustomSelect
                  className='border-0'
                  name='reason_id'
                  onChange={(val) => {
                    setReason(val)
                  }}
                  isSearchable={false}
                  value={reason}
                  options={reasons}
                />
              </FormGroup>
              <FormGroup>
                <label className='col-form-label pt-0'>
                  Tentative last day (optional)
                </label>
                <CustomDatePicker
                  name='tentative_last_day'
                  value={tentativeLastDay}
                  handleOnChange={(val) => setTentativeLastDay(val)}
                  dateFormat='dd-MM-yyyy'
                  placeholder='Select'
                  showPreviousMonths={false}
                  minDate={tomorrowDate}
                />
              </FormGroup>

              <FormGroup className='justify-content-center'>
                <label className='col-form-label pt-0'>
                  Note<span className='text-danger font-size-16 ml-1'>*</span>
                </label>
                <Input
                  placeholder='Insert reason for termination'
                  className='form-control'
                  type='textarea'
                  name='details'
                  onChange={(s) => {
                    setDetails(s.target.value)
                  }}
                  value={details}
                />
              </FormGroup>
              <Alert color='danger' customIcon={false}>
                <div className='d-flex align-items-start justify-content-start'>
                  <Info size={48} className='text-danger' />

                  <p className='pl-2 rp-font-medium text-text-black font-size-14'>
                    Please refrain from discussing the termination with the
                    employee until you have received feedback from the
                    RemotePass team.
                  </p>
                </div>
              </Alert>
            </>
          )}
        </ModalBody>
        {!requestCompleted && !requestTermination?.isLoading ? (
          <div className='modal-footer'>
            <button className='btn btn btn-outline-secondary' onClick={hide}>
              Cancel
            </button>
            <button className='btn btn-danger' type='submit'>
              {requestTermination?.isLoading && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
              )}
              Request Termination
            </button>
          </div>
        ) : null}
      </AvForm>
    </Modal>
  )
}

export default RequestTerminationModal
