import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'

import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import PageHeading from '../../../../components/ui/page-heading'
import { useFetch } from '../../../../helpers/hooks'
import { deGetJurisdictions } from '../../../../services/api'
import Pagination from '../../../../components/ui/pagination'

export default function Jurisdictions() {
  const [page, setPage] = useState(1)
  const {
    data: jurisdictions,
    isLoading,
    paginator,
  } = useFetch(
    {
      action: deGetJurisdictions,
      autoFetch: true,
      withAdminAccess: true,
      body: { page, perPage: 40 },
    },
    [page],
  )

  const columns = useMemo(() => {
    return [
      { Header: 'Name', accessor: 'name' },
      { Header: 'City', accessor: 'city' },
      { Header: 'Country', accessor: 'country.name' },
      { Header: 'Working Days', accessor: 'working_days' },
      { Header: 'Working Hours', accessor: 'working_hours' },
      { Header: 'Actions', accessor: 'id', Cell: ActionsCell },
    ]
  }, [])
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title>Jurisdictions</PageHeading.Title>

        <Button tag={Link} to='/admin/jurisdictions/add'>
          Add Jurisdiction
        </Button>
      </PageHeading>

      <Card style={{ minHeight: '30rem' }}>
        {isLoading ? (
          <Loader minHeight='30rem' />
        ) : (
          <>
            <DataTable responsive columns={columns} data={jurisdictions} />
            {!showPaginator ? null : (
              <div className='ml-auto mx-3 mt-2'>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={paginator?.per_page ?? 10}
                  totalItemsCount={paginator?.total ?? 0}
                  onChange={(newPage) => setPage(newPage)}
                />
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  )
}

function ActionsCell({ cellData }) {
  return (
    <div className='d-flex gap-8'>
      <Button
        size='sm'
        outline
        tag={Link}
        to={`/admin/jurisdictions/${cellData}`}
      >
        Edit
      </Button>
    </div>
  )
}
