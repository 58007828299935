import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Container, Row } from 'reactstrap'

import ModalHeader from '../../components/ModalHeader'
import Loader from '../../components/ui/loader'
import { contractorTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { updateContractorType } from '../../services/api'
import { loginUserSuccessful } from '../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../store/profile/actions'
import CompanyInfo from '../CompanySetting/CompanyInfo'

const CompleteCompany = () => {
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  const dispatch = useDispatch()
  const history = useHistory()
  const update = useFetch({
    action: updateContractorType,
    onComplete: (data, body) => {
      dispatch(loginUserSuccessful(data))
      dispatch(updateUserProfileInfo({ contractor_type: body?.type }))
      history.push('/activity')
    },
  })

  const location = history.location
  const back = location?.state?.back ?? '/settings'

  const onComplete = () => {
    update.startFetch({ type: contractorTypes.ENTITY })
  }

  return (
    <Container fluid className='tw-min-h-screen tw-bg-white !tw-px-0'>
      <ModalHeader action={() => history.push(back)} />

      <h3 className='mb-4 mt-4 text-center'>Complete your company info</h3>

      <Container fluid>
        <Row className='tw-justify-center'>
          <Col className='!tw-px-0' lg={6}>
            {!countries || countries?.length <= 0 ? (
              <Loader minHeight='30rem' />
            ) : (
              <CompanyInfo
                isCompletion
                onComplete={onComplete}
                countries={countries}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default CompleteCompany
