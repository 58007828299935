import { CircleNotch, Flag } from '@phosphor-icons/react'
import React from 'react'

import { cn } from 'ui'
import ADMIN_PERMISSIONS from '../../config/admin-permissions'
import { useFetch } from '../../helpers/hooks'
import useHasPermission from '../../helpers/hooks/admin/has-permission'
import { unflagUser } from '../../services/api'
import styles from '../user-comments/user-comments.module.scss'

export default function UserFlagging({ user, onCompleteAction }) {
  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_USERS)
  const { startFetch: unflagMyUser, isLoading: isUnflagging } = useFetch({
    action: unflagUser,
    withAdminAccess: true,
    onComplete: () => {
      if (typeof onCompleteAction === 'function') {
        onCompleteAction()
      }
    },
    onError: () => {
      // toastr.error('Error unflagging user')
    },
  })

  function handleFlagging() {
    unflagMyUser({ user_id: user.id })
  }

  return (
    <button
      onClick={handleFlagging}
      title='Unflag user'
      className={cn(
        styles.commentBtn,
        'text-muted tw-rounded tw-border-0 tw-bg-transparent tw-px-1',
      )}
      type='button'
      disabled={isUnflagging || !hasEditPermission}
    >
      {isUnflagging ? (
        <CircleNotch className='tw-size-4 tw-animate-spinTwo' />
      ) : (
        <Flag weight='fill' className='tw-size-4 tw-text-systemRed-100' />
      )}
    </button>
  )
}
