import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader as ModalHeaderRS,
  Row,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import ModalHeader from '../../components/ModalHeader'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import Wootric from '../../components/Wootric/Wootric'
import Head from '../../components/head'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import TabContent from '../../components/ui/tabs'
import {
  ACH,
  BANK_TRANSFER,
  BREX,
  COINBASE_CLIENT,
  CREDIT_CARD,
  SEPA_DEBIT,
} from '../../core/config/payment-methods'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  checkTransferStatus,
  confirmStripe,
  createBrexTransfer,
  createTransactionACH,
  createTransactionCC,
  createTransactionSepa,
  createTransactionTransfer,
  getMethods,
  getPayInPaymentIds,
  getPaymentMethods,
  getPlaidToken,
  getScheduledTaskStatus,
  getTransactionRef,
  savePayInPaymentIds,
} from '../../services/api'
import {
  endConfirmation,
  startConfirmation,
  updateConfirmationStatus,
  updateToPayList,
} from '../../store/payment/actions'
import { tag } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { mapCountryToOption } from '../../utils/map-to-option'
import { DownloadStatementModal } from '../Transactions/download-statement-modal'
import ConfirmationPage from './ConfirmationPage'
import PaymentReview from './PaymentReview'
import CreditCardsForSteps from './creditCardsForSteps'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import { ArrowsLeftRight, Money, Textbox, Warning } from '@phosphor-icons/react'
import { currencyFormatter } from 'ui'

export const scheduledTaskStatus = {
  processing: 'PROCESSING',
  success: 'SUCCESS',
  failed: 'FAILED',
}
let timeout

export function getPaymentIds(selectedList) {
  if (
    !selectedList ||
    !Array.isArray(selectedList) ||
    selectedList.length === 0
  ) {
    return []
  }

  const allPayments = selectedList
    ? [].concat(
        ...(selectedList?.map((e) =>
          e?.payments ? [...(e?.payments ?? [])] : [],
        ) ?? []),
      )
    : []

  if (allPayments.length > 0) {
    return [].concat(
      ...(allPayments?.map((e) => [
        ...(e?.works?.map((w) => w?.payment_item_id) ?? []),
      ]) ?? []),
    )
  } else if (selectedList) {
    return [].concat(
      ...(selectedList?.map((e) => [
        ...(e?.works?.map((w) => w?.payment_item_id) ?? []),
      ]) ?? []),
    )
  }

  return []
}

const PayInvoices = ({ history, match, location }) => {
  const searchParams = new URLSearchParams(location.search)
  const isConfirmation = searchParams.get('confirmation')
  const quoteId = searchParams.get('quote_id')
  const paymentIntent = searchParams.get('payment_intent')
  const paymentIntentClientSecret = searchParams.get(
    'payment_intent_client_secret',
  )
  const state = searchParams.get('state')
  const backUrlParam = searchParams.get('from')
  const backUrl = backUrlParam || '/payment'
  const isOnlyBankTransfer = searchParams.get('only_bank')

  const [activeTab, setActiveTab] = useState(isConfirmation ? 2 : 0)
  const [method, setMethod] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)
  const [loading, setLoading] = useState(false)
  const [trxData, setTrxData] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)

  const [showDownloadStatement, setShowDownloadStatement] = useState(false)
  const [showConfirmTransfer, setShowConfirmTransfer] = useState(false)
  const [clientConfirmedPayment, setClientConfirmedPayment] = useState(false)

  const dispatch = useDispatch()

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const paymentState = useSelector((state) => state.Payment)
  const confirming = useSelector((state) => state?.Payment?.confirming)
  const hasPreferredBank = useSelector(
    (state) => state.userProfile?.userProfile?.company?.has_preferred_bank,
  )

  // The payment ids are stored in the redux store.
  // But, if the user tries to use Brex, we save the ids in the savePayInPaymentIds endpoint
  // and then we get them with getPayInPaymentIds.
  // everything is saved in the state paymentIds
  const statePaymentIds = useSelector((state) => state?.Payment?.toPay)
  const [paymentIds, setPaymentIds] = useState(statePaymentIds)

  const preparationTimeout = useRef()

  if (
    (!statePaymentIds || statePaymentIds?.length <= 0) &&
    !isConfirmation &&
    !state
  ) {
    history.push(backUrl)
  }

  const { isLoading: gettingPaymentIds } = useFetch({
    action: getPayInPaymentIds,
    autoFetch: !!state,
    body: { key: state },
    onError: () => {
      history.push(backUrl)
    },
    onComplete: (data) => {
      const foundIds = data?.values?.split(',') ?? statePaymentIds

      if ((!foundIds || foundIds?.length <= 0) && !isConfirmation) {
        history.push(backUrl)
      }

      setPaymentIds(foundIds)
      paymentMethods.startFetch({ payment_item_ids: foundIds.map(Number) })
    },
  })

  const { startFetch: saveIds } = useFetch({ action: savePayInPaymentIds })
  function savePaymentIds(url) {
    const searchString = url?.split('?')?.[1]
    const searchParams = new URLSearchParams(searchString)
    const state = searchParams.get('state')

    saveIds({ key: state, values: paymentIds.join(',') })
  }

  const [bankTransferCountrySelection, setBankTransferCountrySelection] =
    useState(false)

  const paymentMethods = useFetch({
    autoFetch: !isConfirmation && !state,
    initResult: [],
    action: getMethods,
    body: { payment_item_ids: (paymentIds ?? [])?.map(Number) },
    onError: () => {
      toastr.error('An error occurred')
    },
  })

  const {
    startFetch: _getTransactionPreparationStatus,
    data: transactionInfo,
  } = useFetch({
    action: getScheduledTaskStatus,
    onComplete: (data) => {
      if (data.processes[0].status === scheduledTaskStatus.processing) {
        setIsPreparing(true)
      } else if (data.processes[0].status === scheduledTaskStatus.success) {
        setIsPreparing(false)
      }
    },
  })

  const prepareTRX = useFetch({
    autoFetch: false,
    initResult: null,
    action: getTransactionRef,
    onComplete: (data) => {
      _getTransactionPreparationStatus({ id: data.id })
    },
  })

  useEffect(() => {
    if (isPreparing && !preparationTimeout.current) {
      preparationTimeout.current = setInterval(() => {
        _getTransactionPreparationStatus({ id: prepareTRX.data?.id })
      }, 5 * 1000)
    } else if (!isPreparing) {
      clearInterval(preparationTimeout.current)
      preparationTimeout.current = null
    }

    return () => clearInterval(timeout)
  }, [isPreparing])

  const isMobile = useResize()

  const createAchTrx = useFetch({
    action: createTransactionACH,
    onComplete: (data) => {
      stopLoadingAndTagEvent()
      setTrxData(data)
      onTransactionCreated(data?.ref)
    },
    onError: (error) => {
      setLoading(false)
      toastr.error(error)
    },
  })

  useEffect(() => {
    if (
      isConfirmation &&
      !paymentState?.confirming &&
      !paymentState?.confirmed
    ) {
      dispatch(startConfirmation())
      setLoading(true)
      confirmStripe(user?.token, {
        payment_intent: paymentIntent,
        payment_intent_client_secret: paymentIntentClientSecret,
        quote_id: quoteId,
      })
        .then((r) => {
          dispatch(endConfirmation())
          if (r.data.success) {
            if (r.data.data.status === 'succeeded') {
              setTrxData(r.data?.data)
              dispatch(updateConfirmationStatus(true))
              window.analytics.track('Pay step 4 - Payment Confirmation', {
                pay_amount: paymentMethods?.data?.methods[0]?.total,
                pay_currency: paymentMethods?.data?.currency?.code,
                pay_method: method?.label,
                trx_id: r.data.data?.ref,
              })
            }
          } else {
            toastr.error(
              r.data.data?.error ||
                r.data?.error ||
                r.data?.message ||
                'An error occurred',
            )
          }
          setLoading(false)
        })
        .catch(() => {
          dispatch(endConfirmation())
          setLoading(false)
        })
    }

    if (isOnlyBankTransfer) {
      setMethod({
        id: BANK_TRANSFER.id,
        name: BANK_TRANSFER.name,
        label: BANK_TRANSFER.name,
        type: 'bank_transfer',
      })
      handleNextBankTransfer()
    }
  }, [])

  const onTransactionCreated = (ref) => {
    setActiveTab(activeTab + 1)
    window.analytics.track('Pay step 4 - Payment Confirmation', {
      pay_amount: paymentMethods.data?.methods[0]?.total,
      pay_currency: paymentMethods.data?.currency?.code,
      method: method?.label,
      transaction_id: ref,
    })
  }

  const { startFetch: brexTransfer } = useFetch({
    action: createBrexTransfer,
    onError: () => {
      setLoading(false)
    },
    onComplete: (data) => {
      stopLoadingAndTagEvent()
      setTrxData(data)
      onTransactionCreated(data?.ref)
    },
  })

  function stopLoadingAndTagEvent() {
    setLoading(false)

    const eventData = {
      date: new Date().toISOString(),
      email: userProfile.email,
      utm_source: user?.campaign_data?.source_data?.utm_source ?? '',
    }
    tag(EVENTS.TRANSACTION_COMPLETED, eventData)
  }

  const { startFetch: transferStatus, data: transferStatusData } = useFetch({
    action: checkTransferStatus,
    onComplete: (data) => {
      if (
        data.scheduled_task_processes[0].status === scheduledTaskStatus.success
      ) {
        setIsProcessing(false)
        setTrxData(data)
        onTransactionCreated(data.data?.ref)
      }
    },
    body: { quoteId: transactionInfo?.processes?.[0]?.payload.quote_id },
  })

  useEffect(() => {
    if (isProcessing && !timeout) {
      timeout = setInterval(() => {
        transferStatus()
      }, 5 * 1000)
    } else if (!isProcessing) {
      clearInterval(timeout)
      timeout = null
    }

    return () => clearInterval(timeout)
  }, [isProcessing])

  function processPayment() {
    setLoading(true)
    setClientConfirmedPayment(true)
    if ([CREDIT_CARD.id, SEPA_DEBIT.id].includes(method?.id)) {
      const createTrxFunc =
        method?.id === SEPA_DEBIT.id
          ? createTransactionSepa
          : method?.id === CREDIT_CARD.id
            ? createTransactionCC
            : () => {}

      createTrxFunc(user?.token, {
        quote_id: transactionInfo?.processes?.[0]?.payload.quote_id,
        payment_method_id: selectedCard?.id,
      })
        .then((r) => {
          if (r.data.success) {
            setTrxData(r.data.data)
            if (r.data.data?.url) {
              window.location.replace(r.data.data?.url)
            } else {
              onTransactionCreated(r.data.data?.ref)
            }
          } else {
            toastr.error(r.data.message)
          }
        })
        .catch(() => {
          toastr.error('An error occurred')
        })
        .finally(() => {
          stopLoadingAndTagEvent()
        })
    } else if (method?.id === ACH.id) {
      createAchTrx.startFetch({
        quote_id: transactionInfo?.processes?.[0]?.payload.quote_id,
        payment_method_id: selectedCard?.id,
      })
    } else if (method?.id === BREX.id) {
      brexTransfer({
        quoteId: transactionInfo?.processes?.[0]?.payload.quote_id?.toString(),
        userId: user?.id,
        companyId: userProfile?.company?.id,
      })
    } else {
      createTransactionTransfer(user?.token, {
        quote_id: transactionInfo?.processes?.[0]?.payload.quote_id,
      })
        .then((r) => {
          if (r.data.success) {
            setTrxData(r.data.data)
            if (
              r.data.data.scheduled_task_processes[0].status ===
              scheduledTaskStatus.success
            ) {
              onTransactionCreated(r.data.data?.ref)
            } else if (
              r.data.data.scheduled_task_processes[0].status ===
              scheduledTaskStatus.processing
            ) {
              setIsProcessing(true)
            }
          } else {
            toastr.error(r.data.message)
          }
        })
        .catch(() => {
          toastr.error('An error occurred')
        })
        .finally(() => {
          stopLoadingAndTagEvent()
        })
    }

    window.analytics.track('Pay step 3 - Review', {
      pay_amount: paymentMethods.data?.methods[0]?.total,
      pay_currency: paymentMethods.data?.currency?.code,
      pay_method: method?.label,
    })
  }

  const getToken = useFetch({
    action: getPlaidToken,
    autoFetch: !isOnlyBankTransfer,
    onError() {
      toastr.error('Something went wrong')
    },
  })

  const accounts = useFetch({
    action: getPaymentMethods,
    autoFetch: !isOnlyBankTransfer,
    initResult: [],
    onError() {
      toastr.error('Something went wrong')
    },
  })

  const handleMethodSelection = useCallback(
    (item) => {
      if (Array.isArray(paymentMethods.data?.methods)) {
        window.analytics.track('Pay step 1 - Method', {
          pay_amount: paymentMethods.data?.methods[0]?.total,
          pay_currency: paymentMethods.data?.currency?.code,
          pay_method: method?.name,
        })
        setMethod(item)

        // bank like methods (wise, mercury)
        const isBankTransferLike =
          item?.id === BANK_TRANSFER.id || !!item?.payment_account_id

        if (isBankTransferLike) {
          const body = {
            grouped: 1,
            payment_method_id: item?.id,
            payment_item_ids: paymentIds,
          }

          // bank like methods (wise, mercury)
          if (item?.payment_account_id) {
            body.payment_method_id = BANK_TRANSFER.id
            body.payment_account_id = item?.payment_account_id
          }

          // if the company has a has_preferred_bank and bank transfer is selected
          // we need to select country first
          if (item?.id === BANK_TRANSFER.id && !hasPreferredBank) {
            setBankTransferCountrySelection(true)
          } else {
            prepareTRX.startFetch(body)
            setActiveTab(activeTab + 1)
          }
        }
      }
    },
    [
      paymentIds,
      activeTab,
      method?.name,
      paymentMethods.data?.currency?.code,
      paymentMethods.data?.methods,
      prepareTRX,
      hasPreferredBank,
    ],
  )

  function handleNextBankTransfer(country) {
    const body = {
      grouped: 1,
      payment_method_id: isOnlyBankTransfer ? BANK_TRANSFER.id : method?.id,
      payment_item_ids: paymentIds,
      country_id: country?.id,
    }

    prepareTRX.startFetch(body)
    if (!isOnlyBankTransfer) {
      setActiveTab(activeTab + 1)
    }
    setBankTransferCountrySelection(false)
  }

  const onDownload = () => {
    setShowDownloadStatement(true)
  }

  // bank like methods (wise, mercury, Coinbase)
  const isBankTransferLike =
    method?.id === BANK_TRANSFER.id ||
    method?.id === COINBASE_CLIENT.id ||
    !!method?.payment_account_id

  if (gettingPaymentIds) {
    return (
      <div className='min-vh-100'>
        <Head title='Pay invoice' />
        <Container fluid className='px-0'>
          <ModalHeader />

          <Loader minHeight='max(60vh, 670px)' />
        </Container>
      </div>
    )
  }

  return (
    <div className='min-vh-100'>
      <Head title='Pay invoice' />
      <Container fluid className='px-0'>
        <ModalHeader
          action={() => {
            if (isBankTransferLike && !clientConfirmedPayment) {
              setShowConfirmTransfer(true)
              return
            }
            history.replace(backUrl)
          }}
        >
          {!isMobile && (
            <Steps
              activeTab={activeTab}
              data={
                isOnlyBankTransfer
                  ? ['Review', 'Pay']
                  : ['Payment methods', 'Review', 'Pay']
              }
              noLastAction
            />
          )}
        </ModalHeader>

        <Row className='justify-content-center'>
          <Col sm={12} md={7} lg={7} xl={6}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              {!isOnlyBankTransfer && (
                <TabPane tabId={0}>
                  <StepContainer
                    index={0}
                    title='Payment Methods'
                    total={3}
                    onNext={() => {
                      prepareTRX.startFetch({
                        grouped: 1,
                        payment_method_id: method?.id,
                        payment_item_ids: paymentIds,
                      })
                      setActiveTab(activeTab + 1)
                    }}
                    onBack={() => setActiveTab(activeTab - 1)}
                    nextText='Next'
                    disableNext={!method}
                  >
                    <Container
                      className='p-0 m-0'
                      style={{ minHeight: '30vh' }}
                    >
                      <CreditCardsForSteps
                        onNext={(card) => {
                          setSelectedCard(card)
                          window.analytics.track('Pay step 2 - Account', {
                            pay_amount: paymentMethods.data?.methods[0]?.total,
                            pay_currency: paymentMethods.data?.currency?.code,
                            pay_method: method?.name,
                            pay_account: card?.id,
                          })
                        }}
                        onAccountAdded={(account) => {
                          window.analytics.track('Pay step 2 - Added account', {
                            pay_amount: paymentMethods.data?.methods[0]?.total,
                            pay_currency: paymentMethods.data?.currency?.code,
                            pay_method: method?.name,
                            pay_account: account?.id,
                          })

                          if (!account?.id) {
                            dispatch(updateToPayList(paymentIds))
                          }

                          accounts.startFetch(null, false)
                          setSelectedCard(account)
                        }}
                        type={method?.type}
                        plaidToken={getToken.data?.link_token}
                        onBrexAuthInitiated={(data) => {
                          savePaymentIds(data.url)
                        }}
                        cards={accounts.data}
                        cardsLoading={accounts.isLoading}
                        onMethodSelected={handleMethodSelection}
                        hasMethodSelected={!!method || !!selectedCard}
                        methods={paymentMethods.data}
                        loading={
                          paymentMethods.isLoading ||
                          getToken.isLoading ||
                          accounts.isLoading
                        }
                      />

                      <BankTransferCountryModal
                        isOpen={bankTransferCountrySelection}
                        toggle={() => setBankTransferCountrySelection(false)}
                        onConfirm={(country) => {
                          handleNextBankTransfer(country)
                        }}
                        onCancel={() => setBankTransferCountrySelection(false)}
                      />
                    </Container>
                  </StepContainer>
                </TabPane>
              )}
              <TabPane tabId={isOnlyBankTransfer ? 0 : 1}>
                <StepContainer
                  index={isOnlyBankTransfer ? 0 : 1}
                  total={isOnlyBankTransfer ? 2 : 3}
                  title={
                    isBankTransferLike ? 'Review and confirm' : 'Review & Pay'
                  }
                  onNext={processPayment}
                  onBack={() => {
                    setSelectedCard(null)
                    setMethod(null)
                    setActiveTab(activeTab - 1)
                  }}
                  nextText={
                    isBankTransferLike ? "I'll make the transfer" : 'Confirm'
                  }
                  loading={loading}
                  disableNext={
                    prepareTRX.isLoading || prepareTRX.error || isPreparing
                  }
                  isFlat
                >
                  <PaymentReview
                    onBack={() => {
                      setSelectedCard(null)
                      setMethod(null)
                      setActiveTab(activeTab - 1)
                    }}
                    data={transactionInfo?.processes?.[0]?.payload}
                    dataError={prepareTRX.error}
                    advance={match.params.id}
                    loading={!transactionInfo?.processes?.[0]?.payload.quote_id}
                    card={selectedCard}
                    method={method}
                    bankInfo={isBankTransferLike}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={isOnlyBankTransfer ? 1 : 2}>
                <ConfirmationPage
                  payload={
                    transferStatusData?.scheduled_task_processes[0]?.payload
                  }
                  onComplete={() => {
                    history.push('/transactions')
                  }}
                  loading={loading || confirming}
                  confirmed={!isConfirmation || paymentState?.confirmed}
                  onDownload={trxData?.ref ? onDownload : null}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>

      {activeTab === 3 && (!isConfirmation || paymentState.confirmed) && (
        <Wootric />
      )}
      {showDownloadStatement && (
        <DownloadStatementModal
          isOpen={showDownloadStatement}
          item={trxData}
          toggle={() => setShowDownloadStatement(false)}
        />
      )}

      <Modal isOpen={isProcessing} centered>
        <ModalHeaderRS>Processing request</ModalHeaderRS>
        <ModalBody>
          <div className='tw-text-sm tw-text-disabled'>
            Hold tight! This may take a few minutes.
          </div>
          <div className='tw-my-3 tw-h-3 tw-animate-pulse tw-rounded tw-bg-primary-70' />
        </ModalBody>
      </Modal>

      {showConfirmTransfer && (
        <ConfirmationModal
          isOpen={showConfirmTransfer}
          toggle={() => setShowConfirmTransfer(false)}
          onConfirm={() => {
            setShowConfirmTransfer(false)
            processPayment()
          }}
          caption='Confirm'
          negativeCaption='Discard and leave'
          onCancel={() => {
            setShowConfirmTransfer(false)
            history.replace(backUrl)
          }}
          content={
            <>
              <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
                <Warning size={24} className='tw-text-systemGold-110' />
                <ModalCloseButton
                  toggle={() => setShowConfirmTransfer(false)}
                />
              </div>
              <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                Confirm payment
              </h4>
              <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
                Your transaction is not finalized yet. Please confirm to prevent
                any payment issues.
              </p>
              <div className='tw-py-4'>
                {isBankTransferLike && (
                  <div className='tw-border-b tw-border-surface-30 tw-py-4'>
                    <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2'>
                      <div className='tw-flex tw-items-center tw-gap-4'>
                        <div className='tw-text-text-60'>
                          <ArrowsLeftRight size={16.5} />
                        </div>
                        <div className='tw-text-text-100'>Payment method</div>
                      </div>

                      <div
                        className='tw-overflow-clip tw-text-wrap tw-text-end tw-font-semibold'
                        style={{ overflowClipMargin: 4 }}
                      >
                        {method?.name}
                      </div>
                    </div>
                  </div>
                )}
                <div className='tw-border-b tw-border-surface-30 tw-py-4'>
                  <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2'>
                    <div className='tw-flex tw-items-center tw-gap-4'>
                      <div className='tw-text-text-60'>
                        <Money size={16.5} />
                      </div>
                      <div className='tw-text-text-100'>Total Amount</div>
                    </div>

                    <div
                      className='tw-overflow-clip tw-text-wrap tw-text-end tw-font-semibold'
                      style={{ overflowClipMargin: 4 }}
                    >
                      {currencyFormatter(
                        transactionInfo?.processes?.[0]?.payload
                          ?.company_currency?.code || 'USD',
                      ).format(transactionInfo?.processes?.[0]?.payload?.total)}
                    </div>
                  </div>
                </div>
                <div className='tw-border-b tw-border-surface-30 tw-py-4'>
                  <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2'>
                    <div className='tw-flex tw-items-center tw-gap-4'>
                      <div className='tw-text-text-60'>
                        <Textbox size={16.5} />
                      </div>
                      <div className='tw-text-text-100'>Reference</div>
                    </div>

                    <div
                      className='tw-overflow-clip tw-text-wrap tw-text-end tw-font-semibold'
                      style={{ overflowClipMargin: 4 }}
                    >
                      {
                        transactionInfo?.processes?.[0]?.payload?.account?.[0]
                          ?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      )}
    </div>
  )
}

function BankTransferCountryModal({ isOpen, toggle, onConfirm, onCancel }) {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const [country, setCountry] = useState(() => {
    const country = countries?.find(
      (c) => c?.id === company?.transfer_country_id,
    )
    return mapCountryToOption(country ?? {}, 'id')
  })

  const countryOptions = countries?.map((c) => mapCountryToOption(c, 'id'))

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeaderRS toggle={toggle}>Select country</ModalHeaderRS>
      <ModalBody>
        <label>Select where you will be sending the funds from</label>
        <CustomSelect
          options={countryOptions}
          value={country}
          onChange={setCountry}
          placeholder='Select your country'
        />
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={() => onConfirm(country)}>Confirm</Button>
      </ModalFooter>
    </Modal>
  )
}

export default PayInvoices
