import {
  SET_CONTRACT_TO_DUPLICATE,
  UPDATE_CONTRACT,
  UPDATE_FULL_TIME_CONTRACT,
} from './actionTypes'

const INIT_STATE = {
  details: null,
  fulltime: null,
}

const Contract = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CONTRACT:
      return {
        ...state,
        details: action.payload,
      }
    case SET_CONTRACT_TO_DUPLICATE:
      return {
        ...state,
        toDuplicate: action.payload,
      }
    case UPDATE_FULL_TIME_CONTRACT:
      return {
        ...state,
        fulltime: action.payload,
      }

    default:
      return state
  }
}

export default Contract
