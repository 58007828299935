import { getCookie, setCookie } from '.'

function hasBadgeInCookie(cookieName, id) {
  const cookie = getCookie(cookieName)

  return cookie && cookie.includes(String(id))
}

export function showNewBadge(cookieName, id) {
  return !hasBadgeInCookie(cookieName, id)
}

export function setNewBadge(cookieName, id) {
  if (!hasBadgeInCookie(cookieName, id)) {
    setCookie(cookieName, `${getCookie(cookieName) ?? ''}${id},`)
  }
}
