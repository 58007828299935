import { BellSimple } from '@phosphor-icons/react'
import { formatDistanceToNow } from 'date-fns'
import React, { useState } from 'react'
import { Button as RAButton, TooltipTrigger } from 'react-aria-components'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import SimpleBar from 'simplebar-react'

import { cn } from 'ui'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  getNotificationList,
  markAllNotificationAsRead,
} from '../../../services/api'
import { updateUserNotifications } from '../../../store/profile/actions'
import { ModalCloseButton } from '../../Common/modal-close-button'
import { TooltipV2 } from '../../VerticalLayout/tooltip-v2'

export default function NotificationDropdown() {
  const dispatch = useDispatch()

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const notificationData = useSelector(
    (state) => state?.userProfile?.notifications,
  )
  const user = useSelector((state) => state?.Account?.user)
  const notifications = useFetch({
    autoFetch: true,
    initResult: null,
    action: getNotificationList,
    onComplete: (data) => {
      dispatch(updateUserNotifications(data))
    },
  })

  const markAllAsRead = useFetch({
    action: markAllNotificationAsRead,
    onComplete: notifications.startFetch,
  })

  let unreadCount = 0
  try {
    unreadCount = Array.isArray(notificationData)
      ? notificationData?.filter((n) => !n.read_at)?.length
      : 0
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  const isMobile = useResize()

  return (
    <>
      {!isMobile ? (
        <Dropdown
          isOpen={menu}
          toggle={() => {
            window.analytics.track('Clicked notifications', {
              email_id: user?.email,
            })
            setMenu(!menu)
            markAllAsRead.startFetch(null)
          }}
          direction='down'
          setActiveFromChild
          className='d-inline-block'
        >
          <NotificationButtonTooltip>
            <DropdownToggle
              className='tw-h-full hover:tw-bg-surface-30'
              tag='div'
              aria-label='Notifications'
            >
              <NotificationButtonContent unreadCount={unreadCount} />
            </DropdownToggle>
          </NotificationButtonTooltip>

          <DropdownMenu className='dropdown-menu dropdown-menu-lg notification-dropdown my-0'>
            <SimpleBar className='p-0 m-0' style={{ maxHeight: '70vh' }}>
              {notificationData?.length === 0 ||
              !Array.isArray(notificationData)
                ? emptyNotifications
                : notificationData?.map((n, i) => (
                    <NotificationItem
                      withBorder={i !== notificationData.length - 1}
                      key={`notification-${i}-${n?.created_at}`}
                      notification={n}
                      toggle={() => setMenu(false)}
                    />
                  ))}
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <>
          <NotificationButtonTooltip
            className='tw-bg-transparent hover:tw-bg-surface-30'
            onClick={() => {
              markAllAsRead.startFetch(null)
              setMenu(true)
            }}
          >
            <NotificationButtonContent unreadCount={unreadCount} />
          </NotificationButtonTooltip>

          <Modal
            isOpen={menu}
            toggle={() => {
              setMenu(false)
            }}
          >
            <ModalHeader
              className='tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-z-[1030] tw-bg-white'
              close={<ModalCloseButton toggle={() => setMenu(false)} />}
            >
              Notifications
            </ModalHeader>
            <ModalBody className='tw-mt-16 !tw-p-0 !tw-pb-4'>
              {notificationData?.length === 0 ||
              !Array.isArray(notificationData) ? (
                emptyNotifications
              ) : (
                <SimpleBar>
                  {notificationData?.map((n, i) => (
                    <NotificationItem
                      withBorder={i !== notificationData.length - 1}
                      key={`notification-${i}-${n?.created_at}`}
                      notification={n}
                      toggle={() => {
                        setMenu(false)
                      }}
                    />
                  ))}
                </SimpleBar>
              )}
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  )
}

function NotificationButtonContent({ unreadCount }) {
  return (
    <div className='tw-relative tw-flex tw-h-full tw-flex-shrink-0 tw-items-center tw-p-4'>
      <div className={cn(unreadCount > 0 && 'tw-animate-ringingBell')}>
        <BellSimple size={24} />
      </div>

      {unreadCount > 0 && (
        <span
          style={{ position: 'absolute', top: '20%', right: '20%' }}
          className='badge badge-danger badge-pill border'
        >
          {unreadCount}
        </span>
      )}
    </div>
  )
}

function NotificationButtonTooltip({ children, className, onClick }) {
  return (
    <TooltipTrigger delay={0}>
      <RAButton onPress={onClick} className={cn('tw-h-full', className)}>
        {children}
      </RAButton>
      <TooltipV2 className='tw-text-xs' placement='bottom'>
        Notifications
      </TooltipV2>
    </TooltipTrigger>
  )
}

const emptyNotifications = (
  <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-12'>
    <BellSimple className='tw-size-44 tw-text-secondary-60' />

    <div className='tw-text-lg tw-font-semibold tw-text-text-80'>
      No notifications
    </div>
  </div>
)

function getLink(notification) {
  const typeString = notification.type
  const typeParts = typeString.split('\\')
  const type = typeParts[typeParts.length - 1]
  let tab = ''
  switch (notification?.data?.object_type) {
    case 'contract':
      if (type === 'ExpenseSubmittedNotification') {
        tab = 'expenses'
      } else if (type === 'TimeOffSubmittedNotification') {
        tab = 'timeOff'
      } else if (type === 'DocumentRequestSubmittedNotification') {
        tab = 'documents'
      } else if (type === 'PayslipRegeneratedNotification') {
        tab = 'payslips'
      }
      return `/contract/detail?id=${notification?.data?.object_id}&tab=${tab}`
    case 'company-profile':
      return '/settings#profile'
    case 'contractor-profile':
      return '/settings#profile'
    case 'kyc':
      return '/settings#kyc'
  }
}

function NotificationItem({ notification, toggle, withBorder }) {
  return (
    <Link
      to={getLink(notification)}
      className={cn(
        'tw-block tw-border-b tw-border-transparent tw-p-4 tw-text-xs !tw-text-text-60 hover:tw-bg-surface-20',
        withBorder && 'tw-border-surface-30',
        !notification?.read_at && 'tw-bg-primary-10',
      )}
      onClick={() => toggle()}
    >
      <div className='tw-mb-3 tw-flex tw-flex-wrap tw-justify-between tw-gap-3'>
        <div className='tw-font-bold'>{notification?.data?.title}</div>
        <div>{formatDistanceToNow(new Date(notification.created_at))} ago</div>
      </div>
      <div>{notification?.data?.content}</div>
    </Link>
  )
}
