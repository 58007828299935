import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Col, Container, Row, TabPane } from 'reactstrap'
import toastr from 'toastr'

import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import { useFetch } from '../../../helpers/hooks'
import {
  completeNetSuite,
  connectNetSuite,
  updateNetSuite,
} from '../../../services/api'
import {
  initNetSuite,
  updateNetSuitePreData,
  updateNetSuiteRequest,
} from '../../../store/ns/actions'
import ConnectNetSuiteForm from './ConnectNetSuiteForm'
import AccountMappingStep from './XeroIntegration/accountMappingStep'
import FinalStep from './XeroIntegration/finalStep'
import VendorsSettingStep from './XeroIntegration/vendorsSettingsStep'
import TabContent from '../../../components/ui/tabs'

const NetSuiteLoading = () => {
  const [activePage, setActivePage] = useState(0)

  const location = useLocation()
  const history = useHistory()

  const dispatch = useDispatch()
  const code = new URLSearchParams(location.search).get('code')

  const netSuiteConnection = useFetch({
    action: completeNetSuite,
    successAction: updateNetSuitePreData,
    onComplete: () => {
      setActivePage((p) => p + 1)
    },
  })

  const getToken = useFetch({
    action: connectNetSuite,
    body: {
      code,
    },
    autoFetch: !!code,
    onComplete: (data) => {
      if (data?.synched) {
        history.push('/settings/integrations')
      } else {
        netSuiteConnection.startFetch()
      }
    },
    onError: toastr.error,
  })

  const request = useSelector((state) => state.ns.request)

  const update = useFetch(
    {
      action: updateNetSuite,
      onError: toastr.error,
      onComplete: () => {
        dispatch(updateNetSuiteRequest(null))
        setActivePage(activePage + 1)
      },
    },
    [request],
  )

  useEffect(() => {
    if (request) update.startFetch(request)
  }, [request])

  useEffect(() => {
    return () => {
      dispatch(initNetSuite())
    }
  }, [])

  const steps = [
    'Connect NetSuite',
    'Account Mapping',
    'Vendor Mapping',
    'Completed',
  ]
  return (
    <div>
      <Container fluid className='!tw-px-0'>
        <ModalHeader
          action={() => {
            history.push('/settings/integrations')
          }}
        >
          <Steps activeTab={activePage} data={steps} />
        </ModalHeader>
        <Row className='justify-content-center'>
          <Col md={6}>
            <TabContent
              activeTab={activePage}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  title='Connect NetSuite Account'
                  index={0}
                  total={steps.length}
                  onNext={() => {
                    document
                      .getElementById('submit-netsuite-account-connect')
                      .click()
                  }}
                  loading={getToken.isLoading || netSuiteConnection.isLoading}
                  isFlat
                  noBack
                >
                  <ConnectNetSuiteForm onSubmitted={getToken.startFetch} />
                </StepContainer>
              </TabPane>
              <TabPane tabId={1}>
                <StepContainer
                  title='Account Mapping'
                  index={1}
                  total={steps.length}
                  onNext={() => {
                    document
                      .getElementById('submit-xero-account-mapping-step')
                      .click()
                  }}
                  loading={false}
                  isFlat
                  onBack={() => setActivePage(activePage - 1)}
                >
                  <AccountMappingStep
                    platform='NetSuite'
                    onSubmitted={() => setActivePage(activePage + 1)}
                    loading={getToken.isLoading}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={2}>
                <VendorsSettingStep
                  platform='NetSuite'
                  onBack={() => setActivePage(activePage - 1)}
                  loading={update.isLoading}
                  onSubmitted={(values) => {
                    dispatch(updateNetSuiteRequest(values))
                  }}
                />
              </TabPane>
              <TabPane tabId={3}>
                <FinalStep platform='NetSuite' />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NetSuiteLoading
