import { Check, FilePlus, Warning, X, XCircle } from '@phosphor-icons/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import DropzoneInput from '../../../components/Common/dropzone-input'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import IconButton from '../../../components/ui/icon-button'
import { RadioButtonsV2 } from '../../../components/ui/radio-button/radio-buttons-v2'
import { DIRECT_EMPLOYEE } from '../../../core/config/contract-types'
import { useFetch } from '../../../helpers/hooks'
import {
  checkExportStatus,
  exportContractsTemplate,
  getContractList,
  uploadContracts,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'

const getContractType = (type) => {
  switch (type) {
    case 'Fixed':
      return 'fixed'
    case 'Milestones':
      return 'milestone'
    case 'Pay as you Go':
      return 'payg'
    case 'Full Time':
      return 'full_time'
    default:
      return ''
  }
}

let checkInterval

function BulkUpdateModal({ isOpen, toggle, onClosed, onUpdate }) {
  const [activeOption, setActiveOption] = useState(null)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [showError, setShowError] = useState(false)
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source())

  const { data: contracts, isLoading: loadingList } = useFetch(
    { action: getContractList, autoFetch: true, initResult: [] },
    [],
  )

  const contractTypes = {}
  for (const item of contracts.filter((item) => item.status?.id === 4)) {
    if (item.type !== DIRECT_EMPLOYEE) {
      if (contractTypes[item.type]) {
        contractTypes[item.type].push(item.id)
      } else {
        contractTypes[item.type] = [item.id]
      }
    }
  }
  const checkExported = useFetch({
    action: checkExportStatus,
    onComplete: (data) => {
      if (data?.url) {
        setDownloadLoading(false)
        setCurrentStep(2)
        openFileV2(data.url, { isDataUrl: true, download: true })
        clearInterval(checkInterval)
      }
    },
    onError: (err) => {
      clearInterval(checkInterval)
      setDownloadLoading(false)
      toastr.error(err)
    },
  })
  const exportRequest = useFetch({
    action: exportContractsTemplate,
    onComplete: (data) => {
      checkInterval = setInterval(() => {
        if (data?.id) {
          checkExported.startFetch({ exportId: data?.id })
        }
      }, 1000)
    },
    onError: (err) => {
      setActiveOption(null)
      setDownloadLoading(false)
      toastr.error(err)
    },
    body: { format: 'csv', contract_type: activeOption },
  })

  const { startFetch: uploadCSV, data } = useFetch({
    action: uploadContracts,
    onComplete: (data) => {
      if (data.success === false) {
        setShowError(true)
        setUploadLoading(false)
        setUploadProgress(0)
      } else {
        setUploadLoading(false)
        setUploadProgress(0)
        onUpdate()
        toggle()
        toastr.success(data?.message ?? 'Contracts Updated Successfully')
      }
    },
    onError: (err) => {
      setShowError(true)
      setUploadLoading(false)
      toastr.error(
        err?.error?.messages?.[0] ?? 'Something went wrong while uploading',
      )
      setUploadProgress(0)
    },
  })

  const extraErrors =
    data?.success === false &&
    data?.data?.length > 0 &&
    data?.data?.every((item) => typeof item === 'string')
      ? data?.data
      : []

  async function uploadFile() {
    setUploadLoading(true)
    uploadCSV({
      contracts_csv_file: uploadedFile,
      cancelToken,
      progressFuncs: {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100
          setUploadProgress(progress)
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50
          setUploadProgress(progress)
        },
      },
    })
  }

  const cancelUploading = () => {
    if (uploadLoading) {
      setUploadProgress(0)
      cancelToken.cancel()
      setCancelToken(axios.CancelToken.source())
      setUploadLoading(false)
    } else {
      setUploadedFile(null)
    }
  }

  useEffect(() => {
    return () => {
      clearInterval(checkInterval)
    }
  }, [])

  return (
    <Modal
      className='rounded-8'
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader className='p-4 pb-3'>
        <IconButton
          outline
          color='transparent'
          circle
          size={28}
          onClick={toggle}
          className='position-absolute top-0 right-0 mt-3 mr-3'
          icon={<X size={18} />}
        />
        <div className='text-text-60 font-size-14'>{currentStep}/2</div>
        <p className='font-size-20 font-weight-semibold m-0'>Bulk Update</p>
        <p className='font-size-14 text-text-60 m-0'>
          Select contract type you wish to update
        </p>
      </ModalHeader>
      <ModalBody
        style={{ minHeight: 250 }}
        className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-6'
      >
        {currentStep === 1 ? (
          loadingList ? (
            'Loading ...'
          ) : (
            <RadioButtonsV2
              className='tw-w-full'
              value={activeOption}
              onChange={(event) => {
                setActiveOption(event?.target?.value)
              }}
              options={Object.entries(contractTypes).map(([key, val]) => {
                return {
                  value: getContractType(key),
                  label: key,
                  suffix:
                    val.length + (val.length > 1 ? ' Contracts' : ' Contract'),
                }
              })}
            />
          )
        ) : uploadedFile ? (
          <div className='d-flex flex-column align-items-center justify-content-center w-100'>
            <PickedFile
              fileName={uploadedFile?.path}
              progress={uploadProgress}
              isUploading={uploadLoading}
              cancelUploading={cancelUploading}
            />
            {showError && (
              <Alert
                color='danger'
                className='d-flex mt-3 px-3 py-4 surface-surface-30 bg-red-20 w-100'
                customIcon={<Warning size={24} />}
                innerTag='div'
                innerClassName='d-flex flex-column align-items-baseline text-text-black pl-2'
                style={{ gap: '1.5rem' }}
              >
                {extraErrors.length <= 0 ? (
                  <div>
                    Please make sure the file you uploaded matches the active
                    contracts.
                  </div>
                ) : (
                  extraErrors.map((error) => {
                    return (
                      <div key={error} className='text-red-110 tw-mb-1'>
                        {error}
                      </div>
                    )
                  })
                )}

                <Button
                  color='transparent'
                  className='p-0 mt-3'
                  onClick={() => setUploadedFile(null)}
                  textClassName='text-primary font-weight-bold flex-column'
                >
                  Upload new file
                </Button>
              </Alert>
            )}
          </div>
        ) : (
          <DropzoneInput
            wrapperClassName='w-100'
            accept={{ 'text/csv': ['.csv'] }}
            onDropAccepted={(data) => {
              setUploadedFile(data[0])
              setShowError(false)
            }}
            onDropRejected={(data) => toastr.error(data[0]?.errors[0]?.message)}
            maxFiles={24}
            style={{ height: 190 }}
            className='d-flex w-100 align-items-center justify-content-center flex-column'
          >
            <FilePlus size={32} />
            <span className='mt-2 mb-2 font-size-14 font-weight-bold'>
              Drop files here or click to upload
            </span>

            <span className='font-size-12 text-secondary-80'>
              Valid format CSV
            </span>
          </DropzoneInput>
        )}
      </ModalBody>
      <ModalFooter className='p-4'>
        <Button
          className='m-0 mr-2'
          style={{ minHeight: 48 }}
          textClassName='font-size-14 text-secondary-100'
          outline
          onClick={() => {
            cancelUploading()
            toggle()
          }}
          color='surface-30'
        >
          Cancel
        </Button>
        {currentStep === 1 ? (
          <Button
            className='m-0'
            textClassName='font-size-14'
            disabled={!activeOption || downloadLoading}
            style={{ minHeight: 48 }}
            loading={downloadLoading}
            onClick={() => {
              setDownloadLoading(true)
              exportRequest.startFetch()
            }}
          >
            Download
          </Button>
        ) : (
          <Button
            className='m-0'
            textClassName='font-size-14'
            disabled={!uploadedFile || uploadLoading}
            style={{ minHeight: 48 }}
            loading={uploadLoading}
            onClick={() => {
              uploadFile()
            }}
          >
            Update
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

function PickedFile({ fileName, progress, isUploading, cancelUploading }) {
  return (
    <div className='bg-primary-20 rounded-8 p-3 d-flex flex-column w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center grow-1'>
          <Check color='var(--primary-100)' size={20} />
          <span className='ml-2 text-primary pr-3 font-size-14'>
            {fileName}
          </span>
        </div>
        <XCircle
          onClick={() => cancelUploading()}
          className='cursor-pointer'
          size={20}
          fill='var(--red-100)'
          weight='fill'
        />
      </div>
      {isUploading && (
        <div
          className='w-100 bg-primary-50 mt-3'
          style={{
            height: 4,
            borderRadius: 4,
            background: 'var(--primary-50)',
          }}
        >
          <div
            className='bg-primary-100'
            style={{ height: 4, borderRadius: 4, width: `${progress}%` }}
          ></div>
        </div>
      )}
    </div>
  )
}

export default BulkUpdateModal
