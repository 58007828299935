import { DownloadSimple, Eye } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approveExpense,
  deleteExpense,
  getApprovalFlowTimeline,
  rejectExpense,
} from '../../../services/api'
import { track } from '../../../utils/analytics'
import openFileV2 from '../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { ConfirmDeleteExpenseModal } from '../../AdminPanel/pages/Contracts/ExpenseDetails'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { Timeline } from '../../Contract/ContractPage/payment-approvals'
import { getExpenseIcon } from '../../review-center'
import { BaseAmount } from './base-amount'
import { ExpenseStatusBadge } from './expense-line'
import { CONTRACT_STATUS } from '../../../helpers/enum'

export default function ExpenseDetailsSideMenu({
  expense,
  show,
  onToggle,
  contract,
  onApprove,
  onReject,
  onDelete,
  isClient,
  isAdmin,
}) {
  const [actionOverridden, setActionOverridden] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showConfirmDecline, setShowConfirmDecline] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const formatter = getCurrencyFormatter(expense?.contract_currency?.code)
  const { hasAccess } = usePermissions()

  function onError(error) {
    toastr.error(error)
  }

  const approve = useFetch({
    action: approveExpense,
    onComplete: () => {
      onApprove?.()
      track('Approve expense', {
        requester_contract_type: contract?.type,
        approver_country: userProfile?.country?.name,
        source: 'webapp',
      })
    },
    onError,
  })
  const reject = useFetch({
    action: rejectExpense,
    onComplete: onReject,
    onError,
  })
  const removeExpense = useFetch({
    action: deleteExpense,
    onComplete: () => {
      setShowConfirmDelete(false)
      onDelete?.()
    },
    onError,
  })

  const actionLoading =
    approve.isLoading || reject.isLoading || removeExpense.isLoading

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: expense?.id, type: 'expense' },
      autoFetch: !!expense?.id,
    },
    [expense?.id],
  )

  const firstPending = flowTimeline?.find((item) => item.status === 'Pending')

  const hasApprovalFlow = !!contract?.approval_flow?.steps?.length

  const actionable =
    !isAdmin &&
    String(expense?.approval_status)?.toLowerCase() !== 'declined' &&
    expense?.status.id === 2 &&
    (firstPending?.user_id === user.id ||
      !!userProfile?.is_company_creator ||
      actionOverridden ||
      !isClient ||
      !hasApprovalFlow)

  return (
    <>
      <SideMenu
        isOpen={show}
        onClose={onToggle}
        className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
        itemListClassName={cx(
          'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
          actionable
            ? 'tw-grid-rows-[auto_1fr_91px]'
            : 'tw-grid-rows-[auto_1fr]',
        )}
      >
        <SideMenuHeader toggle={onToggle}>
          <div className='tw-flex tw-items-center tw-gap-2'>
            <div className='tw-rounded-full tw-bg-primary-20 tw-p-2'>
              {getExpenseIcon({ category: expense?.category })}
            </div>

            <div>
              <p className='tw-mb-0 tw-text-xl tw-font-semibold'>
                {expense?.name}
              </p>
              <p className='tw-mb-0 tw-text-sm tw-font-semibold tw-text-text-60'>
                {expense?.category?.name}
              </p>
            </div>
          </div>
        </SideMenuHeader>
        <SideMenuBody>
          <h4 className='tw-font-semibold'>Details</h4>
          <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
            <div>
              <p className='tw-mb-0 tw-text-sm tw-text-text-80'>Status</p>
            </div>
            <div>
              <ExpenseStatusBadge item={expense} />
            </div>
          </div>
          <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
            <div>
              <p className='tw-mb-0 tw-text-sm tw-text-text-80'>Amount</p>
            </div>
            <div className='tw-text-sm tw-font-semibold tw-text-text-100'>
              {formatter.format(expense?.amount)}
              <BaseAmount
                baseAmount={expense?.base_amount}
                baseCurrency={expense?.base_currency}
              />
            </div>
          </div>
          <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>Receipt</p>
            {expense?.file ? (
              <Button
                color='link'
                className='!tw-p-1'
                onClick={() => openFileV2(expense?.file, { isDataUrl: true })}
                iconRight={<Eye size={20} className='tw-text-primary' />}
              >
                Preview
              </Button>
            ) : (
              'No receipt provided'
            )}
          </div>

          <Timeline
            flowTimeline={flowTimeline}
            loading={loadingFlowTimeline}
            loadingLength={contract?.approval_flow?.steps?.length}
            isAdmin={isAdmin}
            actionOverridden={actionOverridden}
            setActionOverridden={setActionOverridden}
            isDeclined={expense?.status?.id === CONTRACT_STATUS.ONGOING.value}
            contract={contract}
          />
        </SideMenuBody>
        {!actionable ? null : (
          <SideMenuFooter className='tw-justify-between'>
            {isClient ? (
              <>
                <PermissionTooltip
                  showing={!hasAccess(permissions.approveExpense)}
                  area={PERMISSION_GROUP.EXPENSES.name}
                  id='decline-expense-btn-tooltip'
                  className='tw-mr-auto'
                >
                  <Button
                    loading={reject.isLoading}
                    onClick={() => {
                      setShowConfirmDecline(true)
                    }}
                    disabled={
                      !(
                        hasAccess(permissions.approveExpense) ||
                        contract?.can_approve
                      ) || actionLoading
                    }
                    color='danger'
                  >
                    Decline
                  </Button>
                </PermissionTooltip>
                <PermissionTooltip
                  showing={!hasAccess(permissions.approveExpense)}
                  area={PERMISSION_GROUP.EXPENSES.name}
                  id='approve-expense-btn-tooltip'
                >
                  <Button
                    loading={approve.isLoading}
                    onClick={() => {
                      approve.startFetch({ expense_id: expense?.id })
                    }}
                    disabled={
                      !(
                        hasAccess(permissions.approveExpense) ||
                        contract?.can_approve
                      ) || actionLoading
                    }
                    color='success'
                  >
                    Approve
                  </Button>
                </PermissionTooltip>
              </>
            ) : (
              <Button
                onClick={() => setShowConfirmDelete(true)}
                color='danger'
                disabled={
                  actionLoading || !hasAccess(permissions.deleteExpense)
                }
              >
                Delete Expense
              </Button>
            )}
          </SideMenuFooter>
        )}
      </SideMenu>

      {showConfirmDecline && (
        <ConfirmDeleteExpenseModal
          show={showConfirmDecline}
          hide={() => setShowConfirmDecline(false)}
          reject={(reason) => {
            reject.startFetch({ expense_id: expense?.id, reason })
          }}
        />
      )}

      {showConfirmDelete && (
        <ConfirmationModal
          toggle={() => setShowConfirmDelete(false)}
          isOpen={showConfirmDelete}
          confirmLoading={removeExpense.isLoading}
          loading={removeExpense.isLoading}
          title='Delete Expense'
          message='Are you sure you want to delete this expense?'
          onConfirm={() => {
            removeExpense.startFetch({ expense_id: expense?.id })
          }}
          caption='Confirm Delete'
          buttonColor='danger'
        />
      )}
    </>
  )
}

export function PreviewExpense({ photo, className, imageClassName, file }) {
  return (
    <div
      className={cx(
        'tw-relative tw-flex tw-min-h-40 tw-flex-col tw-items-center tw-justify-center tw-rounded tw-border tw-border-dashed tw-border-surface-30 tw-bg-surface-20 tw-px-4 tw-py-2 tw-text-text-70',
        className,
      )}
    >
      {photo ? (
        <>
          <img className={cx('tw-h-72', imageClassName)} src={photo} />

          {!file ? null : (
            <Button
              className='tw-absolute tw-bottom-2 tw-right-2 !tw-bg-surface-10'
              size='sm'
              onClick={() => {
                openFileV2(file, { isDataUrl: true })
              }}
              color='link'
              icon={<DownloadSimple size={20} />}
            >
              Download
            </Button>
          )}
        </>
      ) : (
        'No receipt provided'
      )}
    </div>
  )
}
