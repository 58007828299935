import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

export function useBillModuleActive() {
  const history = useHistory()
  const billModuleActive = useSelector(
    (state) => state?.userProfile?.userProfile?.company?.is_bill_enabled,
  )
  useEffect(() => {
    if (!billModuleActive) {
      history.push('/')
    }
  }, [billModuleActive, history])
}
