import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Container, Row, TabPane } from 'reactstrap'

import ModalHeader from '../../components/ModalHeader'
import NoContent from '../../components/NoContent'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import { StyledH5 } from '../../components/Typo'
import Wootric from '../../components/Wootric/Wootric'
import Button from '../../components/ui/button'
import TabContent from '../../components/ui/tabs'
import { useFetch, useResize } from '../../helpers/hooks'
import { getDocumentsUS, getTaxConditions } from '../../services/api'
import generateTaxImage from './../../assets/images/generateTax.svg'
import GenerateTaxForm from './components/GenerateTaxForm'

const CreateDoc = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [completed] = useState(false)
  const isMobile = useResize()

  const inputs = useFetch({
    action: getDocumentsUS,
    autoFetch: true,
  })
  const taxConditions = useFetch({
    action: getTaxConditions,
    autoFetch: true,
  })

  return (
    <div>
      <Container fluid className='!tw-px-0'>
        <ModalHeader>
          {!isMobile && (
            <Steps
              activeTab={activeTab === 1 ? 2 : activeTab}
              data={['Tax form', '', 'Review & Sign']}
            />
          )}
        </ModalHeader>
        <Row className='justify-content-center'>
          <Col md={8}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  title='Tax form'
                  nextText='Proceed'
                  cardStyles={{ width: '40vw' }}
                  index={0}
                  total={2}
                  onNext={() => {
                    setActiveTab(activeTab + 1)
                  }}
                  onBack={() => setActiveTab(activeTab - 1)}
                >
                  {taxConditions && (
                    <div style={{ width: '100%', paddingTop: 10 }}>
                      <StyledH5
                        style={{
                          textAlign: isMobile ? 'start' : 'center',
                          marginLeft: isMobile ? '5%' : '0%',
                        }}
                      >
                        {taxConditions?.data?.title}
                      </StyledH5>

                      <p
                        style={{
                          marginTop: '5%',
                          marginLeft: '5%',
                          color: '#777F9E',
                        }}
                      >
                        {taxConditions?.data?.text}
                      </p>
                    </div>
                  )}
                </StepContainer>
              </TabPane>
              {activeTab === 1 && (
                <TabPane tabId={1}>
                  <StepContainer
                    minHeight='50vh'
                    title='Review & Sign'
                    noFooter
                    index={1}
                    total={2}
                    onNext={() => {}}
                    onBack={() => setActiveTab(activeTab - 1)}
                  >
                    {inputs && inputs?.data?.fields?.length > 0 && (
                      <GenerateTaxForm
                        fields={inputs?.data}
                        onFinish={() => setActiveTab(activeTab + 1)}
                      />
                    )}
                  </StepContainer>
                </TabPane>
              )}
              <TabPane tabId={2}>
                <NoContent
                  headline='Form generated'
                  subtitle='Your tax form has been generated successfully and shared with you client. You can download it under the documents page.'
                  image={generateTaxImage}
                  customBottom={
                    <Button
                      text='Back to Documents'
                      style={{
                        width: window.innerWidth <= 754 ? '100%' : undefined,
                      }}
                      tag={Link}
                      to='/documents'
                    />
                  }
                />
              </TabPane>
            </TabContent>
            {completed && <Wootric />}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(CreateDoc)
