import { CARD_STATUSES } from './card-types-status'

export function formatCard(item) {
  const statusMapping = {
    active: CARD_STATUSES.ACTIVE,
    blocked: CARD_STATUSES.TERMINATED,
    locked: CARD_STATUSES.TEMP_BLOCK,
  }
  return {
    id: item?.id,
    cardStatus: statusMapping[item.status.text] ?? item.status.text,
    maskedCardNumber: item.number,
    line1: item.holder_name,
    validUntil: item.expiration_date,
    cardType: item?.card_type,
  }
}

export function getActiveCard(cards) {
  let activeCard = null
  if (!cards || cards.length <= 0) {
    activeCard = {}
  } else if (cards?.length > 1) {
    const cardFound = cards.find(
      (item) => item?.cardStatus === CARD_STATUSES.ACTIVE,
    )
    // If we have an active card, we set it as active,
    // otherwise we set the last card (could be pending)
    activeCard = cardFound ?? cards[cards.length - 1]
  } else {
    activeCard = cards[0]
  }
  return activeCard
}
