import {
  UPDATE_CONTRACT_REF,
  UPDATE_USER_NOTIFICATIONS,
  UPDATE_USER_PROFILE_INFO,
  USER_PROFILE_INIT,
  UPDATE_PROFILE_COMPANY,
  UPDATE_USER_ADMIN_PERMISSIONS,
} from './actionTypes'

export const updateUserProfileInfo = (payload) => {
  return {
    type: UPDATE_USER_PROFILE_INFO,
    payload,
  }
}
export const updateUserAdminPermissions = (payload) => {
  return {
    type: UPDATE_USER_ADMIN_PERMISSIONS,
    payload,
  }
}
export const updateProfileCompany = (payload) => {
  return {
    type: UPDATE_PROFILE_COMPANY,
    payload,
  }
}
export const updateUserNotifications = (payload) => {
  return {
    type: UPDATE_USER_NOTIFICATIONS,
    payload,
  }
}
export const updateContractRef = (payload) => {
  return {
    type: UPDATE_CONTRACT_REF,
    payload,
  }
}
export const initUserProfile = () => {
  return {
    type: USER_PROFILE_INIT,
  }
}
