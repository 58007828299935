import {
  DownloadSimple,
  Files,
  TrashSimple,
  UploadSimple,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Container, Row, Spinner } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadContractorDoc,
  getDocsList,
} from '../../services/api'
import openFileV2 from '../../utils/file/open-v2'
import UploadModal from './components/UploadModal'

const ContractorDoc = () => {
  const history = useHistory()
  const country = useSelector(
    (state) => state.userProfile?.userProfile?.Country_of_Tax_Residence,
  )
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState(null)
  const docsList = useFetch({
    action: getDocsList,
    autoFetch: true,
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <div className='page-content'>
      <Row className='tw-m-0 tw-mb-6 tw--flex tw-items-center tw-justify-between tw-p-0'>
        <Col xs={12} md={12} className='tw-m-0 tw-p-0'>
          <h3 className='tw-mb-4 tw-text-[22px] md:tw-text-[32px]'>
            Documents
          </h3>
          <h4 className='tw-text-xs tw-font-normal tw-text-disabled md:tw-text-base'>{`Based on your tax country ${
            country?.name || ''
          }, below are the documents you are required to provide to your
                        client.`}</h4>
        </Col>
        <Col
          xs={12}
          md={6}
          className='tw-m-0 tw-flex tw-justify-end tw-p-0'
        ></Col>
      </Row>

      {docsList.isLoading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className='p-0 m-0'>
          {docsList.data?.map((e, i) => (
            <DocCard
              key={`doc${i}`}
              item={e}
              update={() => docsList.startFetch({}, false)}
              select={() => {
                if (e.is_form) {
                  history.push('/document/create')
                } else {
                  setSelectedDoc(e)
                  setIsOpen(true)
                }
              }}
            />
          ))}
          <DocCard
            item={{
              title: 'Any relevant documents (optional) ',
              subtitle: 'Upload a scanned copy of any relevant documents',
            }}
            update={() => {
              docsList.startFetch({}, false)
            }}
            select={() => {
              setSelectedDoc({
                title: 'Any relevant documents (optional) ',
                subtitle: 'Upload a scanned copy of any relevant documents',
              })
              setIsOpen(true)
            }}
          />

          <UploadModal
            update={() => docsList.startFetch({}, false)}
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
            doc={selectedDoc}
          />
        </Container>
      )}
    </div>
  )
}

export const getComplianceDocType = (type) => {
  switch (type) {
    case 'application/pdf':
      return 'pdf'
    case 'image/jpeg':
    case 'image/jpg':
      return 'jpg'
    case 'image/png':
      return 'png'
    default:
      return ''
  }
}

const DocCard = ({ item, update = () => {}, select }) => {
  const download = useFetch({
    action: downloadContractorDoc,
    onComplete: (data) => {
      openFileV2(data, {
        name: `${item.title}.${getComplianceDocType(data?.type)}`,
        download: true,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const deleteDoc = useFetch({
    action: deleteContractorDocument,
    onComplete: update,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    download.startFetch({ token: item.token })
  }
  const handleDelete = () => {
    deleteDoc.startFetch({ token: item.token })
  }

  return (
    <Container fluid>
      <Row className='tw-mb-4 tw-flex tw-items-center tw-gap-y-4 tw-rounded tw-bg-white tw-p-4 tw-shadow'>
        <Files
          size={24}
          weight='fill'
          className='tw-shrink-0 tw-text-primary md:tw-mr-4'
        />
        <Col xs={12} md={7} className='tw-px-0'>
          <h6 className='tw-mb-0 tw-text-sm tw-font-semibold'>
            {item.title} {item?.required && !item?.token ? '(required)' : ''}
          </h6>
          {item?.token ? null : (
            <p className='tw-mb-0 tw-mt-2 tw-text-sm tw-text-disabled'>
              {item.is_form
                ? 'Fill form to generate the document'
                : 'Upload a scanned copy'}
            </p>
          )}
        </Col>
        <Col className='tw-flex tw-flex-wrap tw-gap-2 tw-px-0 md:tw-justify-end'>
          {item?.token ? (
            <>
              <Button
                onClick={handleDelete}
                color='danger'
                size='sm'
                outline
                icon={<TrashSimple size={14} />}
                disabled={download?.isLoading || deleteDoc.isLoading}
                loading={deleteDoc.isLoading}
              >
                Delete
              </Button>
              <Button
                onClick={handleDownload}
                size='sm'
                outline
                icon={<DownloadSimple size={14} />}
                disabled={download?.isLoading || deleteDoc.isLoading}
                loading={download?.isLoading}
              >
                Download
              </Button>
            </>
          ) : (
            <Button
              icon={<UploadSimple size={16} weight='fill' />}
              onClick={select}
            >
              {item?.is_form ? 'Fill form' : 'Upload Document'}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ContractorDoc
