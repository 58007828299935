import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'

export function ControlledCheckboxList({
  name,
  label,
  control,
  wrapperClassName,
  labelClassName,
  itemClassName,
  itemLabelClassName,
  options,
  isFancy,
  horizontal,
}) {
  return (
    <div
      className={cx(
        'tw-flex tw-flex-wrap',
        isFancy ? 'tw-gap-4' : 'tw-gap-1.5',
        horizontal && 'tw-flex-col',
        wrapperClassName,
      )}
    >
      {!label ? null : <legend className={labelClassName}>{label}</legend>}

      {options.map(
        ({ value, name: optionName, label: optionLabel, disabled }) => {
          const compoundName = `${name}.${optionName}`
          return (
            <Controller
              key={value}
              name={compoundName}
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                const isChecked = !!value

                return (
                  <label
                    className={cx(
                      'tw-relative tw-mb-0 tw-flex tw-items-center tw-gap-2 has-[:disabled]:tw-opacity-50',
                      isFancy && 'tw-rounded tw-p-4',
                      isChecked
                        ? 'tw-text-primary-100 tw-ring-2 tw-ring-primary-100'
                        : 'tw-ring-1 tw-ring-surface-30',
                      {
                        'hover:tw-bg-surface-10': !isChecked && !disabled,
                        'tw-cursor-pointer': !disabled,
                      },
                      itemClassName,
                    )}
                  >
                    <input
                      type='checkbox'
                      id={compoundName}
                      name={compoundName}
                      checked={isChecked}
                      onChange={onChange}
                      onBlur={onBlur}
                      className={cx(isFancy ? 'tw-sr-only' : '')}
                      disabled={disabled}
                    />

                    {!optionLabel ? null : (
                      <div className={itemLabelClassName}>
                        {typeof optionLabel === 'function'
                          ? optionLabel({ isActive: isChecked })
                          : optionLabel}
                      </div>
                    )}
                  </label>
                )
              }}
            />
          )
        },
      )}
    </div>
  )
}
