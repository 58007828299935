import { CaretDown, CaretRight, CaretUp, Info } from '@phosphor-icons/react'
import cx from 'classnames'
import { format } from 'date-fns'
import React, { useMemo, useState, type CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Collapse, UncontrolledTooltip } from 'reactstrap'
import { Avatar } from 'ui'

import { Check, ErrorIcon, HourGlass } from '../../helpers/SvgIcons'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import { getFullName } from '../../utils/get-full-name'
import { StyledH3, StyledH4 } from '../Typo'
import Shimmer from '../ui/shimmer'
import { CompleteBankAccountModal } from './complete-bank-account-modal'

function ActionCenter({ data, isLoading, isAdmin, updateTodoList }) {
  return (
    <Col
      className='px-0 mb-4 rounded overflow-auto bg-white'
      style={{ boxShadow: '0 1px 0 #dfe1e6' }}
    >
      {data?.length > 0 ? (
        <StyledH3 max='24px' min='18px' className='rp-font-bold px-4 py-4 mb-0'>
          Things to do
        </StyledH3>
      ) : null}

      {isLoading ? (
        <div className='p-3'>
          <Shimmer width='100%' className='mb-2 tw-h-16' />
          <Shimmer width='100%' className='mb-2 tw-h-16' />
          <Shimmer width='100%' className='mb-2 tw-h-16' />
        </div>
      ) : (
        data?.map((item, index) => (
          <ActionItem
            isAdmin={isAdmin}
            item={item}
            key={index}
            isLast={index === data.length - 1}
            updateTodoList={updateTodoList}
          />
        ))
      )}
    </Col>
  )
}

export const completeContractorEntity = 'contractor_entity_details'

export type UserActionTypes =
  | 'complete_profile'
  | 'bank_account'
  | 'kyc'
  | 'first_contract'
  | 'add_users'
  | 'company_details'
  | 'contractor_entity_details'
  | 'need_assistance'
  | 'pending_documents'
  | 'pending_insurance_payments'
  | 'pending_payroll_approval'
  | 'pending_contracts'
  | 'pending_expenses'
  | 'pending_time_off'
  | 'pending_works'
  | 'pending_invoices'
  | 'pending_equipments'
  | 'pending_document_requests'
  | 'complete_employee_data_collection'
  | 'update_employee_data_collection'
  | 'incomplete_bank_account'

export type AdminActionTypes =
  | 'pending_amendment_requests'
  | 'pending_termination_requests'
  | 'pending_document_requests'
  | `missed_payslips_${string}`
  | 'inputs_pending_review'
  | 'quotations_pending_submission'
  | 'employees_pending_onboarding'
  | 'contracts_pending_rp_signature'
  | 'contracts_offboarding_soon'
  | 'pending_works'
  | 'pending_invoices'
  | 'pending_equipments'
  | 'contracts_pending_data_collection_review'
  | 'employee_contracts'
  | 'contracts_missed_monday_sync_data'

export type ActionItemType = {
  completed?: boolean
  inProgress?: boolean
  onboarding?: boolean
  disabled?: boolean
  list?: any[]
  id?: number
  link?: string
  text: string
  type: UserActionTypes | AdminActionTypes
  userType: 'admin' | 'client'
}

interface ActionItemProps {
  item: any
  isLast: boolean
  isAdmin: boolean
  updateTodoList: () => void
}

export const ActionItem = ({
  item: itemProp,
  isLast,
  isAdmin,
  updateTodoList,
}: ActionItemProps) => {
  const item: ActionItemType = {
    ...itemProp,
    inProgress: itemProp.in_progress,
    userType: itemProp.user_type,
  }
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const isCompleted = !!(item.completed && item.onboarding)
  const inProgress = !!item.inProgress
  const disabled = !!item.disabled
  const userProfile = useSelector(
    (state: { userProfile }) => state?.userProfile?.userProfile,
  )

  const [isBankAccountModalOpen, setIsBankAccountModalOpen] = useState(false)
  function toggleBankAccountModal() {
    setIsBankAccountModalOpen((isOpen) => !isOpen)
  }

  const kycNotVerified =
    userProfile?.kyc_verified !== 1 &&
    userProfile?.type === userTypes.COMPANY &&
    userProfile?.is_company_creator

  const disabledUntilKYCVerified = useMemo(() => {
    const isTypeRestricted = [
      'first_contract',
      'add_users',
      'company_details',
    ].includes(item?.type)
    return isTypeRestricted && kycNotVerified
  }, [item?.type, kycNotVerified])

  function handleHeaderClick() {
    if (item?.list?.length) {
      setIsOpen(!isOpen)
    } else {
      if (!isAdmin) {
        switch (item?.type as UserActionTypes) {
          case 'complete_profile':
            history.push('/settings#profile')
            break
          case 'bank_account':
            history.push('/settings#accounts')
            break
          case 'kyc':
            if (item.userType === 'client') {
              history.push('/profile-settings?tab=verification')
            } else {
              history.push('/settings#kyc')
            }
            break
          case 'first_contract':
            history.push('/contract/create')
            break
          case 'add_users':
            history.push('/settings/users')
            break
          case 'pending_documents':
            history.push('/documents')
            break
          case 'pending_insurance_payments':
            history.push('/insurance')
            break
          case 'company_details': {
            if (!disabled) history.push('/registration-document')
            break
          }
          case 'contractor_entity_details': {
            history.push('/complete-company', {
              back: '/activity',
              action: completeContractorEntity,
            })
            break
          }
          case 'need_assistance': {
            const url = item?.link
            window.open(url, '_blank', 'noopener')
            break
          }
          case 'pending_payroll_approval': {
            history.push('/review-payments')
            break
          }
          case 'update_employee_data_collection':
          case 'complete_employee_data_collection': {
            history.push('/employee/join')
            break
          }
          case 'incomplete_bank_account': {
            setIsBankAccountModalOpen(true)
            return null
          }
        }
      } else {
        switch (item.type as AdminActionTypes) {
          case 'inputs_pending_review':
            history.push('/admin/adjustments?status=pending')
            break

          case 'pending_amendment_requests':
            history.push('/admin/amendments?action=from-todo')
            break

          case 'pending_document_requests':
            history.push('/admin/eor-documents')
            break

          case item?.type.startsWith('missed_payslips_') && item?.type:
            {
              const date = item.type.split('missed_payslips_')[1]
              history.push(`/admin/payrolls?tab=payslips&date=${date}`)
            }

            break
          default:
            break
        }
      }
    }
  }

  const needsAssistance = item.type === 'need_assistance'
  const IconComp = useMemo(
    () =>
      needsAssistance
        ? Info
        : !isCompleted && !inProgress
          ? ErrorIcon
          : inProgress
            ? HourGlass
            : Check,
    [inProgress, isCompleted, needsAssistance],
  )
  const iconId = useMemo(() => `${item?.type}_icon`, [item?.type])

  const Component = isCompleted || inProgress || disabled ? 'div' : 'button'

  const isUp = item?.list && item?.list?.length > 0 && isOpen
  const isDown = item?.list && item?.list?.length > 0 && !isOpen
  const isRight = !item?.list || item?.list?.length <= 0

  return (
    <>
      <Component
        disabled={disabled || disabledUntilKYCVerified}
        onClick={
          isCompleted || inProgress || disabled ? undefined : handleHeaderClick
        }
        className={cx(
          'd-flex justify-content-between align-items-center gap-10 w-100 bg-white text-left border-gray-200 px-4 py-3',
          {
            'hover:bg-soft-light': !isCompleted && !inProgress && !disabled,
            'bg-light': disabled,
            'border-bottom': !isLast,
          },
        )}
        style={{ border: 0 }}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ gap: 10, flex: 1 }}
        >
          <div
            className={cx(
              'p-2 rounded-circle d-flex align-items-center justify-content-center',
              {
                'bg-soft-primary': needsAssistance,
                'bg-soft-warning': !needsAssistance && !isCompleted,
                'bg-soft-success': isCompleted,
              },
            )}
            id={iconId}
            style={
              {
                '--size-icon-wrapper': '40px',
                width: 'var(--size-icon-wrapper)',
                height: 'var(--size-icon-wrapper)',
              } as CSSProperties
            }
          >
            <IconComp
              style={
                {
                  '--size': needsAssistance ? '30px' : '20px',
                  width: 'var(--size)',
                  height: 'var(--size)',
                } as CSSProperties
              }
              className={cx({
                'text-primary': needsAssistance,
                'text-warning': !isCompleted && !disabledUntilKYCVerified,
                'text-soft-primary': disabledUntilKYCVerified,
                'text-success': isCompleted,
              })}
            />
          </div>

          {!!disabledUntilKYCVerified && (
            <UncontrolledTooltip target={iconId}>
              Verify your identify first
            </UncontrolledTooltip>
          )}

          {!inProgress || isCompleted ? null : (
            <UncontrolledTooltip target={iconId}>
              This item is in progress.
            </UncontrolledTooltip>
          )}

          <StyledH4
            max='16px'
            className={cx('mb-0', {
              'text-muted':
                isCompleted || inProgress || disabledUntilKYCVerified,
              'text-dark': !isCompleted && !inProgress,
            })}
            style={{ flex: 1 }}
          >
            {item?.text}
          </StyledH4>
        </div>

        {needsAssistance ? (
          <span className='text-primary'>Book a demo</span>
        ) : !inProgress || isCompleted ? (
          <span className='tw-text-text-60'>
            {!isUp ? null : <CaretUp size={20} weight='bold' />}
            {!isDown ? null : <CaretDown size={20} weight='bold' />}
            {!isRight ? null : <CaretRight size={20} weight='bold' />}
          </span>
        ) : null}

        {item.type !== 'incomplete_bank_account' ||
        !isBankAccountModalOpen ? null : (
          <CompleteBankAccountModal
            isOpen={isBankAccountModalOpen}
            toggle={toggleBankAccountModal}
            updateTodoList={updateTodoList}
          />
        )}
      </Component>

      <Collapse isOpen={isOpen}>
        <div style={{ paddingBottom: 8, borderBottom: '1px solid #f4f5f7' }}>
          {React.Children.toArray(
            item?.list?.map((element, index) => (
              <ItemList
                isAdmin={isAdmin}
                element={element}
                type={item?.type}
                key={index}
              />
            )),
          )}
        </div>
      </Collapse>
    </>
  )
}

export interface listItemProp {
  isAdmin: boolean
  element: any
  type: UserActionTypes | AdminActionTypes
}

const ItemList = ({ isAdmin, element, type }: listItemProp) => {
  const user = useSelector((state: { Account }) => state?.Account?.user)
  const partner =
    user?.type === userTypes.CONTRACTOR && !isAdmin
      ? element?.client
      : element?.contractor

  const quotationCreatedAt =
    type === 'quotations_pending_submission'
      ? element.created_at
        ? format(new Date(element.created_at), 'MMMM dd, yyyy, hh:mm a')
        : null
      : null

  const getItemUrl = () => {
    if (!isAdmin) {
      switch (type as UserActionTypes) {
        case 'pending_contracts': {
          return `/contract/detail?id=${element?.reference}`
        }
        case 'pending_expenses': {
          return `/contract/detail?id=${element?.reference}&tab=expenses`
        }
        case 'pending_time_off': {
          return `/contract/detail?id=${element?.reference}&tab=timeOff`
        }
        case 'pending_works': {
          return `/contract/detail?id=${element?.reference}&tab=works`
        }
        case 'pending_invoices': {
          return `/contract/detail?id=${element?.reference}&tab=works`
        }
        case 'pending_equipments': {
          return `/contract/detail?id=${element?.reference}&tab=equipment`
        }
        case 'pending_documents': {
          return '/documents'
        }
        case 'pending_document_requests': {
          return `/contract/detail?id=${element?.reference}&tab=documents`
        }
      }
    } else {
      switch (type as AdminActionTypes) {
        case 'contracts_offboarding_soon':
        case 'pending_termination_requests':
        case 'contracts_pending_data_collection_review':
        case 'employee_contracts':
        case 'contracts_missed_monday_sync_data':
        case 'quotations_pending_submission': {
          return `/admin-fulltime-details/${element?.id}`
        }
        case 'employees_pending_onboarding': {
          return `/admin/contract-detail?id=${element?.reference}`
        }
      }
    }
  }

  const userProfile = useSelector(
    (state: { userProfile }) => state?.userProfile?.userProfile,
  )
  const contractorType = userProfile?.contractor_type
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const clientFullName = getFullName(partner)
  const companyName = element?.client?.company_name

  const showCompanyName =
    ['pending_equipments', 'pending_contracts'].includes(type) && isDeEmployee
  const addCompanyName = type === 'employees_pending_onboarding'
  return (
    <div className='d-flex justify-content-between align-items-center font-size-14 px-4 pt-3 pb-2'>
      <div
        className='d-flex justify-content-start align-items-center'
        style={{ gap: 10, flex: 1 }}
      >
        <Avatar
          name={clientFullName}
          photo={partner?.[showCompanyName ? 'company_logo' : 'photo']}
          flag={partner?.flag}
        />
        <span className='ml-2 rp-font-bold'>
          {showCompanyName ? companyName : clientFullName}
          {addCompanyName && ` (${companyName})`}
          {isAdmin && quotationCreatedAt ? (
            <span className='tw-font-light tw-text-text-70'>
              {' '}
              (Requested At {quotationCreatedAt})
            </span>
          ) : null}
        </span>
      </div>

      <Link target={isAdmin ? '_blank' : '_self'} to={getItemUrl()}>
        Review
      </Link>
    </div>
  )
}

export default ActionCenter
