import { CaretRight, Lightning, LockSimple } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { Col, Popover, PopoverBody, Row } from 'reactstrap'

import { cn } from 'ui'
import globeGrey from '../../assets/images/identity/greyed_symbol_main.svg'
import globe from '../../assets/images/identity/symbol_main.png'
import {
  ACH,
  BANK_TRANSFER,
  BREX,
  CASH_POINT,
  COINBASE,
  COINBASE_CLIENT,
  CREDIT_CARD,
  MERCURY,
  PAYONEER,
  PAYPAL,
  PAYSEND,
  SEPA_DEBIT,
  TOP_UP_CARD,
  WALLET,
  WISE_AUD,
  WISE_EUR,
  WISE_GBP,
  WISE_USD,
} from '../../core/config/payment-methods'
import { useResize } from '../../helpers/hooks'
import { PERMISSION_GROUP } from '../../pages/CompanySetting/manage-role'
import BadgeX from '../Table/BadgeX'
import { StyledH5 } from '../Typo'
import { PermissionTooltip } from '../permission-tooltip'
import walletIcon from './../../assets/images/Walleticon.svg'
import bankAccount from './../../assets/images/bankAccount.svg'
import bankAccountSelected from './../../assets/images/bankAccount_selected.svg'
import brexLogo from './../../assets/images/brands/brex.png'
import mercuryLogo from './../../assets/images/brands/mercury-black.png'
import wiseLogo from './../../assets/images/brands/wise.svg'
import cash from './../../assets/images/cash.svg'
import cashGray from './../../assets/images/cashGray.svg'
import coinbaseIcon from './../../assets/images/coinbase_icon.svg'
import coinbaseIconInactive from './../../assets/images/coinbase_icon_inactive.svg'
import creditCard from './../../assets/images/creditCard.svg'
import payoneer from './../../assets/images/payoneer-logo.png'
import paypal from './../../assets/images/paypal.svg'
import paypalSelected from './../../assets/images/paypal_selected.svg'
import sepaLogo from './../../assets/images/sepa_logo.svg'
import topUpCardSelected from './../../assets/images/top-up-card-selected.svg'
import topUpCard from './../../assets/images/top-up-card.svg'
import unlockPopover from './../../assets/images/unlock_popover.svg'

const getDesiredText = (id) => {
  switch (id) {
    case 1:
      return '4% Stripe processing fees will apply'
    case 4:
      return 'SEPA Direct Debit is only available to clients with an EU Bank account'
    case 5:
      return 'ACH Direct Debit is only available to clients with a US Bank account'
    default:
      return 'Hi, I have a bank account and would like to request access to bank transfer'
  }
}
const getDesiredTitle = (id) => {
  switch (id) {
    case 1:
      return 'Unlock Credit Card method'
    case 4:
      return 'Unlock SEPA Direct Debit'
    case 5:
      return 'Unlock ACH payment'
    default:
      return 'Unlock method'
  }
}

const CardSelector = ({ options, onSelect, value }) => {
  const isMobile = useResize()
  return (
    <div
      className='d-flex flex-column flex-md-row flex-wrap'
      style={{ '--wrapper-gap': '1rem', gap: 'var(--wrapper-gap)' }}
    >
      {options.map((e, i) => (
        <CardItem
          key={e.id}
          selected={value?.id === e?.id}
          styles={{
            flexBasis:
              isMobile || e?.id === TOP_UP_CARD.id
                ? undefined
                : 'calc(50% - var(--wrapper-gap)/2)',
          }}
          i={i}
          item={e}
          onSelect={() => {
            onSelect(e)
          }}
          isWithdraw
        />
      ))}
    </div>
  )
}

const methodIcon = {
  [BANK_TRANSFER.id]: { icon: bankAccount, activeIcon: bankAccountSelected },
  [TOP_UP_CARD.id]: { icon: globeGrey, activeIcon: globe },
  [PAYSEND.id]: { icon: topUpCard, activeIcon: topUpCardSelected },
  [PAYPAL.id]: { icon: paypal, activeIcon: paypalSelected },
  [PAYONEER.id]: { icon: payoneer },
  [CASH_POINT.id]: { icon: cashGray, activeIcon: cash },
  [COINBASE.id]: { icon: coinbaseIconInactive, activeIcon: coinbaseIcon },
  [COINBASE_CLIENT.id]: {
    icon: coinbaseIconInactive,
    activeIcon: coinbaseIcon,
  },
  [WALLET.id]: { icon: walletIcon },
  [CREDIT_CARD.id]: { icon: creditCard },
  [SEPA_DEBIT.id]: { icon: sepaLogo },
  [ACH.id]: { icon: bankAccount, activeIcon: bankAccountSelected },
  [MERCURY.id]: { icon: mercuryLogo },
  [WISE_USD.id]: { icon: wiseLogo },
  [WISE_EUR.id]: { icon: wiseLogo },
  [WISE_GBP.id]: { icon: wiseLogo },
  [WISE_AUD.id]: { icon: wiseLogo },
  [BREX.id]: { icon: brexLogo },
}

export const CardItem = ({
  selected,
  item,
  onSelect,
  isEditing,
  hasPermission,
  isWithdraw,
  requestAccess,
  styles,
}) => {
  const [showAccessModal, setShowAccessModal] = useState(false)

  const icons = methodIcon[item.id]
  const image = selected ? (icons?.activeIcon ?? icons?.icon) : icons?.icon

  const imageFilter = !isEditing
    ? undefined
    : !selected && !icons?.activeIcon
      ? 'grayscale(100%)'
      : undefined

  return !isWithdraw ? (
    <PermissionTooltip
      showing={!hasPermission}
      id='payment-method-select-btn'
      area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
    >
      {item.id !== 8 && (
        <Popover
          placement='top'
          isOpen={showAccessModal}
          toggle={() => setShowAccessModal(false)}
          target={`pm_popover_${item.id}`}
          style={{
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 16px 24px',
          }}
          trigger='legacy'
          className='unbo-popover'
        >
          <PopoverBody className='p-0'>
            <div>
              <div className='d-flex p-2 align-items-start justify-content-start'>
                <img
                  src={unlockPopover}
                  width={16}
                  height={16}
                  className='ml-2 mt-2'
                  alt=''
                />

                <div className='pl-2'>
                  <div className='px-2 pt-2'>
                    <StyledH5
                      className='font-size-16'
                      style={{ color: '#424652' }}
                    >
                      {getDesiredTitle(item?.id)}
                    </StyledH5>
                  </div>

                  <div className='px-2 text-secondary font-size-12'>
                    <p style={{ color: '#777F9E', marginBottom: '0.4rem' }}>
                      {getDesiredText(item?.id)}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='px-3 py-3'
                style={{ borderTop: '1px solid #E7E8F2' }}
              >
                <button
                  type='button'
                  className='btn btn-primary rounded btn-sm btn-block py-2 font-size-14 font-weight-normal'
                  style={{ color: 'white' }}
                  disabled={!hasPermission}
                  onClick={() => requestAccess(item)}
                >
                  Proceed
                </button>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      )}
      <button
        className='p-0 border-0 bg-transparent text-left'
        style={{ width: '100%' }}
        id={`pm_popover_${item.id}`}
        type='button'
        disabled={!hasPermission}
        onClick={() => {
          if (item?.enabled) {
            onSelect()
          } else {
            setShowAccessModal(true)
          }
        }}
      >
        <Row
          className={cn(
            '!tw-mx-0 tw-mb-4 tw-items-center tw-rounded tw-border tw-border-secondary-30 tw-p-4',
            selected && 'tw-bg-primary-30',
            item?.enabled ? 'hover:tw-bg-secondary-20' : 'tw-bg-secondary-30',
          )}
        >
          {item?.type !== 'sepa_debit' ? (
            <div
              className='rounded mr-2 bg-soft-secondary d-flex justify-content-center align-items-center'
              style={{ height: '38px', width: '38px' }}
            >
              <img
                src={image}
                style={{ height: '24px', width: '24px', filter: imageFilter }}
                alt=''
              />
            </div>
          ) : (
            <img
              className='rounded mr-2 bg-soft-secondary'
              src={image}
              style={{ height: '38px', width: '38px', filter: imageFilter }}
              alt=''
            />
          )}
          <Row
            className='p-0 m-0 pl-1 align-items-center justify-content-between'
            style={{ flex: 1 }}
          >
            <Col className='m-0 p-0'>
              <div className='text-dark font-size-14'>
                {item?.name ?? item?.label}
              </div>
              {!isEditing && (
                <div className='text-secondary font-size-12'>
                  {item?.eta
                    ? `${item?.description} - ${item?.eta}`
                    : item.description}
                </div>
              )}
            </Col>
          </Row>
          {item?.enabled ? (
            <CaretRight
              size={14}
              weight='bold'
              className='tw-text-secondary-80'
            />
          ) : (
            <LockSimple
              size={16}
              weight='fill'
              className='tw-text-secondary-80'
            />
          )}
        </Row>
      </button>
    </PermissionTooltip>
  ) : (
    <button
      className='w-100 p-0 bg-transparent border-0'
      type='button'
      onClick={onSelect}
      style={styles}
    >
      <div
        className={cx('p-3 border rounded text-left position-relative', {
          'text-primary border-transparent bg-primary-10': selected,
          'text-secondary border-light bg-transparent hover:bg-primary-10':
            !selected,
        })}
        style={{
          boxShadow: selected ? '0 0 0px 2px var(--primary-100)' : undefined,
        }}
      >
        <span
          className={
            selected
              ? 'border border-primary radio-button-select-card'
              : 'border border-light'
          }
          style={{
            '--size': '1rem',
            position: 'absolute',
            top: 'var(--size)',
            right: 'var(--size)',
            width: 'var(--size)',
            height: 'var(--size)',
            borderRadius: 'var(--size)',
          }}
        />
        <img
          src={image}
          className='mb-3'
          alt=''
          style={{ height: '24px', width: 'auto', filter: imageFilter }}
        />
        <div>
          <div className='d-flex align-items-center gap-6 mb-2'>
            <h5 className={cx('mb-0', { 'text-primary': selected })}>
              {item?.name ?? item.label}
            </h5>
            {[TOP_UP_CARD.id, PAYSEND.id].includes(item.id) ? (
              <InstantBadge className='' />
            ) : null}
          </div>

          <p
            className={cx('font-size-12 mb-0', {
              'text-primary': selected,
              'text-secondary': !selected,
            })}
          >
            {item.description}
          </p>
        </div>
      </div>
    </button>
  )
}

export function InstantBadge({ className }) {
  return (
    <BadgeX
      className={cx('p-2 uppercase rp-font-extrabold bg-yellow-100', className)}
      size='sm'
      textStatus='dark'
      pill
      leftIcon={<Lightning size={12} weight='fill' />}
    >
      Instant
    </BadgeX>
  )
}
export default CardSelector
