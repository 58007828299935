import { format } from 'date-fns'

export function searchParamsFromObject(
  data = {},
  addQuestionMark = true,
  transformDatesFormat = false,
) {
  const params = new URLSearchParams()
  Object.entries(data).forEach(([key, value]) => {
    if (!value) return
    if (
      ['fromDate', 'toDate', 'start_date', 'end_date', 'date'].includes(key) &&
      transformDatesFormat
    ) {
      params.append(key, format(value, 'yyyy-MM-dd'))
    } else {
      params.append(key, value)
    }
  })

  let sp = params.toString()

  if (sp && addQuestionMark) {
    sp = ['?', sp].join('')
  }

  return sp ?? ''
}
