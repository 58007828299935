import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { declineMilestone, declineWork } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'

function DeclineModal({
  isOpen,
  toggle,
  onDeclined,
  isMilestone,
  e: itemData,
  data: contract,
}) {
  const [comment, setComment] = useState(null)

  const {
    startFetch: declineContractMilestone,
    isLoading: decliningMilestone,
  } = useFetch({
    action: declineMilestone,
    onComplete: handleComplete,
    onError: handleError,
  })
  const { startFetch: declineContractWork, isLoading: decliningWork } =
    useFetch({
      action: declineWork,
      onComplete: (data) => {
        window.analytics.track('Declined work', {
          'contract-id': contract.ref,
          'work-id': itemData?.id,
          'work-name': itemData?.details,
          'work-value': itemData?.qty,
          currency: contract.currency?.code,
          requester_contract_type: contract.type,
        })

        handleComplete(data)
      },
      onError: handleError,
    })

  function handleComplete(data) {
    if (data?.success === false) {
      toastr.error(
        data?.message || 'Something went wrong while declining the item',
      )
    } else {
      toastr.success('The item has been declined successfully')
      toggle?.()
      onDeclined?.()
    }
  }

  function handleError(error) {
    toastr.error(
      getErrorMessage(error) || 'Something went wrong while declining the item',
    )
  }

  const actionLoading = decliningMilestone || decliningWork

  function onDecline() {
    if (isMilestone) {
      declineContractMilestone({ work_id: itemData?.id, comment })
    } else {
      declineContractWork({ work_id: itemData?.id, comment })
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        className='!tw-border-none !tw-pb-0'
        close={<ModalCloseButton toggle={toggle} />}
      >
        Decline Reason
      </ModalHeader>
      <ModalBody>
        <textarea
          name='raison'
          className='form-control'
          placeholder='Reason'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={actionLoading}
        >
          Cancel
        </Button>
        <Button
          type='button'
          color='danger'
          onClick={onDecline}
          disabled={!comment || actionLoading}
          loading={actionLoading}
        >
          Decline
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeclineModal
