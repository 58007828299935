import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'

import bankAccountChecked from '../../../assets/images/cash.svg'
import bankAccount from '../../../assets/images/cashGray.svg'
import { useFetch } from '../../../helpers/hooks'
import { deleteBankAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'
import { PaymentMethodCard } from './PaypalMethodCard'

const CashPointCard = ({
  withTag,
  withRemove,
  card,
  onUpdate,
  selected,
  onSelect,
  onClick,
  checked,
  isLoading,
}) => {
  let result = []

  let details = ''
  const address = ''
  try {
    details = JSON.parse(card.details)
    result = JSON.parse(details)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  const deleteCard = useFetch({
    action: deleteBankAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_account_name: card.holder_name,
        withdraw_method: 'Bank Transfer',
        currency: card?.currency,
        country: address?.country,
        is_default: false,
      })
    },
  })

  return (
    <PaymentMethodCard
      className={`${checked ? 'border-primary' : ''}`}
      item={card}
      deleteCard={deleteCard}
      withRemove={withRemove}
      onClick={onClick}
    >
      <Row className='p-md-3 p-3 d-flex m-0'>
        <Col xs={12} md={2} className='iconi p-0 m-0'>
          <div className='position-relative'>
            <img
              className='mb-3 mb-md-0 mt-md-2'
              src={checked ? bankAccountChecked : bankAccount}
              alt='walet icon'
              style={{ width: '2rem' }}
            />
          </div>
        </Col>

        <Col xs={12} md={10} className='p-0 m-0'>
          <h5
            className={`${
              checked ? 'text-primary' : 'text-dark'
            } mb-2 font-size-16`}
          >
            {'' + result[0]?.value}
          </h5>
          <div className='d-flex flex-row align-items-center'>
            <div
              className={`${
                checked ? 'text-primary' : 'text-secondary'
              } font-size-14 mb-2`}
            >
              {result[3]?.value}
            </div>
          </div>
          {withTag &&
            (isLoading ? (
              <>
                <i className='bx bx-loader bx-spin font-size-14' />
              </>
            ) : (
              <button
                onClick={onSelect}
                className={`btn rounded-sm font-size-10 ${
                  selected ? 'btn-primary' : 'btn-outline-light'
                } py-0 px-1 font-weight-normal`}
              >
                {selected ? 'Default' : 'Make default'}
              </button>
            ))}
        </Col>
      </Row>
    </PaymentMethodCard>
  )
}

export default CashPointCard
