import lowerCase from 'lodash/lowerCase'
import { rangeOccurrences, triggers } from './constants'
import set from 'lodash/set'
import compact from 'lodash/compact'
import upperCase from 'lodash/upperCase'
import {
  updateAutomation as updateAutomationApi,
  updateSteps as updateStepsApi,
} from '../../../../services/api-automations'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'
import entries from 'lodash/entries'
import mapKeys from 'lodash/mapKeys'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export const defaultValue = {
  steps: [
    { type: 'trigger' },
    { type: 'action', to: [], subject: '', body: '' },
  ],
  name: 'Untitled automation',
  isPublished: false,
  description: '',
}

const minutesInDay = 60 * 24

export function transformAutomationResponse(res) {
  const transformSteps = (_steps) => {
    let hasTrigger = false
    let hasAction = false
    let filters
    const steps = _steps.map((s) => {
      const type = lowerCase(s.type)
      const step = { type }
      if (type === 'trigger') {
        const { minutesBefore, minutesLong, event } = s.trigger
        const trigger = triggers
          .reduce((c, p) => [...c, ...p.options], [])
          .find((t) => t.value === event)
        set(step, 'event', trigger)
        if (minutesBefore || minutesLong) {
          set(
            step,
            'occurrenceDays',
            (minutesLong || minutesBefore) / minutesInDay,
          )
          set(step, 'typeOfOccurrence', rangeOccurrences[minutesBefore ? 0 : 1])
        }

        filters = mapKeys(
          mapValues(s?.trigger?.filters ?? {}, (f) => f.value),
          (value, key) => key.replaceAll('.', '$'),
        )

        hasTrigger = true
      }

      if (type === 'action') {
        const message = s?.action?.customMessage
        const targets = s?.action?.notificationTargets ?? []
        set(step, 'subject', message?.emailSubject ?? '')
        set(step, 'body', message?.emailBody ?? '')
        set(
          step,
          'to',
          targets.map((t) => t.email),
        )
        hasAction = true
      }

      if (type === 'delay') {
        const minute = s.delay.minutes
        set(step, 'numberOfDays', minute / minutesInDay)
      }
      return step
    })
    if (!hasTrigger) steps.unshift(defaultValue.steps[0])
    if (!hasAction) steps.push(defaultValue.steps[1])
    return { steps, filters }
  }
  const { steps, filters } = transformSteps(res?.steps ?? [])
  return {
    name: res?.name ?? '',
    focusedNode: 'trigger',
    filters,
    description: res?.description ?? '',
    isPublished: res?.is_published ?? false,
    steps,
  }
}

export function transformStepsRequest(_steps = [], _filters) {
  return compact(
    _steps.map((s) => {
      const step = { type: upperCase(s.type) }
      if (s.type === 'trigger') {
        const { typeOfOccurrence, occurrenceDays, event } = s ?? {}
        const { value, occurrence } = event ?? {}
        if (value) set(step, 'trigger.event', value)
        else return null
        if (occurrence && occurrenceDays)
          set(
            step,
            `trigger.${occurrence?.key ?? typeOfOccurrence?.key}`,
            Number(occurrenceDays) * minutesInDay,
          )

        if (!isEmpty(_filters))
          set(
            step,
            'trigger.filters',
            reduce(
              entries(_filters),
              (prev, [name, value]) => ({
                ...prev,
                [name.replaceAll('$', '.')]: { value, operator: 'in' },
              }),
              {},
            ),
          )
      }
      if (s.type === 'action') {
        const emailSubject = s?.subject ?? ''
        const emailBody = s?.body ?? ''
        const to = s?.to ?? []
        set(step, 'action.type', 'SEND')
        set(step, 'action.channel', 'EMAIL')
        set(
          step,
          'action.notificationTargets',
          to.map((t) => ({
            email: t,
            name: t.split('@')[0],
          })),
        )
        set(step, 'action.customMessage', {
          emailSubject,
          emailBody,
        })
      }
      if (s.type === 'delay') {
        if (!s.numberOfDays) return null
        set(step, 'delay.minutes', s.numberOfDays * minutesInDay)
      }
      return step
    }),
  )
}

export function transformFilters(array) {
  return reduce(
    array,
    (acc, item) => {
      const key = item.key.replaceAll('.', '$')
      acc.all.push({
        title: item.title,
        filter: key,
      })
      acc[key] = item.available_values
      return acc
    },
    { all: [] },
  )
}

export function transformParams({
  page,
  limit,
  onlyTemplates,
  actionType,
  triggerType,
  status,
  name,
}) {
  const statusFilters = {
    draft: { isPublishable: false },
    active: { isPublished: true },
    inactive: { isPublishable: true, isPublished: false },
  }
  return omitBy(
    {
      page,
      limit,
      name,
      isTemplate: onlyTemplates,
      actionChannel: actionType?.value,
      triggerEventType: triggerType?.value,
      ...statusFilters[status?.value],
    },
    isNil,
  )
}

export async function updateAutomation(
  token,
  {
    id,
    steps,
    name,
    isPublished,
    currentAutomation = {},
    filters,
    forceCreate,
  },
) {
  try {
    const stepsRequest = transformStepsRequest(steps, filters)
    const currentRequest = currentAutomation
      ? transformStepsRequest(
          currentAutomation.steps,
          currentAutomation.filters,
        )
      : null
    const isStepsSame = isEqual(stepsRequest, currentRequest)
    const isAutomationSame = isEqual(
      { name, isPublished },
      {
        name: currentAutomation?.name,
        isPublished: currentAutomation?.isPublished,
      },
    )
    if (!isStepsSame || forceCreate)
      await updateStepsApi(token, { steps: stepsRequest, id })
    if (!isAutomationSame || forceCreate)
      await updateAutomationApi(token, { id, name, isPublished })
    return await Promise.resolve({})
  } catch (e) {
    return await Promise.reject(e)
  }
}
