'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Check, Info, Warning } from '@phosphor-icons/react/dist/ssr';
import { useToast, useToastRegion } from '@react-aria/toast';
import { ToastQueue, useToastQueue } from '@react-stately/toast';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { cn } from '../lib/utils/cn';
import { Button } from './button';
const toastQueue = new ToastQueue({ maxVisibleToasts: 10 });
function baseToast(_a) {
    var { timeout, type } = _a, props = __rest(_a, ["timeout", "type"]);
    return toastQueue.add(Object.assign(Object.assign({ withCloseButton: true }, props), { type }), { timeout: timeout !== null && timeout !== void 0 ? timeout : 5000 });
}
export const toaster = {
    success: (props) => {
        baseToast(Object.assign(Object.assign({}, props), { type: 'success' }));
    },
    error: (props) => {
        baseToast(Object.assign(Object.assign({}, props), { type: 'error' }));
    },
    info: (props) => {
        baseToast(Object.assign(Object.assign({}, props), { type: 'info' }));
    },
    warning: (props) => {
        baseToast(Object.assign(Object.assign({}, props), { type: 'warning' }));
    },
};
export function GlobalToastRegion() {
    const state = useToastQueue(toastQueue);
    if (typeof window === 'undefined') {
        return _jsx(ToastRegion, { state: state });
    }
    return createPortal(_jsx(ToastRegion, { state: state }), document.body);
}
function ToastRegion(_a) {
    var { state } = _a, props = __rest(_a, ["state"]);
    const ref = useRef(null);
    const { regionProps } = useToastRegion(props, state, ref);
    return (_jsx("div", Object.assign({}, regionProps, { className: 'tw-fixed tw-right-4 tw-top-[calc(1rem+var(--header-height))] tw-flex tw-flex-col tw-gap-4', ref: ref, children: _jsx(AnimatePresence, { children: state.visibleToasts.map((toast) => (_jsx(Toast, { state: state, toast: toast }, toast.key))) }) })));
}
function Toast(_a) {
    var { state } = _a, props = __rest(_a, ["state"]);
    const ref = useRef(null);
    const { toastProps, titleProps, closeButtonProps } = useToast(props, state, ref);
    const width = 320;
    const margin = 16;
    const toasterType = props.toast.content.type || 'info';
    const typeClasses = {
        success: 'tw-bg-systemGreen-20 tw-text-systemGreen-100',
        error: 'tw-bg-systemRed-20 tw-text-systemRed-100',
        info: 'tw-bg-systemBlue-20 tw-text-systemBlue-100',
        warning: 'tw-bg-systemYellow-20 tw-text-systemYellow-100',
    };
    const iconMap = {
        success: Check,
        error: Warning,
        info: Info,
        warning: Warning,
    };
    const ToasterIcon = iconMap[toasterType];
    return (_jsxs(motion.div, { "aria-labelledby": toastProps['aria-labelledby'] || titleProps.id, role: toastProps['aria-labelledby'] ? 'alert' : undefined, className: cn('tw-flex tw-max-w-full tw-items-center tw-gap-2 tw-rounded-md tw-bg-white tw-px-4 tw-py-3 tw-shadow-md', typeClasses[toasterType]), ref: ref, 
        // motion props
        layout: true, initial: { x: width + margin }, animate: { x: 0 }, exit: {
            opacity: 0,
            zIndex: -1,
            transition: {
                opacity: {
                    duration: 0.2,
                },
            },
        }, transition: {
            type: 'spring',
            mass: 1,
            damping: 30,
            stiffness: 200,
        }, style: { width, WebkitTapHighlightColor: 'transparent' }, children: [_jsx(ToasterIcon, { className: cn('tw-shrink-0 tw-rounded-full tw-p-2', typeClasses[toasterType]), size: 40 }), _jsxs("div", Object.assign({}, titleProps, { children: [!props.toast.content.title ? null : (_jsx("div", { className: 'tw-text-sm tw-font-bold', children: props.toast.content.title })), !props.toast.content.description ? null : (_jsx("div", { className: 'tw-text-sm tw-text-text-90', children: props.toast.content.description }))] })), !props.toast.content.withCloseButton ? null : (_jsx(Button, Object.assign({}, closeButtonProps, { color: 'secondary', lightOutline: true, className: 'tw-ml-auto', size: 'small', type: 'button', children: "Close" })))] }));
}
