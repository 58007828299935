import Cookies from 'js-cookie'

const defaultOptions = {
  secure: true,
  sameSite: 'strict',
}

const api = Cookies.withAttributes(defaultOptions)

export function setCookie(name, value, options = {}) {
  api.set(name, value, options)
}

export function getCookie(name) {
  return api.get(name)
}

export function getAllCookies() {
  return api.get()
}

export function deleteCookie(name, options) {
  api.remove(name, options)
}
