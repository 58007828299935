import React from 'react'
import { cn } from 'ui'

export default function OptionRadioButtons({
  options,
  name,
  onChange,
  value: globalValue,
  isDisabled,
  size = 'md',
}) {
  return (
    <div
      className='d-flex flex-column flex-md-row'
      style={{
        '--gap': size === 'sm' ? 'var(--size-12)' : 'var(--size-16)',
        gap: 'var(--gap)',
      }}
    >
      {options?.map(({ value, label, description, disabled, icon }) => {
        const isOptionDisabled = isDisabled || disabled
        const isOptionChecked = globalValue === value

        return (
          <label
            key={value}
            className='tw-flex-grow tw-cursor-pointer'
            style={{ flexBasis: 'calc(50% - calc(var(--gap) / 2))' }}
          >
            <input
              name={name}
              value={value}
              type='radio'
              className='tw-absolute tw-h-0 tw-w-0 tw-appearance-none tw-opacity-0'
              onChange={() => {
                if (!isOptionDisabled) {
                  onChange(value)
                }
              }}
              disabled={isOptionDisabled}
              checked={isOptionChecked}
            />

            <div
              className={cn(
                'tw-flex tw-items-center tw-gap-2.5 tw-rounded tw-border tw-border-surface-30 tw-p-4 tw-transition-[box-shadow_0.1s_ease] hover:tw-bg-[var(--gray-100)]',
                {
                  'tw-border-primary tw-text-primary tw-shadow-[0px_4px_9px_0px_var(--slate-200)]':
                    isOptionChecked,
                },
              )}
            >
              {!icon ? null : (
                <span className='tw-flex tw-items-center tw-transition-transform tw-ease-in-out'>
                  {icon}
                </span>
              )}
              <LabelDescription
                size={size}
                label={label}
                description={description}
                isOptionChecked={isOptionChecked}
              />
            </div>
          </label>
        )
      })}
    </div>
  )
}

function LabelDescription({ size, label, description, isOptionChecked }) {
  return (
    <div
      className={cn('tw-flex tw-flex-col', {
        'tw-gap-1.5': size === 'sm',
        'tw-gap-2.5': size === 'md',
      })}
    >
      <div
        style={{ lineHeight: 1 }}
        className={cn({
          'tw-text-sm': size === 'sm',
          'tw-text-base': size === 'md',
          'tw-text-secondary': !isOptionChecked,
        })}
      >
        {label}
      </div>
      {!description ? null : (
        <div
          style={{
            lineHeight: 1,
            color: isOptionChecked ? null : 'var(--gray-600)',
          }}
          className={cn({
            'tw-text-xs': size === 'sm',
            'tw-text-sm': size === 'md',
          })}
        >
          {description}
        </div>
      )}
    </div>
  )
}
