import React, { useState } from 'react'
import { ModalFooter } from 'reactstrap'

import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import NoContent from '../../../components/ui/no-content'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch } from '../../../helpers/hooks'
import {
  approveDataCollection,
  assignContractToRegionForm,
  declineDataCollection,
  exportAdminForm,
  getEmployeeDataCollectionAP,
} from '../../../services/api'
import { ConfirmationModal } from '../../remotepass-cards/components/active-credit-card-section'
import EORDataCollectionForm from '../eor-data-collection/form'
import { downloadFile } from '../utils/helpers'
import toastr from 'toastr'

function DataCollectionTab({ contractId, contractLoading, refetchContract }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isExportingExcel, setIsExportingExcel] = useState(false)
  const [isExportingPDF, setIsExportingPDF] = useState(false)
  const { startFetch, data, isLoading } = useFetch(
    {
      autoFetch: contractId,
      action: getEmployeeDataCollectionAP,
      withAdminAccess: true,
      body: { contract_id: contractId },
    },
    [contractId],
  )
  const { startFetch: approve, isLoading: approveIsLoading } = useFetch({
    action: approveDataCollection,
    withAdminAccess: true,
    onComplete: () => {
      startFetch({ contract_id: contractId })
      if (refetchContract) refetchContract({ id: contractId }, false)
    },
  })
  const { startFetch: decline, isLoading: declineIsLoading } = useFetch({
    action: declineDataCollection,
    withAdminAccess: true,
    onComplete: () => {
      setShowConfirmationModal(false)
      startFetch({ contract_id: contractId })
      if (refetchContract) refetchContract({ id: contractId }, false)
    },
  })

  const { startFetch: exportForm } = useFetch({
    action: exportAdminForm,
    withAdminAccess: true,
    onComplete: (data) => {
      setIsExportingExcel(false)
      setIsExportingPDF(false)
      downloadFile(data?.download_link, 'form')
    },
    onError: (error) => {
      setIsExportingExcel(false)
      setIsExportingPDF(false)
      toastr.error(error)
    },
  })

  const { startFetch: assignToRegion, isLoading: isAssigningToRegion } =
    useFetch({
      action: assignContractToRegionForm,
      withAdminAccess: true,
      body: { contract_id: contractId },
      onComplete: () => {
        startFetch({ contract_id: contractId })
        if (refetchContract) refetchContract({ id: contractId }, false)
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const isPending = data?.status === 'Pending review'
  const isPendingSubmission = data?.status === 'Pending submission'
  const isDeclined = data?.status === 'Declined'

  const isAdmin = location.pathname.startsWith('/admin')

  return (
    <>
      {isLoading || contractLoading ? (
        <div className='tw-p-6'>
          <Shimmer className='tw-mb-2 !tw-w-full' />
          <Shimmer className='tw-mb-2 !tw-w-full' />
          <Shimmer className='tw-mb-2 !tw-w-full' />
        </div>
      ) : !data || data?.is_enabled ? (
        <NoContent className='tw-flex tw-flex-col tw-items-center tw-gap-2'>
          <span>Form does not exist, or under maintenance</span>
          {isAdmin ? (
            <Button
              onClick={() => assignToRegion()}
              loading={isAssigningToRegion}
            >
              Assign region form
            </Button>
          ) : null}
        </NoContent>
      ) : (
        <>
          {isPendingSubmission ||
            (isDeclined && (
              <Alert color='info' className='tw-mb-6'>
                Form is pending submission
              </Alert>
            ))}
          <div className='tw-flex tw-w-full tw-justify-between tw-p-6'>
            <h4 className='tw-mb-0'>{data.form?.name}</h4>
            {!isPendingSubmission && (
              <div className='tw-flex tw-items-center tw-gap-2'>
                <Button
                  loading={isExportingExcel}
                  disabled={isExportingExcel}
                  size='sm'
                  onClick={() => {
                    setIsExportingExcel(true)
                    exportForm({
                      contract_id: contractId,
                      export_format: 'excel',
                    })
                  }}
                >
                  Export as Excel
                </Button>
                <Button
                  loading={isExportingPDF}
                  disabled={isExportingPDF}
                  size='sm'
                  onClick={() => {
                    setIsExportingPDF(true)
                    exportForm({
                      contract_id: contractId,
                      export_format: 'pdf',
                    })
                  }}
                >
                  Export as PDF
                </Button>
              </div>
            )}
          </div>
          <EORDataCollectionForm filesDownloadable form={data.form} readOnly />
          {isPending && (
            <ModalFooter>
              <Button
                onClick={() => setShowConfirmationModal(true)}
                color='danger'
              >
                Decline
              </Button>
              <Button
                loading={approveIsLoading}
                disabled={approveIsLoading}
                onClick={() => approve({ contract_id: contractId })}
              >
                Approve
              </Button>
            </ModalFooter>
          )}
        </>
      )}

      <ConfirmationModal
        isOpen={showConfirmationModal}
        toggle={() => setShowConfirmationModal(false)}
        content='Why are you declining this form?'
        cancelText='Cancel'
        confirmText='Decline'
        cancelColor='secondary'
        confirmColor='danger'
        loading={declineIsLoading}
        onConfirm={(ev, reason) => {
          decline({ contract_id: contractId, decline_reason: reason })
        }}
        withReason={{ isRequired: true, placeholder: 'Reason' }}
        title='Decline Form'
      />
    </>
  )
}

export default DataCollectionTab
