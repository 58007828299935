import React from 'react'
import { Controller } from 'react-hook-form'

import { getCustomStyles } from '../Common/react-select-custom-styles'
import CustomSelect from '../Forms/CustomSelect/CustomSelect'
import { getInputErrorMessage } from '../Forms/get-input-error-message'

export const otherSelectOption = { value: 'other', label: 'Other' }

function ControlledSelect({
  name,
  options = [],
  otherOption,
  control,
  error: externalError,
  controlOnChange,
  controlValue,
  rules,
  disabled,
  isDisabled,
  transform,
  selectStyles,
  required,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { value, onBlur, onChange, ref },
        fieldState: { error },
      }) => {
        const allOptions = otherOption
          ? [...options, otherSelectOption]
          : options

        const handleChange =
          typeof controlOnChange === 'function'
            ? controlOnChange
            : (s) => {
                if (props?.isMulti) {
                  const newVal = s.map((i) => i.value)
                  onChange(newVal)
                } else {
                  onChange(s?.value ?? s)
                }
              }

        // TODO: refactor to also cater for when options is both grouped and isMulti
        const newValue =
          typeof controlValue === 'function'
            ? controlValue(value)
            : props?.isMulti
              ? value?.map((vl) => allOptions.find((op) => op.value === vl))
              : options?.[0]?.options // if it's grouped options
                ? options
                    ?.map((op) => op.options)
                    .flat()
                    .find((e) => e.value === value) || null
                : allOptions.find((e) => e.value === value) || null

        const theError =
          getInputErrorMessage(externalError) || getInputErrorMessage(error)

        return (
          <CustomSelect
            name={name}
            options={allOptions}
            value={
              typeof transform?.input === 'function'
                ? transform.input(newValue)
                : newValue
            }
            onChange={(...args) =>
              typeof transform?.output === 'function'
                ? handleChange(transform.output(...args))
                : handleChange(...args)
            }
            onMenuClose={onBlur}
            required={required}
            hasError={!!theError}
            error={theError}
            aria-invalid={!!theError}
            aria-errormessage={name + '-error-msg'}
            classNamePrefix=''
            styles={getCustomStyles({ hasError: !!theError, selectStyles })}
            isDisabled={disabled || isDisabled}
            selectRef={ref}
            {...props}
          />
        )
      }}
    />
  )
}

export default ControlledSelect
