import React from 'react'
import { Col, Row } from 'reactstrap'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  deletePayoneerAccount,
  deletePayPalAccount,
} from '../../../services/api'
import paypal from '../../../assets/images/paypal.svg'
import paypalSelected from '../../../assets/images/paypal_selected.svg'
import payoneer from '../../../assets/images/payoneer-logo.png'
import EVENTS from '../../../utils/events'
import { track } from '../../../utils/analytics'
import { cn } from 'ui'
import { Spinner } from '@phosphor-icons/react'

export const PaymentMethodCard = ({
  className,
  withRemove,
  onClick,
  deleteCard,
  item,
  children,
}) => (
  <button
    className={cn(
      'tw-relative tw-mb-4 tw-rounded tw-border tw-border-surface-130 tw-bg-white tw-text-left md:tw-mb-0',
      { '!tw-cursor-default': !onClick },
      className,
    )}
    onClick={onClick}
    type='button'
  >
    <div className='tw-absolute tw-right-3 tw-top-3 tw-z-[9]'>
      {withRemove && (
        <>
          {deleteCard.isLoading ? (
            <Spinner
              className='tw-size-3.5 tw-animate-[spin_2s_linear_infinite] tw-text-systemRed-100'
              weight='bold'
            />
          ) : (
            <button
              type='button'
              className='rp-btn-nostyle d-flex p-1 text-muted font-size-16'
              onClick={() => {
                deleteCard.startFetch({ id: item?.id })
              }}
            >
              <i className='bx bxs-trash-alt' />
            </button>
          )}
        </>
      )}
    </div>
    {children}
  </button>
)

const PaypalMethodCard = ({
  account,
  withTag,
  onUpdate,
  selected,
  onSelect,
  onClick = () => {},
  withRemove,
  checked,
  isPayoneer,
  isLoading,
}) => {
  const deleteCard = useFetch({
    action: isPayoneer ? deletePayoneerAccount : deletePayPalAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_method: isPayoneer ? 'Payoneer' : 'Paypal',
        is_default: false,
      })
    },
  })
  const isMobile = useResize()

  return (
    <PaymentMethodCard
      className={checked ? 'border-primary' : ''}
      withRemove={withRemove}
      deleteCard={deleteCard}
      item={account}
      onClick={() => onClick(account)}
    >
      <Row
        className={`${isMobile ? 'p-md-3 p-3' : 'p-md-0 p-0'} py-md-3 py-3 m-0`}
      >
        <Col xs={12} md={2} className='p-0 m-0'>
          <div
            style={{ height: '100%', width: '100%' }}
            className={`d-flex align-items-center ${
              isMobile ? 'justify-content-start' : 'justify-content-center'
            }`}
          >
            <img
              className='mb-3 mb-md-0'
              src={isPayoneer ? payoneer : checked ? paypalSelected : paypal}
              alt='Wallet icon'
              style={{
                width: '1.8rem',
                filter: isPayoneer && !checked ? 'grayscale(100%)' : undefined,
              }}
            />
          </div>
        </Col>

        <Col xs={12} md={10} className='p-0 m-0'>
          <h5
            className={`${
              checked ? 'text-primary' : 'text-dark'
            } mb-2 font-size-16`}
          >
            Email
          </h5>
          <div
            className={`${
              checked ? 'text-primary' : 'text-secondary'
            } font-size-14 mb-2`}
          >
            {account.email}
          </div>
          {withTag &&
            (isLoading ? (
              <>
                <i className='bx bx-loader bx-spin font-size-14' />
              </>
            ) : (
              <button
                onClick={onSelect}
                className={`btn rounded-sm font-size-10 ${
                  selected ? 'btn-primary' : 'btn-outline-light'
                } py-0 px-1 font-weight-normal`}
              >
                {selected ? 'Default' : 'Make default'}
              </button>
            ))}
        </Col>
      </Row>
    </PaymentMethodCard>
  )
}

export default PaypalMethodCard
