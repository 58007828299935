import { XCircle } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { StyledH5 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import {
  addAdminPayslip,
  addPayslip,
  uploadAdminPayslip,
  uploadPayslip,
} from '../../../services/api'
import expenseImage from './../../../assets/images/expense.svg'
import { getErrorMessage } from '../../../utils/get-errors'

const AddPayslipModal = ({ show, hide, onSuccessAdd, isAdmin, contractId }) => {
  const [date, setDate] = useState(null)
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg', '.gif'],
      'application/pdf': ['.pdf'],
    },
  })
  const contract = useSelector((state) => state?.Contract?.details)

  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }

  function resetData() {
    setDate(null)
    removeAll()
  }

  const upload = useFetch({
    action: isAdmin ? uploadAdminPayslip : uploadPayslip,
    withAdminAccess: isAdmin,
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })
  const add = useFetch({
    action: isAdmin ? addAdminPayslip : addPayslip,
    withAdminAccess: isAdmin,
    onComplete: () => {
      resetData()
      hide?.()
      onSuccessAdd?.()
    },
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })

  const files = acceptedFiles.map((file, index) =>
    upload.isLoading ? (
      <i
        key={index}
        className='bx bx-loader bx-spin text-primary font-size-20 ml-3'
      />
    ) : (
      <p key={file.path} className='mb-0'>
        {file.path}
      </p>
    ),
  )

  const submit = () => {
    if (!upload.data?.path) {
      toastr.error('Payslip file is required')
      return
    }

    if (!date || !date.getMonth) {
      toastr.error('Please select a month')
      return
    }

    add.startFetch({
      contract_id: isAdmin ? contractId : contract?.id,
      month: date.getMonth() + 1, // before: (1-10-2023)  `9` not `10`
      year: date.getFullYear(),
      document: upload?.data?.path,
    })
  }
  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      upload.startFetch({ document: acceptedFiles[0] })
    }
  }, [acceptedFiles])

  return (
    <Modal isOpen={show} toggle={hide} centered className='modal-sm-2'>
      <ModalHeader toggle={hide}>Add Payslip</ModalHeader>

      <ModalBody>
        <div className='dropzone border-primary mb-3'>
          <div
            className='dz-message needsclick bg-soft-primary d-flex gap-16 flex-column justify-content-center align-items-center cursor-pointer'
            {...getRootProps()}
            style={{ minHeight: 172 }}
          >
            <input {...getInputProps()} />

            {!!acceptedFiles && acceptedFiles?.length > 0 ? (
              <div className='d-flex justify-content-between align-items-center font-size-14 w-100'>
                {files}

                <button
                  onClick={removeAll}
                  className='rp-btn-nostyle text-danger p-0.5'
                >
                  <XCircle weight='fill' size={20} />
                </button>
              </div>
            ) : (
              <>
                <img src={expenseImage} alt='' />

                <StyledH5 max='16px' className='mb-0'>
                  <span className='d-none d-md-block'>
                    Drop here or click to upload payslip
                  </span>
                  <span className='d-md-none'>Click to upload files.</span>
                </StyledH5>
              </>
            )}
          </div>
        </div>

        <FormGroup>
          <Label className='col-form-label pt-0'>Date</Label>
          <CustomDatePicker
            value={date}
            handleOnChange={setDate}
            placeholder='Month'
            dateFormat='MMM - yyyy'
            showMonthYearPicker
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline onClick={hide} disabled={add.isLoading}>
          Cancel
        </Button>
        <Button
          onClick={submit}
          loading={add.isLoading}
          disabled={add.isLoading}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddPayslipModal
