import React, { useState } from 'react'
import { Card, Container, FormGroup, Label, Table } from 'reactstrap'
import Paginations from 'react-js-pagination'

import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import {
  deactivateInsurance,
  getUpcomingOverdueInsurancePayments,
  deductInsurancePayment,
} from '../../../../services/api'
import toastr from 'toastr'
import { useFetch } from '../../../../helpers/hooks'
import StyledTh from '../../../../components/Table/StyledTh'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { Line } from '../../../Insurance/components/InsurancePayments'
import Loader from '../../../../components/ui/loader'
import { BurgerDetails } from '../Users/users'
import { format } from 'date-fns'

const UpcomingOverduePayments = () => {
  const [activePage, setActivePage] = useState(1)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [show, setShow] = useState(false)
  const [confirmDeductInsurancePayment, setConfirmDeductInsurancePayment] =
    useState(null)
  const [deactivateInsurancePayment, setDeactivateInsurancePayment] =
    useState(null)
  const [cancellationDate, setCancellationDate] = useState(null)

  const overdueUpcomingPayments = useFetch(
    {
      action: getUpcomingOverdueInsurancePayments,
      body: { page: activePage },
      withAdminAccess: true,
      autoFetch: true,
    },
    [activePage],
  )

  const deactivate = useFetch({
    action: deactivateInsurance,
    withAdminAccess: true,
    onComplete: (res) => {
      if (res?.success === false) {
        toastr.error(res?.message)
      } else {
        toastr.success(res?.message ?? 'Payment deactivated successfully')
        setCancellationDate(null)
        setDeactivateInsurancePayment(null)
        overdueUpcomingPayments.startFetch()
      }
    },
    onError: (err) => {
      toastr.error(err?.message)
    },
  })

  const deduct = useFetch({
    action: deductInsurancePayment,
    withAdminAccess: true,
    onComplete: (res) => {
      if (res?.success === false) {
        toastr.error(res?.message || res?.error)
      } else {
        toastr.success(res?.message ?? 'Payment deducted successfully')
        setConfirmDeductInsurancePayment(null)
        overdueUpcomingPayments.startFetch()
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handlePageChange = (page) => {
    setActivePage(page)
  }

  const dateFormat = 'yyyy-MM-dd'

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Upcoming & Overdue Payments</h1>

        <Card>
          {overdueUpcomingPayments.isLoading ? (
            <Loader minHeight='70vh' />
          ) : (
            <>
              <div className='table-responsive p-0 m-0'>
                <Table className='table table-centered table-nowrap text-muted'>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh>Payment ID</StyledTh>
                      <StyledTh>User</StyledTh>
                      <StyledTh>Period</StyledTh>
                      <StyledTh>Status</StyledTh>
                      <StyledTh>Amount</StyledTh>
                      <StyledTh>Actions</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {overdueUpcomingPayments.data?.map((payment, key) => (
                      <Line
                        item={payment}
                        key={key}
                        onUpdate={() => {
                          overdueUpcomingPayments.startFetch()
                          setActivePage(1)
                        }}
                        isAdmin
                        overDue
                        onUserClick={(id) => {
                          setSelectedUserId(id)
                          setShow(true)
                        }}
                        deductInsurancePayment={() => {
                          setConfirmDeductInsurancePayment(payment)
                        }}
                        deactivateInsurancePayment={() => {
                          setDeactivateInsurancePayment(payment)
                        }}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className='d-flex justify-content-end px-3'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={activePage}
                  itemsCountPerPage={
                    overdueUpcomingPayments.paginator?.per_page ?? 20
                  }
                  totalItemsCount={overdueUpcomingPayments.paginator?.total}
                  onChange={handlePageChange}
                />
              </div>
            </>
          )}
        </Card>
      </Container>
      {!!deactivateInsurancePayment && (
        <ConfirmationModal
          toggle={() => {
            setCancellationDate(null)
            setDeactivateInsurancePayment(null)
          }}
          isOpen={!!deactivateInsurancePayment}
          title='Cancel Insurance'
          message='Are you sure you want to cancel this insurance'
          content={
            <FormGroup>
              <Label>Cancellation date</Label>
              <CustomDatePicker
                placeholder='Select Cancelation Date'
                dateFormat={dateFormat}
                value={cancellationDate}
                minDate={new Date()}
                handleOnChange={setCancellationDate}
              />
            </FormGroup>
          }
          onConfirm={() => {
            if (cancellationDate) {
              deactivate.startFetch({
                insurance_id: deactivateInsurancePayment?.insurance_id,
                cancellation_date: format(cancellationDate, dateFormat),
              })
            } else {
              toastr.error('Please select a cancellation date')
            }
          }}
        />
      )}

      {!!confirmDeductInsurancePayment && (
        <ConfirmationModal
          toggle={() => setConfirmDeductInsurancePayment(null)}
          isOpen={!!confirmDeductInsurancePayment}
          title='Deduct'
          message='Are you sure you want to continue?'
          onConfirm={() => {
            deduct.startFetch({
              user_id: confirmDeductInsurancePayment?.contractor.id,
              insurance_id: confirmDeductInsurancePayment?.insurance_id,
              payment_id: confirmDeductInsurancePayment?.id,
            })
          }}
        />
      )}
    </div>
  )
}

export default UpcomingOverduePayments
