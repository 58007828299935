import { PencilSimpleLine } from '@phosphor-icons/react'
import React, { useRef, useState } from 'react'

import IconButton from '../../../../components/ui/icon-button'
import { useFetch } from '../../../../helpers/hooks'
import {
  updateCompanyAdminJson,
  updateManagementFee,
} from '../../../../services/api'
import { ManageEorFeeModal, beConfigsKey } from './manage-eor-fee-modal'

export function ManagementFeeManagement({ company, updateCompaniesList }) {
  // We need to keep track of the update status
  // Once all update calls are done, we need to update the companies list
  const updateRef = useRef({})

  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => !open)
  }

  const { startFetch: updateData, isLoading } = useFetch({
    action: updateManagementFee,
    withAdminAccess: true,
    onComplete: () => {
      if (
        updateRef.current.updatedConfigs &&
        !updateRef.current.updatedSetupFee
      ) {
        updateCompaniesList?.()
      }
      updateRef.current = { ...updateRef.current, updatedConfigs: false }
    },
  })

  const { startFetch: updateSetupFee, isLoading: isUpdating } = useFetch({
    action: updateCompanyAdminJson,
    withAdminAccess: true,
    onComplete: () => {
      if (
        !updateRef.current.updatedConfigs &&
        updateRef.current.updatedSetupFee
      ) {
        updateCompaniesList?.()
      }
      updateRef.current = { ...updateRef.current, updatedSetupFee: false }
    },
  })

  function onSubmit(values) {
    const regionalConfigs = [...values[beConfigsKey], ...values.nulledItems]

    if (regionalConfigs.length) {
      updateRef.current = { ...updateRef.current, updatedConfigs: true }

      updateData({
        company_id: values.company_id,
        [beConfigsKey]: regionalConfigs,
      })
    }

    if (Number(company?.eor_setup_fee) !== Number(values.eor_setup_fee)) {
      updateRef.current = { ...updateRef.current, updatedSetupFee: true }

      updateSetupFee({
        eor_setup_fee: values.setup_enabled ? values.eor_setup_fee : null,
        company_id: values.company_id,
      })
    }
  }

  return (
    <>
      <FeeValueDisplay
        value={
          company?.regional_configs?.filter(
            (config) => !!config.eor_annual_management_fees,
          ).length || company?.eor_setup_fee
        }
        onEdit={toggle}
      />

      {!isOpen ? null : (
        <ManageEorFeeModal
          company={company}
          isOpen={isOpen}
          toggle={toggle}
          onSubmit={onSubmit}
          loading={isLoading || isUpdating}
        />
      )}
    </>
  )
}

export function FeeValueDisplay({ value, onEdit }) {
  return (
    <div
      className='align-items-center border d-flex gap-12 position-relative pl-2 pr-1.5 py-1 rounded text-text-70'
      style={{ width: 'fit-content' }}
    >
      <div style={{ lineHeight: 1 }}>{value ? 'Custom' : 'Default'}</div>

      <IconButton
        color='light'
        size={22}
        icon={<PencilSimpleLine size={18} color='currentColor' />}
        onClick={onEdit}
        className='text-current'
      />
    </div>
  )
}

export function getCompanyInfo(company) {
  return [
    { label: 'Company name', value: company.name },
    { label: 'dba', value: company.dba },
    { label: 'Company country', value: company.country?.name },
    { label: 'Currency', value: company.currency?.name },
  ]
}

export function InfoList({ items }) {
  return items.map(({ label, value }) => {
    return (
      <div key={label} className='d-flex'>
        <div className='text-text-70 text-capitalize' style={{ minWidth: 120 }}>
          {label}:
        </div>
        <div>{value ?? 'N/A'}</div>
      </div>
    )
  })
}
