import React, { useEffect, useRef, useState } from 'react'
import Quill from 'quill'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import 'quill-mention'
import Editable from '../TemplateEditor/Editable'

const EquipmentEditor = ({ sections, agreement, onChange, signature }) => {
  // The div containing the quill editor when no instance of Editable is using it.
  const quillEditorContainerTempHolder = useRef()
  // The div that contains the quill toolbar.
  const quillToolbarContainer = useRef()
  // The quill editor
  const quillEditorContainer = useRef()
  // The quill instance
  const quillInstance = useRef()
  // The data for each Editable, that is also persisted in local storage
  const [editables, setEditables] = useState(sections)
  // Derive a list of editables from the editables object
  // const editablesList = Object.keys(editables).map((key) => editables[key])
  // The currently active editable, the one we're manipulating with quill
  const [activeEditable, setActiveEditable] = useState()
  const [activeEditableKey, setActiveEditableKey] = useState()

  const Inline = Quill.import('blots/inline')

  class SpanBlock extends Inline {
    static create(value) {
      const node = super.create()
      node.setAttribute('class', 'tag')
      return node
    }
  }

  SpanBlock.blotName = 'tag'
  SpanBlock.tagName = 'label'
  Quill.register(SpanBlock)

  useEffect(() => {
    setEditables(sections)
  }, [sections])
  /**
   * Instansiate the quill editor, using the quillEditorContainer as
   * the element for it. Also, use our own toolbar.
   */
  useEffect(() => {
    // Store the quill instance for future use
    quillInstance.current = new Quill(quillEditorContainer.current, {
      theme: 'snow',
      modules: {
        toolbar: quillToolbarContainer.current,
      },
    })
    quillEditorContainerTempHolder.current.appendChild(
      quillEditorContainer.current,
    )
    setActiveEditable(undefined)
  }, [])

  /**
   * Add event listeners to quill to update the active editable
   * when we type into the quill editor.
   */
  useEffect(() => {
    if (quillInstance.current && activeEditable) {
      const quill = quillInstance.current
      const onTextChange = () => {
        setEditables([{ details: quill.container.firstChild.innerHTML }])
        onChange(quill.container.firstChild.innerHTML)
      }
      quill.on('text-change', onTextChange)
      return () => {
        quill.off('text-change', onTextChange)
      }
    }
  }, [quillInstance, activeEditable, editables, setEditables])

  // useEffect(() => {
  //   onChange(editables)
  // }, [editables])

  /**
   * An editable has told us it wants to activate/deactivate itself.
   *
   * On activate, update the contents of the quill editor to be the
   * contents of the activated editable, then set the current active
   * editable to the one making the request. Also, position the carret
   * of the quill editor to the end of the content.
   *
   * On deactivation, set the active editable to undefined and move
   * the quill editor DOM element back to the temp holder element.
   */
  const setEditableActive = (editable, activate, key) => {
    if (activate) {
      const quill = quillInstance.current
      /* const delta = quill.clipboard.convert(editable.details.includes('<li')
                    ? `<ol>${editable.details}</ol>`
                    : `<div>${editable.details}</div>`) */
      const delta = quill.clipboard.convert(editable.details)
      quill.setContents(delta, 'silent')
      setActiveEditable(editable)
      setActiveEditableKey(key)
      setTimeout(() => {
        quill.setSelection({ index: 0, length: quill.getLength() - 1 }, 'api')
      })
    } else {
      quillEditorContainerTempHolder.current.appendChild(
        quillEditorContainer.current,
      )
      setActiveEditable(undefined)
      setActiveEditableKey(-1)
    }
  }

  function createMarkup(text) {
    return { __html: text }
  }

  return (
    <div>
      <Row className='w-100 p-0 m-0'>
        <Col
          className='min-vh-60'
          style={{
            padding: '0px',
          }}
        >
          <div className='bg-light p-3' style={{ minHeight: '60vh' }}>
            <div
              id='template-content'
              className='template-content p-3'
              style={{ minHeight: '50vh', marginTop: '20px' }}
            >
              <div
                className='default pb-0'
                dangerouslySetInnerHTML={createMarkup(agreement)}
              />
              <div className='editables-container'>
                {editables?.map((editable, key) => (
                  <>
                    <div
                      key={'section-' + (key + 1)}
                      className={`section ${
                        activeEditableKey === key ? 'editable' : ''
                      }`}
                    >
                      <Editable
                        withIcon
                        editable={editable}
                        content={editable.details}
                        onChangeActive={(e, a) => setEditableActive(e, a, key)}
                        quillEditorContainer={quillEditorContainer}
                        isActive={activeEditableKey === key}
                        key={editable.id}
                      />
                    </div>
                  </>
                ))}
              </div>
              <div
                className='default pt-0'
                dangerouslySetInnerHTML={createMarkup(signature)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{ position: 'fixed', left: '100vw', top: 0 }}
        ref={quillEditorContainerTempHolder}
      >
        <div ref={quillEditorContainer} />
      </div>
    </div>
  )
}

export default EquipmentEditor
