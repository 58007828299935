export default function validateSchema(
  schema,
  data,
  {
    setFocus = () => {},
    setError = () => {},
    clearErrors = () => {},
    onValidSchema = () => {},
  } = {},
) {
  schema
    .validate(data, { abortEarly: false })
    .then(() => {
      clearErrors()
      onValidSchema?.()
    })
    .catch((errors) => {
      const inner = errors?.inner ?? []
      clearErrors()

      inner.forEach((error, index) => {
        if (index === 0) {
          try {
            setFocus(error.path)
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Could not set focus to field: ' + error.path)
          }
        }
        const { path, message } = error
        setError(path, { message })
      })
    })
}
