import {
  ListDashes,
  MagnifyingGlass,
  TreeStructure,
} from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Panel, useNodes, useReactFlow } from 'reactflow'
import { ButtonGroup, Card } from 'reactstrap'
import toastr from 'toastr'

import 'reactflow/dist/base.css'
import { cn } from 'ui'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import Head from '../../components/head'
import BadgeV2 from '../../components/ui/badge-v2'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { useFetch, usePermissions } from '../../helpers/hooks'
import {
  deleteDepartment,
  getDepartmentsData,
  getOrganizationChart,
  updateDepartment,
} from '../../services/api-org-charts'
import { AddDepartment, ManageDepartment } from './manage-department'
import { MissingDepartments } from './missing-departments'
import { MissingManagers } from './missing-managers'
import { OrganizationChart } from './organization-chart'
import { SearchWorkers } from './search-workers'
import { ConfirmationModal } from '../remotepass-cards/components/active-credit-card-section'
import permissions from '../../helpers/permissions'

function getNodesEdges(chartData) {
  const contracts = chartData?.contracts_tree
  if (!contracts) {
    return { nodes: [], edges: [] }
  }

  // sort contracts to have the contracts with no manager first
  contracts?.sort((a, b) => {
    if (a.manager_contract_id && !b.manager_contract_id) {
      return 1
    }
    if (!a.manager_contract_id && b.manager_contract_id) {
      return -1
    }
    return 0
  })

  const theNodes = []
  const theEdges = []

  const yPosition = 0
  const hash = String(Math.floor(Math.random() * 1000))
  const rootNodeId = `manager-root-${String(chartData.company_id)}-${hash}`

  theNodes.push({
    id: rootNodeId,
    // Parent node should be at the top
    // The position will be calculated later
    position: { x: 450, y: yPosition },
    data: {
      name: chartData.company_name,
      logo: chartData.company_logo,
      handless: true,
    },
    type: 'companyProfile',
    style: { cursor: 'default' },
    selectable: false,
  })

  contracts.forEach((contract) => {
    const nodeId = `manager-${String(contract.id)}-${hash}`
    theNodes.push({
      id: nodeId,
      position: { x: 450, y: yPosition },
      data: {
        id: contract.id,
        managerId: contract.manager_contract_id,
        departmentId: contract.department_id,
        name: contract.name,
        role: contract.title,
        photo: contract.photo,
        flag: contract.flag,
        isTopLevel: !contract.manager_contract_id,
        isLastLevel: !contract.children_contracts_ids?.length,
      },
      type: 'userProfile',
    })

    let parentNodeId = nodeId

    if (contract?.employee_count > 0) {
      const empCountNodeId = 'count-' + nodeId
      parentNodeId = empCountNodeId

      theEdges.push({
        id: `${nodeId}-${empCountNodeId}`,
        source: nodeId,
        target: String(empCountNodeId),
        type: 'smoothstep',
      })

      theNodes.push({
        id: String(empCountNodeId),
        position: { x: 450, y: yPosition },
        data: { noOfEmployees: contract.employee_count },
        style: { cursor: 'default' },
        type: 'departmentEmployeesNumber',
      })
    }

    if (contract?.children_contracts_ids?.length > 0) {
      contract.children_contracts_ids.forEach((childId) => {
        const targetID = 'manager-' + String(childId) + '-' + hash
        theEdges.push({
          id: `${parentNodeId}-${childId}`,
          source: String(parentNodeId),
          target: targetID,
          type: 'smoothstep',
        })
      })
    }
    if (!contract.manager_contract_id) {
      theEdges.push({
        id: `${rootNodeId}-${nodeId}`,
        source: rootNodeId,
        target: nodeId,
        type: 'smoothstep',
        style: { stroke: 'transparent' },
      })
    }
  })

  return { nodes: theNodes, edges: theEdges }
}

function getDepartmentsNodesEdges(departmentsData) {
  const depNodes = []
  const depEdges = []

  let depYPosition = 0
  const hash = String(Math.floor(Math.random() * 1000))

  const parentNodeId = `${departmentsData?.company_id}-${hash}`
  const parentNode = {
    id: parentNodeId,
    position: { x: 450, y: depYPosition },
    data: {
      name: departmentsData?.company_name,
      logo: departmentsData?.company_logo,
    },
    type: 'companyProfile',
    style: { cursor: 'default' },
    selectable: false,
  }
  depYPosition += 100

  const assignedEmployeesCount =
    departmentsData?.employee_count -
    departmentsData?.contracts_missing_department.length

  const parentCountId = `count-${parentNodeId}`
  depNodes.push(parentNode)
  depNodes.push({
    id: parentCountId,
    position: { x: 450, y: depYPosition },
    data: { noOfEmployees: assignedEmployeesCount },
    style: { cursor: 'default' },
    type: 'departmentEmployeesNumber',
  })
  depEdges.push({
    id: `${parentNodeId}-${parentCountId}`,
    source: parentNodeId,
    target: parentCountId,
    type: 'smoothstep',
  })

  departmentsData?.departments?.forEach((department) => {
    const depId = `${department.id}-${hash}`
    depNodes.push({
      id: depId,
      position: { x: 450, y: depYPosition },
      data: department,
      type: 'departmentInfo',
    })
    depEdges.push({
      id: `${parentCountId}-${depId}`,
      source: parentCountId,
      target: depId,
      type: 'smoothstep',
    })

    depYPosition += 100

    const depHeads = department.employees
      .filter((emp) => emp.is_head_of_department)
      .map((emp) => ({
        ...emp,
        id: `${emp.id}-${hash}`,
      }))
    const depEmployees = department.employees
      .filter((emp) => !emp.is_head_of_department)
      .map((emp) => ({
        ...emp,
        id: `${emp.id}-${hash}`,
      }))

    const departmentMembersCount = department.employee_count - depHeads.length
    const departmentMembersCountId = `count-${depId}`

    const countNode = {
      id: departmentMembersCountId,
      noOfEmployees: departmentMembersCount,
    }

    const allItems = [
      ...depHeads,
      depHeads.length > 0 ? countNode : null,
      ...depEmployees,
    ]
      .filter(Boolean)
      .map((item) => ({ ...item, id: String(item.id) }))

    allItems?.forEach((empGroup, index) => {
      const currentId = empGroup.id
      const prevId = index === 0 ? depId : allItems[index - 1].id

      depEdges.push({
        id: `${prevId}-${currentId}`,
        source: prevId,
        target: currentId,
        type: 'smoothstep',
      })

      if (currentId === departmentMembersCountId) {
        depNodes.push({
          id: currentId,
          position: { x: 450, y: depYPosition },
          data: { noOfEmployees: empGroup.noOfEmployees },
          style: { cursor: 'default' },
          type: 'departmentEmployeesNumber',
        })
      } else {
        depNodes.push({
          id: currentId,
          position: { x: 450, y: depYPosition },
          data: {
            name: empGroup.name,
            role: empGroup.title,
            photo: empGroup.photo,
            flag: empGroup.flag,
            id: empGroup.id,
            managerId: empGroup.manager_contract_id,
            isHead: empGroup.is_head_of_department,
            departmentId: empGroup.department_id,
          },
          type: 'userProfile',
        })
      }

      depYPosition += 100
    })
  })

  return { nodes: depNodes, edges: depEdges }
}

const VIEW = {
  DEPARTMENT: 'department',
  MANAGERS: 'managers',
}

export function OrganizationChartPage() {
  const { hasAccess } = usePermissions()
  const hasViewPermission = hasAccess(permissions.ViewOrgChart)
  const hasManagePermission = hasAccess(permissions.ManageOrgChart)
  const [selectedDep, setSelectedDep] = useState(null)
  function openDepSidebar(dep) {
    setSelectedDep(dep)
  }
  function closeDepSidebar() {
    setSelectedDep(null)
  }
  const [selectedItem, setSelectedItem] = useState(null)
  function openSidebar(item) {
    setSelectedItem(item)
  }
  function closeSidebar() {
    setSelectedItem(null)
  }

  function handleNodeClick(_, node) {
    if (!node) {
      return
    }

    if (node.type === 'userProfile') {
      openSidebar(node)
    }
    if (node.type === 'departmentInfo' && hasManagePermission) {
      openDepSidebar(node)
    }
  }

  const history = useHistory()
  const location = history.location

  useEffect(() => {
    if (!hasViewPermission) {
      history.push('/contracts')
    }
  }, [hasViewPermission, history])

  const searchParams = new URLSearchParams(location.search)
  const view = searchParams.get('view') ?? VIEW.MANAGERS

  const {
    data: companyChartData,
    isLoading: chartDataLoading,
    startFetch: refreshOrgChartData,
    completed: orgChartDataCompleted,
  } = useFetch(
    {
      action: getOrganizationChart,
      autoFetch: view === VIEW.MANAGERS,
      onError: (error) => toastr.error(error),
    },
    [view],
  )
  const {
    data: depData,
    isLoading: depDataLoading,
    startFetch: refreshDepData,
    completed: depDataCompleted,
  } = useFetch(
    {
      action: getDepartmentsData,
      autoFetch: view === VIEW.DEPARTMENT,
    },
    [view],
  )

  const isManagersView = view === VIEW.MANAGERS
  const isDepartmentView = view === VIEW.DEPARTMENT

  const allContracts = isManagersView
    ? companyChartData?.contracts_tree
    : depData?.all_contracts

  const loadingData = isManagersView
    ? chartDataLoading || !orgChartDataCompleted
    : isDepartmentView
      ? depDataLoading || !depDataCompleted
      : false

  const selectedData = {
    [VIEW.MANAGERS]: getNodesEdges(companyChartData),
    [VIEW.DEPARTMENT]: getDepartmentsNodesEdges(depData),
  }[view]

  const badgeData = {
    [VIEW.MANAGERS]: [
      { count: companyChartData?.employee_count, label: 'Employees' },
      { count: companyChartData?.manager_count, label: 'Managers' },
    ],
    [VIEW.DEPARTMENT]: [
      { count: depData?.employee_count, label: 'Employees' },
      { count: depData?.department_count, label: 'Departments' },
    ],
  }[view]

  return (
    <div style={{ '--nav-header-height': '5.125rem' }}>
      <Head title='Organization Chart' />

      <div className='tw-flex tw-h-[--nav-header-height] tw-flex-wrap tw-items-center tw-gap-2 tw-border tw-border-surface-30 tw-bg-white tw-p-6'>
        <h1 className='tw-mb-0 tw-text-sm tw-font-bold'>Organization Chart</h1>

        {loadingData
          ? null
          : badgeData.map(({ count, label }, index) => {
              return (
                <BadgeV2 color='light' key={index}>
                  {count}{' '}
                  <span className='tw-font-normal tw-uppercase'>{label}</span>
                </BadgeV2>
              )
            })}

        <ModalCloseButton
          toggle={() => {
            history.push('/contracts')
          }}
          className='tw-ml-auto'
        />
      </div>

      {loadingData ? (
        <Loader
          minHeight=''
          className='tw-h-[calc(100dvh-var(--nav-header-height))]'
        />
      ) : (
        <Card
          className='tw-relative !tw-mb-0 tw-h-[calc(100dvh-var(--nav-header-height))] !tw-bg-surface-20'
          style={{ '--filters-nav-height': '3.125rem' }}
        >
          <div className='tw-absolute tw-inset-0'>
            <OrganizationChart
              {...selectedData}
              key={view}
              onNodeClick={handleNodeClick}
            >
              {view === VIEW.MANAGERS && hasManagePermission ? (
                <MissingManagers
                  companyChartData={companyChartData}
                  refreshData={refreshOrgChartData}
                />
              ) : view === VIEW.DEPARTMENT && hasManagePermission ? (
                <>
                  <AddDepartment refreshData={refreshDepData} />

                  <MissingDepartments
                    depData={depData}
                    refreshData={refreshDepData}
                  />
                </>
              ) : null}

              <Panel
                position='top-left'
                className='tw-z-20 tw-flex tw-items-center tw-gap-4'
              >
                <ButtonGroup>
                  {[
                    {
                      key: VIEW.MANAGERS,
                      icon: <TreeStructure size={16} weight='fill' />,
                    },
                    {
                      key: VIEW.DEPARTMENT,
                      icon: <ListDashes size={16} weight='bold' />,
                    },
                  ].map(({ key, icon }) => {
                    const isActive = view === key
                    return (
                      <Button
                        key={key}
                        tag={Link}
                        to={`/org-chart?view=${key}`}
                        size='sm'
                        className={cn(
                          'tw-size-10 !tw-border-surface-30',
                          isActive
                            ? '!tw-bg-primary-10 !tw-text-primary-100'
                            : '!tw-bg-white !tw-text-text-80',
                          {
                            '!tw-rounded-r-none': key === VIEW.MANAGERS,
                            '!tw-rounded-l-none': key === VIEW.DEPARTMENT,
                          },
                        )}
                        icon={icon}
                        aria-label={
                          key === VIEW.MANAGERS
                            ? 'View managers'
                            : 'View departments'
                        }
                      />
                    )
                  })}
                </ButtonGroup>

                <SearchFlow
                  allContracts={allContracts}
                  onSuccess={() => {
                    if (isManagersView) {
                      refreshOrgChartData()
                    } else {
                      refreshDepData()
                    }
                  }}
                />
              </Panel>
            </OrganizationChart>

            {!selectedItem ? null : (
              <SearchWorkers
                isOpen={!!selectedItem}
                toggleSidebar={closeSidebar}
                onSuccess={() => {
                  if (isManagersView) {
                    refreshOrgChartData()
                  } else {
                    refreshDepData()
                  }
                }}
                allContracts={allContracts}
                openSpecificContract={selectedItem.data?.id}
                isManagerView={isManagersView}
              />
            )}

            {!selectedDep ? null : (
              <ManageDepartmentWrapper
                isOpen={selectedDep}
                toggleSidebar={() => closeDepSidebar()}
                defaultValues={{
                  id: selectedDep?.data?.id,
                  name: selectedDep?.data?.name,
                  color: selectedDep?.data?.color,
                }}
                onSuccess={refreshDepData}
              />
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

function handleComplete({ data, action, onSuccess }) {
  if (data?.success === false) {
    toastr.error(`Failed to ${action} department.`)
  } else {
    toastr.success(`Department ${action}d successfully.`)
    onSuccess?.()
  }
}

function ManageDepartmentWrapper(props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { startFetch: updateDep, isLoading: isUpdatingDep } = useFetch({
    action: updateDepartment,
    onComplete: (data) => {
      handleComplete({
        data,
        action: 'update',
        onSuccess: () => {
          props.toggleSidebar()
          props.onSuccess?.()
        },
      })
    },
  })

  const { startFetch: deleteDep, isLoading: isDeletingDep } = useFetch({
    action: deleteDepartment,
    onComplete: (data) => {
      handleComplete({
        data,
        action: 'delete',
        onSuccess: () => {
          props.toggleSidebar()
          props.onSuccess?.()
          setShowDeleteConfirmation(false)
        },
      })
    },
  })

  const loading = isUpdatingDep || isDeletingDep

  return (
    <>
      <ManageDepartment
        {...props}
        onSubmit={(data) => {
          updateDep(data)
        }}
        onDelete={() => {
          setShowDeleteConfirmation(true)
        }}
        loading={loading}
      />
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        loading={loading}
        showX
        toggle={() => setShowDeleteConfirmation(false)}
        title='Delete Department'
        content='Are you sure you want to delete this department?'
        onCancel={() => setShowDeleteConfirmation(false)}
        cancelColor='light'
        confirmColor='danger'
        onConfirm={() => {
          deleteDep({ id: props.defaultValues.id })
        }}
        confirmText='Delete'
      />
    </>
  )
}

function SearchFlow({ allContracts, onSuccess }) {
  const [isOpen, setIsOpen] = useState(false)
  const { setCenter, setNodes } = useReactFlow()
  const nodes = useNodes()
  function toggleSidebar() {
    setIsOpen((open) => !open)
  }
  function findNode(contractId) {
    const node = nodes.find((n) => n.id.startsWith(`manager-${contractId}`))
    const x = node.position.x + node.width / 2
    const y = node.position.y + node.height / 2
    const zoom = 1.4

    setNodes((nodes) => {
      return nodes.map((n) => {
        if (n.id === node.id) {
          return { ...n, selected: true }
        }
        return { ...n, selected: false }
      })
    })

    setCenter(x, y, { zoom, duration: 1000 })
  }

  return (
    <>
      <Button
        size='sm'
        className='tw-size-10 !tw-border-surface-30 !tw-bg-white !tw-text-text-80'
        icon={<MagnifyingGlass size={16} />}
        onClick={toggleSidebar}
      />

      {isOpen && (
        <SearchWorkers
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          findNode={findNode}
          onSuccess={onSuccess}
          allContracts={allContracts}
        />
      )}
    </>
  )
}
