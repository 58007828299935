import * as yup from 'yup'
import isNill from '../utils/is-nill'

/// Build EOS Plans Schema
export const buildEOSPlansSchema = (plans) => {
  const schema = {}
  const requiredSchema = (schema, title) =>
    schema
      .required(`'${title}' is required`)
      .notOneOf([''], `'${title}' is required`)

  plans?.forEach((field) => {
    const { name, title, is_required: isRequired } = field
    let fieldSchema = yup.mixed()
    if (isRequired) {
      fieldSchema = requiredSchema(fieldSchema, title)
    }
    // TODO add the actual contribution plan id and jurisdiction id instead 0f 0-0
    schema[`form_field_${name}-0-0`] = fieldSchema
  })
  return schema
}

/// Used to format the data before sending it to the API
export const formatEOSPlanData = (data) => {
  const formFields = Object.keys(data).filter((key) =>
    key.includes('form_field_'),
  )
  const inputs = formFields
    .map((key) => {
      const [name, jurisdictionId, contributionPlanId] = key.split('-')
      if (
        !name ||
        !jurisdictionId ||
        !contributionPlanId ||
        isNill(data[key]) ||
        data[key] === ''
      )
        return null
      const value = {
        jurisdiction_id: jurisdictionId,
        contribution_plan_id: contributionPlanId,
        name: name.replace('form_field_', ''),
        value: data[key],
      }
      // if no plan or jurisdiction is selected, remove them from the data (will be provided as '0','0')
      if (value.jurisdiction_id === '0' || value.contribution_plan_id === '0') {
        delete value.jurisdiction_id
        delete value.contribution_plan_id
      }
      delete data[key]
      return value
    })
    .filter(Boolean)
  return {
    ...data,
    jurisdiction_eos_plan_inputs: inputs,
  }
}

/// Used to get the default values for the EOS plans
export const getEOSPlansDefaultValues = (data) => {
  const output = {}

  if (data === undefined) return output
  const plans = data?.jurisdiction_eos_plan_fields

  plans?.forEach((plan) => {
    const entries = plan?.fields ?? []
    entries.forEach((entry) => {
      const formFieldKey = `form_field_${entry.name}-${plan.jurisdiction_id}-${plan.contribution_plan_id}`
      output[formFieldKey] = entry.value
    })
  })

  return output
}
