import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Play } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'

export function PublishConfirmModal(props) {
  const { isOpen, toggle, onPublish } = props

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Play size={25} className='tw-text-systemGreen' />
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <span className='tw-text-base tw-font-bold'>Publish automation</span>
        <span className='tw-text-s tw-text-secondary'>
          Are you sure you want to publish this automation?
        </span>
      </ModalBody>
      <ModalFooter>
        <Button
          className='tw-mb-2 !tw-bg-white'
          textClassName='!tw-text-black'
          color='surface-30'
          outline={true}
          onClick={toggle}
        >
          Stay on this page
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onPublish()
            toggle()
          }}
        >
          Yes, publish
        </Button>
      </ModalFooter>
    </Modal>
  )
}
