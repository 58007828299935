import {
  FRESH_UPDATE_PRE_DATA,
  FRESH_SUBMIT_ACCOUNT_MAPPING,
  FRESH_UPDATE_CONTRACTOR,
  FRESH_UPDATE_REQUEST,
  FRESH_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
  FRESH_UPDATE_ORGANIZATIONS,
  FRESH_UPDATE_ORGANIZATION,
  FRESH_XERO_INIT,
} from './actionTypes'

export const freshUpdatePreData = (payload) => {
  return {
    type: FRESH_UPDATE_PRE_DATA,
    payload,
  }
}

export const freshUpdateOrganizations = (payload) => {
  return {
    type: FRESH_UPDATE_ORGANIZATIONS,
    payload,
  }
}

export const updateOrganization = (payload) => {
  return {
    type: FRESH_UPDATE_ORGANIZATION,
    payload,
  }
}
export const initFresh = () => {
  return {
    type: FRESH_XERO_INIT,
  }
}

export const freshSubmitAccountMapping = (payload) => {
  return {
    type: FRESH_SUBMIT_ACCOUNT_MAPPING,
    payload,
  }
}

export const freshUpdateContractor = (payload) => {
  return {
    type: FRESH_UPDATE_CONTRACTOR,
    payload,
  }
}

export const freshUpdateRequest = (payload) => {
  return {
    type: FRESH_UPDATE_REQUEST,
    payload,
  }
}
export const freshSubmitAccountMappingAndUpdateRequest = (payload) => {
  return {
    type: FRESH_SUBMIT_ACCOUNT_MAPPING_AND_UPDATE_REQUEST,
    payload,
  }
}
