import iconAch from './icon-ach.svg'
import iconBankTransfer from './icon-bank_transfer.svg'
import iconCoinbase from './icon-coinbase.svg'
import iconPaypal from './icon-paypal.svg'
import iconRemotepass from './icon-remotepass.svg'
import iconSepa from './icon-sepa.svg'
import iconWise from './icon-wise.svg'

export const icons = {
  ach: iconAch,
  bank_transfer: iconBankTransfer,
  coinbase: iconCoinbase,
  paypal: iconPaypal,
  remotepass: iconRemotepass,
  sepa: iconSepa,
  wise: iconWise,
}
