import { CalendarCheck, CalendarX } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'

export default function TimeOffDaysCount({ timesOff, className }) {
  return (
    <div
      className={cx(
        'bg-surface-10 gap-24 p-2 rounded flex-shrink-0',
        className,
      )}
    >
      <DaysInfo
        label='Paid'
        value={timesOff?.days_paid}
        icon={<CalendarCheck size={20} />}
      />
      <div className='bg-slate-200' style={{ width: 1 }} />
      <DaysInfo
        label='Unpaid'
        value={timesOff?.days_unpaid}
        icon={<CalendarX size={20} />}
      />
    </div>
  )
}

function DaysInfo({ label, value, icon }) {
  return (
    <div className='text-gray-h font-size-14 d-flex align-items-center gap-8'>
      <div className='align-items-center d-flex gap-2 justify-content-center text-slate-500'>
        {!icon ? null : icon}
        <span>{label}</span>
      </div>
      <div className=''>
        {value === 1
          ? `${value} Day`
          : !!value || value === 0
            ? `${value} days`
            : '--'}
      </div>
    </div>
  )
}
