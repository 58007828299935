import {
  HourglassLow,
  ShieldCheck,
  ShieldWarning,
  Spinner,
} from '@phosphor-icons/react'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, Col, NavItem, Row, TabPane } from 'reactstrap'
import toastr from 'toastr'

import backgroundBlue from '../../assets/images/bkblue.svg'
import ChangePassword from '../../components/CommonForBoth/ChangePassword'
import BadgeX from '../../components/Table/BadgeX'
import { StyledH3 } from '../../components/Typo'
import Head from '../../components/head'
import { PageNav } from '../../components/page-nav'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import TabContent from '../../components/ui/tabs'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { getClientInfo, updateClientInfo } from '../../services/api'
import { updateUserProfileInfo } from '../../store/profile/actions'
import capitalizeFirstLetter from '../../utils/capitalize-first-letter'
import { getErrorMessage } from '../../utils/get-errors'
import { getFullName } from '../../utils/get-full-name'
import ManageAvatar from '../CompanySetting/manage-avatar'
import Kyc from '../ContractorSettings/KYC'
import Notifications from './Notifications'
import ProfileInfo from './ProfileInfo'

const ALL_TABS = {
  generalInfo: { id: 1, label: 'General Info', hash: 'general' },
  security: { id: 2, label: 'Security', hash: 'security' },
  notifications: { id: 3, label: 'Notifications', hash: 'notifications' },
  verification: { id: 4, label: 'Verification', hash: 'verification' },
}

const profileTabs = [
  ALL_TABS.generalInfo,
  ALL_TABS.notifications,
  ALL_TABS.verification,
  ALL_TABS.security,
]

export default function ProfileSettings() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const user = useSelector((state) => state?.Account?.user)

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const activeTab =
    profileTabs.find(({ hash }) => tab === hash) ?? ALL_TABS.generalInfo
  const activeTabId = activeTab.id

  const {
    isLoading: loadingClientInfo,
    data: clientInfo,
    startFetch: refetchClientInfo,
  } = useFetch({
    action: getClientInfo,
    autoFetch: true,
    onComplete: (_, body) => {
      if (body?.disabledAnalytics !== true) {
        window.analytics.track('Viewed profile', { 'email-id': user?.email })
      }
    },
  })

  const { startFetch: updateInfo, isLoading: updatingInfo } = useFetch({
    action: updateClientInfo,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(getErrorMessage(data?.error))
      } else {
        dispatch(updateUserProfileInfo(data))
        refetchClientInfo?.({ disabledAnalytics: true })
      }
    },
  })

  return (
    <div className='page-content position-relative'>
      <Head
        title={[activeTab?.label, 'Profile settings']
          .filter(Boolean)
          .join(' - ')}
      />

      <img
        className='position-absolute left-0 right-0 bg-primary w-100'
        style={{
          backgroundRepeat: 'repeat',
          backgroundImage: backgroundBlue ? `url(${backgroundBlue})` : null,
          top: 'var(--header-height)',
          height: 210,
        }}
      />

      <Row className='pt-4.5 mx-0'>
        <Col md={3} className='px-0'>
          <div className='d-flex flex-column align-items-center bg-white rounded pt-4 rp-shadow-2'>
            <ManageAvatar
              src={userProfile?.photo}
              name={user?.first_name}
              onUploadPhoto={(acceptedFiles) => {
                const file = acceptedFiles[0]
                if (file.size > 2000000) {
                  toastr.error('The file may not be greater than 2MB')
                } else {
                  const dataInfo = { photo: acceptedFiles[0] }
                  updateInfo(dataInfo)
                }
              }}
              isUploading={loading || updatingInfo}
              avatarTitleClassName={
                user?.type === 'client' ? 'avatar-title-green' : 'avatar-title'
              }
            />
            <StyledH3
              min='22px'
              mid='1.25em'
              max='24px'
              style={{ fontWeight: 600 }}
              className='tw-mt-2 tw-px-2 tw-text-center'
              translate='no'
            >
              {getFullName(user)}
            </StyledH3>

            <BadgeX status='secondary'>
              {capitalizeFirstLetter(user?.type)}
            </BadgeX>

            {userProfile?.is_company_creator && (
              <div className='mt-2'>
                {!userProfile ? (
                  <BadgeX
                    status='light'
                    textStatus='secondary'
                    className='d-flex gap-6'
                    leftIcon={<Spinner size='sm' />}
                  >
                    Checking Verification
                  </BadgeX>
                ) : userProfile?.kyc_verified === 1 ? (
                  <BadgeX
                    leftIcon={
                      <ShieldCheck weight='fill' className='tw-size-4' />
                    }
                    status='success'
                  >
                    Identity verified
                  </BadgeX>
                ) : userProfile?.kyc_status === 'submitted' ? (
                  <BadgeX
                    leftIcon={
                      <HourglassLow weight='fill' className='tw-size-4' />
                    }
                    status='warning'
                  >
                    Verification in progress
                  </BadgeX>
                ) : (
                  <BadgeX
                    status='danger'
                    leftIcon={
                      <ShieldWarning weight='bold' className='tw-size-4' />
                    }
                  >
                    Identity not verified
                  </BadgeX>
                )}
              </div>
            )}

            <div className='border-top my-md-4 mb-3 mt-4 w-100' />

            <div className='px-3 px-md-4 pb-3 pb-md-4 w-100'>
              <Button block outline color='light' tag={Link} to='/logout'>
                Log out
              </Button>
            </div>
          </div>
        </Col>

        <Col md={9} className='pl-md-4 pr-md-0'>
          <Card>
            <PageNav>
              {profileTabs.map(({ label, hash, id }) => {
                if (
                  userProfile?.type === userTypes.COMPANY &&
                  !userProfile?.is_company_creator &&
                  hash === 'verification'
                ) {
                  return null
                }
                return (
                  <NavItem key={`prNav-${id}`}>
                    <PageNav.Link
                      tag={Link}
                      to={{ search: `?tab=${hash}` }}
                      isActive={activeTabId === id}
                    >
                      {label}
                    </PageNav.Link>
                  </NavItem>
                )
              })}
            </PageNav>

            {loadingClientInfo ? (
              <Loader minHeight='calc(60vh - 73px)' />
            ) : (
              <TabContent activeTab={activeTabId}>
                <TabPane tabId={ALL_TABS.generalInfo.id}>
                  <ProfileInfo
                    ref={formRef}
                    clientInfo={clientInfo}
                    onStart={() => setLoading(true)}
                    onFinish={() => setLoading(false)}
                  />
                </TabPane>
                <TabPane tabId={ALL_TABS.security.id}>
                  <ChangePassword />
                </TabPane>
                <TabPane tabId={ALL_TABS.notifications.id}>
                  {activeTabId !== ALL_TABS.notifications.id ? null : (
                    <Notifications />
                  )}
                </TabPane>
                {userProfile?.type === userTypes.COMPANY &&
                !userProfile?.is_company_creator ? null : (
                  <TabPane tabId={ALL_TABS.verification.id}>
                    {activeTabId !== ALL_TABS.verification.id ? null : (
                      <Kyc isClient userInfo={clientInfo} />
                    )}
                  </TabPane>
                )}
              </TabContent>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}
