import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loginUserSuccessful, updateDeepLink } from '../../store/actions'
import { withRouter } from 'react-router-dom'
import { useFetch } from '../../helpers/hooks'

import toastr from 'toastr'

import { Spinner } from 'reactstrap'
import { switchApi, switchEntity } from '../../services/api'
import { updateProfileCompany } from '../../store/profile/actions'
import { useLocation } from 'react-router'

const Switch = (props) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Account?.user)
  const link = useSelector((state) => state.Account?.link)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const location = useLocation()

  const redirect = () => {
    if (link && typeof link === 'string') {
      props.history.push(link)
      dispatch(updateDeepLink(null))
    } else {
      props.history.push('/activity')
    }
  }
  const switchE = useFetch({
    action: switchEntity,
    onComplete: (data) => {
      dispatch(updateProfileCompany(data))
      redirect()
    },
    onError: () => {
      redirect()
    },
  })
  const switchFunc = useFetch({
    autoFetch: false,
    initResult: null,
    action: switchApi,
    onComplete: async (data) => {
      let changedUser = user
      changedUser = {
        ...changedUser,
        type: user?.type === 'client' ? 'contractor' : 'client',
      }
      changedUser = { ...changedUser, ...data }

      dispatch(loginUserSuccessful(changedUser))

      if (
        !!location.state?.entity_id &&
        userProfile?.company?.id !== location.state?.entity_id
      ) {
        switchE.startFetch(
          { entity_id: location.state?.entity_id },
          true,
          null,
          data.token,
        )
      } else {
        redirect()
      }
    },
    onError: (error) => {
      toastr.error(error)
      props.history.push('/activity')
    },
  })

  useEffect(() => {
    if (location?.state?.notSwitch) {
      switchE.startFetch({ entity_id: location.state?.entity_id })
    } else {
      switchFunc.startFetch()
    }
  }, [])

  return (
    <div
      style={{ minHeight: '100vh' }}
      className='d-flex justify-content-center align-items-center'
    >
      {(switchFunc.isLoading || switchE.isLoading) && (
        <Spinner type='grow' className='mr-2' color='primary' />
      )}
    </div>
  )
}

export default withRouter(Switch)
