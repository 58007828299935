import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { connectCoinbase, verifyCoinbase } from '../../services/api'
import { COINBASE_CURRENCY } from '../../utils/formatters/currency-coinbase'
import CoinbaseMethodCard from '../Withdrawal/components/CoinbaseMethodCard'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'

toastr.options.timeOut = 10000

const CoinbaseAccounts = ({
  onNext = () => {},
  noCards,
  onAdd = () => {},
  onCancel = () => {},
}) => {
  const [checkedCard, setCheckedCard] = useState(-1)
  const [showModal, setShowModal] = useState(false)

  const history = useHistory()
  const location = history.location
  const code = new URLSearchParams(location.search).get('code')

  const { type } = useParams()

  const {
    getAccountsList,
    accounts,
    loading: gettingAccounts,
  } = useWithdrawAccounts('coinbase')

  useFetch({
    action: verifyCoinbase,
    autoFetch: type === 'coinbase' && !!code,
    body: {
      code,
      type: location?.pathname.includes('settings') ? 'settings' : 'withdraw',
    },
    onComplete: () => {
      onAdd()
      getAccountsList()
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/coinbase'
          : '/withdrawal-process/coinbase',
      )
    },
    onError: (err) => {
      toastr.error(err)
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/coinbase'
          : '/withdrawal-process/coinbase',
      )
    },
  })

  function toggle() {
    setShowModal((open) => !open)
  }

  return (
    <>
      <div className='h-100'>
        {!noCards ? (
          <div>
            {gettingAccounts ? (
              <Loader minHeight='15rem' />
            ) : (
              <Col className='p-0 d-flex flex-column' style={{ gap: '1rem' }}>
                {accounts.map((e, i) => (
                  <CoinbaseMethodCard
                    onClick={() => {
                      onNext(e)
                      setCheckedCard(i)
                    }}
                    key={`ppAcc-${i}`}
                    account={e}
                    index={i}
                    checked={checkedCard === i}
                  />
                ))}
                <button
                  onClick={() => {
                    setShowModal(true)
                  }}
                  className='rp-btn-nostyle align-self-start text-primary rp-font-bold'
                  type='button'
                >
                  + Add New Coinbase Account
                </button>
              </Col>
            )}
          </div>
        ) : (
          <CoinBaseForm onCancel={onCancel} />
        )}
      </div>

      <Modal
        isOpen={showModal}
        centered
        className='newAccountModal'
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Add Coinbase account</ModalHeader>

        <CoinBaseForm
          onCancel={() => {
            setShowModal(false)
            onCancel()
          }}
        />
      </Modal>
    </>
  )
}

const coinbaseCurrencies = [
  { value: COINBASE_CURRENCY.BTC, label: 'BTC - Bitcoin' },
  { value: COINBASE_CURRENCY.ETH, label: 'ETH - Ethereum' },
  { value: COINBASE_CURRENCY.USDC, label: 'USD Coin' },
]

function CoinBaseForm({ onCancel }) {
  const [currency, setCurrency] = useState(null)
  const location = useLocation()

  const connect = useFetch({
    action: connectCoinbase,
    onComplete: (data) => {
      if (data?.url) {
        window.location.replace(data?.url)
      }
    },
  })

  const handleAddSubmit = () => {
    connect.startFetch({
      type: location?.pathname.includes('settings') ? 'settings' : 'withdraw',
      currency: currency?.value,
    })
  }

  return (
    <>
      <ModalBody>
        <FormGroup className='mb-0'>
          <Label htmlFor='name'>Currency</Label>
          <CustomSelect
            options={coinbaseCurrencies}
            onChange={setCurrency}
            value={currency}
            inputId='name'
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={connect.isLoading}
          color='light'
          outline
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type='button'
          disabled={!currency || connect.isLoading}
          loading={connect.isLoading}
          onClick={handleAddSubmit}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  )
}

export default CoinbaseAccounts
