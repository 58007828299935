import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap'

import customStyles from '../../../../components/Common/react-select-custom-styles'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import {
  downloadEorInvoice,
  getPartnersInvoicesList,
  payPartnerInvoice,
  revertPartnerInvoice,
  revertPartnerInvoiceBreakdown,
} from '../../../../services/api'
import openFile from '../../../../utils/file/open'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'

const invoicesColor = {
  Paid: 'success',
  Approved: 'success',
  Unpaid: 'danger',
  Rejected: 'danger',
  Declined: 'danger',
  Processing: 'warning',
  'Request approval': 'warning',
  'Pending approval': 'info',
  'Pending submission': 'warning',
}

export function InvoiceStatusBadge({ status }) {
  return <BadgeX name={status} status={invoicesColor[status]} />
}

const partnerInvoiceStatuses = {
  PENDING_APPROVAL: { value: 2, label: 'Pending approval' },
  APPROVED: { value: 3, label: 'Approved' },
  DECLINED: { value: 4, label: 'Declined' },
}
const invoiceOptions = Object.values(partnerInvoiceStatuses)
const APPROVED_ID = partnerInvoiceStatuses.APPROVED.value

const currencyFormatter = getCurrencyFormatter()

function getMonthDate(date) {
  const myDate = new Date(date)
  const month = myDate.getMonth() + 1
  const year = myDate.getFullYear()

  return `${year}-${String(month).padStart(2, '0')}`
}

const thisMonth = getMonthDate(new Date())

export default function PartnerInvoices() {
  const [pageFilters, setPageFilters] = useState({
    month: thisMonth,
    status_id: partnerInvoiceStatuses.PENDING_APPROVAL.value,
  })

  const partnerInvoices = useFetch(
    {
      action: getPartnersInvoicesList,
      withAdminAccess: true,
      // eslint-disable-next-line no-console
      onError: console.log,
      autoFetch: true,
      body: pageFilters,
    },
    [pageFilters],
  )

  return (
    <div className='page-content'>
      <h1 style={{ marginBottom: '2rem' }}>Partner Invoices</h1>

      <Card>
        <Container fluid>
          <Row className='p-3' style={{ gap: '0.75rem' }}>
            <Col md={5} xl={3} className='px-0'>
              <Select
                styles={customStyles}
                options={invoiceOptions}
                value={invoiceOptions.find(
                  (e) => e.value === pageFilters.status_id,
                )}
                onChange={(option) => {
                  setPageFilters((prev) => ({
                    ...prev,
                    status_id: option.value,
                  }))
                }}
              />
            </Col>
            <Col md={5} xl={3} className='px-0'>
              <CustomDatePicker
                showMonthYearPicker
                placeholder='Filter by month'
                dateFormat='yyyy-MM'
                value={pageFilters.month}
                handleOnChange={(newDate) => {
                  setPageFilters((prev) => ({
                    ...prev,
                    month: getMonthDate(newDate),
                  }))
                }}
              />
            </Col>
          </Row>
        </Container>

        {partnerInvoices.isLoading || !partnerInvoices.completed ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: '30vh' }}
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : partnerInvoices.data.length <= 0 ? (
          <p
            className='align-items-center d-flex justify-content-center h5 p-3 mb-0 text-muted'
            style={{ minHeight: '30vh', fontWeight: 400 }}
          >
            No invoices found, try changing your filters.
          </p>
        ) : (
          <Table
            responsive
            className='table-nowrap text-muted mb-0'
            style={{ minHeight: '30vh' }}
          >
            <thead>
              <tr>
                <th className='border-top-0'>Details</th>
                <th className='border-top-0'>Partner Name</th>
                <th className='border-top-0'>Status</th>
                <th className='border-top-0'>Submitted At</th>
                <th className='border-top-0'>Total</th>
                {pageFilters.status_id === APPROVED_ID && (
                  <th className='border-top-0'>Payment</th>
                )}
                <th className='border-top-0'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {partnerInvoices.data.map((invoice) => {
                return (
                  <InvoiceLine
                    key={invoice.id}
                    invoice={invoice}
                    refreshList={() => partnerInvoices.startFetch(pageFilters)}
                    statusId={pageFilters.status_id}
                  />
                )
              })}
            </tbody>
          </Table>
        )}
      </Card>
    </div>
  )
}
function getInvoiceInfoItems(invoice) {
  return [
    { label: 'Payment Id:', value: invoice?.payment_id },
    { label: 'Amount:', value: currencyFormatter.format(invoice?.total) },
    {
      label: 'Partner:',
      value: invoice?.partner_name,
    },
  ]
}

const InvoiceLine = ({ invoice, refreshList, statusId }) => {
  const [downloading, setDownloading] = useState(null)
  const [isOpen, setIsOpen] = useState(null)
  const [isRevertOpen, setIsRevertOpen] = useState(false)
  const [isRevertBkOpen, setIsRevertBkOpen] = useState(false)

  const invoiceCurrency = getCurrencyFormatter(invoice?.currency?.code)

  const { startFetch: downloadInvoice, isLoading: downloadingInvoice } =
    useFetch({
      action: downloadEorInvoice,
      withAdminAccess: true,
      onComplete: (data, body) => {
        openFile(data, `partner-invoice-${body?.partner_invoice_id}.pdf`)
        setDownloading(null)
      },
    })
  const { startFetch: payInvoice, isLoading: payingInvoice } = useFetch({
    action: payPartnerInvoice,
    withAdminAccess: true,
    onComplete: () => {
      setIsOpen(false)
      refreshList()
      document.body.classList.remove('modal-open')
    },
  })

  const { startFetch: revertInvoice, isLoading: revertingInvoice } = useFetch({
    action: revertPartnerInvoice,
    withAdminAccess: true,
    onComplete: () => {
      setIsRevertOpen(false)
      refreshList()
      document.body.classList.remove('modal-open')
    },
  })
  const {
    startFetch: revertInvoiceBreakdown,
    isLoading: revertingInvoiceBreakdown,
  } = useFetch({
    action: revertPartnerInvoiceBreakdown,
    withAdminAccess: true,
    onComplete: () => {
      setIsRevertOpen(false)
      refreshList()
      document.body.classList.remove('modal-open')
    },
  })

  function handlePayInvoice(paymentId) {
    payInvoice({ payment_id: paymentId })
  }

  function handlePreviewInvoice(invoiceId) {
    return function () {
      downloadInvoice({ id: invoiceId })
      setDownloading(invoiceId)
    }
  }
  const status =
    typeof invoice.status === 'string' ? invoice.status : invoice.status.name

  const isDownloading = downloadingInvoice && downloading === invoice.id

  function handleRevertInvoice(invoice) {
    if (invoice?.can_revert !== true || !invoice?.id) return

    revertInvoice({ partner_invoice_id: invoice.id })
  }

  function handleRevertInvoiceBreakdown(invoice) {
    if (invoice?.status.id !== APPROVED_ID || !invoice?.id) return

    revertInvoiceBreakdown({ partner_invoice_id: invoice.id })
  }

  return (
    <tr key={invoice.id}>
      <td>{invoice.details}</td>
      <td>{invoice.partner_name}</td>
      <td>
        <InvoiceStatusBadge status={status} />
      </td>
      <td>{invoice.submitted_at}</td>
      <td>{invoiceCurrency.format(invoice.total)}</td>
      {statusId === APPROVED_ID && (
        <td>
          {invoice?.payment_status !== 'Unpaid' ? (
            <InvoiceStatusBadge status={invoice?.payment_status} />
          ) : (
            <Button
              size='sm'
              onClick={() => setIsOpen(true)}
              disabled={isDownloading}
              loading={isDownloading}
            >
              Pay
            </Button>
          )}
        </td>
      )}
      <td className='d-flex align-items-start' style={{ gap: '0.25rem' }}>
        {statusId === APPROVED_ID ? (
          <Button
            size='sm'
            color='danger'
            outline
            onClick={() => setIsRevertBkOpen(true)}
          >
            Revert Breakdown
          </Button>
        ) : (
          <Button
            size='sm'
            tag={Link}
            to={{
              pathname: '/admin/partner-invoice-breakdown',
              state: { invoice },
            }}
          >
            Add Breakdown
          </Button>
        )}

        {invoice?.can_revert !== true ? null : (
          <Button
            size='sm'
            color='danger'
            outline
            type='button'
            onClick={() => setIsRevertOpen(true)}
          >
            Revert Invoice
          </Button>
        )}

        <Button
          size='sm'
          outline
          color='secondary'
          onClick={handlePreviewInvoice(invoice.id)}
          disabled={isDownloading}
          loading={isDownloading}
        >
          Download
        </Button>
      </td>
      <ConfirmPaymentModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        onConfirm={handlePayInvoice}
        invoice={invoice}
        loading={payingInvoice}
      />
      <ConfirmRevertInvoice
        isOpen={isRevertOpen}
        toggle={() => setIsRevertOpen((o) => !o)}
        invoice={invoice}
        onConfirm={handleRevertInvoice}
        loading={revertingInvoice}
      />
      <ConfirmRevertBreakdown
        isOpen={isRevertBkOpen}
        toggle={() => setIsRevertBkOpen((o) => !o)}
        invoice={invoice}
        onConfirm={handleRevertInvoiceBreakdown}
        loading={revertingInvoiceBreakdown}
      />
    </tr>
  )
}

const ConfirmPaymentModal = ({
  isOpen,
  toggle,
  onConfirm,
  invoice,
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Payment</ModalHeader>
      <ModalBody>
        {getInvoiceInfoItems(invoice).map(({ label, value }, index) => {
          return (
            <div key={index} className='mb-2'>
              <span className='rp-font-bold d-inline-block'>{label}</span>{' '}
              <span className='d-inline-block'>{value}</span>
            </div>
          )
        })}
        <p className='font-size-16 mb-0 mt-3 text-gray-h'>
          Are you sure you want to confirm this payment?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          loading={loading}
          disabled={loading}
          type='submit'
          onClick={() => {
            onConfirm(invoice?.payment_id)
          }}
        >
          Confirm
        </Button>
        <Button onClick={toggle} color='light' outline disabled={loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ConfirmRevertInvoice({ isOpen, toggle, onConfirm, loading, invoice }) {
  return (
    <Modal toggle={toggle} isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>Confirm revert invoice</ModalHeader>
      <ModalBody className='font-size-18 py-4 text-gray-h'>
        Are you sure you want to revert this invoice?
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color='light' outline disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(invoice)}
          color='danger'
          disabled={loading}
          loading={loading}
        >
          Revert invoice
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ConfirmRevertBreakdown({
  isOpen,
  toggle,
  onConfirm,
  loading,
  invoice,
}) {
  return (
    <Modal toggle={toggle} isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>Confirm revert breakdown</ModalHeader>
      <ModalBody className='font-size-18 py-4 text-gray-h'>
        Are you sure you want to revert this invoice’s breakdown?
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color='light' outline disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(invoice)}
          color='danger'
          disabled={loading}
          loading={loading}
        >
          Revert breakdown
        </Button>
      </ModalFooter>
    </Modal>
  )
}
