import { yupResolver } from '@hookform/resolvers/yup'
import { Eye, PencilSimple } from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import { useFetch } from '../../../../helpers/hooks'
import {
  getAdminPaymentMethods,
  updateAdminPaymentMethods,
} from '../../../../services/api'

export default function ManageWithdrawalMethods() {
  const {
    data: paymentMethods,
    isLoading: gettingPaymentMethods,
    startFetch: getPaymentMethods,
  } = useFetch({
    action: getAdminPaymentMethods,
    autoFetch: true,
    withAdminAccess: true,
  })

  const columns = useMemo(() => {
    return [
      { Header: 'Name', accessor: 'name' },
      {
        Header: 'Fee',
        accessor: 'fee',
        Cell: ({ cellData, rowData }) => {
          if (rowData?.fixed === 1) {
            return `$${cellData}`
          } else {
            return `${cellData}%`
          }
        },
      },
      { Header: 'Eta', accessor: 'eta' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Fx markup', accessor: 'fx_markup' },
      { Header: 'Min cap', accessor: 'min_cap' },
      { Header: 'Max cap', accessor: 'max_cap' },
      {
        Header: 'Actions',
        Cell: (args) => (
          <ActionCell {...args} refreshData={getPaymentMethods} />
        ),
      },
    ]
  }, [getPaymentMethods])

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>Manage Withdrawal Methods</h1>
      </div>

      <Card style={{ minHeight: '30rem' }}>
        {gettingPaymentMethods ? (
          <Loader minHeight='30rem' />
        ) : (
          <DataTable columns={columns} data={paymentMethods} responsive />
        )}
      </Card>
    </div>
  )
}

function ActionCell({ rowData, refreshData }) {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)

  return (
    <div className='d-flex gap-8'>
      <Button
        size='sm'
        outline
        icon={<PencilSimple size={12} />}
        onClick={() => setEditModalOpen(true)}
      >
        Edit
      </Button>
      <Button
        size='sm'
        color='info'
        outline
        icon={<Eye size={12} />}
        onClick={() => setViewModalOpen(true)}
      >
        View
      </Button>

      {!viewModalOpen ? null : (
        <ViewMethodModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen((open) => !open)}
          data={rowData}
          refreshData={refreshData}
        />
      )}

      {!editModalOpen ? null : (
        <EditMethodModal
          isOpen={editModalOpen}
          toggle={() => setEditModalOpen((open) => !open)}
          data={rowData}
          refreshData={refreshData}
        />
      )}
    </div>
  )
}

const editMethodFormId = 'editMethodForm'
function EditMethodModal({ isOpen, toggle, data, refreshData }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data,
    resolver: yupResolver(
      yup.object().shape({
        fee: yup.number().required().typeError('Fee is required'),
      }),
    ),
  })

  const { startFetch: editMethod, isLoading: editingMethod } = useFetch({
    action: updateAdminPaymentMethods,
    withAdminAccess: true,
    onComplete: () => {
      toggle?.()
      refreshData?.()
    },
    checkSuccess: true,
    onError: (err) => {
      toastr.error(err || 'Something went wrong')
    },
  })

  function onSubmit(data) {
    editMethod({
      ...data,
      payment_method_id: data.id,
      fixed: data?.fixed ? 1 : 0,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Method</ModalHeader>

      <ModalBody>
        <form
          id={editMethodFormId}
          onSubmit={handleSubmit(onSubmit)}
          className='d-flex flex-column gap-12'
        >
          <MethodForm control={control} errors={errors} />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={editingMethod}>
          Cancel
        </Button>
        <Button
          formId={editMethodFormId}
          type='submit'
          disabled={editingMethod}
          loading={editingMethod}
        >
          Edit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function MethodForm({ control, errors }) {
  return (
    <>
      <ControlledInput
        control={control}
        name='fee'
        label='Fee'
        error={errors?.fee?.message}
        type='number'
      />
      <ControlledCheckbox
        control={control}
        name='fixed'
        id='fixed'
        label='Fixed'
        leftLabel='Percentage'
        error={errors?.fixed?.message}
      />
      <ControlledInput
        control={control}
        name='eta'
        label='Eta'
        error={errors?.eta?.message}
      />
      <ControlledInput
        control={control}
        name='description'
        label='Description'
        error={errors?.description?.message}
      />
      <ControlledInput
        control={control}
        name='fx_markup'
        label='Fx markup'
        error={errors?.fx_markup?.message}
        type='number'
      />
      <ControlledInput
        control={control}
        name='min_cap'
        label='Min cap'
        error={errors?.min_cap?.message}
        type='number'
      />
      <ControlledInput
        control={control}
        name='max_cap'
        label='Max cap'
        error={errors?.max_cap?.message}
        type='number'
      />
    </>
  )
}

function ViewMethodModal({ isOpen, toggle, data }) {
  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues: data })

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>View method</ModalHeader>

      <ModalBody>
        <form id={editMethodFormId}>
          <fieldset disabled className='d-flex flex-column gap-12'>
            <MethodForm control={control} errors={errors} />
          </fieldset>
        </form>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
