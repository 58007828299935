import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container } from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import toastr from 'toastr'

import ModalHeader from '../../components/ModalHeader'
import { useFetch } from '../../helpers/hooks'
import { switchApi } from '../../services/api'
import {
  loginUserSuccessful,
  updateUserInfo,
} from '../../store/auth/register/actions'
import CompanyInfo from '../CompanySetting/CompanyInfo'
import ProfileInfoNew from '../ContractorSettings/ProfileInfoNew'

const CompleteProfile = ({ history }) => {
  const user = useSelector((state) => state.Account?.user)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const otherCountries = useSelector(
    (state) => state.Layout?.staticData?.other_countries,
  )
  const dispatch = useDispatch()
  const onComplete = () => {
    dispatch(updateUserInfo({ must_complete_entity: false, new: false }))
    history.push('/')
  }
  const switchAccount = useFetch({
    autoFetch: false,
    initResult: null,
    action: switchApi,
    onComplete: (data) => {
      let changedUser = user
      changedUser = {
        ...changedUser,
        type: user?.type === 'client' ? 'contractor' : 'client',
      }
      changedUser = { ...changedUser, ...data }

      dispatch(loginUserSuccessful(changedUser))
      history.push('/activity')
    },
    onError: (error) => toastr.error(error),
  })
  return (
    <div className='blur-container h-100'>
      <Container fluid className='bg-white' style={{ minHeight: '100vh' }}>
        <ModalHeader noExit>
          <button
            onClick={() => switchAccount.startFetch()}
            className='btn btn-link text-muted px-0 ml-auto'
          >
            Switch
          </button>
        </ModalHeader>
        <div className='bg-white'>
          <Row className='justify-content-center'>
            <h3 className='mb-4 mt-4 text-center'>Welcome to RemotePass</h3>
          </Row>
          <Row className='justify-content-center'>
            <Col className='p-0' lg={8}>
              {user?.type === 'client' || user?.must_complete_entity ? (
                <>
                  {!!countries?.length && (
                    <CompanyInfo
                      countries={countries}
                      isCompletion
                      onComplete={onComplete}
                    />
                  )}
                </>
              ) : (
                <ProfileInfoNew
                  isCompletion
                  onCompleted={onComplete}
                  countries={countries}
                  otherCountries={otherCountries}
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default CompleteProfile
