import { yupResolver } from '@hookform/resolvers/yup'
import { Plus } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { addPaysendAccount } from '../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../utils/map-to-option'
import CardMethod from '../Withdrawal/components/card-method'
import {
  creditCardNumberToString,
  formatCreditCardNumber,
  getSchema,
} from '../remotepass-cards/screens/activate-physical-card'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'

export default function CardWithdrawAccounts({ onNext }) {
  const [addAccountModal, setAddAccountModal] = useState(false)
  const [checkedCard, setCheckedCard] = useState(null)

  const {
    getAccountsList,
    accounts,
    loading: gettingAccounts,
  } = useWithdrawAccounts('paysend_card_accounts')

  if (gettingAccounts) {
    return <Loader minHeight='15rem' />
  }

  return (
    <div className='d-flex flex-column' style={{ gap: '1rem' }}>
      {accounts.map((account, index) => {
        return (
          <CardMethod
            key={account?.id ?? index}
            account={account}
            selected={JSON.stringify(account) === JSON.stringify(checkedCard)}
            onClick={() => {
              setCheckedCard(account)
              onNext(account)
            }}
          />
        )
      })}

      <button
        onClick={() => {
          setAddAccountModal(true)
        }}
        className='rp-btn-nostyle align-self-start d-flex gap-6 text-primary rp-font-bold rp-font-gilroyB'
        type='button'
      >
        <Plus size={20} />
        <span className='font-size-14'>Add New Card</span>
      </button>

      <AddNewCardModal
        isOpen={addAccountModal}
        getAccountsList={getAccountsList}
        toggle={() => setAddAccountModal(false)}
      />
    </div>
  )
}

const expirationYears = Array.from(
  { length: 10 },
  (_, index) => new Date().getFullYear() + index,
)
const expirationMonths = Array.from({ length: 12 }, (_, index) =>
  (index + 1).toString().padStart(2, '0'),
)

const ccFormId = 'cc-form'
const ccFormSchema = yup.object().shape({
  card_holder_name: yup.string().required('Card holder name is required'),
  card_number: getSchema(16, 'Card Number'),
  month: yup.string().required('Month is required'),
  year: yup.string().required('Year is required'),
  country: yup.string().required('Country is required'),
  currency: yup.string().required('Currency is required'),
})

function AddNewCardModal({ isOpen, toggle, getAccountsList }) {
  return (
    <Modal isOpen={isOpen} centered toggle={toggle}>
      <ModalHeader toggle={toggle}>Add a new card</ModalHeader>

      <ModalBody>
        <AddNewCardModalForm toggle={toggle} onAdd={getAccountsList} />
      </ModalBody>
    </Modal>
  )
}

export function AddNewCardModalForm({ toggle, onAdd }) {
  const { all_countries: countries } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )
  const [currencies, setCurrencies] = useState([])
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(ccFormSchema),
    defaultValues: { card_number: '' },
  })

  const { startFetch: addAccount, isLoading: addingAccount } = useFetch({
    action: addPaysendAccount,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong while adding card')
      } else {
        toastr.success('Card added successfully')
        toggle?.()
        onAdd?.()
      }
    },
    onError: (error) => {
      toastr.error(
        error?.message || error || 'Something went wrong while adding card',
      )
    },
  })

  const onSubmit = (values) => {
    const body = {
      card_holder_name: values?.card_holder_name,
      card_number: values?.card_number,
      card_expiration_date: `${values?.month}/${values?.year
        ?.toString()
        .slice(-2)}`,
      country_code: values?.country,
      card_currency_code: values?.currency,
    }
    addAccount(body)
  }

  function handleToggle() {
    reset()
    toggle?.()
  }

  return (
    <>
      <div className='pt-4 px-3'>
        <Message />

        <form onSubmit={handleSubmit(onSubmit)} id={ccFormId}>
          <FormGroup>
            <ControlledInput
              label='Card Holder'
              control={control}
              name='card_holder_name'
              error={errors.card_holder}
            />
          </FormGroup>
          <FormGroup>
            <ControlledInput
              control={control}
              label='Card number'
              name='card_number'
              id='card_number'
              error={errors?.card_number}
              transform={{
                input: (value) => {
                  const formattedValue = formatCreditCardNumber(value)

                  return formattedValue
                },
                output: (e) => {
                  const output = e.target.value
                  const stringValue = creditCardNumberToString(output)

                  if (stringValue.length > 16) {
                    const error = 'Card number should be 16 digits'
                    setError('card_number', { message: error })
                  } else {
                    setError('card_number', { message: '' })
                  }

                  return stringValue
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <Container fluid>
              <Row>
                <Col xs={6} className='pl-0 pr-1'>
                  <ControlledSelect
                    label='Month'
                    control={control}
                    name='month'
                    error={errors?.month}
                    placeholder='Month'
                    options={expirationMonths.map((month) => ({
                      label: month,
                      value: month,
                    }))}
                  />
                </Col>
                <Col xs={6} className='pl-1 pr-0'>
                  <ControlledSelect
                    label='Year'
                    control={control}
                    name='year'
                    error={errors?.year}
                    placeholder='Year'
                    options={expirationYears.map((year) => ({
                      label: year,
                      value: year,
                    }))}
                  />
                </Col>
              </Row>
            </Container>
          </FormGroup>
          <FormGroup>
            <ControlledSelect
              label='Country of issue'
              name='country'
              error={errors.country}
              control={control}
              transform={{
                output: (value) => {
                  setCurrencies(value.paysend_currencies)
                  return value
                },
              }}
              options={
                countries
                  ? countries
                      .filter((country) => {
                        return country.paysend_currencies.length > 0
                      })
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c) => mapCountryToOption(c, 'iso2'))
                  : []
              }
            />
          </FormGroup>
          <FormGroup>
            <ControlledSelect
              label='Card currency'
              name='currency'
              error={errors.currency}
              disabled={currencies.length === 0}
              options={
                currencies ? currencies.map((c) => mapCurrencyToOption(c)) : []
              }
              control={control}
            />
          </FormGroup>
        </form>
      </div>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={handleToggle}
          disabled={addingAccount}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={ccFormId}
          disabled={addingAccount}
          loading={addingAccount}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

function Message() {
  return (
    <Alert
      className='border-surface-30 bg-blue-10 p-3 font-size-14'
      style={{ color: 'var(--text-black)' }}
    >
      Only Mastercard and Visa debit or credit cards connected to a bank account
      can be added. Prepaid and virtual cards are not supported.
    </Alert>
  )
}
