import { yupResolver } from '@hookform/resolvers/yup'
import { Plus } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../components/Common/modal-close-button'
import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import { createExternalInvoiceClient } from '../../services/api-external-invoices'
import { getErrorMessage } from '../../utils/get-errors'
import { mapCountryToOption } from '../../utils/map-to-option'
import { EXTERNAL_CLIENT_TYPES } from './clients-list'

const newClientFormId = 'new-client-form'
export function AddNewClient({ btnProps, onCreateSuccess }) {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggle() {
    setIsModalOpen((isOpen) => {
      if (!isOpen) {
        reset()
      }

      return !isOpen
    })
  }

  const { startFetch: createExternalClient, isLoading: creatingClient } =
    useFetch({
      action: createExternalInvoiceClient,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Failed to create client')
        } else {
          toastr.success('Client created successfully')
          onCreateSuccess?.(data)
          toggle()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        type: yup
          .string()
          .oneOf([
            EXTERNAL_CLIENT_TYPES.INDIVIDUAL,
            EXTERNAL_CLIENT_TYPES.ENTITY,
          ])
          .required('Client type is required'),
        first_name: yup.string().required('First name is required'),
        last_name: yup.string().required('Last name is required'),
        email: yup.string().email('Invalid email address'),
        country_id: yup.number().required('Country is required'),
        city: yup.string().required('City is required'),
        address_line_1: yup.string().required('Address 1 is required'),
        address_line_2: yup.string(),
      }),
    ),
  })

  function onSubmit(values) {
    if (!values.email) {
      delete values.email
    }
    createExternalClient(values)
  }

  const { className: btnClassName, ...restBtnProps } = btnProps ?? {}

  return (
    <>
      <Button
        type='button'
        icon={<Plus size={20} />}
        color='link'
        {...restBtnProps}
        className={btnClassName}
        onClick={toggle}
        disabled={creatingClient}
      >
        Add New Client
      </Button>

      <Modal isOpen={isModalOpen} toggle={toggle}>
        <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
          New client
        </ModalHeader>

        <ModalBody>
          <form
            onSubmit={stopPropagation(handleSubmit(onSubmit))}
            id={newClientFormId}
            className='tw-grid tw-grid-cols-2 tw-gap-4'
          >
            <ControlledSelect
              control={control}
              label='Client Type'
              name='type'
              options={[
                {
                  label: 'Individual',
                  value: EXTERNAL_CLIENT_TYPES.INDIVIDUAL,
                },
                { label: 'Business', value: EXTERNAL_CLIENT_TYPES.ENTITY },
              ]}
              placeholder='Select client type'
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label='First Name'
              name='first_name'
              placeholder='Enter first name'
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label='Last Name'
              name='last_name'
              placeholder='Enter last name'
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label='Email (optional)'
              name='email'
              placeholder='Enter email'
              wrapperClassName='tw-col-span-2'
            />

            <ControlledSelect
              control={control}
              label='Country'
              name='country_id'
              options={countries?.map((c) => mapCountryToOption(c))}
              placeholder='Select country'
            />

            <ControlledInput
              control={control}
              label='City'
              name='city'
              placeholder='Enter city'
            />

            <ControlledInput
              control={control}
              label='Address 1'
              name='address_line_1'
              placeholder='Enter address 1'
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label='Address 2 (optional)'
              name='address_line_2'
              placeholder='Enter address 2'
              wrapperClassName='tw-col-span-2'
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            color='light'
            outline
            disabled={creatingClient}
          >
            Cancel
          </Button>
          <Button
            formId={newClientFormId}
            type='submit'
            disabled={creatingClient}
            loading={creatingClient}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function stopPropagation(callback) {
  return function (event) {
    callback?.()

    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault()
      }
      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation()
      }
    }
  }
}
