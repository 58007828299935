import {
  UPDATE_LIST_TO_PAY,
  END_CONFIRMATION,
  START_CONFIRMATION,
  UPDATE_CONFIRMATION_STATUS,
} from './actionTypes'

export const updateToPayList = (payload) => {
  return {
    type: UPDATE_LIST_TO_PAY,
    payload,
  }
}

export const startConfirmation = (payload) => {
  return {
    type: START_CONFIRMATION,
    payload,
  }
}

export const endConfirmation = (payload) => {
  return {
    type: END_CONFIRMATION,
    payload,
  }
}

export const updateConfirmationStatus = (payload) => {
  return {
    type: UPDATE_CONFIRMATION_STATUS,
    payload,
  }
}
