import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default function useHasPermission(permission) {
  const myAdminPermissions = useSelector(
    (state) => state?.userProfile?.userProfile?.adminPermissions ?? [],
  )

  const hasPermission = useMemo(() => {
    return myAdminPermissions.includes(permission)
  }, [myAdminPermissions, permission])
  return hasPermission
}
