import { PlusCircle, TrashSimple } from '@phosphor-icons/react'
import React from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'

import { useSelector } from 'react-redux'
import { cn } from 'ui'
import ControlledCurrencyInput, {
  CustomCurrencyInput,
} from '../../components/ControlledCurrencyInput'
import ControlledInput from '../../components/ControlledInput'
import Button from '../../components/ui/button'
import { getInputErrorMessage } from '../../components/Forms/get-input-error-message'
import InputFeedback from '../../components/ui/input-feedback'

export const UNIT = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  HOURLY: 'HOURLY',
}
export const invoiceItemsFieldName = 'items'
export function InvoiceItems({ control }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})
  const currencyId = useWatch({ control, name: 'currency_id' })
  const currencySelected = currencies?.find((c) => c.id === currencyId)
  const currencySymbol = currencySelected?.symbol ?? ''

  const { fields, append, remove } = useFieldArray({
    control,
    name: invoiceItemsFieldName,
  })

  return (
    <div>
      <table className='tw-w-full tw-overflow-auto tw-pl-0.5 max-md:tw-block'>
        <thead>
          <tr>
            <th className='tw-border-b tw-border-b-secondary-30 tw-bg-surface-10 tw-py-4 tw-pl-4'>
              Description
            </th>
            <th className='tw-border-b tw-border-b-secondary-30 tw-bg-surface-10 tw-py-4'>
              Unit
            </th>
            <th className='tw-border-b tw-border-b-secondary-30 tw-bg-surface-10 tw-py-4'>
              Quantity
            </th>
            <th className='tw-border-b tw-border-b-secondary-30 tw-bg-surface-10 tw-py-4'>
              Rate
            </th>
            <th className='tw-border-b tw-border-b-secondary-30 tw-bg-surface-10 tw-py-4'>
              Amount
            </th>
            <th className='tw-border-b tw-border-b-secondary-30 tw-bg-surface-10 tw-py-4'></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => {
            return (
              <tr
                key={field.id}
                className={cn(
                  '[&:first-child>*]:!tw-pt-2 [&:not(:first-child)>*]:!tw-pt-0 [&:not(:last-child)>*]:!tw-pb-2 [&>*:last-child]:!tw-pr-0 [&>*:not(:last-child)]:!tw-pr-2 [&>*]:!tw-pl-0',
                  'md:[&:first-child>*]:!tw-pt-4 md:[&:not(:last-child)>*]:!tw-pb-4 md:[&>*:not(:last-child)]:!tw-pr-4',
                )}
              >
                <td className='align-top'>
                  <ControlledInput
                    name={`${invoiceItemsFieldName}.${index}.description`}
                    control={control}
                    placeholder='Description'
                  />
                </td>
                <td className='align-top'>
                  <ControlledNativeSelect
                    name={`${invoiceItemsFieldName}.${index}.unit`}
                    control={control}
                    options={[
                      { label: 'Select Unit', value: '', disabled: true },
                      ...Object.entries(UNIT).map(([key, value]) => ({
                        label: key,
                        value,
                      })),
                    ]}
                  />
                </td>
                <td className='align-top'>
                  <ControlledInput
                    name={`${invoiceItemsFieldName}.${index}.quantity`}
                    control={control}
                    type='number'
                    wrapperClassName='tw-max-w-20'
                    min={0}
                  />
                </td>
                <td className='align-top'>
                  <ControlledCurrencyInput
                    name={`${invoiceItemsFieldName}.${index}.rate`}
                    control={control}
                    prefix={`${currencySymbol} `}
                  />
                </td>
                <td className='align-top'>
                  <AmountField
                    itemIndex={index}
                    control={control}
                    currencySymbol={currencySymbol}
                  />
                </td>
                <td className='align-top'>
                  <Button
                    icon={<TrashSimple size={20} />}
                    className='!tw-border-surface-30 !tw-p-2.5'
                    color='danger'
                    outline
                    type='button'
                    onClick={() => remove(index)}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <button
        type='button'
        onClick={() => {
          append({ description: '', unit: '', quantity: 0, rate: 0 })
        }}
        className='tw-mt-4 tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary-100 tw-bg-primary-20 tw-p-4 tw-text-primary-100 tw-transition-colors hover:tw-bg-primary-30 focus-visible:tw-ring-2 focus-visible:tw-ring-primary-80 focus-visible:tw-ring-offset-2'
      >
        <PlusCircle size={20} />
        Add Item
      </button>
    </div>
  )
}

function AmountField({ itemIndex, control, currencySymbol }) {
  const watchedField = useWatch({
    control,
    name: `${invoiceItemsFieldName}.${itemIndex}`,
  })
  const amountValue = Number(watchedField.quantity) * Number(watchedField.rate)

  return (
    <CustomCurrencyInput
      isDisabled
      prefix={`${currencySymbol} `}
      value={amountValue}
    />
  )
}

function ControlledNativeSelect({
  control,
  name,
  options,
  error: externalError,
  showError = true,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const theError =
          getInputErrorMessage(externalError) || getInputErrorMessage(error)
        return (
          <>
            <NativeSelect
              error={theError}
              showError={showError}
              options={options}
              {...props}
              {...field}
            />
          </>
        )
      }}
    />
  )
}

function NativeSelect({ options, showError, error, ...selectProps }) {
  const showingError = error && showError
  return (
    <>
      <select
        className='form-select form-control'
        style={{ height: 42 }}
        {...selectProps}
      >
        {options?.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              selected={option.selected}
            >
              {option.label}
            </option>
          )
        })}
      </select>
      {showingError ? (
        <InputFeedback className='tw-mt-1'>{error}</InputFeedback>
      ) : null}
    </>
  )
}
