import React from 'react'
import BadgeV2 from './badge-v2'
import { cn } from 'ui'

export function NewBadge({ className }) {
  return (
    <BadgeV2
      noPadding
      className={cn(
        'tw-rounded-full tw-px-1 tw-py-0.5 tw-font-bold tw-uppercase',
        className,
      )}
      size='sm'
      color='primary'
    >
      New
    </BadgeV2>
  )
}
