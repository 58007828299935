import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import { IntegrationNav } from '../../../../components/integration-nav'
import { StyledH5 } from '../../../../components/Typo'
import TabContent from '../../../../components/ui/tabs'
import { useFetch } from '../../../../helpers/hooks'
import {
  getFreshIntegrationData,
  setupFreshbooks,
} from '../../../../services/api'
import {
  freshSubmitAccountMapping,
  freshUpdatePreData,
  freshUpdateRequest,
} from '../../../../store/fresh/actions'
import AccountMappingTab from './accountMappingTab'
import XeroLogs from './Logs'
import VendorsSettingTab from './vendorsSettingsTab'

const UpdateFreshbooksIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [submitted, setSubmitted] = useState(false)

  const dispatch = useDispatch()

  const onComplete = (data) => {
    const accountMapping = {}
    data.categories.forEach((c) => {
      accountMapping[c.category_id] = c.fresh_id
    })
    dispatch(freshSubmitAccountMapping(accountMapping))
  }

  const xero = useFetch({
    action: getFreshIntegrationData,
    successAction: freshUpdatePreData,
    autoFetch: true,
    onError: toastr.error,
    onComplete,
  })

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (xero.data?.contractors_queued > 0) {
        // eslint-disable-next-line no-return-assign,no-unused-vars
        xero.startFetch(null, false, new axios.CancelToken((c) => (cancel = c)))
      }
    },
    [xero.data],
  )

  useEffect(() => {
    let cancel
    const interval = setInterval(() => refresh(cancel), 800)
    return () => {
      clearInterval(interval)
    }
  }, [xero.data])

  const request = useSelector((state) => state.fresh.request)
  const update = useFetch({
    action: setupFreshbooks,
    onError: toastr.error,
    onComplete: () => {
      setTimeout(() => {
        xero.startFetch(null, false)
      }, 800)
      dispatch(freshUpdateRequest(null))
    },
  })

  useEffect(() => {
    if (request && submitted) {
      update.startFetch(request)
      setSubmitted(false)
    }
  }, [request, submitted])

  useEffect(() => {
    return () => {
      dispatch(freshUpdateRequest(null))
    }
  }, [])

  const onUpdate = () => {
    setSubmitted(true)
  }

  const tabs = [
    {
      label: 'Accounts',
      component: (
        <AccountMappingTab
          data={xero.data}
          loading={update.isLoading}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      label: 'Team',
      component: (
        <VendorsSettingTab
          data={xero.data}
          loading={update.isLoading}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      label: 'Invoices',
      component: (
        <XeroLogs
          onSubmitted={onUpdate}
          onUpdate={() => xero.startFetch(null, true)}
        />
      ),
    },
  ]
  return (
    <div className='page-content'>
      {xero.isLoading ? (
        <Container style={{ minHeight: '100vh' }}>
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <>
          <div className='mb-0 mb-md-2 d-flex'>
            <StyledH5 min='22px' mid='28px' max='32px'>
              Freshbooks
            </StyledH5>
          </div>

          <IntegrationNav tabs className='nav-tabs-custom'>
            {tabs.map((nav, index) => (
              <NavItem key={`integrationNav:${index}`}>
                <NavLink
                  className={cn({ active: activeTab === index })}
                  onClick={() => {
                    setActiveTab(index)
                  }}
                >
                  {nav.label}
                </NavLink>
              </NavItem>
            ))}
          </IntegrationNav>
          <TabContent activeTab={activeTab} className='mt-3'>
            {tabs.map((t, i) => (
              <TabPane key={`tab:${i}`} tabId={i}>
                {t.component}
              </TabPane>
            ))}
          </TabContent>
        </>
      )}
    </div>
  )
}

export default UpdateFreshbooksIntegration
