import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as yup from 'yup'
import toastr from 'toastr'

import { useFetch } from '../../helpers/hooks'
import { updateBankAccount } from '../../services/api'
import { ModalCloseButton } from '../Common/modal-close-button'
import ControlledInput from '../ControlledInput'
import Button from '../ui/button'
import { validateEID } from './validate-aed-id'

const schema = yup.object().shape({
  id_number: yup
    .string()
    .test('valid-uae-id', 'Invalid UAE ID Format', (value) => {
      const [isValid] = validateEID(value)
      return isValid
    })
    .required('This field is required'),
})

const formId = 'completeBankAccountForm'

export function CompleteBankAccountModal({ isOpen, toggle, updateTodoList }) {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  })

  const { startFetch: updateAccount, isLoading: updatingAccount } = useFetch({
    action: updateBankAccount,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data.message || 'An error occurred while updating account')
      } else {
        toastr.success('Account updated successfully')
        toggle()
        updateTodoList?.()
      }
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred while updating account')
    },
  })

  function onSubmit(values) {
    const body = {
      id_number: values.id_number.replace(/[\s-]/g, ''),
    }
    updateAccount(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Complete Bank Account
      </ModalHeader>
      <ModalBody>
        <p>
          You need to complete your bank account to be able to withdraw money.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <ControlledInput
            control={control}
            label='UAE ID number'
            name='id_number'
            id='id_number'
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={updatingAccount}
        >
          Cancel
        </Button>
        <Button
          formId={formId}
          type='submit'
          disabled={updatingAccount}
          loading={updatingAccount}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}
