import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import toastr from 'toastr'

import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  getEnabledMethods,
  getPaymentMethods,
  getPlaidToken,
} from '../../services/api'
import CreditCardsForSteps from '../payInvoices/creditCardsForSteps'

import ccActive from './../../assets/images/creditCardSelected.svg'
import ccInactive from './../../assets/images/credit_card.svg'

const insuranceOptions = [
  {
    label: 'Credit / Debit Card',
    description: '4% Fee',
    icon: ccInactive,
    activeIcon: ccActive,
    type: 'card',
    id: 1,
    enabled: true,
  },
]
export default function PaymentInfo() {
  const user = useSelector((state) => state?.Account?.user)

  useEffect(() => {
    window.analytics.track('Viewed payment Info', { email_id: user?.email })
  }, [])

  const getToken = useFetch({
    action: getPlaidToken,
    autoFetch: true,
  })
  const getEnabled = useFetch({
    action: getEnabledMethods,
    autoFetch: true,
  })

  const paymentMethods = useFetch({
    action: getPaymentMethods,
    autoFetch: true,
    initResult: [],
    onError: (err) => {
      toastr.error(err)
    },
  })

  const updateList = () => {
    paymentMethods.startFetch(null, false)
  }

  return (
    <Card className='rounded-top-0 mb-0 pt-3'>
      <CardBody>
        <CreditCardsForSteps
          onAccountAdded={updateList}
          onUpdate={updateList}
          onMethodSelected={() => {}}
          noCoinbase
          methods={{
            methods:
              user?.type === userTypes.COMPANY
                ? getEnabled.data
                : insuranceOptions,
          }}
          loading={
            getToken.isLoading ||
            paymentMethods.isLoading ||
            getEnabled.isLoading
          }
          cards={paymentMethods.data}
          cardsLoading={paymentMethods.isLoading}
          plaidToken={getToken.data?.link_token}
          isEditing
          isInsurance={user?.type === userTypes.CONTRACTOR}
        />
      </CardBody>
    </Card>
  )
}
