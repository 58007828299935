export const FIXED = 'Fixed'
export const PAY_AS_YOU_GO = 'Pay as you Go'
export const MILESTONES = 'Milestones'
export const FULL_TIME = 'Full Time'
export const DIRECT_EMPLOYEE = 'Direct Employee'

export const CONTRACT_TYPES = {
  PAY_AS_YOU_GO,
  MILESTONES,
  FULL_TIME,
  DIRECT_EMPLOYEE,
  FIXED,
}
