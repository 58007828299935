import React from 'react'
import { Controller } from 'react-hook-form'

import { datePickerDateFormat } from '../../utils/formatters/date-picker-date-format'
import CustomDatePicker from '../Forms/CustomDatePickerNew/CustomDatePicker'
import { getInputErrorMessage } from '../Forms/get-input-error-message'

function ControlledDatePicker({
  name,
  control,
  error: externalError,
  label,
  clearable,
  transform,
  ...prop
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onBlur, onChange },
        fieldState: { error },
      }) => {
        const theError = getInputErrorMessage(externalError ?? error)

        return (
          <CustomDatePicker
            name={name}
            label={label}
            value={
              typeof transform?.input === 'function'
                ? transform.input(value)
                : value
            }
            handleOnChange={(...args) =>
              typeof transform?.output === 'function'
                ? onChange(transform.output(...args))
                : onChange(datePickerDateFormat(args[0]))
            }
            hasError={!!theError}
            error={theError}
            handleClear={() => {
              onChange(null)
            }}
            clearable={clearable}
            onMenuClose={onBlur}
            {...prop}
          />
        )
      }}
    />
  )
}

export default ControlledDatePicker
