import React from 'react'
import { useFormContext } from 'react-hook-form'
import { TRIGGER_OCCURRENCE } from './constants'

function getTriggerTitle(label, occurrenceDays, occurrence, typeOfOccurrence) {
  switch (occurrence) {
    case TRIGGER_OCCURRENCE.PENDING_DAYS:
      return occurrenceDays ? `${label} for ${occurrenceDays} days` : label
    case TRIGGER_OCCURRENCE.BEFORE_DAYS:
      return occurrenceDays ? `${occurrenceDays} days before ${label}` : label
    case TRIGGER_OCCURRENCE.AFTER_DAYS:
      return occurrenceDays ? `${occurrenceDays} days after ${label}` : label
    case TRIGGER_OCCURRENCE.RANGE:
      if (occurrenceDays && typeOfOccurrence) {
        return `${occurrenceDays} days ${typeOfOccurrence.toLowerCase()} ${label}`
      }
      return label
    default:
      return label
  }
}

export function TriggerTitle({ index }) {
  const { watch } = useFormContext()
  const { event, occurrenceDays, typeOfOccurrence } = watch(`steps.${index}`)
  const { occurrence = {}, label } = event ?? {}

  if (!label) {
    return <span className='tw-text-text-80'>Choose trigger details</span>
  }

  const title = getTriggerTitle(
    label,
    occurrenceDays,
    occurrence?.type,
    typeOfOccurrence?.label,
  )

  return <span className='tw-font-medium'>{title}</span>
}
