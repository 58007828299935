import { all, fork, put, takeEvery } from 'redux-saga/effects'

// Account Redux states
import { OTP_VERIFICATION, REGISTER_USER } from './actionTypes'
import {
  OTPFailed,
  OTPSuccessful,
  registerUserFailed,
  registerUserSuccessful,
} from './actions'

// Include Both Helper File with needed methods
// import {getFirebaseBackend} from '../../../helpers/firebase_helper';
import {
  completeRegistration,
  verifyOTP as OTPVerify,
} from '../../../services/api'

function* verifyOTP({ payload }) {
  const { otp, email, type } = payload
  try {
    const response = yield OTPVerify(otp, email, type)
    if (response.data.success) {
      yield put(OTPSuccessful(response.data?.data))
    } else {
      yield put(OTPFailed(response.message))
    }
  } catch (error) {
    yield put(OTPFailed(error))
  }
}

function* registerUser({ payload }) {
  const { data, type, token, onSuccess } = payload
  try {
    const response = yield completeRegistration(type, { ...data }, token)
    if (response.data.success) {
      yield put(registerUserSuccessful({ ...response.data?.data }))
      window.analytics.track('Signed up', {
        email_id: response.data?.data?.email,
      })
      onSuccess()
    } else {
      yield put(registerUserFailed(response.data?.data?.error))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }

  window.scrollTo(0, 0)
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(OTP_VERIFICATION, verifyOTP)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
