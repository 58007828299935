import {
  SET_CONTRACT_TO_DUPLICATE,
  UPDATE_CONTRACT,
  UPDATE_FULL_TIME_CONTRACT,
} from './actionTypes'

export const updateContract = (payload) => ({
  type: UPDATE_CONTRACT,
  payload,
})
export const updateFulltimeContract = (payload) => ({
  type: UPDATE_FULL_TIME_CONTRACT,
  payload,
})
export const setContractToDuplicate = (payload) => ({
  type: SET_CONTRACT_TO_DUPLICATE,
  payload,
})
