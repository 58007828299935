import {
  ArrowRight,
  ArrowSquareOut,
  CreditCard,
  Key,
  Lightning,
  Receipt,
  SealCheck,
  ShieldPlus,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap'

import { cn } from 'ui'
import BadgeX from '../../components/Table/BadgeX'
import Button from '../../components/ui/button'
import { getCookie, setCookie } from '../../utils/cookies'
import {
  BG_CHECK_RED_DOT_COOKIE,
  INSURANCE_ALLOWANCE_RED_DOT_COOKIE,
  INSURANCE_RED_DOT_COOKIE,
  PHYSICAL_CARD_RED_DOT_COOKIE,
} from '../../utils/cookies/cookie-names'
import './_generic-banner.scss'

const BANNER_TYPES = {
  INSURANCE: 'INSURANCE',
  INSURANCE_ALLOWANCE: 'INSURANCE_ALLOWANCE',
  PHYSICAL_CARD: 'PHYSICAL_CARD',
  CARD_CASHBACK: 'CARD_CASHBACK',
  INSTANCE_CARD_PAYOUT: 'INSTANCE_CARD_PAYOUT',
  GENERATE_EXTERNAL_INVOICES: 'GENERATE_EXTERNAL_INVOICES',
  BACKGROUND_CHECK: 'BACKGROUND_CHECK',
  GENERATE_API_KEYS: 'GENERATE_API_KEYS',
}

function redIndicatorPressed(type) {
  switch (type) {
    case BANNER_TYPES.INSURANCE: {
      return Boolean(getCookie(INSURANCE_RED_DOT_COOKIE))
    }
    case BANNER_TYPES.PHYSICAL_CARD: {
      return Boolean(getCookie(PHYSICAL_CARD_RED_DOT_COOKIE))
    }
    case BANNER_TYPES.INSURANCE_ALLOWANCE: {
      return Boolean(getCookie(INSURANCE_ALLOWANCE_RED_DOT_COOKIE))
    }
    case BANNER_TYPES.BACKGROUND_CHECK: {
      return Boolean(getCookie(BG_CHECK_RED_DOT_COOKIE))
    }
  }
}
function getLink(type) {
  switch (type) {
    case BANNER_TYPES.INSURANCE: {
      return '/insurance'
    }
    case BANNER_TYPES.PHYSICAL_CARD: {
      return '/cards'
    }
    case BANNER_TYPES.INSURANCE_ALLOWANCE: {
      return '/insurance'
    }
    case BANNER_TYPES.CARD_CASHBACK: {
      return '/cards'
    }
    case BANNER_TYPES.INSTANCE_CARD_PAYOUT: {
      return '/settings#accounts'
    }
    case BANNER_TYPES.GENERATE_EXTERNAL_INVOICES: {
      return '/invoices?tab=external-invoices'
    }
    case BANNER_TYPES.GENERATE_API_KEYS: {
      return '/settings/apikeys'
    }
    case BANNER_TYPES.BACKGROUND_CHECK: {
      return '/documents'
    }
    default: {
      return '#'
    }
  }
}

function getLearnMoreLink(type) {
  switch (type) {
    case BANNER_TYPES.INSTANCE_CARD_PAYOUT: {
      return 'https://help.remotepass.com/en/articles/8017140-what-is-instant-card-payout'
    }
    case BANNER_TYPES.BACKGROUND_CHECK: {
      return 'https://help.remotepass.com/en/articles/9330637-how-to-run-a-background-check-for-contractors-and-employees'
    }
    default: {
      return null
    }
  }
}

export function TitleBadge({ className }) {
  return (
    <BadgeX
      className={cn('!tw-px-2 !tw-py-2 !tw-font-bold tw-uppercase', className)}
      pill
      size='sm'
      noPadding
      textClassName='font-size-10 d-flex justify-content-center align-items-center'
      color='primary'
    >
      New
    </BadgeX>
  )
}

export default function GenericBanner({ bannerData }) {
  if (!bannerData || !Array.isArray(bannerData) || bannerData.length <= 0) {
    return null
  }

  if (bannerData.length > 1) {
    return <BannerCarousel items={bannerData} />
  }

  return (
    <BannerItem
      item={bannerData[0]}
      onClickItemButton={(item) => setDataCookies(item?.type)}
    />
  )
}

function RedIndicator() {
  return (
    <div className='position-absolute d-flex justify-content-center align-items-center'>
      <div
        className='rounded-circle position-absolute bg-white'
        style={{
          '--size': '26px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-20'
        style={{
          '--size': '20px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-40'
        style={{
          '--size': '16px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-60'
        style={{
          '--size': '12px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-100'
        style={{ '--size': '8px', height: 'var(--size)', width: 'var(--size)' }}
      />
    </div>
  )
}

const bgColor = {
  [BANNER_TYPES.INSURANCE]: '#FBF4F4',
  [BANNER_TYPES.INSTANCE_CARD_PAYOUT]: 'var(--yellow-100)',
}

function setDataCookies(type) {
  let cookieName = null

  if (type === BANNER_TYPES.INSURANCE) {
    cookieName = INSURANCE_RED_DOT_COOKIE
  } else if (type === BANNER_TYPES.PHYSICAL_CARD) {
    cookieName = PHYSICAL_CARD_RED_DOT_COOKIE
  } else if (type === BANNER_TYPES.INSURANCE_ALLOWANCE) {
    cookieName = INSURANCE_ALLOWANCE_RED_DOT_COOKIE
  } else if (type === BANNER_TYPES.BACKGROUND_CHECK) {
    cookieName = BG_CHECK_RED_DOT_COOKIE
  }

  if (!cookieName) return

  setCookie(cookieName, true)
}

function BannerCarousel({ items }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  function next() {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  function previous() {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  function goToIndex(newIndex) {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items?.map((item) => {
    return (
      <CarouselItem
        className='tw-h-auto tw-max-w-full tw-bg-transparent'
        tag='div'
        key={item.type}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        interval={6000}
      >
        <BannerItem
          item={item}
          itemTotalLength={items.length}
          onClickItemButton={(item) => setDataCookies(item?.type)}
        />
      </CarouselItem>
    )
  })

  const navClasses =
    '!tw-top-1/2 !tw-hidden !tw-size-10 tw-translate-y-[calc(-50%-0.875rem)] tw-rounded-full !tw-bg-surface-10 md:!tw-flex [&>span]:tw-size-4 [&>span]:!tw-bg-[auto_100%] [&>span]:!tw-bg-no-repeat [&>span]:!tw-bg-center'

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      interval={0}
      previous={previous}
      className='tw-pb-2'
    >
      {items?.length > 1 ? (
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          className='tw-mb-0 [&>li]:!tw-size-2 [&>li]:tw-rounded-[50%] [&>li]:!tw-bg-secondary-70'
        />
      ) : null}

      {slides}

      {items?.length > 1 ? (
        <>
          <CarouselControl
            direction='prev'
            className={cn(navClasses, '!tw-left-2 lg:!tw-left-6')}
            directionText='Previous'
            onClickHandler={previous}
          />

          <CarouselControl
            direction='next'
            className={cn(navClasses, '!tw-right-2 lg:!tw-right-6')}
            directionText='Next'
            onClickHandler={next}
          />
        </>
      ) : null}
    </Carousel>
  )
}

function BannerItem({ item, itemTotalLength, onClickItemButton }) {
  const itemType = item?.type
  const _bgColor = bgColor[itemType] ?? 'var(--primary-20)'

  const slides = itemTotalLength && itemTotalLength > 1

  return (
    <Alert
      color='light'
      className={cn(
        'tw-mb-6 tw-flex tw-flex-col tw-justify-between !tw-border-b-2 !tw-border-b-surface-30 !tw-bg-white !tw-py-4 md:tw-min-h-[126px] md:tw-flex-row md:tw-items-center lg:tw-px-6 lg:tw-py-6',
        slides ? 'md:!tw-px-14 lg:!tw-px-20' : '',
      )}
      closeClassName='btn-close'
    >
      <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row gap-16'>
        <div className='tw-flex tw-items-center'>
          <div
            className='tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full'
            style={{ backgroundColor: _bgColor }}
          >
            <BannerIcon type={itemType} />
          </div>

          <TitleBadge className='-tw-ml-1 md:!tw-hidden' />
        </div>

        <div>
          <div className='tw-mb-1 tw-flex tw-items-center tw-gap-3'>
            <h3 className='tw-mb-0 tw-text-2xl tw-leading-tight'>
              {item?.title}
            </h3>

            {itemType !== BANNER_TYPES.INSURANCE ? (
              <TitleBadge className='!tw-hidden md:!tw-inline-block' />
            ) : null}
          </div>
          <p
            className='tw-mb-0 tw-text-sm'
            dangerouslySetInnerHTML={{ __html: item?.description }}
          />
        </div>
      </div>

      {itemType === BANNER_TYPES.INSURANCE_ALLOWANCE ? (
        <div className='tw-mt-3 tw-flex tw-gap-1.5 md:tw-mt-0'>
          <div>
            <p className='tw-text-xs tw-font-semibold tw-text-text-90 lg:tw-text-sm'>
              One step left
            </p>
            <div className='tw-h-1 tw-rounded-lg tw-bg-green-100' />
          </div>
          <div>
            <p className='tw-text-xs tw-font-semibold tw-text-text-60 lg:tw-text-sm'>
              About 30 seconds
            </p>
            <div className='tw-h-1 tw-rounded-lg tw-bg-surface-60' />
          </div>
        </div>
      ) : null}

      <div className='tw-mt-4 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-3 tw-text-base md:tw-mt-0 md:tw-justify-end'>
        {getLearnMoreLink(itemType) ? (
          <Button
            iconRight={<ArrowSquareOut size={16} weight='bold' />}
            color='light'
            outline
            tag='a'
            href={getLearnMoreLink(itemType)}
            target='_blank'
            rel='noreferrer'
            className='tw-order-1 md:tw-order-first'
          >
            Learn more
          </Button>
        ) : null}

        <div className='tw-relative'>
          {!redIndicatorPressed(itemType) ? <RedIndicator /> : null}

          <Button
            iconRight={<ArrowRight size={20} className='d-none d-md-block' />}
            block
            tag={Link}
            to={getLink(itemType)}
            onClick={() => onClickItemButton(item)}
            style={{
              backgroundColor:
                itemType === BANNER_TYPES.INSURANCE ? '#D6746C' : '#114EF7',
              borderColor:
                itemType === BANNER_TYPES.INSURANCE ? '#D6746C' : '#114EF7',
            }}
          >
            {item?.button_text}
          </Button>
        </div>
      </div>
    </Alert>
  )
}

function BannerIcon({ type }) {
  switch (type) {
    case BANNER_TYPES.INSURANCE:
    case BANNER_TYPES.INSURANCE_ALLOWANCE: {
      return (
        <ShieldPlus
          size={24}
          color={
            type === BANNER_TYPES.INSURANCE ? '#D6746C' : 'var(--primary-100)'
          }
        />
      )
    }

    case BANNER_TYPES.CARD_CASHBACK:
    case BANNER_TYPES.PHYSICAL_CARD: {
      return <CreditCard color='var(--primary-100)' size={28} />
    }

    case BANNER_TYPES.INSTANCE_CARD_PAYOUT: {
      return <Lightning color='black' size={28} weight='fill' />
    }
    case BANNER_TYPES.GENERATE_EXTERNAL_INVOICES: {
      return <Receipt color='var(--primary-100)' size={28} weight='fill' />
    }

    case BANNER_TYPES.BACKGROUND_CHECK: {
      return <SealCheck color='var(--primary-100)' size={32} weight='fill' />
    }

    case BANNER_TYPES.GENERATE_API_KEYS: {
      return <Key color='var(--primary-100)' size={32} weight='regular' />
    }

    default: {
      return null
    }
  }
}
