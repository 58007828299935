import { AvField } from 'availity-reactstrap-validation'
import cx from 'classnames'
import React from 'react'

import customStyles from '../../../../components/Common/react-select-custom-styles'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { getOptionFromList } from '../../../../utils/map-to-option'

function CustomAvFieldSelect({ onBlur, onChange, disabled, ...props }) {
  const value = getOptionFromList(props.options, props.value)

  return (
    <CustomSelect
      {...props}
      styles={customStyles}
      value={value}
      inputId={props.name}
      onBlurCapture={(option) => onBlur(option?.value)}
      onChange={(option) => onChange(option?.value)}
      isDisabled={disabled}
    />
  )
}

export default function AvFieldSelect({ options, className, ...props }) {
  return (
    <AvField
      type='select'
      className={cx(className)}
      options={options}
      tag={CustomAvFieldSelect}
      {...props}
    />
  )
}
