export const currencyLocal = 'en-US';
export function currencyFormatter(currency = 'USD', locale = currencyLocal, options = {}) {
    return {
        format: (value) => {
            const numericValue = Number(value) * 100;
            const truncatedValue = Math.floor(numericValue) / 100;
            return new Intl.NumberFormat(locale, Object.assign({ style: 'currency', currency: currency ? currency : 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }, options)).format(truncatedValue);
        },
    };
}
