import { DownloadSimple, ListChecks } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../components/Common/modal-close-button'
import Button from '../../components/ui/button'
import { CheckItem } from '../../components/ui/check-item'
import { useFetch } from '../../helpers/hooks'
import { downloadTrxReceipt } from '../../services/api'
import openFile from '../../utils/file/open'

const columnOptions = [
  { label: 'Title', value: 'title' },
  { label: 'Name', value: 'name' },
  { label: 'Contract ID', value: 'contract_id' },
  { label: 'Invoice Item', value: 'invoice_item' },
]

export function DownloadStatementModal({ isOpen, toggle, item }) {
  const [options, setOptions] = useState({
    title: 1,
    name: 1,
    contract_id: 1,
    invoice_item: 1,
    summary: 0,
  })

  function toggleOption(key) {
    if (key === 'summary' && options.summary === 0)
      setOptions({
        title: 0,
        name: 0,
        contract_id: 0,
        invoice_item: 0,
        summary: 1,
      })
    else setOptions((prev) => ({ ...prev, [key]: prev[key] === 1 ? 0 : 1 }))
  }

  const { startFetch: downloadReceipt, isLoading: downloadingReceipt } =
    useFetch({
      action: downloadTrxReceipt,
      onComplete: (data, body) => {
        openFile(data, `trx-statement-${body?.ref}.pdf`)
        toggle?.()
      },
    })

  function handleInvoiceDownload() {
    window.analytics.track('Clicked download receipt', {
      transaction_id: item.ref,
    })

    const body = { ref: item.ref, invoiceToken: item.token, ...options }

    downloadReceipt(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader tag='div' close={<ModalCloseButton toggle={toggle} />}>
        <ListChecks size={32} className='tw-fill-secondary-100' />
        <h3 className='mt-2 tw-text-secondary-120'>Download Statement</h3>
        <p className='mb-0 tw-text-text-60'>
          Choose the columns you wish to include in the statement
        </p>
      </ModalHeader>
      <ModalBody className='d-flex flex-column flex-md-row gap-16'>
        <div className='d-flex flex-column gap-16 p-4 border border-surface-30 rounded w-100'>
          <div className='d-flex gap-16 justify-content-between'>
            {columnOptions.map((option) => {
              return (
                <CheckItem
                  key={option.value}
                  label={option.label}
                  checked={options[option.value] === 1}
                  onChange={() => toggleOption(option.value)}
                  disabled={options.summary === 1}
                />
              )
            })}
          </div>

          <hr className='m-0' />

          <CheckItem
            label='Summary'
            checked={options.summary === 1}
            onChange={() => toggleOption('summary')}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon={<DownloadSimple size={20} />}
          block
          size='lg'
          style={{ height: 48 }}
          onClick={handleInvoiceDownload}
          loading={downloadingReceipt}
          disabled={downloadingReceipt}
        >
          Download
        </Button>
      </ModalFooter>
    </Modal>
  )
}
