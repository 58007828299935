import logger from '../services/logger'

export default function triggerCsChat(msg, type = 'showNewMessage') {
  if (!window.Intercom) {
    if (process.env.NODE_ENV === 'development') {
      logger.warn('Intercom is not loaded')
    }
    return
  }

  window.Intercom(type, msg)
}
