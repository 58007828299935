import React from 'react'
import { getAdminUserDetails } from '../../../../../services/api'
import { useFetch } from '../../../../../helpers/hooks'
import Shimmer from '../../../../../components/ui/shimmer'
export function ShowEmployeeBasicInfo({ employee }) {
  const getUserDetails = useFetch(
    {
      action: getAdminUserDetails,
      withAdminAccess: true,
      autoFetch: !!employee?.id,
      body: { id: employee?.id },
    },
    [employee?.id],
  )

  const hideFields = [
    'profile.type',
    'profile.auto-withdraw',
    'default account.dateofbirth',
  ]

  return (
    <section className='tw-px-6'>
      {getUserDetails?.isLoading ? (
        <div className='tw-flex tw-gap-1'>
          <Shimmer height='15rem' width='50%' />
          <Shimmer height='15rem' width='50%' />
        </div>
      ) : (
        getUserDetails?.data?.map((item, i) => {
          return (
            <div key={item?.title + i}>
              <h4>{item?.title}</h4>
              <div className='tw-grid tw-grid-cols-2 tw-gap-x-2'>
                {item?.value
                  ?.filter(
                    (value) =>
                      !hideFields.includes(
                        `${item?.title}.${value?.title}`.toLowerCase(),
                      ),
                  )
                  ?.map((v, ii) => {
                    return (
                      <div key={v?.title + ii}>
                        <span className='text-text-80 font-size-13'>
                          {v?.title}:
                        </span>
                        {v?.is_image ? (
                          <img
                            src={v?.value}
                            style={{ width: '100%' }}
                            alt={v?.title}
                          />
                        ) : (
                          <p
                            className='font-size-15 tw-truncate'
                            title={v?.value}
                          >
                            {v?.value || 'N/A'}
                          </p>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })
      )}

      <hr />
    </section>
  )
}
