import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import DynamicForm, {
  buildYupSchema,
  formatValues,
  getDefaultValues,
  isStepEmpty,
} from '../../../components/Forms/dynamic-form'
import { Container, ModalFooter, Row } from 'reactstrap'
import Button from '../../../components/ui/button'
import { cn } from 'ui'
import ControlledCheckbox from '../../../components/controlled-checkbox'
function SectionHeader({ title, className }) {
  return <h4 className={cn('tw-mb-6 tw-px-6', className)}>{title}</h4>
}

function EORDataCollectionForm({
  form,
  onComplete,
  isSubmissionLoading,
  readOnly,
  filesDownloadable,
  canDeleteFiles,
}) {
  const { form_steps: steps, form_fields: fields } = form
  const mergedFields =
    fields ??
    steps?.reduce((acc, step) => {
      return [...acc, ...step.form_fields]
    }, [])
  const schema = yup.object().shape({
    ...buildYupSchema({ form }),
    is_data_accuracy_consent_accepted: yup
      .boolean()
      .required()
      .equals(
        [true],
        'Please confirm that the information provided above is accurate and complete to the best of your knowledge',
      ),
  })
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...getDefaultValues(mergedFields),
      is_data_accuracy_consent_accepted: false,
    },
  })
  const onSubmit = (data) => {
    onComplete?.({
      ...formatValues(data),
      is_data_accuracy_consent_accepted: data.is_data_accuracy_consent_accepted,
    })
  }

  const hasSteps = steps && steps.length > 0
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {hasSteps ? (
        steps?.map((step) => {
          return isStepEmpty(step, form.conditions, watch()) ? null : (
            <div
              className='tw-border-b tw-border-b-secondary-20 tw-py-6'
              key={step.id}
            >
              <SectionHeader title={step.title} />
              <Container fluid>
                <Row>
                  <DynamicForm
                    control={control}
                    errors={errors}
                    fields={step?.form_fields}
                    setValue={setValue}
                    conditions={form.conditions}
                    watch={watch}
                    key={step.id}
                    filesDownloadable={filesDownloadable}
                    clearErrors={clearErrors}
                    canDeleteFiles={canDeleteFiles}
                    readOnly={readOnly}
                  />
                </Row>
              </Container>
            </div>
          )
        })
      ) : (
        <Container fluid>
          <Row className='tw-mt-4'>
            <DynamicForm
              control={control}
              errors={errors}
              fields={fields}
              setValue={setValue}
              watch={watch}
              conditions={form.conditions}
              filesDownloadable={filesDownloadable}
              clearErrors={clearErrors}
              readOnly={readOnly}
              canDeleteFiles={canDeleteFiles}
            />
          </Row>
        </Container>
      )}
      {readOnly ? null : (
        <Container className='tw-my-6' fluid>
          <ControlledCheckbox
            label='I confirm that the information provided above is accurate and complete to the best of my knowledge'
            id='is_data_accuracy_consent_accepted'
            control={control}
            error={errors?.is_data_accuracy_consent_accepted}
            name='is_data_accuracy_consent_accepted'
          />
        </Container>
      )}
      {readOnly ? null : (
        <ModalFooter>
          <Button loading={isSubmissionLoading} disabled={isSubmissionLoading}>
            Submit
          </Button>
        </ModalFooter>
      )}
    </form>
  )
}

export default EORDataCollectionForm
