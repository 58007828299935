import {
  UPDATE_LIST_TO_PAY,
  UPDATE_CONFIRMATION_STATUS,
  START_CONFIRMATION,
  END_CONFIRMATION,
} from './actionTypes'

const initialState = {
  toPay: null,
  confirming: false,
  confirmed: false,
}

const payment = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LIST_TO_PAY:
      state = {
        ...state,
        toPay: action.payload,
      }
      break
    case UPDATE_CONFIRMATION_STATUS:
      state = {
        ...state,
        confirmed: action.payload,
      }
      break
    case START_CONFIRMATION:
      state = {
        ...state,
        confirming: true,
      }
      break
    case END_CONFIRMATION:
      state = {
        ...state,
        confirming: false,
      }
      break

    default: {
      return state
    }
  }
  return state
}

export default payment
