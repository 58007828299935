import { List } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
// i18n
import { withTranslation } from 'react-i18next'

// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftMenu,
  updateAdminToken,
} from '../../../../store/actions'

import BurgerMenuPanel from '../../../../components/BurgerMenuPanel'
import HeaderLogoLink from '../../../../components/Common/header-logo'
import {
  SIDEBAR_BREAKDOWN,
  useCustomResize,
  useFetch,
} from '../../../../helpers/hooks'
import { logoutAdmin } from '../../../../services/api'

const Header = ({ routePermissions }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const isMobile = useCustomResize({ minWidth: SIDEBAR_BREAKDOWN })

  const dispatch = useDispatch()
  const logout = useFetch({
    action: logoutAdmin,
    withAdminAccess: true,
    onComplete: () => {
      dispatch(updateAdminToken(null))
    },
  })

  return (
    <header id='page-topbar'>
      {!!isMobile && (
        <BurgerMenuPanel
          open={openMenu}
          hide={() => setOpenMenu(false)}
          routePermissions={routePermissions}
        />
      )}

      <div className='navbar-header pr-0 pr-md-2'>
        <div className='tw-flex'>
          <HeaderLogoLink />
        </div>
        <div className='d-flex' style={{ gap: '0.25rem' }}>
          <button
            type='button'
            onClick={() => setOpenMenu(true)}
            className='align-items-center btn d-flex d-lg-none font-size-16 header-item hover:bg-surface-10 justify-content-center p-3 p-md-4'
          >
            <List size={16} weight='bold' />
          </button>
          <button
            type='button'
            className='rp-btn-nostyle text-primary py-3 px-4 py-md-4 hover:bg-surface-10'
            onClick={logout.startFetch}
          >
            {logout.isLoading ? (
              <i className='bx bx-loader bx-spin align-middle' />
            ) : (
              'Logout'
            )}
          </button>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, {
    showRightSidebarAction,
    toggleLeftMenu,
    changeSidebarType,
  })(withTranslation()(Header)),
)
