import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import { submitMilestone } from '../../../services/api'

const SubmitMilestoneModal = ({
  toggle,
  isOpen,
  milestone,
  onMilestoneSubmitted,
  data,
}) => {
  const isMobile = useResize()

  const {
    startFetch: contractorSubmitMilestone,
    isLoading: submittingMilestone,
  } = useFetch({
    action: submitMilestone,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        toastr.success('Successfully submitted')
        onMilestoneSubmitted?.()
        toggle?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader>
        Submit {data?.type === 'Full Time' ? 'invoice' : 'milestone'}
      </ModalHeader>

      <ModalBody className='!tw-py-6 tw-text-sm'>
        Are you sure you would like to submit this{' '}
        {data?.type === 'Full Time' ? 'Invoice' : 'work'} for approval?
      </ModalBody>

      <ModalFooter className={isMobile ? 'fixed-bottom' : ''}>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={submittingMilestone}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={() => contractorSubmitMilestone({ work_id: milestone?.id })}
          disabled={submittingMilestone}
          loading={submittingMilestone}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SubmitMilestoneModal
