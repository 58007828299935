import { CaretRight } from '@phosphor-icons/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'

import { Avatar } from 'ui'
import { StyledH4, StyledH5 } from '../../components/Typo'
import { HeaderAnonym } from '../../components/ui/header-anonym'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import {
  loginUserSuccessful,
  saveRoute,
  updateDeepLink,
} from '../../store/auth/register/actions'
import { tag } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { getDefaultEventData } from '../../utils/analytics/get-default-event-data'

function trackSignIn(data) {
  const email = data?.email
  const clientId = data?.id

  const defaultEventData = getDefaultEventData(email)
  const eventData = {
    ...defaultEventData,
    userID: clientId,
    email_id: email,
  }

  if (!data?.is_contractor) {
    eventData.companyID = data?.company_id
  }

  tag(EVENTS.SIGNED_IN, eventData)
}

export default function UserTypeChooser() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Account?.user)
  const link = useSelector((state) => state.Account?.link)
  const history = useHistory()
  const lastPath = useSelector((state) => state.Account?.lastPath)

  function handleLoginResponse(data) {
    trackSignIn(data)

    dispatch(loginUserSuccessful(data))
    if (lastPath) {
      dispatch(saveRoute(null))
      history.push(lastPath)
    } else if (link && typeof link === 'string') {
      history.push(link)
      dispatch(updateDeepLink(null))
    }
  }

  return (
    <Modal
      style={{ maxWidth: '100%', height: '100%' }}
      isOpen={true}
      className='p-0 m-0 switch-modal'
      modalClassName='bg-gray-bg'
      contentClassName='bg-transparent'
    >
      <HeaderAnonym />

      <ModalBody
        className='p-md-0 m-md-0 bg-transparent'
        style={{ height: 'calc(100vh - 80px)' }}
      >
        <div style={{ width: '100%', maxWidth: 500 }} className='tw-py-20'>
          <div className='text-center mb-3 mb-md-4 mb-5'>
            <StyledH5 min='22px' max='32px'>
              Welcome back {user?.[0]?.first_name}!
            </StyledH5>
            <p className='text-muted mb-0 mt- font-size-14'>
              Choose a profile below to pick up where you left off.
            </p>
          </div>
          <div className='d-flex flex-column pb-3' style={{ gap: '1rem' }}>
            {user?.length <= 0
              ? null
              : user?.map((user, i) => {
                  const isClient = user?.type === userTypes.COMPANY
                  const isContractor =
                    user?.contractor_type === BE_CONTRACT_CATEGORY.INDIVIDUAL ||
                    user?.contractor_type === BE_CONTRACT_CATEGORY.ENTITY
                  const isDirectEmployee =
                    user?.contractor_type ===
                    BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

                  return (
                    <button
                      key={i}
                      onClick={() => {
                        handleLoginResponse(user)
                      }}
                      className='tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-rounded tw-border-none tw-bg-white tw-px-7 tw-py-6 tw-text-left tw-shadow-sm tw-transition-colors hover:tw-shadow-none hover:tw-ring-2 hover:tw-ring-primary-100'
                    >
                      <div
                        className='d-flex justify-content-between align-items-center'
                        style={{ gap: 20, flex: 1 }}
                      >
                        <Avatar
                          photo={user?.photo}
                          name={user?.first_name || user?.email}
                          size='xl'
                          label={isClient ? 'Client' : undefined}
                        />

                        <div style={{ flex: 1 }}>
                          <StyledH4
                            min='16px'
                            max='16px'
                            className='mb-0 text-dark rp-font-semibold'
                          >
                            {isClient
                              ? 'Client'
                              : isContractor
                                ? 'Contractor'
                                : isDirectEmployee
                                  ? 'Direct Employee'
                                  : 'Employee'}{' '}
                            account
                          </StyledH4>
                          <p
                            style={{
                              marginBottom: 0,
                              marginTop: 6,
                              fontSize: 14,
                              color: '#76809d',
                            }}
                          >
                            {isClient
                              ? user?.company_name
                              : user?.first_name + ' ' + user?.last_name}
                          </p>
                        </div>
                      </div>

                      <CaretRight weight='bold' size={18} />
                    </button>
                  )
                })}

            <Link
              to='/logout'
              className='tw-block tw-w-full tw-text-center tw-text-sm'
            >
              Logout
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
