export const expensesFiltersAreEmpty = (filters) => {
  return !filters.contract_id && !filters.end_date && !filters.start_date
}

export const defaultContract = { value: null, label: 'All Contracts' }

export function getExpenseContract(expense, contracts) {
  return contracts.filter(
    (contract) => contract.label === expense.contract_ref,
  )[0]
}
