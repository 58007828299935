import React, { useEffect, useState } from 'react'

import Head from '../../components/head'
import rpLogoWhite from '../../assets/images/identity/logo_main.png'
import { useLocation } from 'react-router'
import { useFetch } from '../../helpers/hooks'
import { sendSlackAuthCode } from '../../services/api'
import toastr from 'toastr'
import { ArrowSquareOut } from '@phosphor-icons/react'

export default function SlackAuthPage() {
  const [slackRedirectUrl, setSlackRedirectUrl] = useState(null)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const slackCode = params.get('code')

  const { startFetch: sendSlackCode } = useFetch({
    action: sendSlackAuthCode,
    autoFetch: false,
    onComplete: (data) => {
      toastr.success('Slack authorization successful')
      // set redirect url to user's work space
      setSlackRedirectUrl('https://app.slack.com/client/' + data?.workspace_id)
    },
    onError: (error) => toastr.error(error),
  })

  useEffect(() => {
    if (slackCode) {
      sendSlackCode({ code: slackCode })
    }
  }, [slackCode])

  return (
    <div className='min-vh-100 tw-flex tw-flex-col tw-gap-40'>
      <Head title='Slack Auth' />
      <div
        className='bg-white py-4 rp-border tw-flex tw-items-center tw-justify-center tw-text-center'
        style={{ height: 70 }}
      >
        <img src={rpLogoWhite} style={{ height: '24px' }} />
      </div>

      {!slackRedirectUrl ? (
        <h1 className='font-size-28 tw-text-center'>Slack Authorization</h1>
      ) : (
        <div className='mx-auto my-0'>
          <h1 className='font-size-28'>Slack Connected</h1>
          <p>
            Congratulations! Your RemotePass account is now connected to Slack.
          </p>
          <p>
            You will now receive notifications directly in the RemotePass App on
            Slack.
          </p>
          <a
            href={slackRedirectUrl}
            className='btn btn-primary !tw-flex tw-w-fit tw-items-end tw-self-center'
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              window.close()
            }}
          >
            Open Slack <ArrowSquareOut className='tw-ml-1' size={24} />
          </a>
        </div>
      )}
    </div>
  )
}
