import Quill from 'quill'
import Mention from 'quill-mention'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap'

import Editable from './Editable'
import TagSelector from './TagSelector'

function MyEditor(props) {
  // quill logic refs
  // The div containing the quill editor when no instance of Editable is using it.
  const quillEditorContainerTempHolder = useRef()
  // The div that contains the quill toolbar.
  const quillToolbarContainer = useRef()
  // The quill editor
  const quillEditorContainer = useRef()
  // The quill instance
  const quillInstance = useRef()
  const [activeEditableKey, setActiveEditableKey] = useState()
  const [activeEditable, setActiveEditable] = useState()

  const [, setName] = useState('')
  const [agreement, setAgreement] = useState('')
  const [sections, setSections] = useState([])
  const [, setSectionsCount] = useState(0)
  const [orderChanges, setOrderChanges] = useState(false)

  const Inline = Quill.import('blots/inline')

  class SpanBlock extends Inline {
    static create(value) {
      const node = super.create()
      node.setAttribute('class', 'tag')
      return node
    }
  }

  SpanBlock.blotName = 'tag'
  SpanBlock.tagName = 'label'

  const modules = {
    toolbar: quillToolbarContainer.current,
  }
  Quill.register(SpanBlock)
  Quill.register(Mention)

  useEffect(() => {
    // Store the quill instance for future use
    quillInstance.current = new Quill(quillEditorContainer.current, {
      theme: 'snow',
      modules,
    })
  }, [])
  /**
   * Add event listeners to quill to update the active editable
   * when we type into the quill editor.
   */
  useEffect(() => {
    if (quillInstance.current && activeEditable) {
      const quill = quillInstance.current
      const onTextChange = () => {
        const newSections = [...sections]
        newSections[activeEditableKey - 1] =
          quill.container.firstChild.innerHTML
        setSections(newSections)
      }
      quill.on('text-change', onTextChange)
      return () => quill.off('text-change', onTextChange)
    }
  }, [quillInstance, activeEditable, sections, setSections])

  const setEditableActive = (editable, activate, key) => {
    if (activate) {
      const quill = quillInstance.current
      const delta = quill.clipboard.convert(
        editable.details.includes('<li')
          ? `<ol>${editable.details}</ol>`
          : `<div>${editable.details}</div>`,
      )
      quill.setContents(delta, 'silent')
      setActiveEditable(editable)
      setActiveEditableKey(key)
      setTimeout(() => {
        quill.setSelection({ index: 0, length: quill.getLength() - 1 }, 'api')
      })
    } else {
      quillEditorContainerTempHolder.current.appendChild(
        quillEditorContainer.current,
      )
      setActiveEditable(undefined)
    }
  }

  useEffect(() => {
    props.onChange(sections)
    setSectionsCount(sections?.length)
  }, [sections])
  useEffect(() => {
    setName(props.name)
    setAgreement(props.agreement)
    setSections(props.sections)
  }, [props.name, props.agreement, props.sections])

  const moveUp = (key) => {
    setActiveEditableKey(0)
    // updateSection()
    const newSections = [...sections]
    const html = newSections[key - 1]
    newSections[key - 1] = newSections[key]
    newSections[key] = html
    setSections(newSections)
    setOrderChanges(!orderChanges)
  }

  const moveDown = (key) => {
    setActiveEditableKey(0)
    // updateSection()
    const newSections = [...sections]
    const html = newSections[key + 1]
    newSections[key + 1] = newSections[key]
    newSections[key] = html
    setSections(newSections)
    setOrderChanges(!orderChanges)
  }

  const deleteSection = (key) => {
    setActiveEditableKey(0)
    // updateSection()
    const newSections = [...sections]
    newSections.splice(key, 1)
    setSections(newSections)
    setOrderChanges(!orderChanges)
  }

  function createMarkup(text) {
    return { __html: text }
  }

  const handleTitleChanges = (title, key) => {
    const newSections = [...sections]
    newSections[key].name = title
    setSections(newSections)
  }
  const handleAddSection = (key) => {
    setActiveEditableKey(0)
    const newSections = [...sections]
    newSections.splice(key, 0, { name: 'unnamed', details: '<li><p></p></li>' })
    setSections(newSections)
    setOrderChanges(!orderChanges)
  }

  return (
    <div>
      <Row>
        <Col className='min-vh-100' md={10} style={{ padding: '0px' }}>
          <div>
            <div id='toolbar' ref={quillToolbarContainer} className='fixed-top'>
              <div className='ql-formats'>
                <select className='ql-header' defaultValue={false}>
                  <option value='false' />
                  <option value='1' />
                  <option value='2' />
                  <option value='3' />
                </select>
              </div>
              <span className='ql-formats'>
                <button className='ql-bold' />
                <button className='ql-italic' />
                <button className='ql-underline' />
                <button className='ql-strike' />
              </span>
              <span className='ql-formats'>
                <select className='ql-color' />
                <select className='ql-background' />
              </span>
              <span className='ql-formats'>
                <button className='ql-script' value='sub' />
                <button className='ql-script' value='super' />
              </span>
              <span className='ql-formats'>
                <button className='ql-blockquote' />
                <button className='ql-code-block' />
              </span>
              <span className='ql-formats'>
                <button className='ql-list' value='ordered' />
                <button className='ql-list' value='bullet' />
                <button className='ql-indent' value='-1' />
                <button className='ql-indent' value='+1' />
              </span>
              <span className='ql-formats'>
                <button className='ql-direction' value='rtl' />
                <select className='ql-align' />
              </span>
              <span className='ql-formats'>
                <button className='ql-link' />
                <button className='ql-image' />
                <button className='ql-video' />
                <button className='ql-formula' />
              </span>
              <span className='ql-formats'>
                <button className='ql-clean' />
              </span>
            </div>
            <div style={{ height: '60px' }} />
            <div id='section-0'></div>
            <div className='bg-light p-5' style={{ minHeight: '100vh' }}>
              <div id='template' className='p-5' style={{ minHeight: '70vh' }}>
                <div
                  className='default'
                  dangerouslySetInnerHTML={createMarkup(agreement)}
                />
                {sections?.map((section, key) => (
                  <>
                    {activeEditableKey !== key + 1 && (
                      <div className='row px-5 justify-content-between'>
                        <a
                          className='text-primary'
                          onClick={() => handleAddSection(key)}
                        >
                          + Add section
                        </a>
                        <div>
                          {key < sections.length - 1 && (
                            <a onClick={() => moveDown(key)}>
                              <i className='bx bxs-down-arrow text-primary' />
                            </a>
                          )}{' '}
                          &nbsp;
                          {key > 0 && (
                            <a onClick={() => moveUp(key)}>
                              <i className='bx bxs-up-arrow text-primary' />
                            </a>
                          )}
                          &nbsp;
                          <a onClick={() => deleteSection(key)}>
                            <i className='bx bxs-trash-alt text-primary' />
                          </a>
                          &nbsp;
                        </div>
                      </div>
                    )}
                    <ul
                      key={'section-' + (key + 1)}
                      className={`section ${
                        activeEditableKey === key + 1 ? 'editable' : ''
                      }`}
                    >
                      <EditableHeader
                        defaultValue={section.name}
                        onChanges={(e) => handleTitleChanges(e, key)}
                      />

                      <Editable
                        editable={section}
                        content={section.details}
                        onChangeActive={(e, a) =>
                          setEditableActive(e, a, key + 1)
                        }
                        quillEditorContainer={quillEditorContainer}
                        isActive={activeEditableKey === key + 1}
                        key={`section-${key}`}
                      />
                    </ul>
                  </>
                ))}
              </div>
            </div>
            <div
              style={{ position: 'fixed', left: '100vw', top: 0 }}
              ref={quillEditorContainerTempHolder}
            >
              <div ref={quillEditorContainer} />
            </div>
          </div>
        </Col>
        <Col className='p-0 bg-white tags-container' md={2}>
          <TagSelector
            onSelect={(tag, key) => {
              const mention = quillInstance.current.getModule('mention')
              if (mention) {
                mention.insertItem(
                  { id: key, value: `<label class="tag">${tag}</label>` },
                  true,
                )
              }
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

const EditableHeader = ({ defaultValue, onChanges = () => {} }) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(defaultValue)
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  return editing ? (
    <input
      defaultValue={value}
      onChange={(e) => {
        const v = e.target.value
        setValue(v)
        onChanges(v)
      }}
      onBlur={() => {
        setEditing(false)
      }}
    />
  ) : (
    <b onClick={() => setEditing(true)}>{value}</b>
  )
}

export default MyEditor
