import { XCircle } from '@phosphor-icons/react'
import cx from 'classnames'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Input } from 'reactstrap'

import { cn } from 'ui'
import { useOnClickOutside } from '../../helpers/hooks/use-outside-click'
import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

function ControlledInput({
  autoComplete,
  watchState,
  control,
  disabled,
  name,
  defaultValue,
  error: externalError,
  showError = true,
  label,
  labelClassName,
  labelFor,
  transform,
  containerClassName,
  wrapperClassName,
  wrapperStyles,
  ...otherProps
}) {
  const inputContainerRef = useRef()
  const [suggestion, setSuggestions] = useState([])

  useOnClickOutside(inputContainerRef, () => {
    setSuggestions([])
  })

  useEffect(() => {
    const selectedValue =
      autoComplete?.filter((word) => word.title === watchState).length > 0

    if (watchState?.length <= 0) {
      setSuggestions([])
    } else {
      if (!selectedValue) {
        const regex = new RegExp(`^${watchState?.replace(/[^a-z]/gi, '')}`, 'i')
        const newSuggestions = autoComplete?.filter((word) =>
          regex.test(word?.title),
        )
        setSuggestions(newSuggestions?.length > 0 ? newSuggestions : [])
      }
    }
  }, [watchState, autoComplete])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
      }) => {
        const theError = getInputErrorMessage(externalError ?? error)

        const showingError = theError && showError

        return (
          <div className={wrapperClassName} style={wrapperStyles}>
            <div
              className={cx(
                'tw-relative tw-w-full tw-overflow-hidden',
                containerClassName,
              )}
              ref={inputContainerRef}
            >
              {!label || !value ? null : (
                <motion.label
                  className={cn(
                    labelClassName,
                    'tw-absolute tw-block tw-min-w-full tw-overflow-hidden tw-whitespace-nowrap tw-px-[0.8rem] tw-text-[10px] tw-text-text-80',
                  )}
                  htmlFor={otherProps?.id ?? labelFor}
                  style={{ top: '0.4rem', height: '20px' }}
                  whileHover={{ right: 0 }}
                >
                  {label}
                </motion.label>
              )}
              <Input
                disabled={disabled}
                onChange={(e) =>
                  transform?.output
                    ? onChange(transform.output(e))
                    : onChange(e)
                }
                value={transform?.input ? transform.input(value) : value}
                onBlur={onBlur}
                innerRef={ref}
                name={name}
                autoComplete={
                  typeof autoComplete === 'object' ? 'off' : autoComplete
                }
                {...otherProps}
                className={cx(
                  'form-control pb-3 text-danger font-size-14 rp-font-semibold text-text-black',
                  {
                    'border-danger': showingError,
                    'pt-4.5': value,
                    'py-4': !value,
                  },
                  otherProps?.className,
                )}
              />

              {showingError ? (
                <InputFeedback className='tw-mt-1'>{theError}</InputFeedback>
              ) : null}

              {autoComplete && suggestion.length > 0 && (
                <ul
                  className='tw-absolute tw-left-0 tw-z-10 tw-mb-0 tw-max-h-64 tw-w-full tw-overflow-auto tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-py-1 tw-shadow-sm'
                  style={{ top: showingError ? '100%' : 'calc(100% + 8px)' }}
                >
                  {suggestion?.slice(0, 10)?.map((item, i) => {
                    return (
                      <li key={i}>
                        <button
                          className='tw-block tw-w-full tw-px-4 tw-py-2 tw-text-start tw-text-sm/6 hover:tw-bg-surface-20'
                          onClick={() => {
                            onChange(item.title)
                            setSuggestions([])
                          }}
                        >
                          {item?.title}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

export default ControlledInput
