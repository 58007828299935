import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

const ColumnWithDataLabels = ({ values, months, currency, labels }) => {
  return (
    <div className='tw-relative tw-h-[380px]'>
      <Bar
        data={{
          labels: months,
          datasets: [
            {
              label: 'Earnings',
              data: values,
              backgroundColor: '#E8E9EB',
              borderWidth: 0,
              hoverBackgroundColor: '#114EF7',
            },
          ],
        }}
        options={{
          scales: {
            y: {
              type: 'linear',
              border: {
                display: false,
              },
              ticks: {
                stepSize: 50000,
                format: {
                  style: 'currency',
                  currency: currency?.code || 'USD',
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: '#fff',
              bodyColor: '#000',
              titleColor: '#000',
              borderColor: '#000',
              borderWidth: 1,
              multiKeyBackground: '#000',
              cornerRadius: 4,
              boxPadding: 4,
              padding: 12,
              titleFont: {
                family: "'Mulish', Inter, sans-serif",
              },
              bodyFont: {
                family: "'Mulish', Inter, sans-serif",
              },
              callbacks: {
                labelColor: function () {
                  return {
                    backgroundColor: '#114EF7',
                    borderColor: '#fff',
                  }
                },
                title: function (title) {
                  return labels?.[title?.[0]?.dataIndex]
                },
              },
            },
          },
          locale: 'en-US',
          maintainAspectRatio: false,
        }}
      />
    </div>
  )
}
export default ColumnWithDataLabels
