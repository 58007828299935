import { Info } from '@phosphor-icons/react'
import { format, sub } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import customStyles from '../../components/Common/react-select-custom-styles'
import { NativeSelect } from '../../components/controlled-native-select/controlled-native-select'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import UsdSwiftMessage from '../../components/usd-swift-message'
import { BE_CONTRACT_CATEGORY, CONTRACT_STATUS } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  createWithdrawAccount,
  getBankFields,
  getContractList,
  withdrawCurrencies,
} from '../../services/api'
import deepen from '../../utils/deepen'
import { isProduction } from '../../utils/isProduction'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import WithdrawMethodCard from '../Withdrawal/components/WithdrawMethodCard'
import './bank-accounts.css'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'

export const bankAccountAlertClassName =
  '!tw-mb-0 !tw-p-4 !tw-text-xs !tw-bg-systemBlue-10 !tw-border-0 !tw-text-text-80 !tw-text-wrap'

export function Messages({ showUsMessage, className, showBillsAlert = false }) {
  return (
    <div className={cn('tw-flex tw-flex-col tw-gap-2', className)}>
      <Alert color='info' className={bankAccountAlertClassName}>
        <strong>Important:</strong> The name on the bank account must be the
        same as your name or entity name on the signed contract. If different,
        the transfer will be rejected.
      </Alert>

      <UsdSwiftMessage showMessage={showUsMessage} />

      {!showBillsAlert ? null : (
        <Alert
          color='info'
          className={cn(
            'tw-flex tw-items-center tw-gap-2',
            bankAccountAlertClassName,
          )}
        >
          <Info className='tw-size-5 tw-shrink-0 tw-text-systemBlue-100' />
          <p className='tw-mb-0'>
            The bank details entered will need to be reviewed first before the
            payment is processed.
          </p>
        </Alert>
      )}
    </div>
  )
}

const BankAccounts = ({
  onNext = () => {},
  currency,
  isEdit,
  isEditing,
  onAdd = () => {},
  noCards,
  onCancel = () => {},
  isEmployee,
}) => {
  const [checkedCard, setCheckedCard] = useState(-1)
  const [showNewBankAccountModal, setShowNewBankAccountModal] = useState(false)

  const [editing, setEditing] = useState(!!noCards)
  useEffect(() => {
    setEditing(!!noCards)
  }, [isEditing, noCards])

  const selectedCurrency = useSelector(
    (state) => state.Withdraw?.withdrawAmount?.currency,
  )

  const { data: currencies } = useFetch(
    {
      action: withdrawCurrencies,
      autoFetch: editing || showNewBankAccountModal,
      body: { currency: selectedCurrency?.code },
    },
    [editing, showNewBankAccountModal],
  )

  const { getAccountsList, accounts, loading } = useWithdrawAccounts(
    'bank',
    selectedCurrency?.code,
  )

  function handleClose() {
    setShowNewBankAccountModal(false)
    onCancel?.()
  }

  return (
    <div>
      {!editing ? (
        <Row className='justify-content-center p-0 m-0'>
          {loading ? (
            <Col className='p-0 m-0' style={{ minHeight: '15rem' }}>
              <Row
                style={{ minHeight: '15rem' }}
                className='justify-content-center align-items-center p-0 m-0'
              >
                <Spinner type='grow' className='mr-2' color='primary' />
              </Row>
            </Col>
          ) : (
            <Col
              md={isEdit ? 9 : undefined}
              className='p-0 d-flex flex-column'
              style={{ gap: '1rem' }}
            >
              {accounts?.map((e, i) => (
                <WithdrawMethodCard
                  onClick={() => {
                    setCheckedCard(i)
                    onNext(e)
                  }}
                  checked={checkedCard === i}
                  key={`acc${i}`}
                  card={e}
                  index={i}
                />
              ))}
              <button
                onClick={() => setShowNewBankAccountModal(true)}
                className='rp-btn-nostyle align-self-start text-primary rp-font-bold'
                type='button'
              >
                + Add New Bank Account
              </button>
            </Col>
          )}
        </Row>
      ) : (
        <NewAddBankForm
          currency={currency}
          currencies={currencies?.map((c) => mapCurrencyToOption(c))}
          isEmployee={isEmployee}
          toggle={onCancel}
          updateList={(data, res) => {
            onAdd(data, res)
            getAccountsList()
          }}
        />
      )}
      <Modal
        isOpen={showNewBankAccountModal}
        scrollable
        centered
        className='newAccountModal'
        toggle={handleClose}
      >
        <ModalHeader toggle={handleClose}>Add Bank account</ModalHeader>
        <ModalBody className='p-0'>
          <NewAddBankForm
            currency={currency}
            currencies={currencies?.map((c) => mapCurrencyToOption(c))}
            toggle={handleClose}
            updateList={(data, res) => {
              onAdd(data, res)
              setShowNewBankAccountModal(false)
              getAccountsList()
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

function mapValuesAllowed({ key, name }) {
  return { label: name, value: key }
}

const dateFormat = 'yyyy-MM-dd'
function formatDate(date) {
  return format(new Date(date ?? null), dateFormat)
}

export function NewAddBankForm({
  currency,
  currencies,
  toggle,
  updateList,
  isEmployee,
}) {
  const user = useSelector((state) => state.Account?.user)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)
  // Fields to show to user
  const [fields, setFields] = useState(null)
  // Sometimes, there are two (or more) sets of fields,
  // depending on a type of this field
  const [fieldType, setFieldType] = useState(null)
  // The data filled by the user
  const [fieldData, setFieldData] = useState({})

  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  useEffect(() => {
    if (isEmployee) {
      setSelectedCurrency(currencies?.find((cur) => cur?.code === currency))
    }
  }, [currencies, currency, isEmployee])
  const { data: contractList } = useFetch({
    action: getContractList,
    body: {
      status: [
        CONTRACT_STATUS.PENDING_INVITE.value,
        CONTRACT_STATUS.PENDING_CONTRACTOR_SIGNATURE.value,
        CONTRACT_STATUS.PENDING_CLIENT_SIGNATURE.value,
        CONTRACT_STATUS.ONGOING.value,
      ],
    },
    autoFetch: isDeEmployee,
  })

  const contractCurrencies = useMemo(
    () => [...new Set(contractList?.map((c) => c?.currency?.code))],
    [contractList],
  )

  function getFields(data) {
    if (!currency || !selectedCurrency?.value) return
    const body = {
      source: currency,
      target: selectedCurrency?.value,
      targetAmount: 300, // arbitrary value
    }

    if (data) {
      body.details = { ...data }
    }

    setLoadingButton(true)
    getBankFields(user?.token, body)
      .then((r) => {
        let newData = r?.data?.data

        const newDataTypes = Object.values(newData).map((e) => e.type)
        const isInNewTypes = newDataTypes.includes(fieldType)
        const newType =
          newDataTypes.length > 1
            ? isInNewTypes
              ? fieldType
              : newDataTypes[0]
            : newDataTypes[0]
        setFieldType(newType)

        // Convert data format from `[{}, {}]` to `{key: {}, key2: {}}`
        newData = !Array.isArray(newData)
          ? {}
          : newData.reduce((prev, curr) => {
              return { ...prev, [curr?.type]: curr }
            }, {})

        setFields(newData)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const fieldsToRender = useMemo(() => {
    if (!fields || !fieldType) return []

    return fields[fieldType]?.fields
  }, [fieldType, fields])

  const fieldTypeOptions = useMemo(() => {
    if (!fields) return []

    const fieldTypes = Object.values(fields).map(({ title, type }) => ({
      label: title,
      value: type,
    }))

    if (fieldTypes.length <= 1) return []

    return [
      { label: 'Select', value: null, disabled: true, selected: true },
      ...fieldTypes,
    ]
  }, [fields])

  function handleFieldChange(event, field) {
    let name = null
    let value = null

    if (field.type === 'date') {
      name = field.key
      value = event
    } else {
      name = event.target.name
      value = event.target.value
    }

    const newFieldData = { ...fieldData, [name]: value }

    setFieldData(newFieldData)

    if (field?.refreshRequirementsOnChange) {
      getFields(newFieldData)
    }
  }

  useEffect(() => {
    getFields()
    setFieldData({})
  }, [selectedCurrency])

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    // Filter unwanted data
    const filteredData = {}
    const keys = fieldsToRender.map((f) => f.key)
    keys.forEach((key) => {
      filteredData[key] = fieldData[key]
    })

    // #region We need to send the bankName field if we have it
    if (keys.includes('bankCode')) {
      const banks = fieldsToRender.find(
        (e) => e.key === 'bankCode',
      )?.valuesAllowed
      if (banks?.length > 0) {
        const theBank = banks.find((e) => e.key === fieldData.bankCode)
        filteredData.bankName = theBank?.name
      }
    }
    if (keys.includes('branchCode')) {
      const branches = fieldsToRender.find(
        (e) => e.key === 'branchCode',
      )?.valuesAllowed
      if (branches?.length > 0) {
        const theBranch = branches.find((e) => e.key === fieldData.branchCode)
        filteredData.branchName = theBranch?.name
      }
    }
    if (keys.includes('swiftCode')) {
      const field = fieldsToRender.find((e) => e.key === 'swiftCode')
      if (field.type === 'select') {
        const banks = field?.valuesAllowed
        const theBank = banks.find((e) => e.key === fieldData.swiftCode)
        filteredData.bankName = theBank?.name
      }
    }
    // #endregion

    const deepFieldData = deepen(filteredData)

    const data = {
      currency: selectedCurrency?.value,
      accountHolderName: `${user?.first_name} ${user?.last_name}`,
      type: fieldType,
      legalType: deepFieldData?.legalType,
      ...deepFieldData,
    }

    setLoadingButton(true)
    createWithdrawAccount(user?.token, data)
      .then((r) => {
        if (r.data.success) {
          updateList(data, r.data.data)
          toggle()
        } else {
          toastr.error(r.data?.data?.error || r.data?.error || r.data?.message)
        }
      })
      .catch((e) => {
        toastr.error(
          e?.response?.data?.data?.error ||
            e.data?.data?.error ||
            e.data?.error ||
            e.data?.message ||
            'An error occurred',
        )
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const showUsMessage =
    selectedCurrency?.value === 'USD' && fieldType === 'swift_code'

  return (
    <Container fluid className='pt-4 px-3'>
      <Messages showUsMessage={showUsMessage} className='tw-mx-2 tw-mb-4' />

      <form onSubmit={handleSubmit}>
        <div
          className='mb-md-0'
          style={{ minHeight: '50vh', marginBottom: 'calc(74px * 2)' }}
        >
          <Row className='mx-n3'>
            <Col md={6}>
              <FormGroup>
                <Label htmlFor='currency'>Select the desired currency</Label>
                <CustomSelect
                  name='currency'
                  inputId='currency'
                  isDisabled={isEmployee}
                  value={selectedCurrency}
                  onChange={setSelectedCurrency}
                  options={
                    isDeEmployee
                      ? currencies
                          ?.filter((currency) =>
                            contractCurrencies.includes(currency.code),
                          )
                          .map((c) => mapCurrencyToOption(c))
                      : currencies?.map((c) => mapCurrencyToOption(c))
                  }
                  classNamePrefix='RS-Control'
                  styles={customStyles}
                />
              </FormGroup>
            </Col>
            {fieldTypeOptions?.length <= 0 ? null : (
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor='currency'>Type</Label>
                  <NativeSelect
                    value={fieldType}
                    onChange={(e) => setFieldType(e.target.value)}
                    options={fieldTypeOptions}
                  />
                </FormGroup>
              </Col>
            )}
            {fieldsToRender?.map((field) => {
              return (
                <Col md={6} key={field.key}>
                  <FormGroup>
                    <Label htmlFor={field.key}>{field.name}</Label>
                    {field.type === 'text' ? (
                      <Input
                        name={field.key}
                        id={field.key}
                        placeholder={field.name}
                        value={fieldData[field.name]}
                        onChange={(e) => handleFieldChange(e, field)}
                        maxLength={field?.maxLength}
                        minLength={field?.minLength}
                        required={field?.required}
                      />
                    ) : ['select', 'radio'].includes(field.type) ? (
                      <NativeSelect
                        name={field.key}
                        id={field.key}
                        placeholder={field.name}
                        value={fieldData[field.name]}
                        onChange={(e) => handleFieldChange(e, field)}
                        options={[
                          {
                            label: 'Select',
                            value: null,
                            disabled: true,
                            selected: true,
                          },
                          ...(field.valuesAllowed?.map(mapValuesAllowed) ?? []),
                        ]}
                        required={field?.required}
                      />
                    ) : field.type === 'date' ? (
                      <CustomDatePicker
                        value={formatDate(fieldData[field.name])}
                        name={field.key}
                        handleOnChange={(v) => {
                          handleFieldChange(formatDate(v), field)
                        }}
                        placeholder={dateFormat}
                        minDate={sub(new Date(), { years: 118 })}
                        maxDate={sub(new Date(), { years: 18 })}
                      />
                    ) : !isProduction() ? (
                      `${field.type} is not supported`
                    ) : null}
                  </FormGroup>
                </Col>
              )
            })}
          </Row>
        </div>

        <ModalFooter className='mx-n3 custom-modal-footer bg-white'>
          <Button
            color='light'
            outline
            type='button'
            onClick={toggle}
            disabled={loadingButton}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={loadingButton || !selectedCurrency}
            loading={loadingButton}
          >
            Add account
          </Button>
        </ModalFooter>
      </form>
    </Container>
  )
}

export default BankAccounts
