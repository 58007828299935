import { ArrowSquareOut, WarningOctagon } from '@phosphor-icons/react'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'

export function AmendModal({ isOpen, toggle, onProceed, isFullTime }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Amend contract
      </ModalHeader>

      <ModalBody className='tw-flex tw-flex-col tw-gap-4 !tw-p-4 tw-text-sm md:!tw-p-6'>
        <WarningOctagon size={40} weight='duotone' color='var(--gold-100)' />

        {!isFullTime ? (
          <>
            <p className='tw-mb-0 tw-font-semibold'>
              Please note that when you amend an ongoing contract:
            </p>
            <ul className='rp-font-semibold text-text-60 tw-mb-0 tw-ml-4 tw-list-disc'>
              <li>Contract will need to be re-signed by both parties</li>
              <li>Unpaid payments will be removed</li>
              <li>New payment will be created using the new details</li>
            </ul>
          </>
        ) : (
          <>
            <p className='tw-mb-0 tw-text-sm'>
              Please note that your request is subject to approval by
              RemotePass, and the effective date will be determined upon your
              signature of the new Statement of Work.
            </p>
            <a
              className='tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-bold'
              href='https://help.remotepass.com/en/articles/8532029-how-to-amend-a-job-title-or-salary-for-an-eor-contract'
              target='_blank'
              rel='noreferrer'
            >
              Learn More
              <ArrowSquareOut size={20} />
            </a>
          </>
        )}
      </ModalBody>
      <ModalFooter className='!tw-p-4 md:!tw-p-6'>
        <Button type='button' color='light' outline onClick={toggle}>
          Cancel
        </Button>
        <Button type='button' onClick={onProceed}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  )
}
