import React from 'react'

import Button from '../../../components/ui/button'
import PageHeading from '../../../components/ui/page-heading'

export default function ExpensesHeader({
  addExpense,
  showAddButton,
  canAddExpense,
}) {
  return (
    <PageHeading className='align-items-center'>
      <PageHeading.Title>Expenses</PageHeading.Title>

      {showAddButton && (
        <Button
          onClick={addExpense}
          title={canAddExpense ? 'Add Expense' : 'Active contract is required'}
          disabled={!canAddExpense}
        >
          Add Expense
        </Button>
      )}
    </PageHeading>
  )
}
