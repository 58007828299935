import React from 'react'
import { cn } from 'ui'

export const ReviewLayout = {}

function ReviewLayoutPage({ children, className }) {
  return (
    <div
      className={cn(
        'tw-flex tw-h-screen tw-min-h-80 tw-flex-col tw-gap-4 [--top-section-height:80px] md:[--top-section-height:96px] lg:[--top-section-height:78px]',
        className,
      )}
    >
      {children}
    </div>
  )
}

function ReviewLayoutWrapper({
  children,
  footerHeight = defaultFooterHeight,
  pageContentPaddingBottom = defaultPageContentPaddingBottom,

  isSelecting = false,
}) {
  return (
    <div
      className='tw-relative tw-grid tw-flex-grow tw-gap-4'
      style={{
        gridTemplateRows: isSelecting
          ? `1fr ${footerHeight - pageContentPaddingBottom}px`
          : '1fr',
      }}
    >
      {children}
    </div>
  )
}

function ReviewLayoutGrid({ children }) {
  return <div className='tw-grid tw-gap-4 lg:tw-grid-cols-10'>{children}</div>
}

const colSpanMap = {
  4: 'lg:tw-col-span-4',
  6: 'lg:tw-col-span-6',
}
function ReviewLayoutColumn({
  children,
  maxHeight,
  minHeight = 417,
  colSpan = 4,
}) {
  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-flex-col tw-overflow-auto tw-rounded tw-bg-white',
        colSpanMap[colSpan],
      )}
      style={{ maxHeight, minHeight }}
    >
      {children}
    </div>
  )
}

ReviewLayout.Page = ReviewLayoutPage
ReviewLayout.Wrapper = ReviewLayoutWrapper
ReviewLayout.Grid = ReviewLayoutGrid
ReviewLayout.Column = ReviewLayoutColumn

export const defaultFooterHeight = 80
const defaultPageContentPaddingBottom = 60

export function getContentMaxHeight({
  footerHeight,
  isSelecting,
  topActionsHeight,
}) {
  const paddingContentTop = 'var(--padding-content-top)'
  const paddingContentBottom = isSelecting
    ? footerHeight + 'px'
    : 'var(--padding-content-bottom)'
  const navHeight = 'var(--top-section-height)'
  const titleHeight = '42px'
  const gutterSpace = '1rem'
  const gutterHeight = isSelecting ? `calc(${gutterSpace} * 3)` : gutterSpace

  const _topActionsHeight = topActionsHeight || 0

  return `calc(100vh - ${paddingContentTop} - ${titleHeight} - ${paddingContentBottom} - ${navHeight} - ${gutterHeight} - ${_topActionsHeight}px)`
}
