import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useResize } from '../../../../helpers/hooks'
import { useHistory } from 'react-router'
import mappingCompletedImage from './../../../../assets/images/mappingCompleted.svg'

const FinalStep = ({ platform = 'Xero' }) => {
  const isMobile = useResize()

  const history = useHistory()

  const onComplete = () => {
    history.push('/settings/integrations')
  }

  return (
    <Row
      className='justify-content-center align-items-center'
      style={{ minHeight: '80vh' }}
    >
      <Col className='p-0'>
        <Row className='justify-content-center' style={{ fontSize: '10rem' }}>
          <img
            height={isMobile ? 200 : 400}
            src={mappingCompletedImage}
            alt=''
          />
        </Row>
        <Row className='justify-content-center p-3'>
          <h2>Mapping Completed</h2>
        </Row>
        <Row className='justify-content-center text-center text-secondary'>
          <Col md={8}>
            <p>{platform} integration mapping is completed successfully.</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Button
            onClick={onComplete}
            className='my-2 rp-font-bold'
            color='primary'
          >
            Back to Dashboard
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default FinalStep
