import { CaretDown } from '@phosphor-icons/react'
import React from 'react'
import { components } from 'react-select'

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props} className='!tw-px-2 !tw-py-1'>
      <CaretDown size={20} />
    </components.DropdownIndicator>
  )
}
