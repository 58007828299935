import cx from 'classnames'
import React from 'react'

export default function NoContent({
  children,
  minHeight = '20vh',
  className = '',
  style = {},
}) {
  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center',
        className,
      )}
      style={{ minHeight, ...style }}
    >
      {children}
    </div>
  )
}
