import axios from 'axios'
import { searchParamsFromObject } from '../utils/search-params-from-object'

const getConfig = (token, extraHeader, extra) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      ...extraHeader,
    },
    ...extra,
  }
}

const openApiHost = `${process.env.REACT_APP_OPEN_API_HOST}/openapi`
export const openApi = axios.create({
  baseURL: openApiHost,
  headers: { 'Content-Type': 'application/json' },
})

/**
 * Gets a paginated list of API Keys
 * @param {string} token Login token
 * @param {{page: number, limit: number}} param1 Pagination params
 * @returns { Promise<{ page: number, limit: number, total_items_count: number, total_pages_count: number, items: Array<{_id: string, name: string, prefix_id: string, created_at: string}> }> }
 */
export const getApiKeys = async (token, { page = 1, limit = 10 }) => {
  const sp = searchParamsFromObject({ page, limit })
  const result = await openApi.get(`/auth/v1/keys${sp}`, getConfig(token))
  return result
}

export const deleteApiKey = async (token, { keyId }) => {
  const result = await openApi.delete(
    `/auth/v1/keys/${keyId}`,
    getConfig(token),
  )
  return result
}

export const generateApiKey = async (token, { name }) => {
  const result = await openApi.post(
    `/auth/v1/keys`,
    {
      name,
    },
    getConfig(token),
  )
  return result
}
