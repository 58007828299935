import React, { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'reactstrap'
import { StyledH5 } from '../../../../components/Typo'
import { useFetch } from '../../../../helpers/hooks'
import {
  getAdminUpcomingPaymentList,
  getUnpaidPaymentList,
} from '../../../../services/api'
import PaymentList from '../../../due-payments-page/paymentList'
import { useLocation, useParams } from 'react-router'
import axios from 'axios'

const PaymentsByCompany = () => {
  const { companyId } = useParams()

  const location = useLocation()
  const action = location.pathname.startsWith('/admin-due-payments')
    ? getUnpaidPaymentList
    : getAdminUpcomingPaymentList

  const payments = useFetch({
    action,
    withAdminAccess: true,
  })

  useEffect(() => {
    let cancel
    const body = { company_id: companyId }
    // eslint-disable-next-line no-return-assign
    payments.startFetch(body, true, new axios.CancelToken((c) => (cancel = c)))
    return () => {
      if (cancel) cancel()
    }
  }, [companyId])

  return (
    <div className='page-content'>
      {payments.isLoading ? (
        <Container style={{ minHeight: '80vh' }}>
          <Col style={{ minHeight: '80vh' }}>
            <Row
              style={{ minHeight: '80vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className='p-0 m-0'>
          <StyledH5 min='22px' max='32px' className='mb-3 mb-md-4'>
            {location.pathname.startsWith('/admin-due-payments')
              ? 'Due Payments'
              : 'Upcoming Payments'}
          </StyledH5>
          <PaymentList data={payments.data} currency='USD' isAdmin />
        </Container>
      )}
    </div>
  )
}

export default PaymentsByCompany
