import React from 'react'
import Button from '../../../components/ui/button'
import { Key } from '@phosphor-icons/react'

export default function ApiKeysEmptyState({ onGenerateClicked }) {
  return (
    <div className='tw-m-8'>
      <div>
        <div className='tw-text-xl tw-font-bold tw-text-primary-100 md:tw-text-3xl'>
          <Key
            weight='duotone'
            size='200px'
            className='tw-mx-auto tw-fill-primary-100 tw-text-9xl'
          />
        </div>
      </div>
      <div className='tw-mt-3 tw-text-center'>
        <div className='tw-text-lg tw-font-bold'>No Keys</div>
        <div className='tw-mt-2 tw-text-xs tw-text-text-60'>
          Generated keys will be listed here
        </div>
        <div className='tw-mt-4 tw-px-10'>
          <Button block onClick={onGenerateClicked} className='tw-p-3'>
            Generate API Key
          </Button>
        </div>
      </div>
    </div>
  )
}
