import { Chat } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Badge,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import { ActionsDropdown } from 'ui'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  downloadEorInvoice,
  getContractsInvoicesList,
  revertAdminEorClientInvoice,
  updateAdminEorPartnerInvoice,
} from '../../../../services/api'
import openFile from '../../../../utils/file/open'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import isNill from '../../../../utils/is-nill'
import ContractRef from '../../components/ContractRef'
import EorPartnerInvoiceModal from '../Partners/eor-partner-invoice-modal'

const getStatusColor = (status) => {
  switch (status) {
    case 'Unpaid':
      return 'danger'
    case 'Paid':
      return 'success'
    case 'Processing':
      return 'warning'
    case 'Ongoing':
      return 'primary'

    default:
      return 'secondary'
  }
}

const invoiceStatuses = {
  pending_submission: {
    label: 'Pending submission',
    value: 'pending_submission',
  },
  pending_approval: { label: 'Pending approval', value: 'pending_approval' },
  approved: { label: 'Approved', value: 'approved' },
  unpaid: { label: 'Unpaid', value: 'unpaid' },
  processed: { label: 'Processed', value: 'processed' },
}

const invoiceStatusOptions = Object.values(invoiceStatuses).reduce(
  (acc, { label, value }) => {
    return [...acc, { label, value }]
  },
  [],
)

const contractStatusOptions = [
  { label: 'Ongoing', value: 4 },
  { label: 'Terminated', value: 7 },
]

const dateFormat = 'yyyy-MM'

const defaultFilters = {
  page: 1,
  month: format(new Date(), dateFormat),
  contract_id: '',
  contract_status: null,
  client_invoice_status: null,
  partner_invoice_status: null,
}

function getBodyFromFilters(filters) {
  return {
    ...filters,
    contract_id: filters.contract_id || undefined,
    contract_status: filters?.contract_status?.value,
    client_invoice_status: filters?.client_invoice_status?.value,
    partner_invoice_status: filters?.partner_invoice_status?.value,
  }
}

function EorInvoices() {
  const [pageFilters, setPageFilters] = useState(defaultFilters)

  const contractsInvoices = useFetch(
    {
      action: getContractsInvoicesList,
      withAdminAccess: true,
      autoFetch: true,
      // eslint-disable-next-line no-console
      onError: console.error,
      body: getBodyFromFilters(pageFilters),
    },
    [pageFilters],
  )

  function refreshData() {
    contractsInvoices.startFetch(getBodyFromFilters(pageFilters))
  }

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_INVOICE_GENERATOR,
  )

  function handleResetFilters() {
    setPageFilters(defaultFilters)
  }

  function handleChange(changeValue, options) {
    const name = options?.name ?? changeValue.target.name
    const value = options?.name ? changeValue : changeValue.target.value

    setPageFilters((prev) => ({
      ...prev,
      [name]: value,
      page: name === 'page' ? value : 1,
    }))
  }

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Invoices</h1>
        <Row className='m-0 p-0 mt-3'>
          <Col className='p-0 m-0'>
            <Card>
              <Container fluid>
                <Row className='px-2 py-3' style={{ gap: '0.75rem 0' }}>
                  <Col md={4} xs={12} className='px-2'>
                    <FormGroup className='mb-0'>
                      <Label>Month</Label>
                      <CustomDatePicker
                        showMonthYearPicker
                        placeholder='Filter by month'
                        dateFormat={dateFormat}
                        value={pageFilters.month}
                        handleOnChange={(v) => {
                          handleChange(format(v, dateFormat), {
                            name: 'month',
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12} className='px-2'>
                    <FormGroup className='mb-0'>
                      <Label>Contract ID</Label>
                      <Input
                        placeholder='Search by contract id'
                        name='contract_id'
                        value={pageFilters.contract_id}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12} className='px-2'>
                    <FormGroup className='mb-0'>
                      <Label>Contract status</Label>
                      <CustomSelect
                        options={contractStatusOptions}
                        placeholder='Filter by contract status'
                        name='contract_status'
                        value={pageFilters.contract_status}
                        onChange={handleChange}
                        isClearable
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12} className='px-2'>
                    <FormGroup className='mb-0'>
                      <Label>Partner invoice status</Label>
                      <CustomSelect
                        options={invoiceStatusOptions}
                        placeholder='Filter by partner invoice status'
                        name='partner_invoice_status'
                        value={pageFilters.partner_invoice_status}
                        onChange={handleChange}
                        isClearable
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12} className='px-2'>
                    <FormGroup className='mb-0'>
                      <Label>Client invoice status</Label>
                      <CustomSelect
                        options={invoiceStatusOptions}
                        placeholder='Filter by client invoice status'
                        name='client_invoice_status'
                        value={pageFilters.client_invoice_status}
                        onChange={handleChange}
                        isClearable
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12} className='align-items-end d-flex px-2'>
                    <Button onClick={handleResetFilters} type='button'>
                      Clear Filter
                    </Button>
                  </Col>
                </Row>
              </Container>

              {contractsInvoices.isLoading ? (
                <Loader minHeight='39rem' />
              ) : (
                <>
                  <Table
                    responsive
                    className='table-centered table-nowrap text-muted'
                  >
                    <thead>
                      <tr>
                        <th className='border-top-0'>Contract ID</th>
                        <th className='border-top-0'>Title</th>
                        <th className='border-top-0'>Employee</th>
                        <th className='border-top-0'>Employee start date</th>
                        <th className='border-top-0'>Client</th>
                        <th className='border-top-0'>Salary</th>
                        <th className='border-top-0'>Status</th>

                        <th className='border-top-0 bg-light p-1'></th>

                        <th className='border-top-0'>Partner name</th>
                        <th className='border-top-0'>Partner Invoice</th>
                        <th className='border-top-0'>Partner Total</th>

                        <th className='border-top-0 bg-light p-1'></th>

                        <th className='border-top-0'>Client Name</th>
                        <th className='border-top-0'>Client Total</th>
                        <th className='border-top-0'>Client Invoice</th>
                        <th className='border-top-0'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contractsInvoices?.data?.map((rawContract, key) => {
                        const formatter = getCurrencyFormatter(
                          rawContract.currency,
                        )
                        const salaryFormatter = getCurrencyFormatter(
                          rawContract.salary_currency,
                        )

                        const contract = Object.entries(rawContract).reduce(
                          (accu, curr) => {
                            const [key, value] = curr
                            const newValue = isNill(value) ? 'N/A' : value
                            return { ...accu, [key]: newValue }
                          },
                          {},
                        )

                        return (
                          <React.Fragment key={'_invoice_' + key}>
                            <tr>
                              <td>
                                <ContractRef
                                  contractId={contract?.ref}
                                  isAdmin
                                />
                              </td>
                              <td>
                                <div
                                  className='font-size-14 text-truncate'
                                  title={contract?.contract_name}
                                  style={{ maxWidth: 150 }}
                                >
                                  {contract?.contract_name}
                                </div>
                              </td>
                              <td>
                                <div
                                  className='text-truncate'
                                  title={contract?.employee_name}
                                >
                                  {contract?.employee_name}
                                </div>
                              </td>
                              <td>{contract.start_date}</td>
                              <td>
                                <div
                                  className='text-truncate'
                                  title={contract?.client_name}
                                >
                                  {contract?.client_name}
                                </div>
                              </td>
                              <td>
                                {salaryFormatter.format(contract?.salary)}
                              </td>
                              <td className='text-right'>
                                <BadgeX
                                  status={getStatusColor(
                                    contract.contract_status,
                                  )}
                                  name={contract.contract_status}
                                />
                              </td>

                              <td className='bg-light p-1' />

                              <td>{contract?.partner_name}</td>
                              <td>
                                <div
                                  className='d-flex align-items-baseline'
                                  style={{ gap: '0.5rem' }}
                                >
                                  <span
                                    className={`text-${
                                      invoiceStatus[
                                        contract?.partner_invoice_status
                                      ]
                                    }`}
                                  >
                                    {contract?.partner_invoice_status}
                                  </span>
                                  {contract?.missing_partner_invoice !==
                                  1 ? null : (
                                    <EorPartnerMissingInvoices
                                      contract={contract}
                                      onUpdate={refreshData}
                                    />
                                  )}
                                </div>
                              </td>
                              <td>
                                {contract?.partner_invoice_total === 'N/A'
                                  ? contract?.partner_invoice_total
                                  : formatter.format(
                                      contract?.partner_invoice_total,
                                    )}
                              </td>

                              <td className='bg-light p-1' />

                              <td>{contract?.client_name}</td>

                              <td>
                                {contract?.client_invoice_total === 'N/A'
                                  ? contract?.client_invoice_total
                                  : formatter.format(
                                      contract?.client_invoice_total,
                                    )}
                              </td>

                              <td>
                                <ClientInvoiceStatusColumn
                                  rawContract={rawContract}
                                  hasEditPermission={hasEditPermission}
                                  refreshData={refreshData}
                                />
                              </td>
                              <td className='text-center'>
                                <ClientActions
                                  rawContract={rawContract}
                                  refreshData={refreshData}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                  </Table>

                  <div className='d-flex justify-content-end p-4'>
                    <Pagination
                      // pagination class is required, because innerClass overrides it
                      innerClass='pagination mb-0'
                      activePage={pageFilters.page}
                      itemsCountPerPage={contractsInvoices.paginator?.per_page}
                      totalItemsCount={contractsInvoices.paginator?.total ?? 0}
                      onChange={(v) => handleChange(v, { name: 'page' })}
                    />
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

function EorPartnerMissingInvoices({ contract, onUpdate }) {
  const [invoiceData, setInvoiceData] = useState(null)

  function toggle() {
    setInvoiceData(null)
  }

  const { startFetch: updateAdminInvoice, isLoading: updatingInvoice } =
    useFetch({
      action: updateAdminEorPartnerInvoice,
      withAdminAccess: true,
      checkSuccess: true,
      onComplete: () => {
        toggle()
        onUpdate()
        toastr.success('Invoice added successfully')
      },
      onError: (error) => {
        toastr.error(error, 'error')
      },
    })

  function confirm(formData) {
    formData.append('partner_invoice_id', contract?.partner_invoice_id)
    updateAdminInvoice(formData)
  }

  return (
    <>
      <Button size='xs' onClick={() => setInvoiceData(contract)}>
        Add invoice
      </Button>
      <EorPartnerInvoiceModal
        isEditing
        isOpen={!!invoiceData}
        toggle={() => setInvoiceData(null)}
        onConfirm={confirm}
        isConfirmLoading={updatingInvoice}
      />
    </>
  )
}

export const CLIENT_INVOICE_STATUSES = {
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING_APPROVAL: 'Pending approval',
}

const clientInvoiceStatus = Object.values(CLIENT_INVOICE_STATUSES)

// const paymentStatus = {
//   Unpaid: 'warning',
//   Processing: 'info',
//   Paid: 'success',
//   NA: 'secondary',
// }

const invoiceStatus = {
  // partner_invoice_status
  'Pending submission': 'warning',
  'Request approval': 'info',
  Approved: 'success',
  Rejected: 'danger',

  // client_invoice_status
  'Pending approval': 'info',
  Declined: 'danger',
}

function ClientInvoiceStatusColumn({ rawContract, hasEditPermission }) {
  if (!rawContract.partner_invoice_id) {
    return <span className='text-muted'>N/A</span>
  }

  const statusElement = (
    <div className={`text-${invoiceStatus[rawContract.client_invoice_status]}`}>
      {rawContract.client_invoice_status}
    </div>
  )

  if (!hasEditPermission) {
    return statusElement
  }

  const resubmitting =
    rawContract?.client_invoice_status === CLIENT_INVOICE_STATUSES.DECLINED

  if (
    rawContract?.client_invoice_status ===
    CLIENT_INVOICE_STATUSES.PENDING_APPROVAL
  ) {
    return (
      <div className='align-items-center d-flex gap-8'>{statusElement}</div>
    )
  }

  if (
    clientInvoiceStatus.includes(rawContract?.client_invoice_status) &&
    !resubmitting
  ) {
    return statusElement
  }

  const tooltipId = `tooltip-${rawContract?.client_invoice_id}`

  return (
    <>
      <div className='tw-mb-1 tw-flex tw-items-center tw-gap-2'>
        {!resubmitting ? null : statusElement}
        {!rawContract?.client_invoice_rejection_reason ? null : (
          <>
            <span
              id={tooltipId}
              className='tw-cursor-pointer tw-rounded-full tw-bg-surface-30 tw-p-1'
            >
              <Chat />
            </span>

            <UncontrolledTooltip target={tooltipId}>
              {rawContract?.client_invoice_rejection_reason}
            </UncontrolledTooltip>
          </>
        )}
      </div>
      <Link
        to={{
          pathname: '/admin/invoice-generator',
          state: { ...rawContract, resubmitting },
        }}
      >
        <Badge
          className={`py-1 font-size-14 rounded bg-${
            resubmitting ? 'warning' : 'primary'
          } text-white`}
        >
          {resubmitting ? 'Re-submit' : 'Add Invoice'}
        </Badge>
      </Link>
    </>
  )
}

function ClientInvoiceRevertModal({
  isOpen,
  setIsOpen,
  rawContract,
  refreshData,
}) {
  const clientInvoiceId = rawContract?.client_invoice_id

  function toggle() {
    setIsOpen((o) => !o)
  }

  const { startFetch: revertInvoice, isLoading: revertingInvoice } = useFetch({
    action: revertAdminEorClientInvoice,
    withAdminAccess: true,
    onComplete: () => {
      setIsOpen(false)
      toastr.success('Invoice reverted successfully')
      refreshData?.()
    },
  })

  function handleRevertInvoice() {
    revertInvoice({ client_invoice_id: clientInvoiceId })
  }

  const formatter = getCurrencyFormatter(rawContract.currency)

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Revert client invoice</ModalHeader>
      <ModalBody className='font-size-16'>
        <p className='text-gray-h'>
          Are you sure you want to revert this client invoice?
        </p>
        <ul className='d-flex flex-column gap-4 p-0'>
          {[
            { label: 'From partner', value: rawContract.partner_name },
            { label: 'To client', value: rawContract.client_name },
            { label: 'About contract', value: rawContract.contract_name },
            {
              label: 'With a client total of',
              value: formatter.format(rawContract.client_invoice_total),
            },
          ].map(({ label, value }, index) => {
            return (
              <li key={index} className='d-flex align-items-baseline gap-14'>
                <span
                  className='text-muted text-right font-size-14'
                  style={{ width: 140 }}
                >
                  {label}
                </span>
                <span className='text-gray-h'>&#9;&#9;{value}</span>
              </li>
            )
          })}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          type='button'
          onClick={toggle}
          disabled={revertingInvoice}
        >
          Cancel
        </Button>
        <Button
          color='danger'
          type='button'
          loading={revertingInvoice}
          disabled={revertingInvoice}
          onClick={handleRevertInvoice}
        >
          Revert
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ClientActions({ rawContract, refreshData }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { startFetch: downloadInvoice, isLoading: isDownloadingInvoice } =
    useFetch({
      action: downloadEorInvoice,
      withAdminAccess: true,
      onComplete(data) {
        if (data?.success === false || data?.type !== 'application/pdf') {
          toastr.error('Failed to download invoice')
        } else {
          openFile(
            data,
            'eor-client-invoice-' + new Date().toISOString(),
            '.pdf',
          )
        }
      },
    })

  function handleDownloadInvoice() {
    downloadInvoice({ id: rawContract.client_invoice_id })
  }

  const clientPendingApproval =
    rawContract?.client_invoice_status ===
    CLIENT_INVOICE_STATUSES.PENDING_APPROVAL

  const options = [
    !rawContract.client_invoice_id
      ? null
      : {
          label: 'Download client invoice',
          onClick: handleDownloadInvoice,
          disabled: isDownloadingInvoice,
        },
    !clientPendingApproval
      ? null
      : {
          label: 'Revert Invoice',
          className: 'tw-text-red-100',
          onClick: () => setIsModalOpen(true),
        },
  ]

  if (options.length <= 0) return null

  return (
    <>
      <ActionsDropdown data={options} />

      <ClientInvoiceRevertModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        rawContract={rawContract}
        refreshData={refreshData}
      />
    </>
  )
}

export default EorInvoices
