import {
  CalendarCheck,
  CalendarPlus,
  Coin,
  DownloadSimple,
  Eye,
  Info,
  Invoice,
  ListChecks,
  ListDashes,
  Money,
  Notebook,
  X,
} from '@phosphor-icons/react'
import { differenceInDays, differenceInHours, format } from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import toastr from 'toastr'

import { cn } from 'ui'
import { BILL_STATUS, BillInfoText } from '.'
import ApprovalOverride from '../../../components/approval-override'
import ConfirmActionButton from '../../../components/confirm-action-button'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  sideMenuGridClass,
  SideMenuHeader,
  SideMenuHeading,
} from '../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approveBill,
  declineBill,
  deleteBill,
} from '../../../services/api-bill-payments'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { rpFormatDate } from '../../../utils/formatters/date-picker-date-format'
import { formatDays, formatHours } from '../../../utils/formatters/format-days'
import { TimelineType2 } from '../../Contract/ContractPage/item-timeline'
import { FormSectionHr } from '../../Contract/CreateContract/components/form-section-title'
import { DetailsInfoList } from '../../review-center/review-layout-details-components'
import { DetailSectionTitle, VendorBankDetails } from '../detail-section-title'

const PAYMENT_STATUS = {
  UNPAID: 'unpaid',
  PROCESSING: 'processing',
  PAID: 'paid',
  APPROVED: 'approved',
  MISSING_VENDOR_DETAILS: 'missing vendor details',
  MISSING_BANK_DETAILS: 'missing bank details',
  DECLINED: 'declined',
}

export const getBillStatusBadgeColor = (status = '') => {
  switch (status.toLowerCase()) {
    case PAYMENT_STATUS.UNPAID:
    case PAYMENT_STATUS.MISSING_VENDOR_DETAILS:
    case PAYMENT_STATUS.MISSING_BANK_DETAILS:
    case PAYMENT_STATUS.DECLINED:
      return {
        label: status,
        className: '!tw-bg-systemRed-20 !tw-text-systemRed-110',
      }
    case PAYMENT_STATUS.PAID:
    case BILL_STATUS.COMPLETED:
    case PAYMENT_STATUS.APPROVED:
      return {
        label: status,
        className: '!tw-bg-systemGreen-20 !tw-text-systemGreen-110',
      }
    case PAYMENT_STATUS.PROCESSING:
    default:
      return {
        label: status,
        className: '!tw-bg-systemGold-20 !tw-text-systemGold-110',
      }
  }
}

export const BillStatusBadge = ({ status }) => (
  <BadgeV2
    className={getBillStatusBadgeColor(status).className}
    name={getBillStatusBadgeColor(status).label}
  />
)

export function BillDueDate({ date, titleClassName }) {
  const dayDiff = differenceInDays(new Date(date), new Date())
  const isPast = dayDiff < 0
  const className = { 'tw-text-systemRed': isPast }

  return (
    <BillInfoText
      title={
        <span className={cn(className)}>
          {rpFormatDate(date, 'yyyy-MM-dd', 'dd/MM/yyyy')}
        </span>
      }
      subTitle={<DueInText date={date} className={className} />}
      className={cn('tw-text-left', className, titleClassName)}
    />
  )
}

export function DueInText({ date, className }) {
  const dateObj = new Date(date)
  const today = new Date()

  const dayDiff = differenceInDays(dateObj, today)
  const hourDiff = dayDiff === 0 ? differenceInHours(dateObj, today) : 0

  const isPast = dayDiff < 0

  return (
    <span className={className}>
      {isPast
        ? `${dayDiff === 0 ? formatHours(Math.abs(hourDiff)) : formatDays(Math.abs(dayDiff))} overdue`
        : `Due in ${dayDiff === 0 ? formatHours(Math.abs(hourDiff)) : formatDays(dayDiff)}`}
    </span>
  )
}

const FooterLeft = ({ tab, bill, onComplete, toggle }) => {
  const [showDeclineConfirm, setShowDeclineConfirm] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [reason, setReason] = useState('')

  const { isLoading: deletingBill, startFetch: _deleteBill } = useFetch({
    action: deleteBill,
    onComplete: () => {
      toastr.success(`Bill "${bill.name}" successfully deleted.`)
      setShowDeleteConfirm()
      onComplete()
    },
    onError: (error) => toastr.error(error),
  })

  const { isLoading: decliningBill, startFetch: _declineBill } = useFetch({
    action: declineBill,
    onComplete: () => {
      toastr.success(`Bill declined successfully.`)
      setShowDeclineConfirm(false)
      onComplete()
    },
    onError: (error) => toastr.error(error),
  })

  if (tab === BILL_STATUS.DRAFT) {
    return (
      <ConfirmActionButton
        buttonLabel='Delete'
        className='!tw-px-0 !tw-text-systemRed'
        title='Delete Bill'
        message='Are you sure you want to delete this bill?'
        onConfirm={() => _deleteBill({ id: bill.id })}
        isLoading={deletingBill}
        toggle={() =>
          setShowDeleteConfirm((showDeleteConfirm) => !showDeleteConfirm)
        }
        isOpen={showDeleteConfirm}
      />
    )
  }

  if (tab === BILL_STATUS.PENDING) {
    return (
      <ConfirmActionButton
        buttonLabel='Decline'
        className='!tw-bg-systemRed !tw-text-white'
        title='Decline Reason'
        content={
          <textarea
            className='form-control'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        }
        onConfirm={() => _declineBill({ id: bill.id, reason })}
        isLoading={decliningBill}
        toggle={() =>
          setShowDeclineConfirm((showDeclineConfirm) => !showDeclineConfirm)
        }
        isOpen={showDeclineConfirm}
        caption='Decline'
        buttonColor='danger'
        confirmDisabled={!reason}
      />
    )
  }

  if (tab === BILL_STATUS.READY) {
    return (
      <Button
        color='transparent'
        icon={<X size={20} />}
        className='!tw-px-0 !tw-text-systemRed'
        onClick={toggle}
      >
        Cancel
      </Button>
    )
  }
}

const FooterRight = ({ tab, bill, onComplete, onPayClick, canPay }) => {
  const { isLoading: approvingBill, startFetch: _approveBill } = useFetch({
    action: approveBill,
    onComplete: () => {
      toastr.success('Bill approved successfully.')
      onComplete()
    },
    onError: (error) => toastr.error(error),
  })

  if (tab === BILL_STATUS.PENDING) {
    return (
      <Button
        onClick={() => _approveBill({ id: bill.id })}
        className='!tw-bg-systemGreen !tw-text-white'
        loading={approvingBill}
        disabled={approvingBill}
      >
        Approve
      </Button>
    )
  }

  if (tab === BILL_STATUS.READY && canPay) {
    return (
      <Button icon={<Money size={20} />} onClick={onPayClick}>
        Pay
      </Button>
    )
  }
}

const showFooter = ({
  isNextApprover,
  showOverride,
  canManageBills,
  activeTab,
  userHasApproved,
}) => {
  if (activeTab.key === BILL_STATUS.COMPLETED) {
    return false
  }
  if (activeTab.key === BILL_STATUS.PENDING) {
    if (userHasApproved) {
      return false
    }
    return (isNextApprover || showOverride) && canManageBills
  }
  return true
}

export default function BillDetailsButton({
  bill,
  fetchBills,
  activeTab,
  onPayClick,
  activeItem,
  showItem = () => {},
  closeItem = () => {},
  approvalFlow = {},
}) {
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()
  const [actionOverridden, setActionOverridden] = useState(false)
  const {
    currency,
    name,
    vendor,
    created_at: createdAt,
    due_date: dueDate,
    amount,
    file,
    items,
    creator_id: creatorId,
    creator_name: creatorName,
    category,
    timeline,
    next_approver: nextApprover,
  } = bill
  const formatter = getCurrencyFormatter(currency.code)
  const iconStyle = 'tw-fill-text-60 tw-size-6'
  const isActive = activeItem === name
  const userHasApproved = timeline?.find(
    (item) => item.status === 'approved' && item.actor_id === user.id,
  )
  const canOverride = approvalFlow.id
    ? approvalFlow.steps?.find((step) => step.can_override)?.user_id === user.id
    : undefined
  const isNextApprover = nextApprover?.id === user.id

  return (
    <>
      <Button
        color='link'
        onClick={showItem}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        Details
      </Button>

      {isActive && (
        <SideMenu
          isOpen
          onClose={closeItem}
          itemListClassName={sideMenuGridClass()}
        >
          <SideMenuHeader toggle={closeItem}>
            <SideMenuHeading
              title={
                <>
                  <span>{name}</span>
                  <BillStatusBadge status={activeTab.label} />
                </>
              }
              subTitle={vendor?.name}
            />
          </SideMenuHeader>

          <SideMenuBody>
            <DetailsInfoList
              items={[
                {
                  label: 'Created on',
                  value: (
                    <BillInfoText
                      title={format(new Date(createdAt), 'dd/MM/yyyy')}
                      subTitle={
                        creatorName &&
                        `By ${creatorName} ${user.id === creatorId ? '(You)' : ''}`
                      }
                    />
                  ),
                  icon: <CalendarPlus className={iconStyle} />,
                },
                {
                  icon: <Notebook className={iconStyle} />,
                  label: 'Category',
                  value: <BillInfoText title={category?.name ?? 'N/A'} />,
                },
                {
                  label: 'Due date',
                  value: (
                    <BillDueDate
                      date={dueDate}
                      titleClassName='tw-text-right'
                    />
                  ),
                  icon: <CalendarCheck className={iconStyle} />,
                },
                {
                  label: 'Amount',
                  value: <BillInfoText title={formatter.format(amount)} />,
                  icon: <Money className={iconStyle} />,
                },
                {
                  label: 'Currency',
                  value: (
                    <BillInfoText
                      title={`${currency.code} - ${currency.name} (${currency.symbol})`}
                    />
                  ),
                  icon: <Coin className={iconStyle} />,
                },
              ]}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title='Details'
                  icon={<Info size={20} className='tw-mr-2 tw-fill-primary' />}
                  className='tw-mb-2'
                />
              }
            />
            <FormSectionHr className='tw-my-6' />

            {file && (
              <>
                <DetailsInfoList
                  items={[
                    {
                      label: (
                        <span className='tw-flex tw-items-center'>
                          <img
                            src={file.thumbnail}
                            alt='invoice'
                            className='tw-mr-4 tw-size-12 tw-rounded tw-border tw-border-surface-30'
                          />
                          <BillInfoText
                            title='Invoice'
                            subTitle='invoice12_RT.PDF'
                            className='tw-text-left'
                          />
                        </span>
                      ),
                      value: (
                        <Button
                          iconRight={
                            <DownloadSimple className='tw-ml-2' size={20} />
                          }
                          color='link'
                          className='!tw-px-0'
                          target='_blank'
                          rel='noreferrer'
                          download
                          tag='a'
                          href={file.pdf}
                        >
                          Download
                        </Button>
                      ),
                    },
                  ]}
                  className='tw-p-0'
                  title={
                    <DetailSectionTitle
                      title='Files'
                      className='tw-gap-2'
                      icon={<Invoice size={20} className='tw-fill-primary' />}
                    />
                  }
                />
                <FormSectionHr className='tw-my-6' />
              </>
            )}

            <DetailsInfoList
              items={items.map((item) => ({
                label: (
                  <BillInfoText
                    title={item.description}
                    subTitle={category?.name}
                    className='tw-text-wrap tw-text-left'
                  />
                ),
                className: 'tw-flex-nowrap',
                valueClassName: 'tw-flex-shrink-0',
                value: (
                  <BillInfoText
                    title={`${currency.symbol} ${item.amount}`}
                    subTitle='Amount'
                  />
                ),
              }))}
              title={
                <DetailSectionTitle
                  title='Line items'
                  icon={
                    <ListDashes size={20} className='tw-mr-2 tw-fill-primary' />
                  }
                />
              }
              className='tw-p-0'
            />
            <FormSectionHr className='tw-my-6' />

            {bill.vendor?.bank_account && (
              <>
                <VendorBankDetails
                  bankInfo={bill.vendor?.bank_account}
                  vendor={vendor}
                />
                <FormSectionHr className='tw-my-6' />
              </>
            )}

            <TimelineType2
              flowTimeline={timeline}
              title={
                <span className='tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black'>
                  <ListChecks size={20} className='tw-mr-2 tw-fill-primary' />
                  Approvals
                </span>
              }
              rightWrapperClassName='tw-text-right'
            />

            {canOverride &&
              activeTab.key === BILL_STATUS.PENDING &&
              !isNextApprover && (
                <ApprovalOverride
                  shouldOverride={actionOverridden}
                  onOverride={(event) =>
                    setActionOverridden(event.target.checked)
                  }
                />
              )}
          </SideMenuBody>

          {showFooter({
            isNextApprover,
            showOverride:
              canOverride &&
              actionOverridden &&
              activeTab.key === BILL_STATUS.PENDING,
            canManageBills:
              hasAccess(permissions.ManageAllBills) ||
              hasAccess(permissions.ManageOwnBills),
            activeTab,
            userHasApproved,
          }) && (
            <SideMenuFooter>
              <div className='tw-mr-auto'>
                <FooterLeft
                  tab={activeTab.key}
                  bill={bill}
                  onComplete={() => {
                    closeItem()
                    fetchBills()
                  }}
                  toggle={closeItem}
                />
              </div>
              {(hasAccess(permissions.ManageAllBills) ||
                timeline?.[0]?.actor_id === user.id) &&
                activeTab.key === BILL_STATUS.DRAFT && (
                  <Button
                    tag={Link}
                    to={`/bills/edit/${bill.id}`}
                    color='light'
                    outline
                  >
                    Edit
                  </Button>
                )}

              <FooterRight
                tab={activeTab.key}
                bill={bill}
                onComplete={() => {
                  closeItem()
                  fetchBills()
                }}
                onPayClick={onPayClick}
                canPay={hasAccess(permissions.PrepareTransactions)}
              />
            </SideMenuFooter>
          )}
        </SideMenu>
      )}
    </>
  )
}
