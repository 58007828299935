import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

export function PermissionTooltip({
  id,
  children,
  showing,
  className,
  placement = 'bottom',
  area = 'this area',
  customMessage,
  action = 'manage',
}) {
  if (!id || !showing) return children

  const _message =
    customMessage ??
    `You do not have permission to${
      action !== '' ? ' ' + action : ''
    } ${area}. Please contact your administrator.`

  return (
    <>
      <div className={className} id={id}>
        {children}
      </div>

      <UncontrolledTooltip
        className={className}
        placement={placement}
        target={id}
      >
        {_message}
      </UncontrolledTooltip>
    </>
  )
}
