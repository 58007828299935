import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import Button from '../../../../components/ui/button'
import {
  SideMenu,
  sideMenuGridClass,
  SideMenuHeading,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { withdrawCurrencies } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import { AddBankAccountV2Form } from '../../../withdrawProcess/add-bank-account-v2'
import {
  getBankAccountData,
  useCreateVendorBankAccount,
  validateBankAccountData,
} from '../../../withdrawProcess/use-bank-account-data'

const addBankAccountFormId = 'addBankAccountFormId'
export function AddBankSideMenu({ isOpen, toggle, vendor, updateVendors }) {
  const user = useSelector((state) => state.Account?.user)

  const profileCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )

  const { data: currencies } = useFetch(
    {
      action: withdrawCurrencies,
      autoFetch: isOpen && !!profileCurrency,
      body: { currency: profileCurrency },
      initResult: [],
    },
    [profileCurrency, isOpen],
  )

  const formMethods = useForm()
  const { handleSubmit, control, setError, clearErrors } = formMethods

  const { createBankAccount, isLoading: isCreatingBankAccount } =
    useCreateVendorBankAccount({
      successMessage: [
        `You successfully edited the bank details for ${vendor?.name}`,
        'Bank details added',
      ],
      onSuccess: () => {
        toggle?.()
        updateVendors?.()
      },
    })

  if (!isOpen) return null

  function onSubmit(values) {
    const isValid = validateBankAccountData({ values, setError, clearErrors })

    if (!isValid) {
      toastr.error('Please check field errors')
      return
    }

    const bankAccountData = getBankAccountData(values, {
      user: { firstName: user?.first_name, lastName: user?.last_name },
    })

    createBankAccount({ ...bankAccountData, vendorId: vendor?.id })
  }

  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName={sideMenuGridClass()}
    >
      <SideMenu.Header toggle={toggle}>
        <SideMenuHeading
          title='Bank details'
          subTitle='Edit vendor bank details'
          onClickBack={toggle}
        />
      </SideMenu.Header>
      <SideMenu.Body>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} id={addBankAccountFormId}>
            <AddBankAccountV2Form
              control={control}
              currency={profileCurrency}
              currencies={currencies?.map((c) => mapCurrencyToOption(c))}
              className='tw-pt-2'
              fieldsClassName='!tw-grid-cols-1'
              isBillVendor
            />
          </form>
        </FormProvider>
      </SideMenu.Body>
      <SideMenu.Footer className='tw-sticky tw-bottom-0'>
        <Button
          type='button'
          onClick={toggle}
          outline
          color='light'
          disabled={isCreatingBankAccount}
        >
          Cancel
        </Button>
        <Button
          formId={addBankAccountFormId}
          type='submit'
          disabled={isCreatingBankAccount}
          loading={isCreatingBankAccount}
        >
          Save
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}
