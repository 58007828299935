import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../components/ui/button'
import PageHeading from '../../components/ui/page-heading'

export function AllPaymentActions({
  onPayAedPayments,
  formattedTotalToPay,
  rawTotalToPay,
  showAction = true,
  showAedPayments = false,
}) {
  const history = useHistory()

  const isZero = rawTotalToPay === 0

  return !showAction ? null : (
    <PageHeading.Action>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3'>
        <Button
          disabled={isZero}
          type='button'
          onClick={() => {
            history.push('/pay-invoices')
          }}
        >
          {isZero ? 'Select payments to pay' : `Pay ${formattedTotalToPay}`}
        </Button>

        {!showAedPayments ? null : (
          <Button
            type='button'
            color='link'
            className='!tw-px-0'
            onClick={() => {
              onPayAedPayments?.()
            }}
          >
            Process AED payments separately
          </Button>
        )}
      </div>
    </PageHeading.Action>
  )
}
