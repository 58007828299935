export const userTypes = {
  CONTRACTOR: 'contractor',
  COMPANY: 'client',
}

export const clientRole = {
  ACCOUNTANT: 'Accountant',
  MANAGER: 'Manager',
  ADMIN: 'Admin',
  HR: 'HR',
}

export const contractTypes = {
  FIXED: 'FIXED',
  PAYG: 'PAYG',
  MILESTONES: 'MILESTONES',
  FULL_TIME: 'FULL_TIME',
  DIRECT_EMPLOYEE: 'DIRECT_EMPLOYEE',
}
export const contractorTypes = {
  INDIVIDUAL: 'individual',
  ENTITY: 'entity',
}

export const CONTRACT_STATUS = {
  PENDING_INVITE: {
    value: 1,
    label: 'Pending invite',
  },
  PENDING_CONTRACTOR_SIGNATURE: {
    value: 2,
    label: 'Pending contractor signature',
  },
  PENDING_CLIENT_SIGNATURE: {
    value: 3,
    label: 'Pending client signature',
  },
  ONGOING: {
    value: 4,
    label: 'Ongoing',
  },
  ENDED: {
    value: 5,
    label: 'Ended',
  },
  CANCELLED: {
    value: 6,
    label: 'Cancelled',
  },
  TERMINATED: {
    value: 7,
    label: 'Terminated',
  },
  AMENDED: {
    value: 8,
    label: 'Amended',
  },
}

export const BE_CONTRACT_CATEGORY = {
  DIRECT_EMPLOYEE: 'direct-employee',
  EMPLOYEE: 'employee',
  INDIVIDUAL: 'individual',
  ENTITY: 'entity',
}

export const CONTRACT_CATEGORY = {
  CONTRACTOR: 'CONTRACTOR',
  EMPLOYEE: 'EMPLOYEE',
}

export const EMPLOYEE_CONTRACT_TYPE = {
  EOR: 'EOR',
  DIRECT_EMPLOYEE: 'DIRECT_EMPLOYEE',
}

export const CONTRACT_DOC_TYPE = {
  RP_TEMPLATE: 'RP_TEMPLATE',
  CUSTOM: 'CUSTOM',
  CUSTOM_TEMPLATE: 'CUSTOM_TEMPLATE',
}

export const DE_PAYMENT_PROVIDER = {
  RP_PROVIDER: 'RP_PROVIDER',
  EXTERNAL_PROVIDER: 'EXTERNAL_PROVIDER',
}

export const TERMINATE_CONTRACT_TYPE = {
  NOW: 'NOW',
  LATER: 'LATER',
}

export const CONTRACT_ACTIVITIES = {
  TERMINATION_SCHEDULED: 'Termination Scheduled',
  SCHEDULED_TERMINATION_CANCELLED: 'Scheduled Termination Cancelled',
  SOW_SIGNED: 'SOW Signed',
  CONTRACT_CREATED: 'Contract Created',
  COMPLIANCE_COMPLETED: 'Compliance Completed',
  CONTRACTOR_SIGNED: 'Contractor Signed',
  CONTRACT_TERMINATED: 'Contract Terminated',
  CONTRACT_AMENDED: 'Contract Amended',
  CONTRACT_CANCELLED: 'Contract Cancelled',
  DEPOSIT_PAID: 'Deposit Paid',
  CLIENT_SIGNED: 'Client Signed',
  CONTRACT_ENDED: 'Contract Ended',
  // Fulltime contracts activities
  EMPLOYEE_ONBOARDED: 'Employee Onboarded',
  EMPLOYEE_INVITED: 'Employee Invited',
  PROVIDER_SIGNED: 'Provider Signed',

  TERMINATION_REQUESTED: 'Termination Requested',
  TERMINATION_REQUEST_CANCELLED: 'Termination Request Cancelled',
  TERMINATION_REQUEST_REJECTED: 'Termination Request Rejected',
  TERMINATION_REQUEST_APPROVED: 'Termination Request Approved',
}
