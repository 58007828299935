import React from 'react'
import { Modal } from 'reactstrap'
import { WarningOctagon, X } from '@phosphor-icons/react'
import IconButton from '../../../components/ui/icon-button'

const CancelAmendmentModal = ({ isOpen, toggle, onAgree }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-header tw-min-h-[68px]'>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
          <h5 className='tw-mb-0 tw-pl-3 tw-text-[20px] tw-font-semibold'>
            You have unsaved changes
          </h5>
          <IconButton
            outline
            color='transparent'
            circle
            size={34}
            onClick={toggle}
            icon={<X size={24} />}
          />
        </div>
      </div>
      <div className='modal-content'>
        <div className='tw-flex tw-flex-col tw-gap-4 tw-p-6'>
          <WarningOctagon size={40} weight='duotone' color='var(--gold-100)' />

          <p className='tw-mb-0 tw-text-sm'>
            If you close this dialog, your changes will not be saved.
          </p>
        </div>
        <div className='modal-footer p-3 p-md-4'>
          <button
            type='button'
            className='btn btn-outline-light'
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            data-dismiss='modal'
            onClick={onAgree}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CancelAmendmentModal
