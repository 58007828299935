import cx from 'classnames'
import Quill from 'quill'
import 'quill-mention'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'

import TagSelector from '../ContractEditor/TagSelector'
import Editable from './Editable'
import Toolbar from './Toolbar'

const Inline = Quill.import('blots/inline')

class SpanBlock extends Inline {
  static create(value) {
    const node = super.create()
    node.setAttribute('class', 'tag')
    return node
  }
}

SpanBlock.blotName = 'tag'
SpanBlock.tagName = 'label'
Quill.register(SpanBlock)

const Editor = ({
  sections,
  agreement,
  placeholders,
  onChange,
  logo,
  onEdit,
  hideToolbar,
  hideSidebar,
  sideBarTopElement,
  noCounter,
  readOnly,
  onlyOneSection,
  stepsLayout,
}) => {
  // The div containing the quill editor when no instance of Editable is using it.
  const quillEditorContainerTempHolder = useRef()
  // The div that contains the quill toolbar.
  const quillToolbarContainer = useRef()
  // The quill editor
  const quillEditorContainer = useRef()
  // The quill instance
  const quillInstance = useRef()
  // The data for each Editable, that is also persisted in local storage
  const [editables, setEditables] = useState(sections)
  // Derive a list of editables from the editables object
  // const editablesList = Object.keys(editables).map((key) => editables[key])
  // The currently active editable, the one we're manipulating with quill
  const [activeEditable, setActiveEditable] = useState()
  const [activeEditableKey, setActiveEditableKey] = useState()

  const tags = useSelector((state) => state.Layout?.staticData?.template_tags)
  const expandedTags = tags
    ?.reduce((acc, cur) => [...acc, ...cur.tags], [])
    .map((e, i) => ({ id: i, value: `<label class="tag">${e}</label>` }))

  useEffect(() => {
    setEditables(sections)
  }, [sections])
  /**
   * Instantiate the quill editor, using the quillEditorContainer as
   * the element for it. Also, use our own toolbar.
   */
  useEffect(() => {
    // Store the quill instance for future use
    quillInstance.current = new Quill(quillEditorContainer.current, {
      theme: 'snow',
      modules: {
        toolbar: quillToolbarContainer.current,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@'],
          source: function (searchTerm, renderList, mentionChar) {
            // renderList(tags, searchTerm)
            if (searchTerm.length === 0) {
              renderList(expandedTags, searchTerm)
            } else {
              const matches = []
              for (let i = 0; i < expandedTags.length; i++) {
                if (
                  ~expandedTags[i].value
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase())
                ) {
                  matches.push(expandedTags[i])
                }
              }
              renderList(matches, searchTerm)
            }
          },
          // eslint-disable-next-line react/display-name
          renderItem: (item, searchTerm) => {
            return `<label>${item?.value}</label>`
          },
        },
      },
    })
    quillEditorContainerTempHolder.current.appendChild(
      quillEditorContainer.current,
    )
    setActiveEditable(undefined)
  }, [])

  /**
   * Add event listeners to quill to update the active editable
   * when we type into the quill editor.
   */
  useEffect(() => {
    if (quillInstance.current && activeEditable) {
      const quill = quillInstance.current
      const onTextChange = () => {
        const newSections = [...editables]
        newSections[activeEditableKey].details =
          quill.container.firstChild.innerHTML
        setEditables(newSections)
        onEdit?.()
      }
      quill.on('text-change', onTextChange)
      return () => quill.off('text-change', onTextChange)
    }
  }, [quillInstance, activeEditable, editables, activeEditableKey, onEdit])

  useEffect(() => {
    onChange(editables)
  }, [editables, onChange])

  /**
   * An editable has told us it wants to activate/deactivate itself.
   *
   * On activate, update the contents of the quill editor to be the
   * contents of the activated editable, then set the current active
   * editable to the one making the request. Also, position the caret
   * of the quill editor to the end of the content.
   *
   * On deactivation, set the active editable to undefined and move
   * the quill editor DOM element back to the temp holder element.
   */
  const setEditableActive = (editable, activate, key) => {
    if (activate) {
      const quill = quillInstance.current
      /* const delta = quill.clipboard.convert(editable.details.includes('<li')
        ? `<ol>${editable.details}</ol>`
        : `<div>${editable.details}</div>`) */
      const delta = quill.clipboard.convert(editable.details)
      quill.setContents(delta, 'silent')
      setActiveEditable(editable)
      setActiveEditableKey(key)
      setTimeout(() => {
        quill.setSelection({ index: 0, length: quill.getLength() - 1 }, 'api')
      })
    } else {
      quillEditorContainerTempHolder.current.appendChild(
        quillEditorContainer.current,
      )
      setActiveEditable(undefined)
      setActiveEditableKey(-1)
    }
  }
  const moveUp = (key) => {
    setEditableActive(undefined, false, -1)
    const newSections = [...editables]
    const html = newSections[key - 1]
    newSections[key - 1] = newSections[key]
    newSections[key] = html
    setEditables(newSections)
  }

  const moveDown = (key) => {
    setEditableActive(undefined, false, -1)
    const newSections = [...editables]
    const html = newSections[key + 1]
    newSections[key + 1] = newSections[key]
    newSections[key] = html
    setEditables(newSections)
  }

  const deleteSection = (key) => {
    setEditableActive(undefined, false, -1)
    const newSections = [...editables]
    newSections.splice(key, 1)
    setEditables(newSections)
  }
  const handleTitleChanges = (title, key) => {
    const newSections = [...editables]
    newSections[key].name = title
    setEditables(newSections)
  }
  const handleAddSection = (key) => {
    const newSections = [...editables]
    const newSection = {
      name: 'Section default name',
      details: '<p></p>',
      is_editable: 1,
    }
    newSections.splice(key, 0, newSection)
    setEditables(newSections)
    setEditableActive(newSection, true, key)
  }

  function createMarkup(text) {
    return { __html: text }
  }

  return (
    <Container fluid>
      <Row className={hideSidebar ? 'mx-0' : undefined}>
        <Col
          className='bg-light p-0 position-relative'
          md={hideSidebar ? 12 : stepsLayout ? 9 : 10}
        >
          {hideToolbar ? null : (
            <Toolbar
              ref={quillToolbarContainer}
              quillInstance={quillInstance}
            />
          )}

          <div className='p-2 p-md-5'>
            <div
              id='template-content'
              className={cx('p-2 p-md-5', { 'no-counter': noCounter })}
              style={{ minHeight: '60vh', marginTop: hideToolbar ? 0 : 40 }}
            >
              {!logo ? null : <div className='ml-3'>{logo}</div>}

              {!agreement ? null : (
                <div
                  className='default'
                  dangerouslySetInnerHTML={createMarkup(agreement)}
                />
              )}

              <div className='editables-container'>
                {editables?.map((editable, key) => {
                  const isEditable = editable?.is_editable?.toString()
                  const localReadOnly = readOnly || isEditable !== '1'

                  return (
                    <React.Fragment key={'section-' + (key + 1)}>
                      {localReadOnly ? null : (
                        <div
                          className='d-flex px-3 justify-content-between'
                          style={{ minHeight: 24 }}
                        >
                          {onlyOneSection ? (
                            <div />
                          ) : (
                            <button
                              type='button'
                              className='rp-btn-nostyle text-primary'
                              onClick={() => handleAddSection(key)}
                              style={activeEditableKey === key ? {} : {}}
                            >
                              + Add section
                            </button>
                          )}

                          <div>
                            {key < editables.length - 1 && (
                              <button
                                type='button'
                                className='rp-btn-nostyle text-primary'
                                onClick={() => moveDown(key)}
                              >
                                <i className='bx bxs-down-arrow' />
                              </button>
                            )}
                            &nbsp;
                            {key > 0 && (
                              <button
                                type='button'
                                className='rp-btn-nostyle text-primary'
                                onClick={() => moveUp(key)}
                              >
                                <i className='bx bxs-up-arrow' />
                              </button>
                            )}
                            &nbsp;
                            {onlyOneSection ? null : (
                              <button
                                type='button'
                                className='rp-btn-nostyle text-primary'
                                onClick={() => deleteSection(key)}
                              >
                                <i className='bx bxs-trash-alt' />
                              </button>
                            )}
                            &nbsp;
                          </div>
                        </div>
                      )}

                      <ul
                        className={cx('section', {
                          'editable tw-border tw-border-transparent !tw-p-5':
                            activeEditableKey === key,
                          readOnly: localReadOnly,
                        })}
                      >
                        <EditableHeader
                          activeEditableKey={activeEditableKey}
                          parentEditableKey={key}
                          onEdit={() => setEditableActive(editable, true, key)}
                          defaultValue={editable.name}
                          onChanges={(e) => handleTitleChanges(e, key)}
                          readOnly={localReadOnly}
                        />
                        <Editable
                          editable={editable}
                          content={editable.details}
                          onChangeActive={(editable, active) => {
                            setEditableActive(editable, active, key)
                          }}
                          quillEditorContainer={quillEditorContainer}
                          isActive={activeEditableKey === key}
                          key={editable.id}
                          readOnly={localReadOnly}
                        />
                      </ul>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
        </Col>

        {hideSidebar ? null : (
          <Col
            className={cx('p-0 bg-white tags-container', {
              'position-relative h-100': stepsLayout,
            })}
            md={stepsLayout ? 3 : 2}
          >
            {!sideBarTopElement ? null : sideBarTopElement}
            <TagSelector
              placeholders={placeholders}
              onSelect={(tag, key) => {
                const mention = quillInstance.current.getModule('mention')
                if (mention) {
                  mention.insertItem(
                    { id: key, value: `<label class="tag">${tag}</label>` },
                    true,
                  )
                }
              }}
            />
          </Col>
        )}
      </Row>

      <div
        style={{ position: 'fixed', left: '100vw', top: 0 }}
        ref={quillEditorContainerTempHolder}
      >
        <div ref={quillEditorContainer} />
      </div>
    </Container>
  )
}
function EditableHeader({
  defaultValue,
  onChanges = () => {},
  onEdit = () => {},
  readOnly,
  parentEditableKey,
  activeEditableKey,
}) {
  const editing = activeEditableKey === parentEditableKey

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const classes = cx(
    'title-text tw-w-[calc(100%-40px)] tw-text-left tw-text-sm',
    editing &&
      'hover:tw-bg-primary-10 focus:tw-bg-primary-10 focus:tw-outline-none',
  )

  return editing ? (
    <input
      className={classes}
      defaultValue={value}
      value={value}
      onChange={(e) => {
        const v = e.target.value
        setValue(v)
        onChanges(v)
      }}
    />
  ) : (
    <button
      type='button'
      className={classes}
      onClick={readOnly ? null : onEdit}
    >
      {value}
    </button>
  )
}
export default Editor
