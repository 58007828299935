import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import Loader from '../../../../../components/ui/loader'
import PageHeading from '../../../../../components/ui/page-heading'
import Pagination from '../../../../../components/ui/pagination'
import { useFetch } from '../../../../../helpers/hooks'
import { getContractsListAdmin } from '../../../../../services/api'
import { CardsSectionHeader } from '../../../../remotepass-cards/components/section-heading'
import { useFilters } from '../../cards/use-filters'
import { CorContractList, CorFilters } from './cor-contract-list'

export const contractStatusKey = 'status_id'

function getBodyFromFilters(filters) {
  return {
    ...filters,
    [contractStatusKey]: filters?.[contractStatusKey]?.value,
    company_id: filters?.company_id?.value,
    archived: filters?.archived ? 1 : 0,
  }
}

export default function CorContracts() {
  const [filters, handleFiltersChange] = useFilters(
    {
      archived: false,
      is_cor: 1,
      // is_sow_rp_signed: 0,
      // is_sow_client_signed: 0,
    },
    { statusKey: contractStatusKey },
  )

  const contractStatuses = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses ?? [],
  )

  const {
    data,
    paginator,
    isLoading,
    startFetch: updateContractList,
  } = useFetch(
    {
      action: getContractsListAdmin,
      autoFetch: true,
      withAdminAccess: true,
      body: getBodyFromFilters(filters),
    },
    [filters],
  )

  function handleUpdate() {
    updateContractList(filters)
  }

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title>RemotePass+ / Contracts</PageHeading.Title>
      </PageHeading>

      <Card style={{ position: 'unset' }}>
        <CardsSectionHeader>
          <CorFilters
            filters={filters}
            onChange={handleFiltersChange}
            contractStatuses={contractStatuses.map((status) => ({
              label: status.name,
              value: status.id,
            }))}
            companyList={data?.companies?.map((company) => ({
              label: company.name,
              value: company.id,
            }))}
          />
        </CardsSectionHeader>

        <CardBody>
          {isLoading ? (
            <Loader minHeight='30rem' />
          ) : (
            <>
              <CorContractList
                contracts={data?.contracts}
                onUpdate={handleUpdate}
                isArchived={filters?.archived}
              />

              {!showPaginator ? null : (
                <div className='ml-auto pr-4 mt-3 w-100 d-flex justify-content-end'>
                  <Pagination
                    activePage={filters?.page}
                    itemsCountPerPage={paginator?.per_page ?? 10}
                    totalItemsCount={paginator?.total ?? 0}
                    onChange={(newPage) => handleFiltersChange('page', newPage)}
                  />
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
