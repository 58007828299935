import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
} from 'reactstrap'
import * as yup from 'yup'
import toastr from 'toastr'

import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import DropzoneInput from '../../../components/Common/dropzone-input'
import UploadPreview from '../../../components/Common/upload-preview'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import SimpleMessage from '../../../components/ui/simple-message'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import { format } from 'date-fns'
import {
  addDocumentRequestEmployee,
  adminAddDocumentRequest,
  deleteEmployeeDocumentAdmin,
  deleteEmployeeDocumentRequestAdmin,
  downloadEmployeeDocument,
  downloadEmployeeDocumentAdmin,
  getContractDetailAdmin,
  getEmployeeDocuments,
  getEmployeeDocumentsAdmin,
  uploadEmployeeDocument,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import { getFullName } from '../../../utils/get-full-name'
import ContractRef from '../../AdminPanel/components/ContractRef'
import TabCardHeader from '../../Contract/components/tab/tab-card-header'
import Pagination from '../../../components/ui/pagination'
import { ActionsDropdown } from 'ui'
import { useForm } from 'react-hook-form'
import IconButton from '../../../components/ui/icon-button'
import { Trash } from '@phosphor-icons/react'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import permissions from '../../../helpers/permissions'
import { toBase64 } from '../../../helpers/helper'

export default function EmployeeDocsList({ isAdmin, isContractOngoing }) {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [page, setPage] = useState(1)

  const {
    data: employeeDocuments,
    isLoading,
    paginator,
    startFetch: refreshData,
  } = useFetch(
    {
      action: isAdmin ? getEmployeeDocumentsAdmin : getEmployeeDocuments,
      autoFetch: true,
      withAdminAccess: isAdmin,
      body: { page, perPage: 40 },
    },
    [page],
  )

  function refreshDocuments() {
    return refreshData({ page, perPage: 40 }, false)
  }

  const location = useLocation()

  const { hasAccess } = usePermissions()

  const searchParams = new URLSearchParams(location.search)
  const contractRef = searchParams.get('id')

  const filteredEmployeeDocuments = useMemo(
    () =>
      !contractRef
        ? employeeDocuments
        : employeeDocuments?.filter((doc) => doc.contract_ref === contractRef),
    [employeeDocuments, contractRef],
  )

  const { isLoading: downloadingDocument, startFetch: downloadDocument } =
    useFetch({
      action: isAdmin
        ? downloadEmployeeDocumentAdmin
        : downloadEmployeeDocument,
      autoFetch: true,
      withAdminAccess: isAdmin,
      onComplete: (data, body) => {
        openFileV2(data, {
          download: true,
          name: `${body?.title}-${body?.request_id}.pdf`,
        })
      },
    })
  const { startFetch: deleteDocument } = useFetch({
    action: deleteEmployeeDocumentAdmin,
    withAdminAccess: isAdmin,
    onComplete: () => {
      refreshDocuments()
    },
  })
  function toggleRequestModal() {
    setIsRequestModalOpen((open) => !open)
  }
  function toggleUploadModal() {
    setIsUploadModalOpen((open) => !open)
  }

  function handleUploadEorDocument(rowData) {
    setIsUploadModalOpen(true)
    setSelectedItem(rowData)
  }
  const handleDownload = useCallback(
    function handleDownload(rowData) {
      downloadDocument({ request_id: rowData?.id, title: rowData?.title })
    },
    [downloadDocument],
  )
  const handleDelete = useCallback(
    function handleDelete(rowData) {
      deleteDocument({ request_id: rowData.id, title: rowData.title })
    },
    [deleteDocument],
  )

  const columns = useMemo(() => {
    return [
      !isAdmin
        ? null
        : {
            Header: 'Contract',
            accessor: 'contract_ref',
            Cell: ({ cellData }) => (
              <ContractRef contractId={cellData} isAdmin />
            ),
          },
      !isAdmin
        ? null
        : {
            Header: 'Full Name',
            accessor: 'user',
            Cell: ({ cellData }) => getFullName(cellData),
          },
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Requested on',
        accessor: 'requested_on',
        Cell: ({ cellData }) => format(new Date(cellData), 'dd/MM/yyyy HH:mm'),
      },
      {
        Header: 'Submitted by',
        accessor: 'submitted_by',
        Cell: ({ cellData }) => getFullName(cellData),
      },
      {
        Header: 'Submitted on',
        accessor: 'submitted_on',
        Cell: ({ cellData }) =>
          cellData ?? format(new Date(cellData), 'dd/MM/yyyy HH:mm'),
      },
      {
        Header: 'Actions',
        accessor: 'file',
        Cell: ({ cellData: file, rowData }) => {
          const options = [
            {
              label: 'Download',
              onClick: () => {
                handleDownload(rowData)
              },
            },
            ...(isAdmin
              ? [
                  {
                    label: 'Delete',
                    onClick: () => {
                      handleDelete(rowData)
                    },
                  },
                ]
              : []),
          ]
          if (!file) {
            if (isAdmin) {
              return (
                <div className='tw-flex tw-items-center tw-gap-2'>
                  <Button
                    type='button'
                    color='info'
                    outline
                    size='sm'
                    onClick={() => handleUploadEorDocument(rowData)}
                  >
                    Upload document
                  </Button>
                  <DeleteRequestButton
                    refreshDocuments={refreshDocuments}
                    isAdmin={isAdmin}
                    rowData={rowData}
                  />
                </div>
              )
            } else {
              return 'Document is being prepared'
            }
          } else {
            return isAdmin ? (
              <ActionsDropdown data={options} />
            ) : (
              <Button
                type='button'
                outline
                size='sm'
                disabled={downloadingDocument}
                onClick={() => handleDownload(rowData)}
              >
                Download
              </Button>
            )
          }
        },
      },
    ].filter(Boolean)
  }, [downloadingDocument, handleDelete, handleDownload, isAdmin])

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  return (
    <Card>
      <TabCardHeader
        title='Employee Documents'
        extra={
          (!contractRef && !isContractOngoing) ||
          isLoading ||
          !hasAccess(permissions.RequestContractsDocuments) ? null : (
            <Button type='button' onClick={toggleRequestModal}>
              {isAdmin ? 'Create request' : 'Request document'}
            </Button>
          )
        }
      />

      <CardBody>
        {isLoading ? (
          <Loader minHeight='min(276px, 30vh)' />
        ) : !filteredEmployeeDocuments ||
          filteredEmployeeDocuments?.length <= 0 ? (
          <SimpleMessage minHeight='min(276px, 30vh)'>
            Your documents will be shown here.
          </SimpleMessage>
        ) : (
          <DataTable
            data={filteredEmployeeDocuments}
            columns={columns}
            responsive
          />
        )}
      </CardBody>

      {!showPaginator ? null : (
        <div className='ml-auto mx-3 mt-2'>
          <Pagination
            activePage={page}
            itemsCountPerPage={paginator?.per_page ?? 10}
            totalItemsCount={paginator?.total ?? 0}
            onChange={(newPage) => setPage(newPage)}
          />
        </div>
      )}

      <RequestDocumentModal
        isOpen={isRequestModalOpen}
        toggle={toggleRequestModal}
        afterSuccess={refreshDocuments}
        isAdmin={isAdmin}
        contractRef={contractRef}
      />

      <UploadDocumentModal
        isOpen={isUploadModalOpen}
        toggle={toggleUploadModal}
        item={selectedItem}
        afterSuccess={refreshDocuments}
      />
    </Card>
  )
}

const employeeDocumentOtherTypeValue = 'other'

function DeleteRequestButton({ rowData, isAdmin, refreshDocuments }) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { startFetch: deleteRequest, isLoading: deletingRequest } = useFetch({
    action: deleteEmployeeDocumentRequestAdmin,
    withAdminAccess: isAdmin,
    onComplete: () => {
      refreshDocuments()
      setShowConfirmation(false)
    },
    onError: () => {
      toastr.error('Something went wrong!')
    },
  })
  function handleDeleteRequest(rowData) {
    deleteRequest({ request_id: rowData?.id })
  }

  return (
    <>
      <IconButton
        size={26}
        loading={deletingRequest}
        className='hover:!tw-text-red-90'
        outline
        onClick={() => setShowConfirmation(true)}
        color='danger'
        icon={<Trash />}
      />
      {showConfirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          toggle={() => setShowConfirmation(false)}
          confirmLoading={deletingRequest}
          content='Are you sure you want to delete this request?'
          title='Delete Document Request'
          onConfirm={() => handleDeleteRequest(rowData)}
          onClosed={() => setShowConfirmation(false)}
        />
      )}
    </>
  )
}

export function RequestDocumentModal({
  isOpen,
  toggle,
  afterSuccess,
  isAdmin,
  contractRef,
}) {
  const requestDocumentFormId = 'doc-form'

  const { hasAccess } = usePermissions()

  const { startFetch: addDocument, isLoading: requestingDocument } = useFetch({
    action: isAdmin ? adminAddDocumentRequest : addDocumentRequestEmployee,
    withAdminAccess: isAdmin,
    onComplete: () => {
      toggle?.()
      afterSuccess?.()
      toastr.success('Document request sent successfully')
    },
    onError: (resp) => {
      toastr.error(resp?.message || 'Something went wrong')
    },
  })

  const { data: contractDetails } = useFetch(
    {
      autoFetch: !!contractRef,
      action: getContractDetailAdmin,
      withAdminAccess: true,
      body: { id: contractRef },
    },
    [contractRef],
  )

  const requestTypes =
    useSelector((state) =>
      state?.Layout?.staticData?.document_request_types.map((e) => {
        return {
          label: e.name,
          value: e.name,
        }
      }),
    ) || []

  function handleConfirm() {
    const body = {
      title:
        watch('document_type')?.toLowerCase() === employeeDocumentOtherTypeValue
          ? 'Other - ' + watch('document_name')
          : watch('document_type'),
    }
    if (isAdmin) {
      body.employee_id = contractDetails?.employee?.id
    }

    addDocument(body)
  }

  const schema = yup.object().shape({
    document_type: yup.string().required('Document type is required'),
    document_name: yup.string().when('document_type', {
      is: (value) => value?.toLowerCase() === 'other',
      then: (schema) => schema.required('Document name is required'),
      otherwise: (schema) => schema.optional(),
    }),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose={true}>
      <ModalHeader>Request document from the employee</ModalHeader>

      <ModalBody>
        <Form id={requestDocumentFormId} onSubmit={handleSubmit(handleConfirm)}>
          <FormGroup>
            <Label htmlFor='document_type'>Document name</Label>
            <ControlledSelect
              inputId='document_type'
              options={requestTypes}
              control={control}
              name='document_type'
              error={errors?.document_type?.message}
            />
          </FormGroup>
        </Form>

        {watch('document_type')?.toLowerCase() ===
        employeeDocumentOtherTypeValue ? (
          <FormGroup>
            <Label htmlFor='document_name'>Document name</Label>
            <ControlledInput
              type='text'
              id='document_name'
              placeholder='Document name'
              control={control}
              name='document_name'
              error={errors?.document_name?.message}
            />
          </FormGroup>
        ) : null}
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={() => {
            reset()
            toggle()
          }}
          disabled={requestingDocument}
        >
          Cancel
        </Button>
        <Button
          formId={requestDocumentFormId}
          type='submit'
          loading={requestingDocument}
          disabled={
            requestingDocument ||
            !hasAccess(permissions.RequestContractsDocuments)
          }
        >
          Request document
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function UploadDocumentModal({ isOpen, toggle, item, afterSuccess }) {
  const [document, setDocument] = useState(null)
  const [sendEmail, setSendEmail] = useState(false)

  const { startFetch: uploadRequestedDocument, isLoading: uploadingDocument } =
    useFetch({
      action: uploadEmployeeDocument,
      withAdminAccess: true,
      onComplete: () => {
        toggle()
        setDocument(null)
        afterSuccess()
      },
    })

  async function handleDrop([file]) {
    const base64File = await toBase64(file)

    setDocument({ file, data: base64File })
  }

  function handleUpload() {
    uploadRequestedDocument({
      request_id: item?.id,
      file: document.file,
      send_email: sendEmail ? 1 : 0,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Upload document</ModalHeader>
      <ModalBody>
        <FormGroup className='mb-3 mx-1'>
          <Label
            htmlFor='send-email'
            className='font-size-14 mb-0 d-flex align-items-center rp-checkbox-label rp-font-normal gap-10 rounded-sm'
            style={{ color: 'var(--slate-700)' }}
          >
            <Input
              type='checkbox'
              name='send-email'
              id='send-email'
              className='rp-checkbox-input'
              value={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
            />

            <span>Send email to employee</span>
          </Label>
        </FormGroup>

        <DropzoneInput
          onDropAccepted={handleDrop}
          accept={{ 'application/pdf': ['.pdf'] }}
        >
          <div
            className='d-flex flex-column gap-12 justify-content-center align-items-center'
            style={{ minHeight: '250px' }}
          >
            {document ? (
              <>
                <div className='d-flex justify-content-between align-items-center flex-grow w-100'>
                  <div>{document.file.name}</div>
                  <Button
                    type='button'
                    size='sm'
                    color='secondary'
                    outline
                    onClick={(e) => {
                      e.stopPropagation()
                      setDocument(null)
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <UploadPreview
                  preview={{ data: document.data, type: document.file.type }}
                  style={{ minHeight: 345, maxHeight: 345 }}
                />
              </>
            ) : (
              <>
                <i className='bx bxs-file-doc' style={{ fontSize: '52px' }} />
                <span className='font-size-16'>
                  Click or drop your document (pdf) here to upload
                </span>
              </>
            )}
          </div>
        </DropzoneInput>
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={uploadingDocument}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={handleUpload}
          loading={uploadingDocument}
          disabled={uploadingDocument}
        >
          Submit document
        </Button>
      </ModalFooter>
    </Modal>
  )
}
