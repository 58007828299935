import React from 'react'
import DataTable from '../../../../components/ui/data-table'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import TabEmpty from '../../../Contract/components/tab/tab-empty'

const columns = [
  { Header: 'Transaction ref', accessor: 'transaction_ref' },
  {
    Header: 'Total',
    accessor: 'base_amount',
    Cell: ({ rowData }) => {
      const formatter = getCurrencyFormatter()
      return formatter.format(rowData.base_amount)
    },
  },
  {
    Header: 'Fee',
    accessor: 'base_fee',
    Cell: ({ rowData }) => {
      const formatter = getCurrencyFormatter()
      return formatter.format(rowData.base_fee)
    },
  },
  { Header: 'Created At', accessor: 'created_at' },
]

export function RefundsList({ refunds }) {
  if (refunds?.length <= 0) {
    return <TabEmpty minHeight='max(40vh, 400px)' title='No refunds found' />
  }

  return <DataTable data={refunds} columns={columns} />
}
