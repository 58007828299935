import { ArrowSquareOut } from '@phosphor-icons/react'
import ExtraCashbackLogos from '../../../assets/images/extra-cashback.svg'
import React from 'react'
import cx from 'classnames'
import { Card } from 'reactstrap'
import { useResize } from '../../../helpers/hooks'
function ExtraCashBack() {
  const isMobile = useResize()
  return (
    <Card className='mb-0 rp-shadow-2'>
      <div
        className={cx('d-flex justify-content-between', {
          'flex-column-reverse': isMobile,
        })}
      >
        <div className={cx(isMobile ? 'p-3' : 'p-4.5')}>
          <div
            className={cx('font-size-24', isMobile ? '' : 'font-weight-medium')}
          >
            Extra cashback!
          </div>
          <div className='text-text-70 font-size-14'>
            Earn extra money by shopping at your favorite brands like Airbnb,
            Booking, Uber...
          </div>
          <div className=''>
            <a
              className='font-size-14 font-weight-bold mt-3 d-flex align-items-center'
              style={{ color: 'var(--secondary-100)' }}
              href='https://specials.priceless.com/en-sg/cardhome/RemotePass_Card_?issuerId=202110060001&productId=202307250001'
              rel='noreferrer'
              target='_blank'
            >
              Explore offers <ArrowSquareOut size={20} className='ml-2' />
            </a>
          </div>
        </div>
        <div className={cx('mr-3 text-center', { 'px-3': isMobile })}>
          <img style={{ minHeight: '100%' }} src={ExtraCashbackLogos} />
        </div>
      </div>
    </Card>
  )
}

export default ExtraCashBack
