import { apiV2, getConfigV2 } from './api'

export const createAutomation = (token, data) => {
  return apiV2.post(
    `/automation/api/v1/workflow`,
    {
      name: data.name,
      isTemplate: data.isTemplate,
      description: data.description,
    },
    getConfigV2(token),
  )
}

export const updateAutomation = (token, { id, ...data }) => {
  return apiV2.patch(
    `/automation/api/v1/workflow/${id}`,
    data,
    getConfigV2(token),
  )
}

export const duplicateAutomation = (token, data) => {
  return apiV2.post(
    `automation/api/v1/workflow/${data.templateId}/duplicate`,
    {},
    getConfigV2(token),
  )
}

export const getAutomations = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow`,
    getConfigV2(token, null, data),
  )
}

export const getAutomationDetail = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow/${data.id}`,
    getConfigV2(token, null, data),
  )
}

export const getPlaceholders = (token) => {
  return apiV2.get(
    `/automation/api/v1/workflow/action/variables`,
    getConfigV2(token),
  )
}

export const getFilters = (token) => {
  return apiV2.get(
    `/automation/api/v1/workflow/trigger/filters`,
    getConfigV2(token),
  )
}

export const updateSteps = (token, data) => {
  return apiV2.put(
    `/automation/api/v1/workflow/${data.id}/steps`,
    { steps: data.steps },
    getConfigV2(token),
  )
}

export const deleteAutomation = (token, data) => {
  return apiV2.delete(
    `/automation/api/v1/workflow/${data.id}`,
    getConfigV2(token),
  )
}
