import {
  UPDATE_CONTRACT_REF,
  UPDATE_PROFILE_COMPANY,
  UPDATE_USER_NOTIFICATIONS,
  UPDATE_USER_PROFILE_INFO,
  UPDATE_USER_ADMIN_PERMISSIONS,
  UPDATE_USER_PROFILE_ROLE,
  USER_PROFILE_INIT,
} from './actionTypes'

const initialState = {
  userProfile: null,
  isAdmin: false,
  notifications: [],
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE_INFO:
      state = {
        ...state,
        userProfile: { ...state.userProfile, ...action.payload },
      }
      break

    case UPDATE_USER_ADMIN_PERMISSIONS:
      state = {
        ...state,
        userProfile: { ...state.userProfile, adminPermissions: action.payload },
      }
      break

    case UPDATE_USER_PROFILE_ROLE:
      state = {
        ...state,
        isAdmin: true,
      }
      break
    case UPDATE_USER_NOTIFICATIONS:
      state = {
        ...state,
        notifications: action.payload,
      }
      break
    case UPDATE_CONTRACT_REF:
      state = {
        ...state,
        contractRef: action.payload,
      }
      break
    case UPDATE_PROFILE_COMPANY:
      state = {
        ...state,
        userProfile: { ...state.userProfile, company: action?.payload },
      }
      break
    case USER_PROFILE_INIT:
      state = { ...initialState }
  }
  return state
}

export default profile
