export function track(event, data) {
  if (!window.analytics) return

  window.analytics.track(event, data)
}

export function tag(event, data) {
  if (!window?.dataLayer || !window?.dataLayer?.push) return

  window.dataLayer.push({ event, ...data })
}

export function getIntercomData(data) {
  const intercomData = {
    name: data?.first_name,
    first_name: data?.first_name,
    last_name: data?.last_name,
    email: data?.email,
    user_id: data?.id,
    type: data?.type,
    'kyc-verified': data?.kyc_verified === 1,
    country: data?.country?.name || data?.company?.country?.name,
    phone: data?.phone,
    role: data?.role_name,
    'company-name': data?.company?.name,
    '2fa': data?.is_2fa_enabled,
  }

  return intercomData
}
