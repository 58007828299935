import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import { Card, Spinner } from 'reactstrap'
import toastr from 'toastr'

import { StyledH3 } from '../../../components/Typo'
import { useFetch } from '../../../helpers/hooks'
import { cardTransactions } from '../../../services/api'
import LatestTransactions from '../components/latest-transactions'
import TransactionDetails from '../components/transactions-details'

const CardTransactions = () => {
  const [show, setShow] = useState(false)
  const [selectedTrx, setSelectedTrx] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [trx, setTrx] = useState([])

  const { isLoading, data } = useFetch(
    {
      action: cardTransactions,
      body: { page: (activePage - 1).toString(), limit: 10 },
      autoFetch: true,
      onError: (err) => {
        toastr.error(err)
      },
    },
    [activePage],
  )

  const handlePageChange = (page) => {
    setActivePage(page)
  }

  return (
    <div className='page-content'>
      <TransactionDetails
        show={show}
        data={trx}
        selectedTrx={selectedTrx}
        setShow={setShow}
      />
      <StyledH3
        min='22px'
        max='32px'
        className='align-items-center d-flex mb-4 gap-12 text-gray-h'
      >
        <Link to='/cards' className='d-flex text-current'>
          <i
            className='bx bx-arrow-back text-muted'
            style={{ fontSize: 'smaller' }}
          />
        </Link>
        <span>All Transactions</span>
      </StyledH3>

      {isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: 245 }}
        >
          <Spinner type='grow' color='primary' />
        </div>
      ) : (
        <Card className='p-4 p-md-0'>
          <LatestTransactions
            data={data?.items}
            setSelectedTrx={setSelectedTrx}
            setTrx={setTrx}
            setShow={setShow}
            withArrow
          />

          {data?.items?.length <= 0 || data?.total_pages_count <= 1 ? null : (
            <div className='d-flex justify-content-end p-md-3 mt-3 mt-md-0'>
              <Pagination
                itemClass='page-item'
                linkClass='page-link'
                activePage={activePage}
                totalItemsCount={data?.total_items_count ?? 0}
                onChange={handlePageChange}
                innerClass='pagination mb-0'
              />
            </div>
          )}
        </Card>
      )}
    </div>
  )
}
export default CardTransactions
