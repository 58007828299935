import { ArrowSquareIn } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { UncontrolledTooltip } from 'reactstrap'

import RemotePassIcon from '../../../../assets/images/identity/symbol_main.png'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import Alert from '../../../../components/ui/alert'
import Button from '../../../../components/ui/button'
import InputFeedback from '../../../../components/ui/input-feedback'
import PremiumEligibilityModal from './premium-eligibility-modal'

export function ControlledPremiumRecruitment({
  control,
  name,
  required,
  transform,
  disabled,
  companyCountry,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => {
        const { value, onChange } = field

        function handleChange(newVal) {
          typeof transform?.output === 'function'
            ? onChange(transform.output(newVal))
            : onChange(newVal)
        }

        return (
          <RenderControlledBlock
            error={error}
            value={value}
            transform={transform}
            handleChange={handleChange}
            disabled={disabled}
            companyCountry={companyCountry}
          />
        )
      }}
    />
  )
}

function RenderControlledBlock({
  transform,
  value,
  error,
  handleChange,
  disabled,
  companyCountry,
}) {
  const [premiumModalOpen, setPremiumModalOpen] = useState(false)

  const { setValue } = useFormContext()

  return (
    <>
      <PremiumRecruitment
        checked={transform?.input ? transform.input(value) : value}
        onChange={() => {
          if (value === true) {
            handleChange(false)
          } else {
            setPremiumModalOpen(true)
          }
        }}
        disabled={disabled}
        companyCountry={companyCountry}
      />

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {typeof error?.message === 'string' ? error.message : error}
        </InputFeedback>
      )}

      {premiumModalOpen && (
        <PremiumEligibilityModal
          isOpen={premiumModalOpen}
          toggle={(eligible) => {
            if (eligible === true) {
              handleChange(true)
            }
            setPremiumModalOpen(false)
          }}
          onEligible={(questions) => {
            // This is setting the eligibility_responses field in the form for the contract creation process
            setValue('eligibility_responses', questions)
          }}
        />
      )}
    </>
  )
}

const blockId = 'premium-recruitment'
function PremiumRecruitment({ checked, onChange, disabled, companyCountry }) {
  return (
    <Alert
      className='p-0 bg-transparent border-0'
      innerTag='div'
      innerClassName={cx(
        'rounded p-3 border d-flex align-items-center gap-10 w-100',
        disabled ? 'bg-secondary-10' : 'bg-primary-10',
      )}
      customIcon={false}
      id={blockId}
    >
      <div
        className={cx(
          'rounded-circle p-2.5 flex-shrink-0 align-self-start',
          disabled ? 'bg-secondary-20' : 'bg-primary-20',
        )}
      >
        <img
          src={RemotePassIcon}
          style={{
            ...(disabled
              ? { filter: 'grayscale(1)', opacity: 0.6 }
              : undefined),
            height: 26,
            width: 26,
          }}
        />
      </div>
      <div
        className={cx(disabled ? 'text-secondary-60' : 'text-secondary-100')}
      >
        <h3
          className={cx(
            'font-size-16 font-weight-medium mb-1',
            disabled && 'text-secondary-70',
          )}
        >
          Enable RemotePass+
        </h3>
        <p className='mb-0 text-current'>
          RemotePass to hire and sign the agreement directly with the
          contractor.
        </p>

        <Button
          iconRight={<ArrowSquareIn size={20} />}
          color='link'
          className={cx('px-0.5 py-1 mt-2', disabled && 'text-secondary-70')}
          tag='a'
          target='_blank'
          rel='noreferrer'
          href='https://help.remotepass.com/en/articles/8512670-how-to-onboard-contractors-using-remotepass'
        >
          Learn more
        </Button>
      </div>

      {!disabled ? null : (
        <UncontrolledTooltip target={blockId}>
          {companyCountry ?? 'The company country'} is not yet supported for the
          RemotePass+ service.
        </UncontrolledTooltip>
      )}

      <Toggle
        check={checked}
        change={(event) => onChange(event.target.checked)}
        marginRight={null}
        className='ml-auto'
        disabled={disabled}
      />
    </Alert>
  )
}

export default PremiumRecruitment
