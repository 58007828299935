import { ArrowsClockwise, Info } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../../components/NoContent'
import StyledTd from '../../../../components/Table/StyledTd'
import StyledTh from '../../../../components/Table/StyledTh'
import TableComp from '../../../../components/Table/TableComp'
import { StyledH5, StyledH6 } from '../../../../components/Typo'
import { freshUpdateContractor } from '../../../../store/fresh/actions'
import { updateNetSuiteContractor } from '../../../../store/ns/actions'
import { qbUpdateContractor } from '../../../../store/qb/actions'
import { updateContractor } from '../../../../store/xero/actions'
import { updateZohoContractor } from '../../../../store/zoho/actions'
import MappingAccountModal from '../MappingAccountModal'
import XeroStatus from '../XeroStatus'
import welcomeImage from './../../../../assets/images/welcome.svg'
import { VendorContractorSelect } from './vendor-contractor-select'

function ContactsList({ defaultValues, history, platform = 'Xero' }) {
  const contractors = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.contractors
      case 'Quickbooks':
        return state.qb?.contractors
      case 'Freshbooks':
        return state.fresh?.contractors
      case 'Zoho':
        return state.zoho?.contractors
      case 'NetSuite':
        return state.ns?.contractors
    }
  })

  return (
    <div>
      {!contractors?.length ? (
        <NoContent
          image={welcomeImage}
          subtitle='Manage contracts'
          title=''
          actionTitle='New Contract'
          onAction={() => {
            history.push('/contract/create')
          }}
        />
      ) : (
        <TableComp responsive className='table-centered'>
          <thead>
            <tr>
              <StyledTh>Contractor</StyledTh>
              <StyledTh>Vendor</StyledTh>
              <StyledTh>Accounts</StyledTh>
              {!!defaultValues && <StyledTh>Status</StyledTh>}
            </tr>
          </thead>
          <tbody>
            {contractors?.map((e, i) => (
              <ContractorItem
                key={`milestone-${i}`}
                item={e}
                withStatus={!!defaultValues}
                platform={platform}
              />
            ))}
          </tbody>
        </TableComp>
      )}
    </div>
  )
}

function ContractorItem({ item, withStatus, platform }) {
  const [showAccountModal, setShowAccountModal] = useState(false)
  const data = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero.preData
      case 'Quickbooks':
        return state.qb.preData
      case 'Freshbooks':
        return state.fresh.preData
      case 'Zoho':
        return state.zoho.preData
      case 'NetSuite':
        return state.ns.preData
    }
  })
  const dispatch = useDispatch()
  const onVendorChange = (element) => {
    switch (platform) {
      case 'Xero':
        dispatch(
          updateContractor({
            ...item,
            xero: element,
          }),
        )
        break
      case 'Quickbooks':
        dispatch(
          qbUpdateContractor({
            ...item,
            xero: element,
          }),
        )
        break
      case 'Freshbooks':
        dispatch(
          freshUpdateContractor({
            ...item,
            xero: { label: element.label, value: element.value.toString() },
          }),
        )
        break
      case 'Zoho':
        dispatch(
          updateZohoContractor({
            ...item,
            xero: element,
          }),
        )
        break
      case 'NetSuite':
        dispatch(
          updateNetSuiteContractor({
            ...item,
            xero: element,
          }),
        )
        break
    }
  }
  const contactOptions =
    data?.contacts?.map((c) => ({ label: c.name, value: c.account_id })) || []

  return (
    <>
      <tr>
        <StyledTd>
          <StyledH5 min='14px' max='14px'>
            {item?.name}
          </StyledH5>
          <StyledH6 min='10px' max='12px' className='text-muted mb-0'>
            {item.email}
          </StyledH6>
        </StyledTd>
        <StyledTd style={{ minWidth: 238 }}>
          {platform !== 'NetSuite' ? (
            <CustomSelect
              placeholder='Create new'
              menuPosition='fixed'
              options={[
                { label: 'Create New', value: null },
                ...contactOptions,
              ]}
              onChange={onVendorChange}
              value={
                item.xero ||
                contactOptions.find(
                  (e) =>
                    item.xero_id === e.value ||
                    item.qb_id === e.value ||
                    item.fresh_id === e.value.toString() ||
                    item.zoho_id === e.value.toString() ||
                    item.netsuite_id === e.value.toString(),
                ) || { label: 'Create New', value: null }
              }
            />
          ) : (
            <VendorContractorSelect
              placeholder='Create new'
              menuPosition='fixed'
              onChange={onVendorChange}
              defaultOptions={[
                { label: 'Create New', value: null },
                ...contactOptions,
              ]}
            />
          )}
        </StyledTd>
        <StyledTd>
          <button
            className='tw-text-primary'
            onClick={() => setShowAccountModal(true)}
          >
            Update
          </button>
        </StyledTd>
        {withStatus && (
          <StyledTd>
            {item.queued ? (
              <ArrowsClockwise
                size={16}
                weight='bold'
                className='tw-animate-[spin_2s_linear_infinite]'
              />
            ) : (
              <div className='tw-flex tw-items-center tw-gap-2'>
                <XeroStatus status={item.status} />
                {!!item.error && (
                  <div style={{ position: 'relative' }}>
                    <Info
                      size={16}
                      weight='bold'
                      id={`xero-sync-contract-${item?.contractor_id}`}
                    />
                    <UncontrolledTooltip
                      placement='top'
                      target={`xero-sync-contract-${item?.contractor_id}`}
                    >
                      {item.error}
                    </UncontrolledTooltip>
                  </div>
                )}
              </div>
            )}
          </StyledTd>
        )}
      </tr>
      {showAccountModal && (
        <MappingAccountModal
          toggle={() => setShowAccountModal(false)}
          isOpen={showAccountModal}
          contractor={item}
          platform={platform}
        />
      )}
    </>
  )
}

export default ContactsList
