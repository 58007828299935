import {
  Check,
  ClockCounterClockwise,
  HourglassMedium,
  LockSimple,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import Button from '../../../components/ui/button'
import { Tooltip } from 'reactstrap'

import { useResize } from '../../../helpers/hooks'

import { getCurrencyFormatter } from '../../../utils/formatters/currency'

import CashbackHistoryModal from './cashback-history/cashback-history'
import BottomSheet from '../../../components/ui/bottom-sheet/bottom-sheet'
import { KYC_STATUSES, getKycRejectedText } from './cards-kyc-banner'
const getKYCOverlayContent = (status, rejectionReason) => {
  const commonContent = {
    showKYCButton: true,
    kycButtonText: 'Get Verified Now',
    kycButtonIcon: null,
    kycIcon: <LockSimple color='var(--primary)' weight='duotone' size={24} />,
    kycTitle: 'Unlock Cashback',
    kycText:
      'Confirm your identity and start earning cashback with every transaction.',
  }

  switch (status) {
    case KYC_STATUSES.PENDING:
    case KYC_STATUSES.ACCEPTED:
    case KYC_STATUSES.SUBMITTED:
      return {
        ...commonContent,
        showKYCButton: false,
        kycIcon: (
          <HourglassMedium color='var(--primary)' weight='duotone' size={24} />
        ),
        kycTitle: 'Verification in progress',
        kycText:
          'You’ll be notified once your verification is approved, and you can begin earning cashback.',
      }

    case KYC_STATUSES.NOT_SUBMITTED:
    case KYC_STATUSES.FAILED:
    case KYC_STATUSES.FETCHING_ERROR:
      return {
        ...commonContent,
        kycButtonText: 'Start Again',
      }

    case KYC_STATUSES.REJECTED:
      return {
        ...commonContent,
        kycButtonText: getKycRejectedText(false, rejectionReason).actionText,
      }

    default:
      return {
        ...commonContent,
        kycButtonIcon: <Check size={16} color='white' />,
      }
  }
}

function CashBack({ data, showKycBanner }) {
  const [openHistory, setOpenHistory] = useState(false)
  const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState(false)
  const isMobile = useResize()
  const cashbackDetails = data?.cashback_details
  const formatter = getCurrencyFormatter(cashbackDetails?.card_currency_code)
  const minSpendingAmount = cashbackDetails?.min_spending_amount
  const spendingLeft = minSpendingAmount - cashbackDetails?.card_spending_amount
  const progress =
    (cashbackDetails?.card_spending_amount / minSpendingAmount) * 100
  const rewardIsUnlocked = cashbackDetails?.cashback_unlocked
  const status = data?.kyc?.status
  const rejectionReason = data?.kyc?.rejection_reason
  const {
    showKYCButton,
    kycText,
    kycTitle,
    kycIcon,
    kycButtonIcon,
    kycButtonText,
  } = getKYCOverlayContent(status, rejectionReason)
  const showLockedCashback =
    showKycBanner &&
    status !== KYC_STATUSES.DONE &&
    status !== KYC_STATUSES.APPROVED

  const handleButtonClick = () => {
    const verifyButton = document.querySelector('#verify_kyc_banner')
    if (verifyButton) {
      verifyButton.click()
    }
  }
  return (
    <div className='position-relative w-100 h-100'>
      {showLockedCashback && (
        <div
          className='position-absolute w-100 h-100 top-0 left-0 d-flex align-items-center justify-content-center flex-column text-center'
          style={{ backgroundColor: 'rgba(248, 248, 248, 0.85)' }}
        >
          <div className='mb-2'>{kycIcon}</div>
          <div className='font-size-20 font-weight-bold mb-2'>{kycTitle}</div>
          <div
            style={{ maxWidth: 350 }}
            className='font-size-14 text-text-60 mb-4'
          >
            {kycText}
          </div>
          {showKYCButton && (
            <Button onClick={handleButtonClick} icon={kycButtonIcon}>
              {kycButtonText}
            </Button>
          )}
        </div>
      )}
      <div className={cx('pt-4 pb-3 w-100', isMobile ? 'px-3' : 'px-4.5')}>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex flex-column'>
            <div className='font-size-20'>Cashback</div>
            <div className='font-size-14 text-text-80'>
              {cashbackDetails?.date}
            </div>
          </div>
          <Button
            color='transparent'
            onClick={() => setOpenHistory(true)}
            className='px-0 text-secondary-100'
            iconRight={<ClockCounterClockwise size={20} />}
          >
            View history
          </Button>
        </div>
        <div className='d-flex justify-content-between mt-4.5'>
          <div
            onClick={isMobile ? () => setBottomSheetIsOpen(true) : null}
            className=''
          >
            <LockIcon
              isUnlocked={rewardIsUnlocked}
              rewardedAt={cashbackDetails?.cashback_rewarded_at}
            />
            <div className='font-size-20 font-weight-bold mt-2 text-secondary-100 d-flex align-items-center'>
              {formatter.format(cashbackDetails?.cashback_amount)}
              {/*
              //TODO To be added later
              <div
                className='bg-green-20 ml-2 rp-font-bold px-2 py-1 font-size-10 d-flex justify-content-center align-items-center'
                style={{
                  borderRadius: 1000,
                  height: 26,
                  color: 'var(--green-120)',
                }}
              >
                + $19.30
              </div> */}
            </div>
            <div className='text-text-60'>Earnings</div>
          </div>
          {bottomSheetIsOpen && (
            <BottomSheet
              content={
                <div className='p-2 mb-4 font-size-16 text-text-80'>
                  {!rewardIsUnlocked
                    ? `Unlock your earnings by spending ${formatter.format(
                        minSpendingAmount,
                      )}`
                    : `Congrats! You will receive your earnings on your RemotePass card on ${cashbackDetails?.cashback_rewarded_at}`}
                </div>
              }
              header={
                !rewardIsUnlocked ? (
                  <div className='px-2'>Reward locked!</div>
                ) : (
                  <div className='px-2'>Reward unlocked!</div>
                )
              }
              isOpen={bottomSheetIsOpen}
              toggle={() => setBottomSheetIsOpen(false)}
            />
          )}
          <div className='d-flex flex-column justify-content-between align-items-end text-right'>
            {spendingLeft > 0 ? (
              <div
                className='bg-yellow-100 rp-font-bold px-2 py-1 font-size-10 d-flex justify-content-center align-items-center'
                style={{ borderRadius: 1000, height: 26 }}
              >
                {formatter.format(spendingLeft)} LEFT
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <div className='font-size-14 font-weight-bold text-secondary-100'>
                {formatter.format(minSpendingAmount)}
              </div>
              <div className='text-text-60'>Spending</div>
            </div>
          </div>
        </div>
        <CashBackProgress className='mt-3 mb-3' progress={progress} />
      </div>
      {openHistory && (
        <CashbackHistoryModal
          isOpen={openHistory}
          toggle={() => setOpenHistory(false)}
        />
      )}
    </div>
  )
}

export default CashBack

function LockIcon({ isUnlocked, rewardedAt }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <>
      <div
        id='cashbackTooltip'
        className={cx(
          'rounded-circle bg-secondary-80 d-flex justify-content-center align-items-center',
          { 'bg-primary': isUnlocked },
        )}
        style={{ height: 28, width: 28 }}
      >
        {isUnlocked ? (
          <Check size={12} color='white' />
        ) : (
          <LockSimple size={12} weight='fill' color='white' />
        )}
      </div>
      <Tooltip
        isOpen={tooltipOpen}
        className='d-none d-md-block'
        target='cashbackTooltip'
        placement='right'
        toggle={toggle}
      >
        {isUnlocked
          ? `Congrats! You will receive your earnings on your RemotePass card on ${rewardedAt}`
          : 'Unlock your earnings by spending $500'}
      </Tooltip>
    </>
  )
}

function CashBackProgress({ progress, className }) {
  const cappedProgress = progress > 100 ? 100 : progress
  return (
    <div
      className={cx('w-100 bg-primary-30', className)}
      style={{ height: 8, borderRadius: 1000, transition: '.3s' }}
    >
      <div
        className='bg-primary'
        style={{
          height: 8,
          borderRadius: 1000,
          width: `${cappedProgress ?? 0}%`,
        }}
      ></div>
    </div>
  )
}
