import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig, getFormData } from './api'

export const getEntities = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('direct_employees/entities' + sp, getConfig(token, extra))
}

export const createEntity = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'direct_employees/entities',
    formData,
    getConfig(token, {}, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateEntity = (token, data) => {
  const { id } = data
  return api.post(
    'direct_employees/entities/' + id + '?_method=PUT',
    data,
    getConfig(token, {}, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const archiveEntity = (token, data) => {
  return api.delete(
    `/direct_employees/entities/${data.id}/archive`,
    getConfig(token),
  )
}

export const unarchiveEntity = (token, data) => {
  return api.put(
    `/direct_employees/entities/${data.id}/unarchive`,
    null,
    getConfig(token),
  )
}

export const deleteEntity = (token, data) => {
  const { id } = data
  return api.delete('direct_employees/entities/' + id, getConfig(token))
}
