import classnames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { Avatar } from 'ui'

import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { StyledH5 } from '../../../../components/Typo'
import { bambooUpdateContractors } from '../../../../store/bamboo/actions'
import { HiBobUpdateContractors } from '../../../../store/hiBob/actions'
import { SaplingUpdateContractors } from '../../../../store/sapling/actions'
import XeroStatus from '../XeroStatus'

const Tab4 = ({ platform, mode, createContractor }) => {
  const hibobContractors = useSelector((state) => state.hiBob.contractors)
  const saplingContractors = useSelector((state) => state.sapling.contractors)
  const bambooContractors = useSelector((state) => state.bamboo.contractors)

  const getcontractorsState = () => {
    switch (platform) {
      case 'sapling':
        return saplingContractors
      case 'bamboo':
        return bambooContractors
      default:
        return hibobContractors
    }
  }

  const functionToDispatch = (contractors) => {
    if (platform === 'sapling') {
      dispatch(SaplingUpdateContractors(contractors))
    } else if (platform === 'bamboo') {
      dispatch(bambooUpdateContractors(contractors))
    } else {
      dispatch(HiBobUpdateContractors(contractors))
    }
  }

  const contractors = getcontractorsState()
  const dispatch = useDispatch()

  return (
    <div className='mx-3 mb-2'>
      <div className='mb-4 mt-3'>
        <StyledH5 className='font-size-16 rp-font-bold'>
          Select which of the existing contracts you wish to create now
        </StyledH5>
      </div>
      <div>
        <Row>
          <Col md={12} className='mb-2'>
            {contractors?.length > 0 ? (
              contractors?.map((c, i) => {
                return (
                  <MiniCard
                    mode={mode}
                    key={i}
                    contractor={c}
                    handleCreate={() => {
                      const ContractorsToSubmit = contractors.filter(
                        (c, index) => i === index,
                      )
                      createContractor(ContractorsToSubmit)
                    }}
                    handelCheck={(value) => {
                      const newContractors = contractors?.map((c, index) => {
                        return i === index ? { ...c, checked: value } : c
                      })

                      functionToDispatch(newContractors)
                    }}
                  />
                )
              })
            ) : (
              <div
                style={{ minHeight: 200 }}
                className='justify-content-center align-items-center d-flex'
              >
                <p>No contractors exist</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

const MiniCard = ({ mode, contractor, handelCheck, handleCreate }) => {
  return (
    <div className='tw-mb-2 tw-flex-[1] tw-rounded tw-p-2 hover:tw-bg-[#eeeeee4d]'>
      <Row className='p-0 m-0 align-items-center'>
        <div className='mr-3'>
          <Avatar
            name={
              contractor?.name ||
              contractor?.first_name + ' ' + contractor?.last_name
            }
            photo={contractor?.photo}
            flag={contractor?.flag}
          />
        </div>
        <div style={{ flex: 1 }}>
          <div className='d-flex align-content-center'>
            <div style={{ minWidth: '16%' }}>
              <StyledH5 className='font-size-16 mt-1 mb-0 pb-0 rp-font-bold mr-2 mb-2'>
                {contractor?.name ||
                  contractor?.first_name + ' ' + contractor?.last_name}
              </StyledH5>
              <p className='text-muted m-0 p-0'> {contractor?.email}</p>
            </div>
          </div>
        </div>
        {mode === 'edit' ? (
          <>
            {contractor?.queued ? (
              <i
                className={classnames({
                  'fas fa-sync-alt mr-2': true,
                  'bx-spin': true,
                })}
              />
            ) : (
              <>
                {contractor?.status === 'Unsynched' ? (
                  <button onClick={handleCreate} className='btn btn-primary'>
                    {false && <i className='bx bx-loader bx-spin' />} Create now
                  </button>
                ) : (
                  <>
                    <XeroStatus
                      status={contractor.status}
                      item={contractor}
                      error={contractor.error}
                    />
                    <div style={{ position: 'relative' }}>
                      {!!contractor.error && (
                        <>
                          <i
                            className='bx bx-info-circle mx-2'
                            id={`xero-sync-status-${contractor?.id}`}
                          />
                          <UncontrolledTooltip
                            placement='top'
                            target={`xero-sync-status-${contractor?.id}`}
                          >
                            {contractor.error}
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div className='d-flex align-items-center'>
            <Toggle
              id={0}
              check={contractor?.checked}
              change={(event) => handelCheck(event.target.checked)}
            />
          </div>
        )}
      </Row>
    </div>
  )
}

export default Tab4
