'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircleNotch } from '@phosphor-icons/react';
import { useRef } from 'react';
import { useButton } from 'react-aria';
import { cn } from '../lib/utils/cn';
const buttonVariants = {
    fill: {
        primary: 'tw-bg-primary tw-text-white hover:tw-bg-primary-110 active:tw-bg-primary-120',
        secondary: 'tw-bg-secondary tw-text-white hover:tw-bg-secondary-110 active:tw-bg-secondary-120',
        red: 'tw-bg-red tw-text-white hover:tw-bg-red-110 active:tw-bg-red-120',
        cyan: 'tw-bg-cyan tw-text-white hover:tw-bg-cyan-110 active:tw-bg-cyan-120',
        yellow: 'tw-bg-yellow tw-text-white hover:tw-bg-yellow-110 active:tw-bg-yellow-120',
        green: 'tw-bg-green tw-text-white hover:tw-bg-green-110 active:tw-bg-green-120',
        blue: 'tw-bg-blue tw-text-white hover:tw-bg-blue-110 active:tw-bg-blue-120',
        gold: 'tw-bg-gold tw-text-white hover:tw-bg-gold-110 active:tw-bg-gold-120',
    },
    outline: {
        primary: 'tw-text-primary tw-border-primary hover:tw-bg-primary-30 active:tw-bg-primary-40',
        secondary: 'tw-text-secondary tw-border-secondary hover:tw-bg-secondary-30 active:tw-bg-secondary-40',
        red: 'tw-text-red tw-border-red hover:tw-bg-red-30 active:tw-bg-red-40',
        cyan: 'tw-text-cyan tw-border-cyan hover:tw-bg-cyan-30 active:tw-bg-cyan-40',
        yellow: 'tw-text-yellow tw-border-yellow hover:tw-bg-yellow-30 active:tw-bg-yellow-40',
        green: 'tw-text-green tw-border-green hover:tw-bg-green-30 active:tw-bg-green-40',
        blue: 'tw-text-blue tw-border-blue hover:tw-bg-blue-30 active:tw-bg-blue-40',
        gold: 'tw-text-gold tw-border-gold hover:tw-bg-gold-30 active:tw-bg-gold-40',
    },
    ghost: {
        primary: 'tw-text-primary hover:tw-text-primary-110 active:tw-text-primary-120',
        secondary: 'tw-text-secondary hover:tw-text-secondary-110 active:tw-text-secondary-120',
        red: 'tw-text-red hover:tw-text-red-110 active:tw-text-red-120',
        cyan: 'tw-text-cyan hover:tw-text-cyan-110 active:tw-text-cyan-120',
        yellow: 'tw-text-yellow hover:tw-text-yellow-110 active:tw-text-yellow-120',
        green: 'tw-text-green hover:tw-text-green-110 active:tw-text-green-120',
        blue: 'tw-text-blue hover:tw-text-blue-110 active:tw-text-blue-120',
        gold: 'tw-text-gold hover:tw-text-gold-110 active:tw-text-gold-120',
    },
};
/**
 * RemotePass customized Button.
 *
 * @param {FC<ButtonProps>} icon - Icon element to be displayed within the button.
 * @param {FC<ButtonProps>} iconRight - Icon element to be displayed on the right side of the button.
 * @param {string} text - Text to be displayed within the button.
 * @param {ButtonColors} color - Color variant of the button.
 * @param {'small' | 'large'} size - Size variant of the button.
 * @param {boolean} loading - Indicates whether the button is in a loading state.
 * @param {boolean} outline - Indicates whether the button has an outline variant.
 * @param {boolean} ghost - Indicates whether the button has a ghost variant.
 * @param {string} formId - ID of the form associated with the button.
 * @param {boolean} block - Indicates whether the button should fill the width of it's parent.
 * @param {CSSProperties} style - Custom inline CSS styles for the button.
 * @param {string} textClassName - Additional CSS class for the text within the button.
 * @returns {FC<ButtonProps>} - Rendered Button component.
 */
const Button = (_a) => {
    var { icon, iconRight, onlyIcon, circle, text, loading, size = 'large', className, formId, children, lightOutline, color = 'primary', outline, isDisabled, block, ghost, style, textClassName, type = 'button' } = _a, btnProps = __rest(_a, ["icon", "iconRight", "onlyIcon", "circle", "text", "loading", "size", "className", "formId", "children", "lightOutline", "color", "outline", "isDisabled", "block", "ghost", "style", "textClassName", "type"]);
    const getDisabledClasses = (type) => {
        switch (type) {
            case 'fill':
                return 'tw-bg-disabled tw-text-text-60 tw-text-white';
            case 'ghost':
                return 'tw-text-text-50';
            case 'outline':
                return 'tw-text-text-50 tw-border-secondary-50';
        }
    };
    const getOutlineColorClasses = () => {
        if (lightOutline) {
            const lighterVariant = {};
            for (const colorKey in buttonVariants.outline) {
                if (Object.prototype.hasOwnProperty.call(buttonVariants.outline, colorKey)) {
                    const color = colorKey;
                    lighterVariant[color] = buttonVariants.outline[color].replace(/tw-border-(\w+)(?=\s|$)/g, 'tw-border-$1-40');
                }
            }
            return lighterVariant[color];
        }
        return buttonVariants.outline[color];
    };
    const getColorClasses = () => {
        const variant = outline ? 'outline' : ghost ? 'ghost' : 'fill';
        return isDisabled
            ? getDisabledClasses(variant)
            : lightOutline
                ? getOutlineColorClasses()
                : buttonVariants[variant][color];
    };
    const ref = useRef(null);
    const { buttonProps } = useButton(Object.assign(Object.assign({}, btnProps), { isDisabled }), ref);
    return (_jsxs("button", Object.assign({ className: cn('tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded tw-py-2 tw-font-bold tw-transition tw-duration-300 tw-ease-in-out', {
            'tw-border-solid tw-bg-none': outline,
            'tw-border': outline && !ghost,
            'tw-cursor-not-allowed tw-opacity-70': isDisabled,
            'tw-w-full': block,
            'tw-rounded-full': circle,
        }, size === 'small'
            ? 'tw-text-xs'
            : size === 'tiny'
                ? 'tw-text-[11px]'
                : 'tw-text-sm', onlyIcon
            ? size === 'small'
                ? 'tw-h-10 tw-w-10 tw-px-2'
                : size === 'tiny'
                    ? 'tw-h-7 tw-w-10 tw-px-2'
                    : 'tw-h-12 tw-w-12 tw-px-2'
            : size === 'small'
                ? 'tw-h-10 tw-px-2'
                : size === 'tiny'
                    ? 'tw-h-7 tw-px-2'
                    : 'tw-h-12 tw-px-6', getColorClasses(), className), disabled: isDisabled }, buttonProps, { style: style, form: formId, type: type, children: [!loading ? (icon) : (_jsx(CircleNotch, { className: 'tw-animate-spin', size: size === 'small' ? 16 : size === 'tiny' ? 14 : 20 })), !text && !children ? null : (_jsx("span", { className: cn('tw-whitespace-nowrap', textClassName), children: text !== null && text !== void 0 ? text : children })), iconRight !== null && iconRight !== void 0 ? iconRight : null] })));
};
export { Button };
