import React from 'react'
import Alert from '../../../../components/ui/alert'
import { useSelector } from 'react-redux'

function ContributionPlansAlert({ jurisdiction }) {
  const companyName = useSelector(
    (state) => state.userProfile?.userProfile?.company?.name,
  )
  const hasDEWSPlan = jurisdiction?.contribution_plans.some(
    (plan) => plan.code === 'dews',
  )
  const hasGCCPlan = jurisdiction?.contribution_plans.some(
    (plan) => plan.code === 'gcc',
  )
  return (
    <>
      {hasDEWSPlan ? (
        <Alert color='info' className='tw-mt-4'>
          {companyName} is a DIFC company, and DEWS contributions will be
          automatically calculated when applicable and added to your monthly
          payroll
        </Alert>
      ) : null}

      {hasGCCPlan ? (
        <Alert color='info' className='tw-mt-4'>
          Pension contributions will be automatically calculated to GCC
          nationals and added to your monthly payroll
        </Alert>
      ) : null}

      {jurisdiction?.is_wps_enabled && (
        <Alert color='info' className='tw-mt-4'>
          Salary will be paid through the UAE Wage Protection System (WPS)
        </Alert>
      )}
    </>
  )
}

export default ContributionPlansAlert
