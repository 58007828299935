import React from 'react'
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'

import kycImage from '../../../assets/images/verification-img.png'
import Button from '../../../components/ui/button'

const KYCModal = ({
  isOpen,
  toggle,
  proceed,
  isCentered = true,
  message = 'Your client has requested to verify your identity before you can sign the contract.',
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={isCentered}>
      <ModalBody>
        <Row className='justify-content-center text-center'>
          <Col md={10}>
            <h2>KYC Verification</h2>

            {!message ? null : <h6 className='text-muted'>{message}</h6>}

            <Row className='justify-content-center'>
              <Col sm='6' xs='8'>
                <div>
                  <img src={kycImage} alt='' className='img-fluid' />
                </div>
              </Col>
            </Row>

            <h6 className='text-muted'>
              It only takes 2 minutes to complete the process
            </h6>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button type='button' color='light' outline onClick={toggle}>
          Cancel
        </Button>
        <Button type='button' onClick={proceed}>
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default KYCModal
