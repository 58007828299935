import React, { useState } from 'react'
import { Card, Container, Nav, NavItem, NavLink, TabPane } from 'reactstrap'
import TabContent from '../../../../components/ui/tabs'
import PayrollOutputs from './payrolls-outputs'
import classNames from 'classnames'
import Payslips from './payslips'
import { useLocation } from 'react-router-dom'

function Payrolls() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const date = searchParams.get('date')
  const tab = searchParams.get('tab')
  const [activeTab, setActiveTab] = useState(tab ?? 'outputs')
  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Payroll</h1>

        <Card>
          <Nav
            tabs
            className='nav-tabs-custom nav-tabs-custom--bg-transparent mx-4 my-3'
          >
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classNames({ active: activeTab === 'outputs' })}
                onClick={() => setActiveTab('outputs')}
              >
                Outputs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classNames({ active: activeTab === 'payslips' })}
                onClick={() => setActiveTab('payslips')}
              >
                Payslips
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='outputs'>
              <PayrollOutputs />
            </TabPane>
            <TabPane tabId='payslips'>
              <Payslips date={date} />
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  )
}

export default Payrolls
