import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { cn } from 'ui'
import { StyledH1 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import { deactivateInsuranceContractor } from '../../../services/api'
import toastr from 'toastr'

const InsuranceStatus = ({
  date,
  number: policyNumber,
  dependents,
  status,
  data,
  refreshData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()

  const location = useLocation()
  const cancelInsurance = new URLSearchParams(location.search).get('cancel')

  const isMobile = useResize()

  function toggleModal() {
    setIsModalOpen((open) => !open)
  }

  const infoItems = [
    {
      label: 'Insurance Status',
      value: status === 'inactive' ? 'Deactivated' : status,
      id: 'insurance-status',
    },
    { label: 'Insurance Plan', value: data?.details?.plan?.name },
    { label: 'Member since', value: date },
    { label: 'Policy Number', value: policyNumber },
  ]

  useEffect(() => {
    if (cancelInsurance === 'true') {
      setIsModalOpen(true)
      history.replace(location.pathname)
    }
  }, [])

  return (
    <div
      className='mb-3 bg-white rounded'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      <div className='p-4'>
        <StyledH1
          className='mb-4'
          style={{ color: 'var(--gray-h)' }}
          min='16px'
          max='24px'
        >
          Your health insurance{' '}
          <span className='rp-font-bold'>
            {status === 'processing'
              ? 'is processing'
              : status === 'active'
                ? 'is active'
                : 'was deactivated'}
          </span>
        </StyledH1>

        <div className='tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-4'>
          {infoItems.map(({ label, value, id }, i) => {
            const color =
              id === 'insurance-status'
                ? status === 'active'
                  ? 'tw-text-systemGreen-100'
                  : 'tw-text-systemRed-100'
                : 'tw-text-text-100'

            return (
              <div key={i} className='tw-text-sm'>
                <div className='tw-mb-1.5 tw-text-secondary-100'>{label}</div>
                <div className={cn('tw-font-semibold tw-capitalize', color)}>
                  {value}
                </div>
              </div>
            )
          })}
        </div>

        {dependents?.length > 0 && (
          <p className='mb-0 mt-3 text-primary font-size-16'>
            {dependents?.length} dependent{dependents?.length > 1 && 's'}
          </p>
        )}
      </div>
      <div
        className='border-top d-flex flex-wrap flex-column flex-md-row'
        style={{ gap: '0 1rem' }}
      >
        <div className='d-flex flex-wrap p-4' style={{ gap: '0.625rem' }}>
          {status === 'processing' ? null : (
            <a
              href='https://form.jotform.com/220486431736356'
              className={`${
                isMobile ? 'w-100' : ''
              } btn btn-outline-primary px-3 d-flex justify-content-center align-items-center`}
              target='_blank'
              rel='noreferrer'
              style={{ gap: '0.5rem' }}
            >
              <i className='bx bx-money font-size-18' />
              <span style={{ whiteSpace: 'nowrap' }}>Make a Claim</span>
            </a>
          )}
          <a
            href={data?.details?.plan?.handbook_url}
            className={`${
              isMobile ? 'w-100' : ''
            } btn btn-outline-primary px-3 d-flex justify-content-center align-items-center`}
            target='_blank'
            rel='noreferrer'
            style={{ gap: '0.5rem' }}
          >
            <i className='bx bx-download font-size-18' />
            <span style={{ whiteSpace: 'nowrap' }}>
              Download Member Handbook
            </span>
          </a>
        </div>

        <div className='border-top d-md-none' />

        <div
          className='d-flex flex-wrap flex-grow-1 flex-md-grow-0 p-4 ml-md-auto'
          style={{ gap: '0.625rem' }}
        >
          {status === 'active' && !!data?.last_cycle ? (
            <>
              <Button
                type='button'
                color='danger'
                outline
                className={isMobile ? 'btn-block mt-0' : null}
                onClick={toggleModal}
              >
                Deactivate
              </Button>
              <DeactivationModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                lastDate={data?.last_cycle}
                policyNumber={data?.details?.policy_number}
                refreshData={refreshData}
              />
            </>
          ) : null}
          {/* <>
            <Button
              type='button'
              className={isMobile ? 'btn-block mt-0' : null}
              onClick={() => {}}
            >
              Update
              Reactivate Plan
            </Button>
          </> */}
        </div>
      </div>
    </div>
  )
}

function DeactivationModal({
  isOpen,
  toggle,
  lastDate,
  refreshData,
  policyNumber,
}) {
  const { startFetch: deactivateInsurance, isLoading: deactivatingInsurance } =
    useFetch({
      action: deactivateInsuranceContractor,
      onComplete: () => {
        toggle()
        refreshData()
        document.body.classList.remove('modal-open')
      },
      onError: (error, res) => {
        toastr.error(res?.message || error)
      },
    })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Deactivate insurance</ModalHeader>
      <ModalBody>
        <p>
          Your current insurance policy will remain active until{' '}
          <strong>{lastDate}</strong> and no further bills will be generated.
        </p>
        <p className='font-size-16 mb-0'>
          Are you sure you want to deactivate your insurance?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button type='button' color='light' outline onClick={toggle}>
          Cancel
        </Button>
        <Button
          type='button'
          color='danger'
          onClick={() => {
            deactivateInsurance({
              policyId: policyNumber,
              cancelationDate: format(new Date(), 'yyyy-MM-dd'),
            })
          }}
          loading={deactivatingInsurance}
          disabled={deactivatingInsurance}
        >
          Deactivate Insurance
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default InsuranceStatus
