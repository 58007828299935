import { Eye } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Container } from 'reactstrap'
import toastr from 'toastr'

import { Badge } from 'ui'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import { createAdminForm, getAdminForms } from '../../../../services/api'
import { ConfirmationModal } from '../../../remotepass-cards/components/active-credit-card-section'
import { useFilters } from '../cards/use-filters'
import { format } from 'date-fns'

function FormsList() {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    search_key: '',
  })
  const [search, setSearch] = useState('')

  const {
    data: adminFormsData,
    paginator,
    isLoading,
  } = useFetch(
    {
      action: getAdminForms,
      withAdminAccess: true,
      autoFetch: true,
      body: filters,
      onError: (err) => {
        toastr.error(err)
      },
    },
    [filters],
  )
  const [createFormModal, setCreateFormModal] = useState(null)
  const history = useHistory()

  const { startFetch: createForm, isLoading: creatingForm } = useFetch({
    action: createAdminForm,
    withAdminAccess: true,
    onComplete: (data) => {
      toastr.success('Form created successfully')
      history.push(`/admin/forms/${data.id}`)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  function handleSearch(query) {
    setSearch(query)
    handleFiltersChange('search_key', query)
  }
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'is_enabled',
      Cell: ({ cellData }) =>
        cellData ? (
          <Badge color='primary'>Enabled</Badge>
        ) : (
          <Badge color='red'>Disabled</Badge>
        ),
    },
    {
      Header: 'Type',
      accessor: 'is_multi_step',
      Cell: ({ cellData }) => (cellData ? 'Multi-step' : 'Single-step'),
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ cellData }) =>
        cellData ? format(new Date(cellData), 'MM/dd/yyy') : 'N/A',
    },
    {
      Header: 'Last Edited',
      accessor: 'updated_at',
      Cell: ({ cellData }) =>
        cellData ? format(new Date(cellData), 'MM/dd/yyy') : 'N/A',
    },
    {
      Header: '',
      Cell: ({ rowData }) => (
        <Button
          textClassName='tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-primary'
          color='transparent'
          onClick={() => history.push(`/admin/forms/${rowData.id}`)}
        >
          <Eye /> Details
        </Button>
      ),
    },
  ]
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <div className='tw-mb-8 tw-flex tw-items-center tw-justify-between'>
          <h1>Forms</h1>
          <Button
            onClick={() => {
              setCreateFormModal({})
            }}
          >
            Create Form
          </Button>
        </div>
        <Card>
          <div className='d-flex p-3'>
            <SearchBar
              name='search'
              placeholder='Search form'
              className='w-100'
              value={search}
              onQueryChanged={handleSearch}
            />
          </div>
          {isLoading ? (
            <Loader minHeight='max(50vh, 550px)' />
          ) : (
            <DataTable data={adminFormsData} columns={columns} />
          )}
          {showPaginator && !isLoading && (
            <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={paginator?.per_page ?? 10}
                totalItemsCount={paginator?.total ?? 0}
                onChange={(newPage) => handleFiltersChange('page', newPage)}
              />
            </div>
          )}
        </Card>
      </Container>

      {createFormModal !== null ? (
        <ConfirmationModal
          isOpen={createFormModal !== null}
          title={createFormModal?.id ? 'Rename Form' : 'Create Form'}
          withReason={{
            name: 'Form Name',
            placeholder: 'Form name',
            value: createFormModal?.name,
            isRequired: true,
            type: 'text',
          }}
          onConfirm={(ev, name) => createForm({ name })}
          icon={<></>} // empty icon
          confirmText={createFormModal?.id ? 'Rename' : 'Create'}
          loading={creatingForm}
          toggle={() => setCreateFormModal(null)}
        />
      ) : null}
    </div>
  )
}

export default FormsList
