import { AvField, AvForm } from 'availity-reactstrap-validation'
import { format, isSameDay } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { Card, Input, Label, Row } from 'reactstrap'
import toastr from 'toastr'

import { Badge } from 'ui'
import ConfirmationModal from '../../../../../components/Common/ConfirmationModal'
import CustomDatePicker from '../../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import TextInput from '../../../../../components/Forms/TextInput'
import Button from '../../../../../components/ui/button'
import ADMIN_PERMISSIONS from '../../../../../config/admin-permissions'
import { useFetch } from '../../../../../helpers/hooks'
import useHasPermission from '../../../../../helpers/hooks/admin/has-permission'
import {
  AssignPartnerToFT,
  AssignSpecialistToFT,
  approveEORTerminationRequest,
  cancelEORRequest,
  cancelEORTerminationRequest,
  getMondayDotComTemplates,
  getPartners,
  getSpecialists,
  submitOffBoardingData,
  terminateEORContract,
} from '../../../../../services/api'
import { getErrorMessage } from '../../../../../utils/get-errors'
import { getFullName } from '../../../../../utils/get-full-name'
import { getContractStatusColor } from '../../../../Contract/ContractList/ContractList'
import { ModalAddOrEditMondayDotComTemplate } from './submit-monday-dot-com-template'

function CustomCard({ title, children }) {
  return (
    <Card className='!tw-mb-6 tw-p-6'>
      <h4 className='tw-mb-6 tw-text-xl tw-font-bold'>{title}</h4>
      {children}
    </Card>
  )
}

function RequestLeftSide({
  contract,
  partner,
  specialist,
  refetchContract,
  terminationDetails,
}) {
  const { contractId } = useParams()
  const history = useHistory()
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_CONTRACT_DETAILS,
  )
  const [cancelTerminationModalOpen, setCancelTerminationModalOpen] =
    useState(false)
  const [approveTerminationModalOpen, setApproveTerminationModalOpen] =
    useState(false)
  const [cancelTerminationNote, setCancelTerminationNote] = useState('')
  const [approveTerminationNote, setApproveTerminationNote] = useState('')
  const [approveTerminationDate, setApproveTerminationDate] = useState()

  const [offBoardingData, setOffBoardingData] = useState({
    template_id: null,
    specialist_id: null,
  })
  const [mondayTemplateModal, setMondayTemplateModal] = useState(null)

  const hideTerminationRequest =
    !contract?.pending_termination_request_id ||
    (contract?.pending_termination_request_id &&
      contract?.is_scheduled_terminated)

  const makeTerminationNoteMandatory =
    terminationDetails?.tentative_last_day &&
    !isSameDay(
      new Date(terminationDetails?.tentative_last_day),
      approveTerminationDate,
    )
  // Requests

  const assignPartner = useFetch({
    action: AssignPartnerToFT,
    withAdminAccess: true,
    onComplete: () => {
      refetchContract({ id: contractId }, false)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const assignSpecialist = useFetch({
    action: AssignSpecialistToFT,
    withAdminAccess: true,
    onComplete: () => {
      refetchContract({ id: contractId }, false)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const { data: requestsPartners } = useFetch({
    action: getPartners,
    withAdminAccess: true,
    autoFetch: true,
    onError: (error) => {
      toastr.error(error)
    },
  })

  const {
    data: offBoardingTemplates,
    startFetch: fetchMondayOffBoardingTemplates,
  } = useFetch({
    action: getMondayDotComTemplates,
    initResult: [],
    body: {
      type: 'off_boarding',
    },
    withAdminAccess: true,
    autoFetch: true,
    onError: (error) => {
      toastr.error(error)
    },
  })
  const { data: requestsSpecialists } = useFetch({
    action: getSpecialists,
    withAdminAccess: true,
    autoFetch: true,
    initResult: [],
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: terminateContract, isLoading: isTerminatingContract } =
    useFetch({
      action: terminateEORContract,
      withAdminAccess: true,
      onComplete: () => {
        history.push('/admin/requests')
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const cancelRequest = useFetch({
    action: cancelEORRequest,
    withAdminAccess: true,
    checkSuccess: true,
    onComplete: () => {
      history.push('/admin/requests')
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const {
    startFetch: cancelTerminationRequest,
    isLoading: cancelTerminationLoading,
  } = useFetch({
    action: cancelEORTerminationRequest,
    withAdminAccess: true,
    onComplete: () => {
      setCancelTerminationModalOpen(false)
      refetchContract({ id: contractId }, false)
    },
  })
  const {
    startFetch: approveTerminationRequest,
    isLoading: approveTerminationLoading,
  } = useFetch({
    action: approveEORTerminationRequest,
    withAdminAccess: true,
    onComplete: () => {
      setApproveTerminationModalOpen(false)
      refetchContract({ id: contractId }, false)
    },
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })

  const { startFetch: submitOffBoarding, isLoading: isSubmittingOffBoarding } =
    useFetch({
      action: submitOffBoardingData,
      withAdminAccess: true,
      onComplete: () => {
        toastr.success('Data submitted')
        refetchContract({ id: contractId }, false)
      },
      onError: (err) => {
        toastr.error(err)
      },
    })
  // End of Requests

  // memoized values

  const lastChanges = useMemo(
    () => contract?.monday_off_boarding_data_submission_history?.at(-1),
    [contract?.monday_off_boarding_data_submission_history],
  )

  useEffect(() => {
    setOffBoardingData({
      template_id: lastChanges
        ? {
            value: lastChanges.monday_template_id,
            label: lastChanges.monday_template_name,
          }
        : null,
      specialist_id: lastChanges
        ? {
            value: lastChanges.off_boarding_specialist_id,
            label: lastChanges.off_boarding_specialist_name,
          }
        : null,
    })
  }, [lastChanges])

  // Handlers
  const handleCancelTerminationRequest = () => {
    if (cancelTerminationNote === '') {
      toastr.error('Please add a rejection note')
    } else {
      cancelTerminationRequest({
        contractId: contract?.id,
        requestId: contract?.pending_termination_request_id,
        rejection_note: cancelTerminationNote,
      })
    }
  }
  const handleApproveTerminationRequest = () => {
    if (!approveTerminationDate) {
      toastr.error('Please choose termination date')
    } else if (makeTerminationNoteMandatory && !approveTerminationNote) {
      toastr.error('Termination note is mandatory')
    } else {
      approveTerminationRequest({
        contractId: contract?.id,
        requestId: contract?.pending_termination_request_id,
        termination_date: format(approveTerminationDate, 'yyyy-MM-dd'),
        termination_note: approveTerminationNote,
      })
    }
  }
  const handleAssignPartner = (data) => {
    assignPartner.startFetch({
      contract_id: contractId,
      partner_id: data.value,
    })
  }
  const handleAssignSpecialist = (data) => {
    assignSpecialist.startFetch({
      contract_id: contractId,
      onboarding_specialist_id: data.value,
    })
  }
  function handleTerminateRequest(_, values) {
    terminateContract({ contract_id: contractId, ...values })
  }

  function handleCancelRequest() {
    cancelRequest.startFetch({ contract_id: contractId })
  }

  const downloadFileByUrl = (url, fileName, target = '_blank') => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    link.setAttribute('target', target)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDownloadMSADocument = () => {
    downloadFileByUrl(contract?.company_msa_agreement_document, 'MSA.pdf')
  }

  const handleSubmitOffBoardingData = () => {
    if (!offBoardingData.template_id || !offBoardingData.specialist_id) {
      toastr.error('Please select both template and specialist')
    } else {
      submitOffBoarding({
        contract_id: contractId,
        monday_off_boarding_template_id: offBoardingData.template_id.value,
        off_boarding_specialist_id: offBoardingData.specialist_id.value,
      })
    }
  }

  // End of Handlers

  // calculated values

  const statusColor = contract.amended
    ? 'danger'
    : getContractStatusColor(contract.status)

  const badgeColor = () => {
    switch (statusColor) {
      case 'danger':
        return 'red'
      case 'success':
        return 'green'
      case 'warning':
        return 'yellow'
      default:
        return 'primary'
    }
  }

  // End of calculated values
  return (
    <>
      <CustomCard title='Personal Information'>
        <AvForm>
          <TextInput
            label='Requester Name'
            name='requester_name'
            value={getFullName(contract.client)}
            disabled
          />
          <TextInput
            label='Requester email'
            name='requester_email'
            value={contract.client?.email}
            disabled
          />
          {contract.company_name ? (
            <TextInput
              label='Company Name'
              name='company_name'
              value={contract.company_name}
              disabled
            />
          ) : null}
          <TextInput
            label='Phone number'
            name='phone_number'
            value={contract.client?.phone}
            disabled
          />
        </AvForm>
        <Label>Partner</Label>
        <CustomSelect
          name='Partner'
          value={partner}
          onChange={handleAssignPartner}
          options={
            requestsPartners
              ? requestsPartners?.map((c) => ({
                  value: c.id,
                  label: c.company_name,
                }))
              : []
          }
        />
        <Label className='tw-mt-2'>Specialist</Label>
        <CustomSelect
          name='Specialist'
          onChange={handleAssignSpecialist}
          value={specialist}
          options={
            requestsSpecialists?.length > 0
              ? requestsSpecialists
                  ?.filter((c) => c.status === 'active')
                  .map((c) => ({
                    value: c.id,
                    label: getFullName(c),
                  }))
              : []
          }
        />
      </CustomCard>
      {hideTerminationRequest ? null : (
        <Card className='p-4 align-items-start'>
          <h4 className='text-gray-h tw-mb-1'>Termination Requested</h4>
          <p className='font-size-12 tw-text-gray-400'>
            Employer requested a termination, Please approve or cancel it
          </p>
          <Row className='px-4 tw-gap-3'>
            <Button
              onClick={() => {
                setCancelTerminationModalOpen(true)
              }}
              color='danger'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setApproveTerminationModalOpen(true)
              }}
            >
              Approve
            </Button>
          </Row>
        </Card>
      )}
      {!contract?.is_scheduled_terminated ? null : (
        <Card className='px-4 py-3 align-items-start'>
          <h4 className='text-gray-h'>Termination Scheduled</h4>
          <p className='text-danger mb-0'>
            Termination date:{' '}
            <span className='tw-font-bold'>{contract?.termination_date}</span>
          </p>
        </Card>
      )}
      {!hasEditPermission ||
      contract?.pending_termination_request_id ||
      contract?.is_scheduled_terminated ? null : [6, 7].includes(
          contract.status?.id,
        ) ? null : (
        <CustomCard title='Actions'>
          {contract.status?.id === 4 ? (
            <AvForm
              onValidSubmit={handleTerminateRequest}
              className='tw-w-full'
            >
              <AvField
                label='Termination Date:'
                name='termination_date'
                type='date'
                required
                className='tw-w-full'
              />
              <Button
                type='submit'
                outline
                block
                color='danger'
                loading={isTerminatingContract}
                disabled={isTerminatingContract}
              >
                Terminate contract
              </Button>
            </AvForm>
          ) : (
            <Button
              type='button'
              outline
              onClick={handleCancelRequest}
              color='danger'
              loading={cancelRequest.isLoading}
              disabled={cancelRequest.isLoading}
            >
              Cancel Request
            </Button>
          )}
        </CustomCard>
      )}
      <CustomCard title='Company MSA agreement'>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
          <div className='tw-text-secondary-80'>Signed</div>
          <div>{contract?.is_company_msa_agreement_signed ? 'Yes' : 'No'}</div>
        </div>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
          <div className='tw-text-secondary-80'>Signed at</div>
          <div>
            {contract?.company_msa_agreement_document_signed_at
              ? format(
                  new Date(contract?.company_msa_agreement_document_signed_at),
                  'yyyy-MM-dd hh:mm aa',
                )
              : '-'}
          </div>
        </div>
        {contract?.company_msa_agreement_document ? (
          <div className='tw-w-full'>
            <Button
              onClick={handleDownloadMSADocument}
              className='tw-mt-4'
              block
            >
              Download Document
            </Button>
          </div>
        ) : null}
      </CustomCard>
      <CustomCard title='Off-boarding template'>
        <CustomSelect
          label='Off-boarding specialist'
          onChange={(data) => {
            setOffBoardingData((prev) => ({
              ...prev,
              specialist_id: data,
            }))
          }}
          value={offBoardingData.specialist_id}
          options={
            requestsSpecialists?.length > 0
              ? requestsSpecialists
                  ?.filter((c) => c.status === 'active')
                  .map((c) => ({
                    value: c.id,
                    label: getFullName(c),
                  }))
              : []
          }
        />
        <CustomSelect
          label='Off-boarding template'
          wrapperClassName='tw-mt-4'
          value={offBoardingData.template_id}
          onChange={(data) => {
            setOffBoardingData((prev) => ({
              ...prev,
              template_id: data,
            }))
          }}
          options={
            offBoardingTemplates.length
              ? offBoardingTemplates.map((c) => ({
                  ...c,
                  value: c.id,
                  label: c.name,
                }))
              : []
          }
        />
        <div className='tw-flex tw-flex-row tw-flex-wrap tw-items-baseline tw-gap-x-1'>
          <Button
            text='Add'
            color='transparent'
            textClassName='tw-text-primary'
            className='p-0 mt-2'
            onClick={() => {
              setMondayTemplateModal('add')
            }}
          />
          or
          <Button
            text='Edit'
            color='transparent'
            textClassName='tw-text-primary'
            className='p-0 mt-2'
            onClick={() => {
              if (!offBoardingData.template_id) {
                toastr.error('Select a template to edit')
                return
              }
              setMondayTemplateModal('edit')
            }}
          />
          a selected template
        </div>
        {lastChanges ? (
          <div className='tw-mt-4 tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-primary-20 tw-p-4 tw-text-sm tw-font-medium tw-text-primary'>
            Data submitted on {format(new Date(lastChanges.date), 'dd/MM/yyyy')}
          </div>
        ) : null}
        <Button
          className='tw-mt-4'
          textClassName={lastChanges ? 'tw-text-primary tw-underline' : ''}
          loading={isSubmittingOffBoarding}
          onClick={() => {
            handleSubmitOffBoardingData()
          }}
          color={lastChanges ? 'transparent' : 'primary'}
        >
          {lastChanges ? 'Re-Submit' : 'Submit data'}
        </Button>
      </CustomCard>
      <CustomCard
        title={
          <div className='tw-flex tw-items-center tw-justify-between'>
            <div>Contract timeline</div>
            <Badge color={badgeColor()} className='tw-block tw-text-center'>
              {contract.status?.name}
            </Badge>
          </div>
        }
      >
        <div className='p-3 p-md-4'>
          <ul className='verti-timeline list-unstyled'>
            {contract.timeline?.map((status, key) => (
              <li key={key} className='event-list'>
                <div className='media tw-flex tw-py-1'>
                  <div
                    className='event-timeline-dot font-size-24 rounded-circle border bg-white tw-mr-1 tw-flex tw-items-center tw-justify-center'
                    style={{
                      marginLeft: '-5px',
                      width: 24,
                      height: 24,
                    }}
                  >
                    <i
                      className={
                        status.completed
                          ? `font-size-10 fas fa-check mt-0 ${
                              [
                                'Contract Cancelled',
                                'Contract Terminated',
                              ].includes(status.name)
                                ? 'text-danger'
                                : 'text-success'
                            } `
                          : 'fas fa-times text-danger mt-0 font-size-10'
                      }
                    />
                  </div>
                  <div className='media-body'>
                    <div>
                      <p
                        style={{ fontWeight: 'bold' }}
                        className='mb-1 font-size-14'
                      >
                        {status?.name}
                      </p>
                      {!!status?.user && (
                        <>
                          <span className='mb-1 text-secondary font-size-14'>
                            by {status.user}
                          </span>
                          <br />
                        </>
                      )}
                      <p className='mb-1 text-secondary font-size-14'>
                        {status.date
                          ? format(status.date * 1000, 'yyyy-MM-dd hh:mm aa')
                          : ' '}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CustomCard>
      {cancelTerminationModalOpen && (
        <ConfirmationModal
          content={
            <Input
              placeHolder='Please enter rejection reason'
              value={cancelTerminationNote}
              onChange={(ev) => setCancelTerminationNote(ev.target.value)}
            />
          }
          title='Cancel Termination Request'
          toggle={() => setCancelTerminationModalOpen(false)}
          isOpen={cancelTerminationModalOpen}
          onConfirm={() => handleCancelTerminationRequest()}
          confirmLoading={cancelTerminationLoading}
          onClosed={() => setCancelTerminationNote('')}
        />
      )}
      {approveTerminationModalOpen && (
        <ConfirmationModal
          content={
            <>
              <CustomDatePicker
                value={approveTerminationDate}
                onChange={(date) => setApproveTerminationDate(date)}
                label='Choose Termination Date'
              />
              <Label className='mt-2'>
                Termination Note
                {makeTerminationNoteMandatory && (
                  <span className='text-danger font-size-16 mx-1'>*</span>
                )}
              </Label>
              <Input
                placeHolder='Termination note'
                value={approveTerminationNote}
                onChange={(ev) => setApproveTerminationNote(ev.target.value)}
              />
            </>
          }
          title='Approve Termination Request'
          toggle={() => setApproveTerminationModalOpen(false)}
          isOpen={approveTerminationModalOpen}
          onConfirm={() => handleApproveTerminationRequest()}
          confirmLoading={approveTerminationLoading}
          onClosed={() => setApproveTerminationDate(null)}
        />
      )}
      {mondayTemplateModal && (
        <ModalAddOrEditMondayDotComTemplate
          isOpen={mondayTemplateModal}
          isEdit={mondayTemplateModal === 'edit'}
          toggle={() => {
            setMondayTemplateModal(null)
          }}
          type='off_boarding'
          selectedTemplate={offBoardingData.template_id}
          fetchMondayDotComTemplates={fetchMondayOffBoardingTemplates}
        />
      )}
    </>
  )
}

export default RequestLeftSide
