import React from 'react'
import { Badge } from 'reactstrap'

const XeroStatus = ({ status, error, item }) => {
  const getColor = () => {
    switch (status) {
      case 'Synched':
        return 'success'
      case 'Unsynched':
        return 'warning'
      case 'Failed':
        return 'danger'
    }
  }
  return (
    <>
      <Badge className={`font-size-12 p-1 bg-soft-${getColor()}`}>
        <p className={`mb-0 text-${getColor()}`}>{status}</p>
      </Badge>{' '}
    </>
  )
}

export default XeroStatus
