import React, { createContext, useState } from 'react'

const bulkCreationContext = createContext({
  bulkFormData: null,
  setBulkFormData: () => {},
})

export function BulkCreationContextProvider({ children, defaultData }) {
  const [bulkFormData, setBulkFormData] = useState(defaultData)

  function handleSetData(data) {
    setBulkFormData((prevData) => ({ ...prevData, ...data }))
  }

  return (
    <bulkCreationContext.Provider
      value={{ bulkFormData, setBulkFormData: handleSetData }}
    >
      {children}
    </bulkCreationContext.Provider>
  )
}

export function useBulkCreationFlowContext() {
  return React.useContext(bulkCreationContext)
}
