import React from 'react'

import { cn } from 'ui'

export function Box({
  borderDashed,
  children,
  className,
  tag: Comp = 'div',
  noPadding = false,
  ...props
}) {
  return (
    <Comp
      className={cn(
        'tw-overflow-clip tw-rounded tw-border tw-border-surface-30 tw-text-sm',
        { 'tw-p-4': !noPadding },
        borderDashed ? 'tw-border-dashed' : '',
        className,
      )}
      {...props}
    >
      {children}
    </Comp>
  )
}

export function BoxIcon({ children, className, sizeClasses }) {
  return (
    <div
      className={cn(
        'tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded tw-bg-surface-20 tw-text-black',
        sizeClasses ?? 'tw-h-10 tw-w-10',
        className,
      )}
    >
      {children}
    </div>
  )
}
