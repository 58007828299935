import React from 'react'
import cx from 'classnames'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import './bottom-sheet.scss'
import { ModalCloseButton } from '../../Common/modal-close-button'
function BottomSheet({
  toggle,
  isOpen,
  header,
  content,
  modalClassName,
  contentClassName,
  dialogClassName,
  headerClassName,
  bodyClassName,
}) {
  return (
    <Modal
      modalClassName={cx('bts-modal', modalClassName)}
      className={cx('bts-dialog', dialogClassName)}
      contentClassName={cx('bts-content', contentClassName)}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalHeader
        className={cx('bts-header', headerClassName)}
        close={<ModalCloseButton toggle={toggle} />}
      >
        {header}
      </ModalHeader>
      <ModalBody
        style={{ minHeight: 460 }}
        className={cx(
          'd-md-flex d-block flex-column align-items-end justify-content-between bts-body',
          bodyClassName,
        )}
      >
        {content}
      </ModalBody>
    </Modal>
  )
}

export default BottomSheet
