import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useController, useFieldArray, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import * as yup from 'yup'
import { getAgeBracket } from '../../../utils/insurance-age-bracket'
import { mapCountryToOption } from '../../../utils/map-to-option'

import { endOfDay, subYears } from 'date-fns'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { StyledH4 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import { useResize } from '../../../helpers/hooks'

export const insuranceFormSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  gender: yup.string().required(),
  birthDate: yup.string().required(),
  email: yup.string().email().required(),
  residency: yup.string().required(),
  citizenship: yup.string().required(),
  dependents: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      gender: yup.string().required(),
      birthDate: yup
        .string()
        .test({
          name: 'max',
          exclusive: false,
          message:
            'Birth date doesn’t fall in the previously selected age range',
          test: function (value) {
            return getAgeBracket(value)[0] === this.parent.age
          },
        })
        .required(),
      relationship: yup.string().required(),
      occupation: yup.string().optional(),
      email: yup.string().email().optional(),
      residency: yup.string().required(),
      citizenship: yup.string().required(),
    }),
  ),
})

const RequestModalNew = ({
  isOpen,
  toggle,
  onConfirm,
  dependents,
  planeName,
  req,
  data,
}) => {
  const countries = useSelector(
    (state) => state?.Layout?.staticData?.countries ?? [],
  )
  const otherCountries = useSelector(
    (state) => state?.Layout?.staticData?.other_countries ?? [],
  )
  const nationalities = [...countries, ...otherCountries]

  const user = useSelector((state) => state?.userProfile?.userProfile)
  const defaultCountry =
    countries && countries !== undefined
      ? countries?.find((p) => p.id.toString() === data?.country_id)
      : []
  const isMobile = useResize()
  const defaultValues = req
    ? {
        firstName: req?.firstName,
        lastName: req?.lastName,
        birthDate: req?.birthDate,
        email: req?.email,
        gender: req?.gender,
        residency: req?.residency?.id,
        citizenship: req?.citizenship?.id,
      }
    : {
        firstName: user?.first_name,
        lastName: user?.last_name,
        birthDate: user?.birth_date,
        email: user?.email,
        residency: defaultCountry?.iso2,
        citizenship: defaultCountry?.iso2,
        dependents: dependents?.slice(0, -1).map(() => ({
          residency: defaultCountry?.iso2,
          citizenship: defaultCountry?.iso2,
        })),
      }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(insuranceFormSchema),
    defaultValues,
  })

  useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'dependents', // unique name for your Field Array
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size='lg'>
      <ModalHeader toggle={toggle}>Review & Submit request</ModalHeader>
      <ModalBody>
        <div className='p-3 p-md-4'>
          <p className='font-size-16 m-0' style={{ color: '#4A4E5B' }}>
            Please review your personal details before submitting your request.
          </p>
        </div>
        <form onSubmit={handleSubmit(onConfirm)}>
          <Row className='p-0 m-0 pb-2 pt-3'>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='firstName'>
                  First Name
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.firstName}
                  name='firstName'
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='lastName'>
                  Last Name
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.lastName}
                  name='lastName'
                  disabled
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Gender</Label>
                <ControlledSelect
                  error={errors.gender}
                  control={control}
                  name='gender'
                  options={[
                    { label: 'Female', value: 'FEMALE' },
                    { label: 'Male', value: 'MALE' },
                  ]}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='mb-0'>
                <Label className='font-size-14'>Birth Date</Label>
                <ControlledDatePicker
                  disabled
                  control={control}
                  name='birthDate'
                  error={errors.birthDate}
                  maxDate={subYears(new Date(), 18)}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='email'>
                  Email
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.email}
                  name='email'
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Citizenship</Label>
                <ControlledSelect
                  error={errors.citizenship}
                  control={control}
                  name='citizenship'
                  options={nationalities?.map((c) =>
                    mapCountryToOption(c, 'iso2'),
                  )}
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Residency</Label>
                <ControlledSelect
                  error={errors.residency}
                  control={control}
                  name='residency'
                  options={countries?.map((c) => mapCountryToOption(c, 'iso2'))}
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Plan</Label>
                <Input value={planeName} disabled />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline p-0 m-0'>
                <Label className='font-size-14'>Dependents</Label>
                <Input disabled value={dependents?.length - 1} />
              </FormGroup>
            </Col>
            <Col style={{ height: '80px' }} sm={12} className='d-md-none' />
          </Row>
          {dependents?.map((dependent, number) => {
            if (number === dependents.length - 1) return null
            else {
              return (
                <div key={number}>
                  <div className='d-flex align-items-center justify-content-start pl-4'>
                    <div>
                      <StyledH4 className='text-secondary'>{`Dependent ${
                        number + 1
                      }`}</StyledH4>
                    </div>
                    <div
                      className='bg-secondary ml-1'
                      style={{ height: '1px', width: '82%' }}
                    />
                  </div>
                  <EntryForm
                    key={`dependent-${number}`}
                    entry={dependent}
                    control={control}
                    number={number}
                    dependents={dependents}
                    countries={countries}
                    nationalities={nationalities}
                    errors={errors}
                    user={user}
                    setValue={setValue}
                    req={req}
                    defaultCountry={defaultCountry}
                  />
                </div>
              )
            }
          })}
          <div
            className={`modal-footer ${
              isMobile && 'fixed-bottom'
            } p-3 bg-white p-md-4 justify-content-between justify-content-md-end`}
          >
            <Button type='button' color='light' outline onClick={toggle}>
              Cancel
            </Button>

            <Button type='submit'>Submit Request</Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}
const EntryForm = ({
  control,
  number,
  countries,
  nationalities,
  errors,
  dependents,
  setValue,
  req,
  defaultCountry,
}) => {
  useController({
    name: `dependents.${number}.age`,
    control,
    defaultValue: dependents[number + 1],
  })
  useEffect(() => {
    setValue(
      `dependents.${number}.citizenship`,
      req ? req?.residency?.id : defaultCountry?.iso2,
    )
    setValue(
      `dependents.${number}.residency`,
      req ? req?.citizenship?.id : defaultCountry?.iso2,
    )
  }, [])

  return (
    <Row className='p-0 m-0 pb-2 pt-3'>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='firstName'>
            First Name
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.firstName?.message?.slice(14)
            }
            name={`dependents.${number}.firstName`}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='lastName'>
            Last Name
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.lastName?.message?.slice(14)
            }
            name={`dependents.${number}.lastName`}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Relationship</Label>
          <ControlledSelect
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.relationship?.message?.slice(14)
            }
            control={control}
            name={`dependents.${number}.relationship`}
            options={[
              { label: 'Partner', value: 'PARTNER' },
              { label: 'Child', value: 'CHILD' },
            ]}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='occupation'>
            Occupation
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.occupation?.message?.slice(14)
            }
            name={`dependents.${number}.occupation`}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='email'>
            Email
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.email?.message?.slice(14)
            }
            name={`dependents.${number}.email`}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Gender</Label>
          <ControlledSelect
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.gender?.message?.slice(14)
            }
            control={control}
            name={`dependents.${number}.gender`}
            options={[
              { label: 'Female', value: 'FEMALE' },
              { label: 'Male', value: 'MALE' },
            ]}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='mb-0'>
          <Label className='font-size-14'>Birth Date</Label>
          <ControlledDatePicker
            control={control}
            name={`dependents.${number}.birthDate`}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.birthDate?.message
            }
            maxDate={endOfDay(new Date())}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Citizenship</Label>
          <ControlledSelect
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.citizenship?.message?.slice(14)
            }
            control={control}
            name={`dependents.${number}.citizenship`}
            options={nationalities?.map((c) => mapCountryToOption(c, 'iso2'))}
          />
        </FormGroup>
      </Col>

      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Residency</Label>
          <ControlledSelect
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.residency?.message?.slice(14)
            }
            control={control}
            name={`dependents.${number}.residency`}
            options={countries?.map((c) => mapCountryToOption(c, 'iso2'))}
          />
        </FormGroup>
      </Col>
      <Col style={{ height: '80px' }} sm={12} className='d-md-none' />
    </Row>
  )
}

export default RequestModalNew
