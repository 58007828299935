import cx from 'classnames'
import { ArrowRight, X } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import {
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import Button from '../button'
import './style.scss'
import { useResize } from '../../../helpers/hooks'

/**
 * A custom modal used for onboarding with slider containing image, title, and text.
 *
 * @component
 * @param {boolean} isOpen - showing or hiding the modal
 * @param {function} onClose - The function to call when closing events is triggered.
 * @param {Array} slides - The array of slides to display.
 * @param {Object} carouselProps - props to be added to the carousel component
 * @param {function} onIndexChange - function will be fired when the index changes with the new index
 * @param {function} onNext - function will be fired when next button clicked and will return the current index
 * @param {function} onPrev - function will be fired when prev button clicked and will return the current index
 * @param {boolean} autoplay - enable/disable autoplay
 * @param {boolean} dots - show/hid carousel indicator dots
 * @param {number} interval - autoplay interval in milliseconds
 * @param {Object} customFooter - display custom footer on specific index object should look like :
 * ```
 * {
 *    index: number,
 *    component: JSX.Element,
 *    dots: boolean
 * }
 * ```
 * @returns {JSX.Element} The rendered modal component.
 */

function SliderModal({
  isOpen,
  slides,
  onClose,
  carouselProps,
  onIndexChange,
  onNext,
  onPrev,
  autoplay = false,
  interval = 5000,
  customFooter,
  dots = true,
  ...props
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const isMobile = useResize()

  const showCustomFooter =
    customFooter?.index !== null &&
    customFooter?.component != null &&
    activeIndex === customFooter?.index

  const hideDots = !dots || (showCustomFooter && !customFooter?.dots)

  useEffect(() => {
    onIndexChange?.(activeIndex)
  }, [activeIndex])

  const next = () => {
    if (animating) return
    if (activeIndex === slides.length - 1) return
    const nextIndex = activeIndex + 1
    onNext?.(nextIndex)
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    if (activeIndex === 0) return
    const nextIndex = activeIndex - 1
    onPrev?.(nextIndex)
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slidesComps = slides?.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        {item?.src ? (
          <img src={item.src} alt={item.altText} />
        ) : item?.content ? (
          item.content
        ) : null}
        <div className={cx('p-4 slide-text', item.wrapperClassname)}>
          <div
            className={cx(
              'font-weight-semibold text-secondary-100 mb-1',
              isMobile ? 'font-size-24' : 'font-size-32',
            )}
          >
            {item.title}
          </div>
          <div
            className={cx(
              'text-text-80',
              isMobile ? 'font-size-14' : 'font-size-16',
            )}
          >
            {item.description}
          </div>
        </div>
      </CarouselItem>
    )
  })
  return (
    <Modal
      isOpen={isOpen}
      centered={isMobile}
      className='slider-modal'
      {...props}
    >
      <ModalBody className='p-0'>
        <Button
          onClick={() => onClose(activeIndex)}
          className='position-absolute right-0 z-1000 btn font-size-24 p-0 m-4 rp-font-normal'
          color='transparent'
          icon={<X size={22} color='var(--secondary-100)' />}
        ></Button>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={autoplay ? interval : null}
          {...carouselProps}
        >
          {slidesComps}
        </Carousel>
      </ModalBody>
      <ModalFooter
        className={cx(
          'p-4 d-flex align-items-center justify-content-between position-relative',
          showCustomFooter ? customFooter.wrapperClassname : '',
        )}
      >
        {showCustomFooter ? (
          customFooter?.component
        ) : (
          <>
            {activeIndex !== 0 ? (
              <ModalFooterBtn outline onClick={previous}>
                Back
              </ModalFooterBtn>
            ) : (
              <div></div>
            )}
            <ModalFooterBtn
              onClick={next}
              iconRight={<ArrowRight className='ml-1' size={20} />}
            >
              Next
            </ModalFooterBtn>
          </>
        )}
        {!hideDots && (
          <CarouselIndicators
            className='slider-modal-indicators mb-4.5'
            items={slides}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
        )}
      </ModalFooter>
    </Modal>
  )
}

export function ModalFooterBtn({
  onClick,
  children,
  outline,
  iconRight,
  className,
  ...extraProps
}) {
  const isMobile = useResize()
  return (
    <Button
      onClick={onClick}
      outline={outline}
      className={cx(
        'z-1000 px-4',
        isMobile ? 'font-size-12' : 'font-size-14',
        {
          'text-secondary-100': outline,
        },
        className,
      )}
      color={outline && 'surface-30'}
      iconRight={iconRight}
      style={{ minHeight: isMobile ? 40 : 48 }}
      {...extraProps}
    >
      {children}
    </Button>
  )
}

export default SliderModal
