import { useSelector } from 'react-redux'
import { track } from '../../../utils/analytics'

export const BULK_CONTRACT_CREATION_EVENTS = {
  CLICKED_BULK_CREATION: 'Clicked Bulk Contract Upload',
  SELECTED_CONTRACT_TYPE: 'Selected Bulk Import Contract Type',
  DOWNLOADED_TEMPLATE: 'Downloaded Bulk Import Template',
  UPLOADED_CSV: 'Uploaded Bulk Import CSV',
  SUBMITTED_REQUEST: 'Submitted Bulk Import Request',
}

export const BULK_ADJUSTMENT_EVENTS = {
  CLICKED_BULK_UPDATE: 'Clicked Bulk Update',
  SELECTED_ADJUSTMENT_TYPE: 'Selected Bulk Adjustment Type',
  SELECTED_WORKERS: 'Selected Workers for Bulk Adjustment',
  DOWNLOADED_TEMPLATE: 'Downloaded Bulk Adjustment Template',
  UPLOADED_CSV: 'Uploaded Bulk Adjustment CSV',
  SUBMITTED_ADJUSTMENT: 'Submitted Bulk Adjustment Request',
}

export const useBulkContractActionEvents = () => {
  const { email, id } = useSelector((state) => state.Account?.user ?? {})
  const trackEvent = (event, additionalProperties = {}) => {
    track(event, { email_id: email, id, ...additionalProperties })
  }

  return { trackEvent }
}
