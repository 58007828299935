import React from 'react'
import { StyledH5 } from '../../../../components/Typo'
import { Col, Container, Row, Spinner } from 'reactstrap'
import XeroAccountMapping from '../XeroAccountMapping'

// eslint-disable-next-line react/display-name
const AccountMappingStep = ({
  onSubmitted = () => {},
  loading,
  platform = 'Xero',
}) => {
  return loading ? (
    <Container style={{ minHeight: '100vh' }}>
      <Col style={{ minHeight: '100vh' }}>
        <Row
          style={{ minHeight: '100vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' color='primary' />
        </Row>
      </Col>
    </Container>
  ) : (
    <Col className='py-3'>
      <div className='mb-3'>
        <StyledH5 className='font-size-16 rp-font-bold mb-3'>
          Select the {platform} account to use for each bill
        </StyledH5>
      </div>
      <Row className='align-items-center w-100 p-0 m-0'>
        <p className='text-muted'>
          Remotepass categories on the left will be posted to the {platform}{' '}
          accounts on the right
        </p>
      </Row>
      <XeroAccountMapping
        onSubmitted={onSubmitted}
        context='step'
        platform={platform}
      />
    </Col>
  )
}

export default AccountMappingStep
