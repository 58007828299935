import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Modal, ModalBody } from 'reactstrap'

import { ReactComponent as Earth } from '../../../assets/images/earth.svg'
import { ReactComponent as Integration } from '../../../assets/images/integration.svg'
import { ReactComponent as Messaging } from '../../../assets/images/messagerie.svg'
import { ReactComponent as Mobile } from '../../../assets/images/mobile.svg'
import { ReactComponent as Rating1 } from '../../../assets/images/rating1.svg'
import rating2 from '../../../assets/images/rating_green.png'

const items = [
  {
    text: 'Onboard & Pay contractors and employees in 150+ countries in full compliance.',
    icon: <Earth />,
  },
  {
    text: 'Empower your team with a super app with access to financial services and benefits.',
    icon: <Mobile />,
  },
  {
    text: 'Integrate with your HR and accounting system for an end-to-end seamless experience.',
    icon: <Integration />,
  },
]

const PreviewModal = ({
  isOpen,
  toggle,
  isCentered = true,
  onConfirm,
  loading,
}) => {
  const user = useSelector((state) => state.Account?.user)

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={isCentered}
      className='w-100'
    >
      <ModalBody className='px-0 p-0'>
        <div
          className='d-flex flex-column align-items-center p-3'
          style={{ backgroundColor: '#F4F5F7' }}
        >
          <div className='pb-3'>
            <img
              src='logo_main.png'
              style={{ height: 24, width: 'auto' }}
              alt='RemotePass Logo'
            />
          </div>
          <Card className='p-4 p-md-0 rp-shadow-2 w-100'>
            <div className='d-flex flex-column align-items-center py-4'>
              <Messaging />
              <div className='font-size-24'>
                {user?.first_name} thinks you’ll love RemotePass
              </div>
              <div
                className='font-size-16 font-weight-400 text-center p-4'
                style={{ color: '#424652' }}
              >
                Thanks to {user?.first_name}, we’re giving you a 1 month FREE
                trial. RemotePass is a platform for companies to onboard, manage
                & pay remote contractors and employees.
              </div>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{
                  width: '251px',
                  height: '48px',
                  background: '#114EF7',
                  borderRadius: '4px',
                  color: '#FFFFFF',
                  mixBlendMode: 'normal',
                }}
              >
                Accept invite
              </div>
              <div className='font-size-14 text-primary pt-2'>Learn more</div>
            </div>
          </Card>
          <Card className='p-4 p-md-0 rp-shadow-2 w-100'>
            <div className='d-flex flex-column align-items-center py-4 px-2'>
              {items?.map((item, i) => {
                return (
                  <div
                    className='d-flex align-items-center justify-content-between'
                    key={i}
                  >
                    {item?.icon}
                    <div className='pl-4'>{item?.text}</div>
                  </div>
                )
              })}
            </div>
            <div
              className='d-flex align-items-center justify-content-center font-size-24 text-center p-3'
              style={{ background: 'rgba(34, 83, 238, 0.09)' }}
            >
              <p className='w-75'>Onboard & pay anyone, from anywhere!</p>
            </div>
          </Card>
          <Card className='p-4 p-md-0 rp-shadow-2 w-100'>
            <div className='d-flex flex-column align-items-center py-4 px-2'>
              <div className='font-size-24'>Our clients love RemotePass</div>
              <div className='d-flex align-items-center justify-content-around w-100'>
                <div>
                  <Rating1 />
                </div>
                <div>
                  <img src={rating2} style={{ height: 54.91, width: 150 }} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </ModalBody>
      <div className='modal-footer' style={{ backgroundColor: '#F4F5F7' }}>
        <button type='button' className='btn btn-light' onClick={toggle}>
          Cancel
        </button>
        <button
          type='button'
          className='btn btn-primary'
          data-dismiss='modal'
          onClick={onConfirm}
          loading={loading}
        >
          Confirm
        </button>
      </div>
    </Modal>
  )
}
export default PreviewModal
