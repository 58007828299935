import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import ControlledSelect from '../../../../../components/ControlledSelect'
import ControlledInput from '../../../../../components/ControlledInput'
import ControlledDatePicker from '../../../../../components/ControlledDatePicker'
import { useFetch } from '../../../../../helpers/hooks'
import {
  addImmigrationDocument,
  uploadTempFileAdmin,
} from '../../../../../services/api'
import {
  FILE_SIZE_LIMITS_IN_BYTES,
  MAX_SIZE_5MB_READABLE,
} from '../../../../Contract/utils/constants'
import toastr from 'toastr'
import Loader from '../../../../../components/ui/loader'
import { format } from 'date-fns'
import DataTable from '../../../../../components/ui/data-table'
import openFileV2 from '../../../../../utils/file/open-v2'
import DropzoneInput from '../../../../../components/Common/dropzone-input'

const schema = yup.object().shape({
  identifier: yup.string().required('Identifier is required'),
  type: yup.string().required('Document type is required'),
  name: yup.string().when('type', {
    is: (val) => val === 'other',
    then: (schema) => schema.required('Document name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  issue_date: yup.date().required('Issue date is required'),
  expiry_date: yup.date().required('Expiry date is required'),
  file: yup.mixed().required('Document file is required'),
})

function ImmigrationStep({ documents, contractId, refetch }) {
  const [uploadModal, setUploadModal] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const { startFetch: uploadFile, isLoading: isUploading } = useFetch({
    action: uploadTempFileAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      setValue('file', data?.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const { startFetch: submitDocument, isLoading: isSubmitting } = useFetch({
    action: addImmigrationDocument,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Document added successfully')
      setUploadModal(false)
      refetch?.()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const onSubmit = (data) => {
    submitDocument({
      ...data,
      fulltime_contract_id: contractId,
      issue_date: format(data.issue_date, 'yyyy-MM-dd'),
      expiry_date: format(data.expiry_date, 'yyyy-MM-dd'),
    })
  }

  const columns = [
    {
      Header: 'Document Name',
      accessor: 'name',
    },
    {
      Header: 'Identifier',
      accessor: 'identifier',
    },
    {
      Header: 'Document Type',
      accessor: 'type',
    },
    {
      Header: 'Issue Date',
      accessor: 'issue_date',
    },
    {
      Header: 'Expiry Date',
      accessor: 'expiry_date',
    },
    {
      Header: 'Actions',
      Cell: ({ rowData }) => {
        return (
          <Button
            onClick={() =>
              openFileV2(rowData.file, {
                name: rowData.name,
                isDataUrl: true,
              })
            }
            size='sm'
          >
            Download
          </Button>
        )
      },
    },
  ]
  return (
    <div>
      {documents?.length > 0 && (
        <div className='tw-mb-6'>
          <DataTable responsive columns={columns} data={documents} />
        </div>
      )}
      <Button onClick={() => setUploadModal(true)} color='primary' block>
        Upload Document
      </Button>
      <Modal isOpen={uploadModal} toggle={() => setUploadModal(false)}>
        <ModalHeader toggle={() => setUploadModal(false)}>
          Add Immigration Document
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Label
              htmlFor='identifier'
              md='3'
              className='col-form-label p-0 m-0 mb-2 font-size-14'
            >
              Identifier
              <span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <ControlledInput
              control={control}
              wrapperClassName='tw-mb-4'
              name='identifier'
              error={errors?.name}
            />

            <ControlledSelect
              control={control}
              error={errors?.type}
              name='type'
              wrapperClassName='tw-mb-4'
              required
              label='Document Type'
              options={[
                {
                  label: 'Work Permit',
                  value: 'work permit',
                },
                {
                  label: 'Work Visa',
                  value: 'work visa',
                },
                {
                  label: 'Other',
                  value: 'other',
                },
              ]}
            />
            {watch('type') === 'other' && (
              <>
                <Label
                  htmlFor='name'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Document Name
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  wrapperClassName='tw-mb-4'
                  name='name'
                  error={errors?.name}
                />
              </>
            )}
            <Label
              htmlFor='issue_date'
              md='3'
              className='col-form-label p-0 m-0 mb-2 font-size-14'
            >
              Issue Date
              <span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <ControlledDatePicker
              control={control}
              name='issue_date'
              error={errors?.issue_date}
              maxDate={new Date()}
            />
            <Label
              htmlFor='expiry_date'
              md='3'
              className='col-form-label p-0 m-0 mb-2 font-size-14'
            >
              Expiry Date
              <span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <ControlledDatePicker
              control={control}
              name='expiry_date'
              error={errors?.issue_date}
              minDate={new Date()}
            />
            <DropzoneInput
              className='tw-mt-4'
              onDropAccepted={(acceptedFiles) => {
                uploadFile({
                  file: acceptedFiles[0],
                  type: 'immigration_documents',
                })
              }}
              accept='application/pdf'
              maxSize={FILE_SIZE_LIMITS_IN_BYTES.FIVE_MB}
              error={errors?.file?.message}
            >
              <div className='tw-flex tw-h-[100px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center'>
                {isUploading ? (
                  <Loader />
                ) : watch('file') ? (
                  <>
                    <span className='tw-text-xl'>File Uploaded</span>
                    <span className='tw-text-sm tw-text-text-80'>
                      {watch('file')}
                    </span>
                  </>
                ) : (
                  <>
                    <span className='tw-text-xl'>Drop file or click here</span>
                    <span className='tw-text-sm tw-text-text-60'>
                      Max file size {MAX_SIZE_5MB_READABLE}
                    </span>
                  </>
                )}
              </div>
            </DropzoneInput>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

export default ImmigrationStep
