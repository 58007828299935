import React from 'react'
import { useSelector } from 'react-redux'

import { UserSidebarContent } from './user-sidebar-content'

export default function Sidebar() {
  const leftSideBarType = useSelector((state) => state.Layout?.leftSideBarType)

  if (leftSideBarType === 'condensed') return null

  return (
    <div className='tw-fixed tw-bottom-0 tw-top-[--header-height] tw-z-10 tw-mt-0 tw-hidden tw-w-[--user-sidebar-width] tw-border-r tw-border-surface-30 tw-bg-white lg:tw-block'>
      <UserSidebarContent />
    </div>
  )
}
