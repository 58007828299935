import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'

import paymentConfirmation from '../../assets/images/payment_confirmation.svg'
import ModalHeader from '../../components/ModalHeader'
import Button from '../../components/ui/button'

export default function ReviewPaymentsConfirmed() {
  return (
    <Container fluid className='px-0' style={{ minHeight: '100vh' }}>
      <ModalHeader noExit />

      <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1 pb-5'>
        <img src={paymentConfirmation} alt='' />
        <h2 className='text-gray-h'>Payments approved</h2>
        <p className='text-muted font-size-16 text-center'>
          Admins will be informed to process the payments
        </p>
        <Button tag={Link} to='/activity' className='mt-4'>
          Back to dashboard
        </Button>
      </div>
    </Container>
  )
}
