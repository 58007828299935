import {
  ArrowSquareOut,
  CheckCircle,
  FlowArrow,
  Invoice,
  List,
  Plus,
  TrashSimple,
  UsersThree,
  Warning,
  WarningOctagon,
} from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import ApprovalFlowNameList from '../../../components/approval-flow-name-list'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledSelect from '../../../components/ControlledSelect'
import { ControlledToggle } from '../../../components/Forms/Toggle/controlled-toggle'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PrimaryAlert } from '../../../components/ui/alert'
import BadgeV2 from '../../../components/ui/badge-v2'
import { Box, BoxIcon } from '../../../components/ui/box'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  createApprovalFlow,
  deleteApprovalFlow,
  getApprovalFlowContracts,
  getApprovalFlows,
  getUsers,
  updateApprovalFlow,
  updateCompanyInfo,
} from '../../../services/api'
import { getBillApprovalFlow } from '../../../services/api-bill-payments'
import { updateProfileCompany } from '../../../store/profile/actions'
import { LoadingFlowSteps } from '../../Contract/ContractPage/payment-approvals'
import { PERMISSION_GROUP } from '../manage-role'
import ApprovalFlowForm, { getUsersList } from './approval-flow-form'
import BillApprovalActionButton from './bill-approval-action-button'
import DefaultApprovalFlow from './default-approval-flow'
import PayrollApprovalModal from './payroll-approval-modal'
import PayrollApprovalToggle from './payroll-approval-toggle'

export function ApprovalsTab() {
  const dispatch = useDispatch()
  const [payrollModalOpen, setPayrollModalOpen] = useState(false)

  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const payrollApprovalEnabled =
    company?.is_payroll_approval_enabled?.toString() === '1'
  const isBillEnabled = !!company?.is_bill_enabled

  const { startFetch: updateCompany, isLoading } = useFetch({
    action: updateCompanyInfo,
    onComplete: (data) => {
      dispatch(updateProfileCompany(data))
      setPayrollModalOpen(false)
    },
  })

  const {
    data: approvalFlows,
    startFetch: refreshApprovalFlows,
    isLoading: loadingApprovalFlows,
  } = useFetch({
    action: getApprovalFlows,
    autoFetch: true,
  })

  const {
    data: billApprovalFlow,
    startFetch: _getBillApprovalFlow,
    isLoading: fetchingBillApprovalFlow,
  } = useFetch({
    action: getBillApprovalFlow,
    autoFetch: true,
    onError: (error) => toastr.error(error),
  })

  return (
    <div style={{ minHeight: 'calc(422px - 72px)' }} className='tw-py-6'>
      <div className='tw-px-6'>
        <div className='tw-mb-6 tw-flex tw-flex-wrap tw-gap-3 *:tw-w-full lg:tw-grid lg:tw-grid-cols-2'>
          <span className='tw-flex tw-flex-col'>
            <h4 className='tw-mb-0.5 tw-flex tw-items-center tw-gap-2 tw-text-base tw-font-bold'>
              <span>Default Approval</span>
              <a
                href='https://help.remotepass.com/en/articles/8769367-default-approvals-approval-flows'
                target='_blank'
                rel='noreferrer'
                className='tw-text-current'
                title='Learn more about default approval'
              >
                <ArrowSquareOut size={20} />
              </a>
            </h4>

            <p className='tw-mb-4 tw-flex-1 tw-text-sm tw-text-text-80'>
              As a default setting, the 1-level approval flow is assigned to new
              contracts
            </p>
            <span className='tw-flex tw-gap-4'>
              <DefaultApprovalFlow
                icon={<UsersThree size={24} />}
                cta={<PayrollApprovalToggle />}
                description='Require approval for all payments related to contracts'
                infoId='main'
                tip='Contracts are assigned to the default approval, where any of the assigned approvers can approve expenses, time off requests and work submissions.'
                title='Default Flow'
                subtitle='Assigned users can approve contract items'
              />
              {FEATURE_FLAGS.BILL_PAYMENTS ? null : (
                // @todo: This can be deleted after bill payments is pushed to prod
                <PrimaryAlert className='tw-flex-1 tw-items-center tw-gap-4'>
                  Contracts are assigned to the default approval, where any of
                  the assigned approvers can approve expenses, time off
                  requests, and work submissions.
                </PrimaryAlert>
              )}
            </span>
          </span>

          {FEATURE_FLAGS.BILL_PAYMENTS && isBillEnabled ? (
            <span>
              <h4 className='tw-mb-0.5 tw-flex tw-items-center tw-gap-2 tw-text-base tw-font-bold'>
                <span>Bill Approval</span>
                {/*
                // @ todo: Should be uncommented when article is available
               <a
                href='https://help.remotepass.com/en/articles/8769367-default-approvals-approval-flows'
                target='_blank'
                rel='noreferrer'
                className='tw-text-current'
                title='Learn more about bill approval'
              >
                <ArrowSquareOut size={20} />
              </a> */}
              </h4>

              <p className='tw-mb-4 tw-text-sm tw-text-text-80'>
                The default approval flow applies to all bills. You can
                customize it for each vendor from the vendor details.
              </p>
              <DefaultApprovalFlow
                icon={<Invoice size={24} />}
                cta={
                  <BillApprovalActionButton
                    approvalFlows={approvalFlows}
                    fetchingApprovalFlows={loadingApprovalFlows}
                    approvalFlow={billApprovalFlow}
                    onApprovalFlowChange={_getBillApprovalFlow}
                    key={billApprovalFlow}
                  />
                }
                description='My custom bill approval flow'
                infoId='bill'
                tip='Bills are assigned to the default approval, where any of the assigned approvers can approve bills.'
                title={
                  billApprovalFlow?.name ??
                  'You haven’t assigned a default flow for Bill Pay yet'
                }
                subtitle={
                  billApprovalFlow?.id
                    ? `${
                        billApprovalFlow?.steps?.length
                      }-level sequential approval flow`
                    : 'Assigned users can approve bills'
                }
                isLoading={fetchingBillApprovalFlow}
                nameList={
                  <ApprovalFlowNameList
                    nameList={billApprovalFlow?.steps?.map(
                      (step) => step.user_name,
                    )}
                  />
                }
              />
            </span>
          ) : null}
        </div>

        <hr className='-tw-mx-6 tw-my-8' />

        <CustomApprovalFlows
          approvalFlows={approvalFlows}
          refreshApprovalFlows={refreshApprovalFlows}
          loadingApprovalFlows={loadingApprovalFlows}
          billApprovalFlow={billApprovalFlow}
        />
      </div>

      {!payrollModalOpen ? null : (
        <PayrollApprovalModal
          isOpen={payrollModalOpen}
          toggle={() => setPayrollModalOpen((o) => !o)}
          newValue={!payrollApprovalEnabled}
          isLoading={isLoading}
          onConfirm={({ sendEmail }) => {
            const body = {
              is_payroll_approval_enabled: !payrollApprovalEnabled ? 1 : 0,
            }
            if (sendEmail) {
              body.send_payroll_approval_enabled_notification = true
            }
            updateCompany(body)
          }}
        />
      )}
    </div>
  )
}

function CustomApprovalFlows({
  approvalFlows,
  refreshApprovalFlows,
  loadingApprovalFlows,
  billApprovalFlow,
}) {
  const approvalFormatted =
    approvalFlows?.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        approvers: item?.steps?.map((step) => ({
          id: step?.user_id,
          can_override: !!step?.can_override,
        })),
        description: `${
          item?.steps?.length ?? 1
        }-level sequential approval flow`,
        usedForBills: item?.bills > 0,
        usedForContracts: item?.contracts > 0,
      }
    }) ?? []

  function updateFlows() {
    refreshApprovalFlows?.()
  }

  const { hasAccess } = usePermissions()

  const canManageApprovals = hasAccess(permissions.manageCompanySettings)

  return (
    <>
      <h4 className='tw-mb-0.5 tw-flex tw-items-center tw-gap-2 tw-text-base tw-font-bold'>
        <span>Custom Approval Flows</span>
        <a
          href='https://help.remotepass.com/en/articles/8769367-default-approvals-approval-flows'
          target='_blank'
          rel='noreferrer'
          className='tw-text-current'
          title='Learn more about multi-level approval flows'
        >
          <ArrowSquareOut size={20} />
        </a>
      </h4>
      <p className='tw-mb-4 tw-text-sm tw-text-text-80'>
        Create multi-level approval flows
      </p>

      {loadingApprovalFlows ? (
        <LoadingFlowSteps
          length={2}
          itemHeight='74px'
          className='tw-grid tw-items-start tw-gap-3 !tw-border-0 md:tw-grid-cols-2'
          itemClassName='tw-border tw-border-surface-30 tw-rounded'
        />
      ) : (
        <div className='tw-grid tw-items-start tw-gap-3 md:tw-grid-cols-2'>
          {canManageApprovals ? (
            <AddApprovalFlow updateFlows={updateFlows} />
          ) : null}

          {approvalFormatted.map((flow) => {
            return (
              <ApprovalFlowItem
                key={flow.id}
                flow={flow}
                updateFlows={updateFlows}
                billFlow={billApprovalFlow}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

function ApprovalFlowItem({ flow, updateFlows, billFlow }) {
  const [flowIsOpen, setFlowIsOpen] = useState(false)
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  function toggle() {
    setFlowIsOpen((open) => !open)
  }

  const { startFetch: updateFlow, isLoading: isUpdatingFlow } = useFetch({
    action: updateApprovalFlow,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        toastr.success('Approval flow updated successfully')
        toggle?.()
        setShowUpdateConfirm(false)
        updateFlows?.()
      }
    },
    onError: (error) => {
      toastr.error(error || 'Error updating approval flow')
    },
  })

  const { startFetch: deleteFlow, isLoading: isDeletingFlow } = useFetch({
    action: deleteApprovalFlow,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        toastr.success('Approval flow deleted successfully')
        toggle?.()
        setShowDeleteConfirm(false)
        updateFlows?.()
      }
    },
    onError: (error) => {
      toastr.error(error || 'Error deleting approval flow')
    },
  })

  const {
    data: flowContracts,
    startFetch: getFlowContracts,
    isLoading: gettingFlowContracts,
  } = useFetch({
    action: getApprovalFlowContracts,
  })

  function handleShowDeleteConfirm() {
    getFlowContracts({ id: flow.id })
    setShowDeleteConfirm({ id: flow.id })
  }

  function handleSubmit(data) {
    const diffName = data?.name !== flow.name

    const diffApprovers =
      data?.approvers.length !== flow.approvers.length ||
      (data?.approvers?.some(
        (approver, index) =>
          approver.id !== flow.approvers[index]?.id ||
          approver.can_override !== flow.approvers[index]?.can_override,
      ) ??
        false)

    if (!diffName && !diffApprovers) {
      toastr.info('No changes detected', undefined, { timeOut: 1000 })
    } else {
      getFlowContracts({ id: flow.id })
      setShowUpdateConfirm({ ...data, id: flow.id })
    }
  }

  const { hasAccess } = usePermissions()
  const canManageApprovals = hasAccess(permissions.manageCompanySettings)
  const isBillFlow = flow.usedForBills

  return (
    <>
      <PermissionTooltip
        showing={!canManageApprovals}
        id={`add-user-btn-tooltip-${flow?.id}`}
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <Box noPadding>
          <button
            type='button'
            className={cn(
              'tw-flex tw-items-center tw-gap-4',
              'tw-w-full tw-rounded tw-bg-primary-10 tw-p-4 tw-text-left tw-transition-colors tw-duration-75 hover:tw-bg-primary-20',
              { '!tw-cursor-default': !canManageApprovals },
            )}
            onClick={canManageApprovals ? toggle : null}
          >
            <BoxIcon className='!tw-bg-primary-30'>
              <FlowArrow size={24} className='tw-fill-primary-100' />
            </BoxIcon>

            <div className='tw-flex-1'>
              <p className='tw-mb-0 tw-break-all tw-font-bold tw-text-black'>
                {flow.name}
              </p>
              <p className='tw-mb-0 tw-mt-0.5 tw-text-secondary-100'>
                {flow.description}
              </p>
            </div>

            <span className='tw-mr-2 tw-flex tw-gap-2'>
              {flow.usedForContracts && (
                <BadgeV2
                  name='contracts'
                  className='!tw-bg-systemGreen-20 !tw-text-systemGreen'
                />
              )}
              {isBillFlow && (
                <BadgeV2
                  name='bills'
                  className='!tw-bg-systemBlue-20 !tw-text-systemBlue-110'
                />
              )}
            </span>

            {canManageApprovals && (
              <div className='tw-ml-auto'>
                <Button tag='div' color='link' className='!tw-px-0'>
                  Edit
                </Button>
              </div>
            )}
          </button>
        </Box>
      </PermissionTooltip>

      <ApprovalFlowSlider
        state={{
          show: flowIsOpen,
          data: {
            flowName: flow.name,
            approvers:
              flow.approvers?.length > 0
                ? flow.approvers.map((approver) => ({
                    userId: approver.id,
                    canOverride: approver.can_override,
                  }))
                : [{ userId: null }],
          },
        }}
        toggle={toggle}
        updateFlows={updateFlows}
        onSubmit={handleSubmit}
        onDelete={handleShowDeleteConfirm}
        actionsLoading={isUpdatingFlow || isDeletingFlow}
      />

      {showUpdateConfirm ? (
        <ConfirmationModal
          isOpen={!!showUpdateConfirm}
          content={
            <>
              <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
                <WarningOctagon size={24} className='tw-fill-systemGold-100' />

                <ModalCloseButton toggle={() => setShowUpdateConfirm(false)} />
              </div>

              <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                Do you want to save these changes?
              </p>
              <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
                This flow is applied to {flowContracts?.length} contract
                {flowContracts?.length === 1 ? '' : 's'}, all pending items will
                be approved with the new changes.
              </p>

              {isBillFlow ? (
                <p className='tw-mb-0 tw-mt-3 tw-text-sm tw-text-text-80'>
                  This approval flow is used for some bills. Changes in the flow
                  will reflect to all bills that are in the Draft and Pending
                  Approval statuses.
                </p>
              ) : null}
            </>
          }
          onConfirm={() => updateFlow(showUpdateConfirm)}
          toggle={() => setShowUpdateConfirm(false)}
          loading={gettingFlowContracts}
          confirmLoading={isUpdatingFlow}
          caption='Apply changes'
          negativeCaption='Don’t save'
        />
      ) : null}

      {showDeleteConfirm ? (
        <ConfirmationModal
          isOpen={!!showDeleteConfirm}
          content={
            <>
              <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
                <Warning size={24} className='tw-fill-red-100' />

                <ModalCloseButton toggle={() => setShowDeleteConfirm(false)} />
              </div>

              <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                Do you want to delete “{flow.name}”?
              </p>
              {isBillFlow ? (
                <p className='tw-text-sm tw-text-text-80'>
                  This flow cannot be deleted because it’s the default approval
                  flow for Bills. To delete this flow, please assign another
                  approval flow for Bills.
                </p>
              ) : (
                <p className='tw-text-sm tw-text-text-80'>
                  This flow is applied to{' '}
                  {flowContracts?.length === 0 ? 'no' : flowContracts?.length}{' '}
                  contract
                  {flowContracts?.length === 1 ? '' : 's'}, deleting it will
                  automatically reset the approval flow to default approver.
                </p>
              )}
            </>
          }
          negativeCaption={isBillFlow ? 'Close' : undefined}
          hideConfirmButton={isBillFlow}
          onConfirm={() => deleteFlow({ id: flow.id })}
          toggle={() => setShowDeleteConfirm(false)}
          loading={gettingFlowContracts}
          confirmLoading={isDeletingFlow}
          caption='Delete flow'
          buttonColor='danger'
        />
      ) : null}
    </>
  )
}

function AddApprovalFlow({ updateFlows }) {
  const [flowIsOpen, setFlowIsOpen] = useState(false)

  function toggle() {
    setFlowIsOpen((open) => !open)
  }

  const { startFetch: createFlow, isLoading: isCreatingFlow } = useFetch({
    action: createApprovalFlow,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        toastr.success('Approval flow created successfully')
        toggle?.()
        updateFlows?.()
      }
    },
    onError: (error) => {
      toastr.error(error || 'Error creating approval flow')
    },
  })

  return (
    <>
      <Box noPadding borderDashed>
        <button
          type='button'
          className='tw-flex tw-w-full tw-items-center tw-gap-4 tw-p-4 tw-text-left hover:tw-bg-surface-10'
          onClick={toggle}
        >
          <BoxIcon>
            <FlowArrow size={24} />
          </BoxIcon>

          <div>
            <p className='tw-mb-0 tw-flex tw-items-center tw-gap-1 tw-font-bold tw-text-black'>
              Add flow
            </p>
            <p className='tw-mb-0 tw-mt-0.5 tw-text-secondary-100'>
              Create a sequence of approvers
            </p>
          </div>

          <div className='ml-auto'>
            <Plus size={24} />
          </div>
        </button>
      </Box>

      <ApprovalFlowSlider
        state={{ show: flowIsOpen }}
        toggle={toggle}
        updateFlows={updateFlows}
        onSubmit={createFlow}
        actionsLoading={isCreatingFlow}
      />
    </>
  )
}

const approvalFlowFormId = 'approvalFlowForm'
function ApprovalFlowSlider({
  state,
  toggle,
  onSubmit,
  actionsLoading,
  onDelete,
}) {
  const { data: users, isLoading: usersLoading } = useFetch(
    { action: getUsers, autoFetch: state.show },
    [state.show],
  )

  if (!state.show) {
    return null
  }

  return (
    <SideMenu
      onClose={toggle}
      isOpen={state.show}
      className='!tw-w-full tw-max-w-[532px]'
      itemListClassName='tw-grid tw-grid-rows-[77px_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
    >
      <SideMenuHeader toggle={toggle}>Approval flow</SideMenuHeader>
      <SideMenuBody>
        {usersLoading ? (
          <Loader className='tw-h-full' />
        ) : (
          <ApprovalFlowForm
            formId={approvalFlowFormId}
            usersList={getUsersList(users)}
            onSubmit={onSubmit}
            defaultValues={state.data}
          />
        )}
      </SideMenuBody>
      <SideMenuFooter>
        {onDelete && typeof onDelete === 'function' ? (
          <Button
            color='link'
            className='tw-mr-auto !tw-px-0 !tw-text-red-100'
            outline
            onClick={onDelete}
            disabled={actionsLoading || usersLoading}
            loading={actionsLoading}
          >
            Delete
          </Button>
        ) : null}
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={actionsLoading || usersLoading}
        >
          Cancel
        </Button>
        <Button
          formId={approvalFlowFormId}
          disabled={actionsLoading || usersLoading}
          loading={actionsLoading}
        >
          Save
        </Button>
      </SideMenuFooter>
    </SideMenu>
  )
}

function getDragAfterElement(draggableElements, clientY) {
  return draggableElements.reduce(
    (closest, child) => {
      const box = child.getBoundingClientRect()
      const offset = clientY - box.top - box.height

      if (offset < 0 && offset > closest.offset) {
        return { offset, element: child }
      } else {
        return closest
      }
    },
    { offset: Number.NEGATIVE_INFINITY },
  ).element
}

const defaultApprover = { userId: null, canOverride: false }
export function ControlledApprovers({
  control,
  name,
  label,
  usersList,
  wrapperClassName,
}) {
  const { fields, append, insert, remove, move } = useFieldArray({
    control,
    name,
  })

  const notDisabledUsersList = usersList?.filter((user) => !user.isDisabled)
  const isFull = fields?.length >= notDisabledUsersList?.length

  useEffect(() => {
    // Add default approver if there are no approvers
    if (fields.length === 0) {
      append(defaultApprover)
    }
  }, [append, fields.length])

  function handleOnDragOver(event) {
    event.preventDefault()

    const draggableElements = [
      ...event.currentTarget.querySelectorAll('[draggable]'),
    ]

    const afterElement = getDragAfterElement(draggableElements, event.clientY)

    const draggingElement = draggableElements.find(
      (element) => element.dataset.dragging === 'true',
    )
    const fromIndex = draggingElement?.dataset?.itemIndex

    if (!afterElement) {
      const toLastPosition = fields.length - 1
      move(fromIndex, toLastPosition)
    } else {
      const toAfterElementIndex = Number(afterElement.dataset.itemIndex)
      move(fromIndex, toAfterElementIndex)
    }
  }

  return (
    <div className={wrapperClassName}>
      {!label ? null : <label>{label}</label>}

      <div onDragOver={handleOnDragOver}>
        {fields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              <div className='tw-flex tw-items-center tw-gap-4' key={field.id}>
                <ControlledApprover
                  control={control}
                  name={`${name}.${index}`}
                  index={index}
                  label='User'
                  usersList={usersList}
                  remove={() => remove(index)}
                />
              </div>
              <div className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
                <div className='tw-h-2 tw-w-px tw-bg-surface-30' />

                <button
                  type='button'
                  onClick={() => insert(index + 1, defaultApprover)}
                  className='tw-rounded-full tw-border tw-border-surface-30 tw-p-1 hover:tw-bg-surface-20 focus:tw-bg-surface-20 disabled:tw-bg-surface-10 disabled:tw-text-surface-90'
                  disabled={isFull}
                >
                  <Plus size={16} color='currentColor' />
                </button>

                <div className='tw-h-2 tw-w-px tw-bg-surface-30' />
                {index !== fields.length - 1 ? null : (
                  <>
                    <div className='tw-h-2 tw-w-px tw-bg-surface-30' />
                    <CheckCircle
                      size={28}
                      className='-tw-m-0.5 tw-fill-systemGreen-100'
                    />
                  </>
                )}
              </div>
            </React.Fragment>
          )
        })}

        {fields.length > 0 ? null : (
          <div className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
            <button
              type='button'
              onClick={() => append(defaultApprover)}
              className='tw-rounded-full tw-border tw-border-surface-30 tw-p-1'
            >
              <Plus size={16} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const colorCombos = {
  yellow: {
    border: 'tw-border-t-yellow-100',
    bg: 'tw-bg-yellow-10',
    avatar: '!tw-bg-yellow-30 !tw-text-yellow-120',
  },
  primary: {
    border: 'tw-border-t-primary-100',
    bg: 'tw-bg-primary-10',
    avatar: '!tw-bg-primary-30 !tw-text-primary-120',
  },
  green: {
    border: 'tw-border-t-green-100',
    bg: 'tw-bg-green-10',
    avatar: '!tw-bg-green-30 !tw-text-green-120',
  },
  secondary: {
    border: 'tw-border-t-secondary-100',
    bg: 'tw-bg-secondary-10',
    avatar: '!tw-bg-secondary-30 !tw-text-surface-120',
  },
  cyan: {
    border: 'tw-border-t-cyan-100',
    bg: 'tw-bg-cyan-10',
    avatar: '!tw-bg-cyan-30 !tw-text-cyan-120',
  },
  red: {
    border: 'tw-border-t-red-100',
    bg: 'tw-bg-red-10',
    avatar: '!tw-bg-red-30 !tw-text-red-120',
  },
}

const colorsArray = [
  colorCombos.yellow,
  colorCombos.primary,
  colorCombos.green,
  colorCombos.secondary,
  colorCombos.cyan,
  colorCombos.red,
]

function ControlledApprover({
  control,
  name,
  index,
  label,
  usersList,
  remove,
}) {
  const currentUser = useWatch({ control, name })
  const foundUser =
    usersList?.find((user) => user.value === currentUser?.userId) ?? null

  const allApprovers = useWatch({ control, name: 'approvers' })
  const allApproversIds = [...allApprovers]
    ?.filter((_, i) => i !== index)
    ?.map((approver) => approver.userId)
    ?.filter(Boolean)
  const filteredUsersList = usersList?.filter(
    (user) => !allApproversIds.includes(user.value),
  )

  const isLast = index === allApprovers.length - 1

  const colors = colorsArray[index % colorsArray.length]

  const isEditing = !currentUser?.userId
  const isViewing = !isEditing

  return (
    <div
      className={cn(
        'tw-min-h-[80px] tw-w-full',
        isEditing
          ? 'tw-rounded tw-border-2 tw-border-surface-90'
          : ['tw-rounded-t tw-border-t-4 tw-border-surface-30', colors.border],
      )}
    >
      <div
        className={cn(
          'tw-group tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-p-3 md:tw-gap-6 md:tw-p-4',
          isViewing && [
            'tw-overflow-hidden tw-border-x tw-border-b tw-border-surface-30',
            colors.bg,
            isLast ? '' : 'tw-rounded-b',
          ],
        )}
        draggable={!isEditing}
        data-item-index={index}
        onDragStart={(event) => {
          event.currentTarget?.classList?.add('tw-opacity-50')
          event.currentTarget.dataset.dragging = true
        }}
        onDragEnd={(event) => {
          event.currentTarget?.classList?.remove('tw-opacity-50')
          event.currentTarget.dataset.dragging = false
        }}
      >
        {isEditing ? (
          <ControlledSelect
            control={control}
            name={`${name}.userId`}
            label={label}
            options={filteredUsersList}
            wrapperClassName='tw-flex tw-w-full tw-items-center tw-gap-6'
            className='tw-flex-grow'
            labelClassName='tw-mb-0'
          />
        ) : (
          <>
            <div className='tw-flex tw-items-center tw-gap-2'>
              <Button
                color='link'
                className={cn(
                  '!tw-hidden !tw-cursor-move tw-rounded tw-border tw-border-transparent !tw-p-1.5 !tw-text-secondary-50 !tw-transition-all !tw-duration-300 hover:tw-border-secondary-30 hover:!tw-bg-secondary-20 focus:tw-border-secondary-30 focus:!tw-bg-secondary-20 md:!tw-flex',
                  'group-focus-within:tw-opacity-100 group-hover:tw-opacity-100',
                  'md:-tw-ml-[46px] md:tw-opacity-0 group-focus-within:md:-tw-ml-2 group-hover:md:-tw-ml-2',
                )}
                tag='div'
                icon={<List size={20} color='currentColor' />}
              />

              <Avatar
                photo={foundUser?.photo}
                name={foundUser?.fullName || foundUser?.email}
                className={colors.avatar}
              />

              <div className='tw-text-sm'>
                {!foundUser?.fullName ? null : (
                  <div className='tw-font-semibold'>{foundUser.fullName}</div>
                )}
                {!foundUser?.email ? null : (
                  <div className='tw-text-text-80'>{foundUser.email}</div>
                )}
              </div>
            </div>

            <Button
              color='link'
              className={cn(
                '!tw-p-2 !tw-text-red-100 !tw-transition-all !tw-duration-300 hover:!tw-bg-red-20 focus:!tw-bg-red-20',
                'md:tw-translate-x-2 md:tw-opacity-0 group-focus-within:md:tw-translate-x-0 group-hover:md:tw-translate-x-0',
                'group-focus-within:tw-opacity-100 group-hover:tw-opacity-100',
              )}
              onClick={remove}
              icon={<TrashSimple size={20} color='currentColor' />}
            />
          </>
        )}
      </div>

      {!isLast || isEditing ? null : (
        <ControlledToggle
          control={control}
          name={`${name}.canOverride`}
          inputClassName='tw-order-2'
          wrapperClassName='tw-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-b tw-border-x tw-border-b tw-border-surface-30 tw-p-4'
          label='Can override other approvals in this flow'
        />
      )}
    </div>
  )
}
