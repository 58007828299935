export default function openFile(
  data,
  name,
  nameSuffix = '',
  callBack = () => {},
  type,
) {
  const url = URL.createObjectURL(new Blob([data], { type }))

  const link = document.createElement('a')
  link.href = url
  link.download = name + nameSuffix

  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  link.remove()

  callBack()
}
