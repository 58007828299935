import React from 'react'
import { SimpleCard } from '../../../components/simple-card'
import { Row } from 'reactstrap'
import Toggle from '../../../components/Forms/Toggle/Toggle'

export default function VendorAutoSyncToggle({
  toggleCheck,
  onToggle,
  itemToSync,
  whenToSync,
}) {
  return (
    <SimpleCard className='tw-mb-4 tw-p-6'>
      <Row className='!tw-m-0 tw-items-center tw-p-0'>
        <div className='tw-mr-3'>
          <Toggle check={toggleCheck} change={onToggle} />
        </div>
        <div>
          <span className='tw-text-base tw-font-bold'>
            {itemToSync} auto sync
          </span>
          <p className='text-muted tw-m-0 tw-p-0'>{whenToSync}</p>
        </div>
      </Row>
    </SimpleCard>
  )
}
