import { BAMBOO_REQUEST, BAMBOO_UPDATE_CONTRACTORS } from './actionTypes'

export const bambooRequest = (data) => {
  return {
    type: BAMBOO_REQUEST,
    data,
  }
}

export const bambooUpdateContractors = (payload) => {
  return {
    type: BAMBOO_UPDATE_CONTRACTORS,
    payload,
  }
}
