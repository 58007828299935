import React from 'react'

import { cn } from 'ui'

export default function PageHeading({ children, className }) {
  return (
    <div
      className={cn(
        'tw-mb-6 tw-flex tw-flex-wrap tw--items-start tw-justify-between tw-gap-4',
        className,
      )}
    >
      {children}
    </div>
  )
}

function PageTitle({
  action,
  children,
  containerClassName,
  className,
  subtitle,
  subtitleClassName,
}) {
  return (
    <div
      className={cn(
        'tw-flex tw-gap-2',
        subtitle ? 'tw-min-h-[60px]' : 'tw-min-h-[44px]',
        containerClassName,
      )}
      style={{ '--heading-lh': '39px' }}
    >
      {!action ? null : (
        <div className='tw-flex tw-h-[--heading-lh] tw-w-8 tw-items-center tw-justify-center'>
          {action}
        </div>
      )}

      <div>
        <h1
          className={cn(
            'tw-mb-0 tw-text-3xl tw-leading-[--heading-lh]',
            className,
          )}
        >
          {children}
        </h1>

        {subtitle && (
          <p
            className={cn(
              'tw-mb-0 tw-text-base tw-text-text-60',
              subtitleClassName,
            )}
          >
            {subtitle}
          </p>
        )}
      </div>
    </div>
  )
}

export function PageAction({ children }) {
  return <>{children}</>
}

export function PageActionGroup({ children, className }) {
  return <div className={cn('tw-flex tw-gap-4', className)}>{children}</div>
}

PageHeading.Title = PageTitle
PageHeading.Action = PageAction
PageHeading.ActionGroup = PageActionGroup
