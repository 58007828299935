import React from 'react'

import { cn } from 'ui'

export default function StyledTh(props) {
  const { children, textRight, className = '' } = props
  return (
    <th
      className={cn(
        '!tw-border-t-0 !tw-p-4 tw-text-sm !tw-font-medium first:!tw-pl-6 last:!tw-pr-6',
        textRight ? 'tw-text-end' : '',
        className,
      )}
    >
      {children}
    </th>
  )
}
