import React from 'react'
import { Card, Container } from 'reactstrap'
import Shimmer from '../../../../../components/ui/shimmer'

const Loading = () => {
  return (
    <Container className='tw-mt-20'>
      <div className='tw-flex tw-justify-between'>
        <Shimmer width='100%' className='tw-my-4' />
      </div>
      <Card className='tw-p-6'>
        <Shimmer width='100%' className='tw-my-4' />
        <Shimmer width='100%' className='tw-my-4' />
        <Shimmer width='100%' className='tw-my-4' />
        <Shimmer width='100%' className='tw-my-4' />
      </Card>
    </Container>
  )
}
export default Loading
