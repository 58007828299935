import React from 'react'
import * as Yup from 'yup'
import { IdentificationCard, Person } from '@phosphor-icons/react'
export const TRIGGER_OCCURRENCE = {
  PENDING_DAYS: 'pending_days',
  AFTER_DAYS: 'after_days',
  BEFORE_DAYS: 'before_days',
  RANGE: 'range',
}

export const ACTION_TYPE = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
}

export const occurrenceDaysLabel = {
  [TRIGGER_OCCURRENCE.BEFORE_DAYS]: 'Days before',
  [TRIGGER_OCCURRENCE.AFTER_DAYS]: 'Days After',
  [TRIGGER_OCCURRENCE.PENDING_DAYS]: 'For how many days',
  [TRIGGER_OCCURRENCE.RANGE]: 'How many days?',
}

export const rangeOccurrences = [
  {
    label: 'Before',
    value: 'before',
    key: 'minutesBefore',
  },
  {
    label: 'After',
    value: 'after',
    key: 'minutesLong',
  },
]

export const triggers = [
  {
    label: 'Contract',
    icon: (props) => <IdentificationCard {...props} />,
    options: [
      {
        label: 'Contract Pending Worker Signature',
        value: 'CONTRACTOR_INVITED',
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Contract Pending Company Signature',
        value: 'CLIENT_INVITED',
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Contract Activated',
        value: 'CONTRACT_ACTIVATED',
      },
      {
        label: 'Contract Terminated',
        value: 'CONTRACT_TERMINATED',
      },
      {
        label: 'Contract Amended',
        value: 'CONTRACT_AMENDED',
      },
    ],
  },
  {
    label: 'People',
    icon: (props) => <Person {...props} />,
    options: [
      {
        label: 'On work anniversary',
        value: 'WORK_ANNIVERSARY',
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
      {
        label: 'On start date',
        value: 'WORKER_BIRTHDAY',
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
      {
        label: 'On Birthday',
        value: 'CONTRACT_START_DATE',
        occurrence: {
          type: TRIGGER_OCCURRENCE.BEFORE_DAYS,
          key: 'minutesBefore',
        },
      },
      {
        label: 'Direct Manager Changes',
        value: 'DIRECT_MANAGER_ASSIGNED',
      },
      {
        label: 'Title Changes',
        value: 'CONTRACT_TITLE_CHANGED',
      },
    ],
  },
]

export const actionTypes = [
  { label: 'Email', value: ACTION_TYPE.EMAIL },
  { label: 'Slack', value: ACTION_TYPE.SLACK },
]

export const statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Draft', value: 'draft' },
]

export const suggestedTemplate = {
  name: 'Custom automation',
  buttonText: 'Create your own',
  category: 'Suggested for you',
  custom: true,
  _id: 'custom-automation',
}

export const delayTypeOfDays = [
  { label: 'Calendar days', value: 'calendarDays' },
]

export const schema = Yup.object().shape({
  isPublished: Yup.boolean(),
  shouldValidate: Yup.boolean(),
  steps: Yup.array().when('shouldValidate', {
    is: true,
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('Type is required'),
          event: Yup.mixed().when('type', {
            is: 'trigger',
            then: () =>
              Yup.object().required('Event is required for trigger type'),
            otherwise: () => Yup.mixed().notRequired(),
          }),
          to: Yup.array()
            .of(Yup.string().required())
            .when('type', {
              is: 'action',
              then: () =>
                Yup.array()
                  .min(1, 'To must have more than one element for action type')
                  .required('To is required for action type'),
              otherwise: () => Yup.array().notRequired(),
            }),
          subject: Yup.string().when('type', {
            is: 'action',
            then: () =>
              Yup.string()
                .required('Subject is required for action type')
                .min(1, 'Must be more than 1 character'),
            otherwise: () => Yup.string().notRequired(),
          }),
          body: Yup.string().when('type', {
            is: 'action',
            then: () =>
              Yup.string()
                .required('Body is required for action type')
                .min(1, 'Must be more than 1 character'),
            otherwise: () => Yup.string().notRequired(),
          }),
          numberOfDays: Yup.string().when('type', {
            is: 'delay',
            then: () =>
              Yup.string()
                .required('Number of days is required for delay type')
                .min(1, 'Must be more than 1 character'),
            otherwise: () => Yup.string().notRequired(),
          }),
        }),
      ),
    otherwise: () => Yup.array().notRequired(), // If isPublished is false, steps field is not required
  }),
})
