import { Info, Warning } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Col, Row } from 'reactstrap'

import alfardanLogo from '../../assets/images/alfardan.png'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { COINBASE, TOP_UP_CARD } from '../../core/config/payment-methods'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import {
  weirdCurrencies,
  weirdCurrenciesFormatter,
} from '../../utils/formatters/currency-coinbase'

const KYC_WARNING_TEXT =
  'Your withdrawal limit on your RemotePass card is <strong>$1,000</strong>. To lift this limitation, you must to complete the KYC Level 2 verification process'

const WithdrawReview = ({
  onBack,
  bankInfo,
  loading,
  method,
  title = 'Withdraw',
  receptionLabel = 'You will receive:',
  loadingMinHeight = '30rem',
}) => {
  const usdFormatter = getCurrencyFormatter()
  const code = bankInfo?.currency?.code

  const formatter = weirdCurrencies.includes(code)
    ? weirdCurrenciesFormatter(
        code,
        method?.id === 5 ? COINBASE.key : undefined,
      )
    : getCurrencyFormatter(code)

  const baseFormatter = getCurrencyFormatter(bankInfo?.base_currency?.code)

  if (loading) {
    return <Loader minHeight={loadingMinHeight} />
  }

  return (
    <Row className='justify-content-center'>
      <Col>
        <Row className='justify-content-center'>
          {!!bankInfo && (
            <Col className='font-size-14'>
              <div
                className='d-flex flex-column mb-3'
                style={{ gap: '0.7rem' }}
              >
                <div className='d-flex justify-content-between px-3 pt-3 mb-2'>
                  <h3
                    className='rp-font-bold font-size-18 m-0'
                    style={{ color: '#424652' }}
                  >
                    {title}
                  </h3>
                  {method?.id === 1 &&
                    bankInfo?.details?.provider === 'alfardan' && (
                      <div className='p-0 flex-column align-items-end'>
                        <div className='font-size-10 text-right text-muted justify-content-center'>
                          <span>Powered by</span>
                        </div>
                        <Row className='justify-content-center'>
                          <img
                            style={{ height: 36 }}
                            src={alfardanLogo}
                            alt=''
                          />
                        </Row>
                      </div>
                    )}
                </div>
                {!bankInfo?.subtotal ? null : (
                  <InfoDisplay
                    label='Amount'
                    value={baseFormatter.format(bankInfo.subtotal)}
                    muted
                  />
                )}
                {!bankInfo?.fee ? null : (
                  <InfoDisplay
                    label='Transfer fee'
                    value={baseFormatter.format(bankInfo.fee)}
                    muted
                  />
                )}
                {!bankInfo?.details?.transfer_fee ? null : (
                  <InfoDisplay
                    label='Transfer fee'
                    value={bankInfo?.details?.transfer_fee}
                    muted
                  />
                )}
                {!bankInfo?.rate ? null : (
                  <InfoDisplay
                    label='Conversion rate'
                    value={baseFormatter.format(bankInfo.rate)}
                    muted
                  />
                )}

                {!bankInfo?.exchangeRate ||
                bankInfo.exchangeRate === 1 ? null : (
                  <InfoDisplay
                    label='Rate'
                    value={bankInfo.exchangeRate}
                    muted
                  />
                )}

                <InfoDisplay
                  label={receptionLabel}
                  value={
                    !bankInfo?.details?.coinbase
                      ? `${
                          !bankInfo?.total_usd
                            ? ''
                            : `(${usdFormatter.format(bankInfo?.total_usd)})`
                        } ${formatter.format(bankInfo?.total)}`
                      : `${
                          !bankInfo?.total_usd
                            ? ''
                            : usdFormatter.format(bankInfo?.total_usd)
                        } in ${bankInfo?.currency?.code}`
                  }
                />

                {bankInfo?.details?.should_arrive && (
                  <InfoDisplay
                    label='Should arrive'
                    value={bankInfo?.details?.should_arrive}
                    muted
                  />
                )}
              </div>
              <div
                className='d-flex flex-column mb-3'
                style={{ gap: '0.7rem' }}
              >
                <div
                  className='mx-3'
                  style={{ borderTop: '1px solid #E7E8F2' }}
                />

                {!method ? null : (
                  <>
                    <div className='d-flex justify-content-between px-3'>
                      <div className='rp-font-bold m-0 p-0'>Method</div>
                      {method?.id !== TOP_UP_CARD.id ? (
                        <button
                          type='button'
                          className='rp-btn-nostyle text-primary rp-font-bold p-0'
                          onClick={onBack}
                        >
                          Edit
                        </button>
                      ) : null}
                    </div>
                    <div
                      className='mx-3'
                      style={{ borderTop: '1px solid #E7E8F2' }}
                    />

                    <div className='d-flex flex-column mx-3'>
                      {bankInfo?.warnings?.map((warning, index) => {
                        return (
                          <Alert
                            className='mb-0 p-2 font-size-12 bg-soft-primary border border-soft-primary text-primary'
                            color='primary'
                            key={index}
                          >
                            <p
                              dangerouslySetInnerHTML={{ __html: warning }}
                              className='mb-0'
                            />
                          </Alert>
                        )
                      })}
                    </div>

                    <InfoDisplay label='Method' value={method?.label} muted />
                  </>
                )}

                {bankInfo?.details?.details?.map((e, i) => (
                  <InfoDisplay
                    key={`detail-${i}`}
                    label={e?.name}
                    value={e?.value}
                    muted
                  />
                ))}
                {method?.id === TOP_UP_CARD.id ? (
                  <InfoDisplay
                    label='Currency'
                    value={bankInfo?.details?.currency_code}
                  />
                ) : null}
              </div>
              {method?.id === TOP_UP_CARD.id && !method?.has_full_kyc ? (
                <div className='p-3' style={{ borderTop: '1px solid #E7E8F2' }}>
                  <MessageSection
                    text={KYC_WARNING_TEXT}
                    isLimit={bankInfo?.total > 1000}
                  />
                </div>
              ) : null}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

function InfoDisplay({ value, label, muted }) {
  return (
    <div
      className={cx('d-flex justify-content-between font-weight-light px-3', {
        'text-muted': muted,
      })}
    >
      <p className='mb-0 rp-capitalize'>{label}</p>
      <p className='mb-0 text-gray-h'>{value}</p>
    </div>
  )
}
function MessageSection({ text, isLimit }) {
  return (
    <div
      className={`d-flex border p-3 bg-${
        isLimit ? 'red-20' : 'blue-20'
      } rounded`}
    >
      {isLimit ? (
        <Warning size={32} color='#BF342B' />
      ) : (
        <Info size={32} color='#256CAD' />
      )}
      <div className='px-3'>
        <p
          className='mb-0 font-size-14 font-weight-500'
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <Button
          color='transparent'
          tag={Link}
          to='/cards'
          className='text-primary p-0 mt-3'
          textStyle={{ textAlign: 'left', fontWeight: 700, fontSize: '14px' }}
        >
          Get Verified Now
        </Button>
      </div>
    </div>
  )
}
export default WithdrawReview
