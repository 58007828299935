import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, apiV2, getConfig, getConfigV2 } from './api'

export function getBackgroundCheckContracts(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get('background_check/contracts' + sp, getConfig(token, extra))
}

export function getDocumentInfo(token, data, extra) {
  return api.get(
    'background_check/documents/' + data?.contract_id,
    getConfig(token, extra),
  )
}

export function getBackgroundCheckQuote(token, data) {
  return api.post('background_check/quote', data, getConfig(token))
}

export function getScreeningHistory(token, data) {
  return apiV2.get(
    `compliance/service/v1/screening/user/${data.id}`,
    getConfigV2(token),
  )
}

export function screenTransaction(token, data) {
  return apiV2.post(
    `compliance/service/v1/screening/${data.id}`,
    data,
    getConfigV2(token),
  )
}

export function getBackgroundCheck(token, data) {
  const sp = searchParamsFromObject(data)

  return apiV2.get(
    `compliance/service/v1/background-checks${sp}`,
    getConfigV2(token),
  )
}

export function requestBackgroundCheck(token, data) {
  return apiV2.post(
    `compliance/service/v1/background-checks`,
    data,
    getConfigV2(token),
  )
}

export function getBackgroundCheckReportUrl(token, data) {
  const { applicantId, ...rest } = data
  const sp = searchParamsFromObject(rest)

  return apiV2.get(
    `compliance/service/v1/background-checks/${applicantId}/report-url${sp}`,
    getConfigV2(token),
  )
}

export function getCountryFeatures(token, data, extra) {
  const sp = searchParamsFromObject(data)

  return apiV2.get(
    `compliance/service/v1/country-features${sp}`,
    getConfigV2(token, {}, extra),
  )
}
