import { ArrowCircleRight, CheckCircle } from '@phosphor-icons/react'
import React from 'react'

export default function ApprovalFlowNameList({ nameList }) {
  return (
    <span className='tw-flex tw-flex-wrap tw-items-center tw-gap-2'>
      {nameList?.map((name, index, { length }) => (
        <>
          <span className='tw-whitespace-nowrap tw-text-sm tw-text-text-80'>
            {name}
          </span>

          {index === length - 1 ? (
            <CheckCircle size={16} className='tw-flex-shrink-0' />
          ) : (
            <ArrowCircleRight size={16} className='tw-flex-shrink-0' />
          )}
        </>
      ))}
    </span>
  )
}
