import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import DynamicForm, {
  buildYupSchema,
  getDefaultValues,
  isStepEmpty,
} from '../../../../../components/Forms/dynamic-form'
import { Container, ModalFooter, Row } from 'reactstrap'
import { cn } from 'ui'
import toastr from 'toastr'
import Button from '../../../../../components/ui/button'
function SectionHeader({ title, className }) {
  return <h4 className={cn('tw-mb-6 tw-px-6', className)}>{title}</h4>
}

export function FormPreview({ form }) {
  const { form_steps: steps, form_fields: fields } = form
  const mergedFields =
    fields ??
    steps?.reduce((acc, step) => {
      return [...acc, ...step.form_fields]
    }, [])
  const schema = yup.object().shape(buildYupSchema({ form }))
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(mergedFields),
  })
  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log('Submission', data)
    toastr.success('Form data is valid')
  }
  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log('Error data', err)
    toastr.error('Form data is not valid')
  }

  const hasSteps = steps && steps.length > 0
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {hasSteps ? (
        steps?.map((step) => {
          return isStepEmpty(step, form.conditions, watch()) ? null : (
            <div
              className='tw-border-b tw-border-b-secondary-20 tw-py-6'
              key={step.id}
            >
              <SectionHeader title={step.title} />
              <Container fluid>
                <Row>
                  <DynamicForm
                    control={control}
                    errors={errors}
                    fields={step?.form_fields}
                    setValue={setValue}
                    conditions={form.conditions}
                    watch={watch}
                    key={step.id}
                    isPreviewOnly
                    newUI={false}
                    clearErrors={clearErrors}
                  />
                </Row>
              </Container>
            </div>
          )
        })
      ) : (
        <Container fluid>
          <Row>
            <DynamicForm
              control={control}
              errors={errors}
              fields={fields}
              setValue={setValue}
              watch={watch}
              conditions={form.conditions}
              isPreviewOnly
              newUI={false}
              clearErrors={clearErrors}
            />
          </Row>
        </Container>
      )}
      <ModalFooter>
        <Button>Simulate Submission</Button>
      </ModalFooter>
    </form>
  )
}
