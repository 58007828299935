import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'

export default function ModalBase({
  isOpen,
  toggle,
  heading,
  children,
  actions,
  size,
  centered = true,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={centered} size={size}>
      {!heading ? null : (
        <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
          {heading}
        </ModalHeader>
      )}
      {!children ? null : <ModalBody>{children}</ModalBody>}
      {!actions ? null : <ModalFooter>{actions}</ModalFooter>}
    </Modal>
  )
}
